import Image from './Image'
import {CardStateTitle} from '../utils/card'
import {useTranslation} from 'react-i18next'
import RearPlaceholder from '../assets/RearPlaceholder.png'
import FrontPlaceholder from '../assets/FrontPlaceholder.png'
import {getSrc} from '../utils/image'

export default function CardImages({card}) {
  const {t} = useTranslation()
  return (
    <>
      <div className="flex">
        <div className="mx-auto flex justify-between">
          <Image
            ImgSrc={getSrc(
              card?.images?.front === 'Deleted Front' ? FrontPlaceholder : card?.images?.front,
            )}
          />
          <Image
            ImgSrc={getSrc(
              card?.images?.rear === 'Deleted Rear' ? RearPlaceholder : card?.images?.rear,
            )}
          />
        </div>
      </div>
      <div className="m-2 flex flex-col">
        <p className="text-wrap text-sm text-gray-700">
          {`${t('cardInfo.CardState')}: ${CardStateTitle(card?.state?.name)}`}
        </p>
      </div>
    </>
  )
}
