import {useEffect, useRef} from 'react'
import {Formik, Form} from 'formik'
import {TextInput, SelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {
  getAccessProfilesTypes,
  getAccessProfilesTypesClearError,
  deleteAccessProfileType,
  deleteAccessProfileTypeClearError,
  createAccessProfile,
  createAccessProfileClearError,
} from '../../stores/access_profiles'
import {FormikSaveButton} from '../Buttons'
import FormAddResourceType from './FormAddResourceType'
import FormEditResourceType from './FormEditResourceType'
import DeletionWarning from '../DeletionWarning'

export default function FormAddResource() {
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const dispatch = useDispatch()

  const access_profiles_types = useSelector(
    (state) => state.access_profiles.get_access_profiles_types.success,
  )
  const access_profiles_types_error = useSelector(
    (state) => state.access_profiles.get_access_profiles_types.error,
  )
  const create_access_profile_pending = useSelector(
    (state) => state.access_profiles.create_access_profile.pending,
  )
  const create_access_profile_error = useSelector(
    (state) => state.access_profiles.create_access_profile.error,
  )
  const create_access_profile_type_success = useSelector(
    (state) => state.access_profiles.create_access_profile_type.success,
  )
  const update_access_profile_type_success = useSelector(
    (state) => state.access_profiles.update_access_profile_type.success,
  )
  const delete_access_profile_type_success = useSelector(
    (state) => state.access_profiles.delete_access_profile_type.success,
  )
  const delete_access_profile_type_pending = useSelector(
    (state) => state.access_profiles.delete_access_profile_type.pending,
  )
  const delete_access_profile_type_error = useSelector(
    (state) => state.access_profiles.delete_access_profile_type.error,
  )
  const {t} = useTranslation()

  const initialValues = {
    resource_type: '',
    name: '',
    location: '',
  }

  const validationSchema = Yup.object({
    resource_type: Yup.number('Please select Resource').required(
      t('yupError.ThisFieldIsRequired', {field: t('addResourceForm.ResourceType')}),
    ),
    name: Yup.string()
      .max(256, t('yupError.MaxCharsAllowed', {numberOfChars: '256'})) // 64
      .required(t('yupError.ThisFieldIsRequired', {field: t('addResourceForm.ResourceType')})), // name??
    location: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addEmployee.Domain')}),
    ),
  })

  useEffect(() => {
    dispatch(getAccessProfilesTypes())
  }, [
    dispatch,
    create_access_profile_type_success,
    update_access_profile_type_success,
    delete_access_profile_type_success,
    access_profiles_types_error?.message,
  ])

  let resourceTypeSelectRef = useRef()
  let domainSelectRef = useRef()
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          dispatch(createAccessProfile(values, userID))
          actions.resetForm()
          // resetting react-select components
          domainSelectRef.current.select.setValue('')
          resourceTypeSelectRef.current.select.setValue('')
        }}
      >
        <Form>
          <SelectInput
            label={t('addResourceForm.ResourceType')}
            createNewEntry={permissions.createCompany}
            addItem={<FormAddResourceType />}
            addItemTitle={t('addResourceTypeForm.AddResourceTypeTitle')}
            editItem={<FormEditResourceType />}
            editItemTitle={t('updateResourceType.UpdateResourceTypeTitle')} // TODO: translation is not complete
            deleteItem={
              <DeletionWarning
                deleteFunction={deleteAccessProfileType}
                error={delete_access_profile_type_error}
                setError={deleteAccessProfileTypeClearError}
                pending={delete_access_profile_type_pending}
                message={t('confirmDeletion.DeleteResourceType')}
                text={t('confirmDeletion.Confirm')}
              />
            }
            deleteItemTitle={t('confirmDeletion.ConfirmDeletion')}
            options={access_profiles_types.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            placeholder={t('addResourceForm.ResourceTypePlaceholder')}
            name="resource_type"
            innerRef={resourceTypeSelectRef}
            required
            error={access_profiles_types_error}
            setError={getAccessProfilesTypesClearError}
          />
          <SelectInput
            label={t('addResourceForm.ResourceDomain')}
            options={permissions.createAccessProfileLocations}
            placeholder={t('addResourceForm.ResourceDomainPlaceholder')}
            name="location"
            required
            innerRef={domainSelectRef}
          />
          <TextInput
            label={t('addResourceForm.ResourceName')}
            name="name"
            type="text"
            placeholder=""
            required
          />
          <FormikSaveButton
            type="submit"
            testid="form-add-resource-submit"
            text={t('addResourceForm.ResourceAdd')}
            primary
            pending={create_access_profile_pending}
            error={create_access_profile_error}
            setError={() => dispatch(createAccessProfileClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
