import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useFieldArray, useFormContext} from 'react-hook-form'
import {TrashIcon} from '@heroicons/react/outline'
import {InputComponent} from '../../formRendererRHF/types'
import {
  getAsyncReactSelectConfig,
  getBasisConfig,
  getDatePickerConfig,
  getIsMulti,
  getPlaceholder,
  getStaticText,
  getTextarea,
  getValidationByComponent,
} from './inputsSettingsConfig'
import DynamicInput from '../../formRendererRHF/DynamicInput'
import {Button} from '../../Buttons'
import OptionsFieldArray from './OptionsFieldArray'

/**
 * renders settings inputs for each user field
 * saves the state in rhf state
 */
const InputsSettings: FC = () => {
  const {t} = useTranslation()
  const {control, watch} = useFormContext()
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'fields',
  })
  const watchAllFields = watch()
  const renderFields = (config, index) =>
    config.map((inputConfig) => {
      return (
        <DynamicInput
          key={inputConfig.name}
          {...inputConfig}
          name={`fields.${index}.${inputConfig.name}`}
        />
      )
    })

  return (
    <>
      <ul>
        {fields.map((item: any, index) => {
          // @ts-ignore
          const component = watchAllFields.fields && watchAllFields.fields[index]?.component
          const isWithOptions =
            component === InputComponent.RADIO || component === InputComponent.SELECT

          return (
            <li
              key={item.id}
              className="m-2 block flex flex-col gap-2 rounded-md border border-indigo-200 p-2 text-sm font-medium text-gray-700 shadow-sm"
            >
              <h3
                id="form-preview-title"
                className="text-md flex justify-between bg-lime-50 p-2 font-medium text-gray-900 shadow sm:rounded-lg"
              >
                <span>
                  {t('formBuilder.DefineField')}: {index}
                </span>
                <button type="button" className="h-5 w-5" onClick={() => remove(index)}>
                  <TrashIcon className="text-red-400 hover:text-red-500" />
                </button>
              </h3>
              {renderFields(getBasisConfig(t), index)}
              {component === InputComponent.DATEPICKER &&
                renderFields(getDatePickerConfig(t), index)}
              {component === InputComponent.TEXTAREA && renderFields(getTextarea(t), index)}
              {component === InputComponent.TEXT_INPUT && renderFields(getPlaceholder(t), index)}
              {component === InputComponent.STATIC_TEXT && renderFields(getStaticText(t), index)}
              {isWithOptions && <OptionsFieldArray inputSettingsIndex={index} isRequired />}
              {(component === InputComponent.ASYNCREACTSELECT ||
                component === InputComponent.SELECT) &&
                renderFields(getIsMulti(t), index)}
              {component === InputComponent.ASYNCREACTSELECT &&
                renderFields(getAsyncReactSelectConfig(t), index)}
              {renderFields(getValidationByComponent(t, component), index)}
            </li>
          )
        })}
      </ul>
      <Button
        type="button"
        className="mt-5 sm:mt-4 sm:flex"
        onClick={() =>
          append({
            component: '',
            name: '',
            label: '',
          })
        }
        text={t('formBuilder.AddFields')}
      />
    </>
  )
}

export default InputsSettings
