import axios from '../services/api'
import {createNotificationOnClient} from './notifications'

// Types
const CREATE_INVENTORY_PHOTO_PENDING =
  'idfunctiondb/inventories_photos/CREATE_INVENTORY_PHOTO_PENDING'
const CREATE_INVENTORY_PHOTO_SUCCESS =
  'idfunctiondb/inventories_photos/CREATE_INVENTORY_PHOTO_SUCCESS'
const CREATE_INVENTORY_PHOTO_ERROR = 'idfunctiondb/inventories_photos/CREATE_INVENTORY_PHOTO_ERROR'

const SET_INVENTORY_PHOTO_PENDING = 'idfunctiondb/inventories_photos/SET_INVENTORY_PHOTO_PENDING'
const SET_INVENTORY_PHOTO_SUCCESS = 'idfunctiondb/inventories_photos/SET_INVENTORY_PHOTO_SUCCESS'
const SET_INVENTORY_PHOTO_ERROR = 'idfunctiondb/inventories_photos/SET_INVENTORY_PHOTO_ERROR'

const DELETE_INVENTORY_PHOTO_PENDING =
  'idfunctiondb/inventories_photos/DELETE_INVENTORY_PHOTO_PENDING'
const DELETE_INVENTORY_PHOTO_SUCCESS =
  'idfunctiondb/inventories_photos/DELETE_INVENTORY_PHOTO_SUCCESS'
const DELETE_INVENTORY_PHOTO_ERROR = 'idfunctiondb/inventories_photos/DELETE_INVENTORY_PHOTO_ERROR'
const DELETE_INVENTORY_PHOTO_CLEAR_ERROR =
  'idfunctiondb/inventories_photos/DELETE_INVENTORY_PHOTO_CLEAR_ERROR'

const CREATE_INVENTORY_TYPE_PHOTO_PENDING =
  'idfunctiondb/inventories_photos/CREATE_INVENTORY_TYPE_PHOTO_PENDING'
const CREATE_INVENTORY_TYPE_PHOTO_SUCCESS =
  'idfunctiondb/inventories_photos/CREATE_INVENTORY_TYPE_PHOTO_SUCCESS'
const CREATE_INVENTORY_TYPE_PHOTO_ERROR =
  'idfunctiondb/inventories_photos/CREATE_INVENTORY_TYPE_PHOTO_ERROR'

const SET_INVENTORY_TYPE_PHOTO_PENDING =
  'idfunctiondb/inventories_photos/SET_INVENTORY_TYPE_PHOTO_PENDING'
const SET_INVENTORY_TYPE_PHOTO_SUCCESS =
  'idfunctiondb/inventories_photos/SET_INVENTORY_TYPE_PHOTO_SUCCESS'
const SET_INVENTORY_TYPE_PHOTO_ERROR =
  'idfunctiondb/inventories_photos/SET_INVENTORY_TYPE_PHOTO_ERROR'

const CREATE_AND_SET_INVENTORY_TYPE_PHOTO_PENDING =
  'idfunctiondb/inventories_photos/CREATE_AND_SET_INVENTORY_TYPE_PHOTO_PENDING'
const CREATE_AND_SET_INVENTORY_TYPE_PHOTO_SUCCESS =
  'idfunctiondb/inventories_photos/CREATE_AND_SET_INVENTORY_TYPE_PHOTO_SUCCESS'
const CREATE_AND_SET_INVENTORY_TYPE_PHOTO_ERROR =
  'idfunctiondb/inventories_photos/CREATE_AND_SET_INVENTORY_TYPE_PHOTO_ERROR'
const CREATE_AND_SET_INVENTORY_TYPE_PHOTO_CLEAR_ERROR =
  'idfunctiondb/inventories_photos/CREATE_AND_SET_INVENTORY_TYPE_PHOTO_CLEAR_ERROR'

const CREATE_AND_SET_INVENTORY_PHOTO_PENDING =
  'idfunctiondb/inventories_photos/CREATE_AND_SET_INVENTORY_PHOTO_PENDING'
const CREATE_AND_SET_INVENTORY_PHOTO_SUCCESS =
  'idfunctiondb/inventories_photos/CREATE_AND_SET_INVENTORY_PHOTO_SUCCESS'
const CREATE_AND_SET_INVENTORY_PHOTO_ERROR =
  'idfunctiondb/inventories_photos/CREATE_AND_SET_INVENTORY_PHOTO_ERROR'

const CREATE_AND_SET_INVENTORY_PHOTO_CLEAR_ERROR =
  'idfunctiondb/inventories_photos/CREATE_AND_SET_INVENTORY_PHOTO_CLEAR_ERROR'

// Initial
const initialState = {
  set_inventory_photo: {
    success: null,
    pending: false,
    error: null,
  },

  delete_inventory_photo: {
    success: null,
    pending: false,
    error: null,
  },

  create_inventory_photo: {
    success: null,
    pending: false,
    error: null,
  },

  set_inventory_type_photo: {
    success: null,
    pending: false,
    error: null,
  },

  create_inventory_type_photo: {
    success: null,
    pending: false,
    error: null,
  },

  create_and_set_inventory_type_photo: {
    success: null,
    pending: false,
    error: null,
  },
  create_and_set_inventory_photo: {
    success: null,
    pending: false,
    error: null,
    successMessage: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_INVENTORY_PHOTO_PENDING:
      return {
        ...state,
        set_inventory_photo: {
          ...state.set_inventory_photo,
          pending: true,
        },
      }
    case SET_INVENTORY_PHOTO_SUCCESS:
      return {
        ...state,
        set_inventory_photo: {
          ...state.set_inventory_photo,
          success: action.payload,
          pending: false,
        },
      }
    case SET_INVENTORY_PHOTO_ERROR:
      return {
        ...state,
        set_inventory_photo: {
          ...state.set_inventory_photo,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_INVENTORY_PHOTO_PENDING:
      return {
        ...state,
        delete_inventory_photo: {
          ...state.delete_inventory_photo,
          pending: true,
        },
      }
    case DELETE_INVENTORY_PHOTO_SUCCESS:
      return {
        ...state,
        delete_inventory_photo: {
          ...state.delete_inventory_photo,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_INVENTORY_PHOTO_ERROR:
      return {
        ...state,
        delete_inventory_photo: {
          ...state.delete_inventory_photo,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_INVENTORY_PHOTO_CLEAR_ERROR:
      return {
        ...state,
        delete_inventory_photo: {
          ...state.delete_inventory_photo,
          error: null,
        },
      }
    case CREATE_INVENTORY_PHOTO_PENDING:
      return {
        ...state,
        create_inventory_photo: {
          ...state.create_inventory_photo,
          pending: true,
        },
      }
    case CREATE_INVENTORY_PHOTO_SUCCESS:
      return {
        ...state,
        create_inventory_photo: {
          ...state.create_inventory_photo,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_INVENTORY_PHOTO_ERROR:
      return {
        ...state,
        create_inventory_photo: {
          ...state.create_inventory_photo,
          error: action.payload,
          pending: false,
        },
      }
    case SET_INVENTORY_TYPE_PHOTO_PENDING:
      return {
        ...state,
        set_inventory_type_photo: {
          ...state.set_inventory_type_photo,
          pending: true,
        },
      }
    case SET_INVENTORY_TYPE_PHOTO_SUCCESS:
      return {
        ...state,
        set_inventory_type_photo: {
          ...state.set_inventory_type_photo,
          success: action.payload,
          pending: false,
        },
      }
    case SET_INVENTORY_TYPE_PHOTO_ERROR:
      return {
        ...state,
        set_inventory_type_photo: {
          ...state.set_inventory_type_photo,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_INVENTORY_TYPE_PHOTO_PENDING:
      return {
        ...state,
        create_inventory_type_photo: {
          ...state.create_inventory_type_photo,
          pending: true,
        },
      }
    case CREATE_INVENTORY_TYPE_PHOTO_SUCCESS:
      return {
        ...state,
        create_inventory_type_photo: {
          ...state.create_inventory_type_photo,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_INVENTORY_TYPE_PHOTO_ERROR:
      return {
        ...state,
        create_inventory_type_photo: {
          ...state.create_inventory_type_photo,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_AND_SET_INVENTORY_TYPE_PHOTO_PENDING:
      return {
        ...state,
        create_and_set_inventory_type_photo: {
          ...state.create_and_set_inventory_type_photo,
          pending: true,
        },
      }
    case CREATE_AND_SET_INVENTORY_TYPE_PHOTO_SUCCESS:
      return {
        ...state,
        create_and_set_inventory_type_photo: {
          ...state.create_and_set_inventory_type_photo,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_AND_SET_INVENTORY_TYPE_PHOTO_ERROR:
      return {
        ...state,
        create_and_set_inventory_type_photo: {
          ...state.create_and_set_inventory_type_photo,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_AND_SET_INVENTORY_TYPE_PHOTO_CLEAR_ERROR:
      return {
        ...state,
        create_and_set_inventory_type_photo: {
          ...state.create_and_set_inventory_type_photo,
          error: null,
          pending: false,
        },
      }
    case CREATE_AND_SET_INVENTORY_PHOTO_PENDING:
      return {
        ...state,
        create_and_set_inventory_photo: {
          ...state.create_and_set_inventory_photo,
          pending: true,
          successMessage: null,
        },
      }
    case CREATE_AND_SET_INVENTORY_PHOTO_SUCCESS:
      return {
        ...state,
        create_and_set_inventory_photo: {
          ...state.create_and_set_inventory_photo,
          success: action.payload.data,
          pending: false,
          successMessage: action.payload.successMessage,
        },
      }
    case CREATE_AND_SET_INVENTORY_PHOTO_ERROR:
      return {
        ...state,
        create_and_set_inventory_photo: {
          ...state.create_and_set_inventory_photo,
          error: action.payload,
          pending: false,
          successMessage: null,
        },
      }
    case CREATE_AND_SET_INVENTORY_PHOTO_CLEAR_ERROR:
      return {
        ...state,
        create_and_set_inventory_photo: {
          ...state.create_and_set_inventory_photo,
          error: null,
          pending: false,
          successMessage: null,
        },
      }
    default:
      return state
  }
}

// Actions

function createInventoryPhotoPending() {
  return {type: CREATE_INVENTORY_PHOTO_PENDING}
}

function createInventoryPhotoSuccess(payload) {
  return {type: CREATE_INVENTORY_PHOTO_SUCCESS, payload: payload}
}

function createInventoryPhotoError(error) {
  return {type: CREATE_INVENTORY_PHOTO_ERROR, payload: error}
}

function setInventoryPhotoPending() {
  return {type: SET_INVENTORY_PHOTO_PENDING}
}

function setInventoryPhotoSuccess(payload) {
  return {type: SET_INVENTORY_PHOTO_SUCCESS, payload: payload}
}

function setInventoryPhotoError(error) {
  return {type: SET_INVENTORY_PHOTO_ERROR, payload: error}
}

function deleteInventoryPhotoPending() {
  return {type: DELETE_INVENTORY_PHOTO_PENDING}
}

function deleteInventoryPhotoSuccess(payload) {
  return {type: DELETE_INVENTORY_PHOTO_SUCCESS, payload: payload}
}

function deleteInventoryPhotoError(error) {
  return {type: DELETE_INVENTORY_PHOTO_ERROR, payload: error}
}

export function deleteInventoryPhotoClearError() {
  return {type: DELETE_INVENTORY_PHOTO_CLEAR_ERROR}
}

function createInventoryTypePhotoPending() {
  return {type: CREATE_INVENTORY_TYPE_PHOTO_PENDING}
}

function createInventoryTypePhotoSuccess(payload) {
  return {type: CREATE_INVENTORY_TYPE_PHOTO_SUCCESS, payload: payload}
}

function createInventoryTypePhotoError(error) {
  return {type: CREATE_INVENTORY_TYPE_PHOTO_ERROR, payload: error}
}

function setInventoryTypePhotoPending() {
  return {type: SET_INVENTORY_TYPE_PHOTO_PENDING}
}

function setInventoryTypePhotoSuccess(payload) {
  return {type: SET_INVENTORY_TYPE_PHOTO_SUCCESS, payload: payload}
}

function setInventoryTypePhotoError(error) {
  return {type: SET_INVENTORY_TYPE_PHOTO_ERROR, payload: error}
}

function createAndSetInventoryTypePhotoPending() {
  return {type: CREATE_AND_SET_INVENTORY_TYPE_PHOTO_PENDING}
}

function createAndSetInventoryTypePhotoSuccess(payload) {
  return {type: CREATE_AND_SET_INVENTORY_TYPE_PHOTO_SUCCESS, payload: payload}
}

function createAndSetInventoryTypePhotoError(error) {
  return {type: CREATE_AND_SET_INVENTORY_TYPE_PHOTO_ERROR, payload: error}
}

export function createAndSetInventoryTypePhotoClearError() {
  return {type: CREATE_AND_SET_INVENTORY_TYPE_PHOTO_CLEAR_ERROR}
}

function createAndSetInventoryPhotoPending() {
  return {type: CREATE_AND_SET_INVENTORY_PHOTO_PENDING}
}

function createAndSetInventoryPhotoSuccess(payload) {
  return {type: CREATE_AND_SET_INVENTORY_PHOTO_SUCCESS, payload}
}

function createAndSetInventoryPhotoError(error) {
  return {type: CREATE_AND_SET_INVENTORY_PHOTO_ERROR, payload: error}
}

export function createAndSetInventoryPhotoClearError() {
  return {type: CREATE_AND_SET_INVENTORY_PHOTO_CLEAR_ERROR}
}

// Operations

export const createInventoryPhoto = (url, target) => (dispatch) => {
  dispatch(createInventoryPhotoPending())
  var formData = new FormData()
  formData.append('photo', target)
  return axios
    .post(url, formData)
    .then((res) => {
      dispatch(createInventoryPhotoSuccess(res.data))
      return res.data
    })
    .catch((error) => {
      dispatch(createInventoryPhotoError(error))
      return null
    })
}

export const setInventoryPhoto = (url, values) => (dispatch) => {
  dispatch(setInventoryPhotoPending())
  return axios
    .put(url, values)
    .then((res) => {
      dispatch(setInventoryPhotoSuccess(url))
      return res
    })
    .catch((error) => dispatch(setInventoryPhotoError(error)))
}

export const deleteInventoryPhoto = (id, values) => (dispatch) => {
  dispatch(deleteInventoryPhotoPending())
  return axios
    .delete(`/api/v1/inventories/${id}/photo`)
    .then((res) => {
      dispatch(deleteInventoryPhotoSuccess(values))
      return res.data
    })
    .catch((error) => {
      dispatch(deleteInventoryPhotoError(error))
    })
}

export const createInventoryTypePhoto = (url, target) => (dispatch) => {
  dispatch(createInventoryTypePhotoPending())
  var formData = new FormData()
  formData.append('photo', target)
  return axios
    .post(url, formData)
    .then((res) => {
      dispatch(createInventoryTypePhotoSuccess(res.data))
      return res.data
    })
    .catch((error) => {
      dispatch(createInventoryTypePhotoError(error))
      return null
    })
}

export const setInventoryTypePhoto = (url, values) => (dispatch) => {
  dispatch(setInventoryTypePhotoPending())
  return axios
    .put(url, values)
    .then((res) => {
      dispatch(setInventoryTypePhotoSuccess(url))
      return res
    })
    .catch((error) => dispatch(setInventoryTypePhotoError(error)))
}

export const createAndSetInventoryTypePhoto = (url, target) => (dispatch) => {
  dispatch(createAndSetInventoryTypePhotoPending())
  const setUrl = url.slice(0, -1)
  dispatch(createInventoryTypePhoto(url, target))
    .then((res) => {
      dispatch(setInventoryTypePhoto(setUrl, {file_id: res.id}))
        .then((res) => {
          dispatch(createAndSetInventoryTypePhotoSuccess(res.data))
          return res.data
        })
        .catch((error) => {
          dispatch(createAndSetInventoryTypePhotoError(error))
        })
    })
    .catch((error) => {
      dispatch(createAndSetInventoryTypePhotoError(error))
    })
}

export const createAndSetInventoryPhoto = (url, target) => (dispatch) => {
  dispatch(createAndSetInventoryPhotoPending())
  const setUrl = url.slice(0, -1)
  dispatch(createInventoryPhoto(url, target))
    .then((res) => {
      dispatch(setInventoryPhoto(setUrl, {file_id: res.id}))
        .then((res) => {
          dispatch(
            createAndSetInventoryPhotoSuccess({
              data: res.data,
              successMessage: 'notificationSuccess.InventoryPhotoUpdated',
            }),
          )
          dispatch(
            createNotificationOnClient(res.status, 'notificationSuccess.InventoryPhotoUpdated'),
          )
          return res.data
        })
        .catch((error) => {
          dispatch(createAndSetInventoryPhotoError(error))
        })
    })
    .catch((error) => {
      dispatch(createAndSetInventoryPhotoError(error))
    })
}
