import {useEffect} from 'react'
import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import {getMyExecutions, putExecutionClearError} from '../stores/workflows'
import Table from '../components/table'
import Tasks from '../components/Tasks'
import {useTableColumns} from '../hooks/useTableColumns'
import {TableId} from '../stores/types/tables'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'

export default function TodosScreen() {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const executions = useSelector((state) => state.workflows.get_my_executions.success)
  const executionsPending = useSelector((state) => state.workflows.get_my_executions.pending)
  const executionsError = useSelector((state) => state.workflows.get_my_executions.error)
  const putExecution = useSelector((state) => state.workflows.put_execution.success)
  const putExecutionError = useSelector((state) => state.workflows.put_execution.error)

  // getMyExecutions
  useEffect(() => {
    dispatch(getMyExecutions())
  }, [dispatch, putExecution, putExecutionError])

  const columns = useTableColumns(TableId.TODOS)
  return (
    <>
      <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{t('tasks.Title')}</h1>{' '}
            <p className="text-sm font-medium text-gray-500">{t('tasks.TasksInstruction')}</p>
          </div>
        </div>
      </div>
      <Collapse title={t('tasks.YourRequests')}>
        {/* "your requests" : that someone else will approve or disapprove and you cancel your requests */}
        <EditTableWrapper tableId={TableId.TODOS} />
        <Table
          columns={columns}
          data={executions}
          pending={executionsPending}
          error={executionsError}
          setError={putExecutionClearError}
        />
      </Collapse>
      <Tasks />
    </>
  )
}
