export function getLocalRefreshToken() {
  const tokenData = JSON.parse(sessionStorage.getItem('tokenData'))
  return tokenData?.refresh_token
}

export function getLocalAccessToken() {
  const tokenData = JSON.parse(sessionStorage.getItem('tokenData'))
  return tokenData?.access_token
}

export function updateLocalTokenData(token) {
  sessionStorage.setItem('tokenData', JSON.stringify(token))
}

export function getTokenData() {
  return JSON.parse(sessionStorage.getItem('tokenData'))
}

export function setTokenData(tokenData) {
  sessionStorage.setItem('tokenData', JSON.stringify(tokenData))
}

export function removeTokenData() {
  sessionStorage.removeItem('tokenData')
}

export function getCodeVerifier() {
  return JSON.parse(sessionStorage.getItem('codeVerifier'))
}

export function setCodeVerifier(codeVerifier) {
  sessionStorage.setItem('codeVerifier', JSON.stringify(codeVerifier))
}
