import {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import axios from '../services/api'
import {CameraIcon, DownloadIcon, UploadIcon} from '@heroicons/react/outline'
import {ModalOpenButton, Modal, ModalContentsBase} from '../components/Modal'
import FormCameraRegular from '../components/forms/FormCameraRegular'
import FormEditImageUploadresource from '../components/forms/FormEditImageUploadInventory'
import Image from '../components/Image'
import IdentityNavigationArrows from '../components/IdentityNavigationArrows'
import Collapse from '../components/Collapse'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'
import {TableId} from '../stores/types/tables'
import {useTableColumns} from '../hooks/useTableColumns'
import ServerSideTable from '../components/table/ServerSideTable'
import {BASE_URL} from '../utils/consts'
import FormUpdateResource from '../components/forms/FormUpdateResource'
import FormAddResourceIdentity from '../components/forms/FormAddResourceIdentity'
import FormCustomAttributes from '../components/forms/FormCustomAttributes'
import {useDispatch, useSelector} from 'react-redux'
import {getAccessProfile} from '../stores/access_profiles'
import ResourceDependencies from '../components/Dependency/ResourceDependencies'
import ErrorModal from '../components/ErrorModal'
import {getEmployeeClearError} from '../stores/employees'
import {ResourcesIcon} from '../assets/ResourcesIcon'

const ResourceScreen = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [resource, setResource] = useState()
  const [isParents, setIsParents] = useState(false)
  const [resourceMeta, setResourceMeta] = useState()
  const [forceUpdate, setForceUpdate] = useState(0)

  const dispatch = useDispatch()

  const {success: deleteChildSuccessState} = useSelector(
    (state) => state.access_profiles.delete_access_profile_child,
  )
  const errorResource = useSelector((state) => state.access_profiles.get_access_profile.error)
  const {success: deleteMemberSuccessState} = useSelector(
    (state) => state.access_profiles_identities.delete,
  )
  const {success: updateMemberSuccessState} = useSelector(
    (state) => state.access_profiles_identities.update,
  )
  const {updateAccessProfile} = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const fetchResource = useCallback(async () => {
    if (id) {
      const data = await dispatch(getAccessProfile(id))
      setResource(data)
      setIsParents(!!data?.parent_resources)
    }
  }, [id, dispatch])

  useEffect(() => {
    if (id) {
      fetchResource().then()
    }
  }, [id, fetchResource])

  useEffect(() => {
    const fetchMeta = async (id) => {
      try {
        const {data: dataMeta} = await axios.get(`/api/v1/access_profiles?current=${id}`)
        setResourceMeta(dataMeta.meta)
      } catch (error) {
        setResourceMeta(undefined)
      }
    }

    if (id) {
      fetchMeta(id).then()
    }
  }, [id])

  const columnsResourcesIdentities = useTableColumns(TableId.RESOURCES_IDENTITIES)
  const columnsResourcesChildren = useTableColumns(TableId.RESOURCE_CHILDREN)
  const columnsResourcesParents = useTableColumns(TableId.RESOURCE_PARENTS)

  return (
    <>
      {!!errorResource && (
        <ErrorModal error={errorResource} setError={() => dispatch(getEmployeeClearError())} />
      )}
      <div className="flex justify-between">
        <div className="flex ">
          {resource?.photos?.current_id ? (
            <>
              <Image
                ImgSrc={BASE_URL ? BASE_URL + resource?.photos?.current : resource?.photos?.current}
              />
            </>
          ) : resource?.type?.photos?.current_id ? (
            <>
              <Image
                ImgSrc={
                  BASE_URL
                    ? BASE_URL + resource?.type?.photos?.current
                    : resource?.type?.photos?.current
                }
                className={'profile_image'}
                spinnerwidth={'140px'}
                spinnerheight={'175px'}
              />
            </>
          ) : (
            <ResourcesIcon fill="#6b7280" className="mr-3 h-24 w-24 flex-shrink-0" />
          )}
          <div className="flex flex-col pt-3">
            <Modal>
              <ModalOpenButton>
                <CameraIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </ModalOpenButton>
              <ModalContentsBase>
                <FormCameraRegular identity={resource} />
              </ModalContentsBase>
            </Modal>
            <Modal>
              <ModalOpenButton>
                <UploadIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </ModalOpenButton>
              <ModalContentsBase>
                <FormEditImageUploadresource identity={resource} />
              </ModalContentsBase>
            </Modal>
            <div className="space-y-1 text-center">
              <a
                href={resource?.photos?.current}
                download={
                  BASE_URL
                    ? BASE_URL + `${resource?.model}_${resource?.resource_number}.png`
                    : `${resource?.model}_${resource?.resource_number}.png`
                }
              >
                <DownloadIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </a>
            </div>
            <ResourceDependencies />
          </div>
          <div className="flex flex-col pt-3">
            <p className="text-gray-700">{resource?.name}</p>
            <p className="text-gray-700">{resource?.type?.name}</p>
          </div>
        </div>
        {!!resourceMeta && (
          <div className="flex pt-3">
            <IdentityNavigationArrows
              next={resourceMeta?.next}
              previous={resourceMeta?.previous}
              navTo="/resources"
            />
          </div>
        )}
      </div>
      <Collapse title={t('resource.Members')}>
        <Collapse title={t('resource.AddMembers')}>
          <FormAddResourceIdentity
            resource={resource}
            onSubmit={() => setForceUpdate((prev) => prev + 1)}
          />
        </Collapse>
        {id ? (
          <>
            <EditTableWrapper tableId={TableId.RESOURCES_IDENTITIES} />
            <ServerSideTable
              columns={columnsResourcesIdentities}
              entityName={'identities_access_profiles'}
              additionalParams={'flattened=false'}
              additionalFilter={`resource_id eq ${id}`}
              addAction={forceUpdate}
              deleteAction={deleteMemberSuccessState}
              addActionQuick={updateMemberSuccessState}
            />
          </>
        ) : (
          t('resource.MemberNotFound')
        )}
      </Collapse>
      {isParents && (
        <Collapse title={t('resource.Parents')}>
          <>
            <EditTableWrapper tableId={TableId.RESOURCE_PARENTS} />
            <ServerSideTable
              columns={columnsResourcesParents}
              entityName={`access_profiles/${id}/parents`}
              rowUrlAccessor={'id'}
              url={'/resources/'}
            />
          </>
        </Collapse>
      )}
      <Collapse title={t('resource.Children')}>
        <>
          <Collapse title={t('resource.AddChildren')}>
            <FormUpdateResource
              resource={resource}
              onUpdate={() => setForceUpdate((prev) => prev + 1)}
              onlyChildren
            />
          </Collapse>
          <EditTableWrapper tableId={TableId.RESOURCE_CHILDREN} />
          <ServerSideTable
            columns={columnsResourcesChildren}
            entityName={`access_profiles/${id}/children`}
            rowUrlAccessor={'id'}
            url={'/resources/'}
            addAction={forceUpdate}
            deleteAction={deleteChildSuccessState}
          />
        </>
      </Collapse>
      <Collapse title={t('resource.Details')}>
        <FormUpdateResource resource={resource} onUpdate={fetchResource} />
        {!!updateAccessProfile && !resource?.subsystem_id && (
          <Collapse title={t('TableLabelsCommon.CustomAttributes')}>
            <FormCustomAttributes resource={resource} forceUpdate={fetchResource} />
          </Collapse>
        )}
      </Collapse>
    </>
  )
}

export default ResourceScreen
