import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'SelectCheckbox',
    cell: 'SelectCheckboxCell',
    label: 'setAvatarForm.Select',
    id: 'selection.Select',
    width: 25,
    accessor: 'identity.id',
    noSort: true,
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.city',
    label: 'TableLabelsCommon.City',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.country',
    label: 'TableLabelsCommon.Country',
  },
  {
    header: 'SelectColumnFilterCompanies',
    accessor: 'company.name',
    label: 'TableLabelsCommon.CompanyName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.phone',
    label: 'TableLabelsCommon.CompanyPhone',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.state',
    label: 'TableLabelsCommon.CompanyState',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.street',
    label: 'TableLabelsCommon.CompanyStreet',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.type',
    label: 'TableLabelsCommon.CompanyType',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.uni_location_code',
    label: 'TableLabelsCommon.CompanyUniLocationCode',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.zip',
    label: 'TableLabelsCommon.CompanyZip',
  },
  {
    header: 'ColumnFilter',
    accessor: 'costcenter',
    label: 'TableLabelsCommon.Costcenter',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'created_at',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.CustomAttributes',
    accessor: 'custom_attributes',
    noSort: true,
    cell: 'CustomAttributesCell',
    label: 'TableLabelsCommon.CustomAttributes',
  },
  {
    header: 'ColumnFilter',
    accessor: 'department',
    label: 'TableLabelsCommon.Department',
  },
  {
    header: 'ColumnFilter',
    accessor: 'email',
    label: 'TableLabelsCommon.Email',
  },
  {
    header: 'ColumnFilter',
    accessor: 'employee_number',
    label: 'TableLabelsCommon.EmployeeId',
  },
  {
    header: 'SelectColumnFilterEmployeeState',
    accessor: 'employee_state.id',
    cell: 'EditEmployeeStatusCell',
    label: 'TableLabelsCommon.Status',
    noSort: true,
  },
  {
    header: 'ColumnFilter',
    accessor: 'employee_state_long',
    label: 'TableLabelsCommon.EmployeeStateLong',
  },
  {
    header: 'ColumnFilter',
    accessor: 'employee_state_short',
    label: 'TableLabelsCommon.EmployeeStateShort',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'end_of_contract',
    label: 'TableLabelsCommon.EndOfContract',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'last_day_of_work',
    label: 'TableLabelsCommon.LastDayOfWork',
  },
  {
    header: 'ColumnFilter',
    accessor: 'local_hr_number',
    label: 'TableLabelsCommon.LocalHrNumber',
  },
  {
    header: 'SelectColumnFilterLocations',
    accessor: 'location.description',
    label: 'TableLabelsCommon.Location',
    permissionsAccessor: 'listEmployeesLocations',
  },
  {
    header: 'ColumnFilter',
    accessor: 'mainoffice',
    label: 'TableLabelsCommon.Mainoffice',
  },
  {
    header: 'ColumnFilter',
    accessor: 'mobile',
    label: 'TableLabelsCommon.Mobile',
  },
  {
    header: 'ColumnFilter',
    accessor: 'person.first_name',
    label: 'TableLabelsCommon.FirstName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'person.full_name',
    cell: 'AvatarCell',
    label: 'TableLabelsCommon.FullName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'person.last_name',
    label: 'TableLabelsCommon.LastName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'phone',
    label: 'TableLabelsCommon.Phone',
  },
  {
    header: 'ColumnFilter',
    accessor: 'position',
    label: 'TableLabelsCommon.Position',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'start_of_contract',
    label: 'TableLabelsCommon.StartOfContract',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },

  {
    header: 'employeeList.Actions',
    label: 'employeeList.Actions',
    accessor: 'id',
    width: 40,
    cell: 'EmployeeActionCell',
    noSort: true,
  },
]
export default columnConfig
