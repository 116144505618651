import axios from '../services/api'
import {createNotification, createNotificationOnClient} from './notifications'
import I18n from '../i18n'

// Types

const GET_EMPLOYEES_TOTAL_PENDING = 'idfunctiondb/employees/GET_EMPLOYEES_TOTAL_PENDING'
const GET_EMPLOYEES_TOTAL_SUCCESS = 'idfunctiondb/employees/GET_EMPLOYEES_TOTAL_SUCCESS'
const GET_EMPLOYEES_TOTAL_ERROR = 'idfunctiondb/employees/GET_EMPLOYEES_TOTAL_ERROR'

const GET_EMPLOYEE_PENDING = 'idfunctiondb/employees/GET_EMPLOYEE_PENDING'
const GET_EMPLOYEE_SUCCESS = 'idfunctiondb/employees/GET_EMPLOYEE_SUCCESS'
const GET_EMPLOYEE_ERROR = 'idfunctiondb/employees/GET_EMPLOYEE_ERROR'
const GET_EMPLOYEE_CLEAR_ERROR = 'idfunctiondb/employees/GET_EMPLOYEE_CLEAR_ERROR'

const GET_EMPLOYEE_META_PENDING = 'idfunctiondb/employees/GET_EMPLOYEE_META_PENDING'
const GET_EMPLOYEE_META_SUCCESS = 'idfunctiondb/employees/GET_EMPLOYEE_META_SUCCESS'
const GET_EMPLOYEE_META_ERROR = 'idfunctiondb/employees/GET_EMPLOYEE_META_ERROR'

const GET_EMPLOYEE_FILES_PENDING = 'idfunctiondb/employees/GET_EMPLOYEE_FILES_PENDING'
const GET_EMPLOYEE_FILES_SUCCESS = 'idfunctiondb/employees/GET_EMPLOYEE_FILES_SUCCESS'
const GET_EMPLOYEE_FILES_ERROR = 'idfunctiondb/employees/GET_EMPLOYEE_FILES_ERROR'
const GET_EMPLOYEE_FILES_CLEAR_ERROR = 'idfunctiondb/employees/GET_EMPLOYEE_FILES_CLEAR_ERROR'

const SET_EMPLOYEE_PENDING = 'idfunctiondb/employees/SET_EMPLOYEE_PENDING'
const SET_EMPLOYEE_SUCCESS = 'idfunctiondb/employees/SET_EMPLOYEE_SUCCESS'
const SET_EMPLOYEE_ERROR = 'idfunctiondb/employees/SET_EMPLOYEE_ERROR'
const SET_EMPLOYEE_CLEAR_ERROR = 'idfunctiondb/employees/SET_EMPLOYEE_CLEAR_ERROR'

const CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_PENDING =
  'idfunctiondb/employees/CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_PENDING'
const CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_SUCCESS =
  'idfunctiondb/employees/CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_SUCCESS'
const CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_ERROR =
  'idfunctiondb/employees/CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_ERROR'

const CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_CLEAR_ERROR =
  'idfunctiondb/employees/CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_CLEAR_ERROR'

const DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_PENDING =
  'idfunctiondb/employees/DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_PENDING'
const DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_SUCCESS =
  'idfunctiondb/employees/DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_SUCCESS'
const DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_ERROR =
  'idfunctiondb/employees/DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_ERROR'

const CREATE_EMPLOYEE_PENDING = 'idfunctiondb/employees/CREATE_EMPLOYEE_PENDING'
const CREATE_EMPLOYEE_SUCCESS = 'idfunctiondb/employees/CREATE_EMPLOYEE_SUCCESS'
const CREATE_EMPLOYEE_ERROR = 'idfunctiondb/employees/CREATE_EMPLOYEE_ERROR'
const CREATE_EMPLOYEE_CLEAR_ERROR = 'idfunctiondb/employees/CREATE_EMPLOYEE_CLEAR_ERROR'

const CREATE_QUICK_EMPLOYEE_PENDING = 'idfunctiondb/employees/CREATE_QUICK_EMPLOYEE_PENDING'
const CREATE_QUICK_EMPLOYEE_SUCCESS = 'idfunctiondb/employees/CREATE_QUICK_EMPLOYEE_SUCCESS'
const CREATE_QUICK_EMPLOYEE_ERROR = 'idfunctiondb/employees/CREATE_QUICK_EMPLOYEE_ERROR'
const CREATE_QUICK_EMPLOYEE_CLEAR_ERROR = 'idfunctiondb/employees/CREATE_QUICK_EMPLOYEE_CLEAR_ERROR'

const DELETE_EMPLOYEE_FILE_PENDING = 'idfunctiondb/employees/DELETE_EMPLOYEE_FILE_PENDING'
const DELETE_EMPLOYEE_FILE_SUCCESS = 'idfunctiondb/employees/DELETE_EMPLOYEE_FILE_SUCCESS'
const DELETE_EMPLOYEE_FILE_ERROR = 'idfunctiondb/employees/DELETE_EMPLOYEE_FILE_ERROR'

const CREATE_EMPLOYEE_FILE_PENDING = 'idfunctiondb/employees/CREATE_EMPLOYEE_FILE_PENDING'
const CREATE_EMPLOYEE_FILE_SUCCESS = 'idfunctiondb/employees/CREATE_EMPLOYEE_FILE_SUCCESS'
const CREATE_EMPLOYEE_FILE_ERROR = 'idfunctiondb/employees/CREATE_EMPLOYEE_FILE_ERROR'
const CREATE_EMPLOYEE_FILE_CLEAR_ERROR = 'idfunctiondb/employees/CREATE_EMPLOYEE_FILE_CLEAR_ERROR'

const DELETE_EMPLOYEE_PENDING = 'idfunctiondb/employees/DELETE_EMPLOYEE_PENDING'
const DELETE_EMPLOYEE_SUCCESS = 'idfunctiondb/employees/DELETE_EMPLOYEE_SUCCESS'
const DELETE_EMPLOYEE_ERROR = 'idfunctiondb/employees/DELETE_EMPLOYEE_ERROR'

const DELETE_EMPLOYEE_PHOTO_PENDING = 'idfunctiondb/employees/DELETE_EMPLOYEE_PHOTO_PENDING'
const DELETE_EMPLOYEE_PHOTO_SUCCESS = 'idfunctiondb/employees/DELETE_EMPLOYEE_PHOTO_SUCCESS'
const DELETE_EMPLOYEE_PHOTO_ERROR = 'idfunctiondb/employees/DELETE_EMPLOYEE_PHOTO_ERROR'
const DELETE_EMPLOYEE_PHOTO_CLEAR_ERROR = 'idfunctiondb/employees/DELETE_EMPLOYEE_PHOTO_CLEAR_ERROR'

const initialState = {
  get_employee: {
    success: {},
    pending: false,
    error: null,
  },

  get_employees_total: {
    success: null,
    pending: false,
    error: null,
  },

  set_employee: {
    success: null,
    pending: false,
    error: null,
  },

  create_employee_custom_attribute: {
    success: null,
    pending: false,
    error: null,
  },

  delete_employee_custom_attribute: {
    success: null,
    pending: false,
    error: null,
  },

  create_employee: {
    success: null,
    pending: false,
    error: null,
    successMessage: false,
  },

  create_quick_employee: {
    success: null,
    pending: false,
    error: null,
  },

  delete_employee: {
    success: null,
    pending: false,
    error: null,
  },

  get_employee_files: {
    success: [],
    pending: false,
    error: null,
  },

  create_employee_file: {
    success: null,
    pending: false,
    error: null,
  },

  delete_employee_file: {
    success: null,
    pending: false,
    error: null,
  },

  delete_employee_photo: {
    success: null,
    pending: false,
    error: null,
  },

  get_employee_meta: {
    success: {},
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_EMPLOYEES_TOTAL_PENDING:
      return {
        ...state,
        get_employees_total: {
          ...state.get_employees_total,
          pending: true,
        },
      }
    case GET_EMPLOYEES_TOTAL_SUCCESS:
      return {
        ...state,
        get_employees_total: {
          ...state.get_employees_total,
          success: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEES_TOTAL_ERROR:
      return {
        ...state,
        get_employees_total: {
          ...state.get_employees_total,
          error: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEE_PENDING:
      return {
        ...state,
        get_employee: {
          ...state.get_employee,
          pending: true,
        },
      }
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        get_employee: {
          ...state.get_employee,
          success: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEE_ERROR:
      return {
        ...state,
        get_employee: {
          ...state.get_employee,
          error: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEE_CLEAR_ERROR:
      return {
        ...state,
        get_employee: {
          ...state.get_employee,
          error: null,
        },
      }
    case GET_EMPLOYEE_META_PENDING:
      return {
        ...state,
        get_employee_meta: {
          ...state.get_employee_meta,
          pending: true,
        },
      }
    case GET_EMPLOYEE_META_SUCCESS:
      return {
        ...state,
        get_employee_meta: {
          ...state.get_employee_meta,
          success: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEE_META_ERROR:
      return {
        ...state,
        get_employee_meta: {
          ...state.get_employee_meta,
          error: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEE_FILES_PENDING:
      return {
        ...state,
        get_employee_files: {
          ...state.get_employee_files,
          pending: true,
        },
      }
    case GET_EMPLOYEE_FILES_SUCCESS:
      return {
        ...state,
        get_employee_files: {
          ...state.get_employee_files,
          success: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEE_FILES_ERROR:
      return {
        ...state,
        get_employee_files: {
          ...state.get_employee_files,
          error: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEE_FILES_CLEAR_ERROR:
      return {
        ...state,
        get_employee_files: {
          ...state.get_employee_files,
          error: null,
          pending: false,
        },
      }
    case SET_EMPLOYEE_PENDING:
      return {
        ...state,
        set_employee: {
          ...state.set_employee,
          pending: true,
        },
      }
    case SET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        set_employee: {
          ...state.set_employee,
          success: action.payload,
          pending: false,
        },
      }
    case SET_EMPLOYEE_ERROR:
      return {
        ...state,
        set_employee: {
          ...state.set_employee,
          error: action.payload,
          pending: false,
        },
      }
    case SET_EMPLOYEE_CLEAR_ERROR:
      return {
        ...state,
        set_employee: {
          ...state.set_employee,
          error: null,
          pending: false,
        },
      }
    case CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_PENDING:
      return {
        ...state,
        create_employee_custom_attribute: {
          ...state.create_employee_custom_attribute,
          pending: true,
        },
      }
    case CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        create_employee_custom_attribute: {
          ...state.create_employee_custom_attribute,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_ERROR:
      return {
        ...state,
        create_employee_custom_attribute: {
          ...state.create_employee_custom_attribute,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_CLEAR_ERROR:
      return {
        ...state,
        create_employee_custom_attribute: {
          ...state.create_employee_custom_attribute,
          error: null,
          pending: false,
        },
      }
    case DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_PENDING:
      return {
        ...state,
        delete_employee_custom_attribute: {
          ...state.delete_employee_custom_attribute,
          pending: true,
        },
      }
    case DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        delete_employee_custom_attribute: {
          ...state.delete_employee_custom_attribute,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_ERROR:
      return {
        ...state,
        delete_employee_custom_attribute: {
          ...state.delete_employee_custom_attribute,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_EMPLOYEE_PENDING:
      return {
        ...state,
        create_employee: {
          ...state.create_employee,
          pending: true,
        },
      }
    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        create_employee: {
          ...state.create_employee,
          success: action.payload,
          successMessage: true,
          pending: false,
        },
      }
    case CREATE_EMPLOYEE_ERROR:
      return {
        ...state,
        create_employee: {
          ...state.create_employee,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_EMPLOYEE_CLEAR_ERROR:
      return {
        ...state,
        create_employee: {
          ...state.create_employee,
          error: null,
          successMessage: false,
          pending: false,
        },
      }
    case CREATE_QUICK_EMPLOYEE_PENDING:
      return {
        ...state,
        create_quick_employee: {
          ...state.create_quick_employee,
          pending: true,
        },
      }
    case CREATE_QUICK_EMPLOYEE_SUCCESS:
      return {
        ...state,
        create_quick_employee: {
          ...state.create_quick_employee,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_QUICK_EMPLOYEE_ERROR:
      return {
        ...state,
        create_quick_employee: {
          ...state.create_quick_employee,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_QUICK_EMPLOYEE_CLEAR_ERROR:
      return {
        ...state,
        create_quick_employee: {
          ...state.create_quick_employee,
          error: null,
          pending: false,
        },
      }
    case CREATE_EMPLOYEE_FILE_PENDING:
      return {
        ...state,
        create_employee_file: {
          ...state.create_employee_file,
          pending: true,
        },
      }
    case CREATE_EMPLOYEE_FILE_SUCCESS:
      return {
        ...state,
        create_employee_file: {
          ...state.create_employee_file,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_EMPLOYEE_FILE_ERROR:
      return {
        ...state,
        create_employee_file: {
          ...state.create_employee_file,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_EMPLOYEE_FILE_CLEAR_ERROR:
      return {
        ...state,
        create_employee_file: {
          ...state.create_employee_file,
          error: null,
          pending: false,
        },
      }
    case DELETE_EMPLOYEE_FILE_PENDING:
      return {
        ...state,
        delete_employee_file: {
          ...state.delete_employee_file,
          pending: true,
        },
      }
    case DELETE_EMPLOYEE_FILE_SUCCESS:
      return {
        ...state,
        delete_employee_file: {
          ...state.delete_employee_file,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_EMPLOYEE_FILE_ERROR:
      return {
        ...state,
        delete_employee_file: {
          ...state.delete_employee_file,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_EMPLOYEE_PHOTO_PENDING:
      return {
        ...state,
        delete_employee_photo: {
          ...state.delete_employee_photo,
          pending: true,
        },
      }
    case DELETE_EMPLOYEE_PHOTO_SUCCESS:
      return {
        ...state,
        delete_employee_photo: {
          ...state.delete_employee_photo,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_EMPLOYEE_PHOTO_ERROR:
      return {
        ...state,
        delete_employee_photo: {
          ...state.delete_employee_photo,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_EMPLOYEE_PHOTO_CLEAR_ERROR:
      return {
        ...state,
        delete_employee_photo: {
          ...state.delete_employee_photo,
          error: null,
        },
      }
    case DELETE_EMPLOYEE_PENDING:
      return {
        ...state,
        delete_employee: {
          ...state.delete_employee,
          pending: true,
        },
      }
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        delete_employee: {
          ...state.delete_employee,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_EMPLOYEE_ERROR:
      return {
        ...state,
        delete_employee: {
          ...state.delete_employee,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions

function getEmployeesTotalPending() {
  return {type: GET_EMPLOYEES_TOTAL_PENDING}
}

function getEmployeesTotalSuccess(payload) {
  return {type: GET_EMPLOYEES_TOTAL_SUCCESS, payload: payload}
}

function getEmployeesTotalError(error) {
  return {type: GET_EMPLOYEES_TOTAL_ERROR, payload: error}
}

function getEmployeePending() {
  return {type: GET_EMPLOYEE_PENDING}
}

function getEmployeeSuccess(payload) {
  return {type: GET_EMPLOYEE_SUCCESS, payload: payload}
}

function getEmployeeError(error) {
  return {type: GET_EMPLOYEE_ERROR, payload: error}
}

export function getEmployeeClearError() {
  return {type: GET_EMPLOYEE_CLEAR_ERROR}
}

function getEmployeeMetaPending() {
  return {type: GET_EMPLOYEE_META_PENDING}
}

function getEmployeeMetaSuccess(payload) {
  return {type: GET_EMPLOYEE_META_SUCCESS, payload: payload}
}

function getEmployeeMetaError(error) {
  return {type: GET_EMPLOYEE_META_ERROR, payload: error}
}

function setEmployeePending() {
  return {type: SET_EMPLOYEE_PENDING}
}

function setEmployeeSuccess(payload) {
  return {type: SET_EMPLOYEE_SUCCESS, payload: payload}
}

function setEmployeeError(error) {
  return {type: SET_EMPLOYEE_ERROR, payload: error}
}

export function setEmployeeClearError() {
  return {type: SET_EMPLOYEE_CLEAR_ERROR}
}

function createEmployeeCustomAttributePending() {
  return {type: CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_PENDING}
}

function createEmployeeCustomAttributeSuccess(payload) {
  return {type: CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_SUCCESS, payload: payload}
}

function createEmployeeCustomAttributeError(error) {
  return {type: CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_ERROR, payload: error}
}

export function createEmployeeCustomAttributeClearError() {
  return {type: CREATE_EMPLOYEE_CUSTOM_ATTRIBUTE_CLEAR_ERROR}
}

function deleteEmployeeCustomAttributePending() {
  return {type: DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_PENDING}
}

function deleteEmployeeCustomAttributeSuccess(payload) {
  return {type: DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_SUCCESS, payload: payload}
}

function deleteEmployeeCustomAttributeError(error) {
  return {type: DELETE_EMPLOYEE_CUSTOM_ATTRIBUTE_ERROR, payload: error}
}

function createEmployeePending() {
  return {type: CREATE_EMPLOYEE_PENDING}
}

function createEmployeeSuccess(payload) {
  return {type: CREATE_EMPLOYEE_SUCCESS, payload: payload}
}

function createEmployeeError(error) {
  return {type: CREATE_EMPLOYEE_ERROR, payload: error}
}

export function createEmployeeClearError() {
  return {type: CREATE_EMPLOYEE_CLEAR_ERROR}
}

function createQuickEmployeePending() {
  return {type: CREATE_QUICK_EMPLOYEE_PENDING}
}

function createQuickEmployeeSuccess(payload) {
  return {type: CREATE_QUICK_EMPLOYEE_SUCCESS, payload: payload}
}

function createQuickEmployeeError(error) {
  return {type: CREATE_QUICK_EMPLOYEE_ERROR, payload: error}
}

export function createQuickEmployeeClearError() {
  return {type: CREATE_QUICK_EMPLOYEE_CLEAR_ERROR}
}

function deleteEmployeePending() {
  return {type: DELETE_EMPLOYEE_PENDING}
}

export function deleteEmployeeSuccess(payload) {
  return {type: DELETE_EMPLOYEE_SUCCESS, payload: payload}
}

function deleteEmployeeError(error) {
  return {type: DELETE_EMPLOYEE_ERROR, payload: error}
}

function getEmployeeFilesPending() {
  return {type: GET_EMPLOYEE_FILES_PENDING}
}

function getEmployeeFilesSuccess(payload) {
  return {type: GET_EMPLOYEE_FILES_SUCCESS, payload: payload}
}

function getEmployeeFilesError(error) {
  return {type: GET_EMPLOYEE_FILES_ERROR, payload: error}
}

export function getEmployeeFilesClearError() {
  return {type: GET_EMPLOYEE_FILES_CLEAR_ERROR}
}

function createEmployeeFilePending() {
  return {type: CREATE_EMPLOYEE_FILE_PENDING}
}

function createEmployeeFileSuccess(payload) {
  return {type: CREATE_EMPLOYEE_FILE_SUCCESS, payload: payload}
}

function createEmployeeFileError(error) {
  return {type: CREATE_EMPLOYEE_FILE_ERROR, payload: error}
}

export function createEmployeeFileClearError() {
  return {type: CREATE_EMPLOYEE_FILE_CLEAR_ERROR}
}

function deleteEmployeeFilePending() {
  return {type: DELETE_EMPLOYEE_FILE_PENDING}
}

function deleteEmployeeFileSuccess(payload) {
  return {type: DELETE_EMPLOYEE_FILE_SUCCESS, payload: payload}
}

function deleteEmployeeFileError(error) {
  return {type: DELETE_EMPLOYEE_FILE_ERROR, payload: error}
}

function deleteEmployeePhotoPending() {
  return {type: DELETE_EMPLOYEE_PHOTO_PENDING}
}

function deleteEmployeePhotoSuccess(payload) {
  return {type: DELETE_EMPLOYEE_PHOTO_SUCCESS, payload: payload}
}

function deleteEmployeePhotoError(error) {
  return {type: DELETE_EMPLOYEE_PHOTO_ERROR, payload: error}
}

export function deleteEmployeePhotoClearError() {
  return {type: DELETE_EMPLOYEE_PHOTO_CLEAR_ERROR}
}

// Operations

export const getEmployeesTotal = () => (dispatch) => {
  dispatch(getEmployeesTotalPending())
  return axios
    .get(`/api/v1/employees?amount_of=total`)
    .then((res) => {
      dispatch(getEmployeesTotalSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getEmployeesTotalError(error)))
}

export const getEmployee = (id) => (dispatch) => {
  dispatch(getEmployeePending())
  return axios
    .get(`/api/v1/employees/${id}`)
    .then((res) => {
      dispatch(getEmployeeSuccess(res.data))
      return res.data
    })
    .catch((error) => {
      dispatch(getEmployeeError(error))
    })
}

export const getEmployeeMeta = (id) => (dispatch) => {
  dispatch(getEmployeeMetaPending())
  return axios
    .get(`/api/v1/employees?current=${id}`)
    .then((res) => {
      dispatch(getEmployeeMetaSuccess(res.data.meta))
      return res.data.meta
    })
    .catch((error) => dispatch(getEmployeeMetaError(error)))
}

export const getEmployeeFiles = (id) => (dispatch) => {
  dispatch(getEmployeeFilesPending())
  return axios
    .get(`/api/v1/employees/${id}/files`)
    .then((res) => {
      dispatch(getEmployeeFilesSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(getEmployeeFilesError(error)))
}

export const setEmployee = (id, values) => (dispatch) => {
  dispatch(setEmployeePending())
  return axios
    .put(`/api/v1/employees/${id}`, values)
    .then((res) => {
      dispatch(setEmployeeSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(setEmployeeError(error)))
}

export const createEmployee = (values, notify, id) => (dispatch) => {
  dispatch(createEmployeePending())
  return axios
    .post(`/api/v1/employees`, values)
    .then((res) => {
      dispatch(createEmployeeSuccess(res))
      if (notify) {
        dispatch(
          createNotification(id, {
            code: '201',
            message: I18n.t('notificationSuccess.EmployeeCreated', {
              number: res.data.person.full_name,
            }),
            link: `/employees/${res.data.id}`,
          }),
        )
      }
      return res.data
    })
    .catch((error) => {
      dispatch(createEmployeeError(error))
    })
}

export const createQuickEmployee = (values, id) => (dispatch) => {
  dispatch(createQuickEmployeePending())
  return axios
    .post(`/api/v1/data/employees`, values)
    .then((res) => {
      dispatch(createQuickEmployeeSuccess(res))
      dispatch(
        createNotification(id, {
          code: '201',
          message: I18n.t('notificationSuccess.EmployeeCreated', {
            number: res.data.person.full_name,
          }),
          link: `/employees/${res.data.id}`,
        }),
      )

      return res.data
    })
    .catch((error) => {
      dispatch(createQuickEmployeeError(error))
    })
}

export const createEmployeeCustomAttribute = (id, values) => (dispatch) => {
  dispatch(createEmployeeCustomAttributePending())
  return axios
    .post(`/api/v1/employees/${id}/custom_attributes`, values)
    .then((res) => {
      dispatch(createEmployeeCustomAttributeSuccess(res))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.CustomAttributeCreated'))
      return res.data
    })
    .catch((error) => {
      dispatch(createEmployeeCustomAttributeError(error))
    })
}

export const deleteEmployeeCustomAttribute = (id, key) => (dispatch) => {
  dispatch(deleteEmployeeCustomAttributePending())
  return axios
    .delete(`/api/v1/employees/${id}/custom_attributes/${key}`)
    .then((res) => {
      dispatch(deleteEmployeeCustomAttributeSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(deleteEmployeeCustomAttributeError(error))
    })
}

export const createEmployeeFile =
  (id, target, setUploadedFile, notify, userID, tagIds, description, validTo) => (dispatch) => {
    dispatch(createEmployeeFilePending())
    var formData = new FormData()
    var imagefile = target
    formData.append('file', imagefile.files[0])
    formData.append('description', description)
    for (var i = 0; i < tagIds.length; i++) {
      formData.append('tags', tagIds[i])
    }
    if (validTo !== null) {
      formData.append('valid_to', validTo)
    }
    return axios
      .post(`/api/v1/employees/${id}/files`, formData)
      .then((res) => {
        setUploadedFile && setUploadedFile(null)
        dispatch(createEmployeeFileSuccess(res))
        if (notify) {
          dispatch(
            createNotification(userID, {
              code: '201',
              message: I18n.t('notificationSuccess.EmployeeFileCreated', {name: res.data.name}),
              link: `/employees/${id}`,
            }),
          )
        }
        return res.data
      })
      .catch((error) => {
        dispatch(createEmployeeFileError(error))
      })
  }

export const deleteEmployeeFile = (id, file_id, notify, userID) => (dispatch) => {
  dispatch(deleteEmployeeFilePending())
  return axios
    .delete(`/api/v1/employees/${id}/files/${file_id}`)
    .then((res) => {
      dispatch(deleteEmployeeFileSuccess(res))
      if (notify) {
        dispatch(
          createNotification(userID, {
            code: '201',
            message: I18n.t('notificationSuccess.EmployeeFileDeleted', {name: res.data}),
          }),
        )
      }
      return res.data
    })
    .catch((error) => {
      dispatch(deleteEmployeeFileError(error))
    })
}

export const deleteEmployeePhoto = (id) => (dispatch) => {
  dispatch(deleteEmployeePhotoPending())
  return axios
    .delete(`/api/v1/employees/${id}/photo`)
    .then((res) => {
      dispatch(deleteEmployeePhotoSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(deleteEmployeePhotoError(error))
    })
}

export const deleteEmployee = (id, userID) => (dispatch) => {
  dispatch(deleteEmployeePending())
  return axios
    .delete(`/api/v1/employees/${id}`)
    .then((res) => {
      dispatch(
        deleteEmployeeSuccess(I18n.t('notificationSuccess.EmployeeDeleted', {number: res.data})),
      )
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.EmployeeDeleted', {number: res.data}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(deleteEmployeeError(error)))
}
