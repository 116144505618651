import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'configureInterfaces.Active',
    accessor: 'active',
    cell: 'Pill',
    label: 'configureInterfaces.Active',
  },
  {
    header: 'TableLabelsCommon.Type',
    accessor: 'config_type',
    label: 'TableLabelsCommon.Type',
  },
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'configureInterfaces.Description',
    label: 'configureInterfaces.Description',
    accessor: 'description',
    cell: 'TwoRowCell',
    secondAccessor: 'config_type',
  },
  {
    header: 'configureInterfaces.LastExecution',
    accessor: 'execution_date',
    cell: 'DateCell',
    label: 'configureInterfaces.LastExecution',
  },
  {
    header: 'ID',
    accessor: 'id',
    label: 'ID',
  },
  {
    header: 'configureInterfaces.StatusDescription',
    accessor: 'status_description',
    label: 'configureInterfaces.StatusDescription',
  },
  {
    header: 'configureInterfaces.Status',
    label: 'configureInterfaces.Status',
    accessor: 'status_name',
    Filter: 'SelectColumnFilter',
    cell: 'StatusPill',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'Actions',
    label: 'Actions',
    accessor: 'name',
    idAccessor: 'id',
    activeAccessor: 'active',
    typeAccessor: 'config_type',
    summaryAccessor: 'summary',
    descriptionAccessor: 'description',
    statusAccessor: 'status_name',
    cell: 'FormEditConfigurationCell',
    noSort: true,
  },
]
export default columnConfig
