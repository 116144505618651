import {useFormContext} from 'react-hook-form'
import {classNames} from '../../../utils/classNames'
import {ExclamationCircleIcon} from '@heroicons/react/outline'
import {useTranslation} from 'react-i18next'
import {getRhfValidations, isFieldRequired} from '../lib/utils'
import InputWrapper from './inputWrapper'

const TextInput = (props) => {
  const {
    defaultValue,
    name,
    label,
    placeholder,
    validations,
    validationType,
    inputType = 'text',
    withRhfValidation,
    disabled,
  } = props
  const {t} = useTranslation()
  const {register, formState, getValues, getFieldState} = useFormContext()
  const error = getFieldState(name, formState).error?.message || ''
  const registerOptions = withRhfValidation ? getRhfValidations(getValues, name, validations) : {}

  return (
    <InputWrapper
      id={name}
      isRequired={isFieldRequired(validations, validationType)}
      label={label}
      error={error}
    >
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          className={classNames(
            error
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
              : 'border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500',
            'block w-full rounded-md disabled:bg-gray-100 disabled:text-gray-500 sm:text-sm',
          )}
          {...register(name, registerOptions)}
          type={inputType}
          placeholder={t(placeholder)}
          id={name}
          data-testid={`input-${name}`}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
    </InputWrapper>
  )
}

export default TextInput
