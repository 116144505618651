import {yupResolver} from '@hookform/resolvers/yup'
import {FormProvider, useForm} from 'react-hook-form'
import {DevTool} from '@hookform/devtools'
import DynamicInput from './DynamicInput'
import {getInputs} from './lib/getInputs'
import {useEffect, useMemo} from 'react'
import SpinnerSVG from '../../assets/Spinner.svg'
import {useTranslation} from 'react-i18next'

export const DynamicRhfForm = ({
  formConfig,
  onSubmit,
  infoMessage,
  isSubmitDisabled,
  noFormBorder,
  noFormReset,
  watchFieldName,
  onFieldChange,
  pending,
}) => {
  const {name, submitButtonClasses, submitButtonText, formTitle, description, fields} = formConfig

  const {t} = useTranslation()
  // const perfTestFields = [...Array(70).keys()].map(key => ({
  //   label: 'New username' + key,
  //   component: 'text',
  //   name: 'username' + key,
  // }))
  const {validationSchema, initialValues} = useMemo(() => getInputs(fields), [fields])
  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {...initialValues},
    mode: 'onTouched',
  })

  const {isValid} = formMethods.formState
  const watchedFiledValue = watchFieldName && formMethods.watch(watchFieldName)

  useEffect(() => {
    if (onFieldChange) {
      onFieldChange(watchedFiledValue)
    }
  }, [watchedFiledValue, onFieldChange])

  useEffect(() => {
    if (formMethods.formState.isSubmitSuccessful && !noFormReset) {
      formMethods.reset(initialValues)
    }
  }, [formMethods.formState, formMethods.reset, formMethods, initialValues, noFormReset])

  return (
    <FormProvider {...formMethods}>
      <DevTool control={formMethods.control} placement="top-left" />
      <form
        // noValidate
        name={name}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        className={
          noFormBorder
            ? 'flex w-full flex-col items-center'
            : 'bg-secondary shadow-primary/30 border-primary flex w-full flex-col items-center gap-3 rounded-md border p-10 pt-5 shadow-2xl'
        }
      >
        {!!formTitle && (
          <h5 className="mb-2 border-b border-white/50 pb-2 text-center text-2xl font-extrabold text-gray-700">
            {formTitle}
          </h5>
        )}
        {!!description && <p className="mt-4 text-gray-700">{description}</p>}

        <section className="mt-3 flex w-full flex-1 flex-col">
          {fields.map((inputProps) => (
            <DynamicInput key={inputProps.name} {...inputProps} />
          ))}
        </section>

        <button
          data-testid={`sbm-btn-${name}`}
          disabled={!isValid || isSubmitDisabled || pending}
          className={
            submitButtonClasses ||
            'mt-4 min-w-[10rem] self-end rounded-md bg-lime-600 px-4 py-2 text-sm font-bold text-white transition-opacity hover:opacity-90 active:opacity-100 disabled:opacity-50'
          }
          type="submit"
        >
          {pending ? (
            <div className="flex items-center">
              <img alt="o" src={SpinnerSVG} className="mr-3 h-5 w-5 animate-spin" />
              <span className="hidden sm:inline">{t('submitting')}</span>
            </div>
          ) : (
            <>{submitButtonText}</>
          )}
        </button>
        {infoMessage}
      </form>
    </FormProvider>
  )
}

// DynamicRhfForm.whyDidYouRender = false
