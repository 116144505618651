export const base64DecToArr = (sBase64, nBlocksSize) => {
  // convert base64 encoded string to Uintarray
  // from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Base64_encoding_and_decoding
  var sB64Enc = sBase64.replace(/[^A-Za-z0-9+/]/g, ''),
    nInLen = sB64Enc.length,
    nOutLen = nBlocksSize
      ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize
      : (nInLen * 3 + 1) >> 2,
    taBytes = new Uint8Array(nOutLen)

  for (var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
    nMod4 = nInIdx & 3
    nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (18 - 6 * nMod4)
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255
      }
      nUint24 = 0
    }
  }
  return taBytes
}

const b64ToUint6 = (nChr) => {
  // convert base64 encoded character to 6-bit integer
  // from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Base64_encoding_and_decoding
  return nChr > 64 && nChr < 91
    ? nChr - 65
    : nChr > 96 && nChr < 123
    ? nChr - 71
    : nChr > 47 && nChr < 58
    ? nChr + 4
    : nChr === 43
    ? 62
    : nChr === 47
    ? 63
    : 0
}

export const takePhotoSuccessCanvas = (canvasRef) => {
  let ctx = canvasRef.getContext('2d')
  ctx.beginPath()
  ctx.fillStyle = 'rgba(225,225,225,0.3)'
  ctx.fillRect(0, 0, canvasRef.width, canvasRef.height)
  ctx.stroke()
}

export const takePhotoErrorCanvas = (canvasRef) => {
  let ctx = canvasRef.getContext('2d')
  ctx.beginPath()
  ctx.fillStyle = 'rgba(225,0,0,0.3)'
  ctx.fillRect(0, 0, canvasRef.width, canvasRef.height)
  ctx.stroke()
}

export const drawImageOnCanvas = (canvasRef, src) => {
  const ctx = canvasRef.getContext('2d')
  ctx.clearRect(0, 0, canvasRef.width, canvasRef.height)
  let imageObj = new Image()
  imageObj.src = src
  imageObj.onload = function () {
    ctx.drawImage(imageObj, 0, 0, 250, 320)
  }
}

export const drawBlobOnCanvas = (canvasRef, blob) => {
  if (canvasRef && blob) {
    const ctx = canvasRef.getContext('2d')
    ctx.clearRect(0, 0, canvasRef.width, canvasRef.height)
    let imageObj = new Image()
    imageObj.src = URL.createObjectURL(blob)
    imageObj.onload = function () {
      ctx.drawImage(imageObj, 0, 0, 250, 320)
    }
  }
}

export const drawImageOnCanvasFitting = (canvasRef, src) => {
  const ctx = canvasRef.getContext('2d')
  ctx.clearRect(0, 0, canvasRef.width, canvasRef.height)
  let imageObj = new Image()
  imageObj.src = src
  imageObj.onload = function () {
    fitImageOn(canvasRef, imageObj) //ctx.drawImage(imageObj,0,0,250,320);
  }
  let fitImageOn = (canvasRef, imageObj) => {
    ctx.clearRect(0, 0, canvasRef.width, canvasRef.height)
    var imageDimensionRatio = imageObj.width / imageObj.height
    var canvasDimensionRatio = canvasRef.width / canvasRef.height
    var renderableHeight, renderableWidth, xStart, yStart
    if (imageDimensionRatio < canvasDimensionRatio) {
      renderableHeight = canvasRef.height
      renderableWidth = imageObj.width * (renderableHeight / imageObj.height)
      xStart = (canvasRef.width - renderableWidth) / 2
      yStart = 0
    } else if (imageDimensionRatio > canvasDimensionRatio) {
      renderableWidth = canvasRef.width
      renderableHeight = imageObj.height * (renderableWidth / imageObj.width)
      xStart = 0
      yStart = (canvasRef.height - renderableHeight) / 2
    } else {
      renderableHeight = canvasRef.height
      renderableWidth = canvasRef.width
      xStart = 0
      yStart = 0
    }
    ctx.drawImage(imageObj, xStart, yStart, renderableWidth, renderableHeight)
  }
}

export const drawRoiOnCanvas = (canvas, ctx, predictions, portraitMode, setDisableButtons) => {
  const equalizer = portraitMode ? 60 : 0
  const start = predictions.topLeft
  const end = predictions.bottomRight
  const size = [end[0] - start[0], end[1] - start[1]]

  // ROI_RECTANGLE
  let x = start[0] - 17 // x axis of the topLeft of the prediction rectangle -13 to center the rectangle around the face
  let y = start[1] // y axis of the topLeft of the prediction rectangle
  let w = size[0] + 50 // make rectangle a little bit bigger
  let h = size[1] + 50

  const xCen = x + w / 2
  const yCen = y + h / 2

  const ROI_SIZE = 0.55

  let roiWidth
  let roiHeight

  if (canvas.width / canvas.height === 45 / 35) {
    roiWidth = h * ROI_SIZE
    roiHeight = w * ROI_SIZE
  } else {
    roiWidth = h * ROI_SIZE
    roiHeight = roiWidth * (45 / 35)
  }

  const hMax = 2 * roiHeight
  const wMax = 2 * roiWidth
  const shift = 0

  x = xCen - roiWidth
  y = yCen - roiHeight + shift
  w = wMax
  h = hMax

  // CENTER POINTS
  let xCenFin = x + w / 2
  let yCenFin = y + h / 2

  //ELLIPSE CENTER
  let majorAxisY = roiHeight * 0.8
  let eCenterY = yCen - (0.6 * majorAxisY) / 4 + shift
  let minorAxisX = roiWidth * 0.7
  let eMinorAxis = minorAxisX * 1.1
  let eCenterX = xCen
  let eMajorAxis = majorAxisY * 1.1
  //let arcCenterX = xCen;
  //let maxFaceHeight = hMax * 0.73;
  //let arcCenterY = yCen + (1.05 * majorAxisY - maxFaceHeight / 2) + shift;
  //let arcMinorAxis = eMinorAxis;
  //let arcMajorAxis = majorAxisY * 0.5;

  // ROI CONDITIONS
  // facial landmarks
  let rightEyeCenter = predictions.landmarks[0]
  let leftEyeCenter = predictions.landmarks[1]
  let noseCenter = predictions.landmarks[2]

  // compute center (x,y) coordinates between the two eyes in the input image
  let eyesCenter = [
    (leftEyeCenter[0] + rightEyeCenter[0]) / 2,
    (leftEyeCenter[1] + rightEyeCenter[1]) / 2,
  ]

  // compute the angle of rotation between the eye centroids
  let dY = rightEyeCenter[1] - leftEyeCenter[1]
  let dX = rightEyeCenter[0] - leftEyeCenter[0]

  let radiansToDegrees = (radians) => {
    let pi = Math.PI
    return radians * (180 / pi)
  }

  let angle_wrt_horizontal_axis = Math.abs(radiansToDegrees(Math.atan2(dY, dX)) - 180)

  //compute the angle of rotation between the mid-eye point and mid-nose point
  let dY_MidEyeNose = noseCenter[1] - eyesCenter[1]
  let dX_MidEyeNose = noseCenter[0] - eyesCenter[0]

  let angleWrtVerticalAxis = Math.abs(
    radiansToDegrees(Math.atan2(dY_MidEyeNose, dX_MidEyeNose)) - 90,
  )
  let notInPosition =
    xCenFin - roiWidth < 0 ||
    xCenFin + roiWidth > canvas.width ||
    yCenFin * (16 / 9 + 4 / 3) - roiHeight - 500 < 0 ||
    yCenFin + roiHeight > canvas.height ||
    10 > angle_wrt_horizontal_axis > 350 ||
    angleWrtVerticalAxis > 15 ||
    10 > angle_wrt_horizontal_axis > 350 ||
    angleWrtVerticalAxis > 15
  setDisableButtons(notInPosition)
  ctx.beginPath()
  ctx.lineWidth = 2
  ctx.clearRect(0, 0, w, h)
  ctx.strokeStyle = notInPosition ? '#FF0000' : '#00FF00' // red or green;
  ctx.strokeRect(x + equalizer, y + equalizer, w - 2 * equalizer, h - 2 * equalizer)
  ctx.ellipse(
    eCenterX,
    eCenterY,
    eMinorAxis - equalizer,
    eMajorAxis - equalizer,
    Math.PI,
    0,
    2 * Math.PI,
  )
  ctx.stroke()
  ctx.beginPath()
  ctx.strokeStyle = '#A9A9A9'
  ctx.moveTo(eCenterX, eCenterY - 20)
  ctx.lineTo(eCenterX, eCenterY + 20)
  ctx.moveTo(eCenterX - 20, eCenterY)
  ctx.lineTo(eCenterX + 20, eCenterY)
  ctx.stroke()
}
