import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import axios from '../../services/api'

import 'reactflow/dist/style.css'
import DependencyView from './DependancyView'
import {childrenAccessorIdentities, parentAccessorIdentities} from './consts'

const IdentityDependencies = () => {
  const {t} = useTranslation()
  const {success: currentResource} = useSelector((state) => state.identity.get_identity)
  const [parents, setParents] = useState()
  const [children, setChildren] = useState()
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchMeta = async (id) => {
      try {
        const {data} = await axios.get(`/api/v1/identities/${id}/children`)
        setChildren(data.results)
      } catch (error) {
        setError(true)
      }
    }

    if (currentResource?.id) {
      fetchMeta(currentResource?.id).then()
    }
  }, [currentResource])

  useEffect(() => {
    const fetchMeta = async (id) => {
      try {
        const {data} = await axios.get(`/api/v1/identities/${id}/parents`)

        setParents(data.results)
      } catch (error) {
        setError(true)
      }
    }

    if (currentResource?.id) {
      fetchMeta(currentResource?.id).then()
    }
  }, [currentResource])

  return (
    <DependencyView
      error={error}
      childrenAccessor={childrenAccessorIdentities}
      children={children}
      parents={parents}
      text={t('resource.DependenciesAll')}
      parentAccessor={parentAccessorIdentities}
      root={currentResource}
      hideOnMobile
    />
  )
}

export default IdentityDependencies
