import {Disclosure} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/solid'
import {classNames} from '../utils/classNames'

export default function Collapse(props) {
  return (
    <Disclosure as="div" className="pt-6">
      {({open}) => (
        <>
          <dt className="text-sm">
            <Disclosure.Button className="flex w-full items-start text-left text-gray-400">
              <span className="font-medium text-gray-900">{props.title}</span>
              <span className="ml-2 flex h-6 items-center">
                <ChevronDownIcon
                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
            <p className="text-sm text-gray-500">{props.children}</p>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
