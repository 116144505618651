import Collapse from '../Collapse'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import FormAddCompany from '../forms/FormAddCompany'
import Table from '../table'
import {getCompanies, getCompaniesCLearError} from '../../stores/companies'
import {useSelector, useDispatch} from 'react-redux'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'
import {useTableColumns} from '../../hooks/useTableColumns'

export default function CompanyWrapper() {
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const companies = useSelector((state) => state.companies.get_companies.success)
  const pending = useSelector((state) => state.companies.get_companies.pending)
  const error = useSelector((state) => state.companies.get_companies.error)
  const createCompany = useSelector((state) => state.companies.create_company.success)
  const updateCompany = useSelector((state) => state.companies.set_company.success)
  const delete_company = useSelector((state) => state.companies.delete_company.success)
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch, createCompany, updateCompany, delete_company, error?.message])

  const columns = useTableColumns(TableId.COMPANY)
  return (
    <>
      <Collapse title={t('addCompanyForm.AddCompanyTitle')}>
        <FormAddCompany
          userID={userID}
          createCompanyLocations={permissions.createCompanyLocations}
        />
      </Collapse>
      <>
        <EditTableWrapper tableId={TableId.COMPANY} />
        <Table
          columns={columns}
          data={companies}
          pending={pending}
          error={error}
          setError={getCompaniesCLearError}
        />
      </>
    </>
  )
}
