import {useEffect} from 'react'
import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import FormEditUserSettings from '../components/forms/FormEditUserSettings'
import {getSelectedPrinter} from '../stores/printers'
import UserHeader from '../components/UserHeader'
import FormAssignDefaultCompany from '../components/forms/FormAssignDefaultCompany'
import InfoBox from '../components/InfoBox'
import {useCompanyOptions} from '../hooks/useCompanies'

export default function UserScreen() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  // user states
  const user = useSelector((state) => state.auth.fetchUserSuccess)

  // printer states
  const userPrinterList = useSelector((state) => state.printers.get_selected_printer.success)

  const attach_printer_pending = useSelector(
    (state) => state.printers.attach_printer_to_user.pending,
  )

  const {defaultCompany} = useCompanyOptions()

  useEffect(() => {
    dispatch(getSelectedPrinter(user.id))
  }, [dispatch, attach_printer_pending, user])

  return (
    <>
      <UserHeader user={user} />
      <Collapse title={t('setDefaultCompany')}>
        <InfoBox message={t('defaultCompany') + (defaultCompany?.name || '')} />
        <FormAssignDefaultCompany />
      </Collapse>
      <Collapse title={t('userInfo.Title')}>
        <FormEditUserSettings data={user} userPrinterList={userPrinterList} />
      </Collapse>
    </>
  )
}
