import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {applyActionOnCard, applyActionOnCardClearError} from '../stores/cards'
import {
  AnnotationIcon,
  ClockIcon,
  PrinterIcon,
  LockClosedIcon,
  LockOpenIcon,
  BanIcon,
} from '@heroicons/react/outline'
import {classNames} from '../utils/classNames'
import ErrorModal from './ErrorModal'

export default function IdentityCardFooterActions({identityCard, selected_printer}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const apply_action_card_pending = useSelector((state) => state.cards.apply_action_card.pending)
  const apply_action_card_error = useSelector((state) => state.cards.apply_action_card.error)

  const functionalities = [
    {
      functionName:
        selected_printer === null
          ? t('employeeFunctionalitySidebar.SelectPrinter')
          : identityCard.card.state.name === 'PRODUCTION_FAILED'
          ? t('employeeFunctionalitySidebar.RetryProduction')
          : t('employeeFunctionalitySidebar.Produce'),
      icon:
        selected_printer === null
          ? AnnotationIcon
          : identityCard.card.state.name === 'PRODUCTION_FAILED'
          ? ClockIcon
          : PrinterIcon,
      functionBody: function () {
        dispatch(
          applyActionOnCard(identityCard.card.url, {action: 'request_production'}, false, userID),
        )
      },
      disabled:
        (identityCard.card.state.name !== 'PRODUCTION_FAILED' &&
          identityCard.card.state.name !== 'UNPRODUCED') ||
        selected_printer === null ||
        identityCard.card.state.name === 'PRODUCED' ||
        identityCard.card.state.name === 'REQUESTED' ||
        apply_action_card_pending ||
        !permissions.produceCard,
      error: apply_action_card_error,
    },
    // {
    //   functionName: t('employeeFunctionalitySidebar.Validate4eye'),
    //   icon: 'n-4-square',
    //   functionBody: function () {
    //     props.applyActionOnCard(
    //       identityCard.card.url,
    //       {action: 'four_eyes_validate'},
    //       true,
    //       userID,
    //     )
    //   },
    //   disabled:
    //     identityCard.card.state.name !== 'FOUR_EYES_VALIDATION_REQUIRED' ||
    //     identityCard.card.state.name === 'PRODUCED' ||
    //     identityCard.card.state.name === 'REQUESTED' ||
    //     apply_action_card_pending, // should be a permission NOT USED
    // },
    {
      functionName: t('employeeFunctionalitySidebar.BlockTemporary'),
      icon: LockClosedIcon,
      functionBody: function () {
        dispatch(applyActionOnCard(identityCard.card.url, {action: 'block'}, false, userID))
      },
      disabled:
        identityCard.card.state.name !== 'ACTIVE' ||
        identityCard.card.state.name === 'PRODUCED' ||
        identityCard.card.state.name === 'REQUESTED' ||
        apply_action_card_pending ||
        !permissions.blockCard,
      error: apply_action_card_error,
    },
    {
      functionName: t('employeeFunctionalitySidebar.RevokeTempBlock'),
      icon: LockOpenIcon,
      functionBody: function () {
        dispatch(applyActionOnCard(identityCard.card.url, {action: 'unblock'}, false, userID))
      },
      disabled:
        identityCard.card.state.name !== 'BLOCKED' ||
        identityCard.card.state.name === 'PRODUCED' ||
        identityCard.card.state.name === 'REQUESTED' ||
        apply_action_card_pending ||
        !permissions.updateCard,
      error: apply_action_card_error,
    },
    {
      functionName: t('employeeFunctionalitySidebar.BlockPermanently'),
      icon: BanIcon,
      functionBody: function () {
        dispatch(applyActionOnCard(identityCard.card.url, {action: 'destroy'}, false, userID))
      },
      disabled:
        identityCard.card.state.name === 'PRODUCED' ||
        identityCard.card.state.name === 'REQUESTED' ||
        apply_action_card_pending ||
        !permissions.deleteCard,
      error: apply_action_card_error,
    },
  ]
  return (
    <>
      <div className="flex flex-shrink flex-nowrap">
        <div className="-mx-4 flex  justify-between sm:mx-auto">
          {functionalities.map((item) => (
            <div key={item.functionName}>
              <button
                onClick={() => {
                  item.functionBody(identityCard.id)
                }}
                disabled={item.disabled}
              >
                <div className="m-2 flex flex-col items-center">
                  <item.icon
                    className={classNames(
                      item.disabled
                        ? 'cursor-not-allowed text-red-200'
                        : 'cursor-pointer text-gray-500',
                      'm-4 h-6 w-6 flex-shrink-0',
                    )}
                  />
                  <p className="text-wrap invisible text-xs sm:visible">{item.functionName}</p>
                </div>
              </button>
              {item.error ? (
                <ErrorModal
                  error={item.error}
                  setError={() => dispatch(applyActionOnCardClearError())}
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
