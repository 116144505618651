import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/solid'
import {classNames} from '../utils/classNames'
import {useState} from 'react'
import {Modal, ModalContentsBase, ModalOpenButton} from './Modal'
import {QuestionMarkCircleIcon} from '@heroicons/react/outline'
import InfoBox from './InfoBox'
import {useTranslation} from 'react-i18next'

const MessageTypeMapper = {
  error: {
    bgColor: 'bg-red-50',
    textColor: 'text-red-800',
    closeButtonStyle: 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600',
    icon: <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
    infoMessageTitle: 'ErrorMessage',
  },
  success: {
    bgColor: 'bg-green-50',
    textColor: 'text-green-800',
    closeButtonStyle: 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600',
    icon: <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />,
    infoMessageTitle: 'SuccessMessage',
  },
  info: {
    bgColor: 'bg-yellow-50',
    textColor: 'text-gray-800',
    closeButtonStyle: 'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600',
    icon: <InformationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />,
    infoMessageTitle: 'InfoMessage',
  },
}
export default function InfoMessage({message, type, onClose, infoText}) {
  const {t} = useTranslation()
  const messageType = type && MessageTypeMapper[type] ? type : 'info'
  const {bgColor, icon, textColor, closeButtonStyle, infoMessageTitle} =
    MessageTypeMapper[messageType]

  const [close, setClose] = useState(false)

  if (close) {
    return null
  }

  return (
    <div className={classNames('flex w-full items-center justify-between rounded-md p-2', bgColor)}>
      <div className="flex-shrink-0">{icon}</div>
      <div className="ml-3 flex grow items-center justify-start">
        <p className={classNames('ml-3 text-sm font-medium', textColor)}>{message}</p>
        {!!infoText && (
          <Modal>
            <ModalOpenButton>
              <QuestionMarkCircleIcon className="ml-1 h-4 w-4 text-blue-400 hover:text-blue-500" />
            </ModalOpenButton>
            <ModalContentsBase title={t(infoMessageTitle)}>
              <InfoBox message={infoText} />
            </ModalContentsBase>
          </Modal>
        )}
      </div>
      <button
        type="button"
        className={classNames(
          'inline-flex rounded-md  p-1.5  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50',
          closeButtonStyle,
        )}
        onClick={() => {
          setClose(true)
          onClose && onClose()
        }}
      >
        <span className="sr-only">Dismiss</span>
        <XIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  )
}
