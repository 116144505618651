export interface TableInitialState {
  id: TableId
  columns: string[]
  hiddenColumns: string[]
  update?: RequestState
}

export enum RequestState {
  PENDING = 'pending',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum TableId {
  PERSON = 'person',
  CARD = 'card',
  EMPLOYEE = 'employee',
  INVENTORY = 'inventory',
  RESOURCE = 'resource',
  RESOURCE_CHILDREN = 'resourceChildren',
  RESOURCE_PARENTS = 'resourceParents',
  VISITOR = 'visitor',
  CARDS_HISTORY = 'cardsHistory',
  COMPANY = 'company',
  CONFIGURATION = 'configuration',
  EXECUTION = 'execution',
  FILES_VISITOR = 'filesVisitor',
  FILES_EMPLOYEE = 'filesEmployee',
  FORMS = 'forms',
  IDENTITY_PROVIDER = 'identityProvider',
  INVENTORY_HISTORY = 'inventoryHistory',
  INVENTORY_TYPE = 'inventoryType',
  PRINTER = 'printer',
  RESOURCE_TYPE = 'resourceType',
  SUBSCRIPTION = 'subscription',
  USER = 'user',
  WORKFLOWS = 'workflows',
  MEETING = 'meeting',
  TODOS = 'toDos',
  RESOURCES_EMPLOYEE = 'resourcesEmployee',
  INVENTORIES_EMPLOYEE = 'inventoriesEmployee',
  INVENTORIES_VISITOR = 'inventoriesVisitor',
  RESOURCES_IDENTITIES = 'resourcesIdentities',
  IDENTITY = 'identity',
  IDENTITY_MEMBERS = 'identityMembers',
  IDENTITY_CHILDREN = 'identityChildren',
  IDENTITY_PARENTS = 'identityParents',
  IDENTITIES_EMPLOYEE = 'identitiesEmployee',
  IDENTITIES_VISITOR = 'identitiesVisitor',
}
