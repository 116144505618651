import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import ErrorOnView from '../ErrorOnView'
import {InputComponent, InputYupValidationsType} from '../formRendererRHF/types'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  getCompanies,
  getCompaniesCLearError,
  setDefaultCompany,
  setDefaultCompanyClearError,
} from '../../stores/companies'

export default function FormAssignDefaultCompany() {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const {success: get_companies, error: get_companiesError} = useSelector(
    (state) => state.companies.get_companies,
  )

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch, get_companiesError?.message])

  const fields = [
    {
      component: InputComponent.SELECT,
      name: `preferred_company_id`,
      label: 'selectCompany',
      placeholder: 'select',
      options: get_companies?.map((item) => ({
        value: item.id,
        label: item.name,
      })),
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
  ]

  const handleSubmit = (values) => {
    dispatch(setDefaultCompany(values, true))
  }

  const {error, pending} = useSelector((state) => state.companies.set_default_company)

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-set-default-company',
          submitButtonText: t('submit'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        isSubmitDisabled={pending}
        infoMessage={<Notification error={error} setError={setDefaultCompanyClearError} />}
      />
      {!!get_companiesError && (
        <ErrorOnView error={get_companiesError} setError={getCompaniesCLearError} />
      )}
    </>
  )
}
