import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import FormAddResource from '../components/forms/FormAddResource'
import ServerSideTable from '../components/table/ServerSideTable'
import {RESOURCE_TABLE} from '../utils/formViews'
import WorkflowForms from '../components/WorkflowForms'
import {useTableColumns} from '../hooks/useTableColumns'
import {TableId} from '../stores/types/tables'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'

export default function ResourceTableScreen() {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const deleteResourceAction = useSelector(
    (state) => state.access_profiles.delete_access_profile.success,
  )
  const addResourceAction = useSelector(
    (state) => state.access_profiles.create_access_profile.success,
  )
  const columns = useTableColumns(TableId.RESOURCE)

  return (
    <>
      {permissions.createAccessProfile ? (
        <Collapse title={t('addResourceForm.AddResourceTitle')}>
          <FormAddResource />
        </Collapse>
      ) : null}
      <WorkflowForms renderOnUi={RESOURCE_TABLE} />
      {permissions.listAccessProfiles ? (
        <>
          <EditTableWrapper tableId={TableId.RESOURCE} />
          <ServerSideTable
            columns={columns}
            entityName="access_profiles"
            deleteAction={deleteResourceAction}
            addAction={addResourceAction}
            rowUrlAccessor={'id'}
          />
        </>
      ) : null}
    </>
  )
}
