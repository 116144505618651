import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'InventoryHistoryInfo.AssignedTo',
    label: 'InventoryHistoryInfo.AssignedTo',
    accessor: 'assigned_to',
    cell: 'LinkForwardCell',
    secondAccessor: 'assigned_to_url',
  },
  {
    header: 'employeeInventory.Number',
    label: 'employeeInventory.Number',
    accessor: 'inventory.inventory_number',
  },
  {
    header: 'employeeInventory.Color',
    accessor: 'inventory.color',
    label: 'employeeInventory.Color',
  },
  {
    header: 'employeeInventory.Manufacturer',
    accessor: 'inventory.manufacturer',
    label: 'employeeInventory.Manufacturer',
  },
  {
    header: 'employeeInventory.Model',
    label: 'employeeInventory.Model',
    accessor: 'inventory.model',
  },
  {
    header: 'TableLabelsCommon.Owner',
    label: 'TableLabelsCommon.Owner',
    accessor: 'inventory.owner',
  },
  {
    header: 'employeeInventory.Type',
    label: 'employeeInventory.Type',
    accessor: 'inventory.type.name',
  },
  {
    header: 'TableLabelsCommon.Notes',
    label: 'TableLabelsCommon.Notes',
    accessor: 'notes',
  },
  {
    header: 'InventoryHistoryInfo.RelatedTo',
    label: 'InventoryHistoryInfo.RelatedTo',
    accessor: 'related_to',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    label: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
  },
  {
    header: 'employeeInventory.From',
    label: 'employeeInventory.From',
    accessor: 'valid_from',
    cell: 'DateCell',
  },
  {
    header: 'employeeInventory.To',
    label: 'employeeInventory.To',
    accessor: 'valid_to',
    cell: 'DateCell',
  },
  {
    header: 'employeeInventory.Actions',
    label: 'employeeInventory.Actions',
    accessor: 'id',
    cell: 'InventoriesVisitorActionsCell',
    noSort: true,
  },
]
export default columnConfig
