export const getAllValuesByKeyName = (obj, searchKey, results = []) => {
  const r = results
  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    if (key === searchKey && typeof value !== 'object') {
      r.push(value)
    } else if (typeof value === 'object') {
      getAllValuesByKeyName(value, searchKey, r)
    }
  })
  return r
}

export const getDuplicatesByKeyValue = (obj, keyName) => {
  const names = getAllValuesByKeyName(obj, keyName)

  return getDuplicatesFromArray(names)
}

export const getDuplicatesFromArray = (arr) =>
  arr.filter((currentValue, currentIndex) => arr.indexOf(currentValue) !== currentIndex)

export const parseJSON = (inputString, fallback, callback) => {
  if (inputString?.trim()) {
    try {
      return JSON.parse(inputString)
    } catch (e) {
      callback && callback({response: {data: {error_custom: e.toString()}}})
      return fallback
    }
  }
}

export const resolvePath = (object, path, defaultValue) =>
  path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object)

export const removeLastSymbol = (str, symbol) =>
  str.indexOf(symbol, str.length - 1) > 0 ? str.slice(0, -1) : str
