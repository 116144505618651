import Collapse from './Collapse'
import {InfoBoxFetchTodoInfo} from './InfoBoxFetch'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getMyTodos, postTodosWebhook, postTodosWebhookClearError} from '../stores/workflows'
import {DynamicRhfForm} from './formRendererRHF/DynamicRhfForm'
import {parseJSON} from '../utils/helpers'
import Notification from './Notification'

export default function Tasks() {
  const dispatch = useDispatch()

  const postTodosWebhookPending = useSelector((state) => state.workflows.post_todos_webhook.pending)
  const postTodosWebhookSuccess = useSelector((state) => state.workflows.post_todos_webhook.success)
  const postTodosWebhookError = useSelector((state) => state.workflows.post_todos_webhook.error)

  const todos = useSelector((state) => state.workflows.get_my_todos.success)
    //filter the todos that has a form
    .filter((todo) => todo.form !== null) // TODO: could render_on_ui be null?
    // form json object is deeply nested so destructure it properly before returning any jsx (otherwise form_json.formTitle do not get passed!)
    .map(({webhook, id, custom_attributes, form: {form_json}}) => {
      // fetched form_json is a string and has lots of "\" characters (formatted with RFC 8259 compact). So replace "\" and parse it.
      form_json = parseJSON(form_json.replace(/\\/g, ''))
      return (
        <Collapse key={id} title={form_json.formTitle}>
          <>
            <InfoBoxFetchTodoInfo id={id} custom_attributes={custom_attributes} />
            <DynamicRhfForm
              formConfig={form_json}
              onSubmit={(values) => {
                values.todo_id = id // add the id
                dispatch(postTodosWebhook(values, webhook))
              }}
              pending={!!postTodosWebhookPending}
              infoMessage={
                <Notification
                  error={postTodosWebhookError}
                  setError={() => dispatch(postTodosWebhookClearError())}
                />
              }
            />
          </>
        </Collapse>
      )
    })

  // get todos
  useEffect(() => {
    dispatch(getMyTodos())
  }, [dispatch, postTodosWebhookSuccess])
  console.log('postTodoWebHookSuccess', postTodosWebhookSuccess)
  return <>{todos}</>
}
