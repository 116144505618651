import Collapse from '../Collapse'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import FormAddConfiguration from '../forms/FormAddConfiguration'
import {useDispatch, useSelector} from 'react-redux'
import Table from '../table'
import {getAllConfigs, getAllConfigsClearError, updateConfigClearError} from '../../stores/configs'
import {validateCsvClearError, exportCsvClearError} from '../../stores/import_csv'
import ErrorModal from '../ErrorModal'
import ProgressModal from '../ProgressModal'
import {useTableColumns} from '../../hooks/useTableColumns'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'

export default function ConfigurationWrapper() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const configurations = useSelector((state) => state.configs.get_all_configs.success)
  const pending = useSelector((state) => state.configs.get_all_configs.pending)
  const error = useSelector((state) => state.configs.get_all_configs.error)

  const update_config_success = useSelector((state) => state.configs.update_config.success)
  const update_config_error = useSelector((state) => state.configs.update_config.error)
  const validate_and_store_config_success = useSelector(
    (state) => state.configs.validate_and_store_config.success,
  )
  const validate_csv_success = useSelector((state) => state.import_csv.validate_csv.success)
  const export_csv_success = useSelector((state) => state.import_csv.export_csv.success)

  const validate_csv_pending = useSelector((state) => state.import_csv.validate_csv.pending)
  const export_csv_pending = useSelector((state) => state.import_csv.export_csv.pending)

  const validate_csv_error = useSelector((state) => state.import_csv.validate_csv.error)
  const export_csv_error = useSelector((state) => state.import_csv.export_csv.error)
  const delete_config_success = useSelector((state) => state.configs.delete_config.success)
  const delete_config_file_success = useSelector(
    (state) => state.configs.delete_config_file.success,
  )

  useEffect(() => {
    dispatch(getAllConfigs())
  }, [
    dispatch,
    update_config_success,
    validate_and_store_config_success,
    delete_config_success,
    validate_csv_success,
    export_csv_success,
    error?.message,
    delete_config_file_success,
  ])

  const columns = useTableColumns(TableId.CONFIGURATION)
  return (
    <>
      <Collapse title={t('configureInterfaces.CreateInterface')}>
        <FormAddConfiguration />
      </Collapse>
      <>
        <EditTableWrapper tableId={TableId.CONFIGURATION} />
        <Table
          columns={columns}
          data={configurations}
          pending={pending}
          error={error}
          setError={getAllConfigsClearError}
        />
        {validate_csv_error ? (
          <ErrorModal
            error={validate_csv_error}
            setError={() => dispatch(validateCsvClearError())}
          />
        ) : null}
        {export_csv_error ? (
          <ErrorModal error={export_csv_error} setError={() => dispatch(exportCsvClearError())} />
        ) : null}
        {update_config_error ? (
          <ErrorModal
            error={update_config_error}
            setError={() => dispatch(updateConfigClearError())}
          />
        ) : null}
        {validate_csv_pending || export_csv_pending ? (
          <ProgressModal
            title={t('configureInterfaces.Execution')}
            message={t('configureInterfaces.ExecutionMessage')}
          />
        ) : null}
      </>
    </>
  )
}
