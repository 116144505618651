import {useSelector, useDispatch} from 'react-redux'
import {useEffect} from 'react'
import {getVisitation} from '../stores/visitations'
import FormEditMeeting from '../components/forms/FormEditMeeting'
import {useTranslation} from 'react-i18next'
import {useParams, useNavigate} from 'react-router-dom'

export default function MeetingScreen() {
  let {id} = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const {success: meeting, error} = useSelector((state) => state.visitations.get_visitation)

  useEffect(() => {
    if (error?.response?.status === 404) {
      navigate('/not_found/', {replace: true})
    }
  }, [error?.response?.status, navigate])

  useEffect(() => {
    dispatch(getVisitation(id))
  }, [dispatch, id])

  return (
    <>
      {meeting === null ? ( // TODO: why is this needed I have no idea. issue #68 I don't control for this anywhere else in the app
        <div>{t('addVisitationForm.VisitationLoading')}</div>
      ) : (
        <FormEditMeeting data={meeting} />
      )}
    </>
  )
}
