import axios from '../services/api'
import {createNotificationOnClient} from './notifications'

// Types
export const FORGOT_PASSWORD_PENDING = 'idfunctiondb/forgot_password/FORGOT_PASSWORD_PENDING'
export const FORGOT_PASSWORD_SUCCESS = 'idfunctiondb/forgot_password/FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'idfunctiondb/forgot_password/FORGOT_PASSWORD_ERROR'
export const FORGOT_PASSWORD_CLEAR_ERROR =
  'idfunctiondb/forgot_password/FORGOT_PASSWORD_CLEAR_ERROR'

export const REQUEST_NEW_FORGOT_PASSWORD_CODE_PENDING =
  'idfunctiondb/forgot_password/REQUEST_NEW_FORGOT_PASSWORD_CODE_PENDING'
export const REQUEST_NEW_FORGOT_PASSWORD_CODE_SUCCESS =
  'idfunctiondb/forgot_password/REQUEST_NEW_FORGOT_PASSWORD_CODE_SUCCESS'
export const REQUEST_NEW_FORGOT_PASSWORD_CODE_ERROR =
  'idfunctiondb/forgot_password/REQUEST_NEW_FORGOT_PASSWORD_CODE_ERROR'
export const REQUEST_NEW_FORGOT_PASSWORD_CODE_CLEAR_ERROR =
  'idfunctiondb/forgot_password/REQUEST_NEW_FORGOT_PASSWORD_CODE_CLEAR_ERROR'

export const CHANGE_PASSWORD_PENDING = 'idfunctiondb/forgot_password/CHANGE_PASSWORD_PENDING'
export const CHANGE_PASSWORD_SUCCESS = 'idfunctiondb/forgot_password/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'idfunctiondb/forgot_password/CHANGE_PASSWORD_ERROR'
export const CHANGE_PASSWORD_CLEAR_ERROR =
  'idfunctiondb/forgot_password/CHANGE_PASSWORD_CLEAR_ERROR'

// Initial
const initialState = {
  forgot_password: {
    success: null,
    success_info: {
      code: null,
      message: '',
    },
    pending: false,
    error: null,
  },
  request_new_forgot_password_code: {
    success: null,
    success_info: {
      code: null,
      message: '',
    },
    pending: false,
    error: null,
  },

  change_password: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        forgot_password: {
          ...state.forgot_password,
          pending: true,
        },
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgot_password: {
          ...state.forgot_password,
          success: action.payload,
          success_info: {
            code: action.payload.status,
            message: 'notificationSuccess.EmailHasBeenSent',
          },
          pending: false,
        },
      }
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgot_password: {
          ...state.forgot_password,
          error: action.payload,
          pending: false,
        },
      }
    case FORGOT_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        forgot_password: {
          ...state.forgot_password,
          error: null,
          pending: false,
        },
      }
    case REQUEST_NEW_FORGOT_PASSWORD_CODE_PENDING:
      return {
        ...state,
        request_new_forgot_password_code: {
          ...state.request_new_forgot_password_code,
          pending: true,
        },
      }
    case REQUEST_NEW_FORGOT_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        request_new_forgot_password_code: {
          ...state.request_new_forgot_password_code,
          success: action.payload,
          success_info: {
            code: action.payload.status,
            message: 'notificationSuccess.EmailHasBeenSent',
          },
          pending: false,
        },
      }
    case REQUEST_NEW_FORGOT_PASSWORD_CODE_ERROR:
      return {
        ...state,
        request_new_forgot_password_code: {
          ...state.request_new_forgot_password_code,
          error: action.payload,
          pending: false,
        },
      }
    case REQUEST_NEW_FORGOT_PASSWORD_CODE_CLEAR_ERROR:
      return {
        ...state,
        request_new_forgot_password_code: {
          ...state.request_new_forgot_password_code,
          error: null,
          pending: false,
        },
      }
    case CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        change_password: {
          ...state.change_password,
          pending: true,
        },
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        change_password: {
          ...state.change_password,
          success: action.payload,
          pending: false,
        },
      }
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        change_password: {
          ...state.change_password,
          error: action.payload,
          pending: false,
        },
      }
    case CHANGE_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        change_password: {
          ...state.change_password,
          error: null,
        },
      }
    default:
      return state
  }
}

// Actions
function forgotPasswordPending() {
  return {type: FORGOT_PASSWORD_PENDING}
}
function forgotPasswordSuccess(payload) {
  return {type: FORGOT_PASSWORD_SUCCESS, payload: payload}
}
function forgotPasswordError(error) {
  return {type: FORGOT_PASSWORD_ERROR, payload: error}
}
export function forgotPasswordClearError() {
  return {type: FORGOT_PASSWORD_CLEAR_ERROR}
}

function requestNewForgotPasswordPending() {
  return {type: REQUEST_NEW_FORGOT_PASSWORD_CODE_PENDING}
}
function requestNewForgotPasswordSuccess(payload) {
  return {type: REQUEST_NEW_FORGOT_PASSWORD_CODE_SUCCESS, payload: payload}
}
function requestNewForgotPasswordError(error) {
  return {type: REQUEST_NEW_FORGOT_PASSWORD_CODE_ERROR, payload: error}
}
export function requestNewForgotPasswordClearError() {
  return {type: REQUEST_NEW_FORGOT_PASSWORD_CODE_CLEAR_ERROR}
}

function changePasswordPending() {
  return {type: CHANGE_PASSWORD_PENDING}
}
function changePasswordSuccess(payload) {
  return {type: CHANGE_PASSWORD_SUCCESS, payload: payload}
}
function changePasswordError(error) {
  return {type: CHANGE_PASSWORD_ERROR, payload: error}
}
export function changePasswordClearError() {
  return {type: CHANGE_PASSWORD_CLEAR_ERROR}
}

export const forgotPassword = (username, email) => (dispatch) => {
  dispatch(forgotPasswordPending())
  axios
    .post('/api/v1/auth/forgot_password', {username: username, email: email})
    .then((res) => {
      dispatch(forgotPasswordSuccess(res))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.PasswordRequested'))
    })
    .catch((error) => {
      dispatch(forgotPasswordError(error))
    })
}

export const requestNewForgotPasswordCode = (email) => (dispatch) => {
  dispatch(requestNewForgotPasswordPending())
  axios
    .post('/api/v1/auth/resend_forgot_password_confirmation_code', {email: email})
    .then((res) => {
      dispatch(requestNewForgotPasswordSuccess(res))
      dispatch(
        createNotificationOnClient(
          res.status,
          'notificationSuccess.NewPasswordRequestCodeRequested',
        ),
      )
    })
    .catch((error) => {
      dispatch(requestNewForgotPasswordError(error))
    })
}

export const changePassword = (password, new_password, new_password_repeated) => (dispatch) => {
  dispatch(changePasswordPending())
  axios
    .post('/api/v1/auth/change_password', {
      password: password,
      new_password: new_password,
      new_password_repeated: new_password_repeated,
    })
    .then((res) => {
      dispatch(changePasswordSuccess(res))
    })
    .catch((error) => {
      dispatch(changePasswordError(error))
    })
}
