import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {getIdentitiesFilesTags} from '../../stores/files'
import FormAddFileTag from './FormAddFileTag'
import {iso8061UTC} from '../../utils/datetime'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import AddNewItem from '../AddNewItemRHF'
import {ModalContentsBase} from '../Modal'

export default function FormAssignFile(props) {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const createFileTag_pending = useSelector(
    (state) => state.files.create_identity_files_tags.pending,
  )
  const identities_files_tags = useSelector((state) => state.files.get_identity_files_tags.success)
  const identities_files_tags_error = useSelector(
    (state) => state.files.get_identity_files_tags.error,
  )

  useEffect(() => {
    dispatch(getIdentitiesFilesTags('/api/v1/file_tags'))
  }, [dispatch, createFileTag_pending, identities_files_tags_error?.message])

  const handleSubmit = (values) => {
    dispatch(
      props.addFile(
        props.entityId,
        {files: [values['file'].file]},
        undefined,
        true,
        userID,
        values.tag_ids,
        values.description,
        iso8061UTC(values.valid_to),
      ),
    )
  }

  const fields = [
    {
      component: InputComponent.FILE,
      name: 'file',
      validationType: YupValidationType.OBJECT,
      label: 'uploadFile',
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[4], 'yupError.FileType'],
        },
        {
          type: InputYupValidationsType.FILESIZE,
          params: [3145728, 'yupError.MaxFileSize'],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      label: 'employeeFiles.Tag',
      placeholder: 'employeeFiles.TagPlaceholder',
      options: identities_files_tags.map((file_tag) => ({
        value: file_tag.id,
        label: file_tag.name,
      })),
      name: 'tag_ids',
      isMulti: true,
      validationType: YupValidationType.ARRAY,
      validations: [
        {
          type: InputYupValidationsType.MIN,
          params: [1, 'yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.CUSTOM,
      name: 'add-tag',
      label: 'add-tag',
      renderFn: (
        <AddNewItem
          fieldName="company_id"
          label={t('employeeFiles.AddTag')}
          addComponent={
            <ModalContentsBase title={t('employeeFiles.AddTag')}>
              <FormAddFileTag />
            </ModalContentsBase>
          }
          options={identities_files_tags.map((file_tag) => ({
            value: file_tag.id,
            label: file_tag.name,
          }))}
        />
      ),
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_to',
      label: 'employeeFiles.ValidTo',
      validationType: YupValidationType.DATE,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      name: 'description',
      label: 'employeeFiles.Description',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
        {
          type: InputYupValidationsType.MAX,
          params: [64, 'yupError.MaxCharsAllowed'],
        },
      ],
    },
  ]
  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-assign-file',
          submitButtonText: t('login.Submit'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={props.assignFileToIdentityPending}
        infoMessage={
          <Notification
            error={props.assignFileToIdentityError}
            setError={() => dispatch(props.IdentityCreateFileClearError())}
          />
        }
      />
    </>
  )
}
