import {Formik, Form} from 'formik'
import {useState} from 'react'
import {TextInputLabelOnLeft} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {PencilAltIcon} from '@heroicons/react/solid'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'
import {setInventory, setInventoryClearError} from '../../stores/inventories'

export default function FormEditInventory({data}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const [editable, setEditable] = useState(true)

  const pending = useSelector((state) => state.inventories.set_inventory.pending)
  const error = useSelector((state) => state.inventories.set_inventory.error)

  const initialValues = {
    type: data?.type.name || '',
    model: data?.model || '',
    inventory_number: data?.inventory_number || '',
    color: data?.color || '',
    manufacturer: data?.manufacturer || '',
    owner: data?.owner || '',
  }
  return (
    <>
      <div className="m-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <PencilAltIcon className="h-6 w-6 text-lime-500" onClick={() => setEditable(!editable)} />
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          let InventoryData = {
            model: values.model,
            color: values.color,
            manufacturer: values.manufacturer,
            owner: values.owner,
          }
          dispatch(setInventory(data.id, InventoryData))
          actions.resetForm()
          setEditable(!editable)
        }}
      >
        <Form>
          <TextInputLabelOnLeft
            disabled={true}
            label={t('addInventoryForm.InventoryType')}
            type="text"
            name="type"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('addInventoryForm.InventoryModel')}
            type="text"
            name="model"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('addInventoryForm.InventoryNumber')}
            type="text"
            name="inventory_number"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('addInventoryForm.InventoryColor')}
            type="text"
            name="color"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('addInventoryForm.InventoryManufacturer')}
            type="text"
            name="manufacturer"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('addInventoryForm.InventoryOwner')}
            type="text"
            name="owner"
          />
          <FormikSaveButton
            type="submit"
            testid="form-edit-inventory-submit"
            text={t('addInventoryForm.Save')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(setInventoryClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
