import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import axios from '../../services/api'
import {useSelector} from 'react-redux'
import Notification from '../Notification'

const getFilterQuery = (filterName, values) => {
  return values?.length && filterName
    ? values.reduce((acc, cur, index) => {
        return index ? acc + ` OR ${filterName} eq ${cur}` : acc + `${filterName} eq ${cur}`
      }, '')
    : ''
}
const FormUpdateResource = ({resource, onUpdate, onlyChildren}) => {
  const {t} = useTranslation()
  const [pending, setPending] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [typeFilters, setTypeFilters] = useState('')
  const [currentResource, setCurrentResource] = useState(resource)

  const {updateAccessProfile, createEmployeeLocations} = useSelector(
    (state) => state.auth.fetchUserSuccess.permissions,
  )

  // const resource = useSelector((state) => state.access_profiles.get_access_profile.success)

  const handleSubmit = async (values) => {
    setPending(true)
    setError(null)
    setSuccess(false)
    try {
      delete values.filterType
      delete values.location
      delete values.subsystem_id
      const {data} = await axios.put(`/api/v1/access_profiles/${resource.id}`, values)
      onUpdate()
      setCurrentResource(data)
      setSuccess(true)
    } catch (error) {
      setError(error)
    }
    setPending(false)
  }

  const fields = [
    {
      component: InputComponent.TEXT_INPUT,
      name: 'name',
      label: 'resource.Name',
      defaultValue: currentResource?.name || '',
      disabled: !updateAccessProfile || !!currentResource?.subsystem_id,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      name: 'description',
      label: 'resource.Description',
      defaultValue: currentResource?.description || '',
      disabled: !updateAccessProfile || !!currentResource?.subsystem_id,
    },
    {
      component: InputComponent.ASYNCREACTSELECT,
      name: 'type_id',
      label: 'TableLabelsCommon.Type',
      defaultValue: currentResource?.type?.id || '',
      disabled: !updateAccessProfile || !!currentResource?.subsystem_id,
      url: '/api/v1/access_profiles_types?q=',
      optionValue: 'id',
      optionLabel: 'name',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: t('addEmployee.Domain'),
      placeholder: t('addEmployee.DomainPlaceholder'),
      isMulti: false,
      options: createEmployeeLocations,
      disabled: true,
      defaultValue: createEmployeeLocations.find(
        (option) => option.label === currentResource?.location,
      )?.value,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      name: 'foreign_id',
      label: 'TableLabelsCommon.ForeignId',
      defaultValue: currentResource?.foreign_id || '',
      disabled: true,
    },
    {
      component: InputComponent.TEXT_INPUT,
      name: 'subsystem_id',
      label: 'TableLabelsCommon.SubsystemId',
      defaultValue: currentResource?.subsystem_id || '',
      disabled: true,
    },
  ]

  const fieldsChildren = [
    {
      component: InputComponent.ASYNCREACTSELECT,
      name: 'filterType',
      label: 'TableLabelsCommon.Type',
      isMulti: true,
      url: '/api/v1/access_profiles_types?q=',
      nestedOptions: true,
      optionValue: 'id',
      optionLabel: 'name',
      validationType: YupValidationType.ARRAY,
      disabled: !updateAccessProfile || !!currentResource?.subsystem_id,
    },
    {
      component: InputComponent.ASYNCREACTSELECT,
      name: 'additional_children_resources',
      label: 'resource.Children',
      isMulti: true,
      url: `/api/v1/access_profiles?filters=${typeFilters}&q=`,
      clearOptions: true,
      nestedOptions: true,
      optionValue: 'id',
      optionLabel: 'name',
      validationType: YupValidationType.ARRAY,
      validations: [
        {
          type: InputYupValidationsType.MIN,
          params: [1, 'yupError.ThisFieldIsRequired'],
        },
      ],
      disabled: !updateAccessProfile || !!currentResource?.subsystem_id,
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields: onlyChildren ? fieldsChildren : fields,
          name: 'form-add-employee',
          submitButtonText: t(onlyChildren ? 'formCommon.Add' : 'formCommon.Save'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        isSubmitDisabled={pending || !updateAccessProfile}
        watchFieldName={'filterType'}
        onFieldChange={(value) => {
          setTypeFilters(getFilterQuery('type_id', value))
        }}
        infoMessage={
          <>
            <Notification
              error={error}
              setError={() => {
                setError(null)
                setSuccess(false)
              }}
              success={success}
            />
          </>
        }
      />
    </>
  )
}

export default FormUpdateResource
