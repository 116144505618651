import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'userManagement.Status',
    label: 'userManagement.Status',
    accessor: 'active',
    Filter: 'SelectColumnFilter',
    cell: 'BooleanCell',
    filter: 'boolean',
  },
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },

  {
    header: 'TableLabelsCommon.Email',
    accessor: 'email',
    label: 'TableLabelsCommon.Email',
  },
  {
    header: 'TableLabelsCommon.EmployeeId',
    accessor: 'employee_id',
    label: 'TableLabelsCommon.EmployeeId',
  },
  {
    header: 'ID',
    accessor: 'id',
    label: 'ID',
  },
  {
    header: 'userManagement.LoginCount',
    accessor: 'login_count',
    label: 'userManagement.LoginCount',
  },
  {
    header: 'userManagement.Name',
    label: 'userManagement.Name',
    accessor: 'name',
    cell: 'TwoRowCell',
    secondAccessor: 'email',
  },
  {
    header: 'userManagement.PolicyUrl',
    accessor: 'policy.url',
    label: 'userManagement.PolicyUrl',
  },
  {
    header: 'TableLabelsCommon.PreferredLanguage',
    accessor: 'preferred_language',
    label: 'TableLabelsCommon.PreferredLanguage',
  },
  {
    header: 'TableLabelsCommon.PreferredTimezone',
    accessor: 'preferred_timezone',
    label: 'TableLabelsCommon.PreferredTimezone',
  },
  {
    header: 'userInfo.Printer',
    accessor: 'printer.list',
    label: 'userInfo.Printer',
  },

  {
    header: 'userManagement.Roles',
    label: 'userManagement.Roles',
    accessor: 'RolesFn',
  },
  {
    header: 'TableLabelsCommon.VisitorId',
    accessor: 'visitor_id',
    label: 'TableLabelsCommon.VisitorId',
  },
  {
    header: 'configurePrinters.Actions',
    label: 'configurePrinters.Actions',
    accessor: 'actions',
    idAccessor: 'id',
    cell: 'FormEditUserActionsCell',
    noSort: true,
  },
]
export default columnConfig
