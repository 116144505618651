import {useRef} from 'react'
import {Formik, Form} from 'formik'
import {AsyncSelectInput2} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {
  attachIdentitiesResources,
  attachIdentitiesResourcesClearError,
} from '../../stores/identities'
import {FormikSaveButton} from '../Buttons'
import {DatePickerDefault} from '../DatePickerDefault'
import * as Yup from 'yup'
import {iso8061UTC} from '../../utils/datetime'

export default function FormAssignResource({identityLabel, identity}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)

  const pending = useSelector((state) => state.identities.attach_identity_resources.pending)
  const error = useSelector((state) => state.identities.attach_identity_resources.error)

  const initialValues = {
    valid_from: new Date(),
    valid_to: new Date(),
  }

  const validationSchema = Yup.object({
    valid_from: Yup.date()
      .required(t('yupError.ThisFieldIsRequired', {field: t('addCard.ValidFrom')}))
      .nullable(),
    valid_to: Yup.date()
      .required(t('yupError.ThisFieldIsRequired', {field: t('addCard.ValidTo')}))
      .min(Yup.ref('valid_from'), t('yupError.DateRange'))
      .test('same_dates_test', t('yupError.DateMustBeGreaterThan'), function (value) {
        const {valid_from} = this.parent
        return value.getTime() !== valid_from.getTime()
      })
      .nullable(),
    resource_id: Yup.number('Please select company').required(
      t('yupError.ThisFieldIsRequired', {field: t('attachResourceForm.SelectResourceTemplate')}),
    ),
  })

  let resourceSelectRef = useRef()
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          values.valid_from = iso8061UTC(values.valid_from)
          values.valid_to = iso8061UTC(values.valid_to)
          values[identityLabel] = identity.id
          dispatch(attachIdentitiesResources(values, userID))
          actions.resetForm()
          // resetting react-select components
          resourceSelectRef.current.select.setValue('')
        }}
      >
        <Form>
          <AsyncSelectInput2
            url={`/api/v1/access_profiles?q=`}
            label={t('attachResourceForm.SelectResourceTemplate')}
            placeholder={t('attachResourceForm.ResourceTemplatePlaceholder')}
            optionLabel="name"
            optionValue="id"
            name="resource_id"
            innerRef={resourceSelectRef}
            required
          />
          <DatePickerDefault
            validfrom="valid_from"
            validto="valid_to"
            validtoid="form-assign-resource-datepicker-validTo"
            validfromid="form-assign-resource-datepicker-validFrom"
            checkboxid="form-assign-resource-checkbox"
            type="datetime-local"
            labelvalidfrom={t('addVisitationForm.FromDate')}
            labelvalidto={t('addVisitationForm.ToDate')}
            endlessfieldlabel={t('datePickerForms.Endless')}
            endlessfield="true"
          />
          <FormikSaveButton
            type="submit"
            testid="form-assign-resource-submit"
            text={t('attachResourceForm.Attach')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(attachIdentitiesResourcesClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
