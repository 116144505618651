import VisitorScreen from './screens/VisitorScreen'
import CardScreen from './screens/CardScreen'
import UserScreen from './screens/UserScreen'
import TodosScreen from './screens/TodosScreen'
import HomeSelfServiceScreen from './screens/HomeSelfServiceScreen'
import AboutScreen from './screens/AboutScreen'
import ImpressumScreen from './screens/ImpressumScreen'
import LicencesScreen from './screens/LicencesScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'
import * as React from 'react'
import EmployeeScreen from './screens/EmployeeScreen'
import EmployeeTableScreen from './screens/EmployeeTableScreen'
import VisitorTableScreen from './screens/VisitorTableScreen'
import InventoryTableScreen from './screens/InventoryTableScreen'
import InventoryScreen from './screens/InventoryScreen'
import CardTableScreen from './screens/CardTableScreen'
import ResourceTableScreen from './screens/ResourceTableScreen'
import ResourceScreen from './screens/ResourceScreen'
import IdentityTableScreen from './screens/IdentityTableScreen'
import IdentityScreen from './screens/IdentityScreen'
import MeetingTableScreen from './screens/MeetingTableScreen'
import MeetingScreen from './screens/MeetingScreen'
import AdminPanelScreen from './screens/AdminPanelScreen'
import IdentityProviderScreen from './screens/IdentityProviderScreen'
import FormBuilderScreen from './screens/FormBuilderScreen'
import FormEditScreen from './screens/FormEditScreen'
import NotFound from './screens/NotFound'
import HomeScreen from './screens/HomeScreen'
import {removeLastSymbol} from './utils/helpers'

export const VISITOR_MAIN_ROUTES = [
  {
    path: '/visitors/:id',
    element: <VisitorScreen />,
  },
  {
    path: '/cards/:id',
    element: <CardScreen />,
  },
  {
    path: '/user_settings/',
    element: <UserScreen />,
  },
  {
    path: '/todos',
    element: <TodosScreen />,
  },
]

export const VISITOR_BASIC_ROUTES = [
  {
    path: '/',
    element: <HomeSelfServiceScreen />,
  },
  {
    path: '/about',
    element: <AboutScreen />,
  },
  {
    path: '/impressum',
    element: <ImpressumScreen />,
  },
  {
    path: '/licences',
    element: <LicencesScreen />,
  },
  {
    path: '/privacy_policy',
    element: <PrivacyPolicyScreen />,
  },
]

export const EMPLOYEE_MAIN_ROUTES = [
  {
    path: '/employees/:id',
    element: <EmployeeScreen />,
  },
  {
    path: '/cards/:id',
    element: <CardScreen />,
  },
  {
    path: '/user_settings/',
    element: <UserScreen />,
  },
  {
    path: '/todos',
    element: <TodosScreen />,
  },
]

export const EMPLOYEE_BASIC_ROUTES = VISITOR_BASIC_ROUTES

export const ADMIN_MAIN_ROUTES = [
  {
    path: '/employees/',
    element: <EmployeeTableScreen />,
  },
  {
    path: '/employees/:id',
    element: <EmployeeScreen />,
  },
  {
    path: '/visitors/',
    element: <VisitorTableScreen />,
  },
  {
    path: '/visitors/:id',
    element: <VisitorScreen />,
  },
  {
    path: '/inventories/',
    element: <InventoryTableScreen />,
  },
  {
    path: '/inventories/:id',
    element: <InventoryScreen />,
  },
  {
    path: '/cards/',
    element: <CardTableScreen />,
  },
  {
    path: '/cards/:id',
    element: <CardScreen />,
  },
  {
    path: '/resources/',
    element: <ResourceTableScreen />,
  },
  {
    path: '/resources/:id',
    element: <ResourceScreen />,
  },
  {
    path: '/identities/',
    element: <IdentityTableScreen />,
  },
  {
    path: '/identities/:id',
    element: <IdentityScreen />,
  },
  {
    path: '/visitations/',
    element: <MeetingTableScreen />,
  },
  {
    path: '/visitations/:id',
    element: <MeetingScreen />,
  },
  {
    path: '/management/',
    element: <AdminPanelScreen />,
  },
  {
    path: '/management/identity_providers/oidc/:id',
    element: <IdentityProviderScreen />,
  },
  {
    path: '/user_settings/',
    element: <UserScreen />,
  },
  {
    path: '/todos',
    element: <TodosScreen />,
  },
  {
    path: '/buildform',
    element: <FormBuilderScreen />,
  },
  {
    path: '/editform',
    element: <FormEditScreen />,
  },
  {
    path: '/not_found/',
    element: <NotFound />,
  },
]

export const ADMIN_BASIC_ROUTES = [
  {
    path: '/',
    element: <HomeScreen />,
  },
  {
    path: '/about',
    element: <AboutScreen />,
  },
  {
    path: '/impressum',
    element: <ImpressumScreen />,
  },
  {
    path: '/privacy_policy',
    element: <PrivacyPolicyScreen />,
  },
]

export const routesAuth = [
  ...new Set(
    [
      ...VISITOR_MAIN_ROUTES,
      ...VISITOR_BASIC_ROUTES,
      ...EMPLOYEE_MAIN_ROUTES,
      ...EMPLOYEE_BASIC_ROUTES,
      ...ADMIN_MAIN_ROUTES,
      ...ADMIN_BASIC_ROUTES,
    ].map((item) => item.path),
  ),
]

export const isAuthRoute = (path) => {
  return !!routesAuth.find((authPathName) => {
    // eslint-disable-next-line no-undef
    const pattern = new URLPattern({pathname: removeLastSymbol(authPathName, '/')})

    return pattern.test(removeLastSymbol(path, '/'))
  })
}
