import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Table from '../table'
import {getExecutions} from '../../stores/executions'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'
import {useTableColumns} from '../../hooks/useTableColumns'

export default function ExecutionsWrapper() {
  const dispatch = useDispatch()

  // get todos
  useEffect(() => {
    dispatch(getExecutions())
  }, [dispatch])

  const columns = useTableColumns(TableId.EXECUTION)
  const executions = useSelector((state) => state.executions.get_executions.success)
  const executionsPending = useSelector((state) => state.executions.get_executions.pending)
  const executionsError = useSelector((state) => state.executions.get_executions.error)
  return (
    <>
      <EditTableWrapper tableId={TableId.EXECUTION} />
      <Table
        columns={columns}
        data={executions}
        pending={executionsPending}
        error={executionsError}
        setError={() => {}}
      />
    </>
  )
}
