import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'ID',
    label: 'ID',
    accessor: 'id',
  },
  {
    header: 'configureResourceType.ResourceTypeName',
    label: 'configureResourceType.ResourceTypeName',
    accessor: 'name',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'configureResourceType.Actions',
    label: 'configureResourceType.Actions',
    accessor: 'url',
    idAccessor: 'id',
    nameAccessor: 'name',
    cell: 'FormEditResourceTypeActionsCell',
    noSort: true,
  },
]
export default columnConfig
