import {RequestState, TableId, TableInitialState} from './types/tables'
import axios from '../services/api'
import tablesConfig from '../components/table/configs'

const SET_INITIAL_TABLE_PREFERENCES = 'idfunctiondb/tables/SET_TABLE_PREFERENCES'
const UPDATE_TABLE_CONFIG_SUCCESS = 'idfunctiondb/tables/UPDATE_TABLE_CONFIG_SUCCESS'
const UPDATE_TABLE_CONFIG_ERROR = 'idfunctiondb/tables/UPDATE_TABLE_CONFIG_ERROR'
const UPDATE_TABLE_CONFIG_PENDING = 'idfunctiondb/tables/UPDATE_TABLE_CONFIG_PENDING'
const UPDATE_TABLE_CONFIG_CLEAN = 'idfunctiondb/tables/UPDATE_TABLE_CONFIG_CLEAN'

interface InitialState {
  id: string
  config: {[key in TableId]: TableInitialState}
}

const initialState: InitialState = {
  id: '',
  config: {
    person: {
      id: TableId.PERSON,
      hiddenColumns: [],
      columns: ['full_name', 'email', 'number'],
    },
    card: {
      id: TableId.CARD,
      hiddenColumns: ['valid_from'],
      columns: [
        'selection',
        'badgenr',
        'owner.person.full_name',
        'profile.description',
        'state.name',
        'valid_from',
        'valid_to',
      ],
    },
    employee: {
      id: TableId.EMPLOYEE,
      hiddenColumns: [],
      columns: ['person.full_name', 'company.name', 'employee_number', 'employee_state_long', 'id'],
    },
    inventory: {
      id: TableId.INVENTORY,
      hiddenColumns: [],
      columns: ['inventory_number', 'manufacturer', 'model', 'type.name', 'id'],
    },
    resource: {
      id: TableId.RESOURCE,
      hiddenColumns: [],
      columns: ['name', 'created_at', 'type.name', 'id'],
    },
    resourceChildren: {
      id: TableId.RESOURCE_CHILDREN,
      hiddenColumns: ['children_resources'],
      columns: ['name', 'created_at', 'id'],
    },
    resourceParents: {
      id: TableId.RESOURCE_PARENTS,
      hiddenColumns: ['parent_resources'],
      columns: ['name', 'created_at'],
    },
    visitor: {
      id: TableId.VISITOR,
      hiddenColumns: ['valid_from'],
      columns: [
        'person.full_name',
        'company.name',
        'valid_from',
        'valid_to',
        'visitor_number',
        'id',
      ],
    },
    cardsHistory: {
      id: TableId.CARDS_HISTORY,
      hiddenColumns: ['profile_name'],
      columns: ['badgenr', 'profile_name', 'state_name', 'updated_at'],
    },
    company: {
      id: TableId.COMPANY,
      hiddenColumns: [],
      columns: ['email', 'location.description', 'name', 'id'],
    },
    configuration: {
      id: TableId.CONFIGURATION,
      hiddenColumns: ['id', 'config_type', 'active'],
      columns: [
        'active',
        'config_type',
        'description',
        'execution_date',
        'id',
        'status_name',
        'name',
      ],
    },
    execution: {
      id: TableId.EXECUTION,
      hiddenColumns: [],
      columns: ['requester_name', 'status_name', 'updated_at', 'workflow.name', 'id'],
    },
    filesVisitor: {
      id: TableId.FILES_VISITOR,
      hiddenColumns: [],
      columns: ['ValidToFn', 'description', 'id'],
    },
    filesEmployee: {
      id: TableId.FILES_VISITOR,
      hiddenColumns: [],
      columns: ['ValidToFn', 'description', 'id'],
    },
    forms: {
      id: TableId.FORMS,
      hiddenColumns: [],
      columns: ['description', 'name', 'id'],
    },
    identityProvider: {
      id: TableId.IDENTITY_PROVIDER,
      hiddenColumns: [],
      columns: ['oidc.oidc_provider_id', 'updated_at', 'actions'],
    },
    inventoryHistory: {
      id: TableId.INVENTORY_HISTORY,
      hiddenColumns: [],
      columns: ['assigned_to', 'inventory_id', 'updated_at', 'updated_by_id'],
    },
    inventoryType: {
      id: TableId.INVENTORY_TYPE,
      hiddenColumns: [],
      columns: ['id', 'name', 'updated_at', 'url'],
    },
    printer: {
      id: TableId.PRINTER,
      hiddenColumns: ['url'],
      columns: ['id', 'description', 'name', 'url'],
    },
    resourceType: {
      id: TableId.RESOURCE_TYPE,
      hiddenColumns: [],
      columns: ['id', 'name', 'url'],
    },
    subscription: {
      id: TableId.SUBSCRIPTION,
      hiddenColumns: ['workflow.name'],
      columns: ['active', 'description', 'take_over', 'workflow.name'],
    },
    user: {
      id: TableId.USER,
      hiddenColumns: [],
      columns: ['active', 'name', 'policy.url', 'id'],
    },
    workflows: {
      id: TableId.WORKFLOWS,
      hiddenColumns: ['id'],
      columns: ['url', 'name', 'render_on_ui', 'form', 'unauthenticated_use', 'id'],
    },
    meeting: {
      id: TableId.MEETING,
      hiddenColumns: [],
      columns: ['name', 'host_company.name', 'from_dt', 'id'],
    },
    toDos: {
      id: TableId.TODOS,
      hiddenColumns: ['status_name'],
      columns: ['requester_name', 'workflow.name', 'status_name'],
    },
    resourcesEmployee: {
      id: TableId.RESOURCES_EMPLOYEE,
      hiddenColumns: ['valid_from'],
      columns: ['resource_type', 'resource', 'valid_from', 'valid_to', 'id'],
    },
    inventoriesEmployee: {
      id: TableId.INVENTORIES_EMPLOYEE,
      hiddenColumns: ['valid_from'],
      columns: [
        'inventory.inventory_number',
        'inventory.model',
        'inventory.type.name',
        'valid_from',
        'valid_to',
        'id',
      ],
    },
    inventoriesVisitor: {
      id: TableId.INVENTORIES_VISITOR,
      hiddenColumns: ['valid_from'],
      columns: [
        'inventory.inventory_number',
        'inventory.model',
        'inventory.type.name',
        'valid_from',
        'valid_to',
        'id',
      ],
    },
    resourcesIdentities: {
      id: TableId.RESOURCES_IDENTITIES,
      hiddenColumns: [],
      columns: ['identity.id', 'created_at', 'identity.owner_name'],
    },
    identity: {
      id: TableId.IDENTITY,
      hiddenColumns: [],
      columns: ['created_at', 'owner_name', 'id'],
    },
    [TableId.IDENTITY_MEMBERS]: {
      id: TableId.IDENTITY_MEMBERS,
      hiddenColumns: [],
      columns: ['created_at', 'resource', 'id'],
    },
    identityChildren: {
      id: TableId.IDENTITY_CHILDREN,
      hiddenColumns: ['children_identities'],
      columns: ['created_at', 'owner_name', 'id', 'children_identities'],
    },
    identityParents: {
      id: TableId.IDENTITY_PARENTS,
      hiddenColumns: ['parent_identities'],
      columns: ['created_at', 'owner_name', 'parent_identities'],
    },
    [TableId.IDENTITIES_EMPLOYEE]: {
      id: TableId.IDENTITIES_EMPLOYEE,
      hiddenColumns: [],
      columns: ['created_at', 'owner_name', 'id'],
    },
    [TableId.IDENTITIES_VISITOR]: {
      id: TableId.IDENTITIES_VISITOR,
      hiddenColumns: [],
      columns: ['created_at', 'owner_name'],
    },
  },
}

export default function reducer(state = initialState, action: any = {} as any) {
  switch (action.type) {
    case SET_INITIAL_TABLE_PREFERENCES:
      const newState = {id: action.payload.id, config: state.config}
      if (action.payload.table_configs) {
        // @ts-ignore
        Object.entries(action.payload.table_configs).forEach(([tableId, col]) => {
          const {hiddenColumns, columns} = col as any
          const tableFullConfig = tablesConfig[tableId]?.config.map((c) => c.accessor)
          if (hiddenColumns) {
            newState.config[tableId].hiddenColumns = hiddenColumns.filter((col) =>
              tableFullConfig.includes(col),
            )
          }
          if (columns) {
            newState.config[tableId].columns = columns.filter((col) =>
              tableFullConfig.includes(col),
            )
          }
        })
      }
      return newState
    case UPDATE_TABLE_CONFIG_SUCCESS:
      return {
        id: state.id,
        config: {
          ...state.config,
          [action.payload.tableId]: {
            ...state.config[action.payload.tableId],
            update: RequestState.SUCCESS,
            hiddenColumns: action.payload.hiddenColumns || [],
            columns: action.payload.columns,
          },
        },
      }
    case UPDATE_TABLE_CONFIG_ERROR:
      return {
        ...state,
        config: {
          ...state.config,
          [action.payload.tableId]: {
            ...state.config[action.payload.tableId],
            update: RequestState.ERROR,
          },
        },
      }
    case UPDATE_TABLE_CONFIG_PENDING:
      return {
        ...state,
        config: {
          ...state.config,
          [action.payload.tableId]: {
            ...state.config[action.payload.tableId],
            update: RequestState.PENDING,
          },
        },
      }
    case UPDATE_TABLE_CONFIG_CLEAN:
      return {
        ...state,
        config: {
          ...state.config,
          [action.payload.tableId]: {
            ...state.config[action.payload.tableId],
            update: undefined,
          },
        },
      }
    default:
      return state
  }
}

export function setInitialTablePreferences(payload) {
  return {type: SET_INITIAL_TABLE_PREFERENCES, payload}
}

export const setTableConfig = (payload) => (dispatch) => {
  const {id, tableId, columns, hiddenColumns} = payload
  dispatch(updateTableConfigPending({tableId}))
  return axios
    .put(`/api/v1/preferences/${id}`, {
      table_configs: {
        id: tableId,
        data: {
          hiddenColumns,
          columns,
        },
      },
    })
    .then((res) => {
      const {hiddenColumns, columns} = res.data.table_configs[tableId]
      dispatch(updateTableConfigSuccess({tableId, hiddenColumns, columns}))
    })
    .catch(() =>
      dispatch(
        updateTableConfigError({
          tableId,
          hiddenColumns,
          columns,
        }),
      ),
    )
}

export function updateTableConfigSuccess(payload) {
  return {type: UPDATE_TABLE_CONFIG_SUCCESS, payload}
}

export function updateTableConfigError(payload) {
  return {type: UPDATE_TABLE_CONFIG_ERROR, payload}
}

export function updateTableConfigPending(payload) {
  return {type: UPDATE_TABLE_CONFIG_PENDING, payload}
}

export function updateTableConfigClean(payload) {
  return {type: UPDATE_TABLE_CONFIG_CLEAN, payload}
}
