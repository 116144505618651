import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'TableLabelsCommon.City',
    accessor: 'city',
    label: 'TableLabelsCommon.City',
  },
  {
    header: 'TableLabelsCommon.Country',
    accessor: 'country',
    label: 'TableLabelsCommon.Country',
  },
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.CustomAttributes',
    accessor: 'custom_attributes',
    noSort: true,
    cell: 'CustomAttributesCell',
    label: 'TableLabelsCommon.CustomAttributes',
  },
  {
    header: 'TableLabelsCommon.Email',
    accessor: 'email',
    label: 'TableLabelsCommon.Email',
  },
  {
    header: 'SelectColumnFilterLocations',
    accessor: 'location.description',
    label: 'TableLabelsCommon.Location',
    permissionsAccessor: 'listCompaniesLocations',
  },
  {
    header: 'TableLabelsCommon.LocationId',
    accessor: 'location.id',
    label: 'TableLabelsCommon.LocationId',
  },
  {
    header: 'companyList.CompanyName',
    accessor: 'name',
    label: 'companyList.CompanyName',
  },
  {
    header: 'TableLabelsCommon.Phone',
    accessor: 'phone',
    label: 'TableLabelsCommon.Phone',
  },
  {
    header: 'TableLabelsCommon.State',
    accessor: 'state',
    label: 'TableLabelsCommon.State',
  },
  {
    header: 'TableLabelsCommon.Street',
    accessor: 'street',
    label: 'TableLabelsCommon.Street',
  },
  {
    header: 'TableLabelsCommon.Type',
    accessor: 'type',
    label: 'TableLabelsCommon.Type',
  },
  {
    header: 'TableLabelsCommon.CompanyUniLocationCode',
    accessor: 'uni_location_code',
    label: 'TableLabelsCommon.CompanyUniLocationCode',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    cell: 'DateCell',
    accessor: 'updated_at',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'TableLabelsCommon.Zip',
    accessor: 'zip',
    label: 'TableLabelsCommon.Zip',
  },
  {
    header: 'companyList.Actions',
    label: 'companyList.Actions',
    accessor: 'id',
    nameAccessor: 'name',
    streetAccessor: 'street',
    zipAccessor: 'zip',
    cityAccessor: 'city',
    stateAccessor: 'state',
    countryAccessor: 'country',
    phoneAccessor: 'phone',
    emailAccessor: 'email',
    locationAccessor: 'location',
    cell: 'FormEditCompanyCell',
    noSort: true,
  },
]
export default columnConfig
