import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import Image from '../Image'
import ImageSrc from '../../assets/inventory.svg'
import {updateInventoryType, updateInventoryTypeClearError} from '../../stores/inventories'
import {createAndSetInventoryTypePhoto} from '../../stores/inventories_photos'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'

export default function FormEditInventoryType({selectedItem}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {pending, error, success} = useSelector((state) => state.inventories.update_inventory_type)
  const [typeImage, setTypeImage] = useState(null)
  const [uploadedImage, setUploadedImage] = useState(false)

  const handleSubmit = async (values) => {
    const response = await dispatch(
      updateInventoryType(`/api/v1/inventories_types/${selectedItem.id}`, {name: values.name}),
    )
    if (typeImage !== null) {
      // if there is not a photo selected to upload don't hit this api endpoint
      let blob = await fetch(typeImage).then((r) => r.blob())
      await dispatch(
        createAndSetInventoryTypePhoto(`/api/v1/inventories_types/${response.id}/photos`, blob),
      )
    }

    setUploadedImage(false)
    setTypeImage(null)
  }

  const fields = [
    {
      label: t('uploadFile'),
      component: InputComponent.FILE,
      name: 'inventory-edit-fileInput',
      validationType: YupValidationType.OBJECT,
      onUpdate: (fileFieldValue) => {
        const imageURl = fileFieldValue && URL.createObjectURL(fileFieldValue)
        setUploadedImage(true)
        setTypeImage(imageURl)
      },
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[2, 3], t('yupError.FileType')],
        },
        {
          type: InputYupValidationsType.FILESIZE,
          params: [10000000, t('yupError.MaxFileSize')],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.FileRequired')],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: 'userManagement.Name',
      name: 'name',
      defaultValue: selectedItem.name,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
        {
          type: InputYupValidationsType.MAX,
          params: [256, 'yupError.MaxCharsAllowed'],
        },
      ],
    },
  ]

  return (
    <>
      {uploadedImage ? (
        <Image ImgSrc={typeImage} alt="inventoryTypePHoto" />
      ) : selectedItem.inventoryTypeCurrentPhotoId ? (
        <Image ImgSrc={selectedItem.inventoryTypeCurrentPhotoUrl} alt="inventoryTypePHoto" />
      ) : (
        <Image ImgSrc={ImageSrc} alt="inventoryTypePHoto" />
      )}
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'file-submit',
          submitButtonText: t('addInventoryForm.InventoryAdd'),
        }}
        onSubmit={handleSubmit}
        pending={pending}
        noFormBorder
        infoMessage={
          <Notification
            error={error}
            setError={() => dispatch(updateInventoryTypeClearError())}
            success={!!success}
          />
        }
      />
    </>
  )
}
