import {
  CalendarIcon,
  CogIcon,
  IdentificationIcon,
  UsersIcon,
  HomeIcon,
  InboxIcon,
  UserGroupIcon,
  ExclamationIcon,
  ClipboardCheckIcon,
} from '@heroicons/react/outline'
import {useSelector, useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getEmployeesTotal} from '../stores/employees'
import {getVisitorsTotal} from '../stores/visitors'
import {getCardsTotal} from '../stores/cards'
import {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {IdentitiesIcon} from '../assets/IdentitiesIcon'
import {ResourcesIcon} from '../assets/ResourcesIcon'

export default function HomeScreen() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(getEmployeesTotal())
    dispatch(getCardsTotal())
    dispatch(getVisitorsTotal())
  }, [dispatch])

  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const user = useSelector((state) => state.auth.fetchUserSuccess)
  const get_employees_total = useSelector((state) => state.employees.get_employees_total.success)
  const get_visitors_total = useSelector((state) => state.visitors.get_visitors_total.success)
  const get_cards_total = useSelector((state) => state.cards.get_cards_total.success)
  const navigation = [
    {name: t('sidebar.Home'), href: '/', icon: HomeIcon, current: true},
    {
      name:
        t('sidebar.Employees') +
        ' (' +
        (get_employees_total !== null ? get_employees_total.meta.total : '...') +
        ')',
      desc: t('home.EmployeesDescription'),
      href: '/employees/',
      permission: permissions.listEmployees,
      icon: UserGroupIcon,
      current: false,
    },
    {
      name:
        t('sidebar.Visitors') +
        ' (' +
        (get_visitors_total !== null ? get_visitors_total.meta.total : '...') +
        ')',
      desc: t('home.VisitorsDescription'),
      href: '/visitors/',
      permission: permissions.listVisitors,
      icon: UsersIcon,
      current: false,
    },
    {
      name:
        t('sidebar.CardManagement') +
        ' (' +
        (get_cards_total !== null ? get_cards_total.meta.total : '...') +
        ')',
      href: '/cards/',
      desc: t('home.CardsDescription'),
      permission: permissions.listCards,
      icon: IdentificationIcon,
      current: false,
    },
    {
      name: t('sidebar.Meetings'),
      desc: t('home.MeetingsDescription'),
      href: '/visitations/',
      permission: permissions.listVisitors,
      icon: CalendarIcon,
      current: false,
    },
    {
      name: t('sidebar.Inventories'),
      desc: t('home.InventoriesDescription'),
      href: '/inventories/',
      permission: permissions.listInventories,
      icon: InboxIcon,
      current: false,
    },
    {
      name: t('sidebar.Resources'),
      desc: t('home.ResourcesDescription'),
      href: '/resources/',
      permission: permissions.listAccessProfiles,
      icon: ResourcesIcon,
      current: false,
    },
    {
      name: t('sidebar.Identities'),
      desc: t('home.IdentitiesDescription'),
      href: '/identities/',
      permission: permissions.listIdentities,
      icon: IdentitiesIcon,
      current: false,
    },
    // {
    //   name: t('sidebar.EmployeesReports'),
    //   desc: t('home.EmployeeReportsDescription'),
    //   href: '/reports/employees',
    //   permission: permissions.listReports,
    //   icon: DocumentReportIcon,
    //   current: false,
    // },
    // {
    //   name: t('sidebar.VisitorsReports'),
    //   desc: t('home.VisitorReportsDescription'),
    //   href: '/reports/visitors',
    //   permission: permissions.listVisitors,
    //   icon: DocumentReportIcon,
    //   current: false,
    // },
    {
      name: t('sidebar.AdminTools'),
      desc: t('home.AdministrationDescription'),
      href: '/management/',
      permission: user.roles.some((role) => role.includes('system:admin:')),
      icon: CogIcon,
      current: false,
    },
    {
      name: t('sidebar.Todos'),
      desc: t('home.TodosDescription'),
      href: '/todos/',
      permission: true, // should be visible for anyone; admin or owner
      icon: ClipboardCheckIcon,
      current: false,
    },
  ]

  const permissionedNavigation = navigation.filter((item) => item.permission === true)

  return (
    <>
      <div className="p-8">
        <div className="flex items-center gap-x-6 rounded-lg bg-lime-600 py-2.5 px-6 sm:px-3.5 sm:before:flex-1">
          <p className="flex text-sm leading-6 text-white">
            <strong className="hidden pr-1 font-semibold md:block">
              {t('userInfo.PreferredTimezone')}:
            </strong>
            {user.preferred_timezone === null
              ? t('userInfo.NoTimezoneSelectedShort')
              : user.preferred_timezone === 'N/A'
              ? t('userInfo.CanNotSetTimezoneShort')
              : user.preferred_timezone}
          </p>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-1 w-1 fill-current text-white"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          <p className="flex text-sm leading-6 text-white">
            <strong className="hidden pr-1 font-semibold md:block">
              {t('userInfo.LocalTimezone')}:
            </strong>
            {Intl.DateTimeFormat().resolvedOptions().timeZone}
          </p>
        </div>
        {permissionedNavigation.length === 0 ? (
          <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t('home.UserHasNoPermission')}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t('home.UserHasNoPermissionDescription')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ul className="grid grid-cols-1 gap-6 pt-2 sm:grid-cols-2 lg:grid-cols-3">
            {permissionedNavigation.map((item) => (
              <li
                key={item.name}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-gray-100 shadow hover:bg-gray-200"
              >
                <Link to={item.href}>
                  <div className="flex w-full items-center justify-between space-x-6 p-10">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-gray-900">{item.name}</h3>
                      </div>
                      <p className="mt-1 truncate text-sm text-gray-500">{item.desc}</p>
                    </div>
                    <div className="h-6 w-6">
                      <item.icon />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}
