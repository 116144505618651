import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import FormAddInventory from '../components/forms/FormAddInventory'
import ServerSideTable from '../components/table/ServerSideTable'
import {INVENTORY_TABLE} from '../utils/formViews'
import WorkflowForms from '../components/WorkflowForms'
import {TableId} from '../stores/types/tables'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'
import {useTableColumns} from '../hooks/useTableColumns'

export default function InventoryTableScreen() {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const deleteInventoryAction = useSelector((state) => state.inventories.delete_inventory.success)
  const addInventoryAction = useSelector((state) => state.inventories.create_inventory.success)

  const columns = useTableColumns(TableId.INVENTORY)

  return (
    <>
      {permissions.createInventory ? (
        <Collapse title={t('addInventoryForm.AddInventoryTitle')}>
          <FormAddInventory
            userID={userID}
            createInventoryLocations={permissions.createInventoryLocations}
          />
        </Collapse>
      ) : null}
      <WorkflowForms renderOnUi={INVENTORY_TABLE} />
      {permissions.listInventories ? (
        <>
          <EditTableWrapper tableId={TableId.INVENTORY} />
          <ServerSideTable
            columns={columns}
            entityName="inventories"
            deleteAction={deleteInventoryAction}
            addAction={addInventoryAction}
            rowUrlAccessor={'id'}
          />
        </>
      ) : null}
    </>
  )
}
