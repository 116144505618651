import Table from '../table'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {ModalOpenButton, Modal, ModalContentsBase} from '../Modal'
import {Button} from '../Buttons'
import FormAssignInventory from '../forms/FormAssignInventory'
import EditTableWrapper from './EditTableWrapper'

export default function InventoriesWrapper({
  data,
  pending,
  error,
  setError,
  identityLabel,
  identity,
  assignInventoryToIdentity,
  assignInventoryToIdentityPending,
  assignInventoryToIdentityError,
  assignInventoryToIdentityClearError,
  filterIdentityInventory,
  filterIdentityInventoryClearError,
  filteredIdentityInventoriesPending,
  filteredIdentityInventoriesError,
  filteredIdentityInventories,
  identityAssignInventoryPermission,
  hiddenColumns,
  columns,
  tableId,
}) {
  const {t} = useTranslation()

  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  return (
    <>
      {identityAssignInventoryPermission && (
        <Modal>
          <ModalOpenButton>
            <Button text={t('attachInventoryForm.AttachInventoryTitle')} variant="secondary" />
          </ModalOpenButton>
          <ModalContentsBase>
            <FormAssignInventory
              identity={identity}
              assignInventoryToIdentity={assignInventoryToIdentity}
              assignInventoryToIdentityPending={assignInventoryToIdentityPending}
              assignInventoryToIdentityClearError={assignInventoryToIdentityClearError}
              assignInventoryToIdentityError={assignInventoryToIdentityError}
              identityLabel={identityLabel}
              filterIdentityInventory={filterIdentityInventory}
              filterIdentityInventoryClearError={filterIdentityInventoryClearError}
              filteredIdentityInventoriesPending={filteredIdentityInventoriesPending}
              filteredIdentityInventoriesError={filteredIdentityInventoriesError}
              filteredIdentityInventories={filteredIdentityInventories}
            />
          </ModalContentsBase>
        </Modal>
      )}
      {permissions.readInventory ? (
        <>
          <EditTableWrapper tableId={tableId} />
          <Table
            columns={columns}
            hiddenColumns={hiddenColumns}
            data={data}
            pending={pending}
            error={error}
            setError={setError}
          />
        </>
      ) : null}
    </>
  )
}
