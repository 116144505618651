import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {
  bulkCreateNonPersonalizedCards,
  getCardProfiles,
  bulkCreatePersonalizedCardsClearError,
} from '../../stores/cards'
import {iso8061UTC} from '../../utils/datetime'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'

export default function FormAddCardNonPersonalized() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const cardProfiles = useSelector((state) => state.cards.get_card_profiles.success)
  const cardProfilesError = useSelector((state) => state.cards.get_card_profiles.error)

  const pending = useSelector((state) => state.cards.bulk_create_non_personalized_cards.pending)
  const error = useSelector((state) => state.cards.bulk_create_non_personalized_cards.error)

  useEffect(() => {
    dispatch(getCardProfiles(`/api/v1/cards/profiles?filter_by=reassignable`))
  }, [dispatch, cardProfilesError?.message])

  const handleSubmit = (values) => {
    values.valid_from = iso8061UTC(values.valid_from)
    values.valid_to = iso8061UTC(values.valid_to)
    dispatch(bulkCreateNonPersonalizedCards(values))
  }

  const fields = [
    {
      component: InputComponent.TEXT_INPUT,
      name: 'number_of_cards',
      label: 'addCard.NumberOfCards',
      inputType: 'number',
      validationType: YupValidationType.NUMBER,
      defaultValue: 1,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
        {
          type: InputYupValidationsType.MIN,
          params: [1, t('addCard.CardNumberError')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `card_profile_id`,
      label: 'addCard.CardProfile',
      placeholder: 'addCard.SelectCardProfile',
      options: cardProfiles.map((o) => ({
        value: o.id,
        label: o.name,
      })),
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: 'addEmployee.Domain',
      placeholder: 'addEmployee.DomainPlaceholder',
      options: permissions.createCardLocations,
      defaultValue: permissions.createCardLocations && permissions.createCardLocations[0]?.value,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_from',
      inputType: 'datetime-local',
      label: 'addCard.ValidFrom',
      defaultValue: new Date().toISOString().slice(0, -8),
      validationType: YupValidationType.DATE,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_to',
      inputType: 'datetime-local',
      label: 'addCard.ValidTo',
      validationType: YupValidationType.DATE,
      allowEndless: true,
      validations: [
        {
          type: InputYupValidationsType.ENDDATE, // special type for validation
          params: ['valid_from', t('yupError.DateRange')],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields: fields,
          name: 'add-card-non-personalized',
          submitButtonText: t('addCard.Add'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        infoMessage={
          <>
            <Notification
              error={error}
              setError={() => dispatch(bulkCreatePersonalizedCardsClearError())}
            />
          </>
        }
      />
    </>
  )
}
