import {useTranslation} from 'react-i18next'
import {
  updateIDFunctionWebPrinter,
  updateIDFunctionWebPrinterClearError,
} from '../../stores/printers'
import {useDispatch, useSelector} from 'react-redux'
import {TextInput} from '../FormInputs'
import {Formik, Form} from 'formik'
import {FormikSaveButton} from '../Buttons'

export default function FormEditPrinter({printerName, printerId}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const initialValues = {
    name: printerName || '',
  }
  const pending = useSelector((state) => state.printers.update_idfunctionweb_printer.pending)
  const error = useSelector((state) => state.printers.update_idfunctionweb_printer.error)

  const handleSubmit = async (values) => {
    dispatch(updateIDFunctionWebPrinter(printerId, values, userID))
  }
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <TextInput
            label={t('configurePrinters.PrinterNameForIDF')}
            type="text"
            name="name"
            placeholder=""
          />
          <FormikSaveButton
            type="submit"
            testid="form-edit-printer"
            text={t('addEmployee.AddEmployee')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(updateIDFunctionWebPrinterClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
