import FormFormBuilderSimple from '../components/forms/FormFormBuilderSimple'
import {isEmpty} from '../utils/custom_attributes'
import {useSelector} from 'react-redux'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DynamicRhfForm} from '../components/formRendererRHF/DynamicRhfForm'

export default function FormEditScreen() {
  const {t} = useTranslation()

  const formdata = useSelector((state) => state.forms.set_formdata.success)

  let {formJson, formId, formName, formDescription, formDomain, formJsonString, error} = formdata
  const initialState = isEmpty(formJson) ? formJsonString : formJson
  const [jsonObject, setJsonObject] = useState(initialState)

  return (
    <>
      <div className="min-h-full">
        <main className="py-1">
          {/* Page header */}
          <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{t('formBuilder.FormBuilder')}</h1>
                <p className="text-sm font-medium text-gray-500">
                  {t('formBuilder.InstructionText')}
                </p>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-8 grid max-w-4xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">
            {/* FormFormBuilder Section*/}
            <section
              aria-labelledby="form-building-section"
              className="space-y-6 lg:col-span-2 lg:col-start-1"
            >
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2
                  id="form-preview-title"
                  className="bg-lime-100 p-2 text-lg font-medium text-gray-900 shadow sm:rounded-lg"
                >
                  {t('formBuilder.BuildForm')}
                </h2>
                <FormFormBuilderSimple
                  jsonObject={jsonObject}
                  setJsonObject={setJsonObject}
                  formId={formId}
                  formName={formName}
                  formDescription={formDescription}
                  formDomain={formDomain}
                />
              </div>
            </section>
            {/* Form Preview Section*/}
            <section
              aria-labelledby="form-preview-section"
              className="lg:col-span-2 lg:col-start-3"
            >
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2
                  id="form-preview-title"
                  className="bg-lime-100 p-2 text-lg font-medium text-gray-900 shadow sm:rounded-lg"
                >
                  {t('formBuilder.FormPreview')}
                </h2>
                {!!jsonObject?.formTitle && (
                  <p className="w-full rounded-lg py-2.5 text-center text-sm font-medium leading-5 text-gray-700">
                    {jsonObject?.formTitle}
                  </p>
                )}
                {!isEmpty(jsonObject) && !error ? (
                  <DynamicRhfForm
                    formConfig={jsonObject}
                    onSubmit={(values) => alert(JSON.stringify(values, 0, 2))}
                  />
                ) : (
                  <p>{t('formBuilder.NoFormToShow')}</p>
                )}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  )
}
