import Slider from '../Slider'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {getSelectedPrinter} from '../../stores/printers'
import IdentityCardFooterActions from '../IdentityCardFooterActions'
import IdentityCardHeaderActions from '../IdentityCardHeaderActions'
import IdentityCardImages from '../IdentityCardImages'
import IdentityCardBodyActions from '../IdentityCardBodyActions'
import SpinnerSVG from '../../assets/Spinner.svg'

export default function IdentityCardsWrapper({
  data,
  identityCardsPending,
  urlIdentity,
  cards,
  identityAssignCardPermission,
  identityUnassignCardPermission,
}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)

  // printer states
  const attach_printer_pending = useSelector(
    (state) => state.printers.attach_printer_to_user.pending,
  )
  const selected_printer = useSelector((state) => state.printers.get_selected_printer.success)

  useEffect(() => {
    dispatch(getSelectedPrinter(userID))
  }, [dispatch, attach_printer_pending, userID])

  const slidesArray = cards
    .filter((identityCard) => identityCard.card.state.name !== 'DELETED')
    .map((identityCard) => (
      <>
        <IdentityCardHeaderActions
          identityCard={identityCard}
          identity={data}
          identityAssignCardPermission={identityAssignCardPermission}
        />
        <IdentityCardImages identityCard={identityCard} />
        <IdentityCardBodyActions
          identityCard={identityCard}
          identityUnassignCardPermission={identityUnassignCardPermission}
          urlIdentity={urlIdentity}
          data={data}
        />
        <IdentityCardFooterActions
          identityCard={identityCard}
          selected_printer={selected_printer}
        />
      </>
    ))

  return (
    <>
      {slidesArray.length === 0 && !identityCardsPending ? (
        <>
          <IdentityCardHeaderActions
            identity={data}
            identityAssignCardPermission={identityAssignCardPermission}
          />
          <div>{t('addCard.NoCard')}</div>
        </>
      ) : identityCardsPending ? (
        <div className="text-md flex flex-col items-center justify-center p-3">
          <img alt="o" src={SpinnerSVG} className="mr-3 h-20 w-20 animate-spin text-lime-500" />
          {t('loading')}
        </div>
      ) : (
        <Slider slides={slidesArray} />
      )}
    </>
  )
}
