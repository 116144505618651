import axios from '../services/api'
import {UPLOAD_CONFIG_FILE_ERROR, uploadConfigFile} from './files'
import {createNotification} from './notifications'
import I18n from '../i18n'

// Types
const GET_ALL_CONFIGS_PENDING = 'idfunctiondb/configs/GET_ALL_CONFIGS_PENDING'
const GET_ALL_CONFIGS_SUCCESS = 'idfunctiondb/configs/GET_ALL_CONFIGS_SUCCESS'
const GET_ALL_CONFIGS_ERROR = 'idfunctiondb/configs/GET_ALL_CONFIGS_ERROR'
const GET_ALL_CONFIGS_CLEAR_ERROR = 'idfunctiondb/configs/GET_ALL_CONFIGS_CLEAR_ERROR'

const VALIDATE_AND_STORE_CONFIG_PENDING = 'idfunctiondb/configs/VALIDATE_AND_STORE_CONFIG_PENDING'
const VALIDATE_AND_STORE_CONFIG_SUCCESS = 'idfunctiondb/configs/VALIDATE_AND_STORE_CONFIG_SUCCESS'
const VALIDATE_AND_STORE_CONFIG_ERROR = 'idfunctiondb/configs/VALIDATE_AND_STORE_CONFIG_ERROR'
const VALIDATE_AND_STORE_CONFIG_CLEAR_ERROR =
  'idfunctiondb/configs/VALIDATE_AND_STORE_CONFIG_CLEAR_ERROR'

const UPDATE_CONFIG_PENDING = 'idfunctiondb/configs/UPDATE_CONFIG_PENDING'
const UPDATE_CONFIG_SUCCESS = 'idfunctiondb/configs/UPDATE_CONFIG_SUCCESS'
const UPDATE_CONFIG_ERROR = 'idfunctiondb/configs/UPDATE_CONFIG_ERROR'
const UPDATE_CONFIG_CLEAR_ERROR = 'idfunctiondb/configs/UPDATE_CONFIG_CLEAR_ERROR'

const DELETE_CONFIG_PENDING = 'idfunctiondb/configs/DELETE_CONFIG_PENDING'
const DELETE_CONFIG_SUCCESS = 'idfunctiondb/configs/DELETE_CONFIG_SUCCESS'
const DELETE_CONFIG_ERROR = 'idfunctiondb/configs/DELETE_CONFIG_ERROR'

const DELETE_CONFIG_FILE_PENDING = 'idfunctiondb/configs/DELETE_CONFIG_FILE_PENDING'
const DELETE_CONFIG_FILE_SUCCESS = 'idfunctiondb/configs/DELETE_CONFIG_FILE_SUCCESS'
const DELETE_CONFIG_FILE_ERROR = 'idfunctiondb/configs/DELETE_CONFIG_FILE_ERROR'
const DELETE_CONFIG_FILE_CLEAR_ERROR = 'idfunctiondb/configs/DELETE_CONFIG_FILE_CLEAR_ERROR'

const UPDATE_LOCATION = 'idfunctiondb/configs/UPDATE_LOCATION'

const initialState = {
  get_all_configs: {
    success: [],
    pending: false,
    error: null,
  },

  validate_and_store_config: {
    success: [],
    pending: false,
    error: null,
  },

  update_config: {
    success: [],
    pending: false,
    error: null,
  },

  delete_config: {
    success: null,
    pending: false,
    error: null,
  },
  delete_config_file: {
    success: null,
    pending: false,
    error: null,
  },

  location: undefined,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_LOCATION:
      return {
        ...state,
        location: action.payload,
      }
    case GET_ALL_CONFIGS_PENDING:
      return {
        ...state,
        get_all_configs: {
          ...state.get_all_configs,
          pending: true,
        },
      }
    case GET_ALL_CONFIGS_SUCCESS:
      return {
        ...state,
        get_all_configs: {
          ...state.get_all_configs,
          success: action.payload,
          pending: false,
        },
      }
    case GET_ALL_CONFIGS_ERROR:
      return {
        ...state,
        get_all_configs: {
          ...state.get_all_configs,
          error: action.payload,
          pending: false,
        },
      }
    case GET_ALL_CONFIGS_CLEAR_ERROR:
      return {
        ...state,
        get_all_configs: {
          ...state.get_all_configs,
          error: null,
          pending: false,
        },
      }
    case VALIDATE_AND_STORE_CONFIG_PENDING:
      return {
        ...state,
        validate_and_store_config: {
          ...state.validate_and_store_config,
          pending: true,
        },
      }
    case VALIDATE_AND_STORE_CONFIG_SUCCESS:
      return {
        ...state,
        validate_and_store_config: {
          ...state.validate_and_store_config,
          success: action.payload,
          pending: false,
        },
      }
    case VALIDATE_AND_STORE_CONFIG_ERROR:
      return {
        ...state,
        validate_and_store_config: {
          ...state.validate_and_store_config,
          error: action.payload,
          pending: false,
        },
      }
    case VALIDATE_AND_STORE_CONFIG_CLEAR_ERROR:
      return {
        ...state,
        validate_and_store_config: {
          ...state.validate_and_store_config,
          error: null,
        },
      }
    case UPDATE_CONFIG_PENDING:
      return {
        ...state,
        update_config: {
          ...state.update_config,
          pending: true,
        },
      }
    case UPDATE_CONFIG_SUCCESS:
      return {
        ...state,
        update_config: {
          ...state.update_config,
          success: action.payload,
          pending: false,
        },
      }
    case UPDATE_CONFIG_ERROR:
      return {
        ...state,
        update_config: {
          ...state.update_config,
          error: action.payload,
          pending: false,
        },
      }
    case UPDATE_CONFIG_CLEAR_ERROR:
      return {
        ...state,
        update_config: {
          ...state.update_config,
          error: null,
          pending: false,
        },
      }
    case DELETE_CONFIG_PENDING:
      return {
        ...state,
        delete_config: {
          ...state.delete_config,
          pending: true,
        },
      }
    case DELETE_CONFIG_SUCCESS:
      return {
        ...state,
        delete_config: {
          ...state.delete_config,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_CONFIG_ERROR:
      return {
        ...state,
        delete_config: {
          ...state.delete_config,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_CONFIG_FILE_PENDING:
      return {
        ...state,
        delete_config_file: {
          ...state.delete_config_file,
          pending: true,
        },
      }
    case DELETE_CONFIG_FILE_SUCCESS:
      return {
        ...state,
        delete_config_file: {
          ...state.delete_config_file,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_CONFIG_FILE_ERROR:
      return {
        ...state,
        delete_config_file: {
          ...state.delete_config_file,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_CONFIG_FILE_CLEAR_ERROR:
      return {
        ...state,
        delete_config_file: {
          ...state.delete_config_file,
          error: null,
        },
      }
    default:
      return state
  }
}

// Actions
export function updateLocation(payload) {
  return {type: UPDATE_LOCATION, payload}
}

function getAllConfigsPending() {
  return {type: GET_ALL_CONFIGS_PENDING}
}

function getAllConfigsSuccess(payload) {
  return {type: GET_ALL_CONFIGS_SUCCESS, payload: payload}
}

function getAllConfigsError(error) {
  return {type: GET_ALL_CONFIGS_ERROR, payload: error}
}

export function getAllConfigsClearError() {
  return {type: GET_ALL_CONFIGS_CLEAR_ERROR}
}

function validateAndStoreConfigPending() {
  return {type: VALIDATE_AND_STORE_CONFIG_PENDING}
}

function validateAndStoreConfigSuccess(payload) {
  return {type: VALIDATE_AND_STORE_CONFIG_SUCCESS, payload: payload}
}

function validateAndStoreConfigError(error) {
  return {type: VALIDATE_AND_STORE_CONFIG_ERROR, payload: error}
}

export function validateAndStoreConfigClearError() {
  return {type: VALIDATE_AND_STORE_CONFIG_CLEAR_ERROR}
}

function updateConfigPending() {
  return {type: UPDATE_CONFIG_PENDING}
}

function updateConfigSuccess(payload) {
  return {type: UPDATE_CONFIG_SUCCESS, payload: payload}
}

function updateConfigError(error) {
  return {type: UPDATE_CONFIG_ERROR, payload: error}
}

export function updateConfigClearError() {
  return {type: UPDATE_CONFIG_CLEAR_ERROR}
}

function deleteConfigPending() {
  return {type: DELETE_CONFIG_PENDING}
}

function deleteConfigSuccess(payload) {
  return {type: DELETE_CONFIG_SUCCESS, payload: payload}
}

function deleteConfigError(error) {
  return {type: DELETE_CONFIG_ERROR, payload: error}
}

function deleteConfigFilePending() {
  return {type: DELETE_CONFIG_FILE_PENDING}
}

function deleteConfigFileSuccess(payload) {
  return {type: DELETE_CONFIG_FILE_SUCCESS, payload: payload}
}

function deleteConfigFileError(error) {
  return {type: DELETE_CONFIG_FILE_ERROR, payload: error}
}

export function deleteConfigFileClearError(error) {
  return {type: DELETE_CONFIG_FILE_CLEAR_ERROR, payload: error}
}

// Operations
export const getAllConfigs = () => (dispatch) => {
  dispatch(getAllConfigsPending())
  return axios
    .get('/api/v1/configs?limit=500&offset=0')
    .then((res) => {
      dispatch(getAllConfigsSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(getAllConfigsError(error)))
}

export const validateAndStoreConfig =
  (configType, description, file, status, userID) => (dispatch) => {
    dispatch(validateAndStoreConfigPending())
    return dispatch(uploadConfigFile('/api/v1/files', file))
      .then((res) => {
        if (res.type === UPLOAD_CONFIG_FILE_ERROR) {
          return dispatch(validateAndStoreConfigError(res.payload))
        }
        const configData = {
          config_type: configType,
          description: description,
          config_file_path: res.toString(),
          status: status,
        }
        return axios
          .post('/api/v1/iec_interface/config', configData)
          .then((res) => {
            dispatch(validateAndStoreConfigSuccess(res))
            dispatch(
              createNotification(userID, {
                code: '201',
                message: I18n.t('notificationSuccess.ConfigurationUploaded'),
              }),
            )
          })
          .catch((error) => dispatch(validateAndStoreConfigError(error)))
      })
      .catch((error) => dispatch(validateAndStoreConfigError(error)))
  }

export const validateAndStoreConfigPut =
  (configId, configType, description, file, status, userID) => (dispatch) => {
    dispatch(validateAndStoreConfigPending())
    return dispatch(uploadConfigFile('/api/v1/files', file))
      .then((res) => {
        const configData = {
          config_type: configType,
          description: description,
          config_file_path: res.toString(),
          status: status,
        }
        return axios
          .put(`/api/v1/iec_interface/config/${configId}`, configData)
          .then((res) => {
            dispatch(validateAndStoreConfigSuccess(res))
            dispatch(
              createNotification(userID, {
                code: '201',
                message: I18n.t('notificationSuccess.ConfigurationUploaded'),
              }),
            )
          })
          .catch((error) => dispatch(validateAndStoreConfigError(error)))
      })
      .catch((error) => dispatch(validateAndStoreConfigError(error)))
  }

export const updateConfig = (id, values) => (dispatch) => {
  dispatch(updateConfigPending())
  return axios
    .put(`/api/v1/configs/${id}`, values)
    .then((res) => {
      dispatch(updateConfigSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(updateConfigError(error)))
}

export const deleteConfig = (id) => (dispatch) => {
  dispatch(deleteConfigPending())
  return axios
    .delete(`/api/v1/configs/${id}`)
    .then((res) => {
      dispatch(deleteConfigSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(deleteConfigError(error)))
}

export const deleteConfigFile = (id) => (dispatch) => {
  dispatch(deleteConfigFilePending())
  return axios
    .delete(`/api/v1/configs/${id}/file`)
    .then((res) => {
      dispatch(deleteConfigFileSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(deleteConfigFileError(error)))
}
