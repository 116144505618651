import {useState} from 'react'
import {useField} from 'formik'
import {classNames} from '../utils/classNames'
import {ExclamationCircleIcon} from '@heroicons/react/outline'
import {format} from 'date-fns'

const DatepickerCheckbox = ({children, ...props}) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`

  const [field] = useField({...props, type: 'checkbox'})

  return (
    <>
      <div className="relative mt-1 flex items-start">
        <div className="flex h-5 items-center">
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-lime-600 accent-lime-500 focus:ring-lime-500"
            id={props.id}
            {...field}
            {...props}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={props.id} className="font-medium text-gray-700">
            {props.label}
          </label>
        </div>
      </div>
      {/* {meta.touched && meta.error ? (
          <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
            {meta.error}
          </p>
        ) : null} */}
    </>
  )
}

export function DatePickerDefault(props) {
  const {validfrom, validto, labelvalidfrom, labelvalidto} = props
  const [field, meta] = useField(validfrom)
  const [field2, meta2, helpers2] = useField(validto)

  // Endless implementation
  let d = new Date()
  let year = d.getFullYear()
  let month = d.getMonth()
  let day = d.getDate()
  const endlessDate = new Date(year + 100, month, day)
  const [endless, setEndless] = useState(false)

  //const startDate = new Date()
  //const endDate = new Date()

  return (
    <>
      {/*valid_from */}
      <>
        <label
          htmlFor={props.validfromid || props.validfrom}
          className="block text-sm font-medium text-gray-700"
        >
          {`${labelvalidfrom}${props.required ? '*' : ''}`}
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            className={classNames(
              meta.touched && meta.error
                ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                : 'border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500',
              'block w-full rounded-md sm:text-sm',
            )}
            type={props.type}
            id={props.validfromid}
            {...field}
            {...props}
          />
          {meta.touched && meta.error ? (
            <>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            </>
          ) : null}
        </div>
        {meta.touched && meta.error ? (
          <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
            {meta.error}
          </p>
        ) : null}
      </>
      {/*valid_to */}
      {(!endless || !props.endlessfield) && (
        <>
          <label
            htmlFor={props.validtoid || props.validto}
            className="block text-sm font-medium text-gray-700"
          >
            {`${labelvalidto}${props.required ? '*' : ''}`}
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              className={classNames(
                meta2.touched && meta2.error
                  ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                  : 'border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm',
                'block w-full rounded-md sm:text-sm',
              )}
              type={props.type}
              id={props.validtoid}
              {...field2}
              {...props}
            />
            {meta2.touched && meta2.error ? (
              <>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
              </>
            ) : null}
          </div>
          {meta2.touched && meta2.error ? (
            <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
              {meta2.error}
            </p>
          ) : null}
        </>
      )}
      {props.endlessfield ? (
        <DatepickerCheckbox
          name="valid_to"
          label={props.endlessfieldlabel}
          id={props.checkboxid}
          onChange={() => {
            setEndless(!endless)
            const formattedDate = format(endlessDate, "yyyy-MM-dd'T'HH:mm")
            helpers2.setValue(formattedDate)
          }}
          checked={endless}
        />
      ) : null}
    </>
  )
}
