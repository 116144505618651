export enum YupValidationType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  ARRAY = 'array',
  OBJECT = 'object',
}

export enum InputYupValidationsType {
  REQUIRED = 'required',
  MIN = 'min',
  MAX = 'max',
  EMAIL = 'email',
  URL = 'url',
  TEST = 'test',
  WHEN = 'when',
  ONEOF = 'oneOf',
  FILESIZE = 'fileSize', // custom  special type to handle file UI
  FILETYPE = 'fileType', // custom  special type to handle file UI
  ENDDATE = 'endDate', // custom special to type to handle datepicker
}

export enum InputRhfValidationsType {
  REQUIRED = 'required',
  MIN = 'min',
  MAX = 'max',
  MAXLENGTH = 'maxLength',
  MINLENGTH = 'minLength',
  VALIDATE = 'validate',
  REQUIRED_IF_ANOTHER_VALUE = 'requiredIfAnotherValue',
  NOT_EQUAL_TO_FIELD_VALUE = 'notEqualToFieldValue',
}

export const fileMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'text/csv',
  'text/plain',
]
export const fileMineTypesLabels = [
  '*.doc',
  '*.docx',
  '*.jpeg, *.jpg',
  '*.png',
  '*.pdf',
  '*.csv',
  '*.ini',
]

export enum InputComponent {
  TEXT_INPUT = 'text',
  STATIC_TEXT = 'statictext',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  TEXTAREA = 'textarea',
  FILE = 'file',
  SELECT = 'select',
  ASYNCREACTSELECT = 'asyncreactselect',
  DATEPICKER = 'datePicker',
  CUSTOM = 'custom',
}

export type InputValidationParam = any

export const datePickerInputTypes = ['datetime-local', 'date'] as const
export const InputTypes = [...datePickerInputTypes, 'url', 'number', 'text'] as const
export type DatePickerInputType = (typeof datePickerInputTypes)[number]
export type InputType = (typeof InputTypes)[number]

export interface InputYupValidation {
  type: InputYupValidationsType
  params: InputValidationParam[]
}

export interface InputRhfValidation {
  type: InputRhfValidationsType
  params: InputValidationParam[]
}

export interface InputOption {
  value: string
  label: string
}

export interface InputConfig {
  component: InputComponent
  label: string
  name: string
  withRhfValidation?: boolean // path to error message in RHF state
  placeholder?: string
  value?: string | number | null
  validationType?: YupValidationType // never render
  validations?: InputYupValidation[] | InputRhfValidation[]
  url?: string // used for AsyncSelectInput, required
  optionValue?: string // used for AsyncSelectInput, required
  nestedOptionLabel?: string // used for AsyncSelectInput
  optionLabel?: string // used for AsyncSelectInput, required
  isMulti?: boolean // used for AsyncSelectInput, SelectInput
  clearOptions?: boolean // used for AsyncSelectInput if you like not co concat the options on each query but clear the options
  allowEndless?: boolean // used for DatePicker
  disableOnSelect?: boolean // used for component select
  inputType?: InputType // used for DatePicker and Text
  options?: InputOption[] // used for RadioGroup, SelectInput
  size?: number // used for TextArea, Text
  bold?: boolean // used for StaticText
  renderFn?: Element // used for custom component
  onUpdate?: (value: any) => void // used for FileField
}

export interface DynamicFormConfig {
  location?: string
  submitButtonClasses?: string
  submitButtonText: string
  formTitle: string
  description?: string
  name: string
  configVersion?: string
  fields: InputConfig[]
}
