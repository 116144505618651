import {useTranslation} from 'react-i18next'
import {updateConfig, updateConfigClearError} from '../../stores/configs'
import {useDispatch, useSelector} from 'react-redux'
import {TextInput} from '../FormInputs'
import {Formik, Form} from 'formik'
import {FormikSaveButton} from '../Buttons'

export default function FormEditConfiguration({description, configId}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const pending = useSelector((state) => state.configs.update_config.pending)
  const error = useSelector((state) => state.configs.update_config.error)
  const initialValues = {
    description: description || '',
  }

  const handleSubmit = async (values) => {
    dispatch(updateConfig(configId, values))
  }
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <TextInput
            label={t('userManagement.Description')}
            type="text"
            name="description"
            placeholder=""
          />
          <FormikSaveButton
            type="submit"
            testid="form-edit-configuration-submit"
            text={t('addEmployee.AddEmployee')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(updateConfigClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
