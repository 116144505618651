import {useCallback, useEffect, useRef} from 'react'
import {
  addEdge,
  Background,
  BackgroundVariant,
  Controls,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from 'reactflow'
import {useTranslation} from 'react-i18next'
import dagre from 'dagre'
import {Modal, ModalContentsBase, ModalOpenButton} from '../Modal'
import RelationIcon from '../../assets/Relation.svg'
import SpinnerSVG from '../../assets/Spinner.svg'
import {getIsExpanded, getLayoutedElements, getRenderData} from './dependancy'
import CustomNode from './CustomNode'
import DownloadButton from './DownloadButton'
import {classNames} from '../../utils/classNames'
import Legend from './Legend'
import {NODE_TYPE_ROOT, NODE_TYPE_PARENT} from './consts'

const nodeTypes = {
  custom: CustomNode,
}
const proOptions = {hideAttribution: true}
const edgeOptions = {
  animated: true,
  style: {
    stroke: 'gray',
  },
}

const DependencyView = ({
  root,
  children,
  parents,
  error,
  text,
  childrenAccessor,
  parentAccessor,
  hideOnMobile,
}) => {
  const {t} = useTranslation()
  const componentRef = useRef()

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges])

  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  useEffect(() => {
    const {initialNodes, initialEdges} = getRenderData({
      root,
      children,
      parents,
      childrenAccessor,
      parentAccessor,
    })

    const {nodes, edges} = getLayoutedElements(dagreGraph, initialNodes, initialEdges)

    setNodes(nodes)
    setEdges(edges)
  }, [parents, children, childrenAccessor, parentAccessor, root, setEdges, setNodes]) // eslint-disable-line react-hooks/exhaustive-deps

  const hide = (hidden, childEdgeID, childNodeID) => (nodeOrEdge) => {
    if (childEdgeID.includes(nodeOrEdge.id) || childNodeID.includes(nodeOrEdge.id))
      nodeOrEdge.hidden = hidden
    return nodeOrEdge
  }

  const checkTarget = (edge, id, isParent) => {
    return edge.filter((ed) => {
      return isParent ? ed.source !== id : ed.target !== id
    })
  }

  let outgoers = []
  let connectedEdges = []
  let stack = []

  const nodeClick = (some, node) => {
    const currentNodeID = node.id
    if (currentNodeID.includes(NODE_TYPE_ROOT)) {
      return
    }

    const isParent = currentNodeID.includes(NODE_TYPE_PARENT)
    const isCurrentExpanded = getIsExpanded(edges, currentNodeID, isParent)

    if (isCurrentExpanded) {
      stack.push(node)
      while (stack.length > 0) {
        let lastNOde = stack.pop()
        const isLastNodeParent = lastNOde.id.includes(NODE_TYPE_PARENT)

        let childnode = isLastNodeParent
          ? getIncomers(lastNOde, nodes, edges)
          : getOutgoers(lastNOde, nodes, edges)
        let childedge = checkTarget(
          getConnectedEdges([lastNOde], edges),
          currentNodeID,
          isLastNodeParent,
        )
        // eslint-disable-next-line no-loop-func
        childnode.map((goer) => {
          stack.push(goer)
          outgoers.push(goer)
          return goer
        })
        // eslint-disable-next-line no-loop-func
        childedge.map((edge) => {
          connectedEdges.push(edge)
          return edge
        })
      }
    } else {
      outgoers = isParent ? getIncomers(node, nodes, edges) : getOutgoers(node, nodes, edges)
      connectedEdges = checkTarget(getConnectedEdges([node], edges), currentNodeID, isParent)
    }
    let childNodeID = outgoers.map((node) => {
      return node.id
    })
    let childEdgeID = connectedEdges.map((edge) => {
      return edge.id
    })

    setNodes((node) => node.map(hide(isCurrentExpanded, childEdgeID, childNodeID)))
    setEdges((edge) => edge.map(hide(isCurrentExpanded, childEdgeID, childNodeID)))
  }

  return (
    <div className="flex justify-center">
      <Modal>
        <ModalOpenButton>
          <img
            alt="Dependency"
            src={RelationIcon}
            className={classNames(
              'mr-4 mt-0.5 h-5 w-5 cursor-pointer',
              hideOnMobile && 'hidden sm:inline',
            )}
          />
        </ModalOpenButton>
        <ModalContentsBase title={t('resource.Dependencies')} isFullWidth>
          <p className="hidden py-4 text-gray-700 md:inline">{text}</p>
          {error && t('notificationError.DependenciesError')}
          <div ref={componentRef} className="flex h-[600px] max-h-[calc(100vh-144px)] w-full">
            {!nodes.length && !edges.length ? (
              <div className="text-md flex h-48 w-full flex-col items-center justify-center p-3 lg:m-1">
                <img
                  alt="o"
                  src={SpinnerSVG}
                  className="mr-3 h-20 w-20 animate-spin text-lime-500"
                />
                {t('loading')}
              </div>
            ) : (
              <>
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  fitView
                  defaultEdgeOptions={edgeOptions}
                  nodeTypes={nodeTypes}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  proOptions={proOptions}
                  onNodeClick={nodeClick}
                >
                  <Background variant={BackgroundVariant.Dots} />
                  <Controls />
                  <MiniMap />
                  <DownloadButton />
                  <Legend />
                </ReactFlow>
              </>
            )}
          </div>
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export default DependencyView
