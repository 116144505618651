import {Formik, Form} from 'formik'
import {TextInput, SelectInput, SwitchWrapperFormik} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {
  postSubscription,
  postSubscriptionClearError,
  getSubscriptionSelectors,
} from '../../stores/subscriptions'
import {getWorkflows} from '../../stores/workflows'
import {useEffect} from 'react'

export default function FormAddSubscription() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(getSubscriptionSelectors())
  }, [dispatch])

  useEffect(() => {
    dispatch(getWorkflows())
  }, [dispatch])

  const workflows = useSelector((state) => state.workflows.get_workflows.success)

  const workflowsOptions = workflows?.map(({id, name}) => ({
    value: id,
    label: name,
  }))

  const subscriptionSelectors = useSelector(
    (state) => state.subscriptions.get_subscription_selectors.success,
  )

  const options = subscriptionSelectors.map(({value, label}) => ({
    value: value,
    label: t(`subscriptions.${label}`),
  }))

  const pending = useSelector((state) => state.subscriptions.post_subscription.pending)
  const error = useSelector((state) => state.subscriptions.post_subscription.error)

  const initialValues = {
    description: '',
    payload_filter: '',
    db_filter: '',
    endpoint_selector: '',
    workflow_id: '',
    active: false,
    take_over: false,
  }

  const validationSchema = Yup.object({
    endpoint_selector: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('subscriptions.EndPoint')}),
    ),
  })

  const handleSubmit = (values, actions) => {
    // db_filter ans payload_filter should be set to null when not entered -> Requirement by API & Workflow Engine
    values.db_filter = values.db_filter === '' ? (values.db_filter = null) : values.db_filter
    values.payload_filter =
      values.payload_filter === '' ? (values.payload_filter = null) : values.payload_filter
    dispatch(postSubscription(values))
    actions.resetForm()
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        <Form>
          <TextInput
            label={t('subscriptions.Description')}
            name="description"
            type="text"
            placeholder=""
          />
          <TextInput
            label={t('subscriptions.PayloadFilter')}
            name="payload_filter"
            type="text"
            placeholder=""
          />
          <TextInput
            label={t('subscriptions.DBFilter')}
            name="db_filter"
            type="text"
            placeholder=""
          />
          <SelectInput
            label={t('subscriptions.EndPoints')}
            name="endpoint_selector"
            type="text"
            placeholder=""
            required
            options={options}
          />
          {/*fetch workflows Can I do AsyncSelectInput? Seems not*/}
          <SelectInput
            label={t('subscriptions.SelectWorkflow')}
            name="workflow_id"
            type="text"
            placeholder=""
            options={workflowsOptions}
          />
          {/* active */}
          <SwitchWrapperFormik name="active" label={t('subscriptions.Active')} />
          {/* take_over */}
          <SwitchWrapperFormik name="take_over" label={t('subscriptions.TakeOver')} />
          <FormikSaveButton
            type="submit"
            testid="form-add-subscription"
            text={t('subscriptions.Save')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(postSubscriptionClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
