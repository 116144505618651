import {
  datePickerInputTypes,
  fileMimeTypes,
  InputComponent,
  InputConfig,
  InputRhfValidationsType,
  YupValidationType,
} from '../../formRendererRHF/types'
import {textSizes} from '../../Text'

export const getFormDefinitions = (t, domainOptions) => [
  {
    label: 'formBuilder.Name',
    component: InputComponent.TEXT_INPUT,
    name: 'name',
    withRhfValidation: true,
    validations: [
      {
        type: 'required',
        params: [t('yupError.ThisFieldIsRequired')],
      },
      {
        type: InputRhfValidationsType.MAXLENGTH,
        params: [255, t('yupError.MaxCharsAllowed', {numberOfChars: 255})],
      },
    ],
  },
  {
    label: 'formBuilder.Description',
    component: InputComponent.TEXT_INPUT,
    name: 'description',
    withRhfValidation: true,
    validations: [
      {
        type: 'required',
        params: [t('yupError.ThisFieldIsRequired')],
      },
      {
        type: InputRhfValidationsType.MAXLENGTH,
        params: [255, t('yupError.MaxCharsAllowed', {numberOfChars: 255})],
      },
    ],
  },
  {
    label: 'formBuilder.Title',
    component: InputComponent.TEXT_INPUT,
    name: 'formTitle',
    withRhfValidation: true,
    validations: [
      {
        type: 'required',
        params: [t('yupError.ThisFieldIsRequired')],
      },
    ],
  },
  {
    label: 'formBuilder.SubmitButtonText',
    component: InputComponent.TEXT_INPUT,
    name: 'submitButtonText',
    withRhfValidation: true,
    validations: [
      {
        type: 'required',
        params: [t('yupError.ThisFieldIsRequired')],
      },
    ],
  },
  {
    label: 'formBuilder.SubmitButtonColors',
    component: InputComponent.TEXT_INPUT,
    name: 'submitButtonClasses',
  },
  {
    component: InputComponent.SELECT,
    name: 'location',
    label: 'formBuilder.Domain',
    options: domainOptions,
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED,
        params: [t('yupError.ThisFieldIsRequired')],
      },
    ],
  },
]

export const getBasisConfig = (t): InputConfig[] => [
  {
    label: t('formBuilder.InputComponent'),
    component: InputComponent.SELECT,
    name: 'component',
    disableOnSelect: true,
    withRhfValidation: true,
    options: Object.values(InputComponent).map((value) => {
      return {value: value, label: value}
    }),
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED,
        params: [t('formBuilder.RequiredField')],
      },
    ],
  },
  {
    label: t('formBuilder.InputName'),
    component: InputComponent.TEXT_INPUT,
    name: 'name',
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED,
        params: [t('formBuilder.RequiredField')],
      },
    ],
  },
  {
    label: t('formBuilder.InputLabel'),
    component: InputComponent.TEXT_INPUT,
    name: 'label',
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED,
        params: [t('formBuilder.RequiredField')],
      },
    ],
  },
]

export const getPlaceholder = (t) => [
  {
    label: t('formBuilder.InputPlaceHolder'),
    component: InputComponent.TEXT_INPUT,
    name: 'placeholder',
  },
]

export const getStaticText = (t) => [
  {
    label: t('formBuilder.StaticTextBold'),
    component: InputComponent.CHECKBOX,
    name: 'bold',
  },
  {
    label: t('formBuilder.StaticTextSize'),
    component: InputComponent.SELECT,
    name: 'size',
    defaultValue: 0,
    options: textSizes.map((size, index) => ({
      value: index,
      label: size,
    })),
  },
]

export const getOptions = (t) => [
  {
    label: t('formBuilder.InputOptions.value'),
    component: InputComponent.TEXT_INPUT,
    name: 'value',
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED,
        params: [t('formBuilder.RequiredField')],
      },
    ],
  },
  {
    label: t('formBuilder.InputOptions.label'),
    component: InputComponent.TEXT_INPUT,
    name: 'label',
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED,
        params: [t('formBuilder.RequiredField')],
      },
    ],
  },
]

export const getTextarea = (t) => [
  ...getPlaceholder(t),
  {
    label: t('formBuilder.TextAreaWideness'),
    component: InputComponent.TEXT_INPUT,
    name: 'size',
    validationType: YupValidationType.NUMBER,
    inputType: 'number',
  },
]

export const getDatePickerConfig = (t) => [
  {
    label: t('formBuilder.InputType'),
    component: InputComponent.SELECT,
    name: 'inputType',
    options: datePickerInputTypes.map((type) => ({value: type, label: type})),
  },
  {
    label: t('formBuilder.ValidToEndless'),
    component: InputComponent.CHECKBOX,
    name: 'allowEndless',
  },
]

export const getAsyncReactSelectConfig = (t) => [
  {
    label: t('formBuilder.AsyncSelectInputOptionUrl'),
    component: InputComponent.TEXT_INPUT,
    name: 'url',
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED,
        params: [t('formBuilder.RequiredField')],
      },
    ],
  },
  {
    label: t('formBuilder.AsyncSelectInputOptionValue'),
    component: InputComponent.TEXT_INPUT,
    name: 'optionValue',
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED,
        params: [t('formBuilder.RequiredField')],
      },
    ],
  },
  {
    label: t('formBuilder.AsyncSelectInputOptionLabel'),
    component: InputComponent.TEXT_INPUT,
    name: 'optionLabel',
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED,
        params: [t('formBuilder.RequiredField')],
      },
    ],
  },
  {
    label: t('formBuilder.AsyncSelectInputNestedOptionLabel'),
    component: InputComponent.TEXT_INPUT,
    name: 'nestedOptionLabel',
  },
]

export const getIsMulti = (t) => [
  {
    label: t('formBuilder.IsMulti'),
    component: InputComponent.CHECKBOX,
    name: 'isMulti',
  },
]

export const getValidationRequired = (t) => ({
  component: InputComponent.TEXT_INPUT,
  name: t('validations.required.0'),
  label: t('formBuilder.ValidationRequired'),
})

export const getValidationByComponent = (t, component) => {
  const extras = validationByComponent(t)[component] || []
  const required = component && component !== InputComponent.STATIC_TEXT && getValidationRequired(t)
  return [required, ...extras].filter((n) => n)
}

const validationParams = (t, type) => [
  {
    label: t(`formBuilder.validation.${type}.value`),
    component: InputComponent.TEXT_INPUT,
    inputType: 'number',
    name: `validations.${type}.0`,
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.MIN,
        params: [1, t('formBuilder.validation.onlyPositiveValues')],
      },
    ],
  },
  {
    label: t(`formBuilder.validation.${type}.message`),
    component: InputComponent.TEXT_INPUT,
    name: `validations.${type}.1`,
    withRhfValidation: true,
    validations: [
      {
        type: InputRhfValidationsType.REQUIRED_IF_ANOTHER_VALUE,
        params: [`validations.${type}.0`, t('formBuilder.RequiredField')],
      },
    ],
  },
]

const validationByComponent = (t) => ({
  [InputComponent.TEXT_INPUT]: [
    ...validationParams(t, 'min'),
    ...validationParams(t, 'max'),
    {
      label: t(`formBuilder.validation.email.message`),
      component: InputComponent.TEXT_INPUT,
      name: 'validations.email.0',
    },
    {
      label: t(`formBuilder.validation.unique.message`),
      component: InputComponent.TEXT_INPUT,
      name: 'validations.test.0',
    },
    {
      label: t(`formBuilder.validation.unique.url`),
      component: InputComponent.TEXT_INPUT,
      name: 'validations.test.1',
      validations: [
        {
          type: InputRhfValidationsType.REQUIRED_IF_ANOTHER_VALUE,
          params: [`validations.unique.0`, t('formBuilder.RequiredField')],
        },
      ],
    },
    {
      label: t(`formBuilder.validation.unique.accessor`),
      component: InputComponent.TEXT_INPUT,
      name: 'validations.test.2',
      validations: [
        {
          type: InputRhfValidationsType.REQUIRED_IF_ANOTHER_VALUE,
          params: [`validations.unique.0`, t('formBuilder.RequiredField')],
        },
      ],
    },
  ],
  [InputComponent.TEXTAREA]: [...validationParams(t, 'min')],
  [InputComponent.CHECKBOX]: [],
  [InputComponent.RADIO]: [],
  [InputComponent.FILE]: [
    ...validationParams(t, 'fileSize'),
    {
      label: t('formBuilder.validation.fileType.value'),
      component: InputComponent.SELECT,
      isMulti: true,
      name: `validations.fileType.0`,
      options: fileMimeTypes.map((type, i) => ({value: i, label: type})),
    },
    {
      label: t('formBuilder.validation.fileType.message'),
      component: InputComponent.TEXT_INPUT,
      name: `validations.fileType.1`,
      withRhfValidation: true,
      validations: [
        {
          type: InputRhfValidationsType.REQUIRED_IF_ANOTHER_VALUE,
          params: ['validations.fileType.0', t('formBuilder.RequiredField')],
        },
      ],
    },
  ],
  [InputComponent.SELECT]: [],
  [InputComponent.ASYNCREACTSELECT]: [],
  [InputComponent.DATEPICKER]: [
    {
      label: t(`formBuilder.validation.dateMin.value`),
      component: InputComponent.DATEPICKER,
      name: `validations.min.0`,
    },
    {
      label: t(`formBuilder.validation.dateMin.message`),
      component: InputComponent.TEXT_INPUT,
      name: `validations.min.1`,
      withRhfValidation: true,
      validations: [
        {
          type: InputRhfValidationsType.REQUIRED_IF_ANOTHER_VALUE,
          params: [`validations.min.0`, t('formBuilder.RequiredField')],
        },
      ],
    },
    {
      label: t(`formBuilder.validation.dateMax.value`),
      component: InputComponent.DATEPICKER,
      name: `validations.max.0`,
    },
    {
      label: t(`formBuilder.validation.dateMax.message`),
      component: InputComponent.TEXT_INPUT,
      name: `validations.max.1`,
      withRhfValidation: true,
      validations: [
        {
          type: InputRhfValidationsType.REQUIRED_IF_ANOTHER_VALUE,
          params: [`validations.max.0`, t('formBuilder.RequiredField')],
        },
      ],
    },
    {
      label: t(`formBuilder.validation.endDate.value`),
      component: InputComponent.TEXT_INPUT,
      name: `validations.endDate.0`,
      withRhfValidation: true,
      validations: [
        {
          type: InputRhfValidationsType.NOT_EQUAL_TO_FIELD_VALUE,
          params: [`name`, t('formBuilder.validation.endDate.valid')],
        },
      ],
    },
    {
      label: t(`formBuilder.validation.endDate.message`),
      component: InputComponent.TEXT_INPUT,
      name: `validations.endDate.1`,
      withRhfValidation: true,
      validations: [
        {
          type: InputRhfValidationsType.REQUIRED_IF_ANOTHER_VALUE,
          params: [`validations.endDate.0`, t('formBuilder.RequiredField')],
        },
      ],
    },
  ],
})
