import axios from '../services/api'

// Types

const VALIDATE_CSV_PENDING = 'idfunctiondb/import/VALIDATE_CSV_PENDING'
const VALIDATE_CSV_SUCCESS = 'idfunctiondb/import/VALIDATE_CSV_SUCCESS'
const VALIDATE_CSV_ERROR = 'idfunctiondb/import/VALIDATE_CSV_ERROR'
const VALIDATE_CSV_CLEAR_ERROR = 'idfunctiondb/import/VALIDATE_CSV_CLEAR_ERROR'

const EXPORT_CSV_PENDING = 'idfunctiondb/import/EXPORT_CSV_PENDING'
const EXPORT_CSV_SUCCESS = 'idfunctiondb/import/EXPORT_CSV_SUCCESS'
const EXPORT_CSV_ERROR = 'idfunctiondb/import/EXPORT_CSV_ERROR'
const EXPORT_CSV_CLEAR_ERROR = 'idfunctiondb/import/EXPORT_CSV_CLEAR_ERROR'

// Initial
const initialState = {
  validate_csv: {
    success: null,
    pending: false,
    error: null,
  },

  export_csv: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VALIDATE_CSV_PENDING:
      return {
        ...state,
        validate_csv: {
          ...state.validate_csv,
          pending: true,
        },
      }
    case VALIDATE_CSV_SUCCESS:
      return {
        ...state,
        validate_csv: {
          ...state.validate_csv,
          success: action.payload,
          pending: false,
        },
      }
    case VALIDATE_CSV_ERROR:
      return {
        ...state,
        validate_csv: {
          ...state.validate_csv,
          error: action.payload,
          pending: false,
        },
      }
    case VALIDATE_CSV_CLEAR_ERROR:
      return {
        ...state,
        validate_csv: {
          ...state.validate_csv,
          error: null,
        },
      }
    case EXPORT_CSV_PENDING:
      return {
        ...state,
        export_csv: {
          ...state.export_csv,
          pending: true,
        },
      }
    case EXPORT_CSV_SUCCESS:
      return {
        ...state,
        export_csv: {
          ...state.export_csv,
          success: action.payload,
          pending: false,
        },
      }
    case EXPORT_CSV_ERROR:
      return {
        ...state,
        export_csv: {
          ...state.export_csv,
          error: action.payload,
          pending: false,
        },
      }
    case EXPORT_CSV_CLEAR_ERROR:
      return {
        ...state,
        export_csv: {
          ...state.export_csv,
          error: null,
        },
      }
    default:
      return state
  }
}

// Actions

function validateCsvPending() {
  return {type: VALIDATE_CSV_PENDING}
}
function validateCsvSuccess(payload) {
  return {type: VALIDATE_CSV_SUCCESS, payload: payload}
}
function validateCsvError(error) {
  return {type: VALIDATE_CSV_ERROR, payload: error}
}
export function validateCsvClearError() {
  return {type: VALIDATE_CSV_CLEAR_ERROR}
}

function exportCsvPending() {
  return {type: EXPORT_CSV_PENDING}
}
function exportCsvSuccess(payload) {
  return {type: EXPORT_CSV_SUCCESS, payload: payload}
}
function exportCsvError(error) {
  return {type: EXPORT_CSV_ERROR, payload: error}
}
export function exportCsvClearError() {
  return {type: EXPORT_CSV_CLEAR_ERROR}
}

// Operations
export const validateCsv = (url, form) => (dispatch) => {
  dispatch(validateCsvPending())
  return axios
    .post(url, form)
    .then((res) => {
      dispatch(validateCsvSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(validateCsvError(error)))
}

export const exportCsv = (url) => (dispatch) => {
  dispatch(exportCsvPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(exportCsvSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(exportCsvError(error)))
}
