import {Formik, Form} from 'formik'
import {TextInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {
  updateAccessProfileType,
  updateAccessProfileTypeClearError,
} from '../../stores/access_profiles'

export default function FormEditResourceType(props) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const pending = useSelector((state) => state.access_profiles.update_access_profile_type.pending)
  const error = useSelector((state) => state.access_profiles.update_access_profile_type.error)

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '256'})) // ?? gave it 256
      .required(t('yupError.ThisFieldIsRequired', {field: t('userManagement.Name')})),
  })

  const handleSubmit = (values, actions) => {
    dispatch(
      updateAccessProfileType(
        `/api/v1/access_profiles_types/${props.selectedItem.value}`,
        {name: values.name},
        userID,
      ),
    )
    actions.resetForm()
  }

  return (
    <>
      <Formik
        initialValues={{name: props.selectedItem.label}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextInput
            name="name"
            label={t('addResourceForm.ResourceType')}
            type="text"
            placeholder=""
          />
          <FormikSaveButton
            type="submit"
            testid="form-edit-resource-type-submit"
            text={t('addInventoryForm.InventoryAdd')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(updateAccessProfileTypeClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
