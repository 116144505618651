import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {
  bulkCreatePersonalizedCards,
  bulkCreatePersonalizedCardsClearError,
  getCardProfiles,
} from '../../stores/cards'
import {iso8061UTC} from '../../utils/datetime'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'

export default function FormAddCardPersonalized() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const [isEmployee, setIsEmployee] = useState(true)
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const cardProfiles = useSelector((state) => state.cards.get_card_profiles.success)
  const cardProfilesError = useSelector((state) => state.cards.get_card_profiles.error)

  const {pending, error} = useSelector((state) => state.cards.bulk_create_personalized_cards)

  useEffect(() => {
    dispatch(getCardProfiles(`/api/v1/cards/profiles`))
  }, [dispatch, cardProfilesError?.message])

  const handleSubmit = (values) => {
    delete values.type
    if (isEmployee) {
      delete values.visitors
    } else {
      delete values.employees
    }

    values.valid_from = iso8061UTC(values.valid_from)
    values.valid_to = iso8061UTC(values.valid_to)
    dispatch(bulkCreatePersonalizedCards(values))
  }

  const fields = [
    {
      component: InputComponent.RADIO,
      name: `type`,
      label: 'identity.EmployeeOrVisitor',
      options: [
        {
          value: 'employee',
          label: 'addCard.Employees',
        },
        {
          value: 'visitor',
          label: 'addCard.Visitors',
        },
      ],
      defaultValue: 'employee',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    isEmployee && {
      component: InputComponent.ASYNCREACTSELECT,
      name: `employees`,
      label: 'Person',
      placeholder: 'addCard.SelectEmployees',
      url: '/api/v1/employees?q=',
      optionLabel: 'full_name',
      nestedOptions: true,
      nestedOptionLabel: 'person',
      optionValue: 'id',
      isMulti: true,
      validationType: YupValidationType.ARRAY,
      validations: [
        {
          type: InputYupValidationsType.MIN,
          params: [1, 'yupError.ThisFieldIsRequired'],
        },
      ],
    },
    !isEmployee && {
      component: InputComponent.ASYNCREACTSELECT,
      name: `visitors`,
      label: 'Person',
      placeholder: 'addCard.SelectVisitors',
      url: '/api/v1/visitors?q=',
      optionLabel: 'full_name',
      isMulti: true,
      nestedOptions: true,
      nestedOptionLabel: 'person',
      optionValue: 'id',
      validationType: YupValidationType.ARRAY,
      validations: [
        {
          type: InputYupValidationsType.MIN,
          params: [1, 'yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `card_profile_id`,
      label: 'addCard.CardProfile',
      placeholder: 'addCard.SelectCardProfile',
      options: cardProfiles.map((o) => ({
        value: o.id,
        label: o.name,
      })),
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: 'addEmployee.Domain',
      placeholder: 'addEmployee.DomainPlaceholder',
      options: permissions.createCardLocations,
      defaultValue: permissions.createCardLocations && permissions.createCardLocations[0]?.value,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_from',
      inputType: 'datetime-local',
      label: 'addCard.ValidFrom',
      defaultValue: new Date().toISOString().slice(0, -8),
      validationType: YupValidationType.DATE,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_to',
      inputType: 'datetime-local',
      label: 'addCard.ValidTo',
      validationType: YupValidationType.DATE,
      allowEndless: true,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
        {
          type: InputYupValidationsType.ENDDATE, // special type for validation
          params: ['valid_from', t('yupError.DateRange')],
        },
      ],
    },
  ].filter(Boolean)
  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields: fields,
          name: 'add-card-personalized-submit',
          submitButtonText: t('addCard.Add'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        watchFieldName={'type'}
        onFieldChange={(value) => {
          setIsEmployee(value === 'employee')
        }}
        infoMessage={
          <>
            <Notification
              error={error}
              setError={() => dispatch(bulkCreatePersonalizedCardsClearError())}
            />
          </>
        }
      />
    </>
  )
}
