import axios from '../services/api'
import {createNotification, createNotificationOnClient} from './notifications'
import I18n from '../i18n'
import {fetchMe} from './auth'

// Types
const GET_COMPANIES_PENDING = 'idfunctiondb/companies/GET_COMPANIES_PENDING'
const GET_COMPANIES_SUCCESS = 'idfunctiondb/companies/GET_COMPANIES_SUCCESS'
const GET_COMPANIES_ERROR = 'idfunctiondb/companies/GET_COMPANIES_ERROR'
const GET_COMPANIES_CLEAR_ERROR = 'idfunctiondb/companies/GET_COMPANIES_CLEAR_ERROR'

const GET_COMPANY_PENDING = 'idfunctiondb/companies/GET_COMPANY_PENDING'
const GET_COMPANY_SUCCESS = 'idfunctiondb/companies/GET_COMPANY_SUCCESS'
const GET_COMPANY_ERROR = 'idfunctiondb/companies/GET_COMPANY_ERROR'

const SET_COMPANY_PENDING = 'idfunctiondb/companies/SET_COMPANY_PENDING'
const SET_COMPANY_SUCCESS = 'idfunctiondb/companies/SET_COMPANY_SUCCESS'
const SET_COMPANY_ERROR = 'idfunctiondb/companies/SET_COMPANY_ERROR'
const SET_COMPANY_CLEAR_ERROR = 'idfunctiondb/companies/SET_COMPANY_CLEAR_ERROR'

const SET_DEFAULT_COMPANY_PENDING = 'idfunctiondb/companies/SET_DEFAULT_COMPANY_PENDING'
const SET_DEFAULT_COMPANY_SUCCESS = 'idfunctiondb/companies/SET_DEFAULT_COMPANY_SUCCESS'
const SET_DEFAULT_COMPANY_ERROR = 'idfunctiondb/companies/SET_DEFAULT_COMPANY_ERROR'
const SET_DEFAULT_COMPANY_CLEAR_ERROR = 'idfunctiondb/companies/SET_DEFAULT_COMPANY_CLEAR_ERROR'

const DELETE_COMPANY_PENDING = 'idfunctiondb/companies/DELETE_COMPANY_PENDING'
const DELETE_COMPANY_SUCCESS = 'idfunctiondb/companies/DELETE_COMPANY_SUCCESS'
const DELETE_COMPANY_ERROR = 'idfunctiondb/companies/DELETE_COMPANY_ERROR'
const DELETE_COMPANY_CLEAR_ERROR = 'idfunctiondb/companies/DELETE_COMPANY_CLEAR_ERROR'

const CREATE_COMPANY_PENDING = 'idfunctiondb/companies/CREATE_COMPANY_PENDING'
const CREATE_COMPANY_SUCCESS = 'idfunctiondb/companies/CREATE_COMPANY_SUCCESS'
const CREATE_COMPANY_ERROR = 'idfunctiondb/companies/CREATE_COMPANY_ERROR'
const CREATE_COMPANY_CLEAR_ERROR = 'idfunctiondb/companies/CREATE_COMPANY_CLEAR_ERROR'

const initialState = {
  get_companies: {
    success: [],
    pending: false,
    error: null,
  },

  get_company: {
    success: [],
    pending: false,
    error: null,
  },

  create_company: {
    success: [],
    pending: false,
    error: null,
  },

  set_company: {
    success: [],
    pending: false,
    error: null,
  },
  set_default_company: {
    success: null,
    pending: false,
    error: null,
  },
  delete_company: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_COMPANIES_PENDING:
      return {
        ...state,
        get_companies: {
          ...state.get_companies,
          pending: true,
        },
      }
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        get_companies: {
          ...state.get_companies,
          success: action.payload,
          pending: false,
        },
      }
    case GET_COMPANIES_ERROR:
      return {
        ...state,
        get_companies: {
          ...state.get_companies,
          error: action.payload,
          pending: false,
        },
      }
    case GET_COMPANIES_CLEAR_ERROR:
      return {
        ...state,
        get_companies: {
          ...state.get_companies,
          error: null,
          pending: false,
        },
      }
    case GET_COMPANY_PENDING:
      return {
        ...state,
        get_company: {
          ...state.get_company,
          pending: true,
        },
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        get_company: {
          ...state.get_company,
          success: action.payload,
          pending: false,
        },
      }
    case GET_COMPANY_ERROR:
      return {
        ...state,
        get_company: {
          ...state.get_company,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_COMPANY_PENDING:
      return {
        ...state,
        create_company: {
          ...state.create_company,
          pending: true,
        },
      }
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        create_company: {
          ...state.create_company,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_COMPANY_ERROR:
      return {
        ...state,
        create_company: {
          ...state.create_company,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_COMPANY_CLEAR_ERROR:
      return {
        ...state,
        create_company: {
          ...state.create_company,
          error: null,
          pending: false,
        },
      }
    case SET_COMPANY_PENDING:
      return {
        ...state,
        set_company: {
          ...state.set_company,
          pending: true,
        },
      }
    case SET_COMPANY_SUCCESS:
      return {
        ...state,
        set_company: {
          ...state.set_company,
          success: action.payload,
          pending: false,
        },
      }
    case SET_COMPANY_ERROR:
      return {
        ...state,
        set_company: {
          ...state.set_company,
          error: action.payload,
          pending: false,
        },
      }
    case SET_COMPANY_CLEAR_ERROR:
      return {
        ...state,
        set_company: {
          ...state.set_company,
          error: null,
          pending: false,
        },
      }
    case SET_DEFAULT_COMPANY_PENDING:
      return {
        ...state,
        set_default_company: {
          ...state.set_default_company,
          pending: true,
        },
      }
    case SET_DEFAULT_COMPANY_SUCCESS:
      return {
        ...state,
        set_default_company: {
          ...state.set_default_company,
          success: action.payload,
          pending: false,
        },
      }
    case SET_DEFAULT_COMPANY_ERROR:
      return {
        ...state,
        set_default_company: {
          ...state.set_default_company,
          error: action.payload,
          pending: false,
        },
      }
    case SET_DEFAULT_COMPANY_CLEAR_ERROR:
      return {
        ...state,
        set_default_company: {
          ...state.set_default_company,
          error: null,
        },
      }
    case DELETE_COMPANY_PENDING:
      return {
        ...state,
        delete_company: {
          ...state.delete_company,
          pending: true,
        },
      }
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        get_companies: {
          ...state.get_companies,
          success: state.get_companies.success.filter(
            (company) => company.id !== action.payload.companyId,
          ),
        },
        delete_company: {
          ...state.delete_company,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_COMPANY_ERROR:
      return {
        ...state,
        delete_company: {
          ...state.delete_company,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_COMPANY_CLEAR_ERROR:
      return {
        ...state,
        delete_company: {
          ...state.delete_company,
          error: null,
        },
      }
    default:
      return state
  }
}

// Actions
function getCompaniesPending() {
  return {type: GET_COMPANIES_PENDING}
}

function getCompaniesSuccess(payload) {
  return {type: GET_COMPANIES_SUCCESS, payload: payload}
}

function getCompaniesError(error) {
  return {type: GET_COMPANIES_ERROR, payload: error}
}

export function getCompaniesCLearError() {
  return {type: GET_COMPANIES_CLEAR_ERROR}
}

function getCompanyPending() {
  return {type: GET_COMPANY_PENDING}
}

function getCompanySuccess(payload) {
  return {type: GET_COMPANY_SUCCESS, payload: payload}
}

function getCompanyError(error) {
  return {type: GET_COMPANY_ERROR, payload: error}
}

function createCompanyPending() {
  return {type: CREATE_COMPANY_PENDING}
}

function createCompanySuccess(payload) {
  return {type: CREATE_COMPANY_SUCCESS, payload: payload}
}

function createCompanyError(error) {
  return {type: CREATE_COMPANY_ERROR, payload: error}
}

export function createCompanyClearError() {
  return {type: CREATE_COMPANY_CLEAR_ERROR}
}

function setCompanyPending() {
  return {type: SET_COMPANY_PENDING}
}

function setCompanySuccess(payload) {
  return {type: SET_COMPANY_SUCCESS, payload: payload}
}

function setCompanyError(error) {
  return {type: SET_COMPANY_ERROR, payload: error}
}

export function setCompanyClearError() {
  return {type: SET_COMPANY_CLEAR_ERROR}
}

function setDefaultCompanyPending() {
  return {type: SET_DEFAULT_COMPANY_PENDING}
}

function setDefaultCompanySuccess(payload) {
  return {type: SET_DEFAULT_COMPANY_SUCCESS, payload: payload}
}

function setDefaultCompanyError(error) {
  return {type: SET_DEFAULT_COMPANY_ERROR, payload: error}
}

export function setDefaultCompanyClearError() {
  return {type: SET_DEFAULT_COMPANY_CLEAR_ERROR}
}

function deleteCompanyPending() {
  return {type: DELETE_COMPANY_PENDING}
}

function deleteCompanySuccess(payload) {
  return {type: DELETE_COMPANY_SUCCESS, payload: payload}
}

function deleteCompanyError(error) {
  return {type: DELETE_COMPANY_ERROR, payload: error}
}

export function deleteCompanyClearError() {
  return {type: DELETE_COMPANY_CLEAR_ERROR}
}

// Operations
export const getCompanies =
  (limit = 500, offset = 0) =>
  (dispatch) => {
    dispatch(getCompaniesPending())
    return axios
      .get(`/api/v1/companies?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getCompaniesSuccess(res.data.results))
        return res.data
      })
      .catch((error) => dispatch(getCompaniesError(error)))
  }

export const getCompany = (id) => (dispatch) => {
  dispatch(getCompanyPending())
  return axios
    .get(`/api/v1/companies/${id}`)
    .then((res) => {
      dispatch(getCompanySuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getCompanyError(error)))
}

export const createCompany = (values, notify, userID) => (dispatch) => {
  dispatch(createCompanyPending())
  return axios
    .post(`/api/v1/companies`, values)
    .then((res) => {
      dispatch(createCompanySuccess(res.data))
      if (notify) {
        dispatch(
          createNotification(userID, {
            code: res.status,
            message: I18n.t(`notificationSuccess.${res.data.notification_key}`, {
              name: res.data.name,
            }),
          }),
        )
      }
      return res.data
    })
    .catch((error) => {
      dispatch(createCompanyError(error))
    })
}

export const setCompany = (id, values, notify) => (dispatch) => {
  dispatch(setCompanyPending())
  return axios
    .put(`/api/v1/companies/${id}`, values)
    .then((res) => {
      dispatch(setCompanySuccess(res.data))
      if (notify) {
        dispatch(createNotificationOnClient(res.status, 'notificationSuccess.CompanyUpdated'))
      }
      return res.data
    })
    .catch((error) => dispatch(setCompanyError(error)))
}

export const setDefaultCompany = (values, notify) => (dispatch) => {
  dispatch(setDefaultCompanyPending())
  return axios
    .post(`/api/v1/auth/me/preferences`, values) // TODO_174: API URL ENDPOINT
    .then((res) => {
      dispatch(setDefaultCompanySuccess(res.data))
      if (notify) {
        dispatch(
          createNotificationOnClient(res.status, 'notificationSuccess.DefaultCompanyUpdated'),
        )
      }
      return res.data
    })
    .then(() => dispatch(fetchMe()))
    .catch((error) => dispatch(setDefaultCompanyError(error)))
}

export const deleteCompany = (id, userID) => (dispatch) => {
  dispatch(deleteCompanyPending())
  return axios
    .delete(`/api/v1/companies/${id}`)
    .then((res) => {
      dispatch(deleteCompanySuccess({...res, companyId: id}))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.CompanyDeleted', {name: res.data}), //TODO: Company name is not showing up on the notification
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(deleteCompanyError(error)))
}
