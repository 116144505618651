import {Formik, Form} from 'formik'
import {useState, useEffect, useRef} from 'react'
import {TextInputLabelOnLeft, TextInputCustomAttribute, SelectInputLabelOnLeft} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {PencilAltIcon} from '@heroicons/react/solid'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'
import {setPerson} from '../../stores/persons'
import {
  setVisitor,
  setVisitorClearError,
  createVisitorCustomAttribute,
  deleteVisitorCustomAttribute,
} from '../../stores/visitors'
import {isEmpty} from '../../utils/custom_attributes'
import {getCompanies, getCompaniesCLearError} from '../../stores/companies'
import {formatInPreferredTimezone, parseWithTimeZone} from '../../utils/datetime'

export default function FormEditVisitor({
  data,
  identityUpdatePermission,
  identityUpdateCustomAttribePermission,
}) {
  // TODO Take a look at setVisitor function data what is being passed to it doesn't seem right!
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const [editable, setEditable] = useState(true)

  const pending = useSelector((state) => state.visitors.set_visitor.pending)
  const error = useSelector((state) => state.visitors.set_visitor.error)

  const initialValues = {
    last_name: data?.person.last_name || '',
    first_name: data?.person.first_name || '',
    employee_number: data?.visitor_number || '',
    location: data?.location.description || '',
    email: data?.email || '',
    phone: data?.phone || '',
    name: data?.company.name || '',
    company_id: data?.company?.id || '',
    department: data?.department || '',
    company_location: data?.mainoffice || '',
    street: data?.company.street || '',
    valid_from: formatInPreferredTimezone(data?.valid_from) || '',
    valid_to: formatInPreferredTimezone(data?.valid_to) || '',
  }

  const renderCustomAttributes = isEmpty(data.custom_attributes)
    ? []
    : Object.keys(data.custom_attributes).map((key) => {
        return (
          <div key={key.replace('.', '_d0t_')}>
            <TextInputCustomAttribute
              disabled={editable}
              label={key}
              type="text"
              defaultValue={data.custom_attributes[key]}
              name={key.replace('.', '_d0t_')}
              id={data.id}
              customAttributeKey={key}
              permission={identityUpdateCustomAttribePermission}
              deleteCustomAttribute={deleteVisitorCustomAttribute}
            />
          </div>
        )
      })

  const get_companies = useSelector((state) => state.companies.get_companies.success)
  const get_companiesError = useSelector((state) => state.companies.get_companies.error)

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch, get_companiesError?.message])

  let companySelectRef = useRef()

  return (
    <>
      {identityUpdatePermission && (
        <div className="m-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <PencilAltIcon className="h-6 w-6 text-lime-500" onClick={() => setEditable(!editable)} />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          let personalData = {
            last_name: values.last_name,
            first_name: values.first_name,
            email: values.email,
            phone: values.phone,
          }
          let visitorData = {
            email: values.email,
            phone: values.phone,
            company_id: values.company_id,
            department: values.department,
            employee_state_short: values.employee_state_short,
            employee_state_long: values.employee_state_long,
            position: values.position,
            local_hr_number: values.local_hr_number,
            valid_to: parseWithTimeZone(values.valid_to),
            valid_from: parseWithTimeZone(values.valid_from),
          }
          const customAttributes = Object.assign(data.custom_attributes)
          let customAttributesTemp = {}
          for (const [key, value] of Object.entries(customAttributes)) {
            let temp_key = key.replace('.', '_d0t_')
            if (value !== values[temp_key]) {
              customAttributesTemp[key] = values[temp_key]
            }
          }
          dispatch(setPerson(data.person.id, personalData))
          dispatch(setVisitor(data.id, visitorData)) // needs to be changed
          const isCustomAttributesEmpty = isEmpty(customAttributesTemp)
          if (!isCustomAttributesEmpty) {
            // if there is no change in custom_attributes don't dispatch
            dispatch(
              createVisitorCustomAttribute(data.id, {
                // needs to be changed
                custom_attributes: [customAttributesTemp],
              }),
            )
          }
          actions.resetForm()
          setEditable(!editable)
        }}
      >
        <Form>
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeePersonalInfo.LastName')}
            type="text"
            name="last_name"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeePersonalInfo.FirstName')}
            type="text"
            name="first_name"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeePersonalInfo.Number')}
            type="text"
            name="employee_number"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeeCompanyInfo.Client')}
            type="text"
            name="location"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePersonalInfo.EMail')}
            type="email"
            name="email"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePersonalInfo.Phone')}
            type="text"
            name="phone"
          />
          {editable ? (
            <TextInputLabelOnLeft
              disabled={true}
              label={t('employeeCompanyInfo.Company')}
              type="text"
              name="name"
            />
          ) : (
            <SelectInputLabelOnLeft
              label={t('addEmployee.AddCompany')}
              options={get_companies.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              placeholder={t('addEmployee.CompanyPlaceholder')}
              name="company_id"
              innerRef={companySelectRef}
              required
              error={get_companiesError}
              setError={getCompaniesCLearError}
            />
          )}
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeeCompanyInfo.Department')}
            type="text"
            name="department"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeeCompanyInfo.MainOffice')}
            type="text"
            name="company_location"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeeCompanyInfo.Address')}
            type="text"
            name="street"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('visitorPersonalInfo.ValidFrom')}
            type="text"
            name="valid_from"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('visitorPersonalInfo.ValidTo')}
            type="text"
            name="valid_to"
          />
          {renderCustomAttributes}
          {identityUpdatePermission && (
            <FormikSaveButton
              type="submit"
              testid="form-edit-visitor-submit"
              text={t('addEmployee.AddEmployee')}
              primary
              pending={pending}
              error={error}
              setError={() => dispatch(setVisitorClearError())}
            />
          )}
        </Form>
      </Formik>
    </>
  )
}
