import Collapse from '../Collapse'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import FormAddResourceType from '../forms/FormAddResourceType'
import Table from '../table'
import {
  getAccessProfilesTypes,
  getAccessProfilesTypesClearError,
} from '../../stores/access_profiles'
import {useTableColumns} from '../../hooks/useTableColumns'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'

export default function ResourceTypeWrapper() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const access_profile_types = useSelector(
    (state) => state.access_profiles.get_access_profiles_types.success,
  )
  const pending = useSelector((state) => state.access_profiles.get_access_profiles_types.pending)
  const error = useSelector((state) => state.access_profiles.get_access_profiles_types.error)
  const create_access_profile_type_success = useSelector(
    (state) => state.access_profiles.create_access_profile_type.success,
  )
  const update_access_profile_type_success = useSelector(
    (state) => state.access_profiles.update_access_profile_type.success,
  )
  const delete_access_profile_type_success = useSelector(
    (state) => state.access_profiles.delete_access_profile_type.success,
  )

  useEffect(() => {
    dispatch(getAccessProfilesTypes())
  }, [
    dispatch,
    create_access_profile_type_success,
    update_access_profile_type_success,
    delete_access_profile_type_success,
    error?.message,
  ])

  const columns = useTableColumns(TableId.RESOURCE_TYPE)

  return (
    <>
      <Collapse title={t('addResourceTypeForm.AddResourceTypeTitle')}>
        <FormAddResourceType />
      </Collapse>
      <>
        <EditTableWrapper tableId={TableId.RESOURCE_TYPE} />
        <Table
          columns={columns}
          data={access_profile_types}
          pending={pending}
          error={error}
          setError={getAccessProfilesTypesClearError}
        />
      </>
    </>
  )
}
