export const NODE_TYPE_ROOT = 'root'
export const NODE_TYPE_CHILD = 'child'
export const NODE_TYPE_PARENT = 'parent'
export const CUSTOM_NODE_SIZE = {x: 140, y: 60} // 60 - is for three lines
export const bg = {
  [NODE_TYPE_ROOT]: 'bg-orange-200',
  [NODE_TYPE_CHILD]: 'bg-lime-200',
  [NODE_TYPE_PARENT]: 'bg-sky-200',
}

export const childrenAccessorResources = 'children_resources'
export const parentAccessorResources = 'parent_resources'

export const childrenAccessorIdentities = 'children_identities'
export const parentAccessorIdentities = 'parent_identities'
