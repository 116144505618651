import {QUERIES, useMediaQuery} from './useMediaQuery'
import {useSelector} from 'react-redux'
import {getColumns} from '../components/table/helpers'
import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {TableId} from '../stores/types/tables'

export const useTableColumns = (tableId: TableId) => {
  const isSmallScreen = useMediaQuery(QUERIES.md)
  const {t} = useTranslation()
  // @ts-ignore
  const tableConfigs = useSelector((state) => state.tables.config)
  // @ts-ignore
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const {hiddenColumns, columns} = tableConfigs[tableId]

  const columnsSm = useMemo(
    () => columns.filter((col) => !hiddenColumns.includes(col)),
    [columns, hiddenColumns],
  )
  const columnAccessors = isSmallScreen ? columnsSm : columns
  return getColumns(tableId, columnAccessors, t, permissions)
}
