import {iso8061UTC} from '../../utils/datetime'
import {resolvePath} from '../../utils/helpers'

const employeeHeaders = [
  'owner.costcenter',
  'owner.department',
  'owner.email',
  'owner.employee_number',
  'owner.employee_state_long',
  'owner.end_of_contract',
  'owner.last_day_of_work',
  'owner.local_hr_number',
  'owner.mainoffice',
  'owner.person.card_pin',
  'owner.person.first_name',
  'owner.person.full_name',
  'owner.person.gender_name',
  'owner.person.last_name',
  'owner.position',
  'owner.start_of_contract',
  'owner.updated_at',
]

const visitorHeaders = [
  'owner.email',
  'owner.person.card_pin',
  'owner.person.first_name',
  'owner.person.full_name',
  'owner.person.gender_name',
  'owner.person.last_name',
  'owner.updated_at',
]

export const getFilterQuery = (filters, additionalFilter = '') => {
  let odata_query = ''
  let index = 0
  filters.forEach(function (filter) {
    let temp = filter.id
    if (filter.id === 'selection') {
      return
    }
    if (filter.id === 'person.full_name') {
      temp = 'person/full_name'
    }
    if (filter.id.includes('owner')) {
      if (filter.id === 'identity.owner_name') {
        odata_query +=
          (index !== 0 ? ' AND ' : '') +
          `(contains(identity/employee/person/full_name, '${filter.value}') OR contains(identity/visitor/person/full_name, '${filter.value}') OR contains(identity/name, '${filter.value}'))`
        index++
      } else {
        if (filter.id === 'owner_name') {
          odata_query +=
            (index !== 0 ? ' AND ' : '') +
            `(contains(employee/person/full_name, '${filter.value}') OR contains(visitor/person/full_name, '${filter.value}') OR contains(name, '${filter.value}'))`
        } else {
          if (visitorHeaders.includes(filter.id)) {
            odata_query +=
              (index !== 0 ? ' AND ' : '') +
              `(contains(${filter.id
                .replaceAll('owner', 'visitors/visitor')
                .replaceAll('.', '/')}, '${filter.value}') OR
                contains(${filter.id
                  .replaceAll('owner', 'employees/employee')
                  .replaceAll('.', '/')}, '${filter.value}'))
                `
            index++
          } else {
            if (employeeHeaders.includes(filter.id)) {
              odata_query +=
                (index !== 0 ? ' AND ' : '') +
                `contains(${filter.id
                  .replaceAll('owner', 'employees/employee')
                  .replaceAll('.', '/')}, '${filter.value}')`
              index++
            }
          }
        }
      }
      return
    }
    if (filter.id === 'employee_state.id' || filter.id === 'visitor_state.id') {
      temp = 'state_id'
    }
    if (filter.id === 'resource') {
      temp = 'resource/name'
    }
    if (filter.id === 'resource_id') {
      temp = 'resource/id'
    }
    if (filter.id === 'resource_type') {
      temp = 'resource/type/name'
    }
    if (filter.id === 'employee_state.name') {
      temp = 'state/name'
    }
    if (filter.id === 'type') {
      temp = 'type/name'
    }
    if (filter.id === 'location') {
      temp = 'location/description'
    }
    if (filter.id === 'location[0].description') {
      temp = 'location/description'
    }
    if (filter.id === 'valid_from') {
      odata_query += (index !== 0 ? ' AND ' : '') + `valid_from gt '${iso8061UTC(filter.value)}'`
    } else if (filter.id === 'valid_to') {
      odata_query += (index !== 0 ? ' AND ' : '') + `valid_to lt '${iso8061UTC(filter.value)}'`
    } else if (filter.id === 'state.name') {
      odata_query += (index !== 0 ? ' AND ' : '') + `state/name eq '${filter.value}'`
    } else
      odata_query +=
        (index !== 0 ? ' AND ' : '') + `contains(${temp.replace('.', '/')}, '${filter.value}')`

    index++
  })

  if (additionalFilter) {
    return odata_query ? additionalFilter + ' AND ' + odata_query : additionalFilter
  }

  return odata_query
}

export const getSortQuery = (sortBy) => {
  if (!sortBy.length) {
    return ''
  }

  const {id, desc} = sortBy[0]
  let queryParam = id
  if (id === 'employee_state.name') {
    queryParam = 'state/name'
  }
  if (id === 'employee_state.id') {
    queryParam = 'state_id'
  }
  if (id === 'type') {
    queryParam = 'type/name'
  }
  if (id === 'resource_id') {
    queryParam = 'resource/id'
  }
  if (id === 'identity_id') {
    queryParam = 'identity/id'
  }
  if (id === 'resource_type') {
    queryParam = 'resource/type/name'
  }
  if (id === 'resource') {
    queryParam = 'resource/name'
  }
  if (id === 'owner_name') {
    queryParam = 'name'
  }
  if (id.includes('location')) {
    queryParam = 'location/description'
  }

  return `${queryParam.replaceAll('.', '/')} eq '${desc ? 'desc' : 'asc'}'`
}

const transformObjToString = (obj) =>
  JSON.stringify(obj)
    .replace(/[\\{}[\]]/g, '')
    .replaceAll(',', ';')

const flattenObject = (input) => {
  let result = {}
  for (const key in input) {
    if (!input.hasOwnProperty(key)) {
      continue
    }
    if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
      result[key] = transformValuesToString(input[key])
      const subFlatObject = flattenObject(input[key])

      for (const subkey in subFlatObject) {
        result[key + '.' + subkey] = subFlatObject[subkey]
      }
    } else {
      result[key] = input[key]
    }
  }
  return result
}

export const transformValuesToString = (value) =>
  value && typeof value === 'object' ? transformObjToString(flattenObject(value)) : value

export const getExportValuesFromTableData = (rows) =>
  rows?.map((row) =>
    Object.entries(row.values).reduce((acc, [key, value]) => {
      acc[key] = transformValuesToString(value)
      return acc
    }, {}),
  )

export const getExportValuesFromResponseData = (data, headers) => {
  const accessors = headers.map((headerObj) => headerObj.key)
  return data.map((row) => {
    const resRow = {}
    accessors.forEach((path) => {
      resRow[path] = transformValuesToString(resolvePath(row, path, ''))
    })
    return resRow
  })
}
