import axios from '../services/api'
import {createNotificationOnClient} from './notifications'

// Types

const UPLOAD_CONFIG_FILE_PENDING = 'idfunctiondb/files/UPLOAD_CONFIG_FILE_PENDING'
const UPLOAD_CONFIG_FILE_SUCCESS = 'idfunctiondb/files/UPLOAD_CONFIG_FILE_SUCCESS'
export const UPLOAD_CONFIG_FILE_ERROR = 'idfunctiondb/files/UPLOAD_CONFIG_FILE_ERROR'

const GET_IDENTITY_FILES_TAGS_PENDING = 'idfunctiondb/files/GET_IDENTITY_FILES_TAGS_PENDING'
const GET_IDENTITY_FILES_TAGS_SUCCESS = 'idfunctiondb/files/GET_IDENTITY_FILES_TAGS_SUCCESS'
const GET_IDENTITY_FILES_TAGS_ERROR = 'idfunctiondb/files/GET_IDENTITY_FILES_TAGS_ERROR'
const GET_IDENTITY_FILES_TAGS_CLEAR_ERROR = 'idfunctiondb/files/GET_IDENTITY_FILES_TAGS_CLEAR_ERROR'

const CREATE_IDENTITY_FILES_TAGS_PENDING = 'idfunctiondb/files/CREATE_IDENTITY_FILES_TAGS_PENDING'
const CREATE_IDENTITY_FILES_TAGS_SUCCESS = 'idfunctiondb/files/CREATE_IDENTITY_FILES_TAGS_SUCCESS'
const CREATE_IDENTITY_FILES_TAGS_ERROR = 'idfunctiondb/files/CREATE_IDENTITY_FILES_TAGS_ERROR'
const CREATE_IDENTITY_FILES_TAGS_CLEAR_ERROR =
  'idfunctiondb/files/CREATE_IDENTITY_FILES_TAGS_CLEAR_ERROR'

const initialState = {
  upload_config_file: {
    success: [],
    pending: false,
    error: null,
  },
  get_identity_files_tags: {
    success: [],
    pending: false,
    error: null,
  },
  create_identity_files_tags: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPLOAD_CONFIG_FILE_PENDING:
      return {
        ...state,
        upload_config_file: {
          ...state.upload_config_file,
          pending: true,
        },
      }
    case UPLOAD_CONFIG_FILE_SUCCESS:
      return {
        ...state,
        upload_config_file: {
          ...state.upload_config_file,
          success: action.payload,
          pending: false,
        },
      }
    case UPLOAD_CONFIG_FILE_ERROR:
      return {
        ...state,
        upload_config_file: {
          ...state.upload_config_file,
          error: action.payload,
          pending: false,
        },
      }
    case GET_IDENTITY_FILES_TAGS_PENDING:
      return {
        ...state,
        get_identity_files_tags: {
          ...state.get_identity_files_tags,
          pending: true,
        },
      }
    case GET_IDENTITY_FILES_TAGS_SUCCESS:
      return {
        ...state,
        get_identity_files_tags: {
          ...state.get_identity_files_tags,
          success: action.payload,
          pending: false,
        },
      }
    case GET_IDENTITY_FILES_TAGS_ERROR:
      return {
        ...state,
        get_identity_files_tags: {
          ...state.get_identity_files_tags,
          error: action.payload,
          pending: false,
        },
      }
    case GET_IDENTITY_FILES_TAGS_CLEAR_ERROR:
      return {
        ...state,
        get_identity_files_tags: {
          ...state.get_identity_files_tags,
          error: null,
          pending: false,
        },
      }
    case CREATE_IDENTITY_FILES_TAGS_PENDING:
      return {
        ...state,
        create_identity_files_tags: {
          ...state.create_identity_files_tags,
          pending: true,
        },
      }
    case CREATE_IDENTITY_FILES_TAGS_SUCCESS:
      return {
        ...state,
        create_identity_files_tags: {
          ...state.create_dentity_files_tags,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_IDENTITY_FILES_TAGS_ERROR:
      return {
        ...state,
        create_identity_files_tags: {
          ...state.create_identity_files_tags,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_IDENTITY_FILES_TAGS_CLEAR_ERROR:
      return {
        ...state,
        create_identity_files_tags: {
          ...state.create_identity_files_tags,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

function uploadConfigFilePending() {
  return {type: UPLOAD_CONFIG_FILE_PENDING}
}

function uploadConfigFileSuccess(payload) {
  return {type: UPLOAD_CONFIG_FILE_SUCCESS, payload: payload}
}

export function uploadConfigFileError(error) {
  return {type: UPLOAD_CONFIG_FILE_ERROR, payload: error}
}

function getIdentitiesFilesTagsPending() {
  return {type: GET_IDENTITY_FILES_TAGS_PENDING}
}

function getIdentitiesFilesTagsSuccess(payload) {
  return {type: GET_IDENTITY_FILES_TAGS_SUCCESS, payload: payload}
}

function getIdentitiesFilesTagsError(error) {
  return {type: GET_IDENTITY_FILES_TAGS_ERROR, payload: error}
}
export function getIdentitiesFilesTagsClearError() {
  return {type: GET_IDENTITY_FILES_TAGS_CLEAR_ERROR}
}

function createIdentitiesFilesTagsPending() {
  return {type: CREATE_IDENTITY_FILES_TAGS_PENDING}
}
function createIdentitiesFilesTagsSuccess(payload) {
  return {type: CREATE_IDENTITY_FILES_TAGS_SUCCESS, payload: payload}
}
function createIdentitiesFilesTagsError(error) {
  return {type: CREATE_IDENTITY_FILES_TAGS_ERROR, payload: error}
}
export function createIdentitiesFilesTagsClearError() {
  return {type: CREATE_IDENTITY_FILES_TAGS_ERROR}
}

export const uploadConfigFile = (url, target) => (dispatch) => {
  dispatch(uploadConfigFilePending())
  var formData = new FormData()
  formData.append('file', target.files[0]?.file)
  return axios
    .post(url, formData)
    .then((res) => {
      dispatch(uploadConfigFileSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(uploadConfigFileError(error)))
}

export const getIdentitiesFilesTags = (url) => (dispatch) => {
  dispatch(getIdentitiesFilesTagsPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getIdentitiesFilesTagsSuccess(res.data.results))
      return res.data
    })
    .catch((error) => {
      dispatch(getIdentitiesFilesTagsError(error))
    })
}

export const createIdentitiesFilesTags = (url, values) => (dispatch) => {
  dispatch(createIdentitiesFilesTagsPending())
  return axios
    .post(url, values)
    .then((res) => {
      dispatch(createIdentitiesFilesTagsSuccess(res.data))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.FileTagCreated'))
      return res.data
    })
    .catch((error) => {
      dispatch(createIdentitiesFilesTagsError(error))
    })
}
