import * as Yup from 'yup'
import {YupValidationType, InputYupValidationsType} from '../types'

/**
 * @param {Object} field
 * @param {String} field.name
 * @param {('string' | 'number' | 'boolean' | 'date' |'object'| 'array' )} field.validationType
 * @param {Array<{type: String, message:string }>} field.validations
 * @returns {*}
 */
const generateValidations = (field) => {
  const {validations = [], validationType = YupValidationType.STRING} = field

  let validator = Yup[validationType]().nullable()
  // a special workaround for date type validation, use case: empty string is not a date
  // https://github.com/jquense/yup/issues/764
  if (validationType === YupValidationType.DATE) {
    validator = Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
  }
  // edge case for multi select
  if (validationType === YupValidationType.ARRAY) {
    validator = Yup.array()
      .nullable()
      .transform((curr, orig) => (orig === '' ? [] : curr))
      .of(Yup.string())
  }

  validations.forEach((validation) => {
    const {params, type} = validation

    if (type === InputYupValidationsType.ENDDATE) {
      validator = validator.when(params[0], (date, schema) => {
        if (date) {
          date.setSeconds(date.getSeconds() + 1)

          return schema.min(date, params[1])
        }
      })
      return
    }

    if (!validator[type]) {
      return
    }

    validator = validator[type](...params)
  })
  return validator
}

const getDefaultValue = (field) => {
  const {validationType, defaultValue} = field

  if (defaultValue) {
    return defaultValue
  }

  switch (true) {
    case validationType === YupValidationType.NUMBER:
      return 0
    case validationType === YupValidationType.BOOLEAN:
      return false
    case validationType === YupValidationType.DATE:
      return null
    case validationType === YupValidationType.OBJECT:
      return undefined // https://github.com/jquense/yup/issues/1455
    case validationType === YupValidationType.ARRAY:
      return []
    default:
      return ''
  }
}

export const getInputs = (inputsConfig) => {
  const initialValues = {}
  const validationsFields = {}

  for (const field of inputsConfig) {
    initialValues[field.name] = getDefaultValue(field)
    validationsFields[field.name] = generateValidations(field)
  }

  return {
    validationSchema: Yup.object().shape({...validationsFields}),
    initialValues: initialValues,
  }
}
