import Tabs from '../Tabs'
import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import FormAddIdentity from './FormAddIdentity'
import FormAddToExistingIdentity from './FormAddToExistingIdentity'

const FormCreateIdentityGroup = ({selected}) => {
  const {t} = useTranslation()

  const tabsArray = useMemo(
    () => [
      {name: t('identity.CreateGroup'), component: <FormAddIdentity selected={selected} />},
      {
        name: t('identity.AddIdentity'),
        component: <FormAddToExistingIdentity selected={selected} />,
      },
    ],
    [selected, t],
  )

  return <Tabs withSpace tabs={tabsArray} />
}

export default FormCreateIdentityGroup
