import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'addIdentityProvider.Status',
    label: 'addIdentityProvider.Status',
    accessor: 'ActiveFn',
    cell: 'StatusPill',
  },
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'addIdentityProvider.Name',
    label: 'addIdentityProvider.Name',
    accessor: 'name',
    cell: 'IdentityProviderTwoRowCellLink',
    idAccessor: 'id',
    secondAccessor: 'description',
  },
  {
    header: 'addIdentityProvider.DiscoveryQuery',
    accessor: 'discovery_query',
    label: 'addIdentityProvider.DiscoveryQuery',
  },
  {
    header: 'addIdentityProvider.RedirectURI',
    label: 'addIdentityProvider.RedirectURI',
    accessor: 'id',
    nameAccessor: 'redirect_uri',
    cell: 'IdentityProviderCustomCell',
  },
  {
    header: 'addIdentityProvider.OidcProviderId',
    accessor: 'oidc.oidc_provider_id',
    label: 'addIdentityProvider.OidcProviderId',
  },
  {
    header: 'addIdentityProvider.OidcProviderScope',
    accessor: 'oidc.oidc_provider_metadata.scope',
    label: 'addIdentityProvider.OidcProviderScope',
  },
  {
    header: 'addIdentityProvider.OidcProviderSecret',
    accessor: 'oidc.oidc_provider_secret',
    label: 'addIdentityProvider.OidcProviderSecret',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'addIdentityProvider.Actions',
    label: 'addIdentityProvider.Actions',
    accessor: 'actions',
    idAccessor: 'id',
    activeAccessor: 'active',
    cell: 'IdentityProviderActionsCell',
    noSort: true,
  },
]
export default columnConfig
