import Table from '../table'
import {useSelector} from 'react-redux'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'
import {useTableColumns} from '../../hooks/useTableColumns'

export default function CardsHistoryWrapper(props) {
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const columns = useTableColumns(TableId.CARDS_HISTORY)

  return (
    <>
      {permissions.readCard ? (
        <>
          <EditTableWrapper tableId={TableId.CARDS_HISTORY} />
          <Table
            columns={columns}
            data={props.data}
            pending={props.pending}
            error={props.error}
            setError={props.setError}
          />
        </>
      ) : null}
    </>
  )
}
