import {Formik, Form} from 'formik'
import {useState, useEffect, useRef} from 'react'
import {TextInputCustomAttribute, TextInputLabelOnLeft, SelectInputLabelOnLeft} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {PencilAltIcon} from '@heroicons/react/solid'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'
import {setPerson} from '../../stores/persons'
import {
  setEmployee,
  setEmployeeClearError,
  createEmployeeCustomAttribute,
  deleteEmployeeCustomAttribute,
} from '../../stores/employees'
import {isEmpty} from '../../utils/custom_attributes'
import {getCompanies, getCompaniesCLearError} from '../../stores/companies'
import {iso8061UTC} from '../../utils/datetime'

export default function FormEditEmployeePersonal({
  data,
  identityUpdatePermission,
  identityUpdateCustomAttribePermission,
}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const [editable, setEditable] = useState(true)

  const pending = useSelector((state) => state.employees.set_employee.pending)
  const error = useSelector((state) => state.employees.set_employee.error)

  const initialValues = {
    last_name: data?.person.last_name || '',
    first_name: data?.person.first_name || '',
    employee_number: data?.employee_number || '',
    location: data?.location.description || '',
    email: data?.email || '',
    phone: data?.phone || '',
    name: data?.company.name || '',
    company_id: data?.company?.id || '',
    department: data?.department || '',
    company_location: data?.mainoffice || '',
    street: data?.company?.street || '',
    local_hr_number: data?.local_hr_number || '',
    start_of_contract: data?.start_of_contract
      ? new Date(data?.start_of_contract).toISOString().split('T')[0]
      : '-',
    end_of_contract: data?.end_of_contract
      ? new Date(data?.end_of_contract).toISOString().split('T')[0]
      : '-',
    last_day_of_work: data?.last_day_of_work
      ? new Date(data?.last_day_of_work).toISOString().split('T')[0]
      : '-',
    position: data?.position || '',
    employee_status: data?.employee_state?.name || '',
    employee_state_short: data?.employee_state_short || '',
    employee_state_long: data?.employee_state_long || '',
  }
  // initialization of const customAttributes helps to re-render renderCustomAttributes when clicked next identity
  const customAttributes = data.custom_attributes
  const renderCustomAttributes = isEmpty(customAttributes)
    ? []
    : Object.keys(customAttributes).map((key) => {
        return (
          <div key={key.replace('.', '_d0t_')}>
            <TextInputCustomAttribute
              disabled={editable}
              label={key}
              type="text"
              defaultValue={customAttributes[key]}
              name={key.replace('.', '_d0t_')}
              id={data.id}
              permission={identityUpdateCustomAttribePermission} // TODO: this should be customAttribute permission
              customAttributeKey={key}
              deleteCustomAttribute={deleteEmployeeCustomAttribute}
            />
          </div>
        )
      })

  const get_companies = useSelector((state) => state.companies.get_companies.success)
  const get_companiesError = useSelector((state) => state.companies.get_companies.error)

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch, get_companiesError?.message])

  let companySelectRef = useRef()

  return (
    <>
      {identityUpdatePermission && (
        <div className="m-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <PencilAltIcon className="h-6 w-6 text-lime-500" onClick={() => setEditable(!editable)} />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          let start_of_contract
          let end_of_contract
          let last_day_of_work

          if (values.start_of_contract && values.start_of_contract !== '-') {
            start_of_contract = iso8061UTC(values.start_of_contract)
          }
          if (values.end_of_contract && values.end_of_contract !== '-') {
            end_of_contract = iso8061UTC(values.end_of_contract)
          }
          if (values.last_day_of_work && values.last_day_of_work !== '-') {
            last_day_of_work = iso8061UTC(values.last_day_of_work)
          }
          let personalData = {
            last_name: values.last_name,
            first_name: values.first_name,
            email: values.email,
            phone: values.phone,
          }
          let employeeData = {
            email: values.email,
            phone: values.phone,
            company_id: values.company_id,
            department: values.department,
            employee_state_short: values.employee_state_short,
            employee_state_long: values.employee_state_long,
            position: values.position,
            local_hr_number: values.local_hr_number,
            start_of_contract: start_of_contract ? start_of_contract : null,
            end_of_contract: end_of_contract ? end_of_contract : null,
            last_day_of_work: last_day_of_work ? last_day_of_work : null,
          }
          const customAttributes = Object.assign(data.custom_attributes)
          let customAttributesTemp = {}
          for (const [key, value] of Object.entries(customAttributes)) {
            let temp_key = key.replace('.', '_d0t_')
            if (value !== values[temp_key]) {
              customAttributesTemp[key] = values[temp_key]
            }
          }
          dispatch(setPerson(data.person.id, personalData))
          dispatch(setEmployee(data.id, employeeData)) // needs to be changed
          const isCustomAttributesEmpty = isEmpty(customAttributesTemp)
          if (!isCustomAttributesEmpty) {
            // if there is no change in custom_attributes don't dispatch
            dispatch(
              createEmployeeCustomAttribute(data.id, {
                // needs to be changed
                custom_attributes: [customAttributesTemp],
              }),
            )
          }
          actions.resetForm()
          setEditable(!editable)
        }}
      >
        <Form>
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeePersonalInfo.LastName')}
            type="text"
            name="last_name"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeePersonalInfo.FirstName')}
            type="text"
            name="first_name"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeePersonalInfo.Number')}
            type="text"
            name="employee_number"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeeCompanyInfo.Client')}
            type="text"
            name="location"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePersonalInfo.EMail')}
            type="email"
            name="email"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePersonalInfo.Phone')}
            type="text"
            name="phone"
          />
          {editable ? (
            <TextInputLabelOnLeft
              disabled={true}
              label={t('employeeCompanyInfo.Company')}
              type="text"
              name="name"
            />
          ) : (
            <SelectInputLabelOnLeft
              label={t('addEmployee.AddCompany')}
              options={get_companies.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              placeholder={t('addEmployee.CompanyPlaceholder')}
              name="company_id"
              innerRef={companySelectRef}
              required
              error={get_companiesError}
              setError={getCompaniesCLearError}
            />
          )}
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeeCompanyInfo.Department')}
            type="text"
            name="department"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeeCompanyInfo.MainOffice')}
            type="text"
            name="company_location"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeeCompanyInfo.Address')}
            type="text"
            name="street"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePersonalInfo.LocalHrNumber')}
            type="text"
            name="local_hr_number"
          />
          {data?.start_of_contract === null && editable ? (
            <TextInputLabelOnLeft
              disabled={editable}
              label={t('employeePersonalInfo.StartOfContract')}
              type="text"
              name="start_of_contract"
            />
          ) : (
            <TextInputLabelOnLeft
              disabled={editable}
              label={t('employeePersonalInfo.StartOfContract')}
              type="date"
              name="start_of_contract"
            />
          )}
          {data?.end_of_contract === null && editable ? (
            <TextInputLabelOnLeft
              disabled={editable}
              label={t('employeePersonalInfo.EndOfContract')}
              type="text"
              name="end_of_contract"
            />
          ) : (
            <TextInputLabelOnLeft
              disabled={editable}
              label={t('employeePersonalInfo.EndOfContract')}
              type="date"
              name="end_of_contract"
            />
          )}
          {data?.last_day_of_work === null && editable ? (
            <TextInputLabelOnLeft
              disabled={editable}
              label={t('employeePersonalInfo.LastDayOfWork')}
              type="text"
              name="last_day_of_work"
            />
          ) : (
            <TextInputLabelOnLeft
              disabled={editable}
              label={t('employeePersonalInfo.LastDayOfWork')}
              type="date"
              name="last_day_of_work"
            />
          )}
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePersonalInfo.Position')}
            type="text"
            name="position"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('employeePersonalInfo.EmployeeStatus')}
            type="text"
            name="employee_status"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePersonalInfo.EmployeeStateShort')}
            type="text"
            name="employee_state_short"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePersonalInfo.EmployeeStateLong')}
            type="text"
            name="employee_state_long"
          />
          {renderCustomAttributes}
          {identityUpdatePermission && (
            <FormikSaveButton
              type="submit"
              testid="form-edit-employee-personal-submit"
              text={t('addEmployee.Save')}
              primary
              pending={pending}
              error={error}
              setError={() => dispatch(setEmployeeClearError())}
              disabled={editable}
            />
          )}
        </Form>
      </Formik>
    </>
  )
}
