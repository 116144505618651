import {Formik, Form} from 'formik'
import {useRef} from 'react'
import {TextInput, SelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'
import {createPerson, createPersonClearError} from '../../stores/persons'
import * as Yup from 'yup'
import {iso8061UTC} from '../../utils/datetime'

export default function FormAddPerson() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const pending = useSelector((state) => state.persons.create_person.pending)
  const error = useSelector((state) => state.persons.create_person.error)

  const genderOptions = [
    {value: 'MALE', label: t('addPersonForm.Male')},
    {value: 'FEMALE', label: t('addPersonForm.Female')},
    {value: 'DIVERSE', label: t('addPersonForm.Diverse')},
  ]

  const initialValues = {
    title: '',
    location: '',
    gender: 'UNKNOWN',
    number: '',
    first_name: '',
    last_name: '',
    birthday: '',
  }

  const validationSchema = Yup.object().shape({
    last_name: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('employeePersonalInfo.LastName')}),
    ),
    location: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addEmployee.Domain')}),
    ),
  })

  const handleSubmit = (values, actions) => {
    if (values.birthday === '') {
      delete values.birthday
    } else {
      values['birthday'] = iso8061UTC(values.birthday)
    }
    dispatch(createPerson(values, true, userID))
    actions.resetForm()
    // resetting react-select components
    domainSelectRef.current.select.setValue('')
    genderSelectRef.current.select.setValue('')
  }
  let domainSelectRef = useRef()
  let genderSelectRef = useRef()
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <SelectInput
            label={t('addPersonForm.Gender')}
            name="gender"
            innerRef={genderSelectRef}
            options={genderOptions}
            placeholder={t('setAvatarForm.Select')}
          />
          <TextInput label={t('addPersonForm.Title')} name="title" type="text" placeholder="" />
          <TextInput
            label={t('addPersonForm.FirstName')}
            name="first_name"
            type="text"
            placeholder=""
          />
          <TextInput
            label={t('addPersonForm.LastName')}
            name="last_name"
            required
            type="text"
            placeholder=""
          />
          <TextInput
            label={t('employeePersonalInfo.Number')}
            name="number"
            type="text"
            placeholder=""
          />
          <SelectInput
            label={t('addEmployee.Domain')}
            name="location"
            innerRef={domainSelectRef}
            options={permissions.createPersonLocations}
            placeholder={t('addEmployee.DomainPlaceholder')}
            required
          />
          <TextInput label={t('birthday.Title')} name="birthday" type="date" placeholder="" />
          <FormikSaveButton
            type="submit"
            testid="form-add-person-submit"
            text={t('addPersonForm.Save')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(createPersonClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
