import React from 'react'
import {connect} from 'react-redux'
import {CameraButtons} from './Buttons'

// Things to cover:
// 1 - ❌ user has no webcam, just a microphone
// 2 - ✅ user has (accidentally) denied access to the webcam
// 3 - ❌ user plugs in the webcam/microphone after your getUserMedia() code has initialized
// 4 - ❌ the device is already used by another app on Windows
// 5 - ❌ Firefox only: the device is already used by another Firefox tab
// 6 - ❌ Chrome only: the user dismisses the privacy dialog
// 7 - ✅ Apple mobile devices are now opening the webcam.
// 8 - ❌ handle when browser doesn't support getUserMedia

///////////////////////////////////// polyfill

// polyfill based on https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
;(function polyfillGetUserMedia() {
  if (typeof window === 'undefined') {
    return
  }

  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {}
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      const getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
    }
  }
})()

class CameraRegular extends React.Component {
  constructor(props) {
    super(props)
    this.play = true
    this.stream = ''
    this.portraitMode = false
    this.ref = React.createRef()
    this.refCanvas = React.createRef()
    this.refVideo = React.createRef()
    this.helpCanvas = React.createRef()
    this.videoCurrentWidth = 0
    this.videoCurrentHeight = 0
    this.avatarUrlPost = this.props.identity.photos.list // adapt it take a look later
    this.avatarUrlPut = this.props.identity.photos.current // new
    this.setImageURI = this.props.setImageURI
  }

  async componentDidMount() {
    await this.setupCamera()
    let video = this.refVideo.current
    video.play()
    let videoWidth = this.refVideo.current.videoWidth
    let videoHeight = this.refVideo.current.videoHeight
    this.refVideo.current.width = videoWidth
    this.refVideo.current.height = videoHeight
    this.helpCanvas.current.width = videoWidth
    this.helpCanvas.current.height = videoHeight
  }

  setupCamera = async () => {
    const video = this.refVideo.current

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width: {
          min: 640,
        },
        height: {
          min: 480,
        },
        facingMode: 'user',
      },
    })
    this.stream = stream
    video.srcObject = stream

    return new Promise((resolve) => {
      video.onloadedmetadata = () => {
        resolve(video)
      }
    })
  }

  capturePhoto = () => {
    const video = this.refVideo.current
    const canvas = this.helpCanvas.current
    // scale the canvas accordingly
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    // draw the video at that frame and apply mirror effect
    let ctx = canvas.getContext('2d')
    // horizontal flip
    //ctx.translate(-canvas.width,0)
    ctx.scale(-1, 1)
    ctx.drawImage(video, -canvas.width, 0)
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    // convert it to a usable data URL
    const dataURL = canvas.toDataURL()
    this.setImageURI(dataURL)
  }

  componentWillUnmount() {
    this.play = false
    let video = this.refVideo.current
    video.pause()
    video.src = ''
    this.stream?.getTracks && this.stream?.getTracks()[0].stop()
  }

  render() {
    return (
      <div>
        <div id="helo" className="relative text-center">
          <div className=" relative">
            <video
              className="z-20 mx-auto -scale-x-100 p-3 lg:m-1"
              ref={this.refVideo}
              playsInline
            ></video>
            <canvas
              className="absolute  top-0 z-20 mx-auto h-full w-full p-3 lg:m-1 "
              ref={this.refCanvas}
            />
            <canvas
              className="absolute  top-0 -z-10 mx-auto h-full w-full -scale-x-100  p-3 lg:m-1"
              ref={this.helpCanvas}
            />
          </div>
        </div>
        <CameraButtons onClick={() => this.capturePhoto()} />
      </div>
    )
  }
}

export default connect(null)(CameraRegular)
