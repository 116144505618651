import {UploadIcon, XIcon} from '@heroicons/react/outline'
import {useController, useFormContext} from 'react-hook-form'
import {ErrorMessage} from '../ErrorMessage'
import {useCallback, useRef, useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {classNames} from '../../../utils/classNames'
import {fileMimeTypes, fileMineTypesLabels, InputYupValidationsType} from '../types'
import {isFieldRequired} from '../lib/utils'

export const getKiloBytesFromBytes = (fileSizeInBytes) =>
  Math.round((fileSizeInBytes / 1024) * 10) / 10
export const getBytesFromKiloBytes = (fileSizeInKiloBytes) => fileSizeInKiloBytes * 1024

const isFileSizeError = (size, fileSizeValidation) => {
  return fileSizeValidation && size > fileSizeValidation
}
const isFileTypeError = (type, fileTypeValidation) => {
  return fileTypeValidation && type && !fileTypeValidation.includes(type)
}

export function FileController({
  name,
  control,
  render,
  fileSize,
  fileType,
  trigger,
  onUpdate,
  isSubmitSuccessful,
}) {
  const inputRef = useRef(null)

  const {field} = useController({name, control})
  const [fileData, setFileData] = useState(null)

  const onChange = useCallback(
    (event) => {
      const file = event.target.files?.[0]
      if (file) {
        const data = {
          name: file.name,
          size: file.size,
          type: file.type,
        }
        setFileData(data)
        if (!isFileSizeError(file.size, fileSize) && !isFileTypeError(file.type, fileType)) {
          field.onChange({
            file,
            ...data,
          })
          onUpdate && onUpdate(file)
        } else {
          field.onChange(undefined)
        }
        trigger(name)
      }
    },
    [field, fileSize, fileType, name, trigger, onUpdate],
  )

  useEffect(() => {
    if (isSubmitSuccessful) {
      setFileData(null)
    }
  }, [isSubmitSuccessful])

  return render({
    field: {
      type: 'file',
      name,
      onChange,
      ref: (instance) => {
        field.ref(instance)
        inputRef.current = instance
      },
    },
    fileData,
    select: () => inputRef.current?.click(),
    remove: () => {
      setFileData(null)
      field.onChange(undefined)
    },
  })
}

// https://stackblitz.com/edit/input-file-react-hook-form?file=src%2FApp.js
function FileUpload({name, label, validations, onUpdate}) {
  const {control, setError, clearErrors, trigger, formState, getFieldState} = useFormContext()
  const {t} = useTranslation()
  const error = getFieldState(name, formState).error?.message || ''
  const id = `${name}`
  const fileSizeParams = validations?.find(
    (validation) => validation.type === InputYupValidationsType.FILESIZE,
  )?.params
  const fileTypeParams = validations?.find(
    (validation) => validation.type === InputYupValidationsType.FILETYPE,
  )?.params
  const fileSize = fileSizeParams && fileSizeParams[0]
  const fileTypes =
    fileTypeParams && fileTypeParams[0]?.map((fileTypeValue) => fileMimeTypes[fileTypeValue])
  const fileTypesLabels =
    fileTypeParams && fileTypeParams[0]?.map((fileTypeValue) => fileMineTypesLabels[fileTypeValue])

  return (
    <div className="flex w-full flex-col gap-1">
      <FileController
        name={name}
        control={control}
        onError={setError}
        clearErrors={clearErrors}
        fileSize={fileSizeParams}
        fileType={fileTypes}
        trigger={trigger}
        onUpdate={onUpdate}
        isSubmitSuccessful={formState.isSubmitSuccessful}
        render={({field, fileData, remove}) => (
          <>
            <label htmlFor={id} className="cursor-pointer">
              <span className="block text-sm font-medium text-gray-700">
                {t(label)}
                {isFieldRequired(validations) && '*'}
              </span>
              <div
                tabIndex={0}
                onBlur={() => {
                  return trigger(name)
                }}
                className={classNames(
                  'mt-1 flex justify-between rounded-md border p-2 text-center text-sm text-gray-600 focus:outline-none',
                  error ||
                    isFileSizeError(fileData?.size, fileSize) ||
                    isFileTypeError(fileData?.type, fileTypes)
                    ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                    : 'border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500',
                )}
              >
                <>
                  <span className="flex ">{fileData?.name}</span>
                  <span className=" relative flex cursor-pointer rounded-md bg-white  font-medium    focus-within:outline-none">
                    <input {...field} type="file" id={id} className="sr-only" />
                    {fileData?.name && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          remove()
                          return trigger(name)
                        }}
                        className="border-r"
                      >
                        <XIcon className="mx-2 h-5 w-5 items-center text-gray-700" />
                      </button>
                    )}
                    <UploadIcon className="ml-2 h-5 w-5 rounded text-gray-700" />
                  </span>
                </>
              </div>
            </label>
            {fileSize && (
              <p className="  text-start text-sm font-medium text-gray-700">
                {t('formCommon.maxFileSizeLabel')} {getKiloBytesFromBytes(fileSize)} kB.{' '}
                {isFileSizeError(fileData?.size, fileSize) && (
                  <span className="text-red-300">{t(fileSizeParams[1])}</span>
                )}
              </p>
            )}
            {fileTypes && (
              <p className="  text-start text-sm font-medium text-gray-700">
                {t('formCommon.allowedFileTypesLabel')} {fileTypesLabels.join(', ')}.{' '}
                {isFileTypeError(fileData?.type, fileTypes) && (
                  <span className="text-red-300">{t(fileTypeParams[1])}</span>
                )}
              </p>
            )}
          </>
        )}
      />
      <ErrorMessage error={t(error)} />
    </div>
  )
}

export default FileUpload
