import axios from '../services/api'
import {createNotificationOnClient} from './notifications'
// Types
export const REGISTER_USER_PENDING = 'idfunctiondb/register/REGISTER_USER_PENDING'
export const REGISTER_USER_SUCCESS = 'idfunctiondb/register/REGISTER_USER_SUCCESS'
export const REGISTER_USER_ERROR = 'idfunctiondb/register/REGISTER_USER_ERROR'
export const REGISTER_USER_CLEAR_ERROR = 'idfunctiondb/register/REGISTER_USER_CLEAR_ERROR'

// Initial
const initialState = {
  register_user: {
    success: null,
    success_info: {
      code: null,
      message: '',
    },
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REGISTER_USER_PENDING:
      return {
        ...state,
        register_user: {
          ...state.register_user,
          pending: true,
        },
      }
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        register_user: {
          ...state.register_user,
          success: action.payload,
          success_info: {
            code: action.payload.status,
            message: 'notificationSuccess.EmailHasBeenSent',
          },
          pending: false,
        },
      }
    case REGISTER_USER_ERROR:
      return {
        ...state,
        register_user: {
          ...state.register_user,
          error: action.payload,
          pending: false,
        },
      }
    case REGISTER_USER_CLEAR_ERROR:
      return {
        ...state,
        register_user: {
          ...state.register_user,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function registerUserPending() {
  return {type: REGISTER_USER_PENDING}
}
function registerUserSuccess(payload) {
  return {type: REGISTER_USER_SUCCESS, payload: payload}
}
function registerUserError(error) {
  return {type: REGISTER_USER_ERROR, payload: error}
}
export function registerUserClearError() {
  return {type: REGISTER_USER_CLEAR_ERROR}
}

export const register = (username, password, email) => (dispatch) => {
  dispatch(registerUserPending())
  axios
    .post('/api/v1/auth/register', {username: username, password: password, email: email})
    .then((res) => {
      dispatch(registerUserSuccess(res))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.Registered'))
    })
    .catch((error) => {
      dispatch(registerUserError(error))
    })
}
