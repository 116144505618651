import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {createCompany, createCompanyClearError} from '../../stores/companies'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {InputComponent, InputYupValidationsType} from '../formRendererRHF/types'
import Notification from '../Notification'

export default function FormAddCompany() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  //TODO_new: Success message is not appearing after successful submit but on the next render.
  const {pending, error, success} = useSelector((state) => state.companies.create_company)

  const fields = [
    {
      label: t('addCompanyForm.Company'),
      component: InputComponent.TEXT_INPUT,
      name: 'name',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: t('addEmployee.Domain'),
      placeholder: t('addEmployee.DomainPlaceholder'),
      isMulti: false,
      options: permissions.createCompanyLocations,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      label: t('addCompanyForm.Street'),
      component: InputComponent.TEXT_INPUT,
      name: 'street',
    },
    {
      label: t('addCompanyForm.ZipCode'),
      component: InputComponent.TEXT_INPUT,
      name: 'zip',
    },
    {
      label: t('addCompanyForm.City'),
      component: InputComponent.TEXT_INPUT,
      name: 'city',
    },
    {
      label: t('addCompanyForm.State'),
      component: InputComponent.TEXT_INPUT,
      name: 'state',
    },
    {
      label: t('addCompanyForm.Country'),
      component: InputComponent.TEXT_INPUT,
      name: 'country',
    },
    {
      label: t('addCompanyForm.Email'),
      component: InputComponent.TEXT_INPUT,
      name: 'email',
      validations: [
        {
          type: InputYupValidationsType.EMAIL,
          params: [t('yupError.EmailInvalid')],
        },
      ],
    },
    {
      label: t('addCompanyForm.Phone'),
      component: InputComponent.TEXT_INPUT,
      name: 'phone',
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: '',
          submitButtonText: t('addCompanyForm.Save'),
        }}
        onSubmit={(values) => dispatch(createCompany(values, true, userID))}
        noFormBorder
        pending={pending}
        infoMessage={
          <Notification
            error={error}
            setError={() => dispatch(createCompanyClearError())}
            //success={!!success}
          />
        }
      />
    </>
  )
}
