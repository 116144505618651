import axios from '../services/api'
import {createNotificationOnClient} from './notifications'

// types

export const GET_OIDC_PROVIDERS_PENDING = 'idfunctiondb/oidc_providers/GET_OIDC_PROVIDERS_PENDING'
export const GET_OIDC_PROVIDERS_SUCCESS = 'idfunctiondb/oidc_providers/GET_OIDC_PROVIDERS_SUCCESS'
export const GET_OIDC_PROVIDERS_ERROR = 'idfunctiondb/oidc_providers/GET_OIDC_PROVIDERS_ERROR'
export const GET_OIDC_PROVIDERS_CLEAR_ERROR =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDERS_CLEAR_ERROR'

export const GET_OIDC_PROVIDER_ROLES_PENDING =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDER_ROLES_PENDING'
export const GET_OIDC_PROVIDER_ROLES_SUCCESS =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDER_ROLES_SUCCESS'
export const GET_OIDC_PROVIDER_ROLES_ERROR =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDER_ROLES_ERROR'

export const GET_OIDC_PROVIDERS_ADMIN_PENDING =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDERS_ADMIN_PENDING'
export const GET_OIDC_PROVIDERS_ADMIN_SUCCESS =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDERS_ADMIN_SUCCESS'
export const GET_OIDC_PROVIDERS_ADMIN_ERROR =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDERS_ADMIN_ERROR'
export const GET_OIDC_PROVIDERS_ADMIN_CLEAR_ERROR =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDERS_CLEAR_ADMIN_ERROR'

export const GET_OIDC_PROVIDER_ADMIN_PENDING =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDER_ADMIN_PENDING'
export const GET_OIDC_PROVIDER_ADMIN_SUCCESS =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDER_ADMIN_SUCCESS'
export const GET_OIDC_PROVIDER_ADMIN_ERROR =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDES_ADMIN_ERROR'
export const GET_OIDC_PROVIDER_ADMIN_CLEAR_ERROR =
  'idfunctiondb/oidc_providers/GET_OIDC_PROVIDER_CLEAR_ADMIN_ERROR'

export const SET_OIDC_PROVIDER_ADMIN_PENDING =
  'idfunctiondb/oidc_providers/SET_OIDC_PROVIDER_ADMIN_PENDING'
export const SET_OIDC_PROVIDER_ADMIN_SUCCESS =
  'idfunctiondb/oidc_providers/SET_OIDC_PROVIDER_ADMIN_SUCCESS'
export const SET_OIDC_PROVIDER_ADMIN_ERROR =
  'idfunctiondb/oidc_providers/SET_OIDC_PROVIDES_ADMIN_ERROR'
export const SET_OIDC_PROVIDER_ADMIN_CLEAR_ERROR =
  'idfunctiondb/oidc_providers/SET_OIDC_PROVIDER_CLEAR_ADMIN_ERROR'

export const DELETE_OIDC_PROVIDER_ADMIN_PENDING =
  'idfunctiondb/oidc_providers/DELETE_OIDC_PROVIDER_ADMIN_PENDING'
export const DELETE_OIDC_PROVIDER_ADMIN_SUCCESS =
  'idfunctiondb/oidc_providers/DELETE_OIDC_PROVIDER_ADMIN_SUCCESS'
export const DELETE_OIDC_PROVIDER_ADMIN_ERROR =
  'idfunctiondb/oidc_providers/DELETE_OIDC_PROVIDES_ADMIN_ERROR'
export const DELETE_OIDC_PROVIDER_ADMIN_CLEAR_ERROR =
  'idfunctiondb/oidc_providers/DELETE_OIDC_PROVIDER_CLEAR_ADMIN_ERROR'

export const POST_OIDC_PROVIDER_PENDING = 'idfunctiondb/oidc_providers/POST_OIDC_PROVIDER_PENDING'
export const POST_OIDC_PROVIDER_SUCCESS = 'idfunctiondb/oidc_providers/POST_OIDC_PROVIDER_SUCCESS'
export const POST_OIDC_PROVIDER_ERROR = 'idfunctiondb/oidc_providers/POST_OIDC_PROVIDER_ERROR'
export const POST_OIDC_PROVIDER_CLEAR_ERROR =
  'idfunctiondb/oidc_providers/POST_OIDC_PROVIDER_CLEAR_ERROR'

// initial

const initialState = {
  get_oidc_providers: {
    success: [],
    pending: false,
    error: null,
  },

  get_oidc_providers_admin: {
    success: [],
    pending: false,
    error: null,
  },

  get_oidc_provider_admin: {
    success: null,
    pending: false,
    error: null,
  },

  set_oidc_provider_admin: {
    success: null,
    pending: false,
    error: null,
  },

  delete_oidc_provider_admin: {
    success: null,
    pending: false,
    error: null,
  },

  post_oidc_provider: {
    success: null,
    pending: false,
    ereor: null,
  },

  get_oidc_provider_roles: {
    success: null,
    pending: false,
    ereor: null,
  },
}

// reducer

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_OIDC_PROVIDERS_PENDING:
      return {
        ...state,
        get_oidc_providers: {
          ...state.get_oidc_providers,
          pending: true,
        },
      }
    case GET_OIDC_PROVIDERS_SUCCESS:
      return {
        ...state,
        get_oidc_providers: {
          ...state.get_oidc_providers,
          success: action.payload,
          pending: false,
        },
      }
    case GET_OIDC_PROVIDERS_ERROR:
      return {
        ...state,
        get_oidc_providers: {
          ...state.get_oidc_providers,
          error: action.payload,
          pending: false,
        },
      }
    case GET_OIDC_PROVIDERS_CLEAR_ERROR:
      return {
        ...state,
        get_oidc_providers: {
          ...state.get_oidc_providers,
          error: null,
        },
      }
    case GET_OIDC_PROVIDERS_ADMIN_PENDING:
      return {
        ...state,
        get_oidc_providers_admin: {
          ...state.get_oidc_providers_admin,
          pending: true,
        },
      }
    case GET_OIDC_PROVIDERS_ADMIN_SUCCESS:
      return {
        ...state,
        get_oidc_providers_admin: {
          ...state.get_oidc_providers_admin,
          success: action.payload,
          pending: false,
        },
      }
    case GET_OIDC_PROVIDERS_ADMIN_ERROR:
      return {
        ...state,
        get_oidc_providers_admin: {
          ...state.get_oidc_providers_admin,
          error: action.payload,
          pending: false,
        },
      }
    case GET_OIDC_PROVIDERS_ADMIN_CLEAR_ERROR:
      return {
        ...state,
        get_oidc_providers_admin: {
          ...state.get_oidc_providers_admin,
          error: null,
        },
      }

    case GET_OIDC_PROVIDER_ADMIN_PENDING:
      return {
        ...state,
        get_oidc_provider_admin: {
          ...state.get_oidc_provider_admin,
          pending: true,
        },
      }
    case GET_OIDC_PROVIDER_ADMIN_SUCCESS:
      return {
        ...state,
        get_oidc_provider_admin: {
          ...state.get_oidc_provider_admin,
          success: action.payload,
          pending: false,
        },
      }
    case GET_OIDC_PROVIDER_ADMIN_ERROR:
      return {
        ...state,
        get_oidc_provider_admin: {
          ...state.get_oidc_provider_admin,
          error: action.payload,
          pending: false,
        },
      }
    case GET_OIDC_PROVIDER_ADMIN_CLEAR_ERROR:
      return {
        ...state,
        get_oidc_provider_admin: {
          ...state.get_oidc_provider_admin,
          error: null,
        },
      }

    case SET_OIDC_PROVIDER_ADMIN_PENDING:
      return {
        ...state,
        set_oidc_provider_admin: {
          ...state.set_oidc_provider_admin,
          pending: true,
        },
      }
    case SET_OIDC_PROVIDER_ADMIN_SUCCESS:
      return {
        ...state,
        set_oidc_provider_admin: {
          ...state.set_oidc_provider_admin,
          success: action.payload,
          pending: false,
        },
      }
    case SET_OIDC_PROVIDER_ADMIN_ERROR:
      return {
        ...state,
        set_oidc_provider_admin: {
          ...state.set_oidc_provider_admin,
          error: action.payload,
          pending: false,
        },
      }
    case SET_OIDC_PROVIDER_ADMIN_CLEAR_ERROR:
      return {
        ...state,
        set_oidc_provider_admin: {
          ...state.set_oidc_provider_admin,
          error: null,
        },
      }

    case DELETE_OIDC_PROVIDER_ADMIN_PENDING:
      return {
        ...state,
        delete_oidc_provider_admin: {
          ...state.delete_oidc_provider_admin,
          pending: true,
        },
      }
    case DELETE_OIDC_PROVIDER_ADMIN_SUCCESS:
      return {
        ...state,
        delete_oidc_provider_admin: {
          ...state.delete_oidc_provider_admin,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_OIDC_PROVIDER_ADMIN_ERROR:
      return {
        ...state,
        delete_oidc_provider_admin: {
          ...state.delete_oidc_provider_admin,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_OIDC_PROVIDER_ADMIN_CLEAR_ERROR:
      return {
        ...state,
        delete_oidc_provider_admin: {
          ...state.delete_oidc_provider_admin,
          error: null,
        },
      }

    case POST_OIDC_PROVIDER_PENDING:
      return {
        ...state,
        post_oidc_provider: {
          ...state.post_oidc_provider,
          pending: true,
        },
      }
    case POST_OIDC_PROVIDER_SUCCESS:
      return {
        ...state,
        post_oidc_provider: {
          ...state.post_oidc_provider,
          success: action.payload,
          pending: false,
        },
      }
    case POST_OIDC_PROVIDER_ERROR:
      return {
        ...state,
        post_oidc_provider: {
          ...state.post_oidc_provider,
          error: action.payload,
          pending: false,
        },
      }
    case POST_OIDC_PROVIDER_CLEAR_ERROR:
      return {
        ...state,
        post_oidc_provider: {
          ...state.post_oidc_provider,
          error: null,
        },
      }
    case GET_OIDC_PROVIDER_ROLES_PENDING:
      return {
        ...state,
        get_oidc_provider_roles: {
          ...state.get_oidc_providers,
          pending: true,
        },
      }
    case GET_OIDC_PROVIDER_ROLES_SUCCESS:
      return {
        ...state,
        get_oidc_provider_roles: {
          ...state.get_oidc_providers,
          success: action.payload,
          pending: false,
        },
      }
    case GET_OIDC_PROVIDER_ROLES_ERROR:
      return {
        ...state,
        get_oidc_provider_roles: {
          ...state.get_oidc_providers,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// actions

function getOidcProvidersPending() {
  return {type: GET_OIDC_PROVIDERS_PENDING}
}
function getOidcProvidersSuccess(payload) {
  return {type: GET_OIDC_PROVIDERS_SUCCESS, payload: payload}
}
function getOidcProvidersError(error) {
  return {type: GET_OIDC_PROVIDERS_ERROR, payload: error}
}

export function getOidcProvidersClearError() {
  return {type: GET_OIDC_PROVIDERS_CLEAR_ERROR}
}

function getOidcProvidersAdminPending() {
  return {type: GET_OIDC_PROVIDERS_ADMIN_PENDING}
}
function getOidcProvidersAdminSuccess(payload) {
  return {type: GET_OIDC_PROVIDERS_ADMIN_SUCCESS, payload: payload}
}
function getOidcProvidersAdminError(error) {
  return {type: GET_OIDC_PROVIDERS_ADMIN_ERROR, payload: error}
}

export function getOidcProvidersAdminClearError() {
  return {type: GET_OIDC_PROVIDERS_ADMIN_CLEAR_ERROR}
}

function getOidcProviderAdminPending() {
  return {type: GET_OIDC_PROVIDER_ADMIN_PENDING}
}
function getOidcProviderAdminSuccess(payload) {
  return {type: GET_OIDC_PROVIDER_ADMIN_SUCCESS, payload: payload}
}
function getOidcProviderAdminError(error) {
  return {type: GET_OIDC_PROVIDER_ADMIN_ERROR, payload: error}
}

export function getOidcProviderAdminClearError() {
  return {type: GET_OIDC_PROVIDER_ADMIN_CLEAR_ERROR}
}

function setOidcProviderAdminPending() {
  return {type: SET_OIDC_PROVIDER_ADMIN_PENDING}
}
function setOidcProviderAdminSuccess(payload) {
  return {type: SET_OIDC_PROVIDER_ADMIN_SUCCESS, payload: payload}
}
function setOidcProviderAdminError(error) {
  return {type: SET_OIDC_PROVIDER_ADMIN_ERROR, payload: error}
}

export function setOidcProviderAdminClearError() {
  return {type: SET_OIDC_PROVIDER_ADMIN_CLEAR_ERROR}
}

function deleteOidcProviderAdminPending() {
  return {type: DELETE_OIDC_PROVIDER_ADMIN_PENDING}
}
function deleteOidcProviderAdminSuccess(payload) {
  return {type: DELETE_OIDC_PROVIDER_ADMIN_SUCCESS, payload: payload}
}
function deleteOidcProviderAdminError(error) {
  return {type: DELETE_OIDC_PROVIDER_ADMIN_ERROR, payload: error}
}

export function deleteOidcProviderAdminClearError() {
  return {type: DELETE_OIDC_PROVIDER_ADMIN_CLEAR_ERROR}
}

function postOidcProviderPending() {
  return {type: POST_OIDC_PROVIDER_PENDING}
}
function postOidcProviderSuccess(payload) {
  return {type: POST_OIDC_PROVIDER_SUCCESS, payload: payload}
}
function postOidcProviderError(error) {
  return {type: POST_OIDC_PROVIDER_ERROR, payload: error}
}
export function postOidcProviderClearError() {
  return {type: POST_OIDC_PROVIDER_CLEAR_ERROR}
}

function getOidcProviderRolesPending() {
  return {type: GET_OIDC_PROVIDER_ROLES_PENDING}
}
function getOidcProviderRolesSuccess(payload) {
  return {type: GET_OIDC_PROVIDER_ROLES_SUCCESS, payload: payload}
}
function getOidcProviderRolesError(error) {
  return {type: GET_OIDC_PROVIDER_ROLES_ERROR, payload: error}
}

export const getOidcProviders = () => (dispatch) => {
  dispatch(getOidcProvidersPending())
  return axios
    .get('/api/v1/identity_providers')
    .then((res) => {
      dispatch(getOidcProvidersSuccess(res.data.results))
    })
    .catch((error) => dispatch(getOidcProvidersError(error)))
}

export const getOidcProvidersAdmin = () => (dispatch) => {
  dispatch(getOidcProvidersAdminPending())
  return axios
    .get('/api/v1/identity_providers/oidc')
    .then((res) => {
      dispatch(getOidcProvidersAdminSuccess(res.data.results))
    })
    .catch((error) => dispatch(getOidcProvidersAdminError(error)))
}

export const getOidcProviderAdmin = (id) => (dispatch) => {
  dispatch(getOidcProviderAdminPending())
  return axios
    .get(`/api/v1/identity_providers/${id}/oidc`)
    .then((res) => {
      dispatch(getOidcProviderAdminSuccess(res.data))
    })
    .catch((error) => dispatch(getOidcProviderAdminError(error)))
}

export const setOidcProviderAdmin = (id, values) => (dispatch) => {
  dispatch(setOidcProviderAdminPending())
  return axios
    .put(`/api/v1/identity_providers/${id}/oidc`, values)
    .then((res) => {
      dispatch(setOidcProviderAdminSuccess(res.data))
    })
    .catch((error) => dispatch(setOidcProviderAdminError(error)))
}

export const getOidcProviderRoles = (id) => (dispatch) => {
  dispatch(getOidcProviderRolesPending())
  return axios
    .get(`/api/v1/identity_providers/${id}/roles`)
    .then((res) => {
      dispatch(getOidcProviderRolesSuccess(res.data))
    })
    .catch((error) => dispatch(getOidcProviderRolesError(error)))
}

export const setOidcProviderLogoAdmin = (id, uploadedFile) => (dispatch) => {
  dispatch(setOidcProviderAdminPending())
  let formData = new FormData()
  if (uploadedFile) {
    formData.append('logo', uploadedFile)
  }
  return axios
    .put(`/api/v1/identity_providers/${id}/photo_thumbnail`, formData)
    .then((res) => {
      dispatch(setOidcProviderAdminSuccess(res.data))
    })
    .catch((error) => dispatch(setOidcProviderAdminError(error)))
}

export const setOidcProviderValidationRuleAdmin = (id, values) => (dispatch) => {
  dispatch(setOidcProviderAdminPending())
  return axios
    .put(`/api/v1/identity_providers/${id}/oidc`, values)
    .then((res) => {
      dispatch(setOidcProviderAdminSuccess(res.data))
    })
    .catch((error) => dispatch(setOidcProviderAdminError(error)))
}

export const deleteOidcProviderValidationRuleAdmin = (id, key) => (dispatch) => {
  dispatch(setOidcProviderAdminPending())
  return axios
    .delete(`/api/v1/identity_providers/${id}/oidc/validation_rules/${key}`)
    .then((res) => {
      dispatch(setOidcProviderAdminSuccess(res.data))
    })
    .catch((error) => dispatch(setOidcProviderAdminError(error)))
}

export const deleteOidcProviderMappingRuleAdmin = (id, key) => (dispatch) => {
  dispatch(setOidcProviderAdminPending())
  return axios
    .delete(`/api/v1/identity_providers/${id}/oidc/mapping_rules/${key}`)
    .then((res) => {
      dispatch(setOidcProviderAdminSuccess(res.data))
    })
    .catch((error) => dispatch(setOidcProviderAdminError(error)))
}

export const setOidcProviderMappingRuleAdmin = (id, values) => (dispatch) => {
  dispatch(setOidcProviderAdminPending())
  return axios
    .put(`/api/v1/identity_providers/${id}/oidc`, values)
    .then((res) => {
      dispatch(setOidcProviderAdminSuccess(res.data))
    })
    .catch((error) => dispatch(setOidcProviderAdminError(error)))
}

export const deleteOidcProviderAdmin = (id) => (dispatch) => {
  dispatch(deleteOidcProviderAdminPending())
  return axios
    .delete(`/api/v1/identity_providers/${id}/oidc`)
    .then((res) => {
      dispatch(deleteOidcProviderAdminSuccess(res.data))
    })
    .catch((error) => dispatch(deleteOidcProviderAdminError(error)))
}

export const postOidcProvider = (uploadedFile, values) => (dispatch) => {
  dispatch(postOidcProviderPending())
  let formData = new FormData()
  if (uploadedFile) {
    formData.append('logo', uploadedFile.files[0])
  }
  for (let i = 0; i < Object.keys(values).length; i++) {
    let temp = values[Object.keys(values)[i]]
    if (
      Object.keys(values)[i] === 'mapping_rules' ||
      Object.keys(values)[i] === 'validation_rules'
    ) {
      temp = JSON.stringify(temp)
    }
    formData.append(`${Object.keys(values)[i]}`, temp)
  }
  return axios
    .post(`/api/v1/identity_providers/oidc`, formData)
    .then((res) => {
      dispatch(postOidcProviderSuccess(res))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.IdentityProviderAdded'))
      return res.data
    })
    .catch((error) => dispatch(postOidcProviderError(error)))
}
