import {
  ColumnFilter,
  ColumnFilterDate,
  SelectCheckbox,
  SelectColumnFilterCardProfiles,
  SelectColumnFilterCardStates,
  SelectColumnFilterCompanies,
  SelectColumnFilterEmployeeState,
  SelectColumnFilterInventoryTypes,
  SelectColumnFilterLocations,
  SelectColumnFilterRenderAt,
  SelectColumnFilterResourceTypes,
} from './TableHeaders'
import * as TableCells from './TableCells'
import {SelectColumnFilter} from './TableComponents'
import {format} from 'date-fns'
import tablesConfig from './configs'

export const getColumns = (tableId, columnAccessors: string[], t, permissions?) =>
  columnAccessors
    .map((columnAccessor) => {
      const column = tablesConfig[tableId]?.config.find((col) => col.accessor === columnAccessor)

      if (!column) {
        return null
      }
      if (column.permissionsAccessor && permissions) {
        column.permissions = permissions[column.permissionsAccessor]
      }

      if (column.cell) {
        column.Cell = getCell[column.cell]
      }

      if (column.Filter) {
        column.Filter = getFilter[column.Filter]
      }

      return {
        ...column,
        Header: getHeader[column.header] || t(column.header),
        accessor: getAccessor[column.accessor] || column.accessor,
        label: t(column.label),
      }
    })
    .filter((col) => !!col)

export const getAccessor = {
  ValidToFn: (row) => {
    return row.tags
      .map(
        (tag) =>
          tag.valid_to !== null
            ? `${tag.tag.name} (${format(new Date(tag.valid_to), 'dd-MM-yyyy')})`
            : `${tag.tag.name}`, // format(new Date(tag.valid_to), "dd/MM/yyyy")
      )
      .join(', ')
  },
  ActiveFn: (row) => (row.active === true ? 'active' : 'inactive'),
  DateCreatedAtFn: (row) => format(new Date(row.created_at), "dd/MM/yyyy 'at' HH:mm"),
  DateValidFromFn: (row) => format(new Date(row.valid_from), "dd/MM/yyyy 'at' HH:mm"),
  DateValidToFn: (row) => format(new Date(row.valid_to), "dd/MM/yyyy 'at' HH:mm"),
  DateUpdatedAtFn: (row) => format(new Date(row.updated_at), "dd/MM/yyyy 'at' HH:mm"),
  SubscriptionFn: (row) =>
    row.subscriptionSelectors?.find(({value}) => value === row?.endpoint_selector)?.label,
  RolesFn: (row) => row.roles.join(', '),
}
export const getHeader = {
  SelectCheckbox,
  ColumnFilter,
  ColumnFilterDate,
  SelectColumnFilterLocations,
  SelectColumnFilterCardProfiles,
  SelectColumnFilterCardStates,
  SelectColumnFilterCompanies,
  SelectColumnFilterEmployeeState,
  SelectColumnFilterInventoryTypes,
  SelectColumnFilterResourceTypes,
  SelectColumnFilterRenderAt,
}

export const getCell = {
  SelectCheckboxCell: TableCells.SelectCheckboxCell,
  LocationCell: TableCells.LocationCell,
  PersonActionCell: TableCells.PersonActionCell,
  TwoRowCellLink: TableCells.TwoRowCellLink,
  TwoRowCell: TableCells.TwoRowCell,
  StatusPill: TableCells.StatusPill,
  CardOwnerAndProfileCell: TableCells.CardOwnerAndProfileCell,
  DateCell: TableCells.DateCell,
  AvatarCell: TableCells.AvatarCell,
  EditEmployeeStatusCell: TableCells.EditEmployeeStatusCell,
  EditVisitorStatusCell: TableCells.EditVisitorStatusCell,
  EmployeeActionCell: TableCells.EmployeeActionCell,
  InventoryTableActionsCell: TableCells.InventoryTableActionsCell,
  ResourceTableActionsCell: TableCells.ResourceTableActionsCell,
  VisitorActionCell: TableCells.VisitorActionCell,
  FormEditCompanyCell: TableCells.FormEditCompanyCell,
  FormEditConfigurationCell: TableCells.FormEditConfigurationCell,
  TodosActionsCell: TableCells.TodosActionsCell,
  LinkCell: TableCells.LinkCell,
  FileVisitorActionCell: TableCells.FileVisitorActionCell,
  FileEmployeeActionCell: TableCells.FileEmployeeActionCell,
  FormEditFormCell: TableCells.FormEditFormCell,
  IdentityProviderTwoRowCellLink: TableCells.IdentityProviderTwoRowCellLink,
  IdentityProviderCustomCell: TableCells.IdentityProviderCustomCell,
  IdentityProviderActionsCell: TableCells.IdentityProviderActionsCell,
  InventoryTypeAvatarCell: TableCells.InventoryTypeAvatarCell,
  InventoryTypeActionsCell: TableCells.InventoryTypeActionsCell,
  FormEditPrinterActionsCell: TableCells.FormEditPrinterActionsCell,
  FormEditResourceTypeActionsCell: TableCells.FormEditResourceTypeActionsCell,
  FormEditSubscriptionEndpointsCell: TableCells.FormEditSubscriptionEndpointsCell,
  FormEditSubscriptionWorkflowCell: TableCells.FormEditSubscriptionWorkflowCell,
  EditSubscriptionActionCell: TableCells.EditSubscriptionActionCell,
  EditSubscriptionTakeOverCell: TableCells.EditSubscriptionTakeOverCell,
  Pill: TableCells.Pill,
  BooleanCell: TableCells.BooleanCell,
  FormEditUserActionsCell: TableCells.FormEditUserActionsCell,
  RenderOnUiCell: TableCells.RenderOnUiCell,
  FormEditWebhookActionsCell: TableCells.FormEditWebhookActionsCell,
  EditWorkflowActionCell: TableCells.EditWorkflowActionCell,
  EditWorkflowAuthenticatedUseActionCell: TableCells.EditWorkflowAuthenticatedUseActionCell,
  DeleteWorkflowCell: TableCells.DeleteWorkflowCell,
  MeetingActionCell: TableCells.MeetingActionCell,
  ResourcesEmployeeActionCell: TableCells.ResourcesEmployeeActionCell,
  InventoriesVisitorActionsCell: TableCells.InventoriesVisitorActionsCell,
  InventoriesEmployeeActionsCell: TableCells.InventoriesEmployeeActionsCell,
  CustomAttributesCell: TableCells.CustomAttributesCell,
  ResourceIdentitiesTableActionsCell: TableCells.ResourceIdentitiesTableActionsCell,
  ResourceIdentitiesChildrenTableActionsCell: TableCells.ResourceIdentitiesChildrenTableActionsCell,
  TreeGraphTableCell: TableCells.TreeGraphTableCell,
  IdentitiesTableActionsCell: TableCells.IdentitiesTableActionsCell,
  IdentitiesChildrenTableActionsCell: TableCells.IdentitiesChildrenTableActionsCell,
  LinkForwardCell: TableCells.LinkForwardCell,
  LinkInventoryCell: TableCells.LinkInventoryCell,
  LinkResourceCell: TableCells.LinkResourceCell,
  IdentitiesEmployeeActionsCell: TableCells.IdentitiesEmployeeActionsCell,
}

export const getFilter = {
  SelectColumnFilter: SelectColumnFilter,
}
