import {classNames} from '../../../utils/classNames'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '../ErrorMessage'
import {ExclamationCircleIcon} from '@heroicons/react/outline'
import {useTranslation} from 'react-i18next'
import {useState, useEffect, useRef} from 'react'
import {getRhfValidations, isFieldRequired} from '../lib/utils'

function DatePicker(props) {
  const {
    name,
    label,
    inputType,
    allowEndless,
    validations,
    validationType,
    withRhfValidation,
    defaultValue = null,
  } = props
  const {
    setValue,
    resetField,
    register,
    formState,
    getValues,
    getFieldState,
    clearErrors,
    trigger,
  } = useFormContext()
  const {t} = useTranslation()

  const [allowEndlessChecked, setAllowEndlessChecked] = useState(false)
  const error = getFieldState(name, formState).error?.message || ''
  const maxTimestamp = new Date(86400000000000).toISOString().slice(0, -8)
  const id = name
  const registerOptions = withRhfValidation ? getRhfValidations(getValues, name, validations) : {}
  const {ref, ...restRegister} = register(name, registerOptions)
  const endlessCheckbox = useRef(null)

  useEffect(() => {
    if (formState.isSubmitSuccessful && allowEndlessChecked) {
      endlessCheckbox?.current?.click()
      setAllowEndlessChecked(false)
    }
  }, [formState.isSubmitSuccessful, allowEndlessChecked])

  useEffect(() => {
    if (defaultValue) {
      trigger(name).then()
    }
  }, [defaultValue])

  return (
    <>
      <div className="relative">
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {t(label)}
          {isFieldRequired(validations, validationType) && '*'}
        </label>
        <input
          ref={(e) => {
            ref(e)
            const listener = () => {
              try {
                e?.showPicker()
              } catch (error) {}
            }
            e?.addEventListener('click', listener)
          }}
          className={classNames(
            error
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
              : 'border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500',
            ' mt-1 w-full rounded-md sm:text-sm',
            allowEndless && allowEndlessChecked ? 'hidden' : 'block',
          )}
          {...restRegister}
          name={name}
          defaultValue={defaultValue}
          type={inputType || 'date'}
          id={id}
          data-testid={`date-picker-${name}`}
        />
        {error && !allowEndlessChecked && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-end py-3 pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {allowEndless && (
        <label className="flex w-fit cursor-pointer items-center gap-2 font-medium text-gray-700">
          <input
            ref={endlessCheckbox}
            className="h-4 w-4 rounded border-gray-300 text-lime-600 accent-lime-500 focus:ring-lime-500"
            name={name}
            type="checkbox"
            data-testid={`checkbox-${name}`}
            onChange={(e) => {
              if (e.target.checked) {
                setValue(name, maxTimestamp)
                setAllowEndlessChecked(true)
                clearErrors(name)
                trigger(name).then()
              } else {
                resetField(name)
                setAllowEndlessChecked(false)
              }
            }}
          />
          {t('datePickerForms.Endless')}
        </label>
      )}
      <ErrorMessage error={t(error)} />
    </>
  )
}

export default DatePicker
