import {Formik, Form} from 'formik'
import {SelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'
import {
  getSubscriptionSelectors,
  updateSubscription,
  updateSubscriptionClearError,
} from '../../stores/subscriptions'
import {useEffect} from 'react'

export default function FormEditSubscriptionEndpoint({id}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(getSubscriptionSelectors())
  }, [dispatch])

  const subscriptionSelectors = useSelector(
    (state) => state.subscriptions.get_subscription_selectors.success,
  )

  const pending = useSelector((state) => state.subscriptions.update_subscription.pending)
  const error = useSelector((state) => state.subscriptions.update_subscription.error)

  const initialValues = {
    endpoint_selector: '',
  }

  const validationSchema = Yup.object({
    endpoint_selector: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('subscriptions.EndPoint')}),
    ),
  })

  const handleSubmit = (values, actions) => {
    dispatch(updateSubscription(id, values))
    actions.resetForm()
  }

  const options = subscriptionSelectors.map(({value, label}) => ({
    value: value,
    label: t(`subscriptions.${label}`),
  }))

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        <Form>
          <SelectInput
            label={t('subscriptions.EndPoints')}
            name="endpoint_selector"
            type="text"
            placeholder=""
            required
            options={options}
          />
          <FormikSaveButton
            type="submit"
            testid="form-edit-subscription-submit"
            text={t('subscriptions.Save')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(updateSubscriptionClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
