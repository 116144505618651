import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {createQuickEmployee, createQuickEmployeeClearError} from '../../stores/employees'
import {getCompaniesCLearError, deleteCompanyClearError} from '../../stores/companies'
import {iso8061UTC} from '../../utils/datetime'
import {InputComponent, InputYupValidationsType} from '../formRendererRHF/types'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import ErrorOnView from '../ErrorOnView'
import AddNewItem from '../AddNewItemRHF'
import {ModalContentsBase} from '../Modal'
import FormAddCompany from './FormAddCompany'
import FormEditCompany from './FormEditCompany'
import {useCompanyOptions} from '../../hooks/useCompanies'

export default function FormAddEmployeeQuick() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const pending = useSelector((state) => state.employees.create_quick_employee.pending)
  const error = useSelector((state) => state.employees.create_quick_employee.error)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const get_companiesError = useSelector((state) => state.companies.get_companies.error)
  const delete_company = useSelector((state) => state.companies.delete_company)

  const {companies, defaultCompany} = useCompanyOptions()

  const handleSubmit = (values) => {
    values['employee_location_id'] = values.location
    values['person_location_id'] = values.location
    values['employee-email'] = values['person-email']
    if (values['employee-start_of_contract'] === '') {
      delete values['employee-start_of_contract']
    } else {
      values['employee-start_of_contract'] = iso8061UTC(values['employee-start_of_contract'])
    }
    delete values['add-company']
    dispatch(createQuickEmployee(values, userID))
  }

  const fields = [
    {
      component: InputComponent.SELECT,
      name: `company_id`,
      label: t('employeeCompanyInfo.Company'),
      placeholder: t('addEmployee.CompanyPlaceholder'),
      isMulti: false,
      options: companies,
      defaultValue: defaultCompany?.id,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.CUSTOM,
      name: 'add-company',
      label: 'add-company',
      renderFn: permissions.createCompany ? (
        <AddNewItem
          fieldName="company_id"
          options={companies}
          label={t('employeeCompanyInfo.ManageCompany')}
          addComponent={
            <ModalContentsBase title={t('addCompanyForm.AddCompanyTitle')}>
              <FormAddCompany />
            </ModalContentsBase>
          }
          deleteState={delete_company}
          setDeleteError={deleteCompanyClearError}
          editComponent={(selectedItem) => (
            <ModalContentsBase title={t('addCompanyForm.EditCompany')}>
              <FormEditCompany notify={true} selectedItem={selectedItem} />
            </ModalContentsBase>
          )}
        />
      ) : null,
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: t('addEmployee.Domain'),
      placeholder: t('addEmployee.DomainPlaceholder'),
      options: permissions.createEmployeeLocations,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      label: t('addPersonForm.FirstName'),
      component: InputComponent.TEXT_INPUT,
      name: 'person-first_name',
    },
    {
      label: t('addPersonForm.LastName'),
      component: InputComponent.TEXT_INPUT,
      name: 'person-last_name',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      label: t('employeePersonalInfo.PersonalNumber'),
      component: InputComponent.TEXT_INPUT,
      name: 'person-number',
    },
    {
      label: t('employeePersonalInfo.EmployeeNo'),
      component: InputComponent.TEXT_INPUT,
      name: 'employee-employee_number',
    },
    {
      label: t('login.Email'),
      component: InputComponent.TEXT_INPUT,
      name: 'person-email',
      validations: [
        {
          type: InputYupValidationsType.EMAIL,
          params: [t('yupError.EmailInvalid')],
        },
      ],
    },
    {
      label: t('employeePersonalInfo.Position'),
      component: InputComponent.TEXT_INPUT,
      name: 'employee-position',
    },
    {
      label: t('employeeCompanyInfo.Department'),
      component: InputComponent.TEXT_INPUT,
      name: 'employee-department',
    },
    {
      label: t('addEmployee.CostCenter'),
      component: InputComponent.TEXT_INPUT,
      name: 'employee-costcenter',
    },
    {
      label: t('employeePersonalInfo.Phone'),
      component: InputComponent.TEXT_INPUT,
      name: 'employee-phone',
    },
    {
      label: t('employeePersonalInfo.StartOfContract'),
      component: InputComponent.DATEPICKER,
      name: 'employee-start_of_contract',
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: '',
          submitButtonText: t('addEmployee.AddEmployee'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        infoMessage={
          <Notification error={error} setError={() => dispatch(createQuickEmployeeClearError())} />
        }
      />
      {!!get_companiesError && (
        <ErrorOnView
          error={get_companiesError}
          setError={() => dispatch(getCompaniesCLearError())}
        />
      )}
    </>
  )
}
