import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {UserIcon, ExclamationIcon, ClipboardCheckIcon} from '@heroicons/react/outline'
import {Link} from 'react-router-dom'

const HomeSelfService = () => {
  const user = useSelector((state) => state.auth.fetchUserSuccess)
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const {t} = useTranslation()

  const employeeOrVisitorUrl =
    user.employee_id === null ? `/visitors/${user.visitor_id}` : `/employees/${user.employee_id}`
  const employeeOrVisitorPermission =
    user.employee_id === null ? permissions.readVisitor : permissions.readEmployee
  const employeeOrVisitorName =
    user.employee_id === null ? t('sidebar.MyVisitorData') : t('sidebar.MyEmployeeData')
  const employeeOrVisitorDescription =
    user.employee_id === null
      ? t('home.MyVisitorDataDescription')
      : t('home.MyEmployeeDataDescription')

  const navigation = [
    {
      name: employeeOrVisitorName,
      desc: employeeOrVisitorDescription,
      permission: employeeOrVisitorPermission,
      href: employeeOrVisitorUrl,
      icon: UserIcon,
    },
    {
      name: t('sidebar.Todos'),
      desc: t('home.TodosDescription'),
      href: '/todos/',
      permission: true, // should be visible for anyone; admin or owner
      icon: ClipboardCheckIcon,
      current: false,
    },
  ]

  const permissionedNavigation = navigation.filter((item) => item.permission === true)

  return (
    <>
      <div className="p-8">
        <div className="flex items-center gap-x-6 rounded-lg bg-lime-600 py-2.5 px-6 sm:px-3.5 sm:before:flex-1">
          <p className="flex text-sm leading-6 text-white">
            <strong className="hidden pr-1 font-semibold md:block">
              {t('userInfo.PreferredTimezone')}:
            </strong>
            {user.preferred_timezone === null
              ? t('userInfo.NoTimezoneSelectedShort')
              : user.preferred_timezone === 'N/A'
              ? t('userInfo.CanNotSetTimezoneShort')
              : user.preferred_timezone}
          </p>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-1 w-1 fill-current text-white"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          <p className="flex text-sm leading-6 text-white">
            <strong className="hidden pr-1 font-semibold md:block">
              {t('userInfo.LocalTimezone')}:
            </strong>
            {Intl.DateTimeFormat().resolvedOptions().timeZone}
          </p>
        </div>
        {permissionedNavigation.length === 0 ? (
          <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t('home.UserHasNoPermission')}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t('home.UserHasNoPermissionDescription')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ul className="grid grid-cols-1 gap-6 pt-2 sm:grid-cols-2 lg:grid-cols-3">
            {permissionedNavigation.map((item) => (
              <li
                key={item.name}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
              >
                <Link to={item.href}>
                  <div className="flex w-full items-center justify-between space-x-6 p-6">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-gray-900">{item.name}</h3>
                      </div>
                      <p className="mt-1 truncate text-sm text-gray-500">{item.desc}</p>
                    </div>
                    <div className="h-6 w-6">
                      <item.icon />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}

export default HomeSelfService
