import {ButtonSuccessAndError} from './Buttons'
import {ExclamationIcon} from '@heroicons/react/outline'
import {useDispatch, useSelector} from 'react-redux'

export default function DeletionWarning({
  message,
  text,
  deleteFunction,
  id,
  error,
  setError,
  success,
  setSuccess,
  pending,
  disabled,
  onSubmit,
  values,
}) {
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const dispatch = useDispatch()
  return (
    <>
      <div className="mx-auto flex items-center justify-center">
        <ExclamationIcon className="h-8 w-8 items-center pr-2 text-red-600" aria-hidden="true" />
        <div className="mt-2">
          <p className="text-sm text-gray-500">{message}</p>
        </div>
      </div>
      <ButtonSuccessAndError
        type="button"
        text={text}
        error={error}
        pending={pending}
        disabled={disabled}
        success={success}
        setSuccess={setSuccess}
        setError={() => setError && dispatch(setError())}
        onClick={onSubmit ? () => onSubmit() : () => dispatch(deleteFunction(id, values || userID))}
      />
    </>
  )
}
