import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {postOidcProvider, postOidcProviderClearError} from '../../stores/oidc_providers'
import {QuestionMarkCircleIcon} from '@heroicons/react/outline'
import {Modal, ModalContentsBase, ModalOpenButton} from '../Modal'
import InfoBox from '../InfoBox'
import Notification from '../Notification'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import {useFormContext} from 'react-hook-form'
import OptionsFieldArray from './FormBuilder/OptionsFieldArray'

const Warning = () => {
  const {t} = useTranslation()
  const {watch} = useFormContext()
  const value = watch('name') || ''
  return (
    <p className="mt-2 text-sm text-red-600">{`${t(
      'addIdentityProvider.WarningMessage',
    )} "/api/v1/auth/oidc/sso/${value.toString()}"`}</p>
  )
}
export default function FormAddOidcProvider() {
  const dispatch = useDispatch()
  const {pending, error, success} = useSelector((state) => state.oidc_providers.post_oidc_provider)
  const {t} = useTranslation()

  const handleSubmit = async (values) => {
    const payload = {...values}
    delete payload.fields
    const [validationRules, mappingRules] = values.fields || []
    if (validationRules?.options.length) {
      payload.validation_rules = validationRules.options
    }
    if (mappingRules?.options.length) {
      payload.mapping_rules = mappingRules.options
    }
    dispatch(postOidcProvider({files: [values.file.file]}, payload))
  }

  const fields = [
    {
      label: t('uploadFile'),
      component: InputComponent.FILE,
      name: 'file',
      validationType: YupValidationType.OBJECT,
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[2, 3], t('yupError.FileType')],
        },
        {
          type: InputYupValidationsType.FILESIZE,
          params: [10000000, t('yupError.MaxFileSize')],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: 'addIdentityProvider.Name',
      name: 'name',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
        {
          type: InputYupValidationsType.MAX,
          params: [256, t('yupError.MaxCharsAllowed')],
        },
      ],
    },
    {
      component: InputComponent.CUSTOM,
      label: 'addIdentityProvider.WarningMessage',
      name: 'addIdentityProvider',
      renderFn: <Warning />,
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: 'addIdentityProvider.Description',
      name: 'description',
    },
    {
      component: InputComponent.CHECKBOX,
      name: 'form-oidc-add-user-checkbox',
      label: 'addIdentityProvider.CreateUsersPermission',
    },
    {
      component: InputComponent.CHECKBOX,
      name: 'form-oidc-edit-user-checkbox',
      label: 'addIdentityProvider.UpdateUsersPermission',
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: 'addIdentityProvider.OidcProviderId',
      name: 'oidc_provider_id',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: 'addIdentityProvider.OidcProviderSecret',
      name: 'oidc_provider_secret',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: 'addIdentityProvider.ServerMetadataUrl',
      name: 'server_metadata_url',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: 'addIdentityProvider.Scope',
      name: 'scope',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: 'addIdentityProvider.DiscoveryQuery',
      name: 'discovery_query',
    },
    {
      component: InputComponent.CUSTOM,
      label: 'addIdentityProvider.ValidationRules',
      name: 'validationRules',
      renderFn: (
        <>
          <OptionsFieldArray
            header={
              <div className="flex">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {t('addIdentityProvider.ValidationRules')}
                </label>
                <div className="pl-2 pt-1 md:pt-3">
                  <Modal>
                    <ModalOpenButton>
                      <QuestionMarkCircleIcon className="h-4 w-4 text-blue-400 hover:text-blue-500" />
                    </ModalOpenButton>
                    <ModalContentsBase title={t('addIdentityProvider.ValidationRules')}>
                      <InfoBox message={t('addIdentityProvider.ValidationRulesHelperText')} />
                    </ModalContentsBase>
                  </Modal>
                </div>
              </div>
            }
            labelKey="addIdentityProvider.ValidationRules"
            isRequired={false}
            inputSettingsIndex={0}
          />
        </>
      ),
    },
    {
      component: InputComponent.CUSTOM,
      label: 'addIdentityProvider.MappingRules',
      name: 'mappingRules',
      renderFn: (
        <>
          <OptionsFieldArray
            header={
              <div className="flex ">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {t('addIdentityProvider.MappingRules')}
                </label>
                <div className="pl-2 pt-1 md:pt-3">
                  <Modal>
                    <ModalOpenButton>
                      <QuestionMarkCircleIcon className="h-4 w-4 text-blue-400 hover:text-blue-500" />
                    </ModalOpenButton>
                    <ModalContentsBase title={t('addIdentityProvider.MappingRules')}>
                      <InfoBox message={t('addIdentityProvider.MappingRulesHelperText')} />
                    </ModalContentsBase>
                  </Modal>
                </div>
              </div>
            }
            labelKey="addIdentityProvider.MappingRules"
            inputSettingsIndex={1}
            isRequired={false}
          />
        </>
      ),
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-add-oidc-provider-submit',
          submitButtonText: t('addInventoryForm.InventoryAdd'),
        }}
        onSubmit={handleSubmit}
        pending={pending}
        noFormBorder
        infoMessage={
          <Notification
            error={error}
            setError={() => dispatch(postOidcProviderClearError())}
            success={!!success}
          />
        }
      />
    </>
  )
}
