import {ModalOpenButton, Modal, ModalContentsBase} from './Modal'
import {CameraIcon, ExternalLinkIcon} from '@heroicons/react/outline'
import FormCameraAi from './forms/FormCameraAi'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {unAssignCard, unAssignCardClearError} from '../stores/cards'
import ErrorModal from './ErrorModal'
import AssignCard from '../assets/AssignCard.svg'
import AssignCardDisabled from '../assets/AssignCardDisabled.svg'

export default function IdentityCardBodyActions({
  identityCard,
  urlIdentity,
  data,
  identityUnassignCardPermission,
}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const unassignError = useSelector((state) => state.cards.unassign_card.error)

  return (
    <>
      <div className="flex flex-shrink flex-nowrap">
        <div className="mx-auto flex  justify-between">
          {identityCard.card.state.name === 'UNPRODUCED' && (
            <div className="m-2 flex flex-col items-center">
              <Modal>
                <ModalOpenButton>
                  <CameraIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
                </ModalOpenButton>
                <ModalContentsBase title={t('takeNewPhoto')}>
                  <FormCameraAi
                    identity={data}
                    isCardPhoto={true}
                    isEmployee={false}
                    isVisitor={false}
                    IdentityCardPhotoPostUrl={`${identityCard.url}/photos`}
                    IdentityCardPhotoPutUrl={`${identityCard.url}/photo`}
                  />
                </ModalContentsBase>
              </Modal>
              <p className="text-wrap invisible text-xs sm:visible">{t('takeNewPhoto')}</p>
            </div>
          )}
          {identityCard.card.profile.reassignable && identityUnassignCardPermission ? (
            <div className="m-2 flex flex-col items-center">
              <button
                text={t('cardInfo.UnAssign')}
                onClick={() => {
                  dispatch(unAssignCard(urlIdentity, data.id, identityCard.card.id))
                }}
              >
                <img
                  alt=""
                  src={AssignCard}
                  className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500"
                />
              </button>
              <p className="text-wrap invisible text-xs sm:visible">{t('cardInfo.UnAssign')}</p>
              {unassignError ? (
                <ErrorModal
                  error={unassignError}
                  setError={() => dispatch(unAssignCardClearError())}
                />
              ) : null}
            </div>
          ) : (
            <div className="m-2 flex flex-col items-center">
              <img
                alt=""
                src={AssignCardDisabled}
                className="mr-4 h-6 w-6 flex-shrink-0 cursor-not-allowed text-red-200"
              />
              <p className="text-wrap invisible text-xs sm:visible">{t('cardInfo.UnAssign')}</p>
            </div>
          )}
          <div className="m-2 flex flex-col items-center">
            <a href={`/cards/${identityCard.card.id}`}>
              <ExternalLinkIcon className="mr-4 h-6 w-6 flex-shrink-0 text-gray-500" />
            </a>
            <span className="invisible text-xs sm:visible">{t('cardInfo.GoToCard')}</span>
          </div>
        </div>
      </div>
    </>
  )
}
