import i18n from '../i18n'
import {isAuthRoute} from '../routes'

export function setLanguagePreference(preferred_language) {
  if (preferred_language !== null && preferred_language !== 'N/A') {
    localStorage.setItem('preferred_language', JSON.stringify(preferred_language))
    i18n.changeLanguage(preferred_language)
  }
}

export function setTimezonePreference(preferred_timezone) {
  if (preferred_timezone !== null && preferred_timezone !== 'N/A') {
    localStorage.setItem('preferred_timezone', JSON.stringify(preferred_timezone))
  }
}

export function clearTimezonePreference() {
  localStorage.setItem('preferred_timezone', null)
}

export function clearLanguagePreference() {
  localStorage.setItem('preferred_language', null)
}

export function getTimezonePreference() {
  // if preferred_timezone not set look for local timezone
  return (
    JSON.parse(localStorage.getItem('preferred_timezone')) ||
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )
}

export function setGDPRNoticePreference(enable_cookie) {
  if (enable_cookie) {
    localStorage.setItem('show_gpdr_notice', JSON.stringify(enable_cookie))
  }
}

export function setContactLinkPreference(contact) {
  if (contact) {
    localStorage.setItem('contact_link_preference', JSON.stringify(contact))
  }
}

export function setSupportLinkPreference(support) {
  if (support) {
    localStorage.setItem('support_link_preference', JSON.stringify(support))
  }
}

export function setServerPreferencesId(id) {
  if (id) {
    localStorage.setItem('server_preferences_id', JSON.stringify(id))
  }
}

export function getServerPreferencesId() {
  return JSON.parse(localStorage.getItem('server_preferences_id') || false)
}

export function getGDPRNoticePreference() {
  return JSON.parse(localStorage.getItem('show_gpdr_notice') || false)
}

export function getContactLinkPreference() {
  return JSON.parse(localStorage.getItem('contact_link_preference') || false)
}

export function getSupportLinkPreference() {
  return JSON.parse(localStorage.getItem('support_link_preference') || false)
}

export function acceptCookiePolicy(answer) {
  return localStorage.setItem('accept_cookie_policy', answer)
}

export function getCookiePolicyAcceptance() {
  return JSON.parse(localStorage.getItem('accept_cookie_policy') || false)
}

export function getPrevLocation() {
  return localStorage.getItem('prevLocation')
}

export function setPrevLocation(location) {
  if (isAuthRoute(location)) {
    const url = new URL(location)

    return localStorage.setItem('prevLocation', url.pathname)
  }
}
