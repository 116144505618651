import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import english from './translations/en.json'
import deutsch from './translations/de.json'
import franche from './translations/fr.json'

const resources = {
  en: {
    translation: english,
  },
  de: {
    translation: deutsch,
  },
  fr: {
    translation: franche,
  },
}

const DETECTION_OPTIONS = {
  order: ['navigator', 'localStorage'],
  caches: ['localStorage'],
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: 'de',
    keySeparator: '.',
    nsSeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
