import {useState} from 'react'
import {classNames} from '../utils/classNames'
import SpinnerSVG from '../assets/Spinner.svg'
import {useTranslation} from 'react-i18next'
import {getSrc} from '../utils/image'

export default function Logo(props) {
  const {t} = useTranslation()
  const [src, setSrc] = useState(null)
  const pending = src !== props.ImgSrc

  return (
    <>
      {pending ? (
        <div className="text-md flex flex-col items-center justify-center p-3">
          <img alt="o" src={SpinnerSVG} className="mr-3 h-20 w-20 animate-spin text-lime-500" />
          {t('loading')}
        </div>
      ) : null}
      <div className="flex max-w-lg flex-row justify-items-start ">
        <img
          className={classNames(pending ? 'hidden' : 'mx-auto h-20 w-20 object-contain p-3 lg:m-1')}
          src={getSrc(props.ImgSrc)}
          alt={props.alt}
          onLoad={() => setSrc(props.ImgSrc)}
          onError={() => setSrc(props.ImgSrc)}
        ></img>
      </div>
    </>
  )
}
