import * as React from 'react'
import {Tab} from '@headlessui/react'
import {classNames} from '../utils/classNames'

export default function Tabs({children, withSpace, ...props}) {
  return (
    <div className={classNames('w-full sm:px-0', withSpace && 'mt-2')}>
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-gray-500/20 p-1">
          {props.tabs.map((tab) => (
            <Tab
              key={tab.name}
              className={({selected}) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-gray-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-lime-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white shadow'
                    : 'text-white-100 hover:bg-white/[0.12] hover:text-blue-700',
                )
              }
            >
              {tab.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {props.tabs.map((tab, idx) => (
            <Tab.Panel key={idx}>{tab.component}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
