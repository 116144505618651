import {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Collapse from '../Collapse'
import {useDispatch, useSelector} from 'react-redux'
import {setTableConfig, updateTableConfigClean} from '../../stores/table'
import {RequestState} from '../../stores/types/tables'
import tablesConfig from '../table/configs'
import Notification from '../Notification'

interface EditTablesWrapperProps {
  tableId: string
}

const EditTablesWrapper: FC<EditTablesWrapperProps> = ({tableId}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  // @ts-ignore
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  // @ts-ignore
  useEffect(() => {
    return () => {
      dispatch(updateTableConfigClean({tableId}))
    }
  }, [tableId, dispatch])

  // @ts-ignore
  const tablesState = useSelector((state) => state.tables)

  if (!tableId || !permissions.updateAppPreferences) {
    return null
  }
  const configObj = tablesConfig[tableId]

  const options = configObj.config
    .map((colConfig) => ({
      label: t(colConfig.label),
      value: colConfig.accessor,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
  const {columns, hiddenColumns, update} = tablesState.config[tableId]

  const fields = [
    {
      component: InputComponent.SELECT,
      name: `columns`,
      label: t('userManagement.tableManagement.SelectColumns'),
      defaultValue: columns,
      isMulti: true,
      options,
      validationType: YupValidationType.ARRAY,
      validations: [
        {
          type: InputYupValidationsType.MIN,
          params: [1, t('yupError.AtLeastOneRequired')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `hiddenColls`,
      withRhfValidation: true,
      label: t('userManagement.tableManagement.SelectHiddenColumns'),
      defaultValue: hiddenColumns,
      isMulti: true,
      options,
      validationType: YupValidationType.ARRAY,
      validations: [
        {
          type: InputYupValidationsType.TEST,
          params: [
            `is-in-coll`,
            t('userManagement.tableManagement.validationErrorHiddenColumns'),
            (hiddenColValue, ctx) => {
              return !hiddenColValue.some((accessor) => !ctx.parent.columns.includes(accessor))
            },
          ],
        },
      ],
    },
  ]

  return (
    <Collapse
      key={tableId}
      title={t('userManagement.Tables')}
      formExpanded={false}
      onToggle={() => dispatch(updateTableConfigClean({tableId}))}
    >
      <DynamicRhfForm
        formConfig={{
          fields,
          name: tableId,
          submitButtonText: t('userManagement.tableManagement.Submit'),
        }}
        onSubmit={(values) => {
          dispatch(
            setTableConfig({
              id: tablesState.id,
              tableId,
              hiddenColumns: values.hiddenColls,
              columns: values.columns,
            }),
          )
        }}
        noFormBorder
        noFormReset
        isSubmitDisabled={update === RequestState.PENDING}
        infoMessage={
          <Notification
            error={update === RequestState.ERROR}
            setError={() => dispatch(updateTableConfigClean({tableId}))}
            success={update === RequestState.SUCCESS}
          />
        }
      />
    </Collapse>
  )
}

export default EditTablesWrapper
