import {useMemo} from 'react'
import {useTable, useGlobalFilter, useFilters, useSortBy, usePagination} from 'react-table'
import {Button, PageButton} from '../Buttons'
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid'
import {useTranslation} from 'react-i18next'
import ErrorModal from '../ErrorModal'
import {useDispatch} from 'react-redux'
import TablePaginationCount from './TablePaginationCount'
import {GlobalFilter, Skeleton} from './TableComponents'
import ExportButtons from './ExportButtons'
import {useLocation} from 'react-router-dom'
import TableView from './TableView'

export default function Table({columns, data, pending, error, setError, rowUrlAccessor, url}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const {pathname} = useLocation()
  const rowBaseUrl = useMemo(() => {
    const base = url || pathname
    return base.endsWith('/') ? base : base + '/'
  }, [url, pathname])
  // Use the state and functions returned from useTable to build your UI

  const tableColumns = useMemo(
    () =>
      pending
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton />,
          }))
        : columns.map((column) => ({
            ...column,
            withClick: (!column.Cell || column.cell === 'DateCell') && !!rowUrlAccessor,
          })),
    [pending, columns, rowUrlAccessor],
  )

  const {
    getTableProps, // required
    getTableBodyProps, // required
    headerGroups, // required
    prepareRow, // required
    // rows, // required

    //when you want to use pagination you don't need rows any more. page containst the rows in a page
    page, // Instead of using 'rows', we'll use page,// which has only the rows for the active page
    rows,
    // other pagination props.
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    //
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns: tableColumns,
      data,
    },
    useFilters, //  adds the category based filter [ order of these matters for performance ]
    useGlobalFilter, // adds the global search
    useSortBy, // adds asc, desc sorting
    usePagination, // adds pagination functionality
  )
  // Render the UI for your table
  return (
    <>
      {error ? (
        <ErrorModal title="Error" error={error} setError={() => dispatch(setError())} />
      ) : null}
      <div className="mt-4">
        <div className="flex gap-x-2">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <ExportButtons headerGroups={headerGroups} rows={rows} page={page} />
          {/* SelectColumn Filter */}
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map((column) =>
              column.Filter ? (
                <div key={column.id}>
                  {/* <label htmlFor={column.id}>{column.render('Header')}: </label> */}
                  {column.render('Filter')}
                </div>
              ) : null,
            ),
          )}
        </div>
        <div className="mt-2 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <TableView
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  page={page}
                  headerGroups={headerGroups}
                  prepareRow={prepareRow}
                  rowBaseUrl={rowBaseUrl}
                  rowUrlAccessor={rowUrlAccessor}
                  pending={pending}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Pagination Part */}
        <div className="flex items-center justify-between py-3">
          <div className="flex flex-1 justify-between sm:hidden">
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              text={t('previous')}
            />
            <Button onClick={() => nextPage()} disabled={!canNextPage} text={t('next')} />
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <TablePaginationCount
              length={pageOptions.length}
              pageSize={state.pageSize}
              pageIndex={state.pageIndex}
              onPageSizeSelect={setPageSize}
              sizeOptions={[5, 10, 20, data.length > 20 ? data.length : 0]}
            />
            <div>
              <nav
                className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <PageButton
                  className="rounded-l-md"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
                <PageButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
                <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
                <PageButton
                  className="rounded-r-md"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                </PageButton>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
