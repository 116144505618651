import {useRef} from 'react'
import {Formik, Form} from 'formik'
import {SelectInput, TextInputLabelOnLeft, SelectInputLabelOnLeft} from '../FormInputs'
import {useTranslation} from 'react-i18next'

export default function FormEditUserSettings({data, userPrinterList}) {
  const {t} = useTranslation()
  const initialValues = {
    active: data?.active ? t('userManagement.userActive') : t('userManagement.userNotActive') || '',
    name: data?.name || '',
    email: data?.email || '',
    printer_list: userPrinterList?.name
      ? userPrinterList.name
      : t('userInfo.NoAssignedPrinterToUser') || '',
    roles: data?.roles,
    preferred_timezone:
      data?.preferred_timezone === 'N/A'
        ? t('userInfo.CanNotSetTimezone')
        : data?.preferred_timezone
        ? data?.preferred_timezone
        : t('userInfo.NoTimezoneSelected') || '',
    preferred_language: data?.preferred_language || '',
  }

  const options = [
    {label: 'English', value: 'en'},
    {label: 'Deutsch', value: 'de'},
    {label: 'Francaise', value: 'fr'},
  ]

  let rolesSelectRef = useRef()
  let languageSelectRef = useRef()
  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={(values) => {}}
      >
        <Form>
          <TextInputLabelOnLeft
            disabled={true}
            label={t('userInfo.Status')}
            type="text"
            name="active"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('userInfo.Name')}
            type="text"
            name="name"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('userInfo.Email')}
            type="text"
            name="email"
          />
          {data?.preferred_language === 'N/A' ? (
            <TextInputLabelOnLeft
              disabled={true}
              label={t('userInfo.PreferredLanguage')}
              value={t('userInfo.CanNotSetLanguage')}
              type="text"
              name="preferred_language"
            />
          ) : (
            <SelectInputLabelOnLeft
              label={t('userInfo.PreferredLanguage')}
              options={options}
              isDisabled={true}
              placeholder={t('userInfo.NoLanguageSelected')}
              name="preferred_language"
              innerRef={languageSelectRef}
              error={false}
              setError={() => {}}
            />
          )}
          <TextInputLabelOnLeft
            disabled={true}
            label={t('userInfo.PreferredTimezone')}
            type="text"
            name="preferred_timezone"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('userInfo.Printer')}
            type="text"
            name="printer_list"
          />
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:p-5">
            <label
              htmlFor="select"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {t('userInfo.Roles')}
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <SelectInput
                isDisabled={true}
                label={''}
                options={data.roles.map((role) => ({
                  value: role,
                  label: role,
                }))}
                isMulti
                name="roles"
                innerRef={rolesSelectRef}
              />
            </div>
          </div>
        </Form>
      </Formik>
    </>
  )
}
