import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {multiSelectAction} from '../stores/multi_select'
import {
  AnnotationIcon,
  PrinterIcon,
  LockClosedIcon,
  LockOpenIcon,
  BanIcon,
} from '@heroicons/react/outline'
import {classNames} from '../utils/classNames'
import ProgressBar from './ProgressBar'

export default function CardFooterActions({selected}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  //const apply_action_card_pending = useSelector((state) => state.cards.apply_action_card.pending)

  const multi_select_action_pending = useSelector(
    (state) => state.multi_select.multi_select_action.pending,
  )

  const apply_action_cards_pending = useSelector(
    (state) => state.multi_select.apply_action_cards.pending,
  )

  const update_loading_bar_multi_select = useSelector(
    (state) => state.loading_bar.update_loading_bar_multi_select.success,
  )

  const selected_printer = useSelector((state) => state.printers.get_selected_printer.success)

  const arrayOfTranslations = [
    t('multiActionReport.Name'),
    t('multiActionReport.CardNumber'),
    t('multiActionReport.Result'),
    t('multiActionReport.CardIsActiveAlready'),
    t('multiActionReport.CardIsProducedAlready'),
    t('multiActionReport.CardIsDestroyAlready'),
    t('multiActionReport.CardIsBlockedAlready'),
    t('multiActionReport.CardIsNotBlocked'),
    t('multiActionReport.ProduceCard'),
    t('multiActionReport.BlockCard'),
    t('multiActionReport.UnblockCard'),
    t('multiActionReport.DestroyCard'),
    t('multiActionReport.CardIsNotActive'),
    t('multiActionReport.CardIsNotInProperStateForProduction'),
    t('multiActionReport.CardIsAlreadyRequested'),
  ]

  const functionalities = [
    {
      functionName:
        selected_printer === null
          ? t('employeeFunctionalitySidebar.SelectPrinter')
          : //   : card?.state?.name === 'PRODUCTION_FAILED'
            //   ? t('employeeFunctionalitySidebar.RetryProduction')
            t('employeeFunctionalitySidebar.Produce'),
      icon:
        selected_printer === null
          ? AnnotationIcon
          : //   : card?.state?.name === 'PRODUCTION_FAILED'
            //   ? ClockIcon
            PrinterIcon,
      functionBody: function () {
        dispatch(
          multiSelectAction(
            selected,
            {action: 'multi_produce'},
            document,
            arrayOfTranslations,
            userID,
          ),
        )
      },
      disabled:
        multi_select_action_pending || selected_printer === null || !permissions.produceCard,
    },
    // {
    //   functionName: t('employeeFunctionalitySidebar.Validate4eye'),
    //   icon: 'n-4-square',
    //   functionBody: function () {
    //     props.applyActionOnCard(
    //       card.url,
    //       {action: 'four_eyes_validate'},
    //       true,
    //       userID,
    //     )
    //   },
    //   disabled:
    //     card?.state?.name !== 'FOUR_EYES_VALIDATION_REQUIRED' ||
    //     card?.state?.name === 'PRODUCED' ||
    //     card?.state?.name === 'REQUESTED' ||
    //     apply_action_card_pending, // should be a permission NOT USED
    // },
    {
      functionName: t('employeeFunctionalitySidebar.BlockTemporary'),
      icon: LockClosedIcon,
      functionBody: function () {
        dispatch(
          multiSelectAction(selected, {action: 'block'}, document, arrayOfTranslations, userID),
        )
      },

      disabled: multi_select_action_pending || !permissions.updateCard,
    },
    {
      functionName: t('employeeFunctionalitySidebar.RevokeTempBlock'),
      icon: LockOpenIcon,
      functionBody: function () {
        dispatch(
          multiSelectAction(selected, {action: 'unblock'}, document, arrayOfTranslations, userID),
        )
      },
      disabled: multi_select_action_pending || !permissions.updateCard,
    },
    {
      functionName: t('employeeFunctionalitySidebar.BlockPermanently'),
      icon: BanIcon,
      functionBody: function () {
        dispatch(
          multiSelectAction(selected, {action: 'destroy'}, document, arrayOfTranslations, userID),
        )
      },
      disabled: multi_select_action_pending || !permissions.deleteCard,
    },
  ]
  return (
    <>
      <div className="flex flex-shrink flex-nowrap">
        <div className="-mx-4 flex  justify-between sm:mx-auto">
          {functionalities.map((item) => (
            <div key={item.functionName}>
              <button
                onClick={() => {
                  item.functionBody()
                }}
                disabled={item.disabled}
              >
                <div className="m-2 flex flex-col items-center">
                  <item.icon
                    className={classNames(
                      item.disabled
                        ? 'cursor-not-allowed text-red-200'
                        : 'cursor-pointer text-gray-500',
                      ' m-4 h-6 w-6 flex-shrink-0',
                    )}
                  />
                  <p className="text-wrap invisible text-xs sm:visible">{item.functionName}</p>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
      {apply_action_cards_pending && <ProgressBar progress={update_loading_bar_multi_select} />}
    </>
  )
}
