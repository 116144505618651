import axios from 'axios'
import axiosRetry from 'axios-retry'
import {BASE_URL} from '../utils/consts'

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: BASE_URL || '/',
})

axiosRetry(instance, {
  // retryDelay: axiosRetry.exponentialDelay,
  retryDelay: (retryCount) => {
    return retryCount * 1000
  },
  retries: 3,
})
export default instance
