// Define a default UI for filtering
import {useTranslation} from 'react-i18next'
import {forwardRef, useEffect, useMemo, useRef, useState} from 'react'
import {useAsyncDebounce} from 'react-table'
import {Modal, ModalContentsBase, ModalOpenButton} from '../Modal'
import {MinusCircleIcon, TrashIcon} from '@heroicons/react/outline'
import DeletionWarning from '../DeletionWarning'

export function GlobalFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter}) {
  const {t} = useTranslation()
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <label className="flex items-baseline gap-x-2">
      <span className="text-gray-700">{t('search')}: </span>
      <input
        type="text"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`${count} ${t('records')}...`}
      />
    </label>
  )
}

export function SelectColumnFilter({
  column: {filterValue, setFilter, preFilteredRows, id, render},
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const {t} = useTranslation()
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <label className="flex items-baseline gap-x-2">
      <span className="text-gray-700">{render('Header')}: </span>
      <select
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">{t('all')}</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option.toString()}
          </option>
        ))}
      </select>
    </label>
  )
}

export function ActionCell({
  value,
  deleteFunc,
  error,
  pending,
  setError,
  message,
  permission,
  success,
  setSuccess,
}) {
  const {t} = useTranslation()
  return (
    <>
      {permission && (
        <Modal>
          <ModalOpenButton>
            <div className="h-5 w-5">
              <TrashIcon className="text-red-400 hover:text-red-500" />
            </div>
          </ModalOpenButton>
          <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
            <DeletionWarning
              deleteFunction={deleteFunc}
              id={value}
              error={error}
              pending={pending}
              setError={setError}
              text={t('confirmDeletion.Confirm')}
              message={message}
              success={success}
              setSuccess={setSuccess}
            />
          </ModalContentsBase>
        </Modal>
      )}
    </>
  )
}

export function SortIcon({className}) {
  return (
    <div className="">
      <svg
        className={className}
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 320 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path>
      </svg>
    </div>
  )
}

export function SortUpIcon({className}) {
  return (
    <div className="">
      <svg
        className={className}
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 320 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path>
      </svg>
    </div>
  )
}

export function SortDownIcon({className}) {
  return (
    <div className="">
      <svg
        className={className}
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 320 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path>
      </svg>
    </div>
  )
}

export const NoData = () => {
  const {t} = useTranslation()
  return (
    <tr>
      <th colSpan="3" className=" p-5">
        <div className="mx-auto flex items-center justify-center p-5">
          <MinusCircleIcon className="h-8 w-8 items-center text-red-600" aria-hidden="true" />
          <p className=" pl-2 text-xl text-gray-500">{t('NoData')}</p>
        </div>
      </th>
    </tr>
  )
}

export const Skeleton = () => {
  return (
    <div className="animate-pulse px-3 py-2">
      <div className="h-2 rounded bg-gray-200"></div>
    </div>
  )
}

export const IndeterminateCheckbox = forwardRef(({indeterminate, ...rest}, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <input
      className="h-4 w-4 rounded border-gray-300 text-lime-600 accent-lime-500 focus:ring-lime-500"
      type="checkbox"
      ref={resolvedRef}
      {...rest}
    />
  )
})
