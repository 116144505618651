import {useTranslation} from 'react-i18next'
import IDCardExchange from '../assets/IDCardExchange.svg'
import IDCardPlus from '../assets/IDCardPlus.svg'
import {ModalOpenButton, Modal, ModalContentsBase} from './Modal'
import FormAssignCardPersonalized from './forms/FormAssignCardPersonalized'
import FormAssignCardNonPersonalized from './forms/FormAssignCardNonPersonalized'
import {useSelector} from 'react-redux'
import {CardStateTitle, CardStateIcon} from '../utils/card'

export default function IdentityCardHeaderActions({
  identityCard,
  identity,
  identityAssignCardPermission,
}) {
  const apply_action_card_pending = useSelector((state) => state.cards.apply_action_card.pending)

  const {t} = useTranslation()
  return (
    <>
      <div className="mx-auto flex flex-shrink  justify-between rounded-xl sm:border-2 sm:shadow-sm">
        {identityCard ? (
          <div className="m-2 flex flex-col items-center">
            {CardStateIcon(
              apply_action_card_pending ? apply_action_card_pending : identityCard.card.state.name,
            )}
            <p className="text-wrap invisible text-xs sm:visible">
              {CardStateTitle(
                apply_action_card_pending
                  ? apply_action_card_pending
                  : identityCard.card.state.name,
              )}
            </p>
          </div>
        ) : (
          <div className="m-2 flex flex-col items-center">
            <p className="text-wrap invisible text-xs sm:visible">
              {t('assignCard.NoCardAssigned')}
            </p>
          </div>
        )}
        {identityAssignCardPermission && (
          <div className="m-2 flex">
            <div>
              <Modal>
                <ModalOpenButton>
                  <img
                    alt="Assign Personalized Card"
                    src={IDCardPlus}
                    className="mr-3 h-10 w-10 cursor-pointer"
                  />
                </ModalOpenButton>
                <ModalContentsBase title={t('assignCard.assignPersonalized')}>
                  <FormAssignCardPersonalized identity={identity} />
                </ModalContentsBase>
              </Modal>
            </div>
            <div>
              <Modal>
                <ModalOpenButton>
                  <img
                    alt="Assign Card"
                    src={IDCardExchange}
                    className="mr-3 h-10 w-10 cursor-pointer"
                  />
                </ModalOpenButton>
                <ModalContentsBase title={t('assignCard.assignNonPersonalized')}>
                  <FormAssignCardNonPersonalized identity={identity} />
                </ModalContentsBase>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
