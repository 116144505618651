import {
  InputRhfValidation,
  InputRhfValidationsType,
  InputYupValidation,
  InputYupValidationsType,
  YupValidationType,
} from '../types'

export const isFieldRequired = (
  validations?: InputYupValidation[],
  validationType?: YupValidationType,
) => {
  if (validationType === YupValidationType.ARRAY) {
    return validations?.some((validation) => validation.type === InputYupValidationsType.MIN)
  }
  return validations?.some((validation) => validation.type === InputYupValidationsType.REQUIRED)
}

const FIELD_ARRAY_VALIDATIONS = 'validations'
// this only work with the particular naming logic implemented for the current inputsSettingsConfig
// for general case this must be improved
const getName = (str1, str2) => {
  const segments1 = str1.split('.')
  const segments2 = str2.split('.')
  const resultSegments = segments2.slice(0, -1).concat(segments1.slice(-1))
  return resultSegments.join('.')
}

const getName2 = (depName, currentFieldName) => {
  const segments1 = currentFieldName.split('.')
  const index = segments1.indexOf(FIELD_ARRAY_VALIDATIONS)
  const resultSegments = segments1.slice(0, index).concat(depName)
  return resultSegments.join('.')
}

export const getRhfValidations = (getValues, name, validations?: InputRhfValidation[]) => {
  if (validations) {
    return validations.reduce((acc, cur) => {
      const rhfStandardValidationTypes = ['maxLength', 'minLength', 'max', 'min']
      if (cur.type === InputRhfValidationsType.REQUIRED) {
        acc.required = cur.params[0]
      }
      if (rhfStandardValidationTypes.includes(cur.type)) {
        acc[cur.type] = {
          value: cur.params[0],
          message: cur.params[1],
        }
      }

      if (cur.type === InputRhfValidationsType.REQUIRED_IF_ANOTHER_VALUE) {
        const depFieldNameFull = getName(cur.params[0], name)
        const depValue = getValueFromNestedObjByName(depFieldNameFull, getValues())
        acc[InputRhfValidationsType.VALIDATE] = {
          requiredIfAnotherValue: (value) => {
            if (depValue) {
              return !!value || cur.params[1]
            }
            return true
          },
        }
      }

      if (cur.type === InputRhfValidationsType.NOT_EQUAL_TO_FIELD_VALUE) {
        const depFieldNameFull = getName2(cur.params[0], name)
        const depValue = getValueFromNestedObjByName(depFieldNameFull, getValues())
        const message = cur.params[1]
        acc[InputRhfValidationsType.VALIDATE] = {
          notEqualToFieldValue: (value) => {
            if (depValue) {
              return depValue !== value || message
            }
            return message
          },
        }
      }

      return acc
    }, {} as any)
  }

  return {}
}

export const getValueFromNestedObjByName = (name, errors) => {
  return name?.split('.').reduce((xs, x) => (xs && xs[x] ? xs[x] : null), errors)
}
