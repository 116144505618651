import {createStore, applyMiddleware, compose} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import thunk from 'redux-thunk'
import rootReducer from './reducer'
import {IS_DEV, REDUX_LOGGER} from './utils/consts'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'static_assets', 'tables'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function createAndPersistStore() {
  // https://github.com/reduxjs/redux-devtools
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
        })
      : compose

  let middleware = [thunk]

  if (IS_DEV && REDUX_LOGGER) {
    const {logger} = require('redux-logger')
    middleware = [...middleware, logger]
  }
  const enhancer = composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
  )
  let store = createStore(persistedReducer, enhancer)
  let persistor = persistStore(store)
  return {store, persistor}
}
