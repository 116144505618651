import {Formik, Form} from 'formik'
import {AsyncSelectInput2} from '../FormInputs' // TextInput, Checkbox,
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {putWorkflow, putWorkflowClearError} from '../../stores/workflows'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {useRef} from 'react'

export default function FormEditWebhook({id}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const pending = useSelector((state) => state.workflows.put_workflow.pending)
  const error = useSelector((state) => state.workflows.put_workflow.error)

  const initialValues = {
    form_id: '',
  }

  const validationSchema = Yup.object({
    // ADD validations
  })

  //TODO: not finished cleaning

  const handleSubmit = (values, actions) => {
    dispatch(putWorkflow(id, values))
    actions.resetForm()
  }

  let formSelectRef = useRef()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        <Form>
          <AsyncSelectInput2
            url={`/api/v1/forms?q=`}
            label="Forms"
            placeholder={t('workflows.SelectForm')}
            optionLabel="name"
            optionValue="id"
            name="form_id"
            innerRef={formSelectRef}
            required
          />
          <FormikSaveButton
            type="submit"
            testid="form-edit-webhook-submit"
            text={t('workflows.Submit')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(putWorkflowClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
