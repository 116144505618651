import {Formik, Form} from 'formik'
import {useState} from 'react'
import {TextInputLabelOnLeft, TextInputCustomAttribute} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {PencilAltIcon} from '@heroicons/react/solid'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'
import {
  createCardCustomAttribute,
  deleteCardCustomAttribute,
  updateCardInfo,
  updateCardInfoClearError,
} from '../../stores/cards'
import {isEmpty} from '../../utils/custom_attributes'
import {parseWithTimeZone, formatInPreferredTimezone} from '../../utils/datetime'

export default function FormEditCard({
  data,
  updateCardPermission,
  cardUpdateCustomAttribePermission,
}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const [editable, setEditable] = useState(true)

  const pending = useSelector((state) => state.cards.update_cardInfo.pending)
  const error = useSelector((state) => state.cards.update_cardInfo.error)

  const initialValues = {
    id: data?.id || '',
    badgenr: data?.badgenr || '',
    profile_name: data?.profile.name || '',
    profile_type: data?.profile.profile_type || '',
    layout_profile: data?.profile.layout_profile.name || '',
    valid_from: formatInPreferredTimezone(data?.valid_from) || '',
    valid_to: formatInPreferredTimezone(data?.valid_to) || '',
    is_active: data?.is_active || '',
    is_produced: data?.is_produced || '',
    is_blocked: data?.is_blocked || '',
    is_destroyed: data?.is_destroyed || '',
    production_state: data?.profile.production_state.name || '',
    card_state: data?.state.name || '',
  }
  const renderCustomAttributes = isEmpty(data.custom_attributes)
    ? []
    : Object.keys(data.custom_attributes).map((key) => {
        return (
          <div key={key.replace('.', '_d0t_')}>
            <TextInputCustomAttribute
              disabled={editable}
              label={key}
              type="text"
              defaultValue={data.custom_attributes[key]}
              name={key.replace('.', '_d0t_')}
              id={data.id}
              customAttributeKey={key}
              permission={cardUpdateCustomAttribePermission}
              deleteCustomAttribute={deleteCardCustomAttribute}
            />
          </div>
        )
      })

  return (
    <>
      {updateCardPermission && (
        <div className="m-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <PencilAltIcon className="h-6 w-6 text-lime-500" onClick={() => setEditable(!editable)} />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          let updateValues = {
            valid_to: parseWithTimeZone(values.valid_to),
            valid_from: parseWithTimeZone(values.valid_from),
          }
          const customAttributes = Object.assign(data.custom_attributes)
          let customAttributesTemp = {}
          for (const [key, value] of Object.entries(customAttributes)) {
            let temp_key = key.replace('.', '_d0t_')
            if (value !== values[temp_key]) {
              customAttributesTemp[key] = values[temp_key]
            }
          }
          dispatch(updateCardInfo(data.id, updateValues))
          const isCustomAttributesEmpty = isEmpty(customAttributesTemp)
          if (!isCustomAttributesEmpty) {
            // if there is no change in custom_attributes don't dispatch
            dispatch(
              createCardCustomAttribute(data.id, {
                // needs to be changed
                custom_attributes: [customAttributesTemp],
              }),
            )
          }
          actions.resetForm()
          setEditable(!editable)
        }}
      >
        <Form>
          <TextInputLabelOnLeft disabled={true} label={t('cardInfo.ID')} type="text" name="id" />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.Badgenr')}
            type="text"
            name="badgenr"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.Profile')}
            type="text"
            name="profile_name"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.ProfileType')}
            type="text"
            name="profile_type"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.LayoutProfile')}
            type="email"
            name="layout_profile"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('cardInfo.ValidFrom')}
            type="text"
            name="valid_from"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('cardInfo.ValidTo')}
            type="text"
            name="valid_to"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.IsActive')}
            type="text"
            name="is_active"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.IsProduced')}
            type="text"
            name="is_produced"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.IsBlocked')}
            type="text"
            name="is_blocked"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.IsDestroyed')}
            type="text"
            name="is_destroyed"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.ProductionState')}
            type="text"
            name="production_state"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('cardInfo.CardState')}
            type="text"
            name="card_state"
          />
          {renderCustomAttributes}
          {updateCardPermission && (
            <FormikSaveButton
              type="submit"
              testid="form-edit-card-submit"
              text={t('addPersonForm.Save')}
              primary
              pending={pending}
              error={error}
              disabled={editable}
              setError={() => dispatch(updateCardInfoClearError())}
            />
          )}
        </Form>
      </Formik>
    </>
  )
}
