import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import axios from '../../services/api'
import {InputComponent, InputYupValidationsType} from '../formRendererRHF/types'

const FormAddToExistingIdentity = ({selected}) => {
  const {t} = useTranslation()
  const [pending, setPending] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const handleSubmit = async (values) => {
    setPending(true)
    setError(null)
    setSuccess(false)
    try {
      await axios.put(`/api/v1/identities/${values.id}`, {
        additional_children_identities: selected.map((i) => i.id),
      })

      setSuccess(true)
    } catch (error) {
      setError(error)
    }
    setPending(false)
  }

  const fields = [
    {
      component: InputComponent.ASYNCREACTSELECT,
      name: 'id',
      label: 'identity.ChooseIdentityGroup',
      url: "/api/v1/identities?filters=is_group eq '1'",
      nestedOptions: false,
      optionValue: 'id',
      optionLabel: 'owner_name',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
  ]

  return (
    <DynamicRhfForm
      formConfig={{
        fields,
        name: 'form-update-identity',
        submitButtonText: t('formCommon.Add'),
      }}
      onSubmit={handleSubmit}
      pending={pending}
      noFormBorder
      infoMessage={
        <Notification
          error={error}
          setError={() => {
            setError(null)
            setSuccess(false)
          }}
          success={!!success}
          successMessageKey="identity.UpdateSuccess"
        />
      }
    />
  )
}

export default FormAddToExistingIdentity
