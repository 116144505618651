import {Formik, Form} from 'formik'
import {TextInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {
  createAccessProfileType,
  createAccessProfileTypeClearError,
} from '../../stores/access_profiles'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'

export default function FormAddResourceType() {
  const dispatch = useDispatch()
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const pending = useSelector((state) => state.access_profiles.create_access_profile_type.pending)
  const error = useSelector((state) => state.access_profiles.create_access_profile_type.error)
  const {t} = useTranslation()

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '256'})) //?? gave it 256
      .required(t('yupError.ThisFieldIsRequired', {field: t('userManagement.Name')})),
  })
  return (
    <>
      <Formik
        initialValues={{name: ''}}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          dispatch(createAccessProfileType(values, userID))
          actions.resetForm()
        }}
      >
        <Form>
          <TextInput label={t('userManagement.Name')} name="name" type="text" placeholder="" />
          <FormikSaveButton
            type="submit"
            testid="form-add-resource-type-submit"
            text={t('addPersonForm.Save')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(createAccessProfileTypeClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
