import axios from '../services/api'
import {createNotification, createNotificationOnClient} from './notifications'
import {createPreInitCardsReport} from './reports'
import I18n from '../i18n'

// Types
const UPDATE_CARDINFO_PENDING = 'idfunctiondb/cards/UPDATE_CARDINFO_PENDING'
const UPDATE_CARDINFO_SUCCESS = 'idfunctiondb/cards/UPDATE_CARDINFO_SUCCESS'
const UPDATE_CARDINFO_ERROR = 'idfunctiondb/cards/UPDATE_CARDINFO_ERROR'
const UPDATE_CARDINFO_CLEAR_ERROR = 'idfunctiondb/cards/UPDATE_CARDINFO_CLEAR_ERROR'

const GET_CARDS_TOTAL_PENDING = 'idfunctiondb/cards/GET_CARDS_TOTAL_PENDING'
const GET_CARDS_TOTAL_SUCCESS = 'idfunctiondb/cards/GET_CARDS_TOTAL_SUCCESS'
const GET_CARDS_TOTAL_ERROR = 'idfunctiondb/cards/GET_CARDS_TOTAL_ERROR'

const GET_CARDS_HISTORY_PENDING = 'idfunctiondb/cards/GET_CARDS_HISTORY_PENDING'
const GET_CARDS_HISTORY_SUCCESS = 'idfunctiondb/cards/GET_CARDS_HISTORY_SUCCESS'
const GET_CARDS_HISTORY_ERROR = 'idfunctiondb/cards/GET_CARDS_HISTORY_ERROR'
const GET_CARDS_HISTORY_CLEAR_ERROR = 'idfunctiondb/cards/GET_CARDS_HISTORY_CLEAR_ERROR'

const GET_EMPLOYEES_CARDS_PENDING = 'idfunctiondb/cards/GET_EMPLOYEES_CARDS_PENDING'
const GET_EMPLOYEES_CARDS_SUCCESS = 'idfunctiondb/cards/GET_EMPLOYEES_CARDS_SUCCESS'
const GET_EMPLOYEES_CARDS_ERROR = 'idfunctiondb/cards/GET_EMPLOYEES_CARDS_ERROR'

const GET_VISITORS_CARDS_PENDING = 'idfunctiondb/cards/GET_VISITORS_CARDS_PENDING'
const GET_VISITORS_CARDS_SUCCESS = 'idfunctiondb/cards/GET_VISITORS_CARDS_SUCCESS'
const GET_VISITORS_CARDS_ERROR = 'idfunctiondb/cards/GET_VISITORS_CARDS_ERROR'

const GET_CARD_PENDING = 'idfunctiondb/cards/GET_CARD_PENDING'
const GET_CARD_SUCCESS = 'idfunctiondb/cards/GET_CARD_SUCCESS'
const GET_CARD_ERROR = 'idfunctiondb/cards/GET_CARD_ERROR'
const GET_CARD_CLEAR_ERROR = 'idfunctiondb/cards/GET_CARD_CLEAR_ERROR'

const GET_CARD_META_PENDING = 'idfunctiondb/cards/GET_CARD_META_PENDING'
const GET_CARD_META_SUCCESS = 'idfunctiondb/cards/GET_CARD_META_SUCCESS'
const GET_CARD_META_ERROR = 'idfunctiondb/cards/GET_CARD_META_ERROR'

const GET_CARD_PROFILES_PENDING = 'idfunctiondb/cards/GET_CARD_PROFILES_PENDING'
const GET_CARD_PROFILES_SUCCESS = 'idfunctiondb/cards/GET_CARD_PROFILES_SUCCESS'
const GET_CARD_PROFILES_ERROR = 'idfunctiondb/cards/GET_CARD_PROFILES_ERROR'
const GET_CARD_PROFILES_CLEAR_ERROR = 'idfunctiondb/cards/GET_CARD_PROFILES_CLEAR_ERROR'

const GET_CARD_STATES_PENDING = 'idfunctiondb/cards/GET_CARD_STATES_PENDING'
const GET_CARD_STATES_SUCCESS = 'idfunctiondb/cards/GET_CARD_STATES_SUCCESS'
const GET_CARD_STATES_ERROR = 'idfunctiondb/cards/GET_CARD_STATES_ERROR'
const GET_CARD_STATES_CLEAR_ERROR = 'idfunctiondb/cards/GET_CARD_STATES_CLEAR_ERROR'

const CREATE_CARD_PENDING = 'idfunctiondb/cards/CREATE_CARD_PENDING'
const CREATE_CARD_SUCCESS = 'idfunctiondb/cards/CREATE_CARD_SUCCESS'
const CREATE_CARD_ERROR = 'idfunctiondb/cards/CREATE_CARD_ERROR'

const BULK_CREATE_PRE_INIT_CARDS_PENDING = 'idfunctiondb/cards/BULK_CREATE_PRE_INIT_CARDS_PENDING'
const BULK_CREATE_PRE_INIT_CARDS_SUCCESS = 'idfunctiondb/cards/BULK_CREATE_PRE_INIT_CARDS_SUCCESS'
const BULK_CREATE_PRE_INIT_CARDS_ERROR = 'idfunctiondb/cards/BULK_CREATE_PRE_INIT_CARDS_ERROR'
const BULK_CREATE_PRE_INIT_CARDS_CLEAR_ERROR =
  'idfunctiondb/cards/BULK_CREATE_PRE_INIT_CARDS_CLEAR_ERROR'

const BULK_CREATE_NON_PERSONALIZED_CARDS_PENDING =
  'idfunctiondb/cards/BULK_CREATE_NON_PERSONALIZED_CARDS_PENDING'
const BULK_CREATE_NON_PERSONALIZED_CARDS_SUCCESS =
  'idfunctiondb/cards/BULK_CREATE_NON_PERSONALIZED_CARDS_SUCCESS'
const BULK_CREATE_NON_PERSONALIZED_CARDS_ERROR =
  'idfunctiondb/cards/BULK_CREATE_NON_PERSONALIZED_CARDS_ERROR'
const BULK_CREATE_NON_PERSONALIZED_CARDS_CLEAR_ERROR =
  'idfunctiondb/cards/BULK_CREATE_NON_PERSONALIZED_CARDS_CLEAR_ERROR'

const BULK_CREATE_PERSONALIZED_CARDS_PENDING =
  'idfunctiondb/cards/BULK_CREATE_PERSONALIZED_CARDS_PENDING'
const BULK_CREATE_PERSONALIZED_CARDS_SUCCESS =
  'idfunctiondb/cards/BULK_CREATE_PERSONALIZED_CARDS_SUCCESS'
const BULK_CREATE_PERSONALIZED_CARDS_ERROR =
  'idfunctiondb/cards/BULK_CREATE_PERSONALIZED_CARDS_ERROR'
const BULK_CREATE_PERSONALIZED_CARDS_CLEAR_ERROR =
  'idfunctiondb/cards/BULK_CREATE_PERSONALIZED_CARDS_CLEAR_ERROR'

const ASSIGN_CARD_PENDING = 'idfunctiondb/cards/ASSIGN_CARD_PENDING'
const ASSIGN_CARD_SUCCESS = 'idfunctiondb/cards/ASSIGN_CARD_SUCCESS'
const ASSIGN_CARD_ERROR = 'idfunctiondb/cards/ASSIGN_CARD_ERROR'
const ASSIGN_CARD_CLEAR_ERROR = 'idfunctiondb/cards/ASSIGN_CARD_CLEAR_ERROR'

const UNASSIGN_CARD_PENDING = 'idfunctiondb/cards/UNASSIGN_CARD_PENDING'
const UNASSIGN_CARD_SUCCESS = 'idfunctiondb/cards/UNASSIGN_CARD_SUCCESS'
const UNASSIGN_CARD_ERROR = 'idfunctiondb/cards/UNASSIGN_CARD_ERROR'
const UNASSIGN_CARD_CLEAR_ERROR = 'idfunctiondb/cards/UNASSIGN_CARD_CLEAR_ERROR'

const APPLY_ACTION_CARD_PENDING = 'idfunctiondb/cards/APPLY_ACTION_CARD_PENDING'
const APPLY_ACTION_CARD_SUCCESS = 'idfunctiondb/cards/APPLY_ACTION_CARD_SUCCESS'
const APPLY_ACTION_CARD_ERROR = 'idfunctiondb/cards/APPLY_ACTION_CARD_ERROR'
const APPLY_ACTION_CARD_CLEAR_ERROR = 'idfunctiondb/cards/APPLY_ACTION_CARD_CLEAR_ERROR'

const CREATE_CARDS_ASSIGN_SET_PHOTOS_PENDING =
  'idfunctiondb/cards/CREATE_CARDS_ASSIGN_SET_PHOTOS_PENDING'
const CREATE_CARDS_ASSIGN_SET_PHOTOS_SUCCESS =
  'idfunctiondb/cards/CREATE_CARDS_ASSIGN_SET_PHOTOS_SUCCESS'
const CREATE_CARDS_ASSIGN_SET_PHOTOS_ERROR =
  'idfunctiondb/cards/CREATE_CARDS_ASSIGN_SET_PHOTOS_ERROR'
const CREATE_CARDS_ASSIGN_SET_PHOTOS_CLEAR_ERROR =
  'idfunctiondb/cards/CREATE_CARDS_ASSIGN_SET_PHOTOS_CLEAR_ERROR'

const CREATE_CARD_CUSTOM_ATTRIBUTE_PENDING =
  'idfunctiondb/cards/CREATE_CARD_CUSTOM_ATTRIBUTE_PENDING'
const CREATE_CARD_CUSTOM_ATTRIBUTE_SUCCESS =
  'idfunctiondb/cards/CREATE_CARD_CUSTOM_ATTRIBUTE_SUCCESS'
const CREATE_CARD_CUSTOM_ATTRIBUTE_ERROR = 'idfunctiondb/cards/CREATE_CARD_CUSTOM_ATTRIBUTE_ERROR'
const CREATE_CARD_CUSTOM_ATTRIBUTE_CLEAR_ERROR =
  'idfunctiondb/cards/CREATE_CARD_CUSTOM_ATTRIBUTE_CLEAR_ERROR'
const DELETE_CARD_CUSTOM_ATTRIBUTE_PENDING =
  'idfunctiondb/cards/DELETE_CARD_CUSTOM_ATTRIBUTE_PENDING'
const DELETE_CARD_CUSTOM_ATTRIBUTE_SUCCESS =
  'idfunctiondb/cards/DELETE_CARD_CUSTOM_ATTRIBUTE_SUCCESS'
const DELETE_CARD_CUSTOM_ATTRIBUTE_ERROR = 'idfunctiondb/cards/DELETE_CARD_CUSTOM_ATTRIBUTE_ERROR'

// Initial
const initialState = {
  get_cards_total: {
    success: null,
    pending: false,
    error: null,
  },

  get_cards_history: {
    success: [],
    pending: false,
    error: null,
  },

  get_card: {
    success: [],
    pending: false,
    error: null,
  },

  update_cardInfo: {
    success: null,
    pending: false,
    error: null,
  },

  get_card_meta: {
    success: {},
    pending: false,
    error: null,
  },

  get_employee_cards: {
    success: [],
    pending: false,
    error: null,
  },

  get_visitor_cards: {
    success: [],
    pending: false,
    error: null,
  },

  assign_card: {
    success: [],
    pending: false,
    error: null,
  },

  unassign_card: {
    success: [],
    pending: false,
    error: null,
  },

  create_card: {
    success: [],
    pending: false,
    error: null,
  },

  bulk_create_pre_init_cards: {
    success: [],
    pending: false,
    error: null,
  },

  bulk_create_non_personalized_cards: {
    success: [],
    pending: false,
    error: null,
  },

  bulk_create_personalized_cards: {
    success: [],
    pending: false,
    error: null,
  },

  apply_action_card: {
    success: null,
    pending: false,
    error: null,
  },

  get_card_profiles: {
    success: [],
    pending: false,
    error: null,
  },

  get_card_states: {
    success: [],
    pending: false,
    error: null,
  },
  create_cards_assign_set_photos: {
    success: null,
    pending: false,
    error: null,
  },
  create_card_custom_attribute: {
    success: null,
    pending: false,
    error: null,
  },

  delete_card_custom_attribute: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CARDS_HISTORY_PENDING:
      return {
        ...state,
        get_cards_history: {
          ...state.get_cards_history,
          pending: true,
        },
      }
    case GET_CARDS_HISTORY_SUCCESS:
      return {
        ...state,
        get_cards_history: {
          ...state.get_cards_history,
          success: action.payload,
          meta: action.payload.meta,
          pending: false,
        },
      }
    case GET_CARDS_HISTORY_ERROR:
      return {
        ...state,
        get_cards_history: {
          ...state.get_cards_history,
          error: action.payload,
          pending: false,
        },
      }
    case GET_CARDS_HISTORY_CLEAR_ERROR:
      return {
        ...state,
        get_cards_history: {
          ...state.get_cards_history,
          error: null,
          pending: false,
        },
      }
    case GET_EMPLOYEES_CARDS_PENDING:
      return {
        ...state,
        get_employee_cards: {
          ...state.get_employee_cards,
          pending: true,
        },
      }
    case GET_EMPLOYEES_CARDS_SUCCESS:
      return {
        ...state,
        get_employee_cards: {
          ...state.get_employee_cards,
          success: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEES_CARDS_ERROR:
      return {
        ...state,
        get_employee_cards: {
          ...state.get_employee_cards,
          error: action.payload,
          pending: false,
        },
      }
    case GET_VISITORS_CARDS_PENDING:
      return {
        ...state,
        get_visitor_cards: {
          ...state.get_visitor_cards,
          pending: true,
        },
      }
    case GET_VISITORS_CARDS_SUCCESS:
      return {
        ...state,
        get_visitor_cards: {
          ...state.get_visitor_cards,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITORS_CARDS_ERROR:
      return {
        ...state,
        get_employee_cards: {
          ...state.get_employee_cards,
          error: action.payload,
          pending: false,
        },
      }
    case GET_CARD_PENDING:
      return {
        ...state,
        get_card: {
          ...state.get_card,
          pending: true,
        },
      }
    case GET_CARD_SUCCESS:
      return {
        ...state,
        get_card: {
          ...state.get_card,
          success: action.payload,
          pending: false,
        },
      }
    case GET_CARD_ERROR:
      return {
        ...state,
        get_card: {
          ...state.get_card,
          error: action.payload,
          pending: false,
        },
      }
    case GET_CARD_CLEAR_ERROR:
      return {
        ...state,
        get_card: {
          ...state.get_card,
          error: null,
        },
      }
    case UPDATE_CARDINFO_PENDING:
      return {
        ...state,
        update_cardInfo: {
          ...state.update_cardInfo,
          pending: true,
        },
      }
    case UPDATE_CARDINFO_SUCCESS:
      return {
        ...state,
        update_cardInfo: {
          ...state.update_cardInfo,
          success: action.payload,
          pending: false,
        },
      }
    case UPDATE_CARDINFO_ERROR:
      return {
        ...state,
        update_cardInfo: {
          ...state.update_cardInfo,
          error: action.payload,
          pending: false,
        },
      }
    case UPDATE_CARDINFO_CLEAR_ERROR:
      return {
        ...state,
        update_cardInfo: {
          ...state.update_cardInfo,
          error: null,
          pending: false,
        },
      }
    case GET_CARDS_TOTAL_PENDING:
      return {
        ...state,
        get_cards_total: {
          ...state.get_cards_total,
          pending: true,
        },
      }
    case GET_CARDS_TOTAL_SUCCESS:
      return {
        ...state,
        get_cards_total: {
          ...state.get_cards_total,
          success: action.payload,
          pending: false,
        },
      }
    case GET_CARDS_TOTAL_ERROR:
      return {
        ...state,
        get_cards_total: {
          ...state.get_cards_total,
          error: action.payload,
          pending: false,
        },
      }
    case GET_CARD_META_PENDING:
      return {
        ...state,
        get_card_meta: {
          ...state.get_card_meta,
          pending: true,
        },
      }
    case GET_CARD_META_SUCCESS:
      return {
        ...state,
        get_card_meta: {
          ...state.get_card_meta,
          success: action.payload,
          pending: false,
        },
      }
    case GET_CARD_META_ERROR:
      return {
        ...state,
        get_card_meta: {
          ...state.get_card_meta,
          error: action.payload,
          pending: false,
        },
      }
    case GET_CARD_PROFILES_PENDING:
      return {
        ...state,
        get_card_profiles: {
          ...state.get_card_profiles,
          pending: true,
        },
      }
    case GET_CARD_PROFILES_SUCCESS:
      return {
        ...state,
        get_card_profiles: {
          ...state.get_card_profiles,
          success: action.payload,
          pending: false,
        },
      }
    case GET_CARD_PROFILES_ERROR:
      return {
        ...state,
        get_card_profiles: {
          ...state.get_card_profiles,
          error: action.payload,
          pending: false,
        },
      }
    case GET_CARD_PROFILES_CLEAR_ERROR:
      return {
        ...state,
        get_card_profiles: {
          ...state.get_card_profiles,
          error: null,
          pending: false,
        },
      }
    case GET_CARD_STATES_PENDING:
      return {
        ...state,
        get_card_states: {
          ...state.get_card_states,
          pending: true,
        },
      }
    case GET_CARD_STATES_SUCCESS:
      return {
        ...state,
        get_card_states: {
          ...state.get_card_states,
          success: action.payload,
          pending: false,
        },
      }
    case GET_CARD_STATES_ERROR:
      return {
        ...state,
        get_card_states: {
          ...state.get_card_states,
          error: action.payload,
          pending: false,
        },
      }
    case GET_CARD_STATES_CLEAR_ERROR:
      return {
        ...state,
        get_card_states: {
          ...state.get_card_states,
          error: null,
        },
      }
    case CREATE_CARD_PENDING:
      return {
        ...state,
        create_card: {
          ...state.create_card,
          pending: true,
        },
      }
    case CREATE_CARD_SUCCESS:
      return {
        ...state,
        create_card: {
          ...state.create_card,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_CARD_ERROR:
      return {
        ...state,
        create_card: {
          ...state.create_card,
          error: action.payload,
          pending: false,
        },
      }
    case BULK_CREATE_PRE_INIT_CARDS_PENDING:
      return {
        ...state,
        bulk_create_pre_init_cards: {
          ...state.bulk_create_pre_init_cards,
          pending: true,
        },
      }
    case BULK_CREATE_PRE_INIT_CARDS_SUCCESS:
      return {
        ...state,
        bulk_create_pre_init_cards: {
          ...state.bulk_create_pre_init_cards,
          success: action.payload,
          pending: false,
        },
      }
    case BULK_CREATE_PRE_INIT_CARDS_ERROR:
      return {
        ...state,
        bulk_create_pre_init_cards: {
          ...state.bulk_create_pre_init_cards,
          error: action.payload,
          pending: false,
        },
      }
    case BULK_CREATE_PRE_INIT_CARDS_CLEAR_ERROR:
      return {
        ...state,
        bulk_create_pre_init_cards: {
          ...state.bulk_create_pre_init_cards,
          error: null,
        },
      }
    case BULK_CREATE_NON_PERSONALIZED_CARDS_PENDING:
      return {
        ...state,
        bulk_create_non_personalized_cards: {
          ...state.bulk_create_non_personalized_cards,
          pending: true,
        },
      }
    case BULK_CREATE_NON_PERSONALIZED_CARDS_SUCCESS:
      return {
        ...state,
        bulk_create_non_personalized_cards: {
          ...state.bulk_create_non_personalized_cards,
          success: action.payload,
          pending: false,
        },
      }
    case BULK_CREATE_NON_PERSONALIZED_CARDS_ERROR:
      return {
        ...state,
        bulk_create_non_personalized_cards: {
          ...state.bulk_create_non_personalized_cards,
          error: action.payload,
          pending: false,
        },
      }
    case BULK_CREATE_NON_PERSONALIZED_CARDS_CLEAR_ERROR:
      return {
        ...state,
        bulk_create_non_personalized_cards: {
          ...state.bulk_create_non_personalized_cards,
          error: null,
        },
      }
    case BULK_CREATE_PERSONALIZED_CARDS_PENDING:
      return {
        ...state,
        bulk_create_personalized_cards: {
          ...state.bulk_create_personalized_cards,
          pending: true,
        },
      }
    case BULK_CREATE_PERSONALIZED_CARDS_SUCCESS:
      return {
        ...state,
        bulk_create_personalized_cards: {
          ...state.bulk_create_personalized_cards,
          success: action.payload,
          pending: false,
        },
      }
    case BULK_CREATE_PERSONALIZED_CARDS_ERROR:
      return {
        ...state,
        bulk_create_personalized_cards: {
          ...state.bulk_create_personalized_cards,
          error: action.payload,
          pending: false,
        },
      }
    case BULK_CREATE_PERSONALIZED_CARDS_CLEAR_ERROR:
      return {
        ...state,
        bulk_create_personalized_cards: {
          ...state.bulk_create_personalized_cards,
          error: null,
        },
      }
    case ASSIGN_CARD_PENDING:
      return {
        ...state,
        assign_card: {
          ...state.assign_card,
          pending: true,
        },
      }
    case ASSIGN_CARD_SUCCESS:
      return {
        ...state,
        assign_card: {
          ...state.assign_card,
          success: action.payload,
          pending: false,
        },
      }
    case ASSIGN_CARD_ERROR:
      return {
        ...state,
        assign_card: {
          ...state.assign_card,
          error: action.payload,
          pending: false,
        },
      }
    case ASSIGN_CARD_CLEAR_ERROR:
      return {
        ...state,
        assign_card: {
          ...state.assign_card,
          error: null,
          pending: false,
        },
      }
    case UNASSIGN_CARD_PENDING:
      return {
        ...state,
        unassign_card: {
          ...state.unassign_card,
          pending: true,
        },
      }
    case UNASSIGN_CARD_SUCCESS:
      return {
        ...state,
        unassign_card: {
          ...state.unassign_card,
          success: action.payload,
          pending: false,
        },
      }
    case UNASSIGN_CARD_ERROR:
      return {
        ...state,
        unassign_card: {
          ...state.unassign_card,
          error: action.payload,
          pending: false,
        },
      }
    case UNASSIGN_CARD_CLEAR_ERROR:
      return {
        ...state,
        unassign_card: {
          ...state.unassign_card,
          error: null,
        },
      }
    case APPLY_ACTION_CARD_PENDING:
      return {
        ...state,
        apply_action_card: {
          ...state.apply_action_card,
          pending: true,
        },
      }
    case APPLY_ACTION_CARD_SUCCESS:
      return {
        ...state,
        apply_action_card: {
          ...state.apply_action_card,
          success: action.payload,
          pending: false,
        },
      }
    case APPLY_ACTION_CARD_ERROR:
      return {
        ...state,
        apply_action_card: {
          ...state.apply_action_card,
          error: action.payload,
          pending: false,
        },
      }
    case APPLY_ACTION_CARD_CLEAR_ERROR:
      return {
        ...state,
        apply_action_card: {
          ...state.apply_action_card,
          error: null,
        },
      }
    case CREATE_CARDS_ASSIGN_SET_PHOTOS_PENDING:
      return {
        ...state,
        create_cards_assign_set_photos: {
          ...state.create_cards_assign_set_photos,
          pending: true,
        },
      }
    case CREATE_CARDS_ASSIGN_SET_PHOTOS_SUCCESS:
      return {
        ...state,
        create_cards_assign_set_photos: {
          ...state.create_cards_assign_set_photos,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_CARDS_ASSIGN_SET_PHOTOS_ERROR:
      return {
        ...state,
        create_cards_assign_set_photos: {
          ...state.create_cards_assign_set_photos,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_CARDS_ASSIGN_SET_PHOTOS_CLEAR_ERROR:
      return {
        ...state,
        create_cards_assign_set_photos: {
          ...state.create_cards_assign_set_photos,
          error: null,
          pending: false,
        },
      }
    case CREATE_CARD_CUSTOM_ATTRIBUTE_PENDING:
      return {
        ...state,
        create_card_custom_attribute: {
          ...state.create_card_custom_attribute,
          pending: true,
        },
      }
    case CREATE_CARD_CUSTOM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        create_card_custom_attribute: {
          ...state.create_card_custom_attribute,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_CARD_CUSTOM_ATTRIBUTE_ERROR:
      return {
        ...state,
        create_card_custom_attribute: {
          ...state.create_card_custom_attribute,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_CARD_CUSTOM_ATTRIBUTE_CLEAR_ERROR:
      return {
        ...state,
        create_card_custom_attribute: {
          ...state.create_card_custom_attribute,
          error: null,
          pending: false,
        },
      }
    case DELETE_CARD_CUSTOM_ATTRIBUTE_PENDING:
      return {
        ...state,
        delete_card_custom_attribute: {
          ...state.delete_card_custom_attribute,
          pending: true,
        },
      }
    case DELETE_CARD_CUSTOM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        delete_card_custom_attribute: {
          ...state.delete_card_custom_attribute,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_CARD_CUSTOM_ATTRIBUTE_ERROR:
      return {
        ...state,
        delete_card_custom_attribute: {
          ...state.delete_card_custom_attribute,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions

function getCardsTotalPending() {
  return {type: GET_CARDS_TOTAL_PENDING}
}

function getCardsTotalSuccess(payload) {
  return {type: GET_CARDS_TOTAL_SUCCESS, payload: payload}
}

function getCardsTotalError(error) {
  return {type: GET_CARDS_TOTAL_ERROR, payload: error}
}

function getCardsHistoryPending() {
  return {type: GET_CARDS_HISTORY_PENDING}
}

function getCardsHistorySuccess(payload) {
  return {type: GET_CARDS_HISTORY_SUCCESS, payload: payload}
}

function getCardsHistoryError(error) {
  return {type: GET_CARDS_HISTORY_ERROR, payload: error}
}

export function getCardsHistoryClearError() {
  return {type: GET_CARDS_HISTORY_CLEAR_ERROR}
}

function getEmployeesCardsPending() {
  return {type: GET_EMPLOYEES_CARDS_PENDING}
}

function getEmployeesCardsSuccess(payload) {
  return {type: GET_EMPLOYEES_CARDS_SUCCESS, payload: payload}
}

function getEmployeesCardsError(error) {
  return {type: GET_EMPLOYEES_CARDS_ERROR, payload: error}
}

function getVisitorsCardsPending() {
  return {type: GET_VISITORS_CARDS_PENDING}
}

function getVisitorsCardsSuccess(payload) {
  return {type: GET_VISITORS_CARDS_SUCCESS, payload: payload}
}

function getVisitorsCardsError(error) {
  return {type: GET_VISITORS_CARDS_ERROR, payload: error}
}

function getCardPending() {
  return {type: GET_CARD_PENDING}
}

function getCardSuccess(payload) {
  return {type: GET_CARD_SUCCESS, payload: payload}
}

function getCardError(error) {
  return {type: GET_CARD_ERROR, payload: error}
}

export function getCardClearError() {
  return {type: GET_CARD_CLEAR_ERROR}
}

function updateCardInfoPending() {
  return {type: UPDATE_CARDINFO_PENDING}
}

function updateCardInfoSuccess(payload) {
  return {type: UPDATE_CARDINFO_SUCCESS, payload: payload}
}

function updateCardInfoError(error) {
  return {type: UPDATE_CARDINFO_ERROR, payload: error}
}

export function updateCardInfoClearError() {
  return {type: UPDATE_CARDINFO_CLEAR_ERROR}
}

function getCardProfilesPending() {
  return {type: GET_CARD_PROFILES_PENDING}
}

function getCardProfilesSuccess(payload) {
  return {type: GET_CARD_PROFILES_SUCCESS, payload: payload}
}

function getCardProfilesError(error) {
  return {type: GET_CARD_PROFILES_ERROR, payload: error}
}

export function getCardProfilesCLearError() {
  return {type: GET_CARD_PROFILES_CLEAR_ERROR}
}

function getCardStatesPending() {
  return {type: GET_CARD_STATES_PENDING}
}

function getCardStatesSuccess(payload) {
  return {type: GET_CARD_STATES_SUCCESS, payload: payload}
}

function getCardStatesError(error) {
  return {type: GET_CARD_STATES_ERROR, payload: error}
}

export function getCardStatesClearError() {
  return {type: GET_CARD_STATES_CLEAR_ERROR}
}

function createCardPending() {
  return {type: CREATE_CARD_PENDING}
}

function createCardSuccess(payload) {
  return {type: CREATE_CARD_SUCCESS, payload: payload}
}

function createCardError(error) {
  return {type: CREATE_CARD_ERROR, payload: error}
}

function createCardCustomAttributePending() {
  return {type: CREATE_CARD_CUSTOM_ATTRIBUTE_PENDING}
}

function createCardCustomAttributeSuccess(payload) {
  return {type: CREATE_CARD_CUSTOM_ATTRIBUTE_SUCCESS, payload: payload}
}

function createCardCustomAttributeError(error) {
  return {type: CREATE_CARD_CUSTOM_ATTRIBUTE_ERROR, payload: error}
}

export function createCardCustomAttributeClearError() {
  return {type: CREATE_CARD_CUSTOM_ATTRIBUTE_CLEAR_ERROR}
}

function deleteCardCustomAttributePending() {
  return {type: DELETE_CARD_CUSTOM_ATTRIBUTE_PENDING}
}

function deleteCardCustomAttributeSuccess(payload) {
  return {type: DELETE_CARD_CUSTOM_ATTRIBUTE_SUCCESS, payload: payload}
}

function deleteCardCustomAttributeError(error) {
  return {type: DELETE_CARD_CUSTOM_ATTRIBUTE_ERROR, payload: error}
}

function getCardMetaPending() {
  return {type: GET_CARD_META_PENDING}
}

function getCardMetaSuccess(payload) {
  return {type: GET_CARD_META_SUCCESS, payload: payload}
}

function getCardMetaError(error) {
  return {type: GET_CARD_META_ERROR, payload: error}
}

function bulkCreatePreInitCardsPending() {
  return {type: BULK_CREATE_PRE_INIT_CARDS_PENDING}
}

function bulkCreatePreInitCardsSuccess(payload) {
  return {type: BULK_CREATE_PRE_INIT_CARDS_SUCCESS, payload: payload}
}

function bulkCreatePreInitCardsError(error) {
  return {type: BULK_CREATE_PRE_INIT_CARDS_ERROR, payload: error}
}

function bulkCreateNonPersonalizedCardsPending() {
  return {type: BULK_CREATE_NON_PERSONALIZED_CARDS_PENDING}
}

function bulkCreateNonPersonalizedCardsSuccess(payload) {
  return {type: BULK_CREATE_NON_PERSONALIZED_CARDS_SUCCESS, payload: payload}
}

function bulkCreateNonPersonalizedCardsError(error) {
  return {type: BULK_CREATE_NON_PERSONALIZED_CARDS_ERROR, payload: error}
}

export function bulkCreateNonPersonalizedCardsClearError() {
  return {type: BULK_CREATE_NON_PERSONALIZED_CARDS_CLEAR_ERROR}
}

function bulkCreatePersonalizedCardsPending() {
  return {type: BULK_CREATE_PERSONALIZED_CARDS_PENDING}
}

function bulkCreatePersonalizedCardsSuccess(payload) {
  return {type: BULK_CREATE_PERSONALIZED_CARDS_SUCCESS, payload: payload}
}

function bulkCreatePersonalizedCardsError(error) {
  return {type: BULK_CREATE_PERSONALIZED_CARDS_ERROR, payload: error}
}

export function bulkCreatePersonalizedCardsClearError() {
  return {type: BULK_CREATE_PERSONALIZED_CARDS_CLEAR_ERROR}
}

export function bulkCreatePreInitCardsClearError() {
  return {type: BULK_CREATE_PRE_INIT_CARDS_CLEAR_ERROR}
}

function assignCardPending() {
  return {type: ASSIGN_CARD_PENDING}
}

function assignCardSuccess(payload) {
  return {type: ASSIGN_CARD_SUCCESS, payload: payload}
}

function assignCardError(error) {
  return {type: ASSIGN_CARD_ERROR, payload: error}
}

export function assignCardClearError() {
  return {type: ASSIGN_CARD_CLEAR_ERROR}
}

function unAssignCardPending() {
  return {type: UNASSIGN_CARD_PENDING}
}

function unAssignCardSuccess(payload) {
  return {type: UNASSIGN_CARD_SUCCESS, payload: payload}
}

function unAssignCardError(error) {
  return {type: UNASSIGN_CARD_ERROR, payload: error}
}

export function unAssignCardClearError() {
  return {type: UNASSIGN_CARD_CLEAR_ERROR}
}

function applyActionOnCardPending() {
  return {type: APPLY_ACTION_CARD_PENDING}
}

function applyActionOnCardSuccess(payload) {
  return {type: APPLY_ACTION_CARD_SUCCESS, payload: payload}
}

function applyActionOnCardError(error) {
  return {type: APPLY_ACTION_CARD_ERROR, payload: error}
}

export function applyActionOnCardClearError() {
  return {type: APPLY_ACTION_CARD_CLEAR_ERROR}
}

function createCardsAssignAndSetPhotosPending() {
  return {type: CREATE_CARDS_ASSIGN_SET_PHOTOS_PENDING}
}

function createCardsAssignAndSetPhotosSuccess(payload) {
  return {type: CREATE_CARDS_ASSIGN_SET_PHOTOS_SUCCESS, payload: payload}
}

function createCardsAssignAndSetPhotosError(error) {
  return {type: CREATE_CARDS_ASSIGN_SET_PHOTOS_ERROR, payload: error}
}

export function createCardsAssignAndSetPhotosClearError() {
  return {type: CREATE_CARDS_ASSIGN_SET_PHOTOS_CLEAR_ERROR}
}

// Operations
export const getCardsTotal = () => (dispatch) => {
  dispatch(getCardsTotalPending())
  return axios
    .get(`/api/v1/cards?amount_of=total`)
    .then((res) => {
      dispatch(getCardsTotalSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getCardsTotalError(error)))
}

export const getCardsHistory =
  (identity, id, limit = 10000, offset = 0) =>
  (dispatch) => {
    dispatch(getCardsHistoryPending())
    return axios
      .get(`/api/v1/${identity}/${id}/cards_history?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getCardsHistorySuccess(res.data.results))
      })
      .catch((error) => dispatch(getCardsHistoryError(error)))
  }

export const getCard = (url) => (dispatch) => {
  dispatch(getCardPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getCardSuccess(res.data))
      return res.data
    })
    .catch((error) => {
      dispatch(getCardError(error))
    })
}

export const getCardMeta = (id) => (dispatch) => {
  dispatch(getCardMetaPending())
  return axios
    .get(`/api/v1/cards?current=${id}`)
    .then((res) => {
      dispatch(getCardMetaSuccess(res.data.meta))
      return res.data.meta
    })
    .catch((error) => dispatch(getCardMetaError(error)))
}

export const getEmployeesCards = (url) => (dispatch) => {
  dispatch(getEmployeesCardsPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getEmployeesCardsSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(getEmployeesCardsError(error)))
}

export const getVisitorsCards = (url) => (dispatch) => {
  dispatch(getVisitorsCardsPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getVisitorsCardsSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(getVisitorsCardsError(error)))
}

export const getCardProfiles = (url) => (dispatch) => {
  dispatch(getCardProfilesPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getCardProfilesSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(getCardProfilesError(error)))
}

export const getCardStates = () => (dispatch) => {
  dispatch(getCardStatesPending())
  return axios
    .get(`/api/v1/cards/states`)
    .then((res) => {
      dispatch(getCardStatesSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(getCardStatesError(error)))
}

export const createCard = (values) => (dispatch) => {
  dispatch(createCardPending())
  return axios
    .post(`/api/v1/cards`, values)
    .then((res) => {
      dispatch(createCardSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(createCardError(error)))
}

export const bulkCreatePreInitCards = (values, arrayOfTranslations, document) => (dispatch) => {
  dispatch(bulkCreatePreInitCardsPending())
  return axios
    .post(`/api/v1/cards/bulk_pre_init`, values)
    .then((res) => {
      if (res.data.results) {
        dispatch(createPreInitCardsReport(res.data.results, arrayOfTranslations, document))
      }
      dispatch(bulkCreatePreInitCardsSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(bulkCreatePreInitCardsError(error)))
}

export const bulkCreatePersonalizedCards = (values) => (dispatch) => {
  dispatch(bulkCreatePersonalizedCardsPending())
  return axios
    .post(`/api/v1/cards/bulk_personalized`, values)
    .then((res) => {
      dispatch(bulkCreatePersonalizedCardsSuccess(res.data))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.CardsBulkCreated'))
      return res.data
    })
    .catch((error) => dispatch(bulkCreatePersonalizedCardsError(error)))
}

export const bulkCreateNonPersonalizedCards = (values) => (dispatch) => {
  dispatch(bulkCreateNonPersonalizedCardsPending())
  return axios
    .post(`/api/v1/cards/bulk_non_personalized`, values)
    .then((res) => {
      dispatch(bulkCreateNonPersonalizedCardsSuccess(res.data))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.CardsBulkCreated'))
      return res.data
    })
    .catch((error) => dispatch(bulkCreateNonPersonalizedCardsError(error)))
}

export const createCardAndAssignAndSetPhotos = (humanValues, values) => (dispatch) => {
  dispatch(createCardsAssignAndSetPhotosPending())
  if (humanValues.url.includes('employees')) {
    values['employees'] = [humanValues.id]
  }
  if (humanValues.url.includes('visitors')) {
    values['visitors'] = [humanValues.id]
  }
  axios // hits the same endpoint as the bulk creation
    .post(`/api/v1/cards/bulk_personalized`, values)
    .then((res) => {
      dispatch(createCardsAssignAndSetPhotosSuccess(res.data.results))
    })
    .catch((error) => dispatch(createCardsAssignAndSetPhotosError(error)))
}

export const assignCard = (url, card_id) => (dispatch) => {
  dispatch(assignCardPending())
  return axios
    .post(url, {card_id: card_id})
    .then((res) => {
      dispatch(assignCardSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(assignCardError(error)))
}

export const unAssignCard = (employeeOrVisitor, eOrV_id, card_id) => (dispatch) => {
  dispatch(unAssignCardPending())
  return axios
    .delete(`/api/v1/${employeeOrVisitor}/${eOrV_id}/cards/${card_id}`, {card_id: card_id})
    .then((res) => {
      dispatch(unAssignCardSuccess(res.data))
      return res.data
    })
    .catch((error) => {
      dispatch(unAssignCardError(error))
    })
}

export const applyActionOnCard = (url, actions, notify, userID) => (dispatch) => {
  dispatch(applyActionOnCardPending())
  return axios
    .put(url, actions)
    .then((res) => {
      dispatch(applyActionOnCardSuccess(res))
      if (notify) {
        dispatch(
          createNotification(userID, {
            code: '201',
            message: I18n.t('notificationSuccess.' + res.data.action + 'Card', {
              number: res.data.badgenr,
            }),
            link: `/cards/${res.data.id}`,
          }),
        )
      }
      return res
    })
    .catch((error) => {
      dispatch(applyActionOnCardError(error))
      return error
    })
}

export const deleteCardCustomAttribute = (id, key) => (dispatch) => {
  dispatch(deleteCardCustomAttributePending())
  return axios
    .delete(`/api/v1/cards/${id}/custom_attributes/${key}`)
    .then((res) => {
      dispatch(deleteCardCustomAttributeSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(deleteCardCustomAttributeError(error))
    })
}

export const createCardCustomAttribute = (id, values) => (dispatch) => {
  dispatch(createCardCustomAttributePending())
  return axios
    .post(`/api/v1/cards/${id}/custom_attributes`, values)
    .then((res) => {
      dispatch(createCardCustomAttributeSuccess(res))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.CustomAttributeCreated'))
      return res.data
    })
    .catch((error) => {
      dispatch(createCardCustomAttributeError(error))
    })
}

export const updateCardInfo = (id, values) => (dispatch) => {
  dispatch(updateCardInfoPending())
  return axios
    .put(`/api/v1/cards/${id}`, values)
    .then((res) => {
      dispatch(updateCardInfoSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(updateCardInfoError(error)))
}
