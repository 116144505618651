import {useEffect} from 'react'
import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import {
  getInventory,
  getInventoryHistory,
  getInventoryHistoryClearError,
  getInventoryMeta,
} from '../stores/inventories'
import InventoryHistoryWrapper from '../components/wrappers/InventoryHistoryWrapper'
import FormEditInventory from '../components/forms/FormEditInventory'
import FormCameraRegular from '../components/forms/FormCameraRegular'
import FormEditImageUploadInventory from '../components/forms/FormEditImageUploadInventory'
import Image from '../components/Image'
import ImageSrc from '../assets/inventory.svg'
import IdentityNavigationArrows from '../components/IdentityNavigationArrows'
import {ModalOpenButton, Modal, ModalContentsBase} from '../components/Modal'
import {DownloadIcon, UploadIcon, CameraIcon, TrashIcon} from '@heroicons/react/outline'
import {useParams, useNavigate} from 'react-router-dom'
import {INVENTORY} from '../utils/formViews'
import WorkflowForms from '../components/WorkflowForms'
import DeletionWarning from '../components/DeletionWarning'
import {deleteInventoryPhoto, deleteInventoryPhotoClearError} from '../stores/inventories_photos'

export default function InventoryScreen() {
  let {id} = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  // inventory States
  const inventory = useSelector((state) => state.inventories.get_inventory.success)
  const inventory_error = useSelector((state) => state.inventories.get_inventory.error)
  const inventoryMeta = useSelector((state) => state.inventories.get_inventory_meta.success)
  const inventoryPhotoSetSuccess = useSelector(
    (state) => state.inventories_photos.create_and_set_inventory_photo.success,
  )
  const inventorySetSuccess = useSelector((state) => state.inventories.set_inventory.success)
  const {
    success: inventory_history,
    pending: inventory_history_pending,
    error: inventory_history_error,
  } = useSelector((state) => state.inventories.get_inventory_history)

  const {
    success: delete_inventory_photo,
    pending: delete_inventory_photo_pending,
    error: delete_inventory_photo_error,
  } = useSelector((state) => state.inventories_photos.delete_inventory_photo)

  useEffect(() => {
    if (inventory_error?.response?.status === 404) {
      navigate('/not_found/', {replace: true})
    }
  }, [inventory_error?.response?.status, navigate])

  // get Inventory
  useEffect(() => {
    dispatch(getInventory(`/api/v1/inventories/${id}`))
  }, [dispatch, id, inventoryPhotoSetSuccess, inventorySetSuccess, delete_inventory_photo])
  // get inventory history
  useEffect(() => {
    dispatch(getInventoryHistory(`/api/v1/inventories/${id}/history`))
  }, [dispatch, id, inventory_history_error?.message])

  // get Inventory Meta
  useEffect(() => {
    if (permissions.listEmployees) {
      // if user has no permission we don't need to call getInventoryMeta
      dispatch(getInventoryMeta(id))
    }
  }, [id, permissions.listEmployees, dispatch])

  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          {inventory?.photos?.current_id ? (
            <Image ImgSrc={inventory?.photos?.current} />
          ) : inventory?.type?.photos?.current_id ? (
            <Image
              ImgSrc={inventory?.type?.photos?.current}
              className={'profile_image'}
              spinnerwidth={'140px'}
              spinnerheight={'175px'}
            />
          ) : (
            <Image ImgSrc={ImageSrc} />
          )}
          <div className="flex flex-col pt-3">
            <Modal>
              <ModalOpenButton>
                <CameraIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </ModalOpenButton>
              <ModalContentsBase>
                <FormCameraRegular identity={inventory} />
              </ModalContentsBase>
            </Modal>
            <Modal>
              <ModalOpenButton>
                <UploadIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </ModalOpenButton>
              <ModalContentsBase>
                <FormEditImageUploadInventory identity={inventory} />
              </ModalContentsBase>
            </Modal>
            <div className="space-y-1 text-center">
              <a
                href={inventory?.photos?.current}
                download={`${inventory?.model}_${inventory?.inventory_number}.png`}
              >
                <DownloadIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </a>
            </div>
            {inventory?.photos?.current_id ? (
              <Modal>
                <ModalOpenButton>
                  <TrashIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
                </ModalOpenButton>
                <ModalContentsBase>
                  <DeletionWarning
                    deleteFunction={deleteInventoryPhoto}
                    id={id}
                    pending={delete_inventory_photo_pending}
                    error={delete_inventory_photo_error}
                    setError={deleteInventoryPhotoClearError}
                    text={t('confirmDeletion.Confirm')}
                    message={t('confirmDeletion.DeleteInventoryPhoto')}
                    values={inventory?.photos?.current}
                  />
                </ModalContentsBase>
              </Modal>
            ) : null}
          </div>
          <div className="flex flex-col pt-3">
            <p className="text-gray-700">{inventory?.model}</p>
            <p className="text-gray-700">{inventory?.type.name}</p>
            <p className="text-gray-700">{inventory?.inventory_number}</p>
          </div>
        </div>
        {permissions.listInventories ? (
          <div className="flex pt-3">
            <IdentityNavigationArrows
              next={inventoryMeta?.next}
              previous={inventoryMeta?.previous}
              navTo="/inventories"
            />
          </div>
        ) : null}
      </div>
      <WorkflowForms renderOnUi={INVENTORY} isOriginUrl />
      <Collapse title={t('InventoryDetails.Title')}>
        <FormEditInventory data={inventory} />
      </Collapse>
      <Collapse title={t('InventoryHistoryInfo.Title')}>
        <InventoryHistoryWrapper
          data={inventory_history}
          pending={inventory_history_pending}
          error={inventory_history_error}
          setError={getInventoryHistoryClearError}
        />
      </Collapse>
    </>
  )
}
