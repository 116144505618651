import FormBuilderJsonPreview from '../components/FormBuilderJsonPreview'
import {Button} from '../components/Buttons'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import FormBuilderNew from '../components/forms/FormBuilder'
import {DynamicRhfForm} from '../components/formRendererRHF/DynamicRhfForm'
import {postWorkflowform} from '../stores/workflows'
import {useDispatch} from 'react-redux'

export default function FormBuilderScreen() {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const [showJson, setShowJson] = useState(false)
  const [formConfig, setFromConfig] = useState()

  const onSubmit = () => {
    const data = {
      form_json: JSON.stringify(formConfig),
      description: formConfig.description,
      location: formConfig.location,
      name: formConfig.name,
    }
    dispatch(postWorkflowform(data))
  }

  return (
    <>
      <div className="min-h-full">
        <main className="py-1">
          {/* Page header */}
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-2xl font-bold text-gray-900">{t('formBuilder.FormBuilder')}</h1>
            <p className="text-sm font-medium text-gray-500">{t('formBuilder.InstructionText')}</p>
          </div>
          <div className="mx-auto mt-8 grid max-w-4xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">
            {/* FormFormBuilder Section*/}
            <section
              aria-labelledby="form-building-section"
              className="space-y-6 bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 lg:col-span-2 lg:col-start-1"
            >
              <h2
                id="form-preview-title"
                className="bg-lime-100 p-2 text-lg font-medium text-gray-900 shadow sm:rounded-lg"
              >
                {t('formBuilder.BuildForm')}
              </h2>
              <FormBuilderNew setFromConfig={setFromConfig} onFormSettingsSubmit={onSubmit} />
            </section>
            {/* Form Preview Section*/}
            <section
              aria-labelledby="form-preview-section"
              className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 lg:col-span-2 lg:col-start-3"
            >
              <h2
                id="form-preview-title"
                className="mb-5 bg-lime-100 p-2 text-lg font-medium text-gray-900 shadow sm:rounded-lg"
              >
                {t('formBuilder.FormPreview')}
              </h2>
              {!!formConfig && (
                <>
                  {!showJson ? (
                    <>
                      <DynamicRhfForm
                        formConfig={formConfig}
                        onSubmit={(values) => alert(JSON.stringify(values, 0, 2))}
                      />
                    </>
                  ) : (
                    <FormBuilderJsonPreview jsonValues={formConfig} />
                  )}
                  <div className="mt-5 flex justify-between">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(formConfig))
                      }}
                      text={t('formBuilder.CopyToClipboard')}
                    />
                    <Button
                      type="button"
                      onClick={() => setShowJson(!showJson)}
                      text={t(showJson ? 'formBuilder.ShowForm' : 'formBuilder.ShowJSON')}
                    />
                  </div>
                </>
              )}
            </section>
          </div>
        </main>
      </div>
    </>
  )
}
