import {useState} from 'react'
import SpinnerSVG from '../../assets/Spinner.svg'
import FormEditImageCameraIdentityHeader from './FormEditImageCameraIdentityHeader'
import CameraAi from '../CameraAi'
import {useSelector} from 'react-redux'
import ErrorOnModal from '../ErrorOnModal'
import {takeSnapshotClearError} from '../../stores/camera'
import {useTranslation} from 'react-i18next'

export default function FormCameraAi({
  identity,
  isEmployee,
  isVisitor,
  isCardPhoto,
  IdentityCardPhotoPutUrl,
  IdentityCardPhotoPostUrl,
  cards,
}) {
  const {t} = useTranslation()
  const [capturedImages, setCapturedImages] = useState(null)
  const [showCamera, setShowCamera] = useState(true)
  const [disableButtons, setDisableButtons] = useState(true)

  const error = useSelector((state) => state.camera.take_snapshoot.error)
  return (
    <>
      {/* Take the photo */}
      {showCamera ? (
        <>
          <CameraAi
            setCapturedImages={setCapturedImages}
            setShowCamera={setShowCamera}
            disableButtons={disableButtons}
            setDisableButtons={setDisableButtons}
          />
        </>
      ) : !capturedImages && !showCamera && !error ? (
        <div className="text-md flex flex-col items-center justify-center p-3">
          <img alt="o" src={SpinnerSVG} className="mr-3 h-20 w-20 animate-spin text-lime-500" />
          {t('addAvatar.ProcessingImage')}
        </div>
      ) : error ? (
        <ErrorOnModal error={error} setShow={setShowCamera} clearError={takeSnapshotClearError} />
      ) : (
        capturedImages && (
          // Edit the photo
          <FormEditImageCameraIdentityHeader
            identity={identity}
            IdentityCardPhotoPutUrl={IdentityCardPhotoPutUrl}
            IdentityCardPhotoPostUrl={IdentityCardPhotoPostUrl}
            capturedImage={capturedImages}
            isCardPhoto={isCardPhoto}
            isEmployee={isEmployee}
            isVisitor={isVisitor}
            cards={cards}
            setShowCamera={setShowCamera}
          />
        )
      )}
    </>
  )
}
