import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'ColumnFilter',
    accessor: 'name',
    label: 'workflows.Name',
    cell: 'TwoRowCell',
    secondAccessor: 'description',
  },
  {
    header: 'SelectColumnFilterRenderAt',
    accessor: 'render_on_ui',
    label: 'workflows.RenderAt',
    cell: 'RenderOnUiCell',
  },
  {
    header: 'SelectColumnFilterLocations',
    label: 'workflows.Location',
    accessor: 'location.description',
    permissionsAccessor: 'listWorkflowsLocations',
  },
  {
    header: 'workflows.Form',
    label: 'workflows.Form',
    accessor: 'form',
    cell: 'FormEditWebhookActionsCell',
    noSort: true,
  },
  {
    header: 'workflows.Active',
    label: 'workflows.Active',
    accessor: 'url',
    cell: 'EditWorkflowActionCell',
    noSort: true,
  },
  {
    header: 'workflows.PublicAccess',
    label: 'workflows.PublicAccess',
    accessor: 'unauthenticated_use',
    cell: 'EditWorkflowAuthenticatedUseActionCell',
    noSort: true,
  },
  {
    header: 'TableLabelsCommon.CreatedAt',
    label: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    label: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
  },
  {
    header: 'TableLabelsCommon.Description',
    label: 'TableLabelsCommon.Description',
    accessor: 'description',
  },
  {
    header: 'workflows.DeleteWorkflow',
    label: 'workflows.DeleteWorkflow',
    accessor: 'id',
    cell: 'DeleteWorkflowCell',
    noSort: true,
  },
]
export default columnConfig
