import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import axios from '../../services/api'
import {useSelector} from 'react-redux'
import Notification from '../Notification'

const FormUpdateIdentity = ({identity, onUpdate, onlyChildren}) => {
  const {t} = useTranslation()
  const [pending, setPending] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [currentIdentity, setCurrentIdentity] = useState(identity)

  const {createEmployeeLocations} = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const handleSubmit = async (values) => {
    setPending(true)
    setError(null)
    setSuccess(false)
    try {
      delete values.location
      const {data} = await axios.put(`/api/v1/identities/${identity.id}`, values)
      onUpdate && onUpdate()
      setCurrentIdentity(data)
      setSuccess(true)
    } catch (error) {
      setError(error)
    }
    setPending(false)
  }

  const fields = [
    {
      component: InputComponent.TEXT_INPUT,
      name: 'name',
      label: 'TableLabelsCommon.Name',
      defaultValue: currentIdentity?.name || '',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      name: 'description',
      label: 'TableLabelsCommon.Description',
      defaultValue: currentIdentity?.description || '',
    },
    identity?.location && {
      component: InputComponent.SELECT,
      name: `location`,
      label: t('addEmployee.Domain'),
      placeholder: t('addEmployee.DomainPlaceholder'),
      isMulti: false,
      options: createEmployeeLocations,
      disabled: true,
      defaultValue: createEmployeeLocations.find(
        (option) => option.label === currentIdentity?.location,
      )?.value,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
  ].filter(Boolean)

  const fieldsChildren = [
    {
      component: InputComponent.ASYNCREACTSELECT,
      name: 'additional_children_identities',
      label: 'resource.Children',
      isMulti: true,
      url: `/api/v1/identities?q=`,
      clearOptions: true,
      nestedOptions: true,
      optionValue: 'id',
      optionLabel: 'owner_name',
      validationType: YupValidationType.ARRAY,
      validations: [
        {
          type: InputYupValidationsType.MIN,
          params: [1, 'yupError.ThisFieldIsRequired'],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields: onlyChildren ? fieldsChildren : fields,
          name: 'form-update-identity',
          submitButtonText: t(onlyChildren ? 'formCommon.Add' : 'formCommon.Save'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        infoMessage={
          <Notification
            error={error}
            setError={() => {
              setError(null)
              setSuccess(false)
            }}
            success={!!success}
            successMessageKey="identity.UpdateSuccess"
          />
        }
      />
    </>
  )
}

export default FormUpdateIdentity
