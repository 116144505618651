import Table from '../table'
import {useTranslation} from 'react-i18next'
import {ModalOpenButton, Modal, ModalContentsBase} from '../Modal'
import {Button} from '../Buttons'
import FormAssignResource from '../forms/FormAssignResource'
import {useTableColumns} from '../../hooks/useTableColumns'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'

export default function ResourcesWrapper({
  identityAssignResourcePermission,
  identityLabel,
  identity,
  data,
  pending,
  error,
  setError,
}) {
  const {t} = useTranslation()
  const columns = useTableColumns(TableId.RESOURCES_EMPLOYEE)
  return (
    <>
      {identityAssignResourcePermission && (
        <Modal>
          <ModalOpenButton>
            <Button text={t('attachResourceForm.AttachResourceTitle')} variant="secondary" />
          </ModalOpenButton>
          <ModalContentsBase title={t('attachResourceForm.AttachResourceTitle')}>
            <FormAssignResource identityLabel={identityLabel} identity={identity} />
          </ModalContentsBase>
        </Modal>
      )}
      <>
        <EditTableWrapper tableId={TableId.RESOURCES_EMPLOYEE} />
        <Table columns={columns} data={data} pending={pending} error={error} setError={setError} />
      </>
    </>
  )
}
