import {formatInTimeZone} from 'date-fns-tz'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Link, useNavigate, useParams} from 'react-router-dom'

import {classNames} from '../../utils/classNames'

//component imports
import {ModalOpenButton, Modal, ModalContentsBase} from '../Modal'
import DeletionWarning from '../DeletionWarning'
import InfoBox from '../InfoBox'
import {SwitchWrapperAction} from '../FormInputs'

import ProfilePhoto from '../../assets/AIFaceScan.svg'
import InventoryPhoto from '../../assets/inventory.svg'

//icon imports
import {BeakerIcon, DocumentDownloadIcon, LinkIcon} from '@heroicons/react/solid'
import {
  LockOpenIcon,
  LockClosedIcon,
  PencilIcon,
  PlayIcon,
  ClipboardIcon,
  TrashIcon,
  CalendarIcon,
  KeyIcon,
  QuestionMarkCircleIcon,
  DocumentRemoveIcon,
  DocumentIcon,
} from '@heroicons/react/outline'

//form imports
import FormEditCompany from '../forms/FormEditCompany'
import FormEditConfiguration from '../forms/FormEditConfiguration'
import FormEditConfigurationExtended from '../forms/FormEditConfigurationExtended'
import FormExtendInventoryValidity from '../forms/FormExtendInventoryValidity'
import FormEditInventoryType from '../forms/FormEditInventoryType'
import FormEditPrinter from '../forms/FormEditPrinter'
import FormEditResourceType from '../forms/FormEditResourceType'
import FormEditWebhook from '../forms/FormEditWebhook'
import FormEditUser from '../forms/FormEditUser'
import FormResetPassword from '../forms/FormResetPassword'
import FormEditRenderOnUi from '../forms/FormEditRenderOnUi'
import FormEditSubscriptionEndPoint from '../forms/FormEditSubscriptionEndpoint'
import FormEditSubscriptionWorkflow from '../forms/FormEditSubscriptionWorkflow.jsx'

//store imports
import {deleteCompany, deleteCompanyClearError} from '../../stores/companies'
import {updateConfig, deleteConfig, deleteConfigFile} from '../../stores/configs'
import {validateCsv, exportCsv} from '../../stores/import_csv'
import {
  deleteOidcProviderAdmin,
  deleteOidcProviderAdminClearError,
  setOidcProviderAdmin,
} from '../../stores/oidc_providers'
import {
  deleteInventoryType,
  deleteInventoryTypeClearError,
  deleteInventory,
  deleteInventoryClearError,
} from '../../stores/inventories'
import {deleteIDFunctionWebPrinter} from '../../stores/printers'
import {
  deleteAccessProfileChild,
  deleteAccessProfileChildClearError,
  deleteAccessProfileType,
  deleteAccessProfileTypeClearError,
  deleteAccessProfile,
  deleteAccessProfileClearError,
} from '../../stores/access_profiles'
import {deleteUser, deleteUserClearError} from '../../stores/users'
import {
  putWorkflow,
  deleteWorkflow,
  deleteWorkflowClearError,
  putExecution,
  putExecutionClearError,
  putExecutionSuccess,
} from '../../stores/workflows'
import {updateSubscription} from '../../stores/subscriptions'
import InfoBoxFetch from '../InfoBoxFetch'
import {deleteForm, setFormdata} from '../../stores/forms'
import {getTimezonePreference} from '../../services/setPreferences'
import {deleteEmployee, deleteEmployeeFile, setEmployee} from '../../stores/employees'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {parseJSON} from '../../utils/helpers'
import {deletePerson} from '../../stores/persons'
import {ActionCell} from './TableComponents'
import FormEditPerson from '../forms/FormEditPerson'
import {deleteVisitor, deleteVisitorFile, setVisitor} from '../../stores/visitors'
import {DeleteFileCell} from '../wrappers/FilesWrapper'
import {deleteVisitation} from '../../stores/visitations'
import {deleteIdentityAccessProfile} from '../../stores/identities'
import {
  deleteEmployeesInventory,
  filterEmployeesInventoriesById,
  filterEmployeesInventoriesClearError,
  setEmployeesInventory,
} from '../../stores/employees_inventories'
import {
  deleteVisitorsInventory,
  filterVisitorsInventoriesById,
  filterVisitorsInventoriesClearError,
  setVisitorsInventory,
} from '../../stores/visitors_inventories'
import {IndeterminateCheckbox} from './ServerSideTable'
import {
  deleteAccessProfileIdentity,
  deleteAccessProfileIdentityClearError,
} from '../../stores/access_profiles_identities'
import FormExtendResourceIdentitiesValidity from '../forms/FormExtendResourceIdentitiesValidity'
import {
  deleteIdentity,
  deleteIdentityChild,
  deleteIdentityChildClearError,
  deleteIdentityClearError,
} from '../../stores/identity'
import DependencyView from '../Dependency/DependancyView'
import {
  childrenAccessorIdentities,
  childrenAccessorResources,
  parentAccessorIdentities,
  parentAccessorResources,
} from '../Dependency/consts'
import {getSrc} from '../../utils/image'

export const SelectCheckboxCell = ({row}) => (
  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
)

export function StatusPill({value}) {
  const status = value ? value.toLowerCase() : 'unknown'
  return (
    <span
      className={classNames(
        'leading-wide rounded-full px-3 py-1 text-xs font-bold uppercase shadow-sm',
        status.startsWith('active') ? 'bg-green-100 text-green-700' : null, // employees
        status.startsWith('inactive') ? 'bg-yellow-100 text-yellow-700' : null,
        status.startsWith('offline') ? 'bg-red-100 text-red-700' : null,
        status.startsWith('successful') ? 'bg-green-100 text-green-700' : null, // Configs
        status.startsWith('failed') ? 'bg-red-100 text-red-700' : null,
        status.startsWith('warning') ? 'bg-yellow-100 text-yellow-700' : null,
        status.startsWith('running') ? 'bg-yellow-100 text-green-700' : null, // test it

        status.startsWith('produced') ? 'bg-green-100 text-green-700' : null, // Card Status
        status.startsWith('requested') ? 'bg-yellow-100 text-yellow-700' : null,
        status.startsWith('production_failed') ? 'bg-red-100 text-red-700' : null,
        status.startsWith('unproduced') ? 'bg-gray-100 text-gray-700' : null,
        status.startsWith('ready') ? 'bg-gray-100 text-purple-700' : null,
        status.startsWith('blocked') ? 'bg-orange-100 text-orange-700' : null,
        status.startsWith('deleted') ? 'bg-orange-100 text-orange-700 line-through' : null,
        status.startsWith('cancelled') ? 'bg-orange-100 text-orange-700 line-through' : null, // executions
        status.startsWith('done') ? 'bg-green-100 text-green-700' : null,
        status.startsWith('pending') ? 'bg-yellow-100 text-yellow-700' : null,
        status.startsWith('broken') ? 'bg-gray-100 text-gray-700' : null,
      )}
    >
      {status}
    </span>
  )
}

export function LocationCell(props) {
  const {value} = props

  return Array.isArray(value) ? (
    <>{value?.map((location) => `${location?.description}, `)}</>
  ) : (
    <>{value?.description}</>
  )
}

export function CardOwnerAndProfileCell({value}) {
  return (
    <div className="text-sm font-medium text-gray-500">
      {value !== null ? value.person.full_name : 'Unassigned'}
    </div>
  )
}

export function Pill({value}) {
  const status = value ? 'true' : 'false'
  return (
    <span
      className={classNames(
        'pointer-events-none inline-block h-4 w-4 transform rounded-full shadow ring-0 transition duration-200 ease-in-out',
        status.startsWith('true') ? 'animate-pulse bg-green-700' : null,
        status.startsWith('false') ? 'animate-pulse bg-red-700' : null,
      )}
    ></span>
  )
}

export function FormEditCompanyCell({value, column, row}) {
  const {error, pending} = useSelector((state) => state.companies.delete_company)
  const {t} = useTranslation()
  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
          <DeletionWarning
            deleteFunction={deleteCompany}
            error={error}
            setError={deleteCompanyClearError}
            penidng={pending}
            text={t('confirmDeletion.Confirm')}
            message={t('confirmDeletion.DeleteCompany')}
            id={value}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <PencilIcon className="h-5 w-5 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('addCompanyForm.EditCompany')}>
          <FormEditCompany
            selectedItem={{
              value: value,
              label: row.original[column.nameAccessor],
              name: row.original[column.nameAccessor],
              street: row.original[column.streetAccessor],
              zip: row.original[column.zipAccessor],
              city: row.original[column.cityAccessor],
              state: row.original[column.stateAccessor],
              country: row.original[column.countryAccessor],
              phone: row.original[column.phoneAccessor],
              email: row.original[column.emailAccessor],
            }}
            notify={false}
          />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function FormEditFormCell({value, row}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  let formJson = parseJSON(row.original.form_json, {error: t('formBuilder.ParseError')})

  const {
    id: formId,
    name: formName,
    description: formDescription,
    location: {id: formDomain},
  } = row.original

  let formData = {formId, formName, formDescription, formDomain}
  if (formJson.error) {
    formData.formJsonString = row.original.form_json
    formData.formJson = {}
    formData.error = formJson.error
  } else {
    formData.formJson = formJson
  }

  const renderDynamicForm = () => (
    <DynamicRhfForm
      formConfig={formJson}
      onSubmit={(values) => alert(JSON.stringify(values, 0, 2))}
    />
  )

  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <DocumentIcon className="h-5 w-5 text-sky-400 hover:text-sky-500" />
        </ModalOpenButton>
        <ModalContentsBase title={formName}>
          {!!formJson.error && formJson.error}
          {!formJson.error && renderDynamicForm()}
        </ModalContentsBase>
      </Modal>
      <PencilIcon
        onClick={() => {
          dispatch(setFormdata(formData))
          navigate('/editform')
        }}
        className="h-5 w-5 text-sky-400 hover:text-sky-500"
      />
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
          <DeletionWarning
            deleteFunction={deleteForm}
            text={t('confirmDeletion.Confirm')}
            message={t('confirmDeletion.DeleteForm')}
            id={value}
          />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function FormEditConfigurationCell({row, column}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleEnable = (configId) => {
    dispatch(updateConfig(configId, {active: true}))
  }

  const handleDisable = (configId) => {
    dispatch(updateConfig(configId, {active: false}))
  }

  const execute = (configType, configId) => {
    if (configType === 'Import') {
      var formData = new FormData()
      formData.append('config_id', configId)
      dispatch(validateCsv('/api/v1/iec_interface/iec_csv', formData))
    } else {
      dispatch(exportCsv(`/api/v1/iec_interface/iec_csv?config_id=${configId}`))
    }
  }
  return (
    <div className="flex">
      {row.original[column.activeAccessor] === false ? (
        <LockOpenIcon
          className="h-5 w-5 text-red-400 hover:text-red-500"
          onClick={() => handleEnable(row.original[column.idAccessor])}
        />
      ) : (
        <LockClosedIcon
          className="h-5 w-5 text-green-400 hover:text-green-500"
          onClick={() => handleDisable(row.original[column.idAccessor])}
        />
      )}
      <button
        className="h-5 w-5 text-gray-400 hover:text-gray-500 disabled:text-gray-200"
        onClick={() => execute(row.original[column.typeAccessor], row.original[column.idAccessor])}
        disabled={!row.original[column.activeAccessor]}
      >
        <PlayIcon />
      </button>
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
          <DeletionWarning
            deleteFunction={deleteConfig}
            text={t('confirmDeletion.Confirm')}
            id={row.original[column.idAccessor]}
            message={t('confirmDeletion.DeleteConfig')}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <DocumentRemoveIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
          <DeletionWarning
            deleteFunction={deleteConfigFile}
            text={t('confirmDeletion.Confirm')}
            id={row.original[column.idAccessor]}
            message={t('confirmDeletion.DeleteFile')}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <PencilIcon className="h-5 w-5 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('configureInterfaces.EditDescription')}>
          <FormEditConfiguration
            description={row.original[column.descriptionAccessor]}
            configId={row.original[column.idAccessor]}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <BeakerIcon className="h-5 w-5 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('configureInterfaces.EditConfiguration')}>
          <FormEditConfigurationExtended
            description={row.original[column.descriptionAccessor]}
            configId={row.original[column.idAccessor]}
            config_type={row.original[column.typeAccessor]}
            status={row.original[column.statusAccessor]}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <ClipboardIcon className="h-5 w-5 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('configureInterfaces.Summary')}>
          <InfoBox message={row.original[column.summaryAccessor]} />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function TwoRowCell({value, column, row}) {
  return (
    <div className="flex items-center">
      <div>
        <div className="text-sm font-medium text-gray-900">{value}</div>
        <div className="text-sm text-gray-500">{row.original[column.secondAccessor]}</div>
      </div>
    </div>
  )
}

export function LinkCell({value, column, row}) {
  return (
    <a href={`${row.original[column.secondAccessor]}`} target="_blank" rel="noopener noreferrer">
      <div className="flex">
        <DocumentDownloadIcon className="h-5 w-5 text-red-500 hover:text-red-400" />
        <span className="text-sm text-gray-500 hover:text-red-400">{value}</span>
      </div>
    </a>
  )
}

export function LinkForwardCell({value, column, row}) {
  const href = row.original[column.secondAccessor]

  return href ? (
    <a href={href} rel="noopener noreferrer">
      <div className="flex">
        <LinkIcon className="h-5 w-5 text-blue-500 hover:text-blue-400" />
        <span className="text-sm text-gray-500 hover:text-blue-400">{value}</span>
      </div>
    </a>
  ) : (
    <span className="text-sm text-gray-500">{value}</span>
  )
}

export function LinkInventoryCell({value, column, row}) {
  const id = row.original[column.secondAccessor]

  return id ? (
    <a href={`/inventories/${id}`} rel="noopener noreferrer">
      <div className="flex">
        <LinkIcon className="h-5 w-5 text-blue-500 hover:text-blue-400" />
        <span className=" text-gray-500 hover:text-blue-400">{value}</span>
      </div>
    </a>
  ) : (
    <span className="text-gray-500">{value}</span>
  )
}

export function LinkResourceCell({value, column, row}) {
  const id = row.original[column.secondAccessor]

  return id ? (
    <a href={`/resources/${id}`} rel="noopener noreferrer">
      <div className="flex">
        <LinkIcon className="h-5 w-5 text-blue-500 hover:text-blue-400" />
        <span className=" text-gray-500 hover:text-blue-400">{value}</span>
      </div>
    </a>
  ) : (
    <span className="text-gray-500">{value}</span>
  )
}

export function BooleanCell({value}) {
  const status = value === true ? 'True' : 'False'
  return (
    <span
      className={classNames(
        'leading-wide rounded-full px-3 py-1 text-xs font-bold uppercase shadow-sm',
        status === 'True' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700',
      )}
    >
      {status}
    </span>
  )
}

export function IdentityProviderActionsCell({row, column}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleEnable = (providerId) => {
    dispatch(setOidcProviderAdmin(providerId, {action: 'activate'}))
  }

  const handleDisable = (providerId) => {
    dispatch(setOidcProviderAdmin(providerId, {action: 'deactivate'}))
  }

  return (
    <div className="flex">
      {row.original[column.activeAccessor] === false ? (
        <LockOpenIcon
          className="h-5 w-5 text-red-400 hover:text-red-500"
          onClick={() => handleEnable(row.original[column.idAccessor])}
        />
      ) : (
        <LockClosedIcon
          className="h-5 w-5 text-green-400 hover:text-green-500"
          onClick={() => handleDisable(row.original[column.idAccessor])}
        />
      )}
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
          <DeletionWarning
            deleteFunction={deleteOidcProviderAdmin}
            setError={deleteOidcProviderAdminClearError}
            text={t('confirmDeletion.Confirm')}
            id={row.original[column.idAccessor]}
            message={t('confirmDeletion.DeleteProvider')}
            disabled={!row.original[column.activeAccessor]}
          />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function IdentityProviderCustomCell({row, column}) {
  return (
    <div className="flex">
      <p className="text-sm text-gray-500">{`${row.original[column.nameAccessor]}`}</p>
    </div>
  )
}

export function IdentityProviderTwoRowCellLink({value, column, row}) {
  // TODO: if secondAccessor is nested like (type.name instead of type) row.original[column.secondAccessor] not taking
  // it.
  return (
    <Link to={`/management/identity_providers/oidc/${row.original[column.idAccessor]}`}>
      <div className="flex items-center">
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{value}</div>
          <div className="text-sm text-gray-500">{row.original[column.secondAccessor]}</div>
        </div>
      </div>
    </Link>
  )
}

export function CardsTwoRowCellLink({value, column, row}) {
  return (
    <Link to={`/cards/${row.original[column.idAccessor]}`}>
      <div className="flex items-center">
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{value}</div>
        </div>
      </div>
    </Link>
  )
}

export function TwoRowCellLink({value, column, row}) {
  // TODO: if secondAccessor is nested like (type.name instead of type) row.original[column.secondAccessor] not taking
  // it.
  return (
    <Link to={`${row.original[column.idAccessor]}`}>
      <div className="flex items-center">
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{value}</div>
          <div className="text-sm text-gray-500">{row.original[column.secondAccessor]}</div>
        </div>
      </div>
    </Link>
  )
}

export function DateCell({value}) {
  const {t} = useTranslation()
  const timezone = getTimezonePreference()
  return (
    <div className="text-sm font-medium text-gray-500">
      {!!value && formatInTimeZone(new Date(value), timezone, t('date.Format'))}
    </div>
  )
}

export function CustomAttributesCell({value}) {
  return <div className="text-sm font-medium text-gray-500">{JSON.stringify(value)}</div>
}

export function InventoriesEmployeeActionsCell({row}) {
  const {t} = useTranslation()

  const filter_employees_inventories_pending = useSelector(
    (state) => state.employees_inventories.filter_employees_inventories.pending,
  )
  const filter_employees_inventories_error = useSelector(
    (state) => state.employees_inventories.filter_employees_inventories.error,
  )
  const filter_employees_inventories = useSelector(
    (state) => state.employees_inventories.filter_employees_inventories.success,
  )
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  return (
    <div className="flex">
      {permissions.deleteInventory && (
        <Modal>
          <ModalOpenButton>
            <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
          </ModalOpenButton>
          <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
            <DeletionWarning
              deleteFunction={deleteEmployeesInventory}
              text={t('confirmDeletion.Confirm')}
              id={row.original.id}
              message={t('confirmDeletion.DeleteInventory')}
            />
          </ModalContentsBase>
        </Modal>
      )}
      {permissions.updateInventory && (
        <Modal>
          <ModalOpenButton>
            <CalendarIcon className="h-5 w-5 text-lime-400 hover:text-lime-500" />
          </ModalOpenButton>
          <ModalContentsBase title={t('attachInventoryForm.ExtendDate')}>
            <FormExtendInventoryValidity
              data={row.original}
              setIdentityInventory={setEmployeesInventory}
              filterIdentityInventoryClearError={filterEmployeesInventoriesClearError}
              filteredIdentityInventoriesPending={filter_employees_inventories_pending}
              filteredIdentityInventoriesError={filter_employees_inventories_error}
              filteredIdentityInventories={filter_employees_inventories}
              filterIdentityInventoryById={filterEmployeesInventoriesById}
            />
          </ModalContentsBase>
        </Modal>
      )}
    </div>
  )
}

export function InventoriesVisitorActionsCell({row}) {
  const {t} = useTranslation()

  const filter_visitors_inventories_pending = useSelector(
    (state) => state.visitors_inventories.filter_visitors_inventories.pending,
  )
  const filter_visitors_inventories_error = useSelector(
    (state) => state.visitors_inventories.filter_visitors_inventories.error,
  )
  const filter_visitors_inventories = useSelector(
    (state) => state.visitors_inventories.filter_visitors_inventories.success,
  )
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  return (
    <div className="flex">
      {permissions.deleteInventory && (
        <Modal>
          <ModalOpenButton>
            <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
          </ModalOpenButton>
          <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
            <DeletionWarning
              deleteFunction={deleteVisitorsInventory}
              text={t('confirmDeletion.Confirm')}
              id={row.original.id}
              message={t('confirmDeletion.DeleteInventory')}
            />
          </ModalContentsBase>
        </Modal>
      )}
      {permissions.updateInventory && (
        <Modal>
          <ModalOpenButton>
            <CalendarIcon className="h-5 w-5 text-lime-400 hover:text-lime-500" />
          </ModalOpenButton>
          <ModalContentsBase title={t('attachInventoryForm.ExtendDate')}>
            <FormExtendInventoryValidity
              data={row.original}
              setIdentityInventory={setVisitorsInventory}
              filterIdentityInventoryClearError={filterVisitorsInventoriesClearError}
              filteredIdentityInventoriesPending={filter_visitors_inventories_pending}
              filteredIdentityInventoriesError={filter_visitors_inventories_error}
              filteredIdentityInventories={filter_visitors_inventories}
              filterIdentityInventoryById={filterVisitorsInventoriesById}
            />
          </ModalContentsBase>
        </Modal>
      )}
    </div>
  )
}

export function FileEmployeeActionCell({cell: {value}}) {
  const {id} = useParams()
  const unassign_file_pending = useSelector((state) => state.employees.delete_employee_file.pending)

  return (
    <DeleteFileCell
      value={value}
      entityId={id}
      unassignFileToIdentityPending={unassign_file_pending}
      deleteFunc={deleteEmployeeFile}
    />
  )
}

export function FileVisitorActionCell({cell: {value}}) {
  const {id} = useParams()
  const unassign_file_pending = useSelector((state) => state.visitors.delete_visitor_file.pending)

  return (
    <DeleteFileCell
      value={value}
      entityId={id}
      unassignFileToIdentityPending={unassign_file_pending}
      deleteFunc={deleteVisitorFile}
    />
  )
}

export function VisitorActionCell({cell: {value}}) {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  return (
    <ActionCell
      value={value}
      deleteFunc={deleteVisitor}
      message={t('confirmDeletion.DeleteVisitor')}
      permission={permissions.deleteVisitor}
    />
  )
}

export function MeetingActionCell({cell: {value}}) {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  return (
    <ActionCell
      value={value}
      deleteFunc={deleteVisitation}
      message={t('confirmDeletion.DeleteVisitation')}
      permission={permissions.deleteVisitation}
    />
  )
}

export function EmployeeActionCell({cell: {value}}) {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  return (
    <ActionCell
      value={value}
      deleteFunc={deleteEmployee}
      message={t('confirmDeletion.DeleteEmployee')}
      permission={permissions.deleteEmployee}
    />
  )
}

export function PersonActionCell({cell: {value}, row}) {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  return (
    <div className="flex">
      <ActionCell
        value={value}
        deleteFunc={deletePerson}
        message={t('confirmDeletion.DeletePerson')}
        permission={permissions.deletePerson}
      />
      <Modal>
        <ModalOpenButton>
          <PencilIcon className="h-5 w-5 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('addPersonForm.Edit')}>
          <FormEditPerson selectedItem={row.original} />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function InventoryTypeAvatarCell({value, column, row}) {
  // Not passing the nested prop here
  // src={row.original[column.imgAccessor]}
  //const sourcer = row.original[column.emailAccessor]//row.original[column.imgAccessor]
  //const sourcer = row.original[column.locationaccessor]
  return (
    <div className="flex items-center">
      <div className="h-10 w-10 flex-shrink-0">
        <img
          className="h-10 w-10 rounded-full object-contain"
          src={getSrc(
            row.original[column.imgAccessor].current_id !== null
              ? row.original[column.imgAccessor].thumbnail
              : InventoryPhoto,
          )}
          alt=""
        />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900">{value}</div>
        <div className="text-sm text-gray-500">{row.original[column.secondAccessor]}</div>
      </div>
    </div>
  )
}

export function InventoryTypeActionsCell({row, column}) {
  const {t} = useTranslation()
  const delete_inventoryType_error = useSelector(
    (state) => state.inventories.delete_inventory_type.error,
  )
  const delete_inventoryType_pending = useSelector(
    (state) => state.inventories.delete_inventory_type.pending,
  )
  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
          <DeletionWarning
            deleteFunction={deleteInventoryType}
            setError={deleteInventoryTypeClearError}
            error={delete_inventoryType_error}
            pending={delete_inventoryType_pending}
            id={row.original[column.idAccessor]}
            text={t('confirmDeletion.Confirm')}
            message={t('confirmDeletion.DeleteInventoryType')}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <PencilIcon className="h-5 w-5 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('addInventoryForm.EditInventory')}>
          <FormEditInventoryType selectedItem={row.original} />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function FormEditPrinterActionsCell({row, column}) {
  const {t} = useTranslation()
  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.Confirm')}>
          <DeletionWarning
            deleteFunction={deleteIDFunctionWebPrinter}
            text={t('confirmDeletion.Confirm')}
            id={row.original[column.idAccessor]}
            message={t('confirmDeletion.DeletePrinter')}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <PencilIcon className="h-5 w-5 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('configurePrinters.EditPrinterNameForIDF')}>
          <FormEditPrinter
            printerId={row.original[column.idAccessor]}
            printerName={row.original[column.nameAccessor]}
          />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function FormEditResourceTypeActionsCell({row, column}) {
  const {t} = useTranslation()
  const delete_access_profile_type_pending = useSelector(
    (state) => state.access_profiles.delete_access_profile_type.pending,
  )
  const delete_access_profile_type_error = useSelector(
    (state) => state.access_profiles.delete_access_profile_type.error,
  )

  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.Confirm')}>
          <DeletionWarning
            deleteFunction={deleteAccessProfileType}
            setError={deleteAccessProfileTypeClearError}
            id={row.original[column.idAccessor]}
            error={delete_access_profile_type_error}
            pending={delete_access_profile_type_pending}
            text={t('confirmDeletion.Confirm')}
            message={t('confirmDeletion.DeleteResourceType')}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <PencilIcon className="h-5 w-5 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('addResourceForm.ResourceEdit')}>
          <FormEditResourceType
            selectedItem={{
              value: row.original[column.idAccessor],
              label: row.original[column.nameAccessor],
            }}
          />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function InventoryTableActionsCell({value}) {
  const {t} = useTranslation()
  const deleteInventoryPending = useSelector((state) => state.inventories.delete_inventory.pending)
  const deleteInventoryError = useSelector((state) => state.inventories.delete_inventory.error)
  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.Confirm')}>
          <DeletionWarning
            deleteFunction={deleteInventory}
            setError={deleteInventoryClearError}
            pending={deleteInventoryPending}
            error={deleteInventoryError}
            id={value}
            text={t('confirmDeletion.Confirm')}
            message={t('confirmDeletion.DeleteInventory')}
          />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function ResourceTableActionsCell({value}) {
  const {t} = useTranslation()
  const deleteResourcePending = useSelector(
    (state) => state.access_profiles.delete_access_profile.pending,
  )
  const deleteResourceError = useSelector(
    (state) => state.access_profiles.delete_access_profile.error,
  )
  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.Confirm')}>
          <DeletionWarning
            deleteFunction={deleteAccessProfile}
            setError={deleteAccessProfileClearError}
            pending={deleteResourcePending}
            error={deleteResourceError}
            id={value}
            text={t('confirmDeletion.Confirm')}
            message={t('confirmDeletion.DeleteResource')}
          />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function ResourceIdentitiesTableActionsCell({value}) {
  const {t} = useTranslation()
  const {pending, error} = useSelector((state) => state.access_profiles_identities.delete)

  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.Confirm')}>
          <DeletionWarning
            deleteFunction={deleteAccessProfileIdentity}
            setError={deleteAccessProfileIdentityClearError}
            pending={pending}
            error={error}
            id={value}
            text={t('confirmDeletion.Confirm')}
            message={t('confirmDeletion.DeleteIdentity')}
          />
        </ModalContentsBase>
      </Modal>

      <Modal>
        <ModalOpenButton>
          <CalendarIcon className="h-5 w-5 text-lime-400 hover:text-lime-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('attachInventoryForm.ExtendDate')}>
          <FormExtendResourceIdentitiesValidity id={value} />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function ResourceIdentitiesChildrenTableActionsCell({value}) {
  const {t} = useTranslation()
  const {id} = useParams()
  const dispatch = useDispatch()
  const {pending, error} = useSelector((state) => state.access_profiles.delete_access_profile_child)

  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.Confirm')}>
          <DeletionWarning
            deleteFunction={deleteAccessProfileChild}
            setError={deleteAccessProfileChildClearError}
            pending={pending}
            error={error}
            id={value}
            onSubmit={() => dispatch(deleteAccessProfileChild(id, value))}
            text={t('confirmDeletion.Confirm')}
            message={t('confirmDeletion.DeleteResource')}
          />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function FormEditWebhookActionsCell({row}) {
  const {t} = useTranslation()
  return (
    <>
      <Modal>
        <ModalOpenButton>
          <button className="inline-flex cursor-pointer items-center rounded-md border border-transparent bg-purple-100 px-3 py-2 text-sm font-medium leading-4 text-purple-700 hover:bg-purple-200 focus:outline-none focus:ring-2  focus:ring-lime-500 focus:ring-offset-2 disabled:opacity-50">
            {row.original.form !== null ? `${row.original.form.name}` : t('workflows.Attach')}
          </button>
        </ModalOpenButton>
        <ModalContentsBase title={t('workflows.EditWorkflow')}>
          <FormEditWebhook id={row.original.id} />
        </ModalContentsBase>
      </Modal>
    </>
  )
}

export function FormEditSubscriptionEndpointsCell({row, value}) {
  const {t} = useTranslation()
  return (
    <>
      <Modal>
        <ModalOpenButton>
          <button className="inline-flex cursor-pointer items-center rounded-md border border-transparent bg-lime-100 px-3 py-2 text-sm font-medium leading-4 text-lime-700 hover:bg-lime-200 focus:outline-none focus:ring-2  focus:ring-lime-500 focus:ring-offset-2 disabled:opacity-50">
            {row.original.endpoint_selector !== null
              ? t(`subscriptions.${value}`)
              : t('subscriptions.Attach')}
          </button>
        </ModalOpenButton>
        <ModalContentsBase title={t('subscriptions.EditEndPoint')}>
          <FormEditSubscriptionEndPoint id={row.original.id} />
        </ModalContentsBase>
      </Modal>
    </>
  )
}

export function FormEditSubscriptionWorkflowCell({row}) {
  const {t} = useTranslation()
  return (
    <>
      <Modal>
        <ModalOpenButton>
          <button className="inline-flex cursor-pointer items-center rounded-md border border-transparent bg-lime-100 px-3 py-2 text-sm font-medium leading-4 text-lime-700 hover:bg-lime-200 focus:outline-none focus:ring-2  focus:ring-lime-500 focus:ring-offset-2 disabled:opacity-50">
            {row.original.workflow !== null
              ? `${row.original.workflow.name}`
              : t('subscriptions.Attach')}
          </button>
        </ModalOpenButton>
        <ModalContentsBase title={t('subscriptions.EditWorkflow')}>
          <FormEditSubscriptionWorkflow id={row.original.id} />
        </ModalContentsBase>
      </Modal>
    </>
  )
}

export function FormEditUserActionsCell({row, column}) {
  const value = row.original[column.idAccessor]
  const {t} = useTranslation()
  const delete_user_error = useSelector((state) => state.users.delete_user.error)
  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('confirmDeletion.Confirm')}>
          <DeletionWarning
            deleteFunction={deleteUser}
            id={value}
            text={t('confirmDeletion.Confirm')}
            error={delete_user_error}
            setError={deleteUserClearError}
            message={t('confirmDeletion.DeleteUser')}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <PencilIcon className="h-5 w-5 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('userInfo.Edit')}>
          <FormEditUser userId={value} data={row.original} />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <KeyIcon className="h-5 w-5 text-green-400 hover:text-green-500" />
        </ModalOpenButton>
        <ModalContentsBase title={t('userInfo.ResetUserPassword')}>
          <FormResetPassword userId={value} />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export function RenderOnUiCell({row}) {
  const {t} = useTranslation()
  return (
    <>
      <Modal>
        <ModalOpenButton>
          <button className="inline-flex cursor-pointer items-center rounded-md border border-transparent bg-cyan-100 px-3 py-2 text-sm font-medium leading-4 text-cyan-700 hover:bg-cyan-200 focus:outline-none focus:ring-2  focus:ring-lime-500 focus:ring-offset-2 disabled:opacity-50">
            {row.original.render_on_ui !== null
              ? `${row.original.render_on_ui}`
              : t('workflows.SelectRenderAt')}
          </button>
        </ModalOpenButton>
        <ModalContentsBase title={t('workflows.EditWorkflow')}>
          <FormEditRenderOnUi id={row.original.id} />
        </ModalContentsBase>
      </Modal>
    </>
  )
}

export function SelectEndpointCell({row}) {
  const {t} = useTranslation()
  return (
    <>
      <Modal>
        <ModalOpenButton>
          <button className="inline-flex cursor-pointer items-center rounded-md border border-transparent bg-lime-100 px-3 py-2 text-sm font-medium leading-4 text-lime-700 hover:bg-lime-200 focus:outline-none focus:ring-2  focus:ring-lime-500 focus:ring-offset-2 disabled:opacity-50">
            {row.original.render_on_ui !== null
              ? `${row.original.render_on_ui}`
              : t('workflows.SelectRenderAt')}
          </button>
        </ModalOpenButton>
        <ModalContentsBase title={t('workflows.EditWorkflow')}>
          <FormEditRenderOnUi id={row.original.id} />
        </ModalContentsBase>
      </Modal>
    </>
  )
}

export function EditSubscriptionActionCell({row}) {
  const dispatch = useDispatch()
  return (
    <div className="flex">
      <SwitchWrapperAction
        checked={row.original.active}
        onChange={() => {
          let action
          if (row.original.active) {
            action = 'deactivate'
          } else {
            action = 'activate'
          }
          dispatch(updateSubscription(row.original.id, {action: action}))
        }}
      />
    </div>
  )
}

export function EditSubscriptionTakeOverCell({row}) {
  const dispatch = useDispatch()
  return (
    <div className="flex">
      <SwitchWrapperAction
        checked={row.original.take_over}
        onChange={() => {
          let take_over
          take_over = !row.original.take_over
          dispatch(updateSubscription(row.original.id, {take_over: take_over}))
        }}
      />
    </div>
  )
}

export function EditWorkflowActionCell({row}) {
  const dispatch = useDispatch()

  return (
    <div className="flex">
      <SwitchWrapperAction
        checked={row.original.active}
        onChange={() => {
          let action
          if (row.original.active) {
            action = 'deactivate'
          } else {
            action = 'activate'
          }
          dispatch(putWorkflow(row.original.id, {action: action}))
        }}
      />
    </div>
  )
}

export function EditEmployeeStatusCell({value, row}) {
  const dispatch = useDispatch()
  return (
    <div className="flex justify-center">
      <SwitchWrapperAction
        checked={value === 1}
        onChange={() => {
          let action
          if (value === 1) {
            action = 'deactivate'
          } else {
            action = 'activate'
          }
          dispatch(setEmployee(row.original.id, {action: action}))
        }}
      />
    </div>
  )
}

export function EditVisitorStatusCell({value, row}) {
  const dispatch = useDispatch()
  return (
    <div className="flex justify-center">
      <SwitchWrapperAction
        checked={value === 1}
        onChange={() => {
          let action
          if (value === 1) {
            action = 'deactivate'
          } else {
            action = 'activate'
          }
          dispatch(setVisitor(row.original.id, {action: action}))
        }}
      />
    </div>
  )
}

export function EditWorkflowAuthenticatedUseActionCell({value, row}) {
  const dispatch = useDispatch()
  return (
    <div className="flex">
      <SwitchWrapperAction
        checked={value}
        onChange={() => {
          dispatch(putWorkflow(row.original.id, {unauthenticated_use: !value}))
        }}
      />
    </div>
  )
}

export function AvatarCell({value, row}) {
  return (
    <Link to={`${row.original.id}`}>
      <div className="flex items-center">
        <div className="h-10 w-10 flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full object-contain"
            src={getSrc(
              row.original.photos.current_id !== null
                ? row.original.photos.thumbnail
                : ProfilePhoto,
            )}
            alt=""
          />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{value}</div>
          <div className="text-sm text-gray-500">{row.original.email}</div>
        </div>
      </div>
    </Link>
  )
}

export function ResourcesEmployeeActionCell({cell: {value}}) {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  return (
    <ActionCell
      value={value}
      deleteFunc={deleteIdentityAccessProfile}
      message={t('confirmDeletion.DeleteResource')}
      permission={permissions.deleteAccessProfile}
    />
  )
}

export function TodosActionsCell({row}) {
  const {t} = useTranslation()
  const {success, pending, error} = useSelector((state) => state.workflows.put_execution)
  return (
    <div className="flex">
      <Modal>
        <ModalOpenButton>
          <button className="inline-flex cursor-pointer items-center rounded-md border border-transparent bg-lime-100 px-3 py-2 text-sm font-medium leading-4 text-lime-700 hover:bg-lime-200 focus:outline-none focus:ring-2  focus:ring-lime-500 focus:ring-offset-2 disabled:opacity-50">
            {t('workflows.CancelExecution')}
          </button>
        </ModalOpenButton>
        <ModalContentsBase>
          <DeletionWarning
            id={row.original.id}
            deleteFunction={putExecution}
            values={{action: 'cancelled'}}
            pending={pending}
            error={error}
            setError={putExecutionClearError}
            success={success}
            setSuccess={putExecutionSuccess}
            message={t('workflows.CancelExecutionMessage')}
            text={t('workflows.CancelExecution')}
          />
        </ModalContentsBase>
      </Modal>
      <Modal>
        <ModalOpenButton>
          <QuestionMarkCircleIcon className="ml-2 mt-1 h-6 w-6 text-blue-400 hover:text-blue-500" />
        </ModalOpenButton>
        <ModalContentsBase>
          <InfoBoxFetch id={row.original.id} />
        </ModalContentsBase>
      </Modal>
    </div>
  )
}

export const DeleteWorkflowCell = ({value}) => {
  const {t} = useTranslation()
  return (
    <Modal>
      <ModalOpenButton>
        <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
      </ModalOpenButton>
      <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
        <DeletionWarning
          deleteFunction={deleteWorkflow}
          setError={deleteWorkflowClearError}
          text={t('confirmDeletion.Confirm')}
          message={t('confirmDeletion.DeleteWorkflow')}
          id={value}
        />
      </ModalContentsBase>
    </Modal>
  )
}

export function TreeGraphTableCell(props) {
  const {t} = useTranslation()
  const {
    value: grandChildren,
    row: {original: root},
    column: {id: accessor},
  } = props
  const {success: currentResource, error: currentResourceError} = useSelector(
    (state) => state.access_profiles.get_access_profile,
  )
  const {success: currentIdentity, error: currentIdentityError} = useSelector(
    (state) => state.identity.get_identity,
  )
  const isParentDeps = accessor.includes('parent')
  const isIdentity = accessor.includes('identities')
  const current = isIdentity ? currentIdentity : currentResource
  const error = isIdentity ? currentIdentityError : currentResourceError
  const childrenAccessor = isIdentity ? childrenAccessorIdentities : childrenAccessorResources
  const parentAccessor = isIdentity ? parentAccessorIdentities : parentAccessorResources

  if (!current) {
    return null
  }
  delete current[childrenAccessor]
  delete current[parentAccessor]

  return (
    <DependencyView
      error={error}
      childrenAccessor={childrenAccessor}
      children={isParentDeps ? [current] : grandChildren}
      parents={isParentDeps ? grandChildren : [current]}
      text={t(isParentDeps ? 'resource.DependenciesParents' : 'resource.DependenciesChildren')}
      parentAccessor={parentAccessor}
      root={root}
    />
  )
}

export function IdentitiesTableActionsCell({row}) {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const {error, pending} = useSelector((state) => state.identity.delete_identity)

  return (
    <div className="flex">
      {permissions.deleteIdentity && (
        <Modal>
          <ModalOpenButton>
            <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
          </ModalOpenButton>
          <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
            <DeletionWarning
              deleteFunction={deleteIdentity}
              text={t('confirmDeletion.Confirm')}
              id={row.original.id}
              message={t('confirmDeletion.DeleteIdentity')}
              error={error}
              setError={deleteIdentityClearError}
              pending={pending}
            />
          </ModalContentsBase>
        </Modal>
      )}
    </div>
  )
}

export function IdentitiesChildrenTableActionsCell({value}) {
  const {t} = useTranslation()
  const {id} = useParams()
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const {pending, error} = useSelector((state) => state.identity.delete_identity_child)

  return (
    <div className="flex">
      {permissions.deleteIdentity && (
        <Modal>
          <ModalOpenButton>
            <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
          </ModalOpenButton>
          <ModalContentsBase title={t('confirmDeletion.Confirm')}>
            <DeletionWarning
              deleteFunction={deleteIdentityChild}
              setError={deleteIdentityChildClearError}
              pending={pending}
              error={error}
              id={value}
              onSubmit={() => dispatch(deleteIdentityChild(id, value))}
              text={t('confirmDeletion.Confirm')}
              message={t('confirmDeletion.DeleteIdentityChild')}
            />
          </ModalContentsBase>
        </Modal>
      )}
    </div>
  )
}

export function IdentitiesEmployeeActionsCell(props) {
  const {value} = props
  const {t} = useTranslation()

  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const {pending, error} = useSelector((state) => state.identity.delete_identity_child)
  const {success} = useSelector((state) => state.identity.get_identity)

  return (
    <div className="flex">
      {!!success?.id && permissions.deleteIdentity && (
        <>
          <Modal>
            <ModalOpenButton>
              <TrashIcon className="h-5 w-5 text-red-400 hover:text-red-500" />
            </ModalOpenButton>
            <ModalContentsBase title={t('confirmDeletion.Confirm')}>
              <DeletionWarning
                deleteFunction={deleteIdentityChild}
                setError={deleteIdentityChildClearError}
                pending={pending}
                error={error}
                id={value}
                onSubmit={() => dispatch(deleteIdentityChild(value, success?.id))}
                text={t('confirmDeletion.Confirm')}
                message={t('confirmDeletion.DeleteIdentityChildEmployee')}
              />
            </ModalContentsBase>
          </Modal>
        </>
      )}
    </div>
  )
}
