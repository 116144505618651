import axios from '../services/api'
import {createNotification} from './notifications'
import I18n from '../i18n'

// Types
const GET_VISITORS_INVENTORIES_PENDING =
  'idfunctiondb/visitors_inventories/GET_VISITORS_INVENTORIES_PENDING'
const GET_VISITORS_INVENTORIES_SUCCESS =
  'idfunctiondb/visitors_inventories/GET_VISITORS_INVENTORIES_SUCCESS'
const GET_VISITORS_INVENTORIES_ERROR =
  'idfunctiondb/visitors_inventories/GET_VISITORS_INVENTORIES_ERROR'
const GET_VISITORS_INVENTORIES_CLEAR_ERROR =
  'idfunctiondb/visitors_inventories/GET_VISITORS_INVENTORIES_CLEAR_ERROR'
const GET_VISITORS_INVENTORY_PENDING =
  'idfunctiondb/visitors_inventories/GET_VISITORS_INVENTORY_PENDING'
const GET_VISITORS_INVENTORY_SUCCESS =
  'idfunctiondb/visitors_inventories/GET_VISITORS_INVENTORY_SUCCESS'
const GET_VISITORS_INVENTORY_ERROR =
  'idfunctiondb/visitors_inventories/GET_VISITORS_INVENTORY_ERROR'

const CREATE_VISITORS_INVENTORY_PENDING =
  'idfunctiondb/visitors_inventories/CREATE_VISITORS_INVENTORY_PENDING'
const CREATE_VISITORS_INVENTORY_SUCCESS =
  'idfunctiondb/visitors_inventories/CREATE_VISITORS_INVENTORY_SUCCESS'
const CREATE_VISITORS_INVENTORY_ERROR =
  'idfunctiondb/visitors_inventories/CREATE_VISITORS_INVENTORY_ERROR'
const CREATE_VISITORS_INVENTORY_CLEAR_ERROR =
  'idfunctiondb/visitors_inventories/CREATE_VISITORS_INVENTORY_CLEAR_ERROR'

const SET_VISITORS_INVENTORY_PENDING =
  'idfunctiondb/visitors_inventories/SET_VISITORS_INVENTORY_PENDING'
const SET_VISITORS_INVENTORY_SUCCESS =
  'idfunctiondb/visitors_inventories/SET_VISITORS_INVENTORY_SUCCESS'
const SET_VISITORS_INVENTORY_ERROR =
  'idfunctiondb/visitors_inventories/SET_VISITORS_INVENTORY_ERROR'

const DELETE_VISITORS_INVENTORY_PENDING =
  'idfunctiondb/visitors_inventories/DELETE_VISITORS_INVENTORY_PENDING'
const DELETE_VISITORS_INVENTORY_SUCCESS =
  'idfunctiondb/visitors_inventories/DELETE_VISITORS_INVENTORY_SUCCESS'
const DELETE_VISITORS_INVENTORY_ERROR =
  'idfunctiondb/visitors_inventories/DELETE_VISITORS_INVENTORY_ERROR'

const FILTER_VISITORS_INVENTORIES_PENDING =
  'idfunctiondb/visitors_inventories/FILTER_VISITORS_INVENTORIES_PENDING'
const FILTER_VISITORS_INVENTORIES_SUCCESS =
  'idfunctiondb/visitors_inventories/FILTER_VISITORS_INVENTORIES_SUCCESS'
const FILTER_VISITORS_INVENTORIES_ERROR =
  'idfunctiondb/visitors_inventories/FILTER_VISITORS_INVENTORIES_ERROR'
const FILTER_VISITORS_INVENTORIES_CLEAR_ERROR =
  'idfunctiondb/visitors_inventories/FILTER_VISITORS_INVENTORIES_CLEAR_ERROR'
// Initial
const initialState = {
  get_visitors_inventories: {
    success: [],
    pending: false,
    error: null,
  },

  get_visitors_inventory: {
    success: [],
    pending: false,
    error: null,
  },

  create_visitors_inventory: {
    success: [],
    pending: false,
    error: null,
  },

  set_visitors_inventory: {
    success: [],
    pending: false,
    error: null,
  },

  delete_visitors_inventory: {
    success: null,
    pending: false,
    error: null,
  },

  filter_visitors_inventories: {
    success: [],
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_VISITORS_INVENTORIES_PENDING:
      return {
        ...state,
        get_visitors_inventories: {
          ...state.get_visitors_inventories,
          pending: true,
        },
      }
    case GET_VISITORS_INVENTORIES_SUCCESS:
      return {
        ...state,
        get_visitors_inventories: {
          ...state.get_visitors_inventories,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITORS_INVENTORIES_ERROR:
      return {
        ...state,
        get_visitors_inventories: {
          ...state.get_visitors_inventories,
          error: action.payload,
          pending: false,
        },
      }
    case GET_VISITORS_INVENTORIES_CLEAR_ERROR:
      return {
        ...state,
        get_visitors_inventories: {
          ...state.get_visitors_inventories,
          error: null,
          pending: false,
        },
      }
    case GET_VISITORS_INVENTORY_PENDING:
      return {
        ...state,
        get_visitors_inventory: {
          ...state.get_visitors_inventory,
          pending: true,
        },
      }
    case GET_VISITORS_INVENTORY_SUCCESS:
      return {
        ...state,
        get_visitors_inventory: {
          ...state.get_visitors_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITORS_INVENTORY_ERROR:
      return {
        ...state,
        get_visitors_inventory: {
          ...state.get_visitors_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITORS_INVENTORY_PENDING:
      return {
        ...state,
        create_visitors_inventory: {
          ...state.create_visitors_inventory,
          pending: true,
        },
      }
    case CREATE_VISITORS_INVENTORY_SUCCESS:
      return {
        ...state,
        create_visitors_inventory: {
          ...state.create_visitors_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITORS_INVENTORY_ERROR:
      return {
        ...state,
        create_visitors_inventory: {
          ...state.create_visitors_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITORS_INVENTORY_CLEAR_ERROR:
      return {
        ...state,
        create_visitors_inventory: {
          ...state.create_visitors_inventory,
          error: null,
          pending: false,
        },
      }
    case SET_VISITORS_INVENTORY_PENDING:
      return {
        ...state,
        set_visitors_inventory: {
          ...state.set_visitors_inventory,
          pending: true,
        },
      }
    case SET_VISITORS_INVENTORY_SUCCESS:
      return {
        ...state,
        set_visitors_inventory: {
          ...state.set_visitors_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case SET_VISITORS_INVENTORY_ERROR:
      return {
        ...state,
        set_visitors_inventory: {
          ...state.set_visitors_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITORS_INVENTORY_PENDING:
      return {
        ...state,
        delete_visitors_inventory: {
          ...state.delete_visitors_inventory,
          pending: true,
        },
      }
    case DELETE_VISITORS_INVENTORY_SUCCESS:
      return {
        ...state,
        delete_visitors_inventory: {
          ...state.delete_visitors_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITORS_INVENTORY_ERROR:
      return {
        ...state,
        delete_visitors_inventory: {
          ...state.delete_visitors_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case FILTER_VISITORS_INVENTORIES_PENDING:
      return {
        ...state,
        filter_visitors_inventories: {
          ...state.filter_visitors_inventories,
          pending: true,
        },
      }
    case FILTER_VISITORS_INVENTORIES_SUCCESS:
      return {
        ...state,
        filter_visitors_inventories: {
          ...state.filter_visitors_inventories,
          success: action.payload,
          pending: false,
        },
      }
    case FILTER_VISITORS_INVENTORIES_ERROR:
      return {
        ...state,
        filter_visitors_inventories: {
          ...state.filter_visitors_inventories,
          error: action.payload,
          pending: false,
        },
      }
    case FILTER_VISITORS_INVENTORIES_CLEAR_ERROR:
      return {
        ...state,
        filter_visitors_inventories: {
          ...state.filter_visitors_inventories,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getVisitorsInventoriesPending() {
  return {type: GET_VISITORS_INVENTORIES_PENDING}
}
function getVisitorsInventoriesSuccess(payload) {
  return {type: GET_VISITORS_INVENTORIES_SUCCESS, payload: payload}
}
function getVisitorsInventoriesError(error) {
  return {type: GET_VISITORS_INVENTORIES_ERROR, payload: error}
}
export function getVisitorsInventoriesClearError() {
  return {type: GET_VISITORS_INVENTORIES_CLEAR_ERROR}
}
function getVisitorsInventoryPending() {
  return {type: GET_VISITORS_INVENTORY_PENDING}
}
function getVisitorsInventorySuccess(payload) {
  return {type: GET_VISITORS_INVENTORY_SUCCESS, payload: payload}
}
function getVisitorsInventoryError(error) {
  return {type: GET_VISITORS_INVENTORY_ERROR, payload: error}
}

function createVisitorsInventoryPending() {
  return {type: CREATE_VISITORS_INVENTORY_PENDING}
}
function createVisitorsInventorySuccess(payload) {
  return {type: CREATE_VISITORS_INVENTORY_SUCCESS, payload: payload}
}
function createVisitorsInventoryError(error) {
  return {type: CREATE_VISITORS_INVENTORY_ERROR, payload: error}
}
export function createVisitorsInventoryCLearError() {
  return {type: CREATE_VISITORS_INVENTORY_CLEAR_ERROR}
}

function setVisitorsInventoryPending() {
  return {type: SET_VISITORS_INVENTORY_PENDING}
}
function setVisitorsInventorySuccess(payload) {
  return {type: SET_VISITORS_INVENTORY_SUCCESS, payload: payload}
}
function setVisitorsInventoryError(error) {
  return {type: SET_VISITORS_INVENTORY_ERROR, payload: error}
}

function deleteVisitorsInventoryPending() {
  return {type: DELETE_VISITORS_INVENTORY_PENDING}
}
function deleteVisitorsInventorySuccess(payload) {
  return {type: DELETE_VISITORS_INVENTORY_SUCCESS, payload: payload}
}
function deleteVisitorsInventoryError(error) {
  return {type: DELETE_VISITORS_INVENTORY_ERROR, payload: error}
}

function filterVisitorsInventoriesPending() {
  return {type: FILTER_VISITORS_INVENTORIES_PENDING}
}
function filterVisitorsInventoriesSuccess(payload) {
  return {type: FILTER_VISITORS_INVENTORIES_SUCCESS, payload: payload}
}
function filterVisitorsInventoriesError(error) {
  return {type: FILTER_VISITORS_INVENTORIES_ERROR, payload: error}
}
export function filterVisitorsInventoriesClearError() {
  return {type: FILTER_VISITORS_INVENTORIES_CLEAR_ERROR}
}
// Operations
export const getVisitorsInventories = (url) => (dispatch) => {
  dispatch(getVisitorsInventoriesPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getVisitorsInventoriesSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(getVisitorsInventoriesError(error)))
}

export const filterVisitorsInventories = (valid_to, valid_from, inventory_type) => (dispatch) => {
  dispatch(filterVisitorsInventoriesPending())
  return axios
    .get(
      `/api/v1/visitors_inventories?filters=` +
        `type_id eq '${inventory_type}'` +
        `&valid_from='${valid_from}'` +
        `&valid_to='${valid_to}'`,
    )
    .then((res) => {
      dispatch(filterVisitorsInventoriesSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(filterVisitorsInventoriesError(error)))
}

export const filterVisitorsInventoriesById =
  (valid_to, valid_from, inventory_id, inventory_type) => (dispatch) => {
    dispatch(filterVisitorsInventoriesPending())
    return axios
      .get(
        `/api/v1/visitors_inventories?filters=` +
          `id eq '${inventory_id}' AND type_id eq '${inventory_type}'` +
          `&valid_from='${valid_from}'` +
          `&valid_to='${valid_to}'`,
      )
      .then((res) => {
        dispatch(filterVisitorsInventoriesSuccess(res.data.results))
        return res.data.results
      })
      .catch((error) => dispatch(filterVisitorsInventoriesError(error)))
  }

export const getVisitorsInventory = (url) => (dispatch) => {
  dispatch(getVisitorsInventoryPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getVisitorsInventorySuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getVisitorsInventoryError(error)))
}

export const createVisitorsInventory = (values, userID) => (dispatch) => {
  dispatch(createVisitorsInventoryPending())
  return axios
    .post(`/api/v1/visitors_inventories`, values)
    .then((res) => {
      dispatch(createVisitorsInventorySuccess(res.data))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.InventoryAttached', {
            assigned_to: res.data.assigned_to,
            related_to: res.data.related_to,
          }),
          link: res.data.assigned_to_url,
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(createVisitorsInventoryError(error)))
}

export const setVisitorsInventory = (id, values) => (dispatch) => {
  dispatch(setVisitorsInventoryPending())
  return axios
    .put(`/api/v1/visitors_inventories/${id}`, values)
    .then((res) => {
      dispatch(setVisitorsInventorySuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(setVisitorsInventoryError(error)))
}

export const deleteVisitorsInventory = (id) => (dispatch) => {
  dispatch(deleteVisitorsInventoryPending())
  return axios
    .delete(`/api/v1/visitors_inventories/${id}`)
    .then((res) => {
      dispatch(deleteVisitorsInventorySuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(deleteVisitorsInventoryError(error)))
}
