import Image from './Image'
import ImageSrc from '../assets/AIFaceScan.svg'
import {ModalOpenButton, Modal, ModalContentsBase} from './Modal'
import {useTranslation} from 'react-i18next'
import FormResetOwnPassword from './forms/FormResetOwnPassword'
import {KeyIcon, GlobeAltIcon, ClockIcon} from '@heroicons/react/outline'
import FormAddPreferredLanguage from './forms/FormAddPreferredLanguage'
import FormAddPreferredTimezone from './forms/FormAddPreferredTimezone'
import AssignPrinter from './AssignPrinter'

export default function UserHeader({user}) {
  const {t} = useTranslation()
  return (
    <div className="flex justify-between">
      <div className="flex">
        <Image ImgSrc={ImageSrc} />
        <div className="flex flex-col pt-3">
          <AssignPrinter iconClassNames="text-gray-500" />
          <Modal>
            <ModalOpenButton>
              <KeyIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
            </ModalOpenButton>
            <ModalContentsBase title={t('userManagement.ResetOwnPassword')}>
              <FormResetOwnPassword />
            </ModalContentsBase>
          </Modal>
          {user.preferred_language !== 'N/A' ? ( // careful when formatting. It should be "N/A" not 'N/A'
            <Modal>
              <ModalOpenButton>
                <GlobeAltIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </ModalOpenButton>
              <ModalContentsBase title={t('userManagement.SetLanguage')}>
                <FormAddPreferredLanguage />
              </ModalContentsBase>
            </Modal>
          ) : null}
          {user.preferred_timezone !== 'N/A' ? (
            <Modal>
              <ModalOpenButton>
                <ClockIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </ModalOpenButton>
              <ModalContentsBase title={t('userManagement.SetTimezone')}>
                <FormAddPreferredTimezone />
              </ModalContentsBase>
            </Modal>
          ) : null}
        </div>
        <div className="flex flex-col pt-3">
          <p>{user?.name}</p>
          <p>{user?.email}</p>
        </div>
      </div>
    </div>
  )
}
