export const packages = {
  name: 'trial',
  version: '0.1.0',
  private: true,
  dependencies: {
    '@evoid/blazeface': '^1.0.3',
    '@headlessui/react': '^1.4.2',
    '@heroicons/react': '^1.0.5',
    '@tailwindcss/forms': '^0.5.3',
    '@tensorflow/tfjs': '3.1.0',
    '@tensorflow/tfjs-backend-wasm': '3.1.0',
    '@testing-library/jest-dom': '^5.16.1',
    '@testing-library/react': '^12.1.2',
    '@testing-library/user-event': '^13.5.0',
    axios: '^0.21.1',
    copyfiles: '^2.4.1',
    'date-fns': '^2.28.0',
    formik: '^2.2.9',
    i18next: '^20.3.1',
    'i18next-browser-languagedetector': '^7.0.1',
    'pkce-challenge': '^3.0.0',
    react: '^17.0.2',
    'react-dom': '^17.0.2',
    'react-i18next': '^11.10.0',
    'react-redux': '^7.1.1',
    'react-router-dom': '^5.2.0',
    'react-scripts': '5.0.0',
    'react-select': '^4.3.1',
    'react-table': '^7.7.0',
    redux: '^4.0.4',
    'redux-logger': '^3.0.6',
    'redux-persist': '^6.0.0',
    'redux-thunk': '^2.3.0',
    'web-vitals': '^2.1.3',
    yup: '^0.32.11',
  },
  scripts: {
    wasm: 'copyfiles tfjs-backend-wasm.wasm model.json group1-shard1of1.bin ./public',
    start: 'yarn wasm && WATCHPACK_POLLING=true react-scripts start',
    build: 'yarn wasm && react-scripts build',
    analyze: "source-map-explorer 'build/static/js/*.js'",
    test: 'react-scripts test',
    eject: 'react-scripts eject',
  },
  eslintConfig: {
    extends: ['react-app', 'react-app/jest'],
  },
  browserslist: {
    production: ['>0.2%', 'not dead', 'not op_mini all'],
    development: ['last 1 chrome version', 'last 1 firefox version', 'last 1 safari version'],
  },
  devDependencies: {
    autoprefixer: '^10.4.13',
    postcss: '^8.4.19',
    'source-map-explorer': '^2.5.2',
    tailwindcss: '^3.2.4',
  },
}
