const controlStylesHasError = (hasError) => {
  const style = {}
  if (hasError) {
    style.border = '1px solid #feb2b2'
  }

  return style
}

export const customStyles = (hasError) => ({
  control: (base, state) => ({
    ...base,
    border: state.isFocused || state.isSelected ? '1px solid #84CC16' : `1px solid #cccccc`, // lime-500
    borderRadius: '0.375rem',
    boxShadow: state.isFocused || state.isSelected ? '0px 0px 6px #84CC16' : 'none',
    '&:hover': {
      border: '1px solid #84CC16',
      boxShadow: '0px 0px 3px #84CC16',
    },
    fontSize: '0.875rem' /* 14px */, // text-sm
    lineHeight: '1.25rem' /* 20px" */, // text-sm
    ...controlStylesHasError(hasError),
  }),
  input: (base) => ({
    ...base,
    'input:focus': {
      boxShadow: 'none',
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '0.875rem' /* 14px */,
    lineHeight: '1.25rem' /* 20px" */,
    textTransform: 'capitalize',
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '0.875rem' /* 14px */, // text-sm
    lineHeight: '1.25rem' /* 20px" */, // text-sm
    backgroundColor: state.isFocused ? '#84CC16' : '#F7FEE7', // lime-50
    '&:hover': {
      backgroundColor: '#84CC16',
    },
    color: '#374151', // text-gray-700
    textTransform: 'capitalize',
  }),
  menuPortal: (base) => ({...base, zIndex: 9999}),
  singleValue: (base) => ({...base, textTransform: 'capitalize'}),
})
