import axios from '../services/api'
import {createNotification, createNotificationOnClient} from './notifications'
import I18n from '../i18n'

// Types
const GET_VISITORS_PENDING = 'idfunctiondb/visitors/GET_VISITORS_PENDING'
const GET_VISITORS_SUCCESS = 'idfunctiondb/visitors/GET_VISITORS_SUCCESS'
const GET_VISITORS_ERROR = 'idfunctiondb/visitors/GET_VISITORS_ERROR'

const GET_VISITORS_TOTAL_PENDING = 'idfunctiondb/visitors/GET_VISITORS_TOTAL_PENDING'
const GET_VISITORS_TOTAL_SUCCESS = 'idfunctiondb/visitors/GET_VISITORS_TOTAL_SUCCESS'
const GET_VISITORS_TOTAL_ERROR = 'idfunctiondb/visitors/GET_VISITORS_TOTAL_ERROR'

const GET_VISITOR_PENDING = 'idfunctiondb/visitors/GET_VISITOR_PENDING'
const GET_VISITOR_SUCCESS = 'idfunctiondb/visitors/GET_VISITOR_SUCCESS'
const GET_VISITOR_ERROR = 'idfunctiondb/visitors/GET_VISITOR_ERROR'
const GET_VISITOR_CLEAR_ERROR = 'idfunctiondb/visitors/GET_VISITOR_CLEAR_ERROR'

const GET_VISITOR_META_PENDING = 'idfunctiondb/visitors/GET_VISITOR_META_PENDING'
const GET_VISITOR_META_SUCCESS = 'idfunctiondb/visitors/GET_VISITOR_META_SUCCESS'
const GET_VISITOR_META_ERROR = 'idfunctiondb/visitors/GET_VISITOR_META_ERROR'

const GET_VISITOR_FILES_PENDING = 'idfunctiondb/visitors/GET_VISITOR_FILES_PENDING'
const GET_VISITOR_FILES_SUCCESS = 'idfunctiondb/visitors/GET_VISITOR_FILES_SUCCESS'
const GET_VISITOR_FILES_ERROR = 'idfunctiondb/visitors/GET_VISITOR_FILES_ERROR'
const GET_VISITOR_FILES_CLEAR_ERROR = 'idfunctiondb/visitors/GET_VISITOR_FILES_CLEAR_ERROR'

const CREATE_VISITOR_CUSTOM_ATTRIBUTE_PENDING =
  'idfunctiondb/visitors/CREATE_VISITOR_CUSTOM_ATTRIBUTE_PENDING'
const CREATE_VISITOR_CUSTOM_ATTRIBUTE_SUCCESS =
  'idfunctiondb/visitors/CREATE_VISITOR_CUSTOM_ATTRIBUTE_SUCCESS'
const CREATE_VISITOR_CUSTOM_ATTRIBUTE_ERROR =
  'idfunctiondb/visitors/CREATE_VISITOR_CUSTOM_ATTRIBUTE_ERROR'

const CREATE_VISITOR_CUSTOM_ATTRIBUTE_CLEAR_ERROR =
  'idfunctiondb/visitors/CREATE_VISITOR_CUSTOM_ATTRIBUTE_CLEAR_ERROR'

const DELETE_VISITOR_CUSTOM_ATTRIBUTE_PENDING =
  'idfunctiondb/visitors/DELETE_VISITOR_CUSTOM_ATTRIBUTE_PENDING'
const DELETE_VISITOR_CUSTOM_ATTRIBUTE_SUCCESS =
  'idfunctiondb/visitors/DELETE_VISITOR_CUSTOM_ATTRIBUTE_SUCCESS'
const DELETE_VISITOR_CUSTOM_ATTRIBUTE_ERROR =
  'idfunctiondb/visitors/DELETE_VISITOR_CUSTOM_ATTRIBUTE_ERROR'

const CREATE_VISITOR_PENDING = 'idfunctiondb/visitors/CREATE_VISITOR_PENDING'
const CREATE_VISITOR_SUCCESS = 'idfunctiondb/visitors/CREATE_VISITOR_SUCCESS'
const CREATE_VISITOR_ERROR = 'idfunctiondb/visitors/CREATE_VISITOR_ERROR'
const CREATE_VISITOR_CLEAR_ERROR = 'idfunctiondb/visitors/CREATE_VISITOR_CLEAR_ERROR'

const CREATE_QUICK_VISITOR_PENDING = 'idfunctiondb/visitors/CREATE_QUICK_VISITOR_PENDING'
const CREATE_QUICK_VISITOR_SUCCESS = 'idfunctiondb/visitors/CREATE_QUICK_VISITOR_SUCCESS'
const CREATE_QUICK_VISITOR_ERROR = 'idfunctiondb/visitors/CREATE_QUICK_VISITOR_ERROR'
const CREATE_QUICK_VISITOR_CLEAR_ERROR = 'idfunctiondb/visitors/CREATE_QUICK_VISITOR_CLEAR_ERROR'

const CREATE_VISITOR_FILE_PENDING = 'idfunctiondb/visitors/CREATE_VISITOR_FILE_PENDING'
const CREATE_VISITOR_FILE_SUCCESS = 'idfunctiondb/visitors/CREATE_VISITOR_FILE_SUCCESS'
const CREATE_VISITOR_FILE_ERROR = 'idfunctiondb/visitors/CREATE_VISITOR_FILE_ERROR'
const CREATE_VISITOR_FILE_CLEAR_ERROR = 'idfunctiondb/visitors/CREATE_VISITOR_FILE_CLEAR_ERROR'

const DELETE_VISITOR_FILE_PENDING = 'idfunctiondb/visitors/DELETE_VISITOR_FILE_PENDING'
const DELETE_VISITOR_FILE_SUCCESS = 'idfunctiondb/visitors/DELETE_VISITOR_FILE_SUCCESS'
const DELETE_VISITOR_FILE_ERROR = 'idfunctiondb/visitors/DELETE_VISITOR_FILE_ERROR'

const SET_VISITOR_PENDING = 'idfunctiondb/visitors/SET_VISITOR_PENDING'
const SET_VISITOR_SUCCESS = 'idfunctiondb/visitors/SET_VISITOR_SUCCESS'
const SET_VISITOR_ERROR = 'idfunctiondb/visitors/SET_VISITOR_ERROR'
const SET_VISITOR_CLEAR_ERROR = 'idfunctiondb/visitors/SET_VISITOR_CLEAR_ERROR'

const DELETE_VISITOR_PENDING = 'idfunctiondb/visitors/DELETE_VISITOR_PENDING'
const DELETE_VISITOR_SUCCESS = 'idfunctiondb/visitors/DELETE_VISITOR_SUCCESS'
const DELETE_VISITOR_ERROR = 'idfunctiondb/visitors/DELETE_VISITOR_ERROR'

const DELETE_VISITOR_PHOTO_PENDING = 'idfunctiondb/visitors/DELETE_VISITOR_PHOTO_PENDING'
const DELETE_VISITOR_PHOTO_SUCCESS = 'idfunctiondb/visitors/DELETE_VISITOR_PHOTO_SUCCESS'
const DELETE_VISITOR_PHOTO_ERROR = 'idfunctiondb/visitors/DELETE_VISITOR_PHOTO_ERROR'
const DELETE_VISITOR_PHOTO_CLEAR_ERROR = 'idfunctiondb/visitors/DELETE_VISITOR_PHOTO_CLEAR_ERROR'

// Initial
const initialState = {
  get_visitors: {
    success: [],
    pending: false,
    error: null,
    meta: {
      total: 0,
    },
  },

  get_visitors_total: {
    success: null,
    pending: false,
    error: null,
  },

  get_visitor: {
    success: {},
    pending: false,
    error: null,
  },

  get_visitor_meta: {
    success: {},
    pending: false,
    error: null,
  },

  create_visitor: {
    success: null,
    pending: false,
    error: null,
  },

  create_quick_visitor: {
    success: null,
    pending: false,
    error: null,
  },

  set_visitor: {
    success: null,
    pending: false,
    error: null,
  },

  create_visitor_custom_attribute: {
    success: null,
    pending: false,
    error: null,
  },

  delete_visitor_custom_attribute: {
    success: null,
    pending: false,
    error: null,
  },

  delete_visitor: {
    success: null,
    pending: false,
    error: null,
  },

  delete_visitor_photo: {
    success: null,
    pending: false,
    error: null,
  },

  get_visitor_files: {
    success: [],
    pending: false,
    error: null,
  },

  create_visitor_file: {
    success: null,
    pending: false,
    error: null,
  },

  delete_visitor_file: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_VISITORS_PENDING:
      return {
        ...state,
        get_visitors: {
          ...state.get_visitors,
          pending: true,
        },
      }
    case GET_VISITORS_SUCCESS:
      return {
        ...state,
        get_visitors: {
          ...state.get_visitors,
          success: action.payload.results,
          meta: action.payload.meta,
          pending: false,
        },
      }
    case GET_VISITORS_ERROR:
      return {
        ...state,
        get_visitors: {
          ...state.get_visitors,
          error: action.payload,
          pending: false,
        },
      }
    case GET_VISITORS_TOTAL_PENDING:
      return {
        ...state,
        get_visitors_total: {
          ...state.get_visitors_total,
          pending: true,
        },
      }
    case GET_VISITORS_TOTAL_SUCCESS:
      return {
        ...state,
        get_visitors_total: {
          ...state.get_visitors_total,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITORS_TOTAL_ERROR:
      return {
        ...state,
        get_visitors_total: {
          ...state.get_visitors_total,
          error: action.payload,
          pending: false,
        },
      }
    case GET_VISITOR_PENDING:
      return {
        ...state,
        get_visitor: {
          ...state.get_visitor,
          pending: true,
        },
      }
    case GET_VISITOR_SUCCESS:
      return {
        ...state,
        get_visitor: {
          ...state.get_visitor,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITOR_ERROR:
      return {
        ...state,
        get_visitor: {
          ...state.get_visitor,
          error: action.payload,
          pending: false,
        },
      }
    case GET_VISITOR_CLEAR_ERROR:
      return {
        ...state,
        get_visitor: {
          ...state.get_visitor,
          error: null,
        },
      }
    case GET_VISITOR_META_PENDING:
      return {
        ...state,
        get_visitor_meta: {
          ...state.get_visitor_meta,
          pending: true,
        },
      }
    case GET_VISITOR_META_SUCCESS:
      return {
        ...state,
        get_visitor_meta: {
          ...state.get_visitor_meta,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITOR_META_ERROR:
      return {
        ...state,
        get_visitor_meta: {
          ...state.get_visitor_meta,
          error: action.payload,
          pending: false,
        },
      }
    case GET_VISITOR_FILES_PENDING:
      return {
        ...state,
        get_visitor_files: {
          ...state.get_visitor_files,
          pending: true,
        },
      }
    case GET_VISITOR_FILES_SUCCESS:
      return {
        ...state,
        get_visitor_files: {
          ...state.get_visitor_files,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITOR_FILES_ERROR:
      return {
        ...state,
        get_visitor_files: {
          ...state.get_visitor_files,
          error: action.payload,
          pending: false,
        },
      }
    case GET_VISITOR_FILES_CLEAR_ERROR:
      return {
        ...state,
        get_visitor_files: {
          ...state.get_visitor_files,
          error: null,
          pending: false,
        },
      }
    case CREATE_VISITOR_PENDING:
      return {
        ...state,
        create_visitor: {
          ...state.create_visitor,
          pending: true,
        },
      }
    case CREATE_VISITOR_SUCCESS:
      return {
        ...state,
        create_visitor: {
          ...state.create_visitor,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITOR_ERROR:
      return {
        ...state,
        create_visitor: {
          ...state.create_visitor,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITOR_CLEAR_ERROR:
      return {
        ...state,
        create_visitor: {
          ...state.create_visitor,
          error: null,
          pending: false,
        },
      }
    case CREATE_QUICK_VISITOR_PENDING:
      return {
        ...state,
        create_quick_visitor: {
          ...state.create_quick_visitor,
          pending: true,
        },
      }
    case CREATE_QUICK_VISITOR_SUCCESS:
      return {
        ...state,
        create_quick_visitor: {
          ...state.create_quick_visitor,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_QUICK_VISITOR_ERROR:
      return {
        ...state,
        create_quick_visitor: {
          ...state.create_quick_visitor,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_QUICK_VISITOR_CLEAR_ERROR:
      return {
        ...state,
        create_quick_visitor: {
          ...state.create_quick_visitor,
          error: null,
          pending: false,
        },
      }
    case CREATE_VISITOR_FILE_PENDING:
      return {
        ...state,
        create_visitor_file: {
          ...state.create_visitor_file,
          pending: true,
        },
      }
    case CREATE_VISITOR_FILE_SUCCESS:
      return {
        ...state,
        create_visitor_file: {
          ...state.create_visitor_file,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITOR_FILE_ERROR:
      return {
        ...state,
        create_visitor_file: {
          ...state.create_visitor_file,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITOR_FILE_CLEAR_ERROR:
      return {
        ...state,
        create_visitor_file: {
          ...state.create_visitor_file,
          error: null,
          pending: false,
        },
      }
    case DELETE_VISITOR_FILE_PENDING:
      return {
        ...state,
        delete_visitor_file: {
          ...state.delete_visitor_file,
          pending: true,
        },
      }
    case DELETE_VISITOR_FILE_SUCCESS:
      return {
        ...state,
        delete_visitor_file: {
          ...state.delete_visitor_file,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITOR_FILE_ERROR:
      return {
        ...state,
        delete_visitor_file: {
          ...state.delete_visitor_file,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITOR_PHOTO_PENDING:
      return {
        ...state,
        delete_visitor_photo: {
          ...state.delete_visitor_photo,
          pending: true,
        },
      }
    case DELETE_VISITOR_PHOTO_SUCCESS:
      return {
        ...state,
        delete_visitor_photo: {
          ...state.delete_visitor_photo,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITOR_PHOTO_ERROR:
      return {
        ...state,
        delete_visitor_photo: {
          ...state.delete_visitor_photo,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITOR_PHOTO_CLEAR_ERROR:
      return {
        ...state,
        delete_visitor_photo: {
          ...state.delete_visitor_photo,
          error: null,
        },
      }
    case SET_VISITOR_PENDING:
      return {
        ...state,
        set_visitor: {
          ...state.set_visitor,
          pending: true,
        },
      }
    case SET_VISITOR_SUCCESS:
      return {
        ...state,
        set_visitor: {
          ...state.set_visitor,
          success: action.payload.results,
          pending: false,
        },
      }
    case SET_VISITOR_ERROR:
      return {
        ...state,
        set_visitor: {
          ...state.set_visitor,
          error: action.payload,
          pending: false,
        },
      }
    case SET_VISITOR_CLEAR_ERROR:
      return {
        ...state,
        set_visitor: {
          ...state.set_visitor,
          error: null,
          pending: false,
        },
      }

    case CREATE_VISITOR_CUSTOM_ATTRIBUTE_PENDING:
      return {
        ...state,
        create_visitor_custom_attribute: {
          ...state.create_visitor_custom_attribute,
          pending: true,
        },
      }
    case CREATE_VISITOR_CUSTOM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        create_visitor_custom_attribute: {
          ...state.create_visitor_custom_attribute,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITOR_CUSTOM_ATTRIBUTE_ERROR:
      return {
        ...state,
        create_visitor_custom_attribute: {
          ...state.create_visitor_custom_attribute,
          error: action.payload,
          pending: false,
        },
      }

    case CREATE_VISITOR_CUSTOM_ATTRIBUTE_CLEAR_ERROR:
      return {
        ...state,
        create_visitor_custom_attribute: {
          ...state.create_visitor_custom_attribute,
          error: null,
          pending: false,
        },
      }

    case DELETE_VISITOR_CUSTOM_ATTRIBUTE_PENDING:
      return {
        ...state,
        delete_visitor_custom_attribute: {
          ...state.delete_visitor_custom_attribute,
          pending: true,
        },
      }
    case DELETE_VISITOR_CUSTOM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        delete_visitor_custom_attribute: {
          ...state.delete_visitor_custom_attribute,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITOR_CUSTOM_ATTRIBUTE_ERROR:
      return {
        ...state,
        delete_visitor_custom_attribute: {
          ...state.delete_visitor_custom_attribute,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITOR_PENDING:
      return {
        ...state,
        delete_visitor: {
          ...state.delete_visitor,
          pending: true,
        },
      }
    case DELETE_VISITOR_SUCCESS:
      return {
        ...state,
        delete_visitor: {
          ...state.delete_visitor,
          success: action.payload.results,
          pending: false,
        },
      }
    case DELETE_VISITOR_ERROR:
      return {
        ...state,
        delete_visitor: {
          ...state.delete_visitor,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getVisitorsPending() {
  return {type: GET_VISITORS_PENDING}
}
function getVisitorsSuccess(payload) {
  return {type: GET_VISITORS_SUCCESS, payload: payload}
}
function getVisitorsError(error) {
  return {type: GET_VISITORS_ERROR, payload: error}
}

function getVisitorsTotalPending() {
  return {type: GET_VISITORS_TOTAL_PENDING}
}
function getVisitorsTotalSuccess(payload) {
  return {type: GET_VISITORS_TOTAL_SUCCESS, payload: payload}
}
function getVisitorsTotalError(error) {
  return {type: GET_VISITORS_TOTAL_ERROR, payload: error}
}

function getVisitorPending() {
  return {type: GET_VISITOR_PENDING}
}
function getVisitorSuccess(payload) {
  return {type: GET_VISITOR_SUCCESS, payload: payload}
}
function getVisitorError(error) {
  return {type: GET_VISITOR_ERROR, payload: error}
}
export function getVisitorClearError() {
  return {type: GET_VISITOR_CLEAR_ERROR}
}

function getVisitorMetaPending() {
  return {type: GET_VISITOR_META_PENDING}
}
function getVisitorMetaSuccess(payload) {
  return {type: GET_VISITOR_META_SUCCESS, payload: payload}
}
function getVisitorMetaError(error) {
  return {type: GET_VISITOR_META_ERROR, payload: error}
}

function getVisitorFilesPending() {
  return {type: GET_VISITOR_FILES_PENDING}
}
function getVisitorFilesSuccess(payload) {
  return {type: GET_VISITOR_FILES_SUCCESS, payload: payload}
}
function getVisitorFilesError(error) {
  return {type: GET_VISITOR_FILES_ERROR, payload: error}
}
export function getVisitorFilesClearError() {
  return {type: GET_VISITOR_FILES_CLEAR_ERROR}
}
function createVisitorPending() {
  return {type: CREATE_VISITOR_PENDING}
}
function createVisitorSuccess(payload) {
  return {type: CREATE_VISITOR_SUCCESS, payload: payload}
}
function createVisitorError(error) {
  return {type: CREATE_VISITOR_ERROR, payload: error}
}
export function createVisitorClearError() {
  return {type: CREATE_VISITOR_CLEAR_ERROR}
}

function createQuickVisitorPending() {
  return {type: CREATE_QUICK_VISITOR_PENDING}
}
function createQuickVisitorSuccess(payload) {
  return {type: CREATE_QUICK_VISITOR_SUCCESS, payload: payload}
}
function createQuickVisitorError(error) {
  return {type: CREATE_QUICK_VISITOR_ERROR, payload: error}
}
export function createQuickVisitorClearError() {
  return {type: CREATE_QUICK_VISITOR_CLEAR_ERROR}
}

function createVisitorFilePending() {
  return {type: CREATE_VISITOR_FILE_PENDING}
}
function createVisitorFileSuccess(payload) {
  return {type: CREATE_VISITOR_FILE_SUCCESS, payload: payload}
}
function createVisitorFileError(error) {
  return {type: CREATE_VISITOR_FILE_ERROR, payload: error}
}

export function createVisitorFileClearError() {
  return {type: CREATE_VISITOR_FILE_CLEAR_ERROR}
}

function deleteVisitorFilePending() {
  return {type: DELETE_VISITOR_FILE_PENDING}
}
function deleteVisitorFileSuccess(payload) {
  return {type: DELETE_VISITOR_FILE_SUCCESS, payload: payload}
}
function deleteVisitorFileError(error) {
  return {type: DELETE_VISITOR_FILE_ERROR, payload: error}
}

function deleteVisitorPhotoPending() {
  return {type: DELETE_VISITOR_PHOTO_PENDING}
}
function deleteVisitorPhotoSuccess(payload) {
  return {type: DELETE_VISITOR_PHOTO_SUCCESS, payload: payload}
}
function deleteVisitorPhotoError(error) {
  return {type: DELETE_VISITOR_PHOTO_ERROR, payload: error}
}
export function deleteVisitorPhotoClearError() {
  return {type: DELETE_VISITOR_PHOTO_CLEAR_ERROR}
}

function setVisitorPending() {
  return {type: SET_VISITOR_PENDING}
}
function setVisitorSuccess(payload) {
  return {type: SET_VISITOR_SUCCESS, payload: payload}
}
function setVisitorError(error) {
  return {type: SET_VISITOR_ERROR, payload: error}
}
export function setVisitorClearError() {
  return {type: SET_VISITOR_CLEAR_ERROR}
}

function createVisitorCustomAttributePending() {
  return {type: CREATE_VISITOR_CUSTOM_ATTRIBUTE_PENDING}
}
function createVisitorCustomAttributeSuccess(payload) {
  return {type: CREATE_VISITOR_CUSTOM_ATTRIBUTE_SUCCESS, payload: payload}
}
function createVisitorCustomAttributeError(error) {
  return {type: CREATE_VISITOR_CUSTOM_ATTRIBUTE_ERROR, payload: error}
}

export function createVisitorCustomAttributeClearError() {
  return {type: CREATE_VISITOR_CUSTOM_ATTRIBUTE_CLEAR_ERROR}
}

function deleteVisitorCustomAttributePending() {
  return {type: DELETE_VISITOR_CUSTOM_ATTRIBUTE_PENDING}
}
function deleteVisitorCustomAttributeSuccess(payload) {
  return {type: DELETE_VISITOR_CUSTOM_ATTRIBUTE_SUCCESS, payload: payload}
}
function deleteVisitorCustomAttributeError(error) {
  return {type: DELETE_VISITOR_CUSTOM_ATTRIBUTE_ERROR, payload: error}
}

function deleteVisitorPending() {
  return {type: DELETE_VISITOR_PENDING}
}
function deleteVisitorSuccess(payload) {
  return {type: DELETE_VISITOR_SUCCESS, payload: payload}
}
function deleteVisitorError(error) {
  return {type: DELETE_VISITOR_ERROR, payload: error}
}

// Operations
export const getVisitors =
  (limit = 10, offset = 0) =>
  (dispatch) => {
    dispatch(getVisitorsPending())
    axios
      .get(`/api/v1/visitors?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getVisitorsSuccess(res.data))
      })
      .catch((error) => dispatch(getVisitorsError(error)))
  }

export const getVisitorsTotal = () => (dispatch) => {
  dispatch(getVisitorsTotalPending())
  axios
    .get(`/api/v1/visitors?amount_of=total`)
    .then((res) => {
      dispatch(getVisitorsTotalSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getVisitorsTotalError(error)))
}

export const getVisitor = (id) => (dispatch) => {
  dispatch(getVisitorPending())
  return axios
    .get(`/api/v1/visitors/${id}`)
    .then((res) => {
      dispatch(getVisitorSuccess(res.data))
      return res.data
    })
    .catch((error) => {
      dispatch(getVisitorError(error))
    })
}

export const getVisitorMeta = (id) => (dispatch) => {
  dispatch(getVisitorMetaPending())
  return axios
    .get(`/api/v1/visitors?current=${id}`)
    .then((res) => {
      dispatch(getVisitorMetaSuccess(res.data.meta))
      return res.data.meta
    })
    .catch((error) => dispatch(getVisitorMetaError(error)))
}

export const getVisitorFiles = (id) => (dispatch) => {
  dispatch(getVisitorFilesPending())
  return axios
    .get(`/api/v1/visitors/${id}/files`)
    .then((res) => {
      dispatch(getVisitorFilesSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(getVisitorFilesError(error)))
}

export const createVisitor = (values, True, userID) => (dispatch) => {
  dispatch(createVisitorPending())
  return axios
    .post(`/api/v1/visitors`, values)
    .then((res) => {
      dispatch(createVisitorSuccess(res))
      if (True) {
        // was notify and creates a confusion with the func. name notify
        dispatch(
          createNotification(userID, {
            code: '201',
            message: I18n.t('notificationSuccess.VisitorCreated', {
              number: res.data.person.full_name,
            }),
            link: `/visitors/${res.data.id}`,
          }),
        )
      }
      return res.data
    })
    .catch((error) => {
      dispatch(createVisitorError(error))
    })
}

export const createQuickVisitor = (values, id) => (dispatch) => {
  dispatch(createQuickVisitorPending())
  return axios
    .post(`/api/v1/data/visitors`, values)
    .then((res) => {
      dispatch(createQuickVisitorSuccess(res))
      dispatch(
        createNotification(id, {
          code: '201',
          message: I18n.t('notificationSuccess.VisitorCreated', {
            number: res.data.person.full_name,
          }),
          link: `/visitors/${res.data.id}`,
        }),
      )

      return res.data
    })
    .catch((error) => {
      dispatch(createQuickVisitorError(error))
    })
}

export const createVisitorCustomAttribute = (id, values) => (dispatch) => {
  dispatch(createVisitorCustomAttributePending())
  return axios
    .post(`/api/v1/visitors/${id}/custom_attributes`, values)
    .then((res) => {
      dispatch(createVisitorCustomAttributeSuccess(res))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.CustomAttributeCreated'))
      return res.data
    })
    .catch((error) => {
      dispatch(createVisitorCustomAttributeError(error))
    })
}

export const deleteVisitorCustomAttribute = (id, key) => (dispatch) => {
  dispatch(deleteVisitorCustomAttributePending())
  return axios
    .delete(`/api/v1/visitors/${id}/custom_attributes/${key}`)
    .then((res) => {
      dispatch(deleteVisitorCustomAttributeSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(deleteVisitorCustomAttributeError(error))
    })
}

export const setVisitor = (id, values) => (dispatch) => {
  dispatch(setVisitorPending())
  return axios
    .put(`/api/v1/visitors/${id}`, values)
    .then((res) => {
      dispatch(setVisitorSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(setVisitorError(error)))
}

export const createVisitorFile =
  (id, target, setUploadedFile, notify, userID, tagIds, description, validTo) => (dispatch) => {
    dispatch(createVisitorFilePending())
    var formData = new FormData()
    var imagefile = target
    formData.append('file', imagefile.files[0])
    formData.append('description', description)
    for (var i = 0; i < tagIds.length; i++) {
      formData.append('tags', tagIds[i])
    }
    formData.append('valid_to', validTo)
    return axios
      .post(`/api/v1/visitors/${id}/files`, formData)
      .then((res) => {
        setUploadedFile && setUploadedFile(null)
        dispatch(createVisitorFileSuccess(res))
        if (notify) {
          dispatch(
            createNotification(userID, {
              code: '201',
              message: I18n.t('notificationSuccess.EmployeeFileCreated', {name: res.data.name}),
              link: `/visitors/${id}`,
            }),
          )
        }
        return res.data
      })
      .catch((error) => {
        dispatch(createVisitorFileError(error))
      })
  }

export const deleteVisitorFile = (id, file_id, notify, userID) => (dispatch) => {
  dispatch(deleteVisitorFilePending())
  return axios
    .delete(`/api/v1/visitors/${id}/files/${file_id}`)
    .then((res) => {
      dispatch(deleteVisitorFileSuccess(res))
      if (notify) {
        dispatch(
          createNotification(userID, {
            code: '201',
            message: I18n.t('notificationSuccess.EmployeeFileDeleted', {name: res.data}),
          }),
        )
      }
      return res.data
    })
    .catch((error) => {
      dispatch(deleteVisitorFileError(error))
    })
}

export const deleteVisitorPhoto = (id) => (dispatch) => {
  dispatch(deleteVisitorPhotoPending())
  return axios
    .delete(`/api/v1/visitors/${id}/photo`)
    .then((res) => {
      dispatch(deleteVisitorPhotoSuccess(res))
      // TODO:Add this later
      // dispatch(
      //   createNotificationOnClient(res.status, 'notificationSuccess.'),
      // )
      return res.data
    })
    .catch((error) => {
      dispatch(deleteVisitorPhotoError(error))
    })
}

export const deleteVisitor = (id, userID) => (dispatch) => {
  dispatch(deleteVisitorPending())
  return axios
    .delete(`/api/v1/visitors/${id}`)
    .then((res) => {
      dispatch(deleteVisitorSuccess(res.data))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.VisitorDeleted', {number: res.data}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(deleteVisitorError(error)))
}
