import {useState} from 'react'
import {Formik, Form} from 'formik'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {Button} from '../Buttons'
import {format} from 'date-fns'
import {DatePicker} from '../DatePicker'
import NotAvailableEntity from '../NotAvailableEntity'
import ErrorOnModal from '../ErrorOnModal'
import * as Yup from 'yup'
import SpinnerSVG from '../../assets/Spinner.svg'
import InfoMessage from '../InfoMessage'
import {iso8061UTC} from '../../utils/datetime'

export default function FormExtendInventoryValidity({
  data,
  filteredIdentityInventoriesPending,
  filteredIdentityInventoriesError,
  filteredIdentityInventories,
  filterIdentityInventoryById,
  filterIdentityInventoryClearError,
  setIdentityInventory,
}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const initialValues = {
    valid_to: format(new Date(data?.valid_to), "yyyy-MM-dd'T'HH:mm") || '',
  }

  const validationSchema = Yup.object().shape({
    valid_to: Yup.date().required(),
  })

  const [valueToExtend, setValueExtend] = useState(null)

  const extendValidity = (data, valueToBeSet) => {
    const values = {valid_to: iso8061UTC(valueToBeSet)}
    dispatch(setIdentityInventory(data.id, values))
  }

  const [showFormFilterInventory, setShowFormFilterInventory] = useState(true)

  return (
    <div>
      {showFormFilterInventory && (
        // Form Filter Inventory
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            let valid_to = iso8061UTC(values.valid_to)
            let valid_from = iso8061UTC(data.valid_to)
            dispatch(
              filterIdentityInventoryById(
                valid_to,
                valid_from,
                data.inventory_id,
                data.inventory.type.id,
              ),
            )
            setValueExtend(values.valid_to)
            setShowFormFilterInventory(false)
          }}
        >
          <Form>
            <DatePicker
              validto="valid_to"
              type="datetime-local"
              id="form-extend-inventory-validTo"
              checkboxid="form-extend-inventory-checkbox"
              labelvalidto={t('addVisitationForm.ToDate')}
              required
            />
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <Button text="Search" type="submit" />
            </div>
          </Form>
        </Formik>
      )}
      {filteredIdentityInventoriesPending ? (
        <div className="text-md flex flex-col items-center justify-center p-3">
          <img alt="o" src={SpinnerSVG} className="mr-3 h-7 w-7 animate-spin text-lime-500" />
          Searching...
        </div>
      ) : null}
      {!filteredIdentityInventoriesPending &&
      filteredIdentityInventoriesError &&
      !showFormFilterInventory ? (
        <ErrorOnModal
          error={filteredIdentityInventoriesError}
          clearError={filterIdentityInventoryClearError}
          setShow={setShowFormFilterInventory}
        />
      ) : null}
      {!filteredIdentityInventoriesPending &&
      !filteredIdentityInventoriesError &&
      filteredIdentityInventories.length === 0 &&
      !showFormFilterInventory ? (
        <NotAvailableEntity
          message={t('notificationSuccess.InventoryQueryZeroResult')}
          setShow={setShowFormFilterInventory}
        />
      ) : null}
      {filteredIdentityInventories.length > 0 &&
      !showFormFilterInventory &&
      !filteredIdentityInventoriesError &&
      !filteredIdentityInventoriesPending ? (
        <div className="pt-2">
          <InfoMessage message={t('attachInventoryForm.ExtensionPossible')} type="success" />
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button
              text={t('attachInventoryForm.Extend')}
              type="button"
              onClick={() => {
                extendValidity(data, valueToExtend)
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
