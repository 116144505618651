import {NoData, SortDownIcon, SortUpIcon} from './TableComponents'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {classNames} from '../../utils/classNames'

const TableView = (props) => {
  const {
    getTableProps,
    getTableBodyProps,
    page,
    headerGroups,
    prepareRow,
    rowBaseUrl,
    rowUrlAccessor,
    pending,
  } = props
  const navigate = useNavigate()
  const {t} = useTranslation()
  const getSortingThProps = (column) => {
    return column.noSort ? {} : column.getSortByToggleProps()
  }

  return (
    <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            style={{
              height: '58px',
            }}
          >
            {headerGroup.headers.map((column) => {
              return (
                <th
                  className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                      height: 'inherit',
                      verticalAlign: typeof column.Header === 'string' ? 'top' : 'bottom',
                    },
                    title: column.noSort ? '' : t('dataTableSorting.SortBy'),
                  })}
                >
                  <div
                    className={classNames(
                      'flex justify-between',
                      typeof column.Header === 'string' ? 'items-start' : 'items-end',
                    )}
                  >
                    {typeof column.Header === 'string' ? (
                      <div className="">{column.render('Header')}</div>
                    ) : (
                      column.render('Header')
                    )}
                    {!column.noSort && (
                      <div className="flex h-full flex-col items-end">
                        {typeof column.Header !== 'string' && <div className="h-4"></div>}
                        <div
                          {...getSortingThProps(column)}
                          className="mx-1 flex flex-col items-center rounded-md border border-gray-300 py-0.5 hover:border-lime-500"
                        >
                          <SortUpIcon
                            className={`h-4 w-4 ${
                              column.isSorted && column.isSortedDesc
                                ? 'text-lime-500'
                                : 'text-gray-400'
                            }`}
                          />
                          <SortDownIcon
                            className={`h-4 w-4 ${
                              column.isSorted && !column.isSortedDesc
                                ? 'text-lime-500'
                                : 'text-gray-400'
                            }`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.length > 0 ? (
          page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="px-6 py-4 text-gray-500"
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.minWidth,
                          width: cell.width,
                          cursor: cell.column.withClick ? 'pointer' : 'auto',
                        },
                      })}
                      onClick={() => {
                        if (cell.column.withClick) {
                          return navigate(`${rowBaseUrl}${row.original[rowUrlAccessor]}`)
                        }
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })
        ) : !pending ? (
          <NoData />
        ) : null}
      </tbody>
    </table>
  )
}

export default TableView
