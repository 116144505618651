import {useEffect} from 'react'
import Collapse from '../Collapse'
import {useTranslation} from 'react-i18next'
import FormAddPrinter from '../forms/FormAddPrinter'
import {useSelector, useDispatch} from 'react-redux'
import {
  getPrinters,
  getPrintersClearError,
  getIDFunctionWebPrinters,
  getIDFunctionWebPrintersClearError,
} from '../../stores/printers'
import Table from '../table'
import {useTableColumns} from '../../hooks/useTableColumns'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'

export default function PrinterWrapper() {
  const dispatch = useDispatch()
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const printers = useSelector((state) => state.printers.get_printers.success)
  const printersError = useSelector((state) => state.printers.get_printers.error)
  const printers_pending = useSelector((state) => state.printers.get_printers.pending)

  const idf_printers = useSelector((state) => state.printers.get_idfunctionweb_printers.success)
  const idf_printers_error = useSelector((state) => state.printers.get_idfunctionweb_printers.error)
  const idf_printers_pending = useSelector(
    (state) => state.printers.get_idfunctionweb_printers.pending,
  )
  const error = useSelector((state) => state.printers.get_idfunctionweb_printers.error)

  const post_printer_pending = useSelector((state) => state.printers.post_printer.pending)
  const post_printer_success = useSelector((state) => state.printers.post_printer.success)
  const post_printer_error = useSelector((state) => state.printers.post_printer.error)
  const update_printer_success = useSelector(
    (state) => state.printers.update_idfunctionweb_printer.success,
  )
  const delete_printer_success = useSelector(
    (state) => state.printers.delete_idfunctionweb_printer.success,
  )

  const {t} = useTranslation()
  useEffect(() => {
    dispatch(getPrinters())
  }, [dispatch, post_printer_success, printersError?.message])

  useEffect(() => {
    dispatch(getIDFunctionWebPrinters())
  }, [
    dispatch,
    post_printer_success,
    update_printer_success,
    delete_printer_success,
    idf_printers_error?.message,
  ]) // track edited/delted printers

  const columns = useTableColumns(TableId.PRINTER)

  return (
    <>
      <Collapse title={t('configurePrinters.AddNewPrinter')}>
        <FormAddPrinter
          printers={printers}
          printersPending={printers_pending}
          printersError={printersError}
          printersClearError={getPrintersClearError}
          userID={userID}
          post_printer_pending={post_printer_pending}
          post_printer_error={post_printer_error}
        />
      </Collapse>
      <>
        <EditTableWrapper tableId={TableId.PRINTER} />
        <Table
          columns={columns}
          data={idf_printers}
          pending={idf_printers_pending}
          error={error}
          setError={getIDFunctionWebPrintersClearError}
        />
      </>
    </>
  )
}
