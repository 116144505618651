import {useTranslation} from 'react-i18next'

export default function InfoBox(props) {
  const {t} = useTranslation()
  return (
    <>
      <div className="mx-auto flex items-center justify-center">
        <div className="mt-2">
          {props.message ? (
            props.message.split('\n').map((message) => {
              return (
                <p key={message} className="p-1 text-sm text-gray-500">
                  {message}
                </p>
              )
            })
          ) : (
            <p className="p-1 text-sm text-gray-500">{t('NoSummary')}</p>
          )}
        </div>
      </div>
    </>
  )
}
