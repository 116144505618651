import Spinner from '../assets/Spinner.svg'

export default function FullPageSpinner({message}) {
  return (
    <div className="flex h-screen flex-col items-center justify-center text-xl">
      <img
        alt="o"
        src={Spinner}
        className="mr-3 h-20 w-20 animate-spin text-lg font-bold text-lime-500"
      />
      {message}
    </div>
  )
}
