import axios from '../services/api'

// types

// might or might not have a form -> from admin panel -> configure workflows section
const GET_WORKFLOWS_PENDING = 'idfunctiondb/workflows/GET_WORKFLOWS_PENDING'
const GET_WORKFLOWS_SUCCESS = 'idfunctiondb/workflows/GET_WORKFLOWS_SUCCESS'
const GET_WORKFLOWS_ERROR = 'idfunctiondb/workflows/GET_WORKFLOWS_ERROR'
const GET_WORKFLOWS_CLEAR_ERROR = 'idfunctiondb/workflows/GET_WORKFLOWS_CLEAR_ERROR'

const GET_PUBLIC_WORKFLOWS_PENDING = 'idfunctiondb/workflows/GET_PUBLIC_WORKFLOWS_PENDING'
const GET_PUBLIC_WORKFLOWS_SUCCESS = 'idfunctiondb/workflows/GET_PUBLIC_WORKFLOWS_SUCCESS'
const GET_PUBLIC_WORKFLOWS_ERROR = 'idfunctiondb/workflows/GET_PUBLIC_WORKFLOWS_ERROR'
const GET_PUBLIC_WORKFLOWS_CLEAR_ERROR = 'idfunctiondb/workflows/GET_PUBLIC_WORKFLOWS_CLEAR_ERROR'

const GET_TODOS_PENDING = 'idfunctiondb/workflows/GET_TODOS_PENDING'
const GET_TODOS_SUCCESS = 'idfunctiondb/workflows/GET_TODOS_SUCCESS'
const GET_TODOS_ERROR = 'idfunctiondb/workflows/GET_TODOS_ERROR'
const GET_TODOS_CLEAR_ERROR = 'idfunctiondb/workflows/GET_TODOS_CLEAR_ERROR'

const GET_MY_TODOS_PENDING = 'idfunctiondb/workflows/GET_MY_TODOS_PENDING'
const GET_MY_TODOS_SUCCESS = 'idfunctiondb/workflows/GET_MY_TODOS_SUCCESS'
const GET_MY_TODOS_ERROR = 'idfunctiondb/workflows/GET_MY_TODOS_ERROR'
const GET_MY_TODOS_CLEAR_ERROR = 'idfunctiondb/workflows/GET_MY_TODOS_CLEAR_ERROR'

const GET_MY_EXECUTIONS_PENDING = 'idfunctiondb/workflows/GET_MY_EXECUTIONS_PENDING'
const GET_MY_EXECUTIONS_SUCCESS = 'idfunctiondb/workflows/GET_MY_EXECUTIONS_SUCCESS'
const GET_MY_EXECUTIONS_ERROR = 'idfunctiondb/workflows/GET_MY_EXECUTIONS_ERROR'
const GET_MY_EXECUTIONS_CLEAR_ERROR = 'idfunctiondb/workflows/GET_MY_EXECUTIONS_CLEAR_ERROR'

const GET_EXECUTION_INFO_PENDING = 'idfunctiondb/workflows/GET_EXECUTION_INFO_PENDING'
const GET_EXECUTION_INFO_SUCCESS = 'idfunctiondb/workflows/GET_EXECUTION_INFO_SUCCESS'
const GET_EXECUTION_INFO_ERROR = 'idfunctiondb/workflows/GET_EXECUTION_INFO_ERROR'
const GET_EXECUTION_INFO_CLEAR_ERROR = 'idfunctiondb/workflows/GET_EXECUTION_INFO_CLEAR_ERROR'

const GET_TODO_INFO_PENDING = 'idfunctiondb/workflows/GET_TODO_INFO_PENDING'
const GET_TODO_INFO_SUCCESS = 'idfunctiondb/workflows/GET_TODO_INFO_SUCCESS'
const GET_TODO_INFO_ERROR = 'idfunctiondb/workflows/GET_TODO_INFO_ERROR'
const GET_TODO_INFO_CLEAR_ERROR = 'idfunctiondb/workflows/GET_TODO_INFO_CLEAR_ERROR'

const PUT_EXECUTION_PENDING = 'idfunctiondb/workflows/PUT_EXECUTION_PENDING'
const PUT_EXECUTION_SUCCESS = 'idfunctiondb/workflows/PUT_EXECUTION_SUCCESS'
const PUT_EXECUTION_ERROR = 'idfunctiondb/workflows/PUT_EXECUTION_ERROR'
const PUT_EXECUTION_CLEAR_ERROR = 'idfunctiondb/workflows/PUT_EXECUTION_CLEAR_ERROR'

// put workflows/<id> form_id :create the relation between workflow and form -> from admin panel -> configure workflows
const PUT_WORKFLOW_PENDING = 'idfunctiondb/workflows/PUT_WORKFLOW_PENDING'
const PUT_WORKFLOW_SUCCESS = 'idfunctiondb/workflows/PUT_WORKFLOW_SUCCESS'
const PUT_WORKFLOW_ERROR = 'idfunctiondb/workflows/PUT_WORKFLOW_ERROR'
const PUT_WORKFLOW_CLEAR_ERROR = 'idfunctiondb/workflows/PUT_WORKFLOW_CLEAR_ERROR'

const DELETE_WORKFLOW_PENDING = 'idfunctiondb/workflows/DELETE_WORKFLOW_PENDING'
const DELETE_WORKFLOW_SUCCESS = 'idfunctiondb/workflows/DELETE_WORKFLOW_SUCCESS'
const DELETE_WORKFLOW_ERROR = 'idfunctiondb/workflows/DELETE_WORKFLOW_ERROR'
const DELETE_WORKFLOW_CLEAR_ERROR = 'idfunctiondb/workflows/DELETE_WORKFLOW_CLEAR_ERROR'

// post workflow_engine/hooks : initiate the workflow webhook (basically the submit button of workflowforms)
const POST_WEBHOOK_PENDING = 'idfunctiondb/workflows/POST_WEBHOOK_PENDING'
const POST_WEBHOOK_SUCCESS = 'idfunctiondb/workflows/POST_WEBHOOK_SUCCESS'
const POST_WEBHOOK_ERROR = 'idfunctiondb/workflows/POST_WEBHOOK_ERROR'
const POST_WEBHOOK_CLEAR_ERROR = 'idfunctiondb/workflows/POST_WEBHOOK_CLEAR_ERROR'

const POST_TODOS_WEBHOOK_PENDING = 'idfunctiondb/workflows/POST_TODOS_WEBHOOK_PENDING'
const POST_TODOS_WEBHOOK_SUCCESS = 'idfunctiondb/workflows/POST_TODOS_WEBHOOK_SUCCESS'
const POST_TODOS_WEBHOOK_ERROR = 'idfunctiondb/workflows/POST_TODOS_WEBHOOK_ERROR'
const POST_TODOS_WEBHOOK_CLEAR_ERROR = 'idfunctiondb/workflows/POST_TODOS_WEBHOOK_CLEAR_ERROR'

const POST_PUBLIC_HOOKS_PENDING = 'idfunctiondb/workflows/POST_PUBLIC_HOOKS_PENDING'
const POST_PUBLIC_HOOKS_SUCCESS = 'idfunctiondb/workflows/POST_PUBLIC_HOOKS_SUCCESS'
const POST_PUBLIC_HOOKS_ERROR = 'idfunctiondb/workflows/POST_PUBLIC_HOOKS_ERROR'
const POST_PUBLIC_HOOKS_CLEAR_ERROR = 'idfunctiondb/workflows/POST_PUBLIC_HOOKS_CLEAR_ERROR'

// post form to database : save the form structure to database -> build form screen
const POST_WORKFLOWFORM_PENDING = 'idfunctiondb/workflows/POST_WORKFLOWFORM_PENDING'
const POST_WORKFLOWFORM_SUCCESS = 'idfunctiondb/workflows/POST_WORKFLOWFORM_SUCCESS'
const POST_WORKFLOWFORM_ERROR = 'idfunctiondb/workflows/POST_WORKFLOWFORM_ERROR'
const POST_WORKFLOWFORM_CLEAR_ERROR = 'idfunctiondb/workflows/POST_WORKFLOWFORM_CLEAR_ERROR'

// get workflowforms : fetch the workflow forms that are saved in the database. these should be distributed through the app views
const GET_WORKFLOWFORMS_PENDING = 'idfunctiondb/workflows/GET_WORKFLOWFORMS_PENDING'
const GET_WORKFLOWFORMS_SUCCESS = 'idfunctiondb/workflows/GET_WORKFLOWFORMS_SUCCESS'
const GET_WORKFLOWFORMS_ERROR = 'idfunctiondb/workflows/GET_WORKFLOWFORMS_ERROR'
const GET_WORKFLOWFORMS_CLEAR_ERROR = 'idfunctiondb/workflows/GET_WORKFLOWFORMS_CLEAR_ERROR'

// initial

const initialState = {
  get_workflows: {
    success: [],
    pending: false,
    error: null,
  },
  get_public_workflows: {
    success: [],
    pending: false,
    error: null,
  },
  get_todos: {
    success: [],
    pending: false,
    error: null,
  },
  get_my_todos: {
    success: [],
    pending: false,
    error: null,
  },
  get_my_executions: {
    success: [],
    pending: false,
    error: null,
  },
  get_execution_info: {
    success: [],
    pending: false,
    error: null,
  },
  get_todo_info: {
    success: {},
    pending: false,
    error: null,
  },
  put_execution: {
    success: null,
    pending: false,
    error: null,
  },
  put_workflow: {
    success: null,
    pending: false,
    error: null,
  },
  delete_workflow: {
    success: null,
    pending: false,
    error: null,
  },
  post_webhook: {
    success: null,
    pending: false,
    error: null,
  },
  post_todos_webhook: {
    success: null,
    pending: false,
    error: null,
  },
  post_public_hooks: {
    success: null,
    pending: false,
    error: null,
  },
  post_workflowform: {
    success: null,
    pending: false,
    error: null,
  },
  get_workflowforms: {
    success: [],
    pending: false,
    error: null,
  },
}

// reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_WORKFLOWS_PENDING:
      return {
        ...state,
        get_workflows: {
          ...state.get_workflows,
          pending: true,
        },
      }
    case GET_WORKFLOWS_SUCCESS:
      return {
        ...state,
        get_workflows: {
          ...state.get_workflows,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_WORKFLOWS_ERROR:
      return {
        ...state,
        get_workflows: {
          ...state.get_workflows,
          error: action.payload,
          pending: false,
        },
      }
    case GET_WORKFLOWS_CLEAR_ERROR:
      return {
        ...state,
        get_workflows: {
          ...state.get_workflows,
          error: null,
        },
      }

    case GET_PUBLIC_WORKFLOWS_PENDING:
      return {
        ...state,
        get_public_workflows: {
          ...state.get_public_workflows,
          pending: true,
        },
      }
    case GET_PUBLIC_WORKFLOWS_SUCCESS:
      return {
        ...state,
        get_public_workflows: {
          ...state.get_public_workflows,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_PUBLIC_WORKFLOWS_ERROR:
      return {
        ...state,
        get_public_workflows: {
          ...state.get_public_workflows,
          error: action.payload,
          pending: false,
        },
      }
    case GET_PUBLIC_WORKFLOWS_CLEAR_ERROR:
      return {
        ...state,
        get_public_workflows: {
          ...state.get_public_workflows,
          error: null,
        },
      }

    case GET_TODOS_PENDING:
      return {
        ...state,
        get_todos: {
          ...state.get_todos,
          pending: true,
        },
      }
    case GET_TODOS_SUCCESS:
      return {
        ...state,
        get_todos: {
          ...state.get_todos,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_TODOS_ERROR:
      return {
        ...state,
        get_todos: {
          ...state.get_todos,
          error: action.payload,
          pending: false,
        },
      }
    case GET_TODOS_CLEAR_ERROR:
      return {
        ...state,
        get_todos: {
          ...state.get_todos,
          error: null,
        },
      }
    case GET_MY_TODOS_PENDING:
      return {
        ...state,
        get_my_todos: {
          ...state.get_my_todos,
          pending: true,
        },
      }
    case GET_MY_TODOS_SUCCESS:
      return {
        ...state,
        get_my_todos: {
          ...state.get_my_todos,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_MY_TODOS_ERROR:
      return {
        ...state,
        get_my_todos: {
          ...state.get_my_todos,
          error: action.payload,
          pending: false,
        },
      }
    case GET_MY_TODOS_CLEAR_ERROR:
      return {
        ...state,
        get_my_todos: {
          ...state.get_my_todos,
          error: null,
        },
      }

    case GET_MY_EXECUTIONS_PENDING:
      return {
        ...state,
        get_my_executions: {
          ...state.get_my_executions,
          pending: true,
        },
      }
    case GET_MY_EXECUTIONS_SUCCESS:
      return {
        ...state,
        get_my_executions: {
          ...state.get_my_executions,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_MY_EXECUTIONS_ERROR:
      return {
        ...state,
        get_my_executions: {
          ...state.get_my_executions,
          error: action.payload,
          pending: false,
        },
      }
    case GET_MY_EXECUTIONS_CLEAR_ERROR:
      return {
        ...state,
        get_my_executions: {
          ...state.get_my_executions,
          error: null,
        },
      }

    case GET_EXECUTION_INFO_PENDING:
      return {
        ...state,
        get_execution_info: {
          ...state.get_execution_info,
          pending: true,
        },
      }
    case GET_EXECUTION_INFO_SUCCESS:
      return {
        ...state,
        get_execution_info: {
          ...state.get_execution_info,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_EXECUTION_INFO_ERROR:
      return {
        ...state,
        get_execution_info: {
          ...state.get_execution_info,
          error: action.payload,
          pending: false,
        },
      }
    case GET_EXECUTION_INFO_CLEAR_ERROR:
      return {
        ...state,
        get_execution_info: {
          ...state.get_execution_info,
          error: null,
        },
      }

    case GET_TODO_INFO_PENDING:
      return {
        ...state,
        get_todo_info: {
          ...state.get_todo_info,
          pending: true,
        },
      }
    case GET_TODO_INFO_SUCCESS:
      return {
        ...state,
        get_todo_info: {
          ...state.get_todo_info,
          success: action.payload,
          pending: false,
        },
      }
    case GET_TODO_INFO_ERROR:
      return {
        ...state,
        get_todo_info: {
          ...state.get_todo_info,
          error: action.payload,
          pending: false,
        },
      }
    case GET_TODO_INFO_CLEAR_ERROR:
      return {
        ...state,
        get_todo_info: {
          ...state.get_todo_info,
          error: null,
        },
      }
    case PUT_WORKFLOW_PENDING:
      return {
        ...state,
        put_workflow: {
          ...state.put_workflow,
          pending: true,
        },
      }
    case PUT_WORKFLOW_SUCCESS:
      return {
        ...state,
        put_workflow: {
          ...state.put_workflow,
          success: action.payload,
          pending: false,
        },
      }
    case PUT_WORKFLOW_ERROR:
      return {
        ...state,
        put_workflow: {
          ...state.put_workflow,
          error: action.payload,
          pending: false,
        },
      }
    case PUT_WORKFLOW_CLEAR_ERROR:
      return {
        ...state,
        put_workflow: {
          ...state.put_workflow,
          error: null,
        },
      }
    case DELETE_WORKFLOW_PENDING:
      return {
        ...state,
        delete_workflow: {
          ...state.delete_workflow,
          pending: true,
        },
      }
    case DELETE_WORKFLOW_SUCCESS:
      return {
        ...state,
        delete_workflow: {
          ...state.delete_workflow,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_WORKFLOW_ERROR:
      return {
        ...state,
        delete_workflow: {
          ...state.delete_workflow,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_WORKFLOW_CLEAR_ERROR:
      return {
        ...state,
        delete_workflow: {
          ...state.delete_workflow,
          error: null,
        },
      }
    case PUT_EXECUTION_PENDING:
      return {
        ...state,
        put_execution: {
          ...state.put_execution,
          pending: true,
        },
      }
    case PUT_EXECUTION_SUCCESS:
      return {
        ...state,
        put_execution: {
          ...state.put_execution,
          success: action.payload,
          pending: false,
        },
      }
    case PUT_EXECUTION_ERROR:
      return {
        ...state,
        put_execution: {
          ...state.put_execution,
          error: action.payload,
          pending: false,
        },
      }
    case PUT_EXECUTION_CLEAR_ERROR:
      return {
        ...state,
        put_execution: {
          ...state.put_execution,
          error: null,
        },
      }
    case POST_WEBHOOK_PENDING:
      return {
        ...state,
        post_webhook: {
          ...state.post_webhook,
          pending: true,
        },
      }
    case POST_WEBHOOK_SUCCESS:
      return {
        ...state,
        post_webhook: {
          ...state.post_webhook,
          success: action.payload,
          pending: false,
        },
      }
    case POST_WEBHOOK_ERROR:
      return {
        ...state,
        post_webhook: {
          ...state.post_webhook,
          error: action.payload,
          pending: false,
        },
      }
    case POST_WEBHOOK_CLEAR_ERROR:
      return {
        ...state,
        post_webhook: {
          ...state.post_webhook,
          error: null,
        },
      }

    case POST_TODOS_WEBHOOK_PENDING:
      return {
        ...state,
        post_todos_webhook: {
          ...state.post_todos_webhook,
          pending: true,
        },
      }
    case POST_TODOS_WEBHOOK_SUCCESS:
      return {
        ...state,
        post_todos_webhook: {
          ...state.post_todos_webhook,
          success: action.payload,
          pending: false,
        },
      }
    case POST_TODOS_WEBHOOK_ERROR:
      return {
        ...state,
        post_todos_webhook: {
          ...state.post_todos_webhook,
          error: action.payload,
          pending: false,
        },
      }
    case POST_TODOS_WEBHOOK_CLEAR_ERROR:
      return {
        ...state,
        post_todos_webhook: {
          ...state.post_todos_webhook,
          error: null,
        },
      }

    case POST_PUBLIC_HOOKS_PENDING:
      return {
        ...state,
        post_public_hooks: {
          ...state.post_public_hooks,
          pending: true,
        },
      }
    case POST_PUBLIC_HOOKS_SUCCESS:
      return {
        ...state,
        post_public_hooks: {
          ...state.post_public_hooks,
          success: action.payload,
          pending: false,
        },
      }
    case POST_PUBLIC_HOOKS_ERROR:
      return {
        ...state,
        post_public_hooks: {
          ...state.post_public_hooks,
          error: action.payload,
          pending: false,
        },
      }
    case POST_PUBLIC_HOOKS_CLEAR_ERROR:
      return {
        ...state,
        post_public_hooks: {
          ...state.post_public_hooks,
          error: null,
        },
      }
    case POST_WORKFLOWFORM_PENDING:
      return {
        ...state,
        post_workflowform: {
          ...state.post_workflowform,
          pending: true,
          success: false,
          error: false,
        },
      }
    case POST_WORKFLOWFORM_SUCCESS:
      return {
        ...state,
        post_workflowform: {
          ...state.post_workflowform,
          success: action.payload,
          pending: false,
        },
      }
    case POST_WORKFLOWFORM_ERROR:
      return {
        ...state,
        post_workflowform: {
          ...state.post_workflowform,
          error: action.payload,
          pending: false,
        },
      }
    case POST_WORKFLOWFORM_CLEAR_ERROR:
      return {
        ...state,
        post_workflowform: {
          ...state.post_workflowform,
          error: null,
        },
      }
    case GET_WORKFLOWFORMS_PENDING:
      return {
        ...state,
        get_workflowforms: {
          ...state.get_workflowforms,
          pending: true,
        },
      }
    case GET_WORKFLOWFORMS_SUCCESS:
      return {
        ...state,
        get_workflowforms: {
          ...state.get_workflowforms,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_WORKFLOWFORMS_ERROR:
      return {
        ...state,
        get_workflowforms: {
          ...state.get_workflowforms,
          error: action.payload,
          pending: false,
        },
      }
    case GET_WORKFLOWFORMS_CLEAR_ERROR:
      return {
        ...state,
        get_workflowforms: {
          ...state.get_workflowforms,
          error: null,
        },
      }
    default:
      return state
  }
}
// actions

function getWorkflowsPending() {
  return {type: GET_WORKFLOWS_PENDING}
}
function getWorkflowsSuccess(payload) {
  return {type: GET_WORKFLOWS_SUCCESS, payload: payload}
}
function getWorkflowsError(error) {
  return {type: GET_WORKFLOWS_ERROR, payload: error}
}
export function getWorkflowsClearError() {
  return {type: GET_WORKFLOWS_CLEAR_ERROR}
}

function getPublicWorkflowsPending() {
  return {type: GET_PUBLIC_WORKFLOWS_PENDING}
}
function getPublicWorkflowsSuccess(payload) {
  return {type: GET_PUBLIC_WORKFLOWS_SUCCESS, payload: payload}
}
function getPublicWorkflowsError(error) {
  return {type: GET_PUBLIC_WORKFLOWS_ERROR, payload: error}
}
export function getPublicWorkflowsClearError() {
  return {type: GET_PUBLIC_WORKFLOWS_CLEAR_ERROR}
}

function getTodosPending() {
  return {type: GET_TODOS_PENDING}
}
function getTodosSuccess(payload) {
  return {type: GET_TODOS_SUCCESS, payload: payload}
}
function getTodosError(error) {
  return {type: GET_TODOS_ERROR, payload: error}
}
export function getTodosClearError() {
  return {type: GET_TODOS_CLEAR_ERROR}
}

function getMyTodosPending() {
  return {type: GET_MY_TODOS_PENDING}
}
function getMyTodosSuccess(payload) {
  return {type: GET_MY_TODOS_SUCCESS, payload: payload}
}
function getMyTodosError(error) {
  return {type: GET_MY_TODOS_ERROR, payload: error}
}
export function getMyTodosClearError() {
  return {type: GET_MY_TODOS_CLEAR_ERROR}
}

function getMyExecutionsPending() {
  return {type: GET_MY_EXECUTIONS_PENDING}
}
function getMyExecutionsSuccess(payload) {
  return {type: GET_MY_EXECUTIONS_SUCCESS, payload: payload}
}
function getMyExecutionsError(error) {
  return {type: GET_MY_EXECUTIONS_ERROR, payload: error}
}
export function getMyExecutionsClearError() {
  return {type: GET_MY_EXECUTIONS_CLEAR_ERROR}
}

function getExecutionInfoPending() {
  return {type: GET_EXECUTION_INFO_PENDING}
}
function getExecutionInfoSuccess(payload) {
  return {type: GET_EXECUTION_INFO_SUCCESS, payload: payload}
}
function getExecutionInfoError(error) {
  return {type: GET_EXECUTION_INFO_ERROR, payload: error}
}
export function getExecutionInfoClearError() {
  return {type: GET_EXECUTION_INFO_CLEAR_ERROR}
}

function getTodoInfoPending() {
  return {type: GET_TODO_INFO_PENDING}
}
function getTodoInfoSuccess(payload) {
  return {type: GET_TODO_INFO_SUCCESS, payload: payload}
}
function getTodoInfoError(error) {
  return {type: GET_TODO_INFO_ERROR, payload: error}
}

export function getTodoInfoClearError() {
  return {type: GET_TODO_INFO_CLEAR_ERROR}
}

function putExecutionPending() {
  return {type: PUT_EXECUTION_PENDING}
}

export function putExecutionSuccess(payload) {
  return {type: PUT_EXECUTION_SUCCESS, payload: payload}
}

function putExecutionError(error) {
  return {type: PUT_EXECUTION_ERROR, payload: error}
}

export function putExecutionClearError() {
  return {type: PUT_EXECUTION_CLEAR_ERROR}
}

function putWorkflowPending() {
  return {type: PUT_WORKFLOW_PENDING}
}
function putWorkflowSuccess(payload) {
  return {type: PUT_WORKFLOW_SUCCESS, payload: payload}
}
function putWorkflowError(error) {
  return {type: PUT_WORKFLOW_ERROR, payload: error}
}
export function putWorkflowClearError() {
  return {type: PUT_WORKFLOW_CLEAR_ERROR}
}

function deleteWorkflowPending() {
  return {type: DELETE_WORKFLOW_PENDING}
}
function deleteWorkflowSuccess(payload) {
  return {type: DELETE_WORKFLOW_SUCCESS, payload: payload}
}
function deleteWorkflowError(error) {
  return {type: DELETE_WORKFLOW_ERROR, payload: error}
}
export function deleteWorkflowClearError() {
  return {type: DELETE_WORKFLOW_CLEAR_ERROR}
}

function postWebhookPending() {
  return {type: POST_WEBHOOK_PENDING}
}
function postWebhookSuccess(payload) {
  return {type: POST_WEBHOOK_SUCCESS, payload: payload}
}
function postWebhookError(error) {
  return {type: POST_WEBHOOK_ERROR, payload: error}
}
export function postWebhookClearError() {
  return {type: POST_WEBHOOK_CLEAR_ERROR}
}

function postTodosWebhookPending() {
  return {type: POST_TODOS_WEBHOOK_PENDING}
}
function postTodosWebhookSuccess(payload) {
  return {type: POST_TODOS_WEBHOOK_SUCCESS, payload: payload}
}
function postTodosWebhookError(error) {
  return {type: POST_TODOS_WEBHOOK_ERROR, payload: error}
}
export function postTodosWebhookClearError() {
  return {type: POST_TODOS_WEBHOOK_CLEAR_ERROR}
}

function postPublicHooksPending() {
  return {type: POST_PUBLIC_HOOKS_PENDING}
}
function postPublicHooksSuccess(payload) {
  return {type: POST_PUBLIC_HOOKS_SUCCESS, payload: payload}
}
function postPublicHooksError(error) {
  return {type: POST_PUBLIC_HOOKS_ERROR, payload: error}
}
export function postPublicHooksClearError() {
  return {type: POST_PUBLIC_HOOKS_CLEAR_ERROR}
}

function postWorkflowformPending() {
  return {type: POST_WORKFLOWFORM_PENDING}
}
function postWorkflowformSuccess(payload) {
  return {type: POST_WORKFLOWFORM_SUCCESS, payload: payload}
}
function postWorkflowformError(error) {
  return {type: POST_WORKFLOWFORM_ERROR, payload: error}
}
export function postWorkflowformClearError() {
  return {type: POST_WORKFLOWFORM_CLEAR_ERROR}
}

function getWorkflowformsPending() {
  return {type: GET_WORKFLOWFORMS_PENDING}
}
function getWorkflowformsSuccess(payload) {
  return {type: GET_WORKFLOWFORMS_SUCCESS, payload: payload}
}
function getWorkflowformsError(error) {
  return {type: GET_WORKFLOWFORMS_ERROR, payload: error}
}
export function getWorkflowformsClearError() {
  return {type: GET_WORKFLOWFORMS_CLEAR_ERROR}
}

// operations

export const getWorkflows =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getWorkflowsPending())
    axios
      .get(`/api/v1/workflows?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getWorkflowsSuccess(res.data))
      })
      .catch((error) => dispatch(getWorkflowsError(error)))
  }
// TODO: write a seperate function
export const getActiveWorkflows =
  (limit = 1000, offset = 0, active = true) =>
  (dispatch) => {
    dispatch(getWorkflowsPending())
    axios
      .get(`/api/v1/workflows?limit=${limit}&offset=${offset}&filters=active eq '${active}'`)
      .then((res) => {
        dispatch(getWorkflowsSuccess(res.data))
      })
      .catch((error) => dispatch(getWorkflowsError(error)))
  }

export const getPublicWorkflows =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getPublicWorkflowsPending())
    axios
      .get(`api/v1/workflows/public?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getPublicWorkflowsSuccess(res.data))
      })
      .catch((error) => dispatch(getPublicWorkflowsError(error)))
  }

export const getTodos =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getTodosPending())
    axios
      .get(`/api/v1/todos?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getTodosSuccess(res.data))
      })
      .catch((error) => dispatch(getTodosError(error)))
  }

export const getTodoInfo = (id) => (dispatch) => {
  // TODO: this function and its associates is not used at all.
  dispatch(getTodoInfoPending())
  axios
    .get(`/api/v1/todos/${id}/info`)
    .then((res) => {
      dispatch(getTodoInfoSuccess(res.data))
    })
    .catch((error) => dispatch(getTodoInfoError(error)))
}

export const getMyTodos =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getMyTodosPending())
    axios
      .get(`/api/v1/auth/me/todos?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getMyTodosSuccess(res.data))
      })
      .catch((error) => dispatch(getMyTodosError(error)))
  }

export const getMyExecutions =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getMyExecutionsPending())
    axios
      .get(`/api/v1/auth/me/executions?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getMyExecutionsSuccess(res.data))
      })
      .catch((error) => dispatch(getMyExecutionsError(error)))
  }

export const getExecutionInfo = (id) => (dispatch) => {
  dispatch(getExecutionInfoPending())
  axios
    .get(`/api/v1/executions/${id}/info`)
    .then((res) => {
      dispatch(getExecutionInfoSuccess(res.data))
    })
    .catch((error) => dispatch(getExecutionInfoError(error)))
}

export const putExecution = (id, values) => (dispatch) => {
  dispatch(putExecutionPending())
  return axios
    .put(`/api/v1/executions/${id}`, values)
    .then((res) => {
      dispatch(putExecutionSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(putExecutionError(error)))
}

export const putWorkflow = (id, values) => (dispatch) => {
  dispatch(putWorkflowPending())
  return axios
    .put(`/api/v1/workflows/${id}`, values)
    .then((res) => {
      dispatch(putWorkflowSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(putWorkflowError(error)))
}

export const deleteWorkflow = (id) => (dispatch) => {
  dispatch(deleteWorkflowPending())
  return axios
    .delete(`/api/v1/workflows/${id}`)
    .then((res) => {
      dispatch(deleteWorkflowSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(deleteWorkflowError(error)))
}

export const getWorkflowforms =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getWorkflowformsPending())
    axios
      .get(`/api/v1/forms?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getWorkflowformsSuccess(res.data))
      })
      .catch((error) => dispatch(getWorkflowformsError(error)))
  }

// Endpoints which send data to workflow engine will send FormData now
function appendObjectToFormData(dataObject, formData) {
  for (const key in dataObject) {
    if (dataObject[key] && dataObject[key].file) {
      const fileData = dataObject[key]
      formData.append(key, fileData.file, fileData.name)
    } else {
      formData.append(key, dataObject[key])
    }
  }
}

export const postWebhook = (values, webhook) => (dispatch) => {
  dispatch(postWebhookPending())
  values['webhook'] = webhook
  const formData = new FormData()
  appendObjectToFormData(values, formData)
  return axios
    .post(`/api/v1/workflow_engine/hooks`, formData)
    .then((res) => {
      dispatch(postWebhookSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(postWebhookError(error))
    })
}

export const postTodosWebhook = (values, webhook) => (dispatch) => {
  dispatch(postTodosWebhookPending())
  values['webhook'] = webhook
  const formData = new FormData()
  appendObjectToFormData(values, formData)
  return axios
    .post(`/api/v1/workflow_engine/todos`, formData)
    .then((res) => {
      dispatch(postTodosWebhookSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(postTodosWebhookError(error))
    })
}

export const postPublicHooks = (values, webhook) => (dispatch) => {
  dispatch(postPublicHooksPending())
  values['webhook'] = webhook
  const formData = new FormData()
  appendObjectToFormData(values, formData)
  return axios
    .post(`/api/v1/workflow_engine/public_hooks`, formData)
    .then((res) => {
      dispatch(postPublicHooksSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(postPublicHooksError(error))
    })
}
// save the form structure to our database
export const postWorkflowform = (values) => (dispatch) => {
  dispatch(postWorkflowformPending())
  return axios
    .post(`/api/v1/forms`, values)
    .then((res) => {
      dispatch(postWorkflowformSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(postWorkflowformError(error))
    })
}
