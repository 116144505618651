import {useEffect, useState} from 'react'

export const QUERIES = {
  sm: 'max-width: 639px',
  md: '(max-width: 767px)',
  lg: 'max-width: 1023px',
  xl: 'max-width: 1279px',
}

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false)
  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [matches, query])

  return matches
}
