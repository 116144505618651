import {useRef, useEffect} from 'react'
import {Formik, Form} from 'formik'
import {SelectInput} from '../FormInputs'
import {
  attachPrinterToUser,
  getIDFunctionWebPrinters,
  getIDFunctionWebPrintersClearError,
  attachPrinterToUserClearError,
} from '../../stores/printers'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {FormikSaveButton} from '../Buttons'

export default function FormAssignPrinter() {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const printers = useSelector((state) => state.printers.get_idfunctionweb_printers.success)
  const printers_error = useSelector((state) => state.printers.get_idfunctionweb_printers.error)
  const attach_printer_success = useSelector(
    (state) => state.printers.attach_printer_to_user.success,
  )

  const attach_printer_pending = useSelector(
    (state) => state.printers.attach_printer_to_user.pending,
  )

  const attach_printer_error = useSelector((state) => state.printers.attach_printer_to_user.error)

  const printerSelectRef = useRef()

  useEffect(() => {
    dispatch(getIDFunctionWebPrinters())
  }, [dispatch, attach_printer_success, printers_error?.message])

  const initialValues = {
    id: '',
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          dispatch(attachPrinterToUser(values, userID))
          actions.resetForm()
          // resetting react-select components
          printerSelectRef.current.select.setValue('')
        }}
      >
        <Form>
          <SelectInput
            label={t('userManagement.ListOfPrinters')}
            placeholder={t('usermenu.SelectPrinter')}
            options={printers.map((o) => ({
              value: o.id,
              label: o.name,
            }))}
            innerRef={printerSelectRef}
            name="id"
            error={printers_error}
            setError={getIDFunctionWebPrintersClearError}
          />
          <FormikSaveButton
            type="submit"
            testid="form-assign-printer-submit"
            text={t('userManagement.Add')}
            primary
            pending={attach_printer_pending}
            error={attach_printer_error}
            setError={() => dispatch(attachPrinterToUserClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
