import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useFieldArray, useFormContext} from 'react-hook-form'
import {PlusCircleIcon, TrashIcon} from '@heroicons/react/outline'
import {getOptions} from './inputsSettingsConfig'
import DynamicInput from '../../formRendererRHF/DynamicInput'
import {ErrorMessage} from '../../formRendererRHF/ErrorMessage'
import {getDuplicatesFromArray} from '../../../utils/helpers'

interface InputOptionsSettingsProps {
  inputSettingsIndex: number
  header?: any
  labelKey?: string
  isRequired: boolean
  messageRequired?: string
}

const OptionsFieldArray: FC<InputOptionsSettingsProps> = ({
  inputSettingsIndex,
  labelKey,
  header,
  isRequired,
  messageRequired,
}) => {
  const {t} = useTranslation()
  const {control, formState, getFieldState} = useFormContext()
  const {fields, append, remove} = useFieldArray({
    control,
    name: `fields.${inputSettingsIndex}.options`,
    rules: {
      required: isRequired
        ? messageRequired || t('formBuilder.validation.options.required')
        : false, // isRequired
      validate: {
        unique: (options) => {
          const values = options.map((option: any) => option.value)
          const duplicates = getDuplicatesFromArray(values)
          return duplicates.length
            ? t('formBuilder.validation.options.duplicates', {DUPLICATES: duplicates.join(', ')})
            : true
        },
      },
    },
  })

  const error =
    getFieldState(`fields.${inputSettingsIndex}.options`, formState).error?.root?.message || ''

  const renderFields = (config, index) =>
    config.map((inputConfig) => (
      <DynamicInput
        key={inputConfig.name}
        {...inputConfig}
        name={`fields.${inputSettingsIndex}.options.${index}.${inputConfig.name}`}
      />
    ))

  // label star
  return (
    <>
      <ul className="w-full">
        {header || (
          <h4 className="">
            {t('formBuilder.InputOptions.header')}
            {isRequired && ' *'}
          </h4>
        )}
        {fields.map((item: any, index) => {
          return (
            <li key={item.id} className="my-2 rounded-md border border-gray-300 p-2">
              <p className="text-md flex justify-between font-medium text-gray-900">
                <span>
                  {t(labelKey || 'formBuilder.InputOptions.header')}: {index}
                </span>
                <button type="button" className="h-5 w-5" onClick={() => remove(index)}>
                  <TrashIcon className="text-red-400 hover:text-red-500" />
                </button>
              </p>
              {renderFields(getOptions(t), index)}
            </li>
          )
        })}

        <div className="flex h-5 w-full justify-end">
          <PlusCircleIcon
            className="text-lime-400 hover:text-lime-500"
            onClick={() =>
              append({
                value: '',
                label: '',
              })
            }
          />
        </div>
        <ErrorMessage error={error} />
      </ul>
    </>
  )
}

export default OptionsFieldArray
