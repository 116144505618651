import axios from '../services/api'
import {createNotification} from './notifications'
import I18n from '../i18n'
// Types
const GET_PRINTERS_PENDING = 'idfunctiondb/printers/GET_PRINTERS_PENDING'
const GET_PRINTERS_SUCCESS = 'idfunctiondb/printers/GET_PRINTERS_SUCCESS'
const GET_PRINTERS_ERROR = 'idfunctiondb/printers/GET_PRINTERS_ERROR'
const GET_PRINTERS_CLEAR_ERROR = 'idfunctiondb/printers/GET_PRINTERS_CLEAR_ERROR'

const GET_IDFUNCTIONWEB_PRINTERS_PENDING =
  'idfunctiondb/printers/GET_IDFUNCTIONWEB_PRINTERS_PENDING'
const GET_IDFUNCTIONWEB_PRINTERS_SUCCESS =
  'idfunctiondb/printers/GET_IDFUNCTIONWEB_PRINTERS_SUCCESS'
const GET_IDFUNCTIONWEB_PRINTERS_ERROR = 'idfunctiondb/printers/GET_IDFUNCTIONWEB_PRINTERS_ERROR'
const GET_IDFUNCTIONWEB_PRINTERS_CLEAR_ERROR =
  'idfunctiondb/printers/GET_IDFUNCTIONWEB_PRINTERS_CLEAR_ERROR'

const GET_IDFUNCTIONWEB_PRINTER_PENDING = 'idfunctiondb/printers/GET_IDFUNCTIONWEB_PRINTER_PENDING'
const GET_IDFUNCTIONWEB_PRINTER_SUCCESS = 'idfunctiondb/printers/GET_IDFUNCTIONWEB_PRINTER_SUCCESS'
const GET_IDFUNCTIONWEB_PRINTER_ERROR = 'idfunctiondb/printers/GET_IDFUNCTIONWEB_PRINTER_ERROR'

const UPDATE_IDFUNCTIONWEB_PRINTER_PENDING =
  'idfunctiondb/printers/UPDATE_IDFUNCTIONWEB_PRINTER_PENDING'
const UPDATE_IDFUNCTIONWEB_PRINTER_SUCCESS =
  'idfunctiondb/printers/UPDATE_IDFUNCTIONWEB_PRINTER_SUCCESS'
const UPDATE_IDFUNCTIONWEB_PRINTER_ERROR =
  'idfunctiondb/printers/UPDATE_IDFUNCTIONWEB_PRINTER_ERROR'
const UPDATE_IDFUNCTIONWEB_PRINTER_CLEAR_ERROR =
  'idfunctiondb/printers/UPDATE_IDFUNCTIONWEB_PRINTER_CLEAR_ERROR'

const DELETE_IDFUNCTIONWEB_PRINTER_PENDING =
  'idfunctiondb/printers/DELETE_IDFUNCTIONWEB_PRINTER_PENDING'
const DELETE_IDFUNCTIONWEB_PRINTER_SUCCESS =
  'idfunctiondb/printers/DELETE_IDFUNCTIONWEB_PRINTER_SUCCESS'
const DELETE_IDFUNCTIONWEB_PRINTER_ERROR =
  'idfunctiondb/printers/DELETE_IDFUNCTIONWEB_PRINTER_ERROR'

const GET_SELECTED_PRINTER_PENDING = 'idfunctiondb/printers/GET_SELECTED_PRINTER_PENDING'
const GET_SELECTED_PRINTER_SUCCESS = 'idfunctiondb/printers/GET_SELECTED_PRINTER_SUCCESS'
const GET_SELECTED_PRINTER_ERROR = 'idfunctiondb/printers/GET_SELECTED_PRINTER_ERROR'

const POST_PRINTER_PENDING = 'idfunctiondb/printers/POST_PRINTER_PENDING'
const POST_PRINTER_SUCCESS = 'idfunctiondb/printers/POST_PRINTER_SUCCESS'
const POST_PRINTER_ERROR = 'idfunctiondb/printers/POST_PRINTER_ERROR'
const POST_PRINTER_CLEAR_ERROR = 'idfunctiondb/printers/POST_PRINTER_CLEAR_ERROR'

const ATTACH_PRINTER_TO_USER_PENDING = 'idfunctiondb/printers/ATTACH_PRINTER_TO_USER_PENDING'
const ATTACH_PRINTER_TO_USER_SUCCESS = 'idfunctiondb/printers/ATTACH_PRINTER_TO_USER_SUCCESS'
const ATTACH_PRINTER_TO_USER_ERROR = 'idfunctiondb/printers/ATTACH_PRINTER_TO_USER_ERROR'
const ATTACH_PRINTER_TO_USER_CLEAR_ERROR =
  'idfunctiondb/printers/ATTACH_PRINTER_TO_USER_CLEAR_ERROR'

const initialState = {
  get_printers: {
    success: [],
    pending: false,
    error: null,
  },

  get_idfunctionweb_printers: {
    success: [],
    pending: false,
    error: null,
  },

  get_idfunctionweb_printer: {
    success: null,
    pending: false,
    error: null,
  },

  update_idfunctionweb_printer: {
    success: [],
    pending: false,
    error: null,
  },

  delete_idfunctionweb_printer: {
    success: [],
    pending: false,
    error: null,
  },

  get_selected_printer: {
    success: null,
    pending: false,
    error: null,
  },

  post_printer: {
    success: null,
    pending: false,
    error: null,
  },

  attach_printer_to_user: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PRINTERS_PENDING:
      return {
        ...state,
        get_printers: {
          ...state.get_printers,
          pending: true,
        },
      }
    case GET_PRINTERS_SUCCESS:
      return {
        ...state,
        get_printers: {
          ...state.get_printers,
          success: action.payload,
          pending: false,
        },
      }
    case GET_PRINTERS_ERROR:
      return {
        ...state,
        get_printers: {
          ...state.get_printers,
          error: action.payload,
          pending: false,
        },
      }
    case GET_PRINTERS_CLEAR_ERROR:
      return {
        ...state,
        get_printers: {
          ...state.get_printers,
          error: null,
          pending: false,
        },
      }
    case GET_IDFUNCTIONWEB_PRINTERS_PENDING:
      return {
        ...state,
        get_idfunctionweb_printers: {
          ...state.get_idfunctionweb_printers,
          pending: true,
        },
      }
    case GET_IDFUNCTIONWEB_PRINTERS_SUCCESS:
      return {
        ...state,
        get_idfunctionweb_printers: {
          ...state.get_idfunctionweb_printers,
          success: action.payload,
          pending: false,
        },
      }
    case GET_IDFUNCTIONWEB_PRINTERS_ERROR:
      return {
        ...state,
        get_idfunctionweb_printers: {
          ...state.get_idfunctionweb_printers,
          error: action.payload,
          pending: false,
        },
      }
    case GET_IDFUNCTIONWEB_PRINTERS_CLEAR_ERROR:
      return {
        ...state,
        get_idfunctionweb_printers: {
          ...state.get_idfunctionweb_printers,
          error: null,
          pending: false,
        },
      }
    case GET_IDFUNCTIONWEB_PRINTER_PENDING:
      return {
        ...state,
        get_idfunctionweb_printer: {
          ...state.get_idfunctionweb_printer,
          pending: true,
        },
      }
    case GET_IDFUNCTIONWEB_PRINTER_SUCCESS:
      return {
        ...state,
        get_idfunctionweb_printer: {
          ...state.get_idfunctionweb_printer,
          success: action.payload,
          pending: false,
        },
      }
    case GET_IDFUNCTIONWEB_PRINTER_ERROR:
      return {
        ...state,
        get_idfunctionweb_printer: {
          ...state.get_idfunctionweb_printer,
          error: action.payload,
          pending: false,
        },
      }
    case UPDATE_IDFUNCTIONWEB_PRINTER_PENDING:
      return {
        ...state,
        update_idfunctionweb_printer: {
          ...state.update_idfunctionweb_printer,
          pending: true,
        },
      }
    case UPDATE_IDFUNCTIONWEB_PRINTER_SUCCESS:
      return {
        ...state,
        update_idfunctionweb_printer: {
          ...state.update_idfunctionweb_printer,
          success: action.payload,
          pending: false,
        },
      }
    case UPDATE_IDFUNCTIONWEB_PRINTER_ERROR:
      return {
        ...state,
        update_idfunctionweb_printer: {
          ...state.update_idfunctionweb_printer,
          error: action.payload,
          pending: false,
        },
      }
    case UPDATE_IDFUNCTIONWEB_PRINTER_CLEAR_ERROR:
      return {
        ...state,
        update_idfunctionweb_printer: {
          ...state.update_idfunctionweb_printer,
          error: null,
          pending: false,
        },
      }
    case DELETE_IDFUNCTIONWEB_PRINTER_PENDING:
      return {
        ...state,
        delete_idfunctionweb_printer: {
          ...state.delete_idfunctionweb_printer,
          pending: true,
        },
      }
    case DELETE_IDFUNCTIONWEB_PRINTER_SUCCESS:
      return {
        ...state,
        delete_idfunctionweb_printer: {
          ...state.delete_idfunctionweb_printer,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_IDFUNCTIONWEB_PRINTER_ERROR:
      return {
        ...state,
        delete_idfunctionweb_printer: {
          ...state.delete_idfunctionweb_printer,
          error: action.payload,
          pending: false,
        },
      }
    case GET_SELECTED_PRINTER_PENDING:
      return {
        ...state,
        get_selected_printer: {
          ...state.get_selected_printer,
          pending: true,
        },
      }
    case GET_SELECTED_PRINTER_SUCCESS:
      return {
        ...state,
        get_selected_printer: {
          ...state.get_selected_printer,
          success: action.payload,
          pending: false,
        },
      }
    case GET_SELECTED_PRINTER_ERROR:
      return {
        ...state,
        get_selected_printer: {
          ...state.get_selected_printer,
          error: action.payload,
          pending: false,
        },
      }
    case POST_PRINTER_PENDING:
      return {
        ...state,
        post_printer: {
          ...state.post_printer,
          pending: true,
        },
      }
    case POST_PRINTER_SUCCESS:
      return {
        ...state,
        post_printer: {
          ...state.post_printer,
          success: action.payload,
          pending: false,
        },
      }
    case POST_PRINTER_ERROR:
      return {
        ...state,
        post_printer: {
          ...state.post_printer,
          error: action.payload,
          pending: false,
        },
      }
    case POST_PRINTER_CLEAR_ERROR:
      return {
        ...state,
        post_printer: {
          ...state.post_printer,
          error: null,
          pending: false,
        },
      }
    case ATTACH_PRINTER_TO_USER_PENDING:
      return {
        ...state,
        attach_printer_to_user: {
          ...state.attach_printer_to_user,
          pending: true,
        },
      }
    case ATTACH_PRINTER_TO_USER_SUCCESS:
      return {
        ...state,
        attach_printer_to_user: {
          ...state.attach_printer_to_user,
          success: action.payload,
          pending: false,
        },
      }
    case ATTACH_PRINTER_TO_USER_ERROR:
      return {
        ...state,
        attach_printer_to_user: {
          ...state.attach_printer_to_user,
          error: action.payload,
          pending: false,
        },
      }
    case ATTACH_PRINTER_TO_USER_CLEAR_ERROR:
      return {
        ...state,
        attach_printer_to_user: {
          ...state.attach_printer_to_user,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getPrintersPending() {
  return {type: GET_PRINTERS_PENDING}
}

function getPrintersSuccess(payload) {
  return {type: GET_PRINTERS_SUCCESS, payload: payload}
}

function getPrintersError(error) {
  return {type: GET_PRINTERS_ERROR, payload: error}
}

export function getPrintersClearError() {
  return {type: GET_PRINTERS_CLEAR_ERROR}
}

function getIDFunctionWebPrintersPending() {
  return {type: GET_IDFUNCTIONWEB_PRINTERS_PENDING}
}

function getIDFunctionWebPrintersSuccess(payload) {
  return {type: GET_IDFUNCTIONWEB_PRINTERS_SUCCESS, payload: payload}
}

function getIDFunctionWebPrintersError(error) {
  return {type: GET_IDFUNCTIONWEB_PRINTERS_ERROR, payload: error}
}

export function getIDFunctionWebPrintersClearError() {
  return {type: GET_IDFUNCTIONWEB_PRINTERS_CLEAR_ERROR}
}

function getIDFunctionWebPrinterPending() {
  return {type: GET_IDFUNCTIONWEB_PRINTER_PENDING}
}

function getIDFunctionWebPrinterSuccess(payload) {
  return {type: GET_IDFUNCTIONWEB_PRINTER_SUCCESS, payload: payload}
}

function getIDFunctionWebPrinterError(error) {
  return {type: GET_IDFUNCTIONWEB_PRINTER_ERROR, payload: error}
}

function updateIDFunctionWebPrinterPending() {
  return {type: UPDATE_IDFUNCTIONWEB_PRINTER_PENDING}
}

function updateIDFunctionWebPrinterSuccess(payload) {
  return {type: UPDATE_IDFUNCTIONWEB_PRINTER_SUCCESS, payload: payload}
}

function updateIDFunctionWebPrinterError(error) {
  return {type: UPDATE_IDFUNCTIONWEB_PRINTER_ERROR, payload: error}
}

export function updateIDFunctionWebPrinterClearError() {
  return {type: UPDATE_IDFUNCTIONWEB_PRINTER_CLEAR_ERROR}
}

function deleteIDFunctionWebPrinterPending() {
  return {type: DELETE_IDFUNCTIONWEB_PRINTER_PENDING}
}

function deleteIDFunctionWebPrinterSuccess(payload) {
  return {type: DELETE_IDFUNCTIONWEB_PRINTER_SUCCESS, payload: payload}
}

function deleteIDFunctionWebPrinterError(error) {
  return {type: DELETE_IDFUNCTIONWEB_PRINTER_ERROR, error: error}
}

function getSelectedPrinterPending() {
  return {type: GET_SELECTED_PRINTER_PENDING}
}

function getSelectedPrinterSuccess(payload) {
  return {type: GET_SELECTED_PRINTER_SUCCESS, payload: payload}
}

function getSelectedPrinterError(error) {
  return {type: GET_SELECTED_PRINTER_ERROR, payload: error}
}

function postPrinterPending() {
  return {type: POST_PRINTER_PENDING}
}

function postPrinterSuccess(payload) {
  return {type: POST_PRINTER_SUCCESS, payload: payload}
}

function postPrinterError(error) {
  return {type: POST_PRINTER_ERROR, payload: error}
}

export function postPrinterClearError() {
  return {type: POST_PRINTER_CLEAR_ERROR}
}

function attachPrinterToUserPending() {
  return {type: ATTACH_PRINTER_TO_USER_PENDING}
}

function attachPrinterToUserSuccess(payload) {
  return {type: ATTACH_PRINTER_TO_USER_SUCCESS, payload: payload}
}

function attachPrinterToUserError(error) {
  return {type: ATTACH_PRINTER_TO_USER_ERROR, payload: error}
}

export function attachPrinterToUserClearError() {
  return {type: ATTACH_PRINTER_TO_USER_CLEAR_ERROR}
}

// Operations
export const getPrinters = () => (dispatch) => {
  dispatch(getPrintersPending())
  return axios
    .get('/api/v1/card_designer/printers')
    .then((res) => {
      dispatch(getPrintersSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getPrintersError(error)))
}

export const getIDFunctionWebPrinters = () => (dispatch) => {
  dispatch(getIDFunctionWebPrintersPending())
  return axios
    .get(`/api/v1/printers`)
    .then((res) => {
      dispatch(getIDFunctionWebPrintersSuccess(res.data.results))
      return res.data
    })
    .catch((error) => dispatch(getIDFunctionWebPrintersError(error)))
}

export const getIDFunctionWebPrinter = (id) => (dispatch) => {
  dispatch(getIDFunctionWebPrinterPending())
  return axios
    .get(`/api/v1/printers/${id}`)
    .then((res) => {
      dispatch(getIDFunctionWebPrinterSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getIDFunctionWebPrinterError(error)))
}
export const updateIDFunctionWebPrinter = (id, values, userID) => (dispatch) => {
  dispatch(updateIDFunctionWebPrinterPending())
  return axios
    .put(`/api/v1/printers/${id}`, values)
    .then((res) => {
      dispatch(updateIDFunctionWebPrinterSuccess(res.data))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.PrinterUpdated', {name: res.data.name}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(updateIDFunctionWebPrinterError(error)))
}
export const deleteIDFunctionWebPrinter = (id, userID) => (dispatch) => {
  dispatch(deleteIDFunctionWebPrinterPending())
  return axios
    .delete(`/api/v1/printers/${id}`)
    .then((res) => {
      dispatch(deleteIDFunctionWebPrinterSuccess(res.data))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.PrinterDeleted', {name: res.data}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(deleteIDFunctionWebPrinterError(error)))
}

export const getSelectedPrinter = (id) => (dispatch) => {
  dispatch(getSelectedPrinterPending())
  return axios
    .get(`/api/v1/users/${id}/printer`)
    .then((res) => {
      dispatch(getSelectedPrinterSuccess(res.data))
    })
    .catch((error) => dispatch(getSelectedPrinterError(error)))
}

export const postPrinter = (values, userID) => (dispatch) => {
  dispatch(postPrinterPending())
  return axios
    .post('/api/v1/printers', values)
    .then((res) => {
      dispatch(postPrinterSuccess(res.data))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.PrinterAdded', {name: res.data.name}),
        }),
      )
    })
    .catch((error) => dispatch(postPrinterError(error)))
}

export const attachPrinterToUser = (values, userID) => (dispatch) => {
  dispatch(attachPrinterToUserPending())
  return axios
    .post(`/api/v1/users/${userID}/printer`, {printer_id: values.id})
    .then((res) => {
      dispatch(attachPrinterToUserSuccess(res))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.PrinterAttachedToUser', {name: res.data.name}), // TODO: notification message is english when language is german and french
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(attachPrinterToUserError(error)))
}
