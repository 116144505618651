import {FC} from 'react'
import {classNames} from '../utils/classNames'

export const textSizes = ['base', 'sm', 'lg']

interface TextProps {
  label: string
  bold?: boolean
  size?: number
}

const Text: FC<TextProps> = ({label, bold, size}) => {
  return (
    <p
      className={classNames(
        'text-gray-700',
        bold && 'font-bold',
        size && `text-${textSizes[size]}`,
      )}
    >
      {label}
    </p>
  )
}

export default Text
