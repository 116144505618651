import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {useTranslation} from 'react-i18next'
import axios from '../../services/api'
import React, {useState} from 'react'
import {InputComponent, InputYupValidationsType} from '../formRendererRHF/types'
import {TrashIcon} from '@heroicons/react/outline'
import Notification from '../Notification'

const FormCustomAttributes = ({resource, forceUpdate}) => {
  const {t} = useTranslation()

  const [pending, setPending] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [pendingDelete, setPendingDelete] = useState(false)
  const [errorDelete, setErrorDelete] = useState('')
  const [successDelete, setSuccessDelete] = useState(false)
  const handleDelete = async (key) => {
    setPendingDelete(true)
    setErrorDelete('')
    setSuccessDelete(false)
    try {
      await axios.delete(`/api/v1/access_profiles/${resource.id}/custom_attributes/${key}`)
      setSuccessDelete(true)
      forceUpdate()
    } catch (error) {
      setErrorDelete(error)
    }
    setPendingDelete(false)
  }

  const handleSubmit = async (values) => {
    setPending(true)
    setError(null)
    setSuccess(false)
    try {
      const transformValues = Object.values(values)
      const payload = {custom_attributes: [{[transformValues[0]]: transformValues[1]}]}
      await axios.post(`/api/v1/access_profiles/${resource.id}/custom_attributes`, payload)
      setSuccess(true)
      forceUpdate()
    } catch (error) {
      setError(error)
    }
    setPending(false)
  }

  const fields = [
    {
      component: InputComponent.TEXT_INPUT,
      name: 'value',
      label: 'addDynamicField.Value',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      name: 'label',
      label: 'addDynamicField.Label',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
  ]

  return (
    <>
      <>
        <ul className="w-full">
          {resource?.custom_attributes &&
            Object.entries(resource?.custom_attributes).map((attr) => (
              <>
                <li key={attr[0]} className="mb-5 flex justify-between">
                  <span className="w-1/5 font-bold">{attr[0]}</span>
                  <span className="grow">{attr[1]}</span>
                  <button
                    type="button"
                    className="h-5 w-5"
                    disabled={pendingDelete}
                    onClick={() => handleDelete(attr[0])}
                  >
                    <TrashIcon className="text-red-400 hover:text-red-500" />
                  </button>
                </li>
              </>
            ))}
        </ul>
        <Notification
          error={errorDelete}
          setError={() => {
            setErrorDelete('')
            setSuccessDelete(false)
          }}
          success={!!successDelete}
        />
      </>
      <DynamicRhfForm
        formConfig={{
          fields: fields,
          name: 'form-add-custom-attributes',
          description: t('addDynamicField.Title'),
          submitButtonText: t('formCommon.Add'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        infoMessage={
          <Notification
            error={error}
            setError={() => {
              setError(null)
              setSuccess(false)
            }}
            success={!!success}
          />
        }
      />
    </>
  )
}

export default FormCustomAttributes
