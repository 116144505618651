import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'created_at',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.CustomAttributes',
    accessor: 'custom_attributes',
    cell: 'CustomAttributesCell',
    label: 'TableLabelsCommon.CustomAttributes',
    noSort: true,
  },
  {
    header: 'ColumnFilter',
    accessor: 'description',
    label: 'TableLabelsCommon.Description',
  },
  {
    header: 'ColumnFilter',
    accessor: 'foreign_id',
    label: 'TableLabelsCommon.ForeignId',
  },
  {
    header: 'SelectColumnFilterLocations',
    accessor: 'location.description',
    label: 'resourceList.ResourceDomain',
    permissionsAccessor: 'listAccessProfilesLocations',
  },
  {
    header: 'ColumnFilter',
    accessor: 'name',
    label: 'TableLabelsCommon.Name',
  },
  {
    header: 'ColumnFilter',
    accessor: 'subsystem_id',
    label: 'TableLabelsCommon.SubsystemId',
  },
  {
    header: 'SelectColumnFilterResourceTypes',
    accessor: 'type.name',
    label: 'TableLabelsCommon.Type',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'resourceList.Actions',
    label: 'resourceList.Actions',
    accessor: 'id',
    width: 40,
    cell: 'ResourceTableActionsCell',
    noSort: true,
  },
]
export default columnConfig
