import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import Image from '../Image'
import ImageSrc from '../../assets/inventory.svg'
import {
  createAndSetInventoryPhoto,
  createAndSetInventoryPhotoClearError,
} from '../../stores/inventories_photos'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'

export default function FormEditImageUploadInventory({identity}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //select and upload an image (process and test) states
  const [uploadReadyImage, setUploadReadyImage] = useState(null)
  const [selectedImageToUpload, setSelectedImageToUpload] = useState(ImageSrc)

  const pending = useSelector(
    (state) => state.inventories_photos.create_and_set_inventory_photo.pending,
  )

  const {error, successMessage} = useSelector(
    (state) => state.inventories_photos.create_and_set_inventory_photo,
  )

  const fields = [
    {
      label: t('uploadFile'),
      component: InputComponent.FILE,
      name: 'inventory-image-fileInput',
      validationType: YupValidationType.OBJECT,
      onUpdate: (fileFieldValue) => {
        const imageURl = fileFieldValue && URL.createObjectURL(fileFieldValue)
        setSelectedImageToUpload(imageURl)
        setUploadReadyImage(fileFieldValue)
      },
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[2, 3], t('yupError.FileType')],
        },
        {
          type: InputYupValidationsType.FILESIZE,
          params: [10000000, t('yupError.MaxFileSize')],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.FileRequired')],
        },
      ],
    },
  ]

  return (
    <>
      <Image ImgSrc={selectedImageToUpload} />
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'file-submit',
          submitButtonText: t('addInventoryForm.InventoryAdd'),
        }}
        onSubmit={() =>
          dispatch(createAndSetInventoryPhoto(identity?.photos.list, uploadReadyImage))
        }
        pending={pending}
        noFormBorder
        infoMessage={
          <Notification
            error={error}
            setError={() => dispatch(createAndSetInventoryPhotoClearError())}
            success={successMessage}
          />
        }
      />
    </>
  )
}
