import {useRef} from 'react'
import {Formik, Form} from 'formik'
import {TextInput, SelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {setPerson, setPersonClearError} from '../../stores/persons'
import {iso8061UTC} from '../../utils/datetime'

export default function FormEditPerson({selectedItem}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const pending = useSelector((state) => state.persons.set_person.pending)
  const error = useSelector((state) => state.persons.set_person.error)

  const genderOptions = [
    {value: 'MALE', label: t('addPersonForm.Male')},
    {value: 'FEMALE', label: t('addPersonForm.Female')},
    {value: 'DIVERSE', label: t('addPersonForm.Diverse')},
  ]

  const initialValues = {
    title: selectedItem.title || '',
    first_name: selectedItem.first_name || '',
    last_name: selectedItem.last_name || '',
    birthday: new Date(selectedItem.birthday).toISOString().split('T')[0],
    gender: selectedItem.gender_name || '', // TODO: backend returns gender_name on get but expexts gender on put not consistent
    phone: selectedItem.phone || '',
    mobile: selectedItem.mobile || '',
    email: selectedItem.email || '',
    country: selectedItem.country || '',
    city: selectedItem.city || '',
    zip: selectedItem.zip || '',
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .max(256, t('yupError.MaxCharsAllowed', {numberOfChars: '256'}))
      .required(t('yupError.ThisFieldIsRequired', {field: t('addPersonForm.FirstName')})), // 256
    last_name: Yup.string()
      .max(256, t('yupError.MaxCharsAllowed', {numberOfChars: '256'}))
      .required(t('yupError.ThisFieldIsRequired', {field: t('addPersonForm.LastName')})), // 256
  })

  const validateEmail = (email) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const handleSubmit = (values, actions) => {
    if (values.birthday === '') {
      delete values.birthday
    } else {
      values['birthday'] = iso8061UTC(values.birthday)
    }
    const isEmailValid = validateEmail(values.email)
    if (!isEmailValid) {
      delete values.email
    }
    dispatch(setPerson(selectedItem.id, values))
    actions.resetForm()
  }

  let genderSelectRef = useRef()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextInput label={t('addPersonForm.Title')} name="title" type="text" placeholder="" />
          <TextInput
            label={t('addPersonForm.FirstName')}
            name="first_name"
            type="text"
            placeholder=""
          />
          <TextInput
            label={t('addPersonForm.LastName')}
            name="last_name"
            type="text"
            placeholder=""
          />
          <TextInput
            label={t('addPersonForm.Birthdate')}
            name="birthday"
            type="date"
            placeholder=""
          />
          <SelectInput
            label={t('addPersonForm.Gender')}
            name="gender"
            innerRef={genderSelectRef}
            options={genderOptions}
            placeholder={t('setAvatarForm.Select')}
          />
          <TextInput label={t('addPersonForm.Phone')} name="phone" type="text" placeholder="" />
          <TextInput label={t('addPersonForm.Mobile')} name="mobile" type="text" placeholder="" />
          <TextInput label={t('addPersonForm.Email')} name="email" type="text" placeholder="" />
          <TextInput label={t('addPersonForm.Country')} name="country" type="text" placeholder="" />
          <TextInput label={t('addPersonForm.City')} name="city" type="text" placeholder="" />
          <TextInput label={t('addPersonForm.Zip')} name="zip" type="text" placeholder="" />
          <FormikSaveButton
            type="submit"
            testid="form-edit-person-submit"
            text={t('addPersonForm.Save')}
            primary
            error={error}
            pending={pending}
            setError={() => dispatch(setPersonClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
