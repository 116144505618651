import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {getCompaniesCLearError, deleteCompanyClearError} from '../../stores/companies'
import {createQuickVisitor, createQuickVisitorClearError} from '../../stores/visitors'
import {iso8061UTC} from '../../utils/datetime'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import ErrorOnView from '../ErrorOnView'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import AddNewItem from '../AddNewItemRHF'
import {ModalContentsBase} from '../Modal'
import FormAddCompany from './FormAddCompany'
import FormEditCompany from './FormEditCompany'
import {useCompanyOptions} from '../../hooks/useCompanies'

export default function FormAddVisitorQuick() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const get_companiesError = useSelector((state) => state.companies.get_companies.error)
  const pending = useSelector((state) => state.visitors.create_quick_visitor.pending)
  const error = useSelector((state) => state.visitors.create_quick_visitor.error)
  const delete_company = useSelector((state) => state.companies.delete_company)

  const handleSubmit = (values) => {
    values['visitor_location_id'] = values.location
    values['person_location_id'] = values.location
    values['person-email'] = values['visitor-email']
    values['visitor-valid_from'] = iso8061UTC(values['visitor-valid_from'])
    values['visitor-valid_to'] = iso8061UTC(values['visitor-valid_to'])
    delete values['add-company']
    dispatch(createQuickVisitor(values, userID))
  }

  const {companies, defaultCompany} = useCompanyOptions()

  const fields = [
    {
      component: InputComponent.ASYNCREACTSELECT,
      placeholder: t('addVisitationForm.HostEmployeePlaceholder'),
      name: 'visitor-host_id',
      label: t('addVisitationForm.HostEmployee'),
      optionLabel: 'full_name',
      optionValue: 'id',
      nestedOptionLabel: 'person',
      url: '/api/v1/employees?q=',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'visitor-valid_from',
      inputType: 'datetime-local',
      label: t('addVisitationForm.FromDate'),
      validationType: YupValidationType.DATE,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'visitor-valid_to',
      inputType: 'datetime-local',
      label: t('addVisitationForm.ToDate'),
      validationType: YupValidationType.DATE,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
        {
          type: InputYupValidationsType.ENDDATE, // special type for validation
          params: ['visitor-valid_from', t('yupError.DateRange')],
        },
      ],
    },
    {
      label: t('addPersonForm.FirstName'),
      component: InputComponent.TEXT_INPUT,
      name: 'person-first_name',
    },
    {
      label: t('addPersonForm.LastName'),
      component: InputComponent.TEXT_INPUT,
      name: 'person-last_name',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      label: t('employeePersonalInfo.PersonalNumber'),
      component: InputComponent.TEXT_INPUT,
      name: 'person-number',
    },
    {
      label: t('addVisitor.VisitorNumber'),
      component: InputComponent.TEXT_INPUT,
      name: 'visitor-visitor_number',
    },
    {
      label: t('login.Email'),
      component: InputComponent.TEXT_INPUT,
      name: 'visitor-email',
      validations: [
        {
          type: InputYupValidationsType.EMAIL,
          params: [t('yupError.EmailInvalid')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `company_id`,
      label: t('employeeCompanyInfo.Company'),
      placeholder: t('addEmployee.CompanyPlaceholder'),
      isMulti: false,
      options: companies,
      defaultValue: defaultCompany?.id,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.CUSTOM,
      name: 'add-company',
      label: 'add-company',
      renderFn: permissions.createCompany ? (
        <AddNewItem
          fieldName="company_id"
          label={t('employeeCompanyInfo.ManageCompany')}
          addComponent={
            <ModalContentsBase title={t('addCompanyForm.AddCompanyTitle')}>
              <FormAddCompany />
            </ModalContentsBase>
          }
          deleteState={delete_company}
          setDeleteError={deleteCompanyClearError}
          options={companies}
          editComponent={(selectedItem) => (
            <ModalContentsBase title={t('addCompanyForm.EditCompany')}>
              <FormEditCompany notify={true} selectedItem={selectedItem} />
            </ModalContentsBase>
          )}
        />
      ) : null,
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: t('addEmployee.Domain'),
      placeholder: t('addEmployee.DomainPlaceholder'),
      isMulti: false,
      options: permissions.createEmployeeLocations,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: '',
          submitButtonText: t('addPersonForm.Save'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        infoMessage={
          <Notification error={error} setError={() => dispatch(createQuickVisitorClearError())} />
        }
      />
      {!!get_companiesError && (
        <ErrorOnView
          error={get_companiesError}
          setError={() => dispatch(getCompaniesCLearError())}
        />
      )}
    </>
  )
}
