import {getIncomers, getOutgoers, Position} from 'reactflow'
import dagre from 'dagre'
import {CUSTOM_NODE_SIZE, NODE_TYPE_CHILD, NODE_TYPE_PARENT, NODE_TYPE_ROOT} from './consts'

const nodeDefaults = {
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
  type: 'custom',
}

export const getIsExpanded = (edges, id, isParent) => {
  return !edges.filter((edge) =>
    isParent ? edge.target === id && edge.hidden : edge.source === id && edge.hidden,
  ).length
}

export const getLayoutedElements = (dagreGraph, nodes, edges, direction = 'LR') => {
  const isHorizontal = direction === 'LR'
  dagreGraph.setGraph({rankdir: direction})

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {width: CUSTOM_NODE_SIZE.x, height: CUSTOM_NODE_SIZE.y})
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    node.targetPosition = isHorizontal ? 'left' : 'top'
    node.sourcePosition = isHorizontal ? 'right' : 'bottom'

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - CUSTOM_NODE_SIZE.x / 2,
      y: nodeWithPosition.y - CUSTOM_NODE_SIZE.y / 2,
    }

    node.data = {
      ...node.data,
      isExpandable: !!(node.id.includes(NODE_TYPE_PARENT)
        ? getIncomers(node, nodes, edges).length
        : getOutgoers(node, nodes, edges).length),
    }

    return node
  })

  return {nodes, edges}
}

export const getRenderData = ({root, children, parents, childrenAccessor, parentAccessor}) => {
  const labelAccessor = childrenAccessor.includes('identities') ? 'owner_name' : 'name'
  const initialNodes = [
    {
      data: {
        label: root?.[labelAccessor],
        dataId: root?.id,
        deep: 0,
        type: NODE_TYPE_ROOT,
        id: NODE_TYPE_ROOT,
      },
      hidden: false,
      id: NODE_TYPE_ROOT,
      ...nodeDefaults,
    },
  ]
  const initialEdges: any[] = []
  let currentIndex = 0

  const mapChildren = (children, parentId, deep, isParent) =>
    children?.map((child) => {
      const accessor = isParent ? parentAccessor : childrenAccessor
      const type = isParent ? NODE_TYPE_PARENT : NODE_TYPE_CHILD
      const id = `${type}_${currentIndex}`

      initialNodes.push({
        id,
        data: {
          label: child[labelAccessor],
          dataId: child.id,
          deep,
          type,
          id,
        },
        hidden: deep > 1,
        ...nodeDefaults,
      })

      initialEdges.push({
        id: `${parentId}--${id}`,
        source: isParent ? id : parentId,
        target: isParent ? parentId : id,
        hidden: deep > 1,
      })

      currentIndex++
      return {
        dataId: child.id,
        name: child.name,
        id,
        deep,
        parentId,
        children: mapChildren(child[accessor], id, deep + 1, isParent),
      }
    })

  children && mapChildren(children, NODE_TYPE_ROOT, 1, false)
  parents && mapChildren(parents, NODE_TYPE_ROOT, 1, true)

  return {
    initialNodes,
    initialEdges,
  }
}
