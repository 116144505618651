import React from 'react'
import {withTranslation} from 'react-i18next'
import {FormLayout} from './FormLayout'
import {Button} from './Buttons'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {hasError: true}
  }

  componentDidCatch(error, info) {
    // error logger
  }

  render() {
    if (this.state.hasError) {
      return (
        <FormLayout title={this.props.t('errorBoundary.Title')}>
          <p className="mt-6 max-w-3xl px-4 text-center text-xl text-gray-800 sm:px-6 lg:px-8">
            {this.props.t('errorBoundary.Text')}
          </p>
          <div className="mt-2 text-center">
            <Button anchor text={this.props.t('errorBoundary.Home')} href="/" />
          </div>
        </FormLayout>
      )
    }

    return this.props.children
  }
}

export default withTranslation()(ErrorBoundary)
