import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'InventoryHistoryInfo.AssignedTo',
    label: 'InventoryHistoryInfo.AssignedTo',
    accessor: 'assigned_to',
    cell: 'LinkForwardCell',
    secondAccessor: 'assigned_to_url',
  },
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'InventoryHistoryInfo.CreatedById',
    accessor: 'created_by_id',
    label: 'InventoryHistoryInfo.CreatedById',
  },
  {
    header: 'TableLabelsCommon.DeletedAt',
    accessor: 'deleted_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.DeletedAt',
  },
  {
    header: 'ID',
    accessor: 'id',
    label: 'ID',
  },
  {
    header: 'InventoryHistoryInfo.InventoryId',
    accessor: 'inventory_id',
    label: 'InventoryHistoryInfo.InventoryId',
  },
  {
    header: 'TableLabelsCommon.Notes',
    accessor: 'notes',
    label: 'TableLabelsCommon.Notes',
  },
  {
    header: 'TableLabelsCommon.Type',
    accessor: 'operation_type',
    label: 'TableLabelsCommon.Type',
  },
  {
    header: 'InventoryHistoryInfo.RelatedTo',
    label: 'InventoryHistoryInfo.RelatedTo',
    accessor: 'related_to',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'InventoryHistoryInfo.UpdatedById',
    accessor: 'updated_by_id',
    label: 'InventoryHistoryInfo.UpdatedById',
  },
  {
    header: 'TableLabelsCommon.ValidFrom',
    accessor: 'valid_from',
    cell: 'DateCell',
    label: 'TableLabelsCommon.ValidFrom',
  },
  {
    header: 'TableLabelsCommon.ValidTo',
    accessor: 'valid_to',
    cell: 'DateCell',
    label: 'TableLabelsCommon.ValidTo',
  },
  {
    header: 'TableLabelsCommon.VisitorId',
    accessor: 'visitor_id',
    label: 'TableLabelsCommon.VisitorId',
  },
]
export default columnConfig
