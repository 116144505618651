import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {useTranslation} from 'react-i18next'
import {
  updateAccessProfileIdentity,
  updateAccessProfileIdentityError,
} from '../../stores/access_profiles_identities'
import {useDispatch, useSelector} from 'react-redux'
import Notification from '../Notification'

const FormExtendResourceIdentitiesValidity = ({id}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {pending, error} = useSelector((state) => state.access_profiles_identities.update)
  const handleSubmit = (values) => {
    dispatch(
      updateAccessProfileIdentity({
        id,
        values,
      }),
    )
  }
  const fields = [
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_to',
      inputType: 'datetime-local',
      allowEndless: true,
      label: 'datePickerForms.ValidTo',
      validationType: YupValidationType.DATE,
      defaultValue: new Date().toISOString().slice(0, -8),
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
        {
          type: InputYupValidationsType.ENDDATE, // special type for validation
          params: ['valid_from', 'yupError.DateRange'],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-add-resource-identity',
          submitButtonText: t('formCommon.Save'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        infoMessage={
          <Notification
            error={error}
            setError={() => dispatch(updateAccessProfileIdentityError())}
          />
        }
      />
    </>
  )
}

export default FormExtendResourceIdentitiesValidity
