import {ColumnConfig} from '../types'

const columnConfigPerson: ColumnConfig[] = [
  {
    header: 'ColumnFilterDate',
    accessor: 'birthday',
    cell: 'DateCell',
    label: 'TableLabelsCommon.Birthday',
  },
  {
    header: 'ColumnFilter',
    accessor: 'card_pin',
    label: 'personList.CardPin',
  },
  {
    header: 'ColumnFilter',
    accessor: 'city',
    label: 'TableLabelsCommon.City',
  },
  {
    header: 'ColumnFilter',
    accessor: 'country',
    label: 'TableLabelsCommon.Country',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.CustomAttributes',
    accessor: 'custom_attributes',
    noSort: true,
    cell: 'CustomAttributesCell',
    label: 'TableLabelsCommon.CustomAttributes',
  },
  {
    header: 'ColumnFilter',
    accessor: 'email',
    label: 'personList.EMail',
  },
  {
    header: 'TableLabelsCommon.EmployeeId',
    accessor: 'employee_id',
    label: 'TableLabelsCommon.EmployeeId',
    noSort: true,
  },
  {
    header: 'ColumnFilter',
    accessor: 'first_name',
    label: 'TableLabelsCommon.FirstName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'full_name',
    label: 'TableLabelsCommon.FullName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'gender_name',
    label: 'TableLabelsCommon.GenderName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'last_name',
    label: 'TableLabelsCommon.LastName',
  },
  {
    header: 'SelectColumnFilterLocations',
    accessor: 'location',
    label: 'personList.Domain',
    permissionsAccessor: 'listPersonsLocations',
    cell: 'LocationCell',
  },
  {
    header: 'ColumnFilter',
    accessor: 'mobile',
    label: 'TableLabelsCommon.Mobile',
  },
  {
    header: 'ColumnFilter',
    accessor: 'number',
    label: 'personList.Number',
  },
  {
    header: 'ColumnFilter',
    accessor: 'phone',
    label: 'TableLabelsCommon.Phone',
  },
  {
    header: 'ColumnFilter',
    accessor: 'preferred_language',
    label: 'TableLabelsCommon.PreferredLanguage',
  },
  {
    header: 'ColumnFilter',
    accessor: 'preferred_timezone',
    label: 'TableLabelsCommon.PreferredTimezone',
  },
  {
    header: 'ColumnFilter',
    accessor: 'street',
    label: 'TableLabelsCommon.Street',
  },
  {
    header: 'ColumnFilter',
    accessor: 'title',
    label: 'TableLabelsCommon.Title',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'TableLabelsCommon.VisitorId',
    accessor: 'visitor_id',
    label: 'TableLabelsCommon.VisitorId',
    noSort: true,
  },
  {
    header: 'ColumnFilter',
    accessor: 'zip',
    label: 'TableLabelsCommon.Zip',
  },
  {
    header: 'personList.Actions',
    label: 'personList.Actions',
    accessor: 'id',
    width: 40,
    cell: 'PersonActionCell',
    noSort: true,
  },
]

export default columnConfigPerson
