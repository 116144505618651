import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/outline'
import {Link} from 'react-router-dom'

export default function IdentityNavigationArrows({next, previous, navTo}) {
  return (
    <div className="flex flex-nowrap">
      {previous !== null && (
        <Link to={`${navTo}/${previous}`}>
          <ChevronLeftIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
        </Link>
      )}
      {next !== null && (
        <Link to={`${navTo}/${next}`}>
          <ChevronRightIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
        </Link>
      )}
    </div>
  )
}
