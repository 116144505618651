import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'ColumnFilterDate',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'deleted_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.DeletedAt',
  },
  {
    header: 'employeeResource.Access',
    accessor: 'access',
    cell: 'BooleanCell',
    label: 'employeeResource.Access',
  },
  {
    header: 'ColumnFilter',
    accessor: 'resource_id',
    label: 'ID',
  },
  {
    header: 'ColumnFilter',
    accessor: 'resource',
    label: 'TableLabelsCommon.Name',
  },
  {
    header: 'ColumnFilter',
    accessor: 'resource_type',
    label: 'TableLabelsCommon.Type',
  },
  {
    header: 'ColumnFilter',
    accessor: 'notes',
    label: 'TableLabelsCommon.Notes',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'valid_from',
    cell: 'DateCell',
    label: 'TableLabelsCommon.ValidFrom',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'valid_to',
    cell: 'DateCell',
    label: 'TableLabelsCommon.ValidTo',
  },
  {
    header: 'Actions',
    label: 'Actions',
    width: 40,
    accessor: 'id',
    cell: 'ResourceIdentitiesTableActionsCell',
    noSort: true,
  },
]

export default columnConfig
