import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18n'
import {Provider} from 'react-redux'
import createAndPersistStore from './store'
import {PersistGate} from 'redux-persist/integration/react'
import setupInterceptors from './services/setupInterceptors'

import('urlpattern-polyfill')

const root = ReactDOM.createRoot(document.getElementById('root'))
export const {store, persistor} = createAndPersistStore()

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

setupInterceptors(store)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
