import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'ColumnFilterDate',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'SelectColumnFilterLocations',
    label: 'workflows.Location',
    accessor: 'location.description',
    permissionsAccessor: 'listIdentitiesLocations',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'deleted_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.DeletedAt',
  },
  {
    header: 'ColumnFilter',
    accessor: 'name',
    label: 'TableLabelsCommon.Name',
  },
  {
    header: 'ColumnFilter',
    accessor: 'description',
    label: 'TableLabelsCommon.Description',
  },
  {
    header: 'Actions',
    label: 'Actions',
    width: 40,
    accessor: 'id',
    cell: 'IdentitiesTableActionsCell',
    noSort: true,
  },
]

export default columnConfig
