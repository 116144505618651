import Collapse from '../Collapse'
import {useTranslation} from 'react-i18next'
import FormAddLogo from '../forms/FormAddLogo'
import FormAddHero from '../forms/FormAddHero'
import FormAddImpressum from '../forms/FormAddImpressum'
import FormEditServerPreferences from '../forms/FormEditServerPreferences'
import FormAddPrivacyPolicy from '../forms/FormAddPrivacyPolicy'
import {useSelector} from 'react-redux'
import Image from '../Image'

export default function StaticAssetsWrapper() {
  const {t} = useTranslation()
  const logo = useSelector((state) => state.static_assets.get_logo.success)
  const {pending: logoPending} = useSelector((state) => state.static_assets.upload_logo)
  const {pending: heroPending} = useSelector((state) => state.static_assets.upload_hero)
  const hero = useSelector((state) => state.static_assets.get_hero.success)

  return (
    <>
      <Collapse title={t('userManagement.ImportLogo')}>
        <p className="py-1 text-sm text-gray-700">{t('currentLogo')}:</p>
        <Image
          ImgSrc={logoPending ? '' : logo}
          alt="currentLogoImg"
          class="h-16 w-auto"
          loaderHeight="h-16"
        />
        <FormAddLogo />
      </Collapse>
      <Collapse title={t('userManagement.ImportHero')}>
        <p className="py-1 text-sm text-gray-700">{t('currentHero')}:</p>
        <Image ImgSrc={heroPending ? '' : hero} alt="currentHeroImg" class="h-52 w-auto" />
        <p className="py-1 text-sm text-gray-700">{t('imageAspectRatio')}</p>
        <FormAddHero />
      </Collapse>
      <Collapse title={t('userManagement.AddImpressum')}>
        <FormAddImpressum />
      </Collapse>
      <Collapse title={t('userManagement.AddPrivacyPolicy')}>
        <FormAddPrivacyPolicy />
      </Collapse>
      <Collapse title={t('userManagement.EditServerPreferences')}>
        <FormEditServerPreferences />
      </Collapse>
    </>
  )
}
