import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {getExecutionInfo, getExecutionInfoClearError} from '../stores/workflows'
import SpinnerSVG from '../assets/Spinner.svg'
import ErrorOnView from './ErrorOnView'
import axios from '../services/api'
import {DateCell} from './table/TableCells'
import {isEmpty} from '../utils/custom_attributes'

export default function InfoBoxFetch({id}) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getExecutionInfo(id))
  }, [dispatch, id])
  //TODO: bring back the pending and error states later
  const infos = useSelector((state) => state.workflows.get_execution_info.success)
  const infoError = useSelector((state) => state.workflows.get_execution_info.error)
  const {t} = useTranslation()

  return (
    <>
      <div className="mx-auto flex items-center justify-center">
        <div className="mt-2">
          {infos ? (
            infos.map((info) => {
              return (
                <div className="divide-y divide-lime-300 rounded-md border-2 border-lime-200 shadow-sm">
                  <p className="p-1 text-sm text-gray-500">
                    <b className="text-gray-900"> {`${t('workflows.Approver')}:`} </b>{' '}
                    {info?.full_name}
                  </p>
                  <p className="p-1 text-sm text-gray-500">
                    <b className="text-gray-900"> {`${t('workflows.Approver')} email:`} </b>
                    {info.email}
                  </p>
                  <p className="p-1 text-sm text-gray-500">
                    <b className="pr-1 text-gray-900"> {`${t('workflows.ExpiresOn')}:`} </b>{' '}
                    {info.expires_at ? <DateCell value={info?.expires_at} /> : 'No Valid Time'}
                  </p>
                </div>
              )
            })
          ) : infoError ? (
            <ErrorOnView
              error={infoError}
              setError={() => dispatch(getExecutionInfoClearError())}
            />
          ) : null}
        </div>
      </div>
    </>
  )
}

export function InfoBoxFetchTodoInfo({id, custom_attributes}) {
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(null)
  const [data, setData] = useState({})

  const renderCustomAttributes = isEmpty(custom_attributes)
    ? []
    : Object.keys(custom_attributes).map((key) => {
        return (
          <p className=" flex p-1 text-sm text-gray-500" key={key}>
            <b className="pr-1 text-gray-900"> {`${key}:`}</b>
            {custom_attributes[key]}
          </p>
        )
      })

  useEffect(() => {
    const fetchData = async (id) => {
      setError(null)
      setLoading(true)
      try {
        const response = await axios.get(`/api/v1/todos/${id}/info`)
        setData(response.data)
      } catch (error) {
        setError(error)
      }
      setLoading(false)
    }
    fetchData(id)
  }, [id])

  const {t} = useTranslation()

  return (
    <>
      <div className="mx-auto flex items-center justify-start">
        <div className="mx-2">
          {isLoading ? (
            <div className="flex items-start justify-center p-2 text-sm">
              <img alt="o" src={SpinnerSVG} className="mr-3 h-6 w-6 animate-spin text-lime-500" />
              {t('loading')}
            </div>
          ) : !isError ? (
            <div className="divide-y divide-lime-300 rounded-md border-2 border-lime-200 shadow-sm">
              <p className="p-1 text-sm text-gray-500">
                <b className="text-gray-900"> {`${t('workflows.Requester')}:`} </b>
                {data?.full_name}
              </p>
              <p className="p-1 text-sm text-gray-500">
                <b className="text-gray-900"> {`${t('workflows.Requester')} E-Mail:`} </b>
                {data.email}
              </p>
              <p className=" flex p-1 text-sm text-gray-500">
                <b className="pr-1 text-gray-900"> {`${t('workflows.RequestedOn')}:`}</b>
                {data.requested_at ? <DateCell value={data?.requested_at} /> : 'No Valid Time'}
              </p>
              {renderCustomAttributes}
            </div>
          ) : (
            <ErrorOnView error={isError} setError={() => setError(null)} />
          )}
        </div>
      </div>
    </>
  )
}
