import {useTranslation} from 'react-i18next'
import {useFormContext} from 'react-hook-form'
import {Modal, ModalContentsBase, ModalOpenButton} from './Modal'
import {PencilAltIcon, PlusCircleIcon, TrashIcon} from '@heroicons/react/outline'
import DeletionWarning from './DeletionWarning'
import {deleteCompany} from '../stores/companies'

const AddNewItem = ({
  fieldName,
  label,
  addComponent,
  deleteState,
  setDeleteError,
  editComponent,
  options,
}) => {
  const {t} = useTranslation()
  const {watch} = useFormContext()
  const value = watch(`${fieldName}`)
  const getValue = () => {
    if (options) {
      return options.find((option) => {
        return option.value === value
      })
    } else {
      return ''
    }
  }

  return (
    <div className="flex w-full items-center justify-start">
      <span className="block text-sm font-medium text-gray-700">{label}</span>

      <div className="m-1 flex items-center justify-start">
        {!!addComponent && (
          <Modal>
            <ModalOpenButton>
              <div className="h-5 w-5">
                <PlusCircleIcon className="text-sky-400 hover:text-sky-500" />
              </div>
            </ModalOpenButton>
            {addComponent}
          </Modal>
        )}

        <>
          {!!deleteState && (
            <Modal>
              {!!value && (
                <ModalOpenButton>
                  <div className="h-5 w-5">
                    <TrashIcon className="text-red-400 hover:text-red-500" />
                  </div>
                </ModalOpenButton>
              )}
              <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
                <DeletionWarning
                  deleteFunction={deleteCompany}
                  message={t('confirmDeletion.DeleteCompany')}
                  text={t('confirmDeletion.Confirm')}
                  id={value}
                  disabled={!value}
                  setError={setDeleteError}
                  error={deleteState?.error?.message}
                  pending={deleteState.pending}
                />
              </ModalContentsBase>
            </Modal>
          )}
          {!!editComponent && (
            <Modal>
              {!!value && (
                <ModalOpenButton>
                  <div className="h-5 w-5">
                    <PencilAltIcon className="text-green-400 hover:text-green-500" />
                  </div>
                </ModalOpenButton>
              )}
              {editComponent(getValue())}
            </Modal>
          )}
        </>
      </div>
    </div>
  )
}

export default AddNewItem
