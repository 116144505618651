import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {getCompanies} from '../stores/companies'

export const useCompanyOptions = () => {
  const dispatch = useDispatch()
  // @ts-ignore
  const get_companies = useSelector((state) => state.companies.get_companies.success)
  // @ts-ignore
  const delete_company = useSelector((state) => state.companies.delete_company.success)
  // @ts-ignore
  const create_company = useSelector((state) => state.companies.create_company.success)
  // @ts-ignore
  const update_company_success = useSelector((state) => state.companies.set_company.success)
  // @ts-ignore
  const {preferred_company_id} = useSelector((state) => state.auth.fetchUserSuccess)

  useEffect(() => {
    dispatch(getCompanies())
  }, [delete_company, create_company.id, update_company_success, dispatch])

  return {
    companies: get_companies?.map((item) => ({
      value: item.id,
      label: item.name,
    })),
    defaultCompany: get_companies?.find((company) => company.id === preferred_company_id),
  }
}
