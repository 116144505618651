import columnConfigPerson from './person'
import columnConfigCard from './card'
import columnConfigEmployee from './employee'
import columnConfigInventory from './inventory'
import columnConfigResource from './resource'
import columnConfigVisitor from './visitor'
import columnConfigCardsHistory from './cardsHistory'
import columnConfigCompany from './company'
import columnConfigConfiguration from './configuration'
import columnConfigExecution from './execution'
import columnConfigFilesVisitor from './filesVisitor'
import columnConfigFilesEmployee from './filesEmployee'
import columnConfigForms from './forms'
import columnConfigIdentityProvider from './identityProvider'
import columnConfigInventoryHistory from './inventoryHistory'
import columnConfigInventoryType from './inventoryType'
import columnConfigPrinter from './printer'
import columnConfigResourceType from './resourceType'
import columnConfigSubscription from './subscription'
import columnConfigUser from './user'
import columnConfigInventoriesEmployee from './inventoriesEmployee'
import columnConfigInventoriesVisitor from './inventoriesVisitor'
import columnConfigResourcesEmployee from './resourcesEmployee'
import columnConfigToDos from './toDos'
import columnConfigMeeting from './meeting'
import columnConfigWorkflows from './workflows'
import columnConfigResourcesIdentities from './resourcesIdentities'
import columnConfigResourceChildren from './resourcesIdentitiesChildren'
import columnConfigResourceParents from './resourceIdentitiesParents'
import columnConfigIdentities from './identity'
import columnConfigIdentityMembers from './identityMembers'
import columnConfigIdentityParents from './identityParents'
import columnConfigIdentityChildren from './identityChildren'
import columnConfigIdentitiesEmployee from './identitiesEmployee'
import columnConfigIdentitiesVisitor from './identitiesVisitor'

const tablesConfig = {
  person: {
    config: columnConfigPerson,
  },
  card: {
    config: columnConfigCard,
  },
  employee: {
    config: columnConfigEmployee,
  },
  inventory: {
    config: columnConfigInventory,
  },
  resource: {
    config: columnConfigResource,
  },
  resourceChildren: {
    config: columnConfigResourceChildren,
  },
  resourceParents: {
    config: columnConfigResourceParents,
  },
  visitor: {
    config: columnConfigVisitor,
  },
  cardsHistory: {
    config: columnConfigCardsHistory,
  },
  company: {
    config: columnConfigCompany,
  },
  configuration: {
    config: columnConfigConfiguration,
  },
  execution: {
    config: columnConfigExecution,
  },
  filesVisitor: {
    config: columnConfigFilesVisitor,
  },
  filesEmployee: {
    config: columnConfigFilesEmployee,
  },
  forms: {
    config: columnConfigForms,
  },
  identityProvider: {
    config: columnConfigIdentityProvider,
  },
  inventoryHistory: {
    config: columnConfigInventoryHistory,
  },
  inventoryType: {
    config: columnConfigInventoryType,
  },
  printer: {
    config: columnConfigPrinter,
  },
  resourceType: {
    config: columnConfigResourceType,
  },
  subscription: {
    config: columnConfigSubscription,
  },
  user: {
    config: columnConfigUser,
  },
  workflows: {
    config: columnConfigWorkflows,
  },
  meeting: {
    config: columnConfigMeeting,
  },
  toDos: {
    config: columnConfigToDos,
  },
  resourcesEmployee: {
    config: columnConfigResourcesEmployee,
  },
  inventoriesEmployee: {
    config: columnConfigInventoriesEmployee,
  },
  inventoriesVisitor: {
    config: columnConfigInventoriesVisitor,
  },
  resourcesIdentities: {
    config: columnConfigResourcesIdentities,
  },
  identity: {
    config: columnConfigIdentities,
  },
  identityMembers: {
    config: columnConfigIdentityMembers,
  },
  identityParents: {
    config: columnConfigIdentityParents,
  },
  identityChildren: {
    config: columnConfigIdentityChildren,
  },
  identitiesEmployee: {
    config: columnConfigIdentitiesEmployee,
  },
  identitiesVisitor: {
    config: columnConfigIdentitiesVisitor,
  },
}

export default tablesConfig
