import {useFormContext, Controller} from 'react-hook-form'
import {getRhfValidations, isFieldRequired} from '../../lib/utils'
import InputWrapper from '../inputWrapper'
import SortableSelectView from './SelectSoratbleView'
import {useEffect, useMemo} from 'react'

function SelectInput({
  name,
  label,
  options,
  isMulti,
  validations,
  validationType,
  withRhfValidation,
  disableOnSelect,
  defaultValue,
  disabled,
}) {
  const {control, formState, getValues, getFieldState, resetField} = useFormContext()
  const id = `${name}`
  const error = getFieldState(name, formState).error?.message || ''
  const rules = withRhfValidation ? getRhfValidations(getValues, name, validations) : {}

  const optionsValues = useMemo(() => options?.map((option) => option.value), [options])

  const currentValue = getValues(name)

  useEffect(() => {
    if (!isMulti && currentValue && !optionsValues.includes(currentValue)) {
      resetField(name)
    }
  }, [optionsValues, currentValue, name, resetField, isMulti])

  return (
    <InputWrapper
      id={id}
      isRequired={isFieldRequired(validations, validationType)}
      label={label}
      error={error}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({field}) => {
          return (
            <SortableSelectView
              defaultValue={defaultValue}
              isMulti={isMulti}
              options={options}
              disableOnSelect={disableOnSelect}
              field={field}
              isError={!!error}
              disabled={disabled}
            />
          )
        }}
      />
    </InputWrapper>
  )
}

export default SelectInput
