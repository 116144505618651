import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {
  bulkCreatePreInitCards,
  bulkCreatePreInitCardsClearError,
  getCardProfiles,
} from '../../stores/cards'
import {iso8061UTC} from '../../utils/datetime'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'

export default function FormAddCardPreinit() {
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const cardProfiles = useSelector((state) => state.cards.get_card_profiles.success)
  const cardProfilesError = useSelector((state) => state.cards.get_card_profiles.error)
  const bulk_create_pre_init_cards_pending = useSelector(
    (state) => state.cards.bulk_create_pre_init_cards.pending,
  )
  const bulk_create_pre_init_cards_error = useSelector(
    (state) => state.cards.bulk_create_pre_init_cards.error,
  )
  const {t} = useTranslation()
  const arrayOfTranslations = [
    t('preInitCards.BadgeNr'),
    t('preInitCards.Result'),
    t('preInitCards.SuccessfullyCreated'),
    t('preInitCards.AlreadyExists'),
  ]

  useEffect(() => {
    dispatch(getCardProfiles(`/api/v1/cards/profiles?filter_by=reassignable`))
  }, [dispatch, cardProfilesError?.message])

  const handleSubmit = (values) => {
    values.valid_from = iso8061UTC(values.valid_from)
    values.valid_to = iso8061UTC(values.valid_to)
    dispatch(bulkCreatePreInitCards(values, arrayOfTranslations, document))
  }

  const fields = [
    {
      component: InputComponent.TEXT_INPUT,
      name: 'number_of_cards',
      label: 'addCard.NumberOfCards',
      inputType: 'number',
      defaultValue: 1,
      validationType: YupValidationType.NUMBER,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
        {
          type: InputYupValidationsType.MIN,
          params: [1, t('addCard.CardNumberError')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `card_profile_id`,
      label: 'addCard.CardProfile',
      placeholder: 'addCard.SelectCardProfile',
      options: cardProfiles.map((o) => ({
        value: o.id,
        label: o.name,
      })),
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: 'addEmployee.Domain',
      placeholder: 'addEmployee.DomainPlaceholder',
      options: permissions.createCardLocations,
      defaultValue: permissions.createCardLocations && permissions.createCardLocations[0]?.value,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_from',
      inputType: 'datetime-local',
      label: 'addCard.ValidFrom',
      validationType: YupValidationType.DATE,
      defaultValue: new Date().toISOString().slice(0, -8),
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_to',
      inputType: 'datetime-local',
      label: 'addCard.ValidTo',
      validationType: YupValidationType.DATE,
      allowEndless: true,
      validations: [
        {
          type: InputYupValidationsType.ENDDATE, // special type for validation
          params: ['valid_from', t('yupError.DateRange')],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      name: 'prefix',
      label: 'addCard.Prefix',
    },
    {
      component: InputComponent.TEXT_INPUT,
      name: 'suffix',
      label: 'addCard.Suffix',
    },
    {
      component: InputComponent.TEXT_INPUT,
      name: 'start',
      label: 'addCard.Start',
      defaultValue: 1,
      inputType: 'number',
      validationType: YupValidationType.NUMBER,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
        {
          type: InputYupValidationsType.MIN,
          params: [1, t('addCard.CardNumberStartError')],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields: fields,
          name: 'add-card-preinit',
          submitButtonText: t('addCard.Add'),
        }}
        onSubmit={handleSubmit}
        pending={bulk_create_pre_init_cards_pending}
        noFormBorder
        infoMessage={
          <>
            <Notification
              error={bulk_create_pre_init_cards_error}
              setError={() => dispatch(bulkCreatePreInitCardsClearError())}
            />
          </>
        }
      />
    </>
  )
}
