import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'ColumnFilter',
    accessor: 'color',
    label: 'TableLabelsCommon.Color',
  },
  {
    header: 'SelectColumnFilterLocations',
    label: 'workflows.Location',
    accessor: 'location.description',
    permissionsAccessor: 'listInventoriesLocations',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'created_at',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.CustomAttributes',
    accessor: 'custom_attributes',
    noSort: true,
    cell: 'CustomAttributesCell',
    label: 'TableLabelsCommon.CustomAttributes',
  },
  {
    header: 'ColumnFilter',
    accessor: 'inventory_number',
    label: 'inventoryList.InventoryNumber',
    idAccessor: 'id',
  },
  {
    header: 'ColumnFilter',
    accessor: 'manufacturer',
    label: 'inventoryList.InventoryManufacturer',
  },
  {
    header: 'ColumnFilter',
    accessor: 'model',
    label: 'inventoryList.InventoryModel',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner',
    label: 'TableLabelsCommon.Owner',
  },
  {
    header: 'SelectColumnFilterInventoryTypes',
    accessor: 'type.name',
    label: 'inventoryList.InventoryType',
  },
  {
    header: 'inventoryList.Actions',
    label: 'inventoryList.Actions',
    accessor: 'id',
    width: 40,
    cell: 'InventoryTableActionsCell',
    noSort: true,
  },
]
export default columnConfig
