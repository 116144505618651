import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'employeeCardHistoryInfo.BadgeNumber',
    accessor: 'badgenr',
    label: 'employeeCardHistoryInfo.BadgeNumber',
  },
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'employeeCardHistoryInfo.CardProfileProduction',
    accessor: 'production_profile_name',
    label: 'employeeCardHistoryInfo.CardProfileProduction',
  },
  {
    header: 'employeeCardHistoryInfo.CardProfile',
    accessor: 'profile_name',
    label: 'employeeCardHistoryInfo.CardProfile',
  },
  {
    header: 'cardList.CardBadgeStatus',
    accessor: 'state_name',
    label: 'cardList.CardBadgeStatus',
    cell: 'StatusPill',
    filter: 'includes',
  },
  {
    header: 'employeeCardHistoryInfo.LastModified',
    accessor: 'updated_at',
    label: 'employeeCardHistoryInfo.LastModified',
    cell: 'DateCell',
  },
  {
    header: 'TableLabelsCommon.ValidFrom',
    accessor: 'valid_from',
    cell: 'DateCell',
    label: 'TableLabelsCommon.ValidFrom',
  },
  {
    header: 'TableLabelsCommon.ValidFrom',
    accessor: 'valid_to',
    cell: 'DateCell',
    label: 'TableLabelsCommon.ValidTo',
  },
]
export default columnConfig
