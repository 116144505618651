import {useState} from 'react'
import {classNames} from '../utils/classNames'
import SpinnerSVG from '../assets/Spinner.svg'
import {useTranslation} from 'react-i18next'
import {getSrc} from '../utils/image'

export default function Image(props) {
  const {t} = useTranslation()
  const [src, setSrc] = useState(null)
  const pending = src !== props.ImgSrc

  return (
    <>
      {pending ? (
        <div
          className={classNames(
            'text-md mx-auto flex  flex-col items-center justify-center p-3 lg:m-1',
            props.loaderHeight || 'h-48',
          )}
        >
          <img
            alt="o"
            src={SpinnerSVG}
            className={classNames('mr-3 animate-spin text-lime-500', props.loaderHeigth || 'h-20')}
          />
          {t('loading')}
        </div>
      ) : null}
      <div className="flex max-w-lg flex-row justify-items-start ">
        <img
          className={classNames(
            pending ? 'hidden' : props.class || 'mx-auto h-48 object-contain p-3 lg:m-1',
          )}
          src={getSrc(props.ImgSrc)}
          alt={props.alt}
          onLoad={() => setSrc(props.ImgSrc)}
          onError={() => setSrc(props.ImgSrc)}
        ></img>
      </div>
    </>
  )
}
