import {getForms} from '../../stores/forms'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import Table from '../table'
import {Button} from '../Buttons'
import {useTableColumns} from '../../hooks/useTableColumns'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'

export default function FormsWrapper() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const forms = useSelector((state) => state.forms.get_forms.success)
  const delete_form_success = useSelector((state) => state.forms.delete_form.success)
  const forms_pending = useSelector((state) => state.forms.get_forms.pending)
  const forms_error = useSelector((state) => state.forms.get_forms.error)

  useEffect(() => {
    dispatch(getForms())
  }, [dispatch, delete_form_success])

  const columns = useTableColumns(TableId.FORMS)
  return (
    <>
      <Button href="/buildform" text={t('formBuilder.BuildForm')} />
      <EditTableWrapper tableId={TableId.FORMS} />
      <Table data={forms} columns={columns} pending={forms_pending} error={forms_error} />
    </>
  )
}
