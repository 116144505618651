import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {validateAndStoreConfig, validateAndStoreConfigClearError} from '../../stores/configs'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'

export default function FormAddConfiguration() {
  const dispatch = useDispatch()
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const validate_and_store_config_pending = useSelector(
    (state) => state.configs.validate_and_store_config.pending,
  )
  const validate_and_store_config_error = useSelector(
    (state) => state.configs.validate_and_store_config.error,
  )
  const {t} = useTranslation()

  const handleSubmit = (values) => {
    dispatch(
      validateAndStoreConfig(
        values.config_type,
        values.description,
        {files: [{file: values['file'].file}]},
        values.status,
        userID,
      ),
    )
  }

  const fields = [
    {
      component: InputComponent.FILE,
      name: 'file',
      validationType: YupValidationType.OBJECT,
      label: 'uploadFile',
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[6], 'yupError.FileType'],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: 'config_type',
      label: 'configureInterfaces.Type',
      placeholder: 'dataTableSorting.Select',
      options: [
        {value: 'Import', label: 'Import'},
        {value: 'Export', label: 'Export'},
      ],
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: 'status',
      label: 'configureInterfaces.Active',
      placeholder: 'dataTableSorting.Select',
      options: [
        {value: true, label: 'True'},
        {value: false, label: 'False'},
      ],
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      label: 'userManagement.Description',
      component: InputComponent.TEXT_INPUT,
      name: 'description',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
        {
          type: InputYupValidationsType.MAX,
          params: [256, 'yupError.MaxCharsAllowed'],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-add-configuration',
          submitButtonText: t('login.Submit'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={validate_and_store_config_pending}
        infoMessage={
          <Notification
            error={validate_and_store_config_error}
            setError={() => dispatch(validateAndStoreConfigClearError())}
          />
        }
      />
    </>
  )
}
