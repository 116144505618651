import {useRef} from 'react'
import {Formik, Form} from 'formik'
import {TextInput, SelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {postPrinter, postPrinterClearError} from '../../stores/printers'
import {FormikSaveButton} from '../Buttons'
import {ExclamationCircleIcon} from '@heroicons/react/outline'
import SpinnerSVG from '../../assets/Spinner.svg'

export default function FormAddPrinter(props) {
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const {t} = useTranslation()

  const initialValues = {
    name: '',
    description: '',
    location: '',
  }
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '256'})) // 256
      .required(t('yupError.ThisFieldIsRequired', {field: t('userManagement.Name')})),
    description: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('usermenu.SelectPrinter')}),
    ),
    location: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addEmployee.Domain')}),
    ),
  })

  let descriptionSelectRef = useRef()
  let domainSelectRef = useRef()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          dispatch(postPrinter(values, props.userID))
          actions.resetForm()
          // resetting react-select components
          descriptionSelectRef.current.select.setValue('')
          domainSelectRef.current.select.setValue('')
        }}
      >
        <Form>
          {props.printersPending ? ( // TODO make it nicer
            <div className="flex flex-col items-start justify-center p-3 text-sm">
              <img alt="o" src={SpinnerSVG} className="mr-3 h-6 w-6 animate-spin text-lime-500" />
              {t('configurePrinters.LoadingPrinters')}
            </div>
          ) : !props.printersPending && !props.printersError && props.printers.length === 0 ? (
            <div className="flex justify-start">
              <p className="m-2 text-sm text-red-600" id="email-error">
                {t('configurePrinters.NoPrinterSettedUp')}
              </p>
              <div className="pointer-events-none inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            </div>
          ) : (
            <>
              <SelectInput
                label={t('configurePrinters.ServerPrinters')}
                required
                innerRef={descriptionSelectRef}
                name="description"
                placeholder={t('usermenu.SelectPrinter')}
                options={props.printers.map((item) => ({
                  value: item,
                  label: item,
                }))}
                error={props.printersError}
                setError={props.printersClearError}
              />
            </>
          )}
          <SelectInput
            label={t('addEmployee.Domain')}
            name="location"
            required
            innerRef={domainSelectRef}
            placeholder={t('addEmployee.DomainPlaceholder')}
            options={permissions.listPrintersLocations}
          />
          <TextInput
            label={t('userManagement.Name')}
            name="name"
            type="text"
            required
            placeholder=""
          />
          <FormikSaveButton
            type="submit"
            testid="form-add-printer-submit"
            text={t('userManagement.Add')}
            primary
            pending={props.post_printer_pending}
            error={props.post_printer_error}
            setError={() => dispatch(postPrinterClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
