import {Formik, Form} from 'formik'
import {TextInputLabelOnLeft, TextArea, AsyncSelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {Button, ButtonSuccessAndError} from '../Buttons'
import {useDispatch, useSelector} from 'react-redux'
import {
  setVisitation,
  removeOldAddNewParticipants,
  setVisitiationClearError,
} from '../../stores/visitations'
import {useState} from 'react'
import {DatePickerDefault} from '../DatePickerDefault'
import MeetingParticipants from '../MeetingParticipants'
import * as Yup from 'yup'
import {iso8061UTC} from '../../utils/datetime'

export default function FormEditMeeting({data, ...props}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const {pending, error, successMessage} = useSelector((state) => state.visitations.set_visitation)

  const initialValues = {
    name: data.name || '',
    host_employee: data.host_employee || '',
    host_company: data.host_company || '',
    from_dt: data.from_dt || '',
    to_dt: data.to_dt || '',
  }

  // destructure employees and visitors from visitation data
  const {employees, visitors, id} = data
  // get the persons from employees and visitors
  const initialEmployeesParticipants = employees.map((employee) => employee.person)
  const initialVisitorsParticipants = visitors.map((visitor) => visitor.person)
  // set initialParticipants
  const [addedParticipants, setAddedParticipants] = useState([
    ...initialEmployeesParticipants,
    ...initialVisitorsParticipants,
  ])

  const [participantSelected, setParticipantSelected] = useState(null)

  const addParticipant = (selected) => {
    if (selected) {
      const allParticipants = [...addedParticipants, selected]
      setAddedParticipants(allParticipants) // Set doesn't work if you remove this
      const uniqueParticipants = [...new Set(allParticipants)]
      setAddedParticipants(uniqueParticipants)
      setParticipantSelected(null)
    }
  }

  const removeParticipant = (selected) => {
    var filtered = addedParticipants.filter(function (value) {
      return value.id !== selected.id
    })
    setAddedParticipants(filtered)
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addVisitationForm.Topic')}),
    ),
    from_dt: Yup.date().required(
      t('yupError.ThisFieldIsRequired', {field: t('addVisitationForm.FromDate')}),
    ),
    to_dt: Yup.date()
      .required(t('yupError.ThisFieldIsRequired', {field: t('addVisitationForm.ToDate')}))
      .min(Yup.ref('from_dt'), t('yupError.DateRange'))
      .test('same_dates_test', t('yupError.DateMustBeGreaterThan'), function (value) {
        const {from_dt} = this.parent
        return value.getTime() !== from_dt.getTime()
      })
      .nullable(),
  })

  const handleSubmit = (values) => {
    let visitationData = {
      name: values.name,
      from_dt: iso8061UTC(values.from_dt),
      to_dt: iso8061UTC(values.to_dt),
    }
    dispatch(setVisitation(id, visitationData))
    const visitors = addedParticipants.filter(
      (addedParticipant) => addedParticipant.visitor_id !== null,
    )
    const employees = addedParticipants.filter(
      (addedParticipant) => addedParticipant.employee_id !== null,
    )
    dispatch(
      removeOldAddNewParticipants(
        id,
        initialEmployeesParticipants,
        initialVisitorsParticipants,
        employees,
        visitors,
        props.history,
      ),
    )
  }

  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Meeting Details</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Status: {data.status}</p>
      </div>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          <TextInputLabelOnLeft
            disabled={true}
            label={t('addVisitationForm.HostEmployee')}
            type="text"
            name="host_employee.person.full_name"
          />
          <TextInputLabelOnLeft
            disabled={true}
            label={t('addVisitationForm.HostCompany')}
            type="text"
            name="host_company.name"
          />
          <TextArea
            label={t('addVisitationForm.Topic')}
            name="name"
            placeholder=""
            rows={3}
            required
          />
          <DatePickerDefault
            validfrom="from_dt"
            validto="to_dt"
            validtoid="form-edit-meeting-datepicker-validTo"
            validfromid="form-edit-meeting-datepicker-validFrom"
            checkboxid="form-edit-meeting-checkbox"
            type="datetime-local"
            labelvalidfrom={t('addVisitationForm.FromDate')}
            labelvalidto={t('addVisitationForm.ToDate')}
            required
          />
          {/*Show Participant adding section here */}
          <h3 className="mt-2 w-full rounded-lg bg-gray-500 px-4 py-2 text-left text-base font-medium text-slate-50 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
            {t('addVisitationForm.AddParticipants')}
          </h3>
          <div className="flex flex-wrap items-center justify-between">
            <div className=" w-64 flex-none sm:w-full">
              <AsyncSelectInput
                url={`/api/v1/persons?q=`}
                getOptionLabel={(e) => e.full_name}
                getOptionValue={(e) => e.id}
                label={t('addVisitationForm.Person')}
                selected={participantSelected}
                placeholder={t('addEmployee.PersonPlaceholder')}
                onChange={(selected) => {
                  if (selected) {
                    setParticipantSelected(selected)
                  } else {
                    setParticipantSelected(null)
                  }
                }}
              />
            </div>
            {/*Show Participant email here */}
            <div className="block">
              <label className=" text-sm font-medium text-gray-700">
                {t('addVisitationForm.ParticipantEmail')}
              </label>
              <p className=" h-10 w-64 flex-none rounded-lg border border-gray-300 bg-gray-100 p-2 text-center text-sm text-gray-700 shadow-sm ">
                {participantSelected !== null ? participantSelected.email : ''}
              </p>
            </div>
            <div className="h-2 flex-row-reverse pt-1 pb-4">
              <Button
                text={t('addVisitationForm.AddParticipant')}
                type="button"
                disabled={!participantSelected}
                onClick={() => {
                  addParticipant(participantSelected)
                }}
              />
            </div>
          </div>
          {/*Show Participants here */}
          <MeetingParticipants
            participants={addedParticipants}
            removeParticipant={removeParticipant}
          />
          <ButtonSuccessAndError
            type="submit"
            primary
            text={t('addVisitationForm.UpdateVisitation')}
            pending={pending}
            error={error}
            setError={setVisitiationClearError}
            success={successMessage}
            setSuccess={setVisitiationClearError}
            onClick={() => {}}
          />
        </Form>
      </Formik>
    </>
  )
}
