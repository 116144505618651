import {Formik, Form} from 'formik'
import {Checkbox, TextInputCustomAttribute, TextInputLabelOnLeft} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {
  deleteOidcProviderMappingRuleAdmin,
  deleteOidcProviderValidationRuleAdmin,
  setOidcProviderAdmin,
  setOidcProviderAdminClearError,
  setOidcProviderMappingRuleAdmin,
  setOidcProviderValidationRuleAdmin,
} from '../../stores/oidc_providers'
import {PlusCircleIcon} from '@heroicons/react/outline'
import {ModalOpenButton, Modal, ModalContentsBase} from '../Modal'
import {isEmpty} from '../../utils/custom_attributes'
import {PencilAltIcon} from '@heroicons/react/solid'
import {useState} from 'react'
import FormAddIdentityProviderOidcValidationRule from './FormAddIdentityProviderOidcValidationRule'
import FormAddIdentityProviderOidcMappingRule from './FormAddIdentityProviderOidcMappingRule'

export default function FormEditOidcProvider({data}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const pending = useSelector((state) => state.oidc_providers.set_oidc_provider_admin.pending)
  const error = useSelector((state) => state.oidc_providers.set_oidc_provider_admin.error)

  const [editable, setEditable] = useState(true)
  const initialValues = {
    description: data.description || '',
    mapping_rules: data.mapping_rules || {}, // _rules
    validation_rules: data.oidc?.validation_rules || {}, // _rules
    discovery_query: data.discovery_query,
    allowed_to_create_users: data.allowed_to_create_users,
    allowed_to_update_users: data.allowed_to_update_users,
    oidc_provider_secret: data.oidc?.oidc_provider_secret || '',
    oidc_provider_id: data.oidc?.oidc_provider_id || '',
    scope: data.oidc?.oidc_provider_metadata?.scope || '',
    server_metadata_url: data.oidc?.oidc_provider_metadata?.server_metadata_url || '',
    //"action": "string"
    active: data.active || '',
  }

  const validationSchema = Yup.object({
    // ADD validations
  })

  const handleSubmit = async (values, actions) => {
    delete values.oidc_provider_id
    values['mapping_rules'] = Object.entries(values.mapping_rules).map((entry) => {
      return {[entry[0].replace('_d0t_', '.')]: entry[1]}
    })
    values['validation_rules'] = Object.entries(values.validation_rules).map((entry) => {
      return {[entry[0].replace('_d0t_', '.')]: entry[1]}
    })
    dispatch(setOidcProviderAdmin(data.id, values))
    actions.resetForm()
  }

  const validationRules = isEmpty(data.oidc?.validation_rules) // validation_rules
    ? []
    : Object.keys(data.oidc?.validation_rules).map((key) => {
        return {
          label: key,
          name: key.replace('.', '_d0t_'),
          defaultValue: data.oidc?.validation_rules[key],
          type: 'text',
          forbidden: false,
          deleteAble: true,
        }
      })

  const mappingRules = isEmpty(data?.mapping_rules) // mapping_rules
    ? []
    : Object.keys(data?.mapping_rules).map((key) => {
        return {
          label: key,
          name: key.replace('.', '_d0t_'),
          defaultValue: data.mapping_rules[key],
          type: 'text',
          forbidden: false,
          deleteAble: true,
        }
      })

  const renderValidationRules = (inputs) => {
    return inputs.map((input) => {
      return (
        <div key={input.name}>
          <TextInputCustomAttribute
            disabled={editable}
            label={input.label}
            type="text"
            defaultValue={input.defaultValue}
            name={`validation_rules[${input.name}]`}
            id={data.id}
            permission={true} // TODO: this should be customAttribute permission
            customAttributeKey={input.label}
            deleteCustomAttribute={deleteOidcProviderValidationRuleAdmin}
          />
        </div>
      )
    })
  }

  const renderMappingRules = (inputs) => {
    return inputs.map((input) => {
      return (
        <div key={input.name}>
          <TextInputCustomAttribute
            disabled={editable}
            label={input.label}
            type="text"
            defaultValue={input.defaultValue}
            name={`mapping_rules[${input.name}]`}
            id={data.id}
            permission={true} // TODO: this should be customAttribute permission
            customAttributeKey={input.label}
            deleteCustomAttribute={deleteOidcProviderMappingRuleAdmin}
          />
        </div>
      )
    })
  }

  return (
    <>
      <div className="m-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <PencilAltIcon className="h-6 w-6 text-lime-500" onClick={() => setEditable(!editable)} />
      </div>

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        <Form>
          <TextInputLabelOnLeft
            label={t('addIdentityProvider.OidcProviderId')}
            disabled={true}
            name="oidc_provider_id"
            type="text"
            placeholder=""
          />
          <TextInputLabelOnLeft
            label={t('addIdentityProvider.OidcProviderSecret')}
            name="oidc_provider_secret"
            type="text"
            placeholder=""
            required
            disabled={editable}
          />
          <TextInputLabelOnLeft
            label={t('addIdentityProvider.Description')}
            disabled={editable}
            name="description"
            type="text"
            placeholder=""
          />
          <TextInputLabelOnLeft
            label={t('addIdentityProvider.DiscoveryQuery')}
            name="discovery_query"
            type="text"
            placeholder=""
            disabled={editable}
          />
          <TextInputLabelOnLeft
            label={t('addIdentityProvider.Scope')}
            name="scope"
            type="text"
            placeholder=""
            required
            disabled={editable}
          />
          <TextInputLabelOnLeft
            label={t('addIdentityProvider.ServerMetadataUrl')}
            name="server_metadata_url"
            type="text"
            placeholder=""
            required
            disabled={editable}
          />
          {/* To draw a line */}
          <div className="mb-4 sm:border-b sm:border-gray-200"></div>
          <Checkbox
            id="form-oidc-add-user-checkbox"
            label={t('addIdentityProvider.CreateUsersPermission')}
            name="allowed_to_create_users"
            disabled={editable}
          />
          <Checkbox
            id="form-oidc-edit-user-checkbox"
            label={t('addIdentityProvider.UpdateUsersPermission')}
            name="allowed_to_update_users"
            disabled={editable}
          />
          <Checkbox
            id="form-oidc-edit-provider-checkbox"
            label={t('addIdentityProvider.Active')}
            name="active"
          />
          {/* To leave space */}
          <div className="sm:mt-4"></div>
          <div className="flex ">
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:py-2">
              {t('addIdentityProvider.MappingRules')}
            </label>
            {editable ? (
              <div className="pl-2 pt-1 md:pt-3">
                <Modal>
                  <ModalOpenButton>
                    <PlusCircleIcon className="h-4 w-4 text-blue-400 hover:text-blue-500" />
                  </ModalOpenButton>
                  <ModalContentsBase title={t('addIdentityProvider.MappingRules')}>
                    <FormAddIdentityProviderOidcMappingRule
                      data={data}
                      submit={setOidcProviderMappingRuleAdmin}
                      pending={pending}
                      error={error}
                      setError={setOidcProviderAdminClearError}
                    />
                  </ModalContentsBase>
                </Modal>
              </div>
            ) : null}
          </div>
          {renderMappingRules(mappingRules)}
          <div className="flex ">
            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:py-2">
              {t('addIdentityProvider.ValidationRules')}
            </label>
            {editable ? (
              <div className="pl-2 pt-1 md:pt-3">
                <Modal>
                  <ModalOpenButton>
                    <PlusCircleIcon className="h-4 w-4 text-blue-400 hover:text-blue-500" />
                  </ModalOpenButton>
                  <ModalContentsBase title={t('addIdentityProvider.ValidationRules')}>
                    <FormAddIdentityProviderOidcValidationRule
                      data={data}
                      submit={setOidcProviderValidationRuleAdmin}
                      pending={pending}
                      error={error}
                      setError={setOidcProviderAdminClearError}
                    />
                  </ModalContentsBase>
                </Modal>
              </div>
            ) : null}
          </div>
          {renderValidationRules(validationRules)}
          <FormikSaveButton
            type="submit"
            testid="form-edit-oidc-provider-submit"
            text={t('addIdentityProvider.Save')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(setOidcProviderAdminClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
