import React, {FC, useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import DynamicInput from '../../formRendererRHF/DynamicInput'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import InputsSettings from './InputsSettings'
import {getFormDefinitions} from './inputsSettingsConfig'
import {Button} from '../../Buttons'
import {
  DynamicFormConfig,
  InputComponent,
  InputConfig,
  InputYupValidationsType,
  YupValidationType,
} from '../../formRendererRHF/types'
import {ErrorMessage} from '../../formRendererRHF/ErrorMessage'
import InfoMessage from '../../InfoMessage'
import {getDuplicatesByKeyValue, resolvePath} from '../../../utils/helpers'
import {DevTool} from '@hookform/devtools'
import axios from '../../../services/api'

// ! pls read the definitions below for the formBuilder domain
// ! userField - is an input interface for the end user (for example a text filed for e-mail in a user registration form)
// ! settingsInput - is an input interface for configure an user field. A set of settingsInputs are needed to configure one user field

interface FormBuilderNewProps {
  setFromConfig: (formConfig: DynamicFormConfig) => void
  onFormSettingsSubmit: () => void
}

const mapSettingsFormStateToInputsConfig = (formState): DynamicFormConfig => {
  const fields = formState.fields.map((formStateField) => {
    const {
      component,
      label,
      name,
      placeholder,
      isMulti,
      allowEndless,
      url,
      optionValue,
      nestedOptionLabel,
      optionLabel,
      inputType,
      size,
      bold,
      options,
      validations = [],
    } = formStateField
    const result = {
      component,
      label,
      name,
      placeholder,
      isMulti,
      allowEndless,
      url,
      optionValue,
      nestedOptionLabel,
      optionLabel,
      inputType,
      size,
      bold,
      options,
      validations: Object.entries(validations)
        .filter(([type, params]) => (params as any).every((curParam) => !!curParam))
        .map(([type, params]) => {
          return {
            type,
            params,
          }
        }),
    } as InputConfig

    if (component === InputComponent.DATEPICKER) {
      result.validationType = YupValidationType.DATE
    }

    if (component === InputComponent.FILE) {
      result.validationType = YupValidationType.OBJECT
    }

    if (isMulti) {
      result.validationType = YupValidationType.ARRAY
    }

    if (component === InputComponent.CHECKBOX) {
      result.validationType = YupValidationType.BOOLEAN
      if (result.validations.length) {
        result.validations = [
          {
            type: InputYupValidationsType.ONEOF,
            params: [[true], result.validations[0].params[0]],
          },
        ]
      }
    }

    if (component === InputComponent.TEXT_INPUT && result.validations.length) {
      result.validations = result.validations.map((validation) => {
        if (validation.type === InputYupValidationsType.TEST) {
          const {params} = validation

          return {
            type: InputYupValidationsType.TEST,
            params: [
              'unique',
              params[0],
              function (value): any {
                const urlParam = params[1]
                const optionLabelParam = params[2]
                return axios.get(urlParam).then((resp) => {
                  const items = resp.data.results
                  const arr = items
                    .map((i) => resolvePath(i, optionLabelParam, ''))
                    .filter((i) => i)
                  return !arr.includes(value)
                })
              },
            ],
          }
        }
        return validation
      })
    }

    return result
  })

  return {
    ...formState,
    configVersion: 2,
    fields,
  }
}

const FormBuilderNew: FC<FormBuilderNewProps> = ({setFromConfig, onFormSettingsSubmit}) => {
  const {t} = useTranslation()
  const [dataReady, setDataReady] = useState<boolean>(false)
  const [showSubmitMessages, setShowSubmitMessages] = useState<boolean>(false)
  const [nameDuplicates, setNameDuplicates] = useState<string>()
  // @ts-ignore
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  // @ts-ignore
  const {pending, error, success} = useSelector((state) => state.workflows.post_workflowform)

  const formDefinitions = getFormDefinitions(t, permissions.createFormLocations)

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      fields: [{}],
    },
  })

  useEffect(() => {
    const subscription = formMethods.watch(() => {
      setDataReady(false)
      setShowSubmitMessages(false)
    })
    return () => subscription.unsubscribe()
  }, [formMethods])

  const {isValid} = formMethods.formState

  const onSubmit = (data) => {
    const nameDuplicates = getDuplicatesByKeyValue(data, 'name')
    if (nameDuplicates.length) {
      setNameDuplicates(nameDuplicates.toString())
    } else {
      setFromConfig(mapSettingsFormStateToInputsConfig(data))
      setDataReady(true)
    }
  }
  // fyi: DevTool slows down inputs
  return (
    <FormProvider {...formMethods}>
      <DevTool control={formMethods.control} placement="top-left" />
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="m-2 rounded-md border border-lime-200 p-2 shadow-sm">
          <h3 className="text-md mb-4 bg-lime-50 p-2 font-medium text-gray-900 shadow sm:rounded-lg">
            {t('formBuilder.DefineForm')}
          </h3>
          {formDefinitions.map((inputProps) => (
            <DynamicInput key={inputProps.name} {...inputProps} />
          ))}

          <InputsSettings />
        </div>
        <div className="mt-5 flex flex-wrap justify-between gap-2 px-4 sm:mt-4">
          <Button
            disabled={!isValid || !dataReady || pending}
            type="button"
            text={t('formBuilder.Submit')}
            onClick={() => {
              onFormSettingsSubmit()
              setShowSubmitMessages(true)
              if (success) {
                formMethods.reset()
              }
            }}
          />
          <Button disabled={pending} type="submit" text={t('formBuilder.PreviewForm')} />
          {showSubmitMessages && !!error?.message && (
            <ErrorMessage error={t('formBuilder.responseMessages.error')} />
          )}
          {showSubmitMessages && nameDuplicates && (
            <ErrorMessage
              error={t('formBuilder.FieldNameDuplicatesError', {NAMES: nameDuplicates})}
            />
          )}
          {showSubmitMessages && !!success && (
            <InfoMessage message={t('formBuilder.responseMessages.success')} type="success" />
          )}
        </div>
      </form>
    </FormProvider>
  )
}

export default FormBuilderNew
