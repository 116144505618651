import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getUsers, getUsersClearError} from '../../stores/users'
import Collapse from '../Collapse'
import {useTranslation} from 'react-i18next'
import FormAddUser from '../forms/FormAddUser'
import Table from '../table'
import {useTableColumns} from '../../hooks/useTableColumns'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'

export default function UserWrapper() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const users = useSelector((state) => state.users.get_users.success)
  const pending = useSelector((state) => state.users.get_users.pending)
  const error = useSelector((state) => state.users.get_users.error)

  const delete_user_success = useSelector((state) => state.users.delete_user.success)
  const create_user_success = useSelector((state) => state.users.create_user.success)
  const update_user_success = useSelector((state) => state.users.set_user.success)

  useEffect(() => {
    dispatch(getUsers('/api/v1/users?limit=500&offset=0'))
  }, [dispatch, delete_user_success, create_user_success, update_user_success, error?.message])

  const columns = useTableColumns(TableId.USER)
  return (
    <>
      <Collapse title={t('userManagement.AddNewUser')}>
        <FormAddUser />
      </Collapse>
      <>
        <EditTableWrapper tableId={TableId.USER} />
        <Table
          columns={columns}
          data={users}
          pending={pending}
          error={error}
          setError={getUsersClearError}
        />
      </>
    </>
  )
}
