import {combineReducers} from 'redux'
import {default as authReducer} from './stores/auth'
import {default as registerReducer} from './stores/register'
import {default as forgotPasswordReducer} from './stores/forgot_password'
import {default as confirmationsReducer} from './stores/confirmations'
import {default as usersReducer} from './stores/users'
import {default as rolesReducer} from './stores/roles'
import {default as photosReducer} from './stores/photos'
import {default as cardsReducer} from './stores/cards'
import {default as employeesReducer} from './stores/employees'
import {default as visitorsReducer} from './stores/visitors'
import {default as inventoriesReducer} from './stores/inventories'
import {default as cardsPhotosReducer} from './stores/cards_photos'
import {default as inventoriesPhotosReducer} from './stores/inventories_photos'
import {default as companiesReducer} from './stores/companies'
import {default as personsReducer} from './stores/persons'
import {default as visitationsReducer} from './stores/visitations'
import {default as reportsReducer} from './stores/reports'
import {default as filesReducer} from './stores/files'
import {default as backgroundExtractionReducer} from './stores/background_extraction'
import {default as cameraReducer} from './stores/camera'
import {default as employeesInventoriesReducer} from './stores/employees_inventories'
import {default as visitorsInventoriesReducer} from './stores/visitors_inventories'
import {default as importCSVReducer} from './stores/import_csv'
import {default as multiSelectReducer} from './stores/multi_select'
import {default as loadingBarReducer} from './stores/loading_bar'
import {default as notificationsReducer} from './stores/notifications'
import {default as printersReducer} from './stores/printers'
import {default as configsReducer} from './stores/configs'
import {default as accessProfilesReducer} from './stores/access_profiles'
import {default as identitiesReducer} from './stores/identities'
import {CLEANUP_AFTER_LOGOUT} from './stores/auth'
import {default as staticAssetsReducer} from './stores/static_assets'
import {default as oidcProvidersReducer} from './stores/oidc_providers'
import {default as workflowsReducers} from './stores/workflows'
import {default as subscriptionsReducers} from './stores/subscriptions'
import {default as formsReducers} from './stores/forms'
import {default as executionsReducers} from './stores/executions'
import {default as tableReducer} from './stores/table'
import {default as accessProfilesIdentitiesReducer} from './stores/access_profiles_identities'
import {default as identityReducer} from './stores/identity'

const appReducer = combineReducers({
  auth: authReducer,
  register: registerReducer,
  forgot_password: forgotPasswordReducer,
  confirmations: confirmationsReducer,
  users: usersReducer,
  roles: rolesReducer,
  photos: photosReducer,
  cards: cardsReducer,
  employees: employeesReducer,
  visitors: visitorsReducer,
  inventories: inventoriesReducer,
  inventories_photos: inventoriesPhotosReducer,
  cards_photos: cardsPhotosReducer,
  companies: companiesReducer,
  persons: personsReducer,
  visitations: visitationsReducer,
  reports: reportsReducer,
  files: filesReducer,
  background_extraction: backgroundExtractionReducer,
  camera: cameraReducer,
  employees_inventories: employeesInventoriesReducer,
  visitors_inventories: visitorsInventoriesReducer,
  import_csv: importCSVReducer,
  multi_select: multiSelectReducer,
  loading_bar: loadingBarReducer,
  notifications: notificationsReducer,
  printers: printersReducer,
  configs: configsReducer,
  access_profiles: accessProfilesReducer,
  access_profiles_identities: accessProfilesIdentitiesReducer,
  identities: identitiesReducer,
  static_assets: staticAssetsReducer,
  oidc_providers: oidcProvidersReducer,
  workflows: workflowsReducers,
  subscriptions: subscriptionsReducers,
  forms: formsReducers,
  executions: executionsReducers,
  tables: tableReducer,
  identity: identityReducer,
})

const rootReducer = (state, action) => {
  if (action.type === CLEANUP_AFTER_LOGOUT) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
