import {useDispatch, useSelector} from 'react-redux'
import {uploadImpressum, uploadImpressumClearError} from '../../stores/static_assets'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {useTranslation} from 'react-i18next'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import Notification from '../Notification'

export default function FormAddLogo() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const {error, pending, success} = useSelector((state) => state.static_assets.upload_impressum)
  const handleSubmit = (values) => {
    dispatch(uploadImpressum({files: [values['impressum-fileInput'].file]}, '/api/v1/impressum'))
  }

  const fields = [
    {
      label: t('uploadFile'),
      component: InputComponent.FILE,
      name: 'impressum-fileInput',
      validationType: YupValidationType.OBJECT,
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[4], t('yupError.FileType')],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.FileRequired')],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-impressum',
          submitButtonText: t('login.Submit'),
        }}
        onSubmit={handleSubmit}
        pending={pending}
        noFormBorder
        infoMessage={
          <Notification
            error={error}
            setError={() => dispatch(uploadImpressumClearError())}
            success={!!success}
          />
        }
      />
    </>
  )
}
