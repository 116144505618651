import {ColumnConfig} from '../types'

const columnConfigCard: ColumnConfig[] = [
  {
    header: 'ID',
    label: 'ID',
    accessor: 'id',
  },
  {
    header: 'SelectColumnFilterLocations',
    label: 'workflows.Location',
    accessor: 'location.description',
    permissionsAccessor: 'listPrintersLocations',
  },
  {
    header: 'configurePrinters.PrinterNameOnServer',
    label: 'configurePrinters.PrinterNameOnServer',
    accessor: 'description',
  },
  {
    header: 'configurePrinters.PrinterNameForIDF',
    label: 'configurePrinters.PrinterNameForIDF',
    accessor: 'name',
  },
  {
    header: 'configurePrinters.Actions',
    label: 'configurePrinters.Actions',
    accessor: 'url',
    idAccessor: 'id',
    nameAccessor: 'name',
    cell: 'FormEditPrinterActionsCell',
    noSort: true,
  },
]
export default columnConfigCard
