import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'ColumnFilterDate',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'ColumnFilter',
    accessor: 'descriptive_status',
    label: 'TableLabelsCommon.StatusDescription',
  },
  {
    header: 'ColumnFilter',
    accessor: 'execution_summary',
    label: 'workflows.ExecutionSummary',
  },
  {
    header: 'ColumnFilter',
    accessor: 'id',
    label: 'ID',
  },
  {
    header: 'ColumnFilter',
    accessor: 'requester_email',
    label: 'TableLabelsCommon.Email',
  },
  {
    header: 'ColumnFilter',
    accessor: 'requester_name',
    label: 'executions.Requester',
  },
  {
    header: 'ColumnFilter',
    accessor: 'requster_id',
    label: 'executions.RequesterId',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },

  {
    header: 'ColumnFilter',
    accessor: 'workflow.description',
    label: 'TableLabelsCommon.Description',
  },
  {
    header: 'ColumnFilter',
    accessor: 'workflow.id',
    label: 'workflows.WorkflowId',
  },
  {
    header: 'ColumnFilter',
    accessor: 'workflow.name',
    label: 'workflows.Name',
  },
  {
    header: 'ColumnFilter',
    accessor: 'workflow.render_on_ui',
    label: 'workflows.RenderAt',
  },
  {
    header: 'ColumnFilter',
    accessor: 'workflow.unauthenticated_use',
    label: 'TableLabelsCommon.UnauthenticatedUse',
  },
  {
    header: 'ColumnFilter',
    accessor: 'workflow.webhook',
    label: 'workflows.Webhook',
  },
  {
    header: 'Actions',
    label: 'Actions',
    accessor: 'status_name',
    cell: 'TodosActionsCell',
    noSort: true,
  },
]
export default columnConfig
