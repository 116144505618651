import Collapse from './Collapse'
import Tabs from './Tabs'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useState, useMemo} from 'react'
import {getActiveWorkflows} from '../stores/workflows'
import {useTranslation} from 'react-i18next'
import {parseJSON} from '../utils/helpers'
import {DynamicRhfForm} from './formRendererRHF/DynamicRhfForm'
import Notification from './Notification'
import ErrorOnView from './ErrorOnView'
import ProgressModal from './ProgressModal'
import axios from '../services/api'

// Endpoints which send data to workflow engine will send FormData now
function appendObjectToFormData(dataObject, formData) {
  for (const key in dataObject) {
    if (dataObject[key] && dataObject[key].file) {
      const fileData = dataObject[key]
      formData.append(key, fileData.file, fileData.name)
    } else {
      formData.append(key, dataObject[key])
    }
  }
}

export default function WorkflowForms({renderOnUi, isOriginUrl}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [pending, setPending] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  // get Workflows
  useEffect(() => {
    dispatch(getActiveWorkflows())
  }, [dispatch])

  const handleSubmit = async (values, webhook) => {
    setPending(true)
    setError(null)
    setSuccess(false)
    values['webhook'] = webhook
    const formData = new FormData()
    appendObjectToFormData(values, formData)
    try {
      await axios.post(`/api/v1/workflow_engine/hooks`, formData)
      setSuccess(true)
    } catch (error) {
      setError(error)
    }
    setPending(false)
  }

  const get_workflows = useSelector((state) => state.workflows.get_workflows.success)
  const filtered_workflows = get_workflows.filter(
    (workflow) => workflow.render_on_ui === renderOnUi && workflow.form !== null,
  )
  const workflows = useMemo(
    () =>
      filtered_workflows.map(({webhook, form: {form_json}}) => {
        form_json = parseJSON(form_json, undefined, setError)

        return (
          form_json && {
            name: form_json.formTitle,
            component: (
              <DynamicRhfForm
                formConfig={form_json}
                noFormBorder
                onSubmit={(values) => {
                  if (isOriginUrl) {
                    values.originalUrl = window.location.pathname
                  }
                  handleSubmit(values, webhook)
                }}
                pending={pending}
                infoMessage={
                  <Notification
                    error={error}
                    setError={() => {
                      setError(null)
                      setSuccess(false)
                    }}
                    success={!!success}
                  />
                }
              />
            ),
          }
        )
      }),
    [error, filtered_workflows, isOriginUrl, pending, success],
  )

  return (
    <>
      {workflows.length !== 0 ? (
        <Collapse title={t('workflows.WorkflowForms')}>
          {error ? (
            <ErrorOnView error={error} setError={() => setError(null)} />
          ) : (
            <Tabs tabs={workflows} />
          )}
        </Collapse>
      ) : null}
      {pending ? (
        <ProgressModal
          title={t('workflows.SubmittingWorkflowData')}
          message={t('workflows.SubmittingWorkflowDataMessage')}
        />
      ) : null}
    </>
  )
}
