import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import ServerSideTable from '../components/table/ServerSideTable'
import {useTableColumns} from '../hooks/useTableColumns'
import {TableId} from '../stores/types/tables'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'
import FormAddIdentity from '../components/forms/FormAddIdentity'

export default function IdentityTableScreen() {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const deleteIdentityAction = useSelector((state) => state.identity.delete_identity.success)
  const addIdentityAction = useSelector((state) => state.identity.create_identity.success)
  const columns = useTableColumns(TableId.IDENTITY)

  return (
    <>
      {permissions.createIdentity ? (
        <Collapse title={t('identity.AddIdentityTitle')}>
          <FormAddIdentity />
        </Collapse>
      ) : null}
      {permissions.listIdentities ? (
        <>
          <EditTableWrapper tableId={TableId.IDENTITY} />
          <ServerSideTable
            columns={columns}
            entityName="identities"
            additionalFilter={"is_group eq '1'"}
            deleteAction={deleteIdentityAction}
            addAction={addIdentityAction}
            rowUrlAccessor={'id'}
          />
        </>
      ) : null}
    </>
  )
}
