import {useFormContext} from 'react-hook-form'
import {classNames} from '../../../utils/classNames'
import {ExclamationCircleIcon} from '@heroicons/react/outline'
import {useTranslation} from 'react-i18next'
import {getRhfValidations, isFieldRequired} from '../lib/utils'
import InputWrapper from './inputWrapper'

const TextArea = (props) => {
  const {name, label, size = 3, placeholder, validations, validationType, withRhfValidation} = props
  const {t} = useTranslation()
  const {register, formState, getValues, getFieldState} = useFormContext()

  const error = getFieldState(name, formState).error?.message || ''
  const registerOptions = withRhfValidation ? getRhfValidations(getValues, name, validations) : {}

  const id = name

  return (
    <InputWrapper
      id={id}
      isRequired={isFieldRequired(validations, validationType)}
      label={label}
      error={error}
    >
      <div className="relative mt-1 overflow-hidden rounded-md shadow-sm">
        <textarea
          className={classNames(
            error
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
              : 'border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500',
            'block w-full resize-none rounded-md p-2 py-3 focus:ring-0 sm:text-sm',
          )}
          {...register(name, registerOptions)}
          rows={size}
          name={name}
          placeholder={t(placeholder)}
          id={id}
          data-testid={`input-${name}`}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex p-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
    </InputWrapper>
  )
}

export default TextArea
