import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'employeeResource.Access',
    accessor: 'access',
    cell: 'Pill',
    label: 'employeeResource.Access',
  },
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.Notes',
    accessor: 'notes',
    label: 'TableLabelsCommon.Notes',
  },
  {
    header: 'employeeResource.ResourceName',
    label: 'employeeResource.ResourceName',
    accessor: 'resource',
    secondAccessor: 'resource_id',
    cell: 'LinkResourceCell',
  },
  {
    header: 'employeeResource.ResourceType',
    label: 'employeeResource.ResourceType',
    accessor: 'resource_type',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'TableLabelsCommon.ValidFrom',
    accessor: 'valid_from',
    cell: 'DateCell',
    label: 'TableLabelsCommon.ValidFrom',
  },
  {
    header: 'TableLabelsCommon.ValidTo',
    accessor: 'valid_to',
    cell: 'DateCell',
    label: 'TableLabelsCommon.ValidTo',
  },
  {
    header: 'Actions',
    label: 'Actions',
    width: 40,
    accessor: 'id',
    cell: 'ResourcesEmployeeActionCell',
    noSort: true,
  },
]
export default columnConfig
