import FormEditOidcProvider from '../components/forms/FormEditOidcProvider'
import FormEditOidcProviderLogo from '../components/forms/FormEditOidcProviderLogo'
import FormMappingOidcProviderRoles from '../components/forms/FormMappingOidcProviderRoles'
import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import {getOidcProviderAdmin, getOidcProviderRoles} from '../stores/oidc_providers'
import Logo from '../components/Logo'
import DeviceLogin from '../assets/DeviceLogin.svg'
import {useEffect} from 'react'
import {ModalOpenButton, Modal, ModalContentsBase} from '../components/Modal'
import {DownloadIcon, UploadIcon} from '@heroicons/react/outline'
import {useParams} from 'react-router-dom'

export default function IdentityProviderScreen() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  let {id} = useParams()

  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const oidc_provider_admin = useSelector(
    (state) => state.oidc_providers.get_oidc_provider_admin.success,
  )

  const set_oidc_provider_admin = useSelector(
    (state) => state.oidc_providers.set_oidc_provider_admin.success,
  )

  const oidc_provider_roles = useSelector(
    (state) => state.oidc_providers.get_oidc_provider_roles.success,
  )

  const delete_role_custom_attribute = useSelector(
    (state) => state.roles.delete_role_custom_attribute.success,
  )

  const add_role_custom_attribute = useSelector(
    (state) => state.roles.add_role_custom_attribute.success,
  )
  // get IdentityProvider
  useEffect(() => {
    dispatch(getOidcProviderAdmin(id))
  }, [dispatch, id, set_oidc_provider_admin])

  useEffect(() => {
    dispatch(getOidcProviderRoles(id))
  }, [
    dispatch,
    id,
    set_oidc_provider_admin,
    delete_role_custom_attribute,
    add_role_custom_attribute,
  ])

  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          {oidc_provider_admin?.logo?.thumbnail_id ? (
            <Logo ImgSrc={oidc_provider_admin?.logo?.thumbnail} />
          ) : (
            <Logo ImgSrc={DeviceLogin} />
          )}
          <div className="flex flex-col pt-3">
            <Modal>
              <ModalOpenButton>
                <UploadIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </ModalOpenButton>
              <ModalContentsBase>
                <FormEditOidcProviderLogo identity={oidc_provider_admin} />
              </ModalContentsBase>
            </Modal>
            <div className="space-y-1 text-center">
              <a
                href={oidc_provider_admin?.logo?.thumbnail}
                download={`${oidc_provider_admin?.name}.png`}
              >
                <DownloadIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </a>
            </div>
          </div>
          <div className="flex flex-col pt-3">
            <p>{oidc_provider_admin?.name}</p>
            <p>{oidc_provider_admin?.description}</p>
          </div>
        </div>
      </div>
      <Collapse title={t('addIdentityProvider.Details')}>
        <FormEditOidcProvider data={oidc_provider_admin} />
      </Collapse>
      <Collapse title={t('addIdentityProvider.RolesMapping')}>
        <FormMappingOidcProviderRoles
          data={oidc_provider_admin}
          oidc_roles={oidc_provider_roles}
          updateIdentityProvider={permissions.updateIdentityProvider}
        />
      </Collapse>
    </>
  )
}
