import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'subscriptions.Active',
    label: 'subscriptions.Active',
    accessor: 'active',
    cell: 'EditSubscriptionActionCell',
    noSort: true,
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'ColumnFilter',
    accessor: 'db_filter',
    label: 'subscriptions.DBFilter',
  },
  {
    header: 'ColumnFilter',
    accessor: 'description',
    label: 'subscriptions.Description',
  },
  {
    header: 'subscriptions.EndPoints',
    label: 'subscriptions.EndPoints',
    accessor: 'SubscriptionFn',
    cell: 'FormEditSubscriptionEndpointsCell',
  },
  {
    header: 'subscriptions.Executable',
    label: 'subscriptions.Executable',
    accessor: 'executable',
    cell: 'Pill',
  },
  {
    header: 'ColumnFilter',
    accessor: 'id',
    label: 'ID',
  },
  {
    header: 'ColumnFilter',
    accessor: 'payload_filter',
    label: 'subscriptions.PayloadFilter',
  },
  {
    header: 'subscriptions.TakeOver',
    label: 'subscriptions.TakeOver',
    accessor: 'take_over',
    cell: 'EditSubscriptionTakeOverCell',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'subscriptions.SelectWorkflow',
    label: 'subscriptions.SelectWorkflow',
    accessor: 'workflow.name',
    cell: 'FormEditSubscriptionWorkflowCell',
    noSort: true,
  },
  {
    header: 'executions.WorkflowId',
    label: 'executions.WorkflowId',
    accessor: 'workflow.id',
  },
]
export default columnConfig
