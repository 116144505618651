import {createPhoto} from './photos'
import axios from '../services/api'
import Logo from '../assets/Logo.svg'
import jumbotronImage from '../assets/jumbotronImage.jpg'
import {getServerPreferencesId} from '../services/setPreferences'

const UPLOAD_LOGO_PENDING = 'idfunctiondb/static_assets/UPLOAD_LOGO_PENDING'
const UPLOAD_LOGO_SUCCESS = 'idfunctiondb/static_assets/UPLOAD_LOGO_SUCCESS'
const UPLOAD_LOGO_ERROR = 'idfunctiondb/static_assets/UPLOAD_LOGO_ERROR'
const UPLOAD_LOGO_CLEAR_ERROR = 'idfunctiondb/static_assets/UPLOAD_LOGO_CLEAR_ERROR'

const UPLOAD_HERO_PENDING = 'idfunctiondb/static_assets/UPLOAD_HERO_PENDING'
const UPLOAD_HERO_SUCCESS = 'idfunctiondb/static_assets/UPLOAD_HERO_SUCCESS'
const UPLOAD_HERO_ERROR = 'idfunctiondb/static_assets/UPLOAD_HERO_ERROR'
const UPLOAD_HERO_CLEAR_ERROR = 'idfunctiondb/static_assets/UPLOAD_HERO_CLEAR_ERROR'

const UPLOAD_IMPRESSUM_PENDING = 'idfunctiondb/static_assets/UPLOAD_IMPRESSUM_PENDING'
const UPLOAD_IMPRESSUM_SUCCESS = 'idfunctiondb/static_assets/UPLOAD_IMPRESSUM_SUCCESS'
const UPLOAD_IMPRESSUM_ERROR = 'idfunctiondb/static_assets/UPLOAD_IMPRESSUM_ERROR'
const UPLOAD_IMPRESSUM_CLEAR_ERROR = 'idfunctiondb/static_assets/UPLOAD_IMPRESSUM_CLEAR_ERROR'

const UPLOAD_PRIVACY_POLICY_PENDING = 'idfunctiondb/static_assets/UPLOAD_PRIVACY_POLICY_PENDING'
const UPLOAD_PRIVACY_POLICY_SUCCESS = 'idfunctiondb/static_assets/UPLOAD_PRIVACY_POLICY_SUCCESS'
const UPLOAD_PRIVACY_POLICY_ERROR = 'idfunctiondb/static_assets/UPLOAD_PRIVACY_POLICY_ERROR'
const UPLOAD_PRIVACY_POLICY_CLEAR_ERROR =
  'idfunctiondb/static_assets/UPLOAD_PRIVACY_POLICY_CLEAR_ERROR'

const GET_LOGO_PENDING = 'idfunctiondb/static_assets/GET_LOGO_PENDING'
const GET_LOGO_SUCCESS = 'idfunctiondb/static_assets/GET_LOGO_SUCCESS'
const GET_LOGO_ERROR = 'idfunctiondb/static_assets/GET_LOGO_ERROR'

const GET_HERO_PENDING = 'idfunctiondb/static_assets/GET_HERO_PENDING'
const GET_HERO_SUCCESS = 'idfunctiondb/static_assets/GET_HERO_SUCCESS'
const GET_HERO_ERROR = 'idfunctiondb/static_assets/GET_HERO_ERROR'

const GET_IMPRESSUM_PENDING = 'idfunctiondb/static_assets/GET_IMPRESSUM_PENDING'
const GET_IMPRESSUM_SUCCESS = 'idfunctiondb/static_assets/GET_IMPRESSUM_SUCCESS'
const GET_IMPRESSUM_ERROR = 'idfunctiondb/static_assets/GET_IMPRESSUM_ERROR'

const GET_PRIVACY_POLICY_PENDING = 'idfunctiondb/static_assets/GET_PRIVACY_POLICY_PENDING'
const GET_PRIVACY_POLICY_SUCCESS = 'idfunctiondb/static_assets/GET_PRIVACY_POLICY_SUCCESS'
const GET_PRIVACY_POLICY_ERROR = 'idfunctiondb/static_assets/GET_PRIVACY_POLICY_ERROR'

const SET_SERVER_PREFERENCES_PENDING = 'idfunctiondb/static_assets/SET_SERVER_PREFERENCES_PENDING'
const SET_SERVER_PREFERENCES_SUCCESS = 'idfunctiondb/static_assets/SET_SERVER_PREFERENCES_SUCCESS'
const SET_SERVER_PREFERENCES_ERROR = 'idfunctiondb/static_assets/SET_SERVER_PREFERENCES_ERROR'
const SET_SERVER_PREFERENCES_CLEAR_ERROR =
  'idfunctiondb/static_assets/SET_SERVER_PREFERENCES_CLEAR_ERROR'

// Initial
const initialState = {
  upload_logo: {
    success: null,
    pending: false,
    error: null,
  },
  upload_hero: {
    success: null,
    pending: false,
    error: null,
  },
  upload_impressum: {
    success: null,
    pending: false,
    error: null,
  },
  upload_privacy_policy: {
    success: null,
    pending: false,
    error: null,
  },
  get_logo: {
    success: null,
    pending: false,
    error: null,
  },
  get_hero: {
    success: null,
    pending: false,
    error: null,
  },
  get_impressum: {
    success: null,
    pending: false,
    error: null,
  },
  get_privacy_policy: {
    success: null,
    pending: false,
    error: null,
  },
  set_server_preferences: {
    success: null,
    pending: false,
    error: null,
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPLOAD_LOGO_PENDING:
      return {
        ...state,
        upload_logo: {
          ...state.upload_logo,
          pending: true,
        },
      }
    case UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        upload_logo: {
          ...state.upload_logo,
          success: action.payload,
          pending: false,
        },
      }
    case UPLOAD_LOGO_ERROR:
      return {
        ...state,
        upload_logo: {
          ...state.upload_logo,
          error: action.payload,
          pending: false,
        },
      }
    case UPLOAD_LOGO_CLEAR_ERROR:
      return {
        ...state,
        upload_logo: {
          ...state.upload_logo,
          error: null,
          success: null,
        },
      }
    case UPLOAD_HERO_PENDING:
      return {
        ...state,
        upload_hero: {
          ...state.upload_hero,
          pending: true,
        },
      }
    case UPLOAD_HERO_SUCCESS:
      return {
        ...state,
        upload_hero: {
          ...state.upload_hero,
          success: action.payload,
          pending: false,
        },
      }
    case UPLOAD_HERO_ERROR:
      return {
        ...state,
        upload_hero: {
          ...state.upload_hero,
          error: action.payload,
          pending: false,
        },
      }
    case UPLOAD_HERO_CLEAR_ERROR:
      return {
        ...state,
        upload_hero: {
          ...state.upload_hero,
          error: null,
          success: null,
        },
      }
    case UPLOAD_IMPRESSUM_PENDING:
      return {
        ...state,
        upload_impressum: {
          ...state.upload_impressum,
          pending: true,
          success: null,
          error: null,
        },
      }
    case UPLOAD_IMPRESSUM_SUCCESS:
      return {
        ...state,
        upload_impressum: {
          ...state.upload_impressum,
          success: action.payload,
          pending: false,
        },
      }
    case UPLOAD_IMPRESSUM_ERROR:
      return {
        ...state,
        upload_impressum: {
          ...state.upload_impressum,
          error: action.payload,
          pending: false,
        },
      }
    case UPLOAD_IMPRESSUM_CLEAR_ERROR:
      return {
        ...state,
        upload_impressum: {
          ...state.upload_impressum,
          error: null,
          success: null,
        },
      }

    case UPLOAD_PRIVACY_POLICY_PENDING:
      return {
        ...state,
        upload_privacy_policy: {
          ...state.upload_privacy_policy,
          pending: true,
        },
      }
    case UPLOAD_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        upload_privacy_policy: {
          ...state.upload_privacy_policy,
          success: action.payload,
          pending: false,
        },
      }
    case UPLOAD_PRIVACY_POLICY_ERROR:
      return {
        ...state,
        upload_privacy_policy: {
          ...state.upload_privacy_policy,
          error: action.payload,
          pending: false,
        },
      }
    case UPLOAD_PRIVACY_POLICY_CLEAR_ERROR:
      return {
        ...state,
        upload_privacy_policy: {
          ...state.upload_privacy_policy,
          error: null,
          success: null,
        },
      }
    case GET_LOGO_PENDING:
      return {
        ...state,
        get_logo: {
          ...state.get_logo,
          pending: true,
        },
      }
    case GET_LOGO_SUCCESS:
      return {
        ...state,
        get_logo: {
          ...state.get_logo,
          success: action.payload,
          pending: false,
        },
      }
    case GET_LOGO_ERROR:
      return {
        ...state,
        get_logo: {
          ...state.get_logo,
          error: action.payload,
          pending: false,
        },
      }
    case GET_HERO_PENDING:
      return {
        ...state,
        get_hero: {
          ...state.get_hero,
          pending: true,
        },
      }
    case GET_HERO_SUCCESS:
      return {
        ...state,
        get_hero: {
          ...state.get_hero,
          success: action.payload,
          pending: false,
        },
      }
    case GET_HERO_ERROR:
      return {
        ...state,
        get_hero: {
          ...state.get_hero,
          error: action.payload,
          pending: false,
        },
      }
    case GET_IMPRESSUM_PENDING:
      return {
        ...state,
        get_impressum: {
          ...state.get_impressum,
          pending: true,
        },
      }
    case GET_IMPRESSUM_SUCCESS:
      return {
        ...state,
        get_impressum: {
          ...state.get_impressum,
          success: action.payload,
          pending: false,
        },
      }
    case GET_IMPRESSUM_ERROR:
      return {
        ...state,
        get_impressum: {
          ...state.get_impressum,
          error: action.payload,
          pending: false,
        },
      }
    case SET_SERVER_PREFERENCES_PENDING:
      return {
        ...state,
        set_server_preferences: {
          ...state.set_server_preferences,
          pending: true,
        },
      }
    case SET_SERVER_PREFERENCES_SUCCESS:
      return {
        ...state,
        set_server_preferences: {
          ...state.set_server_preferences,
          success: action.payload,
          pending: false,
        },
      }
    case SET_SERVER_PREFERENCES_ERROR:
      return {
        ...state,
        set_server_preferences: {
          ...state.set_server_preferences,
          error: action.payload,
          pending: false,
        },
      }
    case SET_SERVER_PREFERENCES_CLEAR_ERROR:
      return {
        ...state,
        set_server_preferences: {
          ...state.set_server_preferences,
          error: null,
        },
      }
    case GET_PRIVACY_POLICY_PENDING:
      return {
        ...state,
        get_privacy_policy: {
          ...state.get_privacy_policy,
          pending: true,
        },
      }
    case GET_PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        get_privacy_policy: {
          ...state.get_privacy_policy,
          success: action.payload,
          pending: false,
        },
      }
    case GET_PRIVACY_POLICY_ERROR:
      return {
        ...state,
        get_privacy_policy: {
          ...state.get_privacy_policy,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

function uploadLogoPending() {
  return {type: UPLOAD_LOGO_PENDING}
}

function uploadLogoSuccess(payload) {
  return {type: UPLOAD_LOGO_SUCCESS, payload: payload}
}

function uploadLogoError(error) {
  return {type: UPLOAD_LOGO_ERROR, payload: error}
}

export function uploadLogoClearError() {
  return {type: UPLOAD_LOGO_CLEAR_ERROR}
}

function uploadHeroPending() {
  return {type: UPLOAD_HERO_PENDING}
}

function uploadHeroSuccess(payload) {
  return {type: UPLOAD_HERO_SUCCESS, payload: payload}
}

function uploadHeroError(error) {
  return {type: UPLOAD_HERO_ERROR, payload: error}
}

export function uploadHeroClearError() {
  return {type: UPLOAD_HERO_CLEAR_ERROR}
}

function uploadImpressumPending() {
  return {type: UPLOAD_IMPRESSUM_PENDING}
}

function uploadImpressumSuccess(payload) {
  return {type: UPLOAD_IMPRESSUM_SUCCESS, payload: payload}
}

function uploadImpressumError(error) {
  return {type: UPLOAD_IMPRESSUM_ERROR, payload: error}
}

export function uploadImpressumClearError() {
  return {type: UPLOAD_IMPRESSUM_CLEAR_ERROR}
}

function uploadPrivacyPolicyPending() {
  return {type: UPLOAD_PRIVACY_POLICY_PENDING}
}

function uploadPrivacyPolicySuccess(payload) {
  return {type: UPLOAD_PRIVACY_POLICY_SUCCESS, payload: payload}
}

function uploadPrivacyPolicyError(error) {
  return {type: UPLOAD_PRIVACY_POLICY_ERROR, payload: error}
}

export function uploadPrivacyPolicyClearError() {
  return {type: UPLOAD_PRIVACY_POLICY_CLEAR_ERROR}
}

function getLogoPending() {
  return {type: GET_LOGO_PENDING}
}

function getLogoSuccess(payload) {
  return {type: GET_LOGO_SUCCESS, payload: payload}
}

function getLogoError(error) {
  return {type: GET_LOGO_ERROR, payload: error}
}

function getHeroPending() {
  return {type: GET_HERO_PENDING}
}

function getHeroSuccess(payload) {
  return {type: GET_HERO_SUCCESS, payload: payload}
}

function getHeroError(error) {
  return {type: GET_HERO_ERROR, payload: error}
}

function getImpressumPending() {
  return {type: GET_IMPRESSUM_PENDING}
}

function getImpressumSuccess(payload) {
  return {type: GET_IMPRESSUM_SUCCESS, payload: payload}
}

function getImpressumError(error) {
  return {type: GET_IMPRESSUM_ERROR, payload: error}
}

function getPrivacyPolicyPending() {
  return {type: GET_PRIVACY_POLICY_PENDING}
}

function getPrivacyPolicySuccess(payload) {
  return {type: GET_PRIVACY_POLICY_SUCCESS, payload: payload}
}

function getPrivacyPolicyError(error) {
  return {type: GET_PRIVACY_POLICY_ERROR, payload: error}
}

function setServerPreferencesPending() {
  return {type: SET_SERVER_PREFERENCES_PENDING}
}

export function setServerPreferencesSuccess(payload) {
  return {type: SET_SERVER_PREFERENCES_SUCCESS, payload: payload}
}

function setServerPreferencesError(error) {
  return {type: SET_SERVER_PREFERENCES_ERROR, payload: error}
}

export function setServerPreferencesClearError() {
  return {type: SET_SERVER_PREFERENCES_CLEAR_ERROR}
}

export const uploadLogo = (target, url) => (dispatch) => {
  dispatch(uploadLogoPending())
  var formData = new FormData()
  var imagefile = target
  formData.append('logo', imagefile.files[0])
  if (imagefile.files[0]) {
    dispatch(createPhoto(url, formData, uploadLogoError))
      .then((resp) => {
        resp && dispatch(uploadLogoSuccess(resp.id))
        return resp
      })
      .then((res) => {
        dispatch(getLogo())
        return res
      })
      .catch((error) => {
        dispatch(uploadLogoError(error))
      })
  } else {
    dispatch(uploadLogoError({}))
  }
}

export const uploadHero = (target, url) => (dispatch) => {
  dispatch(uploadHeroPending())
  var formData = new FormData()
  var imagefile = target
  formData.append('hero', imagefile.files[0])
  if (imagefile.files[0]) {
    dispatch(createPhoto(url, formData, uploadHeroError)) //TODO: be careful about url
      .then((resp) => {
        resp && dispatch(uploadHeroSuccess(resp))
        return resp
      })
      .then((res) => {
        dispatch(getHero())
        return res
      })
      .catch((error) => {
        dispatch(uploadHeroError(error))
      })
  }
}

export const uploadImpressum = (target, url) => (dispatch) => {
  dispatch(uploadImpressumPending())
  var formData = new FormData()
  var impressumFile = target
  formData.append('impressum', impressumFile.files[0])
  if (impressumFile.files[0]) {
    dispatch(createPhoto(url, formData, uploadImpressumError))
      .then((resp) => {
        resp && dispatch(uploadImpressumSuccess(resp))
        return resp
      })
      .then((res) => {
        dispatch(getImpressum())
        return res
      })
      .catch((error) => {
        dispatch(uploadImpressumError(error))
      })
  }
}

export const uploadPrivacyPolicy = (target, url) => (dispatch) => {
  dispatch(uploadPrivacyPolicyPending())
  let formData = new FormData()
  formData.append('privacy_policy', target)
  if (target) {
    dispatch(createPhoto(url, formData, uploadPrivacyPolicyError))
      .then((resp) => {
        resp && dispatch(uploadPrivacyPolicySuccess(resp))
        return resp
      })
      .then((res) => {
        dispatch(getPrivacyPolicy())
        return res
      })
      .catch((error) => {
        dispatch(uploadPrivacyPolicyError(error))
      })
  }
}

export const getLogo = () => (dispatch) => {
  dispatch(getLogoPending())
  return axios
    .get('/api/v1/static_assets/logo') // TODO: change url
    .then((res) => {
      const result = res.data?.warning_key ? Logo : `/api/v1/static_assets/logo?ts=${Date.now()}`
      dispatch(getLogoSuccess(result))
      return result
    })
    .catch((error) => dispatch(getLogoError(error)))
}

export const getHero = () => (dispatch) => {
  dispatch(getHeroPending())
  return axios
    .get('/api/v1/static_assets/hero') // TODO: change url
    .then((res) => {
      const url = res.data?.warning_key
        ? jumbotronImage
        : `/api/v1/static_assets/hero?ts=${Date.now()}`
      dispatch(getHeroSuccess(url))
      return url
    })
    .catch((error) => dispatch(getHeroError(error)))
}

export const setServerPreferences = (values) => (dispatch) => {
  dispatch(setServerPreferencesPending())
  const id = getServerPreferencesId()
  return axios
    .put(`/api/v1/server_preferences/${id}`, values)
    .then((res) => {
      dispatch(setServerPreferencesSuccess(res.data))
    })
    .catch((error) => dispatch(setServerPreferencesError(error)))
}

export const getImpressum = () => (dispatch) => {
  dispatch(getImpressumPending())
  return axios
    .get('/api/v1/impressum')
    .then((res) => {
      dispatch(getImpressumSuccess(res.data))
    })
    .catch((error) => dispatch(getImpressumError(error)))
}

export const getPrivacyPolicy = () => (dispatch) => {
  dispatch(getPrivacyPolicyPending())
  return axios
    .get('/api/v1/privacy_policy') // TODO_221: check with edin
    .then((res) => {
      dispatch(getPrivacyPolicySuccess(res.data))
    })
    .catch((error) => dispatch(getPrivacyPolicyError(error)))
}
