import {Formik, Form} from 'formik'
import {AsyncSelectInput2, TextInput, TextInputCustomAttribute} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {setOidcProviderAdminClearError} from '../../stores/oidc_providers'
import {containsKey, isEmpty} from '../../utils/custom_attributes'
import {useRef, useState} from 'react'
import {addRoleCustomAttribute, deleteRoleCustomAttribute} from '../../stores/roles'

export default function FormMappingOidcProviderRoles({data, oidc_roles, updateIdentityProvider}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const pending = useSelector((state) => state.oidc_providers.set_oidc_provider_admin.pending)
  const error = useSelector((state) => state.oidc_providers.set_oidc_provider_admin.error)

  const [editable] = useState(true)
  const oidc_name = data.name.toLowerCase()
  const initialValues = {
    role_id: '',
    new_name: '',
  }

  const validationSchema = Yup.object({
    new_name: Yup.string()
      .max(256, t('yupError.MaxCharsAllowed', {numberOfChars: '256'}))
      .required(
        t('yupError.ThisFieldIsRequired', {field: t('addIdentityProvider.PredefinedName')}),
      ),
    role_id: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addIdentityProvider.Role')}),
    ),
  })

  const handleSubmit = async (values, actions) => {
    let temp = {}
    temp[oidc_name] = values.new_name
    dispatch(addRoleCustomAttribute(values.role_id, {custom_attributes: [temp]}))
    if (roleSelectRef.current !== undefined) roleSelectRef.current.select.setValue([])
    actions.resetForm()
  }

  const customAttributes = isEmpty(oidc_roles?.results)
    ? []
    : oidc_roles?.results.map((role) => {
        if (containsKey(role.custom_attributes, oidc_name)) {
          return {
            label: role.name + ' (' + role.description + ') ',
            name: role.id,
            defaultValue: role.custom_attributes[oidc_name],
            type: 'text',
            forbidden: false,
            deleteAble: true,
          }
        }
        return []
      })

  const renderRoles = (inputs) => {
    return inputs.map((input) => {
      return (
        <div key={input.name}>
          <TextInputCustomAttribute
            disabled={editable}
            label={input.label}
            type="text"
            defaultValue={input.defaultValue}
            name={input.name}
            id={input.name}
            permission={updateIdentityProvider} // TODO: this should be customAttribute permission
            customAttributeKey={oidc_name}
            deleteCustomAttribute={deleteRoleCustomAttribute}
          />
        </div>
      )
    })
  }

  let roleSelectRef = useRef()

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        <Form>
          {renderRoles(customAttributes)}
          {updateIdentityProvider && (
            <>
              <AsyncSelectInput2
                label={t('addIdentityProvider.Role')}
                url={`/api/v1/roles?q=`}
                placeholder={t('addIdentityProvider.RolePlaceholder')}
                optionLabel="name"
                optionValue="id"
                name="role_id"
                innerRef={roleSelectRef}
                required
              />
              <TextInput
                label={t('addIdentityProvider.PredefinedName')}
                name="new_name"
                type="text"
                placeholder=""
              />
              <FormikSaveButton
                type="submit"
                text={t('addIdentityProvider.Map')}
                testid="form-mapping-oidc-provider-roles-submit"
                primary
                pending={pending}
                error={error}
                setError={() => dispatch(setOidcProviderAdminClearError())}
              />
            </>
          )}
        </Form>
      </Formik>
    </>
  )
}
