import {flatten, splitToChunks, series} from '../utils/visitor'
import {updateLoadingBarMultiSelect} from './loading_bar'
import {applyActionOnCard, getCard} from './cards'
import {getEmployee} from './employees'
import {getVisitor} from './visitors'
import {createCardActionsReport} from './reports'
import {createNotification} from './notifications'
import I18n from '../i18n'

const APPLY_ACTION_CARDS_PENDING = 'idfunctiondb/cards/APPLY_ACTION_CARDS_PENDING'
const APPLY_ACTION_CARDS_SUCCESS = 'idfunctiondb/cards/APPLY_ACTION_CARDS_SUCCESS'
const APPLY_ACTION_CARDS_ERROR = 'idfunctiondb/cards/APPLY_ACTION_CARDS_ERROR'
const APPLY_ACTION_CARDS_CLEAR_ERROR = 'idfunctiondb/cards/APPLY_ACTION_CARDS_CLEAR_ERROR'

const MULTI_SELECT_ACTION_PENDING = 'idfunctiondb/cards/MULTI_SELECT_ACTION_PENDING'
const MULTI_SELECT_ACTION_SUCCESS = 'idfunctiondb/cards/MULTI_SELECT_ACTION_SUCCESS'
const MULTI_SELECT_ACTION_ERROR = 'idfunctiondb/cards/MULTI_SELECT_ACTION_ERROR'

// Initial
const initialState = {
  apply_action_cards: {
    success: null,
    success_info: {
      code: null,
      message: '',
    },
    pending: false,
    error: null,
  },

  multi_select_action: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case APPLY_ACTION_CARDS_PENDING:
      return {
        ...state,
        apply_action_cards: {
          ...state.apply_action_cards,
          pending: true,
        },
      }
    case APPLY_ACTION_CARDS_SUCCESS:
      return {
        ...state,
        apply_action_cards: {
          ...state.apply_action_cards,
          success: action.payload,
          success_info: {
            code: 201,
            message: 'notificationSuccess.multi_produceCard',
          },
          pending: false,
        },
      }
    case APPLY_ACTION_CARDS_ERROR:
      return {
        ...state,
        apply_action_cards: {
          ...state.apply_action_cards,
          error: action.payload,
          pending: false,
        },
      }
    case APPLY_ACTION_CARDS_CLEAR_ERROR:
      return {
        ...state,
        apply_action_cards: {
          ...state.apply_action_cards,
          error: null,
        },
      }
    case MULTI_SELECT_ACTION_PENDING:
      return {
        ...state,
        multi_select_action: {
          ...state.multi_select_action,
          pending: true,
        },
      }
    case MULTI_SELECT_ACTION_SUCCESS:
      return {
        ...state,
        multi_select_action: {
          ...state.multi_select_action,
          success: action.payload,
          pending: false,
        },
      }
    case MULTI_SELECT_ACTION_ERROR:
      return {
        ...state,
        multi_select_action: {
          ...state.multi_select_action,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

function applyActionOnCardsPending() {
  return {type: APPLY_ACTION_CARDS_PENDING}
}
function applyActionOnCardsSuccess(payload) {
  return {type: APPLY_ACTION_CARDS_SUCCESS, payload: payload}
}
function applyActionOnCardsError(error) {
  return {type: APPLY_ACTION_CARDS_ERROR, payload: error}
}
export function applyActionOnCardsClearError() {
  return {type: APPLY_ACTION_CARDS_CLEAR_ERROR}
}

function multiSelectActionPending() {
  return {type: MULTI_SELECT_ACTION_PENDING}
}
function multiSelectActionSuccess(payload) {
  return {type: MULTI_SELECT_ACTION_SUCCESS, payload: payload}
}
function multiSelectActionError(error) {
  return {type: MULTI_SELECT_ACTION_ERROR, payload: error}
}

//Operations
export const applyActionOnCards =
  (getEmployeesResult, arrayOfUrls, actions, step, total, document, arrayOfTranslations, userID) =>
  (dispatch) => {
    dispatch(applyActionOnCardsPending())
    let applyActionsResult = []
    const chunks = splitToChunks(arrayOfUrls, 1)
    return series(chunks, (chunk) => {
      const applyActionsPromises = chunk.map((card) =>
        dispatch(applyActionOnCard(card.url, actions, false, userID)),
      )
      return Promise.all(applyActionsPromises).then(
        (res) => (applyActionsResult = applyActionsResult.concat(res)),
        dispatch(updateLoadingBarMultiSelect(step * (100 / total))),
        step++,
      )
    })
      .then(() => {
        const cardActionReport = flatten(
          arrayOfUrls.map((array, index) => [
            Object.assign(
              {},
              {badgenr: array.badgenr},
              {full_name: getEmployeesResult[index].person.full_name},
              {action: actions.action},
              {
                status: applyActionsResult[index].response
                  ? applyActionsResult[index].response.status
                  : applyActionsResult[index].status,
              },
              applyActionsResult[index].response,
            ),
          ]),
        )
        dispatch(createCardActionsReport(cardActionReport, arrayOfTranslations, document)).then(
          (res) => {
            dispatch(
              createNotification(userID, {
                code: '201',
                message: I18n.t('notificationSuccess.multiAction', {
                  action: I18n.t('multiSelectActions.' + actions.action),
                  link: res,
                }),
              }),
            )
          },
        )
        dispatch(applyActionOnCardsSuccess(applyActionsResult))
        dispatch(multiSelectActionSuccess(arrayOfUrls))
      })
      .catch((error) => {
        dispatch(applyActionOnCardsError(error))
      })
  }

export const multiSelectAction =
  (arrayOfCardIds, actions, document, arrayOfTranslations, userID) => (dispatch) => {
    dispatch(multiSelectActionPending())
    let getCardsResult = []
    const chunks = splitToChunks(arrayOfCardIds, 50)
    let step = 1
    const total = 2 * chunks.length + arrayOfCardIds.length
    return series(chunks, (chunk) => {
      const cardsPromises = chunk
        .filter((object) => object.id !== undefined)
        .map((object) => dispatch(getCard(`/api/v1/cards/${object.id}`)))
      return Promise.all(cardsPromises).then(
        (res) => (getCardsResult = getCardsResult.concat(res)),
        dispatch(updateLoadingBarMultiSelect(step * (100 / total))),
        step++,
      )
    }).then(() => {
      let getEmployeesResult = []
      const chunks = splitToChunks(getCardsResult, 50)
      return series(chunks, (chunk) => {
        const cardUrlPromises = chunk.map((object) =>
          object.employee_id !== null
            ? dispatch(getEmployee(`${object.employee_id}`))
            : object.visitor_id !== null
            ? dispatch(getVisitor(`${object.visitor_id}`))
            : dispatch(function () {
                return {
                  person: {full_name: I18n.t('multiActionReport.FreeCard')},
                  badgenr: object.badgenr,
                }
              }),
        )
        return Promise.all(cardUrlPromises).then(
          (res) => (getEmployeesResult = getEmployeesResult.concat(res)),
          dispatch(updateLoadingBarMultiSelect(step * (100 / total))),
          step++,
        )
      })
        .then(() => {
          dispatch(
            applyActionOnCards(
              getEmployeesResult,
              getCardsResult,
              actions,
              step,
              total,
              document,
              arrayOfTranslations,
              userID,
            ),
          )
        })
        .catch((error) => {
          dispatch(multiSelectActionError(error))
        })
    })
  }
