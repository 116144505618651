import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import Tabs from '../components/Tabs'
import FormAddCardPersonalized from '../components/forms/FormAddCardPersonalized'
import FormAddCardNonPersonalized from '../components/forms/FormAddCardNonPersonalized'
import FormAddCardPreinit from '../components/forms/FormAddCardPreinit'
import ServerSideTable from '../components/table/ServerSideTable'
import {CARD_TABLE} from '../utils/formViews'
import WorkflowForms from '../components/WorkflowForms'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'
import {useTableColumns} from '../hooks/useTableColumns'
import {TableId} from '../stores/types/tables'
import CardMultipleSelectedActions from '../components/CardMultipleSelectedActions'

export default function CardTableScreen() {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const bulk_create_personalized_cards = useSelector(
    (state) => state.cards.bulk_create_personalized_cards.success,
  )
  const bulk_create_non_personalized_cards = useSelector(
    (state) => state.cards.bulk_create_non_personalized_cards.success,
  )
  const bulk_create_pre_init_cards = useSelector(
    (state) => state.cards.bulk_create_pre_init_cards.success,
  )

  const tabsArray = [
    {name: t('addCard.Personalized'), component: <FormAddCardPersonalized />},
    {name: t('addCard.NonPersonalized'), component: <FormAddCardNonPersonalized />},
    {name: t('addCard.PreInitialized'), component: <FormAddCardPreinit />},
  ]

  const columns = useTableColumns(TableId.CARD)

  return (
    <>
      {permissions.createCard ? (
        <Collapse title={t('addCard.AddCardTitle')}>
          <Tabs tabs={tabsArray} />
        </Collapse>
      ) : null}
      <WorkflowForms renderOnUi={CARD_TABLE} />
      {permissions.listCards ? (
        <>
          <EditTableWrapper tableId={TableId.CARD} />
          <ServerSideTable
            rowUrlAccessor={'id'}
            title={t('cardList.SelectCards')}
            columns={columns}
            entityName="cards"
            addAction={bulk_create_personalized_cards?.length}
            addActionQuick={bulk_create_non_personalized_cards?.results?.length}
            editAction={bulk_create_pre_init_cards?.length}
            SelectedActionsComponent={CardMultipleSelectedActions}
          />
        </>
      ) : null}
    </>
  )
}
