import {Controller, useFormContext} from 'react-hook-form'
import {ErrorMessage} from '../../ErrorMessage'
import View from './View'
import {useTranslation} from 'react-i18next'
import {isFieldRequired} from '../../lib/utils'

function AsyncSelectInput(props) {
  const {
    url,
    type,
    nestedOptionLabel,
    optionLabel,
    optionValue,
    name,
    isMulti,
    label,
    validations,
    validationType,
    defaultValue,
    disabled,
    clearOptions,
  } = props
  const {control, formState, setError, clearErrors, getFieldState} = useFormContext()
  const {t} = useTranslation()
  const id = `${name}-${type}-${label}`
  const validationError = getFieldState(name, formState).error?.message || ''

  return (
    <div className="flex w-full flex-col gap-1">
      {label && (
        <label className="block text-sm font-medium text-gray-700" htmlFor={id}>
          {t(label)}
          {isFieldRequired(validations, validationType) && '*'}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({field, fieldState}) => {
          return (
            <>
              <View
                defaultValue={defaultValue}
                hasError={!!validationError}
                optionValue={optionValue}
                optionLabel={optionLabel}
                nestedOptionLabel={nestedOptionLabel}
                url={url}
                clearErrors={() => clearErrors(name)}
                onError={(message) => setError(name, {type: 'custom', message})}
                isMulti={isMulti}
                onChange={(option) => {
                  isMulti
                    ? field.onChange(option.map((c) => c.value))
                    : field.onChange(option?.value)
                }}
                field={field}
                disabled={disabled}
                clearOptions={clearOptions}
              />
              <ErrorMessage error={t(validationError || fieldState.error?.message)} />
            </>
          )
        }}
      />
    </div>
  )
}

export default AsyncSelectInput
