import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '../ErrorMessage'
import {useTranslation} from 'react-i18next'
import {getRhfValidations, isFieldRequired} from '../lib/utils'

const Checkbox = (props) => {
  const {name, label, validations, validationType, withRhfValidation} = props
  const {register, formState, getValues, getFieldState} = useFormContext()
  const {t} = useTranslation()
  const error = getFieldState(name, formState).error?.message || ''
  const registerOptions = withRhfValidation ? getRhfValidations(getValues, name, validations) : {}

  return (
    <>
      <label className="flex w-fit cursor-pointer items-center gap-2 font-medium text-gray-700">
        <input
          className="h-4 w-4 rounded border-gray-300 text-lime-600 accent-lime-500 focus:ring-lime-500"
          name={name}
          type="checkbox"
          data-testid={`checkbox-${name}`}
          {...register(name, registerOptions)}
        />
        {t(label)}
        {isFieldRequired(validations, validationType) && '*'}
      </label>
      <ErrorMessage error={t(error)} />
    </>
  )
}

export default Checkbox
