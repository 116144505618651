import {useState} from 'react'
import FormEditImageCameraInventory from './FormEditImageCameraInventory'
import CameraRegular from '../CameraRegular'

export default function FormCameraRegular({identity}) {
  const [imageURI, setImageURI] = useState(null)

  return (
    <>
      {/* Take the photo */}
      {!imageURI && (
        <>
          <CameraRegular
            identity={identity} //avatarUrlPost={props.avatarUrlPost} identity.photos.list, //props.avatarUrlPost,
            setImageURI={setImageURI}
          />
        </>
      )}
      {/* Edit the photo */}
      {imageURI && <FormEditImageCameraInventory identity={identity} imageURI={imageURI} />}
    </>
  )
}
