import axios from '../services/api'
import {flatten} from '../utils/visitor'
import {createNotification, createNotificationOnClient} from './notifications'
import I18n from '../i18n'

// Types
const GET_VISITATIONS_PENDING = 'idfunctiondb/visitations/GET_VISITATIONS_PENDING'
const GET_VISITATIONS_SUCCESS = 'idfunctiondb/visitations/GET_VISITATIONS_SUCCESS'
const GET_VISITATIONS_ERROR = 'idfunctiondb/visitations/GET_VISITATIONS_ERROR'
const GET_VISITATIONS_CLEAR_ERROR = 'idfunctiondb/visitations/GET_VISITATIONS_CLEAR_ERROR'

const GET_VISITATION_PENDING = 'idfunctiondb/visitations/GET_VISITATION_PENDING'
const GET_VISITATION_SUCCESS = 'idfunctiondb/visitations/GET_VISITATION_SUCCESS'
const GET_VISITATION_ERROR = 'idfunctiondb/visitations/GET_VISITATION_ERROR'

const SET_VISITATION_PENDING = 'idfunctiondb/visitations/SET_VISITATION_PENDING'
const SET_VISITATION_SUCCESS = 'idfunctiondb/visitations/SET_VISITATION_SUCCESS'
const SET_VISITATION_ERROR = 'idfunctiondb/visitations/SET_VISITATION_ERROR'
const SET_VISITATION_CLEAR_ERROR = 'idfunctiondb/visitations/SET_VISITATION_CLEAR_ERROR'

const CANCEL_VISITATION_PENDING = 'idfunctiondb/visitations/CANCEL_VISITATION_PENDING'
const CANCEL_VISITATION_SUCCESS = 'idfunctiondb/visitations/CANCEL_VISITATION_SUCCESS'
const CANCEL_VISITATION_ERROR = 'idfunctiondb/visitations/CANCEL_VISITATION_ERROR'

const DELETE_VISITATION_PENDING = 'idfunctiondb/visitations/DELETE_VISITATION_PENDING'
const DELETE_VISITATION_SUCCESS = 'idfunctiondb/visitations/DELETE_VISITATION_SUCCESS'
const DELETE_VISITATION_ERROR = 'idfunctiondb/visitations/DELETE_VISITATION_ERROR'

const CREATE_VISITATION_PENDING = 'idfunctiondb/visitations/CREATE_VISITATION_PENDING'
const CREATE_VISITATION_SUCCESS = 'idfunctiondb/visitations/CREATE_VISITATION_SUCCESS'
const CREATE_VISITATION_ERROR = 'idfunctiondb/visitations/CREATE_VISITATION_ERROR'

const ADD_VISITOR_TO_VISITATION_PENDING =
  'idfunctiondb/visitations/ADD_VISITOR_TO_VISITATION_PENDING'
const ADD_VISITOR_TO_VISITATION_SUCCESS =
  'idfunctiondb/visitations/ADD_VISITOR_TO_VISITATION_SUCCESS'
const ADD_VISITOR_TO_VISITATION_ERROR = 'idfunctiondb/visitations/ADD_VISITOR_TO_VISITATION_ERROR'

const ADD_EMPLOYEE_TO_VISITATION_PENDING =
  'idfunctiondb/visitations/ADD_EMPLOYEE_TO_VISITATION_PENDING'
const ADD_EMPLOYEE_TO_VISITATION_SUCCESS =
  'idfunctiondb/visitations/ADD_EMPLOYEE_TO_VISITATION_SUCCESS'
const ADD_EMPLOYEE_TO_VISITATION_ERROR = 'idfunctiondb/visitations/ADD_EMPLOYEE_TO_VISITATION_ERROR'

const REMOVE_VISITOR_FROM_VISITATION_PENDING =
  'idfunctiondb/visitations/REMOVE_VISITOR_FROM_VISITATION_PENDING'
const REMOVE_VISITOR_FROM_VISITATION_SUCCESS =
  'idfunctiondb/visitations/REMOVE_VISITOR_FROM_VISITATION_SUCCESS'
const REMOVE_VISITOR_FROM_VISITATION_ERROR =
  'idfunctiondb/visitations/REMOVE_VISITOR_FROM_VISITATION_ERROR'

const REMOVE_EMPLOYEE_FROM_VISITATION_PENDING =
  'idfunctiondb/visitations/REMOVE_EMPLOYEE_FROM_VISITATION_PENDING'
const REMOVE_EMPLOYEE_FROM_VISITATION_SUCCESS =
  'idfunctiondb/visitations/REMOVE_EMPLOYEE_FROM_VISITATION_SUCCESS'
const REMOVE_EMPLOYEE_FROM_VISITATION_ERROR =
  'idfunctiondb/visitations/REMOVE_EMPLOYEE_FROM_VISITATION_ERROR'

const REMOVE_VISITORS_FROM_VISITATION_PENDING =
  'idfunctiondb/visitations/REMOVE_VISITORS_FROM_VISITATION_PENDING'
const REMOVE_VISITORS_FROM_VISITATION_SUCCESS =
  'idfunctiondb/visitations/REMOVE_VISITORS_FROM_VISITATION_SUCCESS'
const REMOVE_VISITORS_FROM_VISITATION_ERROR =
  'idfunctiondb/visitations/REMOVE_VISITORS_FROM_VISITATION_ERROR'

const REMOVE_EMPLOYEES_FROM_VISITATION_PENDING =
  'idfunctiondb/visitations/REMOVE_EMPLOYEES_FROM_VISITATION_PENDING'
const REMOVE_EMPLOYEES_FROM_VISITATION_SUCCESS =
  'idfunctiondb/visitations/REMOVE_EMPLOYEES_FROM_VISITATION_SUCCESS'
const REMOVE_EMPLOYEES_FROM_VISITATION_ERROR =
  'idfunctiondb/visitations/REMOVE_EMPLOYEES_FROM_VISITATION_ERROR'

const ADD_VISITORS_TO_VISITATION_PENDING =
  'idfunctiondb/visitations/ADD_VISITORS_TO_VISITATION_PENDING'
const ADD_VISITORS_TO_VISITATION_SUCCESS =
  'idfunctiondb/visitations/ADD_VISITORS_TO_VISITATION_SUCCESS'
const ADD_VISITORS_TO_VISITATION_ERROR = 'idfunctiondb/visitations/ADD_VISITORS_TO_VISITATION_ERROR'

const ADD_EMPLOYEES_TO_VISITATION_PENDING =
  'idfunctiondb/visitations/ADD_EMPLOYEES_TO_VISITATION_PENDING'
const ADD_EMPLOYEES_TO_VISITATION_SUCCESS =
  'idfunctiondb/visitations/ADD_EMPLOYEES_TO_VISITATION_SUCCESS'
const ADD_EMPLOYEES_TO_VISITATION_ERROR =
  'idfunctiondb/visitations/ADD_EMPLOYEES_TO_VISITATION_ERROR'

const CREATE_VISITATION_ADD_PARTICIPANTS_PENDING =
  'idfunctiondb/visitations/CREATE_VISITATION_ADD_PARTICIPANTS_PENDING'
const CREATE_VISITATION_ADD_PARTICIPANTS_SUCCESS =
  'idfunctiondb/visitations/CREATE_VISITATION_ADD_PARTICIPANTS_SUCCESS'
const CREATE_VISITATION_ADD_PARTICIPANTS_ERROR =
  'idfunctiondb/visitations/CREATE_VISITATION_ADD_PARTICIPANTS_ERROR'

const REMOVE_PARTICIPANTS_FROM_VISITATION_PENDING =
  'idfunctiondb/visitations/REMOVE_PARTICIPANTS_FROM_VISITATION_PENDING'
const REMOVE_PARTICIPANTS_FROM_VISITATION_SUCCESS =
  'idfunctiondb/visitations/REMOVE_PARTICIPANTS_FROM_VISITATION_SUCCESS'
const REMOVE_PARTICIPANTS_FROM_VISITATION_ERROR =
  'idfunctiondb/visitations/REMOVE_PARTICIPANTS_FROM_VISITATION_ERROR'

const initialState = {
  get_visitations: {
    success: [],
    pending: false,
    error: null,
  },

  get_visitation: {
    success: null,
    pending: false,
    error: null,
  },

  set_visitation: {
    success: [],
    pending: false,
    error: null,
    successMessage: false,
  },

  create_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  cancel_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  delete_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  add_visitor_to_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  add_visitors_to_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  add_employee_to_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  add_employees_to_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  remove_visitor_from_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  remove_employee_from_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  create_visitation_add_participants: {
    success: [],
    pending: false,
    error: null,
  },

  remove_visitors_from_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  remove_employees_from_visitation: {
    success: [],
    pending: false,
    error: null,
  },

  remove_participants_from_visitation: {
    success: [],
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_VISITATIONS_PENDING:
      return {
        ...state,
        get_visitations: {
          ...state.get_visitations,
          pending: true,
        },
      }
    case GET_VISITATIONS_SUCCESS:
      return {
        ...state,
        get_visitations: {
          ...state.get_visitations,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITATIONS_ERROR:
      return {
        ...state,
        get_visitations: {
          ...state.get_visitations,
          error: action.payload,
          pending: false,
        },
      }
    case GET_VISITATIONS_CLEAR_ERROR:
      return {
        ...state,
        get_visitations: {
          ...state.get_visitations,
          error: null,
          pending: false,
        },
      }
    case GET_VISITATION_PENDING:
      return {
        ...state,
        get_visitation: {
          ...state.get_visitation,
          pending: true,
        },
      }
    case GET_VISITATION_SUCCESS:
      return {
        ...state,
        get_visitation: {
          ...state.get_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITATION_ERROR:
      return {
        ...state,
        get_visitation: {
          ...state.get_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case SET_VISITATION_PENDING:
      return {
        ...state,
        set_visitation: {
          ...state.set_visitation,
          pending: true,
          successMessage: false,
        },
      }
    case SET_VISITATION_SUCCESS:
      return {
        ...state,
        set_visitation: {
          ...state.set_visitation,
          success: action.payload,
          pending: false,
          successMessage: true,
        },
      }
    case SET_VISITATION_ERROR:
      return {
        ...state,
        set_visitation: {
          ...state.set_visitation,
          error: action.payload,
          pending: false,
          successMessage: false,
        },
      }
    case SET_VISITATION_CLEAR_ERROR:
      return {
        ...state,
        set_visitation: {
          ...state.set_visitation,
          error: null,
          pending: false,
          successMessage: false,
        },
      }
    case CANCEL_VISITATION_PENDING:
      return {
        ...state,
        cancel_visitation: {
          ...state.cancel_visitation,
          pending: true,
        },
      }
    case CANCEL_VISITATION_SUCCESS:
      return {
        ...state,
        cancel_visitation: {
          ...state.cancel_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case CANCEL_VISITATION_ERROR:
      return {
        ...state,
        cancel_visitation: {
          ...state.cancel_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITATION_PENDING:
      return {
        ...state,
        delete_visitation: {
          ...state.delete_visitation,
          pending: true,
        },
      }
    case DELETE_VISITATION_SUCCESS:
      return {
        ...state,
        delete_visitation: {
          ...state.delete_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_VISITATION_ERROR:
      return {
        ...state,
        delete_visitation: {
          ...state.delete_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITATION_PENDING:
      return {
        ...state,
        create_visitation: {
          ...state.create_visitation,
          pending: true,
        },
      }
    case CREATE_VISITATION_SUCCESS:
      return {
        ...state,
        create_visitation: {
          ...state.create_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITATION_ERROR:
      return {
        ...state,
        create_visitation: {
          ...state.create_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case ADD_VISITOR_TO_VISITATION_PENDING:
      return {
        ...state,
        add_visitor_to_visitation: {
          ...state.add_visitor_to_visitation,
          pending: true,
        },
      }
    case ADD_VISITOR_TO_VISITATION_SUCCESS:
      return {
        ...state,
        add_visitor_to_visitation: {
          ...state.add_visitor_to_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case ADD_VISITOR_TO_VISITATION_ERROR:
      return {
        ...state,
        add_visitor_to_visitation: {
          ...state.add_visitor_to_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case ADD_EMPLOYEE_TO_VISITATION_PENDING:
      return {
        ...state,
        add_employee_to_visitation: {
          ...state.add_employee_to_visitation,
          pending: true,
        },
      }
    case ADD_EMPLOYEE_TO_VISITATION_SUCCESS:
      return {
        ...state,
        add_employee_to_visitation: {
          ...state.add_employee_to_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case ADD_EMPLOYEE_TO_VISITATION_ERROR:
      return {
        ...state,
        add_employee_to_visitation: {
          ...state.add_employee_to_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case REMOVE_VISITOR_FROM_VISITATION_PENDING:
      return {
        ...state,
        remove_visitor_from_visitation: {
          ...state.remove_visitor_from_visitation,
          pending: true,
        },
      }
    case REMOVE_VISITOR_FROM_VISITATION_SUCCESS:
      return {
        ...state,
        remove_visitor_from_visitation: {
          ...state.remove_visitor_from_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case REMOVE_VISITOR_FROM_VISITATION_ERROR:
      return {
        ...state,
        remove_visitor_from_visitation: {
          ...state.remove_visitor_from_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case REMOVE_EMPLOYEE_FROM_VISITATION_PENDING:
      return {
        ...state,
        remove_employee_from_visitation: {
          ...state.remove_employee_from_visitation,
          pending: true,
        },
      }
    case REMOVE_EMPLOYEE_FROM_VISITATION_SUCCESS:
      return {
        ...state,
        remove_employee_from_visitation: {
          ...state.remove_employee_from_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case REMOVE_EMPLOYEE_FROM_VISITATION_ERROR:
      return {
        ...state,
        remove_employee_from_visitation: {
          ...state.remove_employee_from_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case REMOVE_VISITORS_FROM_VISITATION_PENDING:
      return {
        ...state,
        remove_visitors_from_visitation: {
          ...state.remove_visitors_from_visitation,
          pending: true,
        },
      }
    case REMOVE_VISITORS_FROM_VISITATION_SUCCESS:
      return {
        ...state,
        remove_visitors_from_visitation: {
          ...state.remove_visitors_from_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case REMOVE_VISITORS_FROM_VISITATION_ERROR:
      return {
        ...state,
        remove_visitors_from_visitation: {
          ...state.remove_visitors_from_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case REMOVE_EMPLOYEES_FROM_VISITATION_PENDING:
      return {
        ...state,
        remove_employees_from_visitation: {
          ...state.remove_employees_from_visitation,
          pending: true,
        },
      }
    case REMOVE_EMPLOYEES_FROM_VISITATION_SUCCESS:
      return {
        ...state,
        remove_employees_from_visitation: {
          ...state.remove_employees_from_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case REMOVE_EMPLOYEES_FROM_VISITATION_ERROR:
      return {
        ...state,
        remove_employees_from_visitation: {
          ...state.remove_employees_from_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITATION_ADD_PARTICIPANTS_PENDING:
      return {
        ...state,
        create_visitation_add_participants: {
          ...state.create_visitation_add_participants,
          pending: true,
        },
      }
    case CREATE_VISITATION_ADD_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        create_visitation_add_participants: {
          ...state.create_visitation_add_participants,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_VISITATION_ADD_PARTICIPANTS_ERROR:
      return {
        ...state,
        create_visitation_add_participants: {
          ...state.create_visitation_add_participants,
          error: action.payload,
          pending: false,
        },
      }
    case ADD_EMPLOYEES_TO_VISITATION_PENDING:
      return {
        ...state,
        add_employees_to_visitation: {
          ...state.add_employees_to_visitation,
          pending: true,
        },
      }
    case ADD_EMPLOYEES_TO_VISITATION_SUCCESS:
      return {
        ...state,
        add_employees_to_visitation: {
          ...state.add_employees_to_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case ADD_EMPLOYEES_TO_VISITATION_ERROR:
      return {
        ...state,
        add_employees_to_visitation: {
          ...state.add_employees_to_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case ADD_VISITORS_TO_VISITATION_PENDING:
      return {
        ...state,
        add_visitors_to_visitation: {
          ...state.add_visitors_to_visitation,
          pending: true,
        },
      }
    case ADD_VISITORS_TO_VISITATION_SUCCESS:
      return {
        ...state,
        add_visitors_to_visitation: {
          ...state.add_visitors_to_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case ADD_VISITORS_TO_VISITATION_ERROR:
      return {
        ...state,
        add_visitors_to_visitation: {
          ...state.add_visitors_to_visitation,
          error: action.payload,
          pending: false,
        },
      }
    case REMOVE_PARTICIPANTS_FROM_VISITATION_PENDING:
      return {
        ...state,
        remove_participants_from_visitation: {
          ...state.remove_participants_from_visitation,
          pending: true,
        },
      }
    case REMOVE_PARTICIPANTS_FROM_VISITATION_SUCCESS:
      return {
        ...state,
        remove_participants_from_visitation: {
          ...state.remove_participants_from_visitation,
          success: action.payload,
          pending: false,
        },
      }
    case REMOVE_PARTICIPANTS_FROM_VISITATION_ERROR:
      return {
        ...state,
        remove_participants_from_visitation: {
          ...state.remove_participants_from_visitation,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getVisitiationsPending() {
  return {type: GET_VISITATIONS_PENDING}
}
function getVisitiationsSuccess(payload) {
  return {type: GET_VISITATIONS_SUCCESS, payload: payload}
}
function getVisitiationsError(error) {
  return {type: GET_VISITATIONS_ERROR, payload: error}
}
export function getVisitiationsClearError() {
  return {type: GET_VISITATIONS_CLEAR_ERROR}
}
function getVisitiationPending() {
  return {type: GET_VISITATION_PENDING}
}

function getVisitiationSuccess(payload) {
  return {type: GET_VISITATION_SUCCESS, payload: payload}
}

function getVisitiationError(error) {
  return {type: GET_VISITATION_ERROR, payload: error}
}

function setVisitiationPending() {
  return {type: SET_VISITATION_PENDING}
}

export function setVisitiationSuccess(payload) {
  return {type: SET_VISITATION_SUCCESS, payload: payload}
}

function setVisitiationError(error) {
  return {type: SET_VISITATION_ERROR, payload: error}
}

export function setVisitiationClearError() {
  return {type: SET_VISITATION_CLEAR_ERROR}
}

function cancelVisitiationPending() {
  return {type: CANCEL_VISITATION_PENDING}
}
function cancelVisitiationSuccess(payload) {
  return {type: CANCEL_VISITATION_SUCCESS, payload: payload}
}
function cancelVisitiationError(error) {
  return {type: CANCEL_VISITATION_ERROR, payload: error}
}

function deleteVisitiationPending() {
  return {type: DELETE_VISITATION_PENDING}
}
function deleteVisitiationSuccess(payload) {
  return {type: DELETE_VISITATION_SUCCESS, payload: payload}
}
function deleteVisitiationError(error) {
  return {type: DELETE_VISITATION_ERROR, payload: error}
}

function createVisitiationPending() {
  return {type: CREATE_VISITATION_PENDING}
}
function createVisitiationSuccess(payload) {
  return {type: CREATE_VISITATION_SUCCESS, payload: payload}
}
function createVisitiationError(error) {
  return {type: CREATE_VISITATION_ERROR, payload: error}
}

function addVisitorToVisitationPending() {
  return {type: ADD_VISITOR_TO_VISITATION_PENDING}
}
function addVisitorToVisitationSuccess(payload) {
  return {type: ADD_VISITOR_TO_VISITATION_SUCCESS, payload: payload}
}
function addVisitorToVisitationError(error) {
  return {type: ADD_VISITOR_TO_VISITATION_ERROR, payload: error}
}

function addEmployeeToVisitationPending() {
  return {type: ADD_EMPLOYEE_TO_VISITATION_PENDING}
}
function addEmployeeToVisitationSuccess(payload) {
  return {type: ADD_EMPLOYEE_TO_VISITATION_SUCCESS, payload: payload}
}
function addEmployeeToVisitationError(error) {
  return {type: ADD_EMPLOYEE_TO_VISITATION_ERROR, payload: error}
}

function removeVisitorFromVisitationPending() {
  return {type: REMOVE_VISITOR_FROM_VISITATION_PENDING}
}
function removeVisitorFromVisitationSuccess(payload) {
  return {type: REMOVE_VISITOR_FROM_VISITATION_SUCCESS, payload: payload}
}
function removeVisitorFromVisitationError(error) {
  return {type: REMOVE_VISITOR_FROM_VISITATION_ERROR, payload: error}
}

function removeEmployeeFromVisitationPending() {
  return {type: REMOVE_EMPLOYEE_FROM_VISITATION_PENDING}
}
function removeEmployeeFromVisitationSuccess(payload) {
  return {type: REMOVE_EMPLOYEE_FROM_VISITATION_SUCCESS, payload: payload}
}
function removeEmployeeFromVisitationError(error) {
  return {type: REMOVE_EMPLOYEE_FROM_VISITATION_ERROR, payload: error}
}

function removeVisitorsFromVisitationPending() {
  return {type: REMOVE_VISITORS_FROM_VISITATION_PENDING}
}
function removeVisitorsFromVisitationSuccess(payload) {
  return {type: REMOVE_VISITORS_FROM_VISITATION_SUCCESS, payload: payload}
}
function removeVisitorsFromVisitationError(error) {
  return {type: REMOVE_VISITORS_FROM_VISITATION_ERROR, payload: error}
}

function removeEmployeesFromVisitationPending() {
  return {type: REMOVE_EMPLOYEES_FROM_VISITATION_PENDING}
}
function removeEmployeesFromVisitationSuccess(payload) {
  return {type: REMOVE_EMPLOYEES_FROM_VISITATION_SUCCESS, payload: payload}
}
function removeEmployeesFromVisitationError(error) {
  return {type: REMOVE_EMPLOYEES_FROM_VISITATION_ERROR, payload: error}
}

function createVisitationAddParticipantsPending() {
  return {type: CREATE_VISITATION_ADD_PARTICIPANTS_PENDING}
}
function createVisitationAddParticipantsSuccess(payload) {
  return {type: CREATE_VISITATION_ADD_PARTICIPANTS_SUCCESS, payload: payload}
}
function createVisitationAddParticipantsError(error) {
  return {type: CREATE_VISITATION_ADD_PARTICIPANTS_ERROR, payload: error}
}

function addEmployeesToVisitationPending() {
  return {type: ADD_EMPLOYEES_TO_VISITATION_PENDING}
}
function addEmployeesToVisitationSuccess(payload) {
  return {type: ADD_EMPLOYEES_TO_VISITATION_SUCCESS, payload: payload}
}
function addEmployeesToVisitationError(error) {
  return {type: ADD_EMPLOYEES_TO_VISITATION_ERROR, payload: error}
}

function addVisitorsToVisitationPending() {
  return {type: ADD_VISITORS_TO_VISITATION_PENDING}
}
function addVisitorsToVisitationSuccess(payload) {
  return {type: ADD_VISITORS_TO_VISITATION_SUCCESS, payload: payload}
}
function addVisitorsToVisitationError(error) {
  return {type: ADD_VISITORS_TO_VISITATION_ERROR, payload: error}
}

function removeParticipantsFromVisitationPending() {
  return {type: REMOVE_PARTICIPANTS_FROM_VISITATION_PENDING}
}
function removeParticipantsFromVisitationSuccess(payload) {
  return {type: REMOVE_PARTICIPANTS_FROM_VISITATION_SUCCESS, payload: payload}
}
function removeParticipantsFromVisitationError(error) {
  return {type: REMOVE_PARTICIPANTS_FROM_VISITATION_ERROR, payload: error}
}

// Operations
export const getVisitations =
  (limit = 500, offset = 0) =>
  (dispatch) => {
    dispatch(getVisitiationsPending())
    return axios
      .get(`/api/v1/visitations?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getVisitiationsSuccess(res.data.results))
        return res.data
      })
      .catch((error) => dispatch(getVisitiationsError(error)))
  }

export const getVisitation = (id) => (dispatch) => {
  dispatch(getVisitiationPending())
  return axios
    .get(`/api/v1/visitations/${id}`)
    .then((res) => {
      dispatch(getVisitiationSuccess(res.data))
      return res.data
    })
    .catch((error) => {
      dispatch(getVisitiationError(error))
    })
}

export const setVisitation = (id, values) => (dispatch) => {
  dispatch(setVisitiationPending())
  return axios
    .put(`/api/v1/visitations/${id}`, values)
    .then((res) => {
      dispatch(setVisitiationSuccess('notificationSuccess.VisitationUpdated'))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.VisitationUpdated'))
      return res.data
    })
    .catch((error) => dispatch(setVisitiationError(error)))
}

export const cancelVisitation = (id) => (dispatch) => {
  dispatch(cancelVisitiationPending())
  return axios
    .put(`/api/v1/visitations/${id}`, {status: 'Cancelled'})
    .then((res) => {
      dispatch(cancelVisitiationSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(cancelVisitiationError(error)))
}

export const deleteVisitation = (id, userID) => (dispatch) => {
  dispatch(deleteVisitiationPending())
  return axios
    .delete(`/api/v1/visitations/${id}`)
    .then((res) => {
      dispatch(deleteVisitiationSuccess(res.data))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.VisitationDeleted', {name: res.data}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(deleteVisitiationError(error)))
}

export const createVisitation = (values) => (dispatch) => {
  dispatch(createVisitiationPending())
  return axios
    .post(`/api/v1/visitations`, values)
    .then((res) => {
      dispatch(createVisitiationSuccess(res))
      return res
    })
    .catch((error) => dispatch(createVisitiationError(error)))
}

export const addVisitorToVisitation = (id, values) => (dispatch) => {
  dispatch(addVisitorToVisitationPending())
  return axios
    .post(`/api/v1/visitations/${id}/visitors`, values)
    .then((res) => {
      dispatch(addVisitorToVisitationSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(addVisitorToVisitationError(error)))
}

export const addEmployeeToVisitation = (id, values) => (dispatch) => {
  dispatch(addEmployeeToVisitationPending())
  return axios
    .post(`/api/v1/visitations/${id}/employees`, values)
    .then((res) => {
      dispatch(addEmployeeToVisitationSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(addEmployeeToVisitationError(error)))
}

export const removeVisitorFromVisitation = (visitationId, employeeId) => (dispatch) => {
  dispatch(removeVisitorFromVisitationPending())
  return axios
    .delete(`/api/v1/visitations/${visitationId}/visitors/${employeeId}`)
    .then((res) => {
      dispatch(removeVisitorFromVisitationSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(removeVisitorFromVisitationError(error)))
}

export const removeEmployeeFromVisitation = (visitationId, visitorId) => (dispatch) => {
  dispatch(removeEmployeeFromVisitationPending())
  return axios
    .delete(`/api/v1/visitations/${visitationId}/employees/${visitorId}`)
    .then((res) => {
      dispatch(removeEmployeeFromVisitationSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(removeEmployeeFromVisitationError(error)))
}

export const addEmployeesToVisitation = (visitationId, employees) => (dispatch) => {
  dispatch(addEmployeesToVisitationPending())
  const promises = flatten(
    employees.map((employee) => [
      dispatch(addEmployeeToVisitation(visitationId, {employee_id: employee.employee_id})).catch(
        (error) => {
          dispatch(addEmployeesToVisitationError(error))
        },
      ),
    ]),
  )

  return Promise.all(promises)
    .then(() => {
      dispatch(addEmployeesToVisitationSuccess())
    })
    .catch((error) => {
      dispatch(addEmployeesToVisitationError(error))
    })
}

export const addVisitorsToVisitation = (visitationId, visitors) => (dispatch) => {
  dispatch(addVisitorsToVisitationPending())
  const promises = flatten(
    visitors.map((visitor) => [
      dispatch(addVisitorToVisitation(visitationId, {visitor_id: visitor.visitor_id})).catch(
        (error) => {
          dispatch(addVisitorsToVisitationError(error))
        },
      ),
    ]),
  )

  return Promise.all(promises)
    .then(() => {
      dispatch(addVisitorsToVisitationSuccess())
    })
    .catch((error) => {
      dispatch(addVisitorsToVisitationError(error))
    })
}

export const removeEmployeesFromVisitation = (visitationId, employees) => (dispatch) => {
  dispatch(removeEmployeesFromVisitationPending())
  const promises = flatten(
    employees.map((employee) => [
      dispatch(removeEmployeeFromVisitation(visitationId, employee.employee_id)).catch((error) => {
        dispatch(removeEmployeesFromVisitationError(error))
      }),
    ]),
  )

  return Promise.all(promises)
    .then((res) => {
      dispatch(removeEmployeesFromVisitationSuccess())
      return res
    })
    .catch((error) => {
      dispatch(removeEmployeesFromVisitationError(error))
    })
}

export const removeVisitorsFromVisitation = (visitationId, visitors) => (dispatch) => {
  dispatch(removeVisitorsFromVisitationPending())
  const promises = flatten(
    visitors.map((visitor) => [
      dispatch(removeVisitorFromVisitation(visitationId, visitor.visitor_id)).catch((error) => {
        dispatch(removeVisitorsFromVisitationError(error))
      }),
    ]),
  )

  return Promise.all(promises)
    .then((res) => {
      dispatch(removeVisitorsFromVisitationSuccess())
      return res
    })
    .catch((error) => {
      dispatch(removeVisitorsFromVisitationError(error))
    })
}

export const createVisitationAddParticipants =
  (visitationData, employees, visitors, userID) => (dispatch) => {
    dispatch(createVisitationAddParticipantsPending())
    dispatch(createVisitation(visitationData)) // 1st function
      .then((res) => {
        dispatch(addEmployeesToVisitation(res.data.id, employees)) // 2nd function
          .then(() => {
            dispatch(addVisitorsToVisitation(res.data.id, visitors)) // 3rd function
              .then(() => {
                dispatch(createVisitationAddParticipantsSuccess(res))
                dispatch(
                  createNotification(userID, {
                    code: '201',
                    message: I18n.t('notificationSuccess.VisitationCreated', {name: res.data.name}),
                    link: `/visitations/${res.data.id}`,
                  }),
                )
              })
              .catch((error) => {
                dispatch(createVisitationAddParticipantsError(error))
              })
          })
          .catch((error) => {
            dispatch(createVisitationAddParticipantsError(error))
          })
      })
      .catch((error) => {
        dispatch(createVisitationAddParticipantsError(error))
      })
  }

export const removeParticipantsFromVisitation =
  (visitationId, employees, visitors) => (dispatch) => {
    dispatch(removeParticipantsFromVisitationPending())
    return dispatch(removeEmployeesFromVisitation(visitationId, employees))
      .then(() => {
        dispatch(removeVisitorsFromVisitation(visitationId, visitors))
          .then(() => {
            return dispatch(removeParticipantsFromVisitationSuccess())
          })
          .catch((error) => {
            dispatch(removeParticipantsFromVisitationError(error))
          })
      })
      .catch((error) => {
        dispatch(removeParticipantsFromVisitationError(error))
      })
  }

export const removeOldAddNewParticipants =
  (visitationId, oldEmployees, oldVisitors, newEmployees, newVisitors) => (dispatch) => {
    /* dispatch(removeOldAddNewParticipantsPending()); */
    dispatch(removeParticipantsFromVisitation(visitationId, oldEmployees, oldVisitors))
      .then(() => {
        dispatch(addEmployeesToVisitation(visitationId, newEmployees))
          .then(() => {
            dispatch(addVisitorsToVisitation(visitationId, newVisitors))
            // .then((res) => {
            //   history.push('/visitations')
            //   /* dispatch(removeOldAddNewParticipantsSuccess()); */
            // })
            // .catch((err) => {
            //   /* dispatch(removeOldAddNewParticipantsError()); */
            // })
          })
          .catch(() => {
            /* dispatch(removeOldAddNewParticipantsError()); */
          })
      })
      .catch(() => {
        /* dispatch(removeOldAddNewParticipantsError()); */
      })
  }
