import {useTranslation} from 'react-i18next'
import InfoMessage from './InfoMessage'

export const getErrorText = (t, i18n, error) => {
  let defaultKey = 'errorBoundary.Text'
  if (!error?.response) {
    return t(defaultKey)
  }
  const {
    response: {status, data},
  } = error
  switch (true) {
    case status === 403:
      return t('notificationError.NotAuthorized')
    case status === 404 && !data?.error_key:
      return t('notificationError.ApiResourceNotFound')
    case status === 500:
      return t('notificationError.InternalServerError')
    case !!data?.error_custom:
      return data?.error_custom
    case !!data?.error_type:
      return data?.error_key.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')
    case !!data.error_field:
      return i18n.exists(`notificationError.${data?.error_key}`)
        ? t(`notificationError.${data?.error_key}`, {
            name: data?.error_field,
          })
        : t(defaultKey)
    default:
      return i18n.exists(`notificationError.${data?.error_key}`)
        ? t(`notificationError.${data?.error_key}`)
        : t(defaultKey)
  }
}

export default function ErrorOnView({error, setError}) {
  const {t, i18n} = useTranslation()

  return (
    <InfoMessage
      message={getErrorText(t, i18n, error)}
      type="error"
      infoText={!!error?.response?.data?.error_type && error?.response?.data?.error_message}
      onClose={() => setError()}
    />
  )
}
