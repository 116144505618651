import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'executions.DescriptiveStatus',
    accessor: 'descriptive_status',
    label: 'executions.DescriptiveStatus',
  },
  {
    header: 'executions.ExecutionSummary',
    accessor: 'execution_summary',
    label: 'executions.ExecutionSummary',
  },
  {
    header: 'ID',
    accessor: 'id',
    label: 'ID',
  },
  {
    header: 'TableLabelsCommon.Name',
    accessor: 'requester_name',
    label: 'TableLabelsCommon.Name',
  },
  {
    header: 'executions.RequesterId',
    accessor: 'requster_id',
    label: 'executions.RequesterId',
  },
  {
    header: 'executions.Status',
    label: 'executions.Status',
    accessor: 'status_name',
    Filter: 'SelectColumnFilter',
    cell: 'StatusPill',
    filter: 'includes', // check this is not on the header! .
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'executions.WorkflowId',
    accessor: 'workflow.id',
    label: 'executions.WorkflowId',
  },
  {
    header: 'executions.WorkflowName',
    accessor: 'workflow.name',
    label: 'executions.WorkflowName',
  },
  {
    header: 'executions.Actions',
    label: 'executions.Actions',
    accessor: 'action',
    cell: 'TodosActionsCell',
    noSort: true,
  },
]
export default columnConfig
