export const IdentitiesIcon = (props) =>(
    <svg version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" className={props.className}  x="0px" y="0px"
	 viewBox="0 0 900 900" >

<path style={{fill:props.fill}} d="M798.6,296.2l59.6-26.4L718.5,168l-18.4,171.9l55.6-24.6C774.2,357.1,784,402.8,784,450c0,56-13.7,109.9-39.5,157.9
	c-13.1-27.4-34.2-47.8-65.5-59.6c6.1-5.2,12.4-9.7,17.8-15.2c11.9-11.9,17.7-26.7,19.8-43.3c2.2-18-2.6-34-12.8-48.6
	c-11.7-16.8-28-26.3-48-29.4c-27.6-4.3-50.2,6.1-68.5,25.8c-13.7,14.7-19,33-17.8,53.1c1.7,12.4,4.9,15.3,12,21
	c16.3,13,30.2,28.4,40.1,46.9c6.8,12.8,13.2,25.9,18.2,39.5c4.4,12,6.7,24.7,9.6,37.2c0.2,1,0.5,1.8,0.9,2.4c-4.4,0-9,0-13.6-0.1
	c0.4-0.8,0.4-1.8,0-3c-4.9-15.5-8.9-31.5-15.3-46.4c-10.3-24.3-26.1-45.1-46-62.7c-11.7-10.4-24.1-19.6-38.1-26.6
	c-8.6-4.3-17.5-8.3-26.8-12.6c1.3-0.9,2.2-1.8,3.3-2.3c13.7-7.2,25.2-17,34.7-29.2c21.6-27.8,27.2-59.7,21.9-93.4
	c-4.5-28.4-18.2-52.4-40.4-71.3c-23.4-19.9-50.7-28.7-80.8-29.1c-10.1-0.1-20.5,1.7-30.3,4.3c-24.8,6.8-46.2,19.5-62.5,39.9
	c-22.5,28.3-32,60.2-25.7,95.9c6,33.7,24.2,60,51.9,79.8c2.3,1.7,4.8,3.2,8.3,5.5c-69.6,27.7-111,77.2-126.8,149.1
	c-4.5,0-9,0-13.3-0.1c0-0.1,0-0.1,0-0.2c2.9-12.5,5.2-25.3,9.6-37.2c5-13.6,11.4-26.7,18.2-39.5c9.8-18.5,23.8-33.9,40.1-46.9
	c7.1-5.7,10.3-8.6,12-21c1.2-20.1-4.1-38.4-17.8-53.1c-18.4-19.7-41-30.1-68.5-25.8c-20,3.1-36.3,12.7-48,29.4
	c-10.2,14.6-15,30.6-12.8,48.6c2,16.6,7.9,31.4,19.8,43.3c5.4,5.4,11.7,10,17.8,15.2c-31.3,11.7-52.4,32.2-65.5,59.6
	C129.7,559.9,116,506,116,450c0-89.2,34.7-173.1,97.8-236.2c63.1-63.1,147-97.8,236.2-97.8c89.2,0,173.1,34.7,236.2,97.8
	c1.1,1.1,2.1,2.1,3.1,3.2l6.2-58.4c-29.2-24.7-61.8-44.8-97.2-59.7C551.3,79.1,501.4,69,450,69S348.7,79.1,301.7,99
	c-45.4,19.2-86.1,46.7-121.1,81.6S118.2,256.3,99,301.7C79.1,348.7,69,398.6,69,450S79.1,551.3,99,598.3
	c19.2,45.4,46.7,86.1,81.6,121.1c35,35,75.7,62.4,121.1,81.6c47,19.9,96.9,29.9,148.3,29.9s101.3-10.1,148.3-29.9
	c45.4-19.2,86.1-46.7,121.1-81.6c35-35,62.4-75.7,81.6-121.1c19.9-47,29.9-96.9,29.9-148.3S820.9,348.7,801,301.7
	C800.3,299.9,799.4,298,798.6,296.2z M525.4,687.6h-51v52.1h-47.6v-52.1h-52.1v-45.9h52.1v-53h47.6v53h51V687.6z"/>
</svg>
)