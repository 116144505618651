import {Disclosure} from '@headlessui/react'
import {ChevronRightIcon} from '@heroicons/react/solid'

export default function Collapse(props) {
  return (
    <div className="w-full pt-4">
      <div className="mx-auto w-full rounded-2xl bg-white">
        <Disclosure>
          {({open}) => (
            <>
              <Disclosure.Button
                className="flex w-full justify-between rounded-lg bg-gray-600 px-4 py-2 text-left text-base font-medium text-slate-50 hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
                onClick={props.onToggle}
              >
                <div className="flex">
                  <span>{props.title}</span>
                  <ChevronRightIcon
                    className={`${open ? 'rotate-90 transform' : ''} h-7 w-7 text-slate-50`}
                  />
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2">{props.children}</Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}
