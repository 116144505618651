import Notification from '../Notification'
import {useTranslation} from 'react-i18next'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {useDispatch, useSelector} from 'react-redux'
import {InputComponent} from '../formRendererRHF/types'
import {setServerPreferences, setServerPreferencesClearError} from '../../stores/static_assets'

const FormEditServerPreferences = () => {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const {error, success} = useSelector((state) => state.static_assets.set_server_preferences)

  const handleSubmit = (values) => {
    const preferences = {}
    // bcs: api shouldn't overwrite null values with empty strings
    if (values.support) {
      preferences.support = values.support
    }
    if (values.contact) {
      preferences.contact = values.contact
    }
    // bcs: checkbox of rhf sends "true" instead of true
    preferences.enable_cookie = values.enable_cookie === 'true'
    dispatch(setServerPreferences(preferences))
  }

  const fields = [
    {
      component: InputComponent.STATIC_TEXT,
      name: 'support-current',
      label: t('supportLinkCurrent') + (success?.support || ''),
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: t('supportLink'),
      type: 'text',
      name: 'support',
      placeholder: 'https://abc.company/support',
    },
    {
      name: 'contact-current',
      component: InputComponent.STATIC_TEXT,
      label: t('contactLinkCurrent') + (success?.contact || ''),
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: t('contactLink'),
      type: 'text',
      name: 'contact',
      placeholder: 'https://abc.company/contact',
    },
    {
      component: InputComponent.STATIC_TEXT,
      name: 'cookie',
      label: t('enableCookieBannerCurrent') + (success?.enable_cookie ? 'true' : 'false'),
    },
    {
      component: InputComponent.CHECKBOX,
      name: 'enable_cookie',
      typeValue: 'boolean',
      label: t('enableCookieBanner'),
    },
  ]

  return (
    <DynamicRhfForm
      formConfig={{
        fields,
        name: 'form-edit-server-preferences',
        submitButtonText: t('submit'),
      }}
      onSubmit={handleSubmit}
      noFormBorder
      infoMessage={
        <Notification error={error} setError={() => dispatch(setServerPreferencesClearError())} />
      }
    />
  )
}

export default FormEditServerPreferences
