import Table from '../table'
import {useTranslation} from 'react-i18next'
import FormAssignFile from '../forms/FormAssignFile'
import {ModalOpenButton, Modal, ModalContentsBase} from '../Modal'
import {TrashIcon, ExclamationIcon} from '@heroicons/react/outline'
import {Button} from '../Buttons'
import {useDispatch, useSelector} from 'react-redux'
import EditTableWrapper from './EditTableWrapper'

export function DeleteFileCell({value, deleteFunc, entityId, unassignFileToIdentityPending}) {
  const {t} = useTranslation()
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const dispatch = useDispatch()
  return (
    <Modal>
      <ModalOpenButton>
        <div className="h-5 w-5">
          <TrashIcon className="text-red-400 hover:text-red-500" />
        </div>
      </ModalOpenButton>
      <ModalContentsBase title={t('confirmDeletion.ConfirmDeletion')}>
        <>
          <div className="mx-auto flex items-center justify-center">
            <ExclamationIcon className="h-8 w-8 items-center text-red-600" aria-hidden="true" />
            <div className="mt-2">
              <p className="text-sm text-gray-500">{t('confirmDeletion.DeleteFile')}</p>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button
              type="button"
              text={t('confirmDeletion.Confirm')}
              onClick={() => dispatch(deleteFunc(entityId, value, true, userID))}
              pending={unassignFileToIdentityPending}
            />
          </div>
        </>
      </ModalContentsBase>
    </Modal>
  )
}

export default function FilesWrapper(props) {
  const columns = props.columns

  return (
    <>
      <FormAssignFile
        addFile={props.addFile}
        entityId={props.entityId}
        assignFileToIdentityPending={props.assignFileToIdentityPending}
        assignFileToIdentityError={props.assignFileToIdentityError}
        IdentityCreateFileClearError={props.IdentityCreateFileClearError}
      />
      <>
        <EditTableWrapper tableId={props.tableId} />
        <Table
          columns={columns}
          data={props.data}
          pending={props.pending}
          error={props.error}
          setError={props.setError}
        />
      </>
    </>
  )
}
