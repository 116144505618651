import {useEffect, useRef} from 'react'
import {setUser, setUserClearError} from '../../stores/users'
import {getRoles, getRolesClearError} from '../../stores/roles'
import {Formik, Form} from 'formik'
import {TextInput, SelectInput, Checkbox} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'

export default function FormEditUser({data}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const roles = useSelector((state) => state.roles.get_roles.success)
  const rolesError = useSelector((state) => state.roles.get_roles.error)
  const pending = useSelector((state) => state.users.set_user.pending)
  const error = useSelector((state) => state.users.set_user.error)

  const initialValues = {
    name: data?.name || '',
    active: data?.active || '',
    email: data?.email || '',
    roles: data?.roles,
  }

  useEffect(() => {
    dispatch(getRoles())
  }, [dispatch, rolesError?.message])

  let rolesSelectRef = useRef()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={(values) => {
          dispatch(setUser(data?.id, values))
        }}
      >
        <Form>
          <Checkbox name="active" label={t('userInfo.Status')} id="form-edit-user-checkbox" />
          <TextInput label={t('userInfo.Name')} name="name" type="text" />
          <TextInput label={t('userInfo.Email')} name="email" type="text" />
          <SelectInput
            label={t('userInfo.Roles')}
            isMulti
            options={roles.map((role) => ({
              value: role.name,
              label: role.name,
            }))}
            name="roles"
            required
            innerRef={rolesSelectRef}
            error={rolesError}
            setError={getRolesClearError}
          />
          <FormikSaveButton
            type="submit"
            testid="form-edit-user-submit"
            text={t('userManagement.Add')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(setUserClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
