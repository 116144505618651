import {Formik, Form} from 'formik'
import {TextInputLabelOnLeft} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {FormikSaveButton} from '../Buttons'
import {useDispatch} from 'react-redux'

export default function FormAddIdentityProviderOidcValidationRule({
  data,
  submit,
  error,
  setError,
  pending,
}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const initialValues = {
    label: '',
    value: '',
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          let validation_rule = {}
          validation_rule[values.label] = values.value
          dispatch(
            submit(data.id, {
              validation_rules: [validation_rule],
            }),
          )
          actions.resetForm()
        }}
      >
        <Form>
          <TextInputLabelOnLeft
            label={t('addDynamicField.Label')}
            type="text"
            name="label"
            placeholder={t('addDynamicField.LabelPlaceholder')}
          />
          <TextInputLabelOnLeft
            label={t('addDynamicField.Value')}
            placeholder={t('addDynamicField.ValuePlaceholder')}
            type="text"
            name="value"
          />
          <FormikSaveButton
            type="submit"
            testid="form-add-identity-provider-oidc-validation-rule-submit"
            text={t('userManagement.Add')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(setError())}
          />
        </Form>
      </Formik>
    </>
  )
}
