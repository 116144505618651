import {Formik, Form} from 'formik'
import {SelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import {DynamicButton} from '../Buttons'
import {useRef} from 'react'
import {timezones} from '../../utils/timezones'
import {useState} from 'react'
import axios from '../../services/api'
import {fetchMe} from '../../stores/auth'

export default function FormAddPreferredTimezone() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const initialValues = {
    preferred_timezone: '',
  }

  const validationSchema = Yup.object({
    preferred_timezone: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('userInfo.PreferredTimezone')}),
    ),
  })

  const [pending, setPending] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = async (values, actions) => {
    setError(false)
    setPending(true)
    let caughtError = null
    try {
      await axios.post(`api/v1/auth/me/preferences`, values)
    } catch (error) {
      caughtError = error
      setError(error)
    }
    setPending(false)
    actions.resetForm()
    if (!caughtError) {
      dispatch(fetchMe())
    }
  }

  let timezoneSelectRef = useRef()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      <Form>
        <SelectInput
          label={t('userManagement.Timezones')}
          placeholder={t('userManagement.SelectTimezone')}
          options={timezones}
          innerRef={timezoneSelectRef}
          name="preferred_timezone"
        />
        <DynamicButton
          type="submit"
          text={t('userManagement.Submit')}
          primary
          pending={pending}
          error={error}
          setError={setError}
        />
      </Form>
    </Formik>
  )
}
