import {useEffect} from 'react'
import Collapse from '../components/Collapse'
import {useSelector, useDispatch} from 'react-redux'
import {
  getCard,
  getCardMeta,
  createCardCustomAttribute,
  createCardCustomAttributeClearError,
  getCardClearError,
} from '../stores/cards'
import FormEditCard from '../components/forms/FormEditCard'
import FormAddCustomField from '../components/forms/FormAddCustomField'
import Tabs from '../components/Tabs'
import CardHeader from '../components/CardHeader'
import ErrorModal from '../components/ErrorModal'
import {useParams, useNavigate} from 'react-router-dom'
import {CARD} from '../utils/formViews'
import {useTranslation} from 'react-i18next'
import WorkflowForms from '../components/WorkflowForms'

export default function CardScreen() {
  let {id} = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const card = useSelector((state) => state.cards.get_card.success)
  const card_error = useSelector((state) => state.cards.get_card.error)

  // card meta states
  const cardMeta = useSelector((state) => state.cards.get_card_meta.success)

  const update_cardInfo = useSelector((state) => state.cards.update_cardInfo.success)

  const unassign_card = useSelector((state) => state.cards.unassign_card.success)
  const assign_card = useSelector((state) => state.cards.assign_card.success)
  const create_card_custom_attribute = useSelector(
    (state) => state.cards.create_card_custom_attribute.success,
  )
  const create_card_custom_attribute_pending = useSelector(
    (state) => state.cards.create_card_custom_attribute.pending,
  )
  const create_card_custom_attribute_error = useSelector(
    (state) => state.cards.create_card_custom_attribute.error,
  )
  const apply_action_card = useSelector((state) => state.cards.apply_action_card.success)

  useEffect(() => {
    if (card_error?.response?.status === 404) {
      navigate('/not_found/', {replace: true})
    }
  }, [card_error?.response?.status, navigate])

  useEffect(() => {
    dispatch(getCard(`/api/v1/cards/${id}`))
  }, [
    dispatch,
    id,
    update_cardInfo,
    create_card_custom_attribute,
    unassign_card,
    assign_card,
    apply_action_card,
  ])

  useEffect(() => {
    if (permissions.listCards) {
      // if user has no permission we don't need to call getCardMeta
      dispatch(getCardMeta(id))
    }
  }, [dispatch, id, permissions.listCards])

  const tabsArray = permissions.createCard // TODO: this should be create cardCustomAttribute permission
    ? [
        {
          name: 'Card Details',
          component: (
            <FormEditCard
              data={card}
              updateCardPermission={permissions.updateCard}
              cardUpdateCustomAttribePermission={permissions.createCard} // TODO: this should be update /delete cardCustomAttribute permission
            />
          ),
        },
        {
          name: 'Custom Fields',
          component: (
            <FormAddCustomField
              data={card}
              submit={createCardCustomAttribute}
              error={create_card_custom_attribute_error}
              setError={createCardCustomAttributeClearError}
              pending={create_card_custom_attribute_pending}
            />
          ),
        },
      ]
    : [
        {
          name: 'Card Details',
          component: (
            <FormEditCard
              data={card}
              updateCardPermission={permissions.updateCard}
              cardUpdateCustomAttribePermission={permissions.createCard}
            />
          ),
        },
      ]
  return (
    <>
      {card_error ? (
        <ErrorModal error={card_error} setError={() => dispatch(getCardClearError())} />
      ) : null}
      <CardHeader card={card} cardListPermission={permissions.listCards} cardMeta={cardMeta} />
      <WorkflowForms renderOnUi={CARD} isOriginUrl />
      <Collapse title={t('cardInfo.CardInfo')}>
        <Tabs tabs={tabsArray} />
      </Collapse>
    </>
  )
}
