import {Formik, Form} from 'formik'
import {SelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {putWorkflow, putWorkflowClearError} from '../../stores/workflows'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {useRef} from 'react'

export default function FormEditWebhook({id}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const pending = useSelector((state) => state.workflows.put_workflow.pending)
  const error = useSelector((state) => state.workflows.put_workflow.error)

  const initialValues = {
    render_on_ui: '',
  }

  const validationSchema = Yup.object({
    // ADD validations
  })

  const handleSubmit = (values, actions) => {
    dispatch(putWorkflow(id, values))
    actions.resetForm()
  }

  let formSelectRef = useRef()

  const options = [
    {value: 'employee', label: 'Employee'},
    {value: 'employeeTable', label: 'Employee Table'},
    {value: 'visitor', label: 'Visitor'},
    {value: 'visitorTable', label: 'Visitor Table'},
    {value: 'cardTable', label: 'Card Table'},
    {value: 'card', label: 'Card'},
    {value: 'inventoryTable', label: 'Object Table'},
    {value: 'inventory', label: 'Object'},
    {value: 'resourceTable', label: 'Resource Table'},
  ]

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        <Form>
          <SelectInput
            label="Render On Ui"
            placeholder={t('workflows.RenderAt')}
            name="render_on_ui"
            options={options}
            innerRef={formSelectRef}
            required
          />
          <FormikSaveButton
            type="submit"
            test-id="form-edit-render-onui-submit"
            text={t('workflows.Submit')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(putWorkflowClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
