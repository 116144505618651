/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, useEffect} from 'react'
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid'
import {PageButton} from './Buttons'
import {Transition} from '@headlessui/react'
import {classNames} from '../utils/classNames'

function SliderIndicators(props) {
  return (
    <ol className="mx-8 flex items-center space-x-5">
      {props.steps.map((step, i) => {
        let selected = props.currentIndex === i
        return (
          <li key={`step_${i}`} onClick={() => props.clickIndicator(i)}>
            <a
              href="#"
              className={classNames(
                selected ? ' bg-lime-600 hover:bg-lime-900' : 'bg-gray-200 hover:bg-gray-400',
                'block h-2.5 w-2.5 rounded-full ',
              )}
            >
              <span className="sr-only"></span>
            </a>
          </li>
        )
      })}
    </ol>
  )
}

export default function Slider({slides}) {
  const [moving, setMoving] = useState('right')

  const [selectedItemIndex, setSelectedItemIndex] = useState(0)

  const prevStep = () => {
    setMoving('left')
    setSelectedItemIndex(selectedItemIndex - 1)
    return false
  }

  const nextStep = async () => {
    setMoving('right')
    setSelectedItemIndex(selectedItemIndex + 1)
    return false
  }

  const clickIndicator = (index) => {
    if (slides) {
      setSelectedItemIndex(index)
    }
    setSelectedItemIndex((prevState) => (prevState = index))
  }

  const wrapper = useRef(null)
  const [wrapperWidth, setWrapperWidth] = useState(0)

  useEffect(() => {
    function handleResize() {
      if (wrapper.current !== null) {
        setWrapperWidth(wrapper.current.offsetWidth)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className=" flex bg-white">
      <div className="justify-top flex flex-1 flex-col py-12 px-4 sm:px-4 ">
        <div className="flex items-start overflow-hidden sm:w-full" ref={wrapper}>
          <div className="flex flex-nowrap ">
            {slides.map((slide, index) => {
              return (
                <Transition
                  appear={false}
                  key={index}
                  unmount={false}
                  show={selectedItemIndex === index}
                  enter="transform transition ease-in-out duration-500"
                  enterFrom={
                    moving === 'right' ? `translate-x-96 opacity-0` : `-translate-x-96 opacity-0`
                  }
                  enterTo={`translate-x-0 opacity-100`}
                  leave="transform transition ease-in-out duration-500 "
                  leaveFrom={`translate-x-0 opacity-100`}
                  leaveTo={
                    moving === 'right'
                      ? `-translate-x-full opacity-0`
                      : `translate-x-full opacity-0`
                  }
                  className="w-0 overflow-visible bg-green-200"
                  as="div"
                >
                  <div className="rounded-xl bg-gray-100" style={{width: `${wrapperWidth}px`}}>
                    <div>{slide}</div>
                  </div>
                </Transition>
              )
            })}
          </div>
        </div>
        <div className="mt-2">
          <p className="mb-1 mt-3 text-center text-sm font-medium">
            Card {selectedItemIndex + 1} of {slides.length}
          </p>
          <nav className="flex items-center justify-center" aria-label="Progress">
            <PageButton
              className="rounded-md"
              type="button"
              disabled={selectedItemIndex === 0}
              onClick={() => prevStep()}
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </PageButton>
            <SliderIndicators
              steps={slides}
              clickIndicator={(index) => clickIndicator(index)}
              currentIndex={selectedItemIndex}
            />
            <PageButton
              className="rounded-md"
              disabled={selectedItemIndex === slides.length - 1}
              onClick={() => nextStep()}
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </PageButton>
          </nav>
        </div>
      </div>
    </div>
  )
}
