import axios from '../services/api'
import {createNotificationOnClient} from './notifications'

// Types
export const REGISTRATION_CONFIRMATION_CODE_PENDING =
  'idfunctiondb/confirmations/REGISTRATION_CONFIRMATION_CODE_PENDING'
export const REGISTRATION_CONFIRMATION_CODE_SUCCESS =
  'idfunctiondb/confirmations/REGISTRATION_CONFIRMATION_CODE_SUCCESS'
export const REGISTRATION_CONFIRMATION_CODE_ERROR =
  'idfunctiondb/confirmations/REGISTRATION_CONFIRMATION_CODE_ERROR'
export const REGISTRATION_CONFIRMATION_CODE_CLEAR_ERROR =
  'idfunctiondb/confirmations/REGISTRATION_CONFIRMATION_CODE_CLEAR_ERROR'

export const CONFIRM_FORGOT_PASSWORD_PENDING =
  'idfunctiondb/confirmations/CONFIRM_FORGOT_PASSWORD_PENDING'
export const CONFIRM_FORGOT_PASSWORD_SUCCESS =
  'idfunctiondb/confirmations/CONFIRM_FORGOT_PASSWORD_SUCCESS'
export const CONFIRM_FORGOT_PASSWORD_ERROR =
  'idfunctiondb/confirmations/CONFIRM_FORGOT_PASSWORD_ERROR'
export const CONFIRM_FORGOT_PASSWORD_CLEAR_ERROR =
  'idfunctiondb/confirmations/CONFIRM_FORGOT_PASSWORD_CLEAR_ERROR'

export const RESEND_REGISTRATION_CONFIRMATION_CODE_PENDING =
  'idfunctiondb/confirmations/RESEND_REGISTRATION_CONFIRMATION_CODE_PENDING'
export const RESEND_REGISTRATION_CONFIRMATION_CODE_SUCCESS =
  'idfunctiondb/confirmations/RESEND_REGISTRATION_CONFIRMATION_CODE_SUCCESS'
export const RESEND_REGISTRATION_CONFIRMATION_CODE_ERROR =
  'idfunctiondb/confirmations/RESEND_REGISTRATION_CONFIRMATION_CODE_ERROR'
export const RESEND_REGISTRATION_CONFIRMATION_CODE_CLEAR_ERROR =
  'idfunctiondb/confirmations/RESEND_REGISTRATION_CONFIRMATION_CODE_CLEAR_ERROR'

// Initial
const initialState = {
  registration_confirmation_code: {
    success: null,
    pending: false,
    error: null,
  },

  confirm_forgot_password: {
    success: null,
    pending: false,
    error: null,
  },

  resend_registration_confirmation_code: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REGISTRATION_CONFIRMATION_CODE_PENDING:
      return {
        ...state,
        registration_confirmation_code: {
          ...state.registration_confirmation_code,
          pending: true,
        },
      }
    case REGISTRATION_CONFIRMATION_CODE_SUCCESS:
      return {
        ...state,
        registration_confirmation_code: {
          ...state.registration_confirmation_code,
          success: action.payload,
          pending: false,
        },
      }
    case REGISTRATION_CONFIRMATION_CODE_ERROR:
      return {
        ...state,
        registration_confirmation_code: {
          ...state.registration_confirmation_code,
          error: action.payload,
          pending: false,
        },
      }
    case REGISTRATION_CONFIRMATION_CODE_CLEAR_ERROR:
      return {
        ...state,
        registration_confirmation_code: {
          ...state.registration_confirmation_code,
          error: null,
          pending: false,
        },
      }
    case RESEND_REGISTRATION_CONFIRMATION_CODE_PENDING:
      return {
        ...state,
        resend_registration_confirmation_code: {
          ...state.resend_registration_confirmation_code,
          pending: true,
        },
      }
    case RESEND_REGISTRATION_CONFIRMATION_CODE_SUCCESS:
      return {
        ...state,
        resend_registration_confirmation_code: {
          ...state.resend_registration_confirmation_code,
          success: action.payload,
          pending: false,
        },
      }
    case RESEND_REGISTRATION_CONFIRMATION_CODE_ERROR:
      return {
        ...state,
        resend_registration_confirmation_code: {
          ...state.resend_registration_confirmation_code,
          error: action.payload,
          pending: false,
        },
      }
    case RESEND_REGISTRATION_CONFIRMATION_CODE_CLEAR_ERROR:
      return {
        ...state,
        resend_registration_confirmation_code: {
          ...state.resend_registration_confirmation_code,
          error: null,
          pending: false,
        },
      }
    case CONFIRM_FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        confirm_forgot_password: {
          ...state.confirm_forgot_password,
          pending: true,
        },
      }
    case CONFIRM_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        confirm_forgot_password: {
          ...state.confirm_forgot_password,
          success: action.payload,
          pending: false,
        },
      }
    case CONFIRM_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        confirm_forgot_password: {
          ...state.confirm_forgot_password,
          error: action.payload,
          pending: false,
        },
      }
    case CONFIRM_FORGOT_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        confirm_forgot_password: {
          ...state.confirm_forgot_password,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function confirmRegistrationCodePending() {
  return {type: REGISTRATION_CONFIRMATION_CODE_PENDING}
}
function confirmRegistrationCodeSuccess(payload) {
  return {type: REGISTRATION_CONFIRMATION_CODE_SUCCESS, payload: payload}
}
function confirmRegistrationCodeError(error) {
  return {type: REGISTRATION_CONFIRMATION_CODE_ERROR, payload: error}
}
export function confirmRegistrationCodeClearError() {
  return {type: REGISTRATION_CONFIRMATION_CODE_CLEAR_ERROR}
}

function confirmForgotPasswordPending() {
  return {type: CONFIRM_FORGOT_PASSWORD_PENDING}
}
function confirmForgotPasswordSuccess(payload) {
  return {type: CONFIRM_FORGOT_PASSWORD_SUCCESS, payload: payload}
}
function confirmForgotPasswordError(error) {
  return {type: CONFIRM_FORGOT_PASSWORD_ERROR, payload: error}
}
export function confirmForgotPasswordClearError() {
  return {type: CONFIRM_FORGOT_PASSWORD_CLEAR_ERROR}
}

function resendRegistrationConfirmationCodePending() {
  return {type: RESEND_REGISTRATION_CONFIRMATION_CODE_PENDING}
}
function resendRegistrationConfirmationCodeSuccess(payload) {
  return {type: RESEND_REGISTRATION_CONFIRMATION_CODE_SUCCESS, payload: payload}
}
function resendRegistrationConfirmationCodeError(error) {
  return {type: RESEND_REGISTRATION_CONFIRMATION_CODE_ERROR, payload: error}
}
export function resendRegistrationConfirmationCodeClearError() {
  return {type: RESEND_REGISTRATION_CONFIRMATION_CODE_CLEAR_ERROR}
}

export const confirmRegistrationCode = (confirmId, code) => (dispatch) => {
  dispatch(confirmRegistrationCodePending())
  axios
    .post('/api/v1/auth/user_confirmation', {confirmation_id: confirmId, code: code})
    .then((res) => {
      dispatch(confirmRegistrationCodeSuccess(res))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.RegistrationComplete'))
    })
    .catch((error) => {
      dispatch(confirmRegistrationCodeError(error))
    })
}

export const confirmForgotPassword = (confirmId, code, newPassword) => (dispatch) => {
  dispatch(confirmForgotPasswordPending())
  axios
    .post('/api/v1/auth/confirmation_forgot_password', {
      confirmation_id: confirmId,
      code: code,
      new_password: newPassword,
    })
    .then((res) => {
      dispatch(confirmForgotPasswordSuccess(res))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.NewPasswordSetted'))
    })
    .catch((error) => {
      dispatch(confirmForgotPasswordError(error))
    })
}

export const resendRegistrationConfirmationCode = (values) => (dispatch) => {
  dispatch(resendRegistrationConfirmationCodePending())
  axios
    .post('/api/v1/auth/resend_user_confirmation_code', values)
    .then((res) => {
      dispatch(resendRegistrationConfirmationCodeSuccess(res))
      dispatch(
        createNotificationOnClient(
          res.status,
          'notificationSuccess.NewRegistrationConfirmationCodeSent',
        ),
      )
    })
    .catch((error) => {
      dispatch(resendRegistrationConfirmationCodeError(error))
    })
}
