import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import FormAddVisitor from '../components/forms/FormAddVisitor'
import FormAddVisitorQuick from '../components/forms/FormAddVisitorQuick'
import {useSelector} from 'react-redux'
import Tabs from '../components/Tabs'
import ServerSideTable from '../components/table/ServerSideTable'
import {VISITOR_TABLE} from '../utils/formViews'
import WorkflowForms from '../components/WorkflowForms'
import {useTableColumns} from '../hooks/useTableColumns'
import {TableId} from '../stores/types/tables'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'
import FormCreateIdentityGroup from '../components/forms/FormCreateIdentityGroup'

export default function VisitorTableScreen() {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const deleteVisitorAction = useSelector((state) => state.visitors.delete_visitor.success)
  const addVisitorAction = useSelector((state) => state.visitors.create_visitor.success)
  const editVisitorAction = useSelector((state) => state.visitors.set_visitor.success)
  const addVisitorQuickAction = useSelector((state) => state.visitors.create_quick_visitor.success)

  const tabsArray = [
    {name: t('addVisitor.Normal'), component: <FormAddVisitorQuick />},
    {name: t('addVisitor.ExistingPerson'), component: <FormAddVisitor />},
  ]

  const columns = useTableColumns(TableId.VISITOR)

  return (
    <>
      {permissions.createVisitor ? (
        <Collapse title={t('addVisitor.addVisitorTitle')}>
          <Tabs tabs={tabsArray} />
        </Collapse>
      ) : null}
      <WorkflowForms renderOnUi={VISITOR_TABLE} />
      {permissions.listVisitors ? (
        <>
          <EditTableWrapper tableId={TableId.VISITOR} />
          <ServerSideTable
            columns={columns}
            entityName={'visitors'}
            deleteAction={deleteVisitorAction}
            editAction={editVisitorAction}
            addAction={addVisitorAction}
            addActionQuick={addVisitorQuickAction}
            rowUrlAccessor={'id'}
            SelectedActionsComponent={FormCreateIdentityGroup}
            selectDataAccessor={'identity.id'}
          />
        </>
      ) : null}
    </>
  )
}
