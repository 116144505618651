import axios from '../services/api'
import {createNotification} from './notifications'
import I18n from '../i18n'

// Types
const GET_USERS_PENDING = 'idfunctiondb/users/GET_USERS_PENDING'
const GET_USERS_SUCCESS = 'idfunctiondb/users/GET_USERS_SUCCESS'
const GET_USERS_ERROR = 'idfunctiondb/users/GET_USERS_ERROR'
const GET_USERS_CLEAR_ERROR = 'idfunctiondb/users/GET_USERS_CLEAR_ERROR'

const GET_USER_PENDING = 'idfunctiondb/users/GET_USER_PENDING'
const GET_USER_SUCCESS = 'idfunctiondb/users/GET_USER_SUCCESS'
const GET_USER_ERROR = 'idfunctiondb/users/GET_USER_ERROR'

const DELETE_USER_PENDING = 'idfunctiondb/users/DELETE_USER_PENDING'
const DELETE_USER_SUCCESS = 'idfunctiondb/users/DELETE_USER_SUCCESS'
const DELETE_USER_ERROR = 'idfunctiondb/users/DELETE_USER_ERROR'
const DELETE_USER_CLEAR_ERROR = 'idfunctiondb/users/DELETE_USER_CLEAR_ERROR'

const SET_USER_PENDING = 'idfunctiondb/users/SET_USER_PENDING'
const SET_USER_SUCCESS = 'idfunctiondb/users/SET_USER_SUCCESS'
const SET_USER_ERROR = 'idfunctiondb/users/SET_USER_ERROR'
const SET_USER_CLEAR_ERROR = 'idfunctiondb/users/SET_USER_CLEAR_ERROR'

const RESET_USER_PASSWORD_PENDING = 'idfunctiondb/users/RESET_USER_PASSWORD_PENDING'
const RESET_USER_PASSWORD_SUCCESS = 'idfunctiondb/users/RESET_USER_PASSWORD_SUCCESS'
const RESET_USER_PASSWORD_ERROR = 'idfunctiondb/users/RESET_USER_PASSWORD_ERROR'
const RESET_USER_PASSWORD_CLEAR_ERROR = 'idfunctiondb/users/RESET_USER_PASSWORD_CLEAR_ERROR'

const CREATE_USER_PENDING = 'idfunctiondb/users/CREATE_USER_PENDING'
const CREATE_USER_SUCCESS = 'idfunctiondb/users/CREATE_USER_SUCCESS'
const CREATE_USER_ERROR = 'idfunctiondb/users/CREATE_USER_ERROR'
const CREATE_USER_CLEAR_ERROR = 'idfunctiondb/users/CREATE_USER_CLEAR_ERROR'

// Initial
const initialState = {
  get_users: {
    success: [],
    pending: false,
    error: null,
  },

  get_user: {
    success: {},
    pending: false,
    error: null,
  },

  set_user: {
    success: {},
    pending: false,
    error: null,
  },

  reset_user_password: {
    success: {},
    pending: false,
    error: null,
  },

  delete_user: {
    success: null,
    pending: false,
    error: null,
  },

  create_user: {
    success: [],
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USERS_PENDING:
      return {
        ...state,
        get_users: {
          ...state.get_users,
          pending: true,
        },
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        get_users: {
          ...state.get_users,
          success: action.payload,
          pending: false,
        },
      }
    case GET_USERS_ERROR:
      return {
        ...state,
        get_users: {
          ...state.get_users,
          error: action.payload,
          pending: false,
        },
      }
    case GET_USERS_CLEAR_ERROR:
      return {
        ...state,
        get_users: {
          ...state.get_users,
          error: null,
          pending: false,
        },
      }
    case GET_USER_PENDING:
      return {
        ...state,
        get_user: {
          ...state.get_user,
          pending: true,
        },
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        get_user: {
          ...state.get_user,
          success: action.payload,
          pending: false,
        },
      }
    case GET_USER_ERROR:
      return {
        ...state,
        get_user: {
          ...state.get_user,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_USER_PENDING:
      return {
        ...state,
        delete_user: {
          ...state.delete_user,
          pending: true,
        },
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        delete_user: {
          ...state.delete_user,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_USER_ERROR:
      return {
        ...state,
        delete_user: {
          ...state.delete_user,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_USER_CLEAR_ERROR:
      return {
        ...state,
        delete_user: {
          ...state.delete_user,
          error: null,
        },
      }
    case SET_USER_PENDING:
      return {
        ...state,
        set_user: {
          ...state.set_user,
          pending: true,
        },
      }
    case SET_USER_SUCCESS:
      return {
        ...state,
        set_user: {
          ...state.set_user,
          success: action.payload,
          pending: false,
        },
      }
    case SET_USER_ERROR:
      return {
        ...state,
        set_user: {
          ...state.set_user,
          error: action.payload,
          pending: false,
        },
      }
    case SET_USER_CLEAR_ERROR:
      return {
        ...state,
        set_user: {
          ...state.set_user,
          error: null,
          pending: false,
        },
      }

    case RESET_USER_PASSWORD_PENDING:
      return {
        ...state,
        reset_user_password: {
          ...state.reset_user_password,
          pending: true,
        },
      }
    case RESET_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        reset_user_password: {
          ...state.reset_user_password,
          success: action.payload,
          pending: false,
        },
      }
    case RESET_USER_PASSWORD_ERROR:
      return {
        ...state,
        reset_user_password: {
          ...state.reset_user_password,
          error: action.payload,
          pending: false,
        },
      }
    case RESET_USER_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        reset_user_password: {
          ...state.reset_user_password,
          error: null,
          pending: false,
        },
      }

    case CREATE_USER_PENDING:
      return {
        ...state,
        create_user: {
          ...state.create_user,
          pending: true,
        },
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        create_user: {
          ...state.create_user,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_USER_ERROR:
      return {
        ...state,
        create_user: {
          ...state.create_user,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_USER_CLEAR_ERROR:
      return {
        ...state,
        create_user: {
          ...state.create_user,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getUsersPending() {
  return {type: GET_USERS_PENDING}
}
function getUsersSuccess(payload) {
  return {type: GET_USERS_SUCCESS, payload: payload}
}
function getUsersError(error) {
  return {type: GET_USERS_ERROR, payload: error}
}
export function getUsersClearError() {
  return {type: GET_USERS_CLEAR_ERROR}
}

function getUserPending() {
  return {type: GET_USER_PENDING}
}
function getUserSuccess(payload) {
  return {type: GET_USER_SUCCESS, payload: payload}
}
function getUserError(error) {
  return {type: GET_USER_ERROR, payload: error}
}

function setUserPending() {
  return {type: SET_USER_PENDING}
}
function setUserSuccess(payload) {
  return {type: SET_USER_SUCCESS, payload: payload}
}
function setUserError(error) {
  return {type: SET_USER_ERROR, payload: error}
}
export function setUserClearError() {
  return {type: SET_USER_CLEAR_ERROR}
}

function resetsetUserPasswordPending() {
  return {type: RESET_USER_PASSWORD_PENDING}
}
function resetUserPasswordSuccess(payload) {
  return {type: RESET_USER_PASSWORD_SUCCESS, payload: payload}
}
function resetUserPasswordError(error) {
  return {type: RESET_USER_PASSWORD_ERROR, payload: error}
}
export function resetUserPasswordClearError() {
  return {type: RESET_USER_PASSWORD_CLEAR_ERROR}
}

function deleteUserPending() {
  return {type: DELETE_USER_PENDING}
}
function deleteUserSuccess(payload) {
  return {type: DELETE_USER_SUCCESS, payload: payload}
}
function deleteUserError(error) {
  return {type: DELETE_USER_ERROR, payload: error}
}
export function deleteUserClearError() {
  return {type: DELETE_USER_CLEAR_ERROR}
}

function createUserPending() {
  return {type: CREATE_USER_PENDING}
}
function createUserSuccess(payload) {
  return {type: CREATE_USER_SUCCESS, payload: payload}
}
function createUserError(error) {
  return {type: CREATE_USER_ERROR, payload: error}
}
export function createUserClearError() {
  return {type: CREATE_USER_CLEAR_ERROR}
}

// Operations
export const getUsers = (url) => (dispatch) => {
  dispatch(getUsersPending())
  return axios
    .get(url)
    .then((res) => {
      const users = res.data.results
      dispatch(getUsersSuccess(users))
    })
    .catch((error) => dispatch(getUsersError(error)))
}

export const getUser = (url) => (dispatch) => {
  dispatch(getUserPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getUserSuccess(res.data))
    })
    .catch((error) => dispatch(getUserError(error)))
}

export const setUser = (id, values) => (dispatch) => {
  // TODO: no notification for this functionality
  dispatch(setUserPending())
  return axios
    .put(`/api/v1/users/${id}`, values)
    .then((res) => {
      dispatch(setUserSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(setUserError(error)))
}

export const resetUserPassword = (id, values, userID) => (dispatch) => {
  dispatch(resetsetUserPasswordPending())
  return axios
    .put(`/api/v1/users/${id}/reset_password`, values)
    .then((res) => {
      dispatch(resetUserPasswordSuccess(res.data))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.UserPasswordResetted', {name: res.data.name}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(resetUserPasswordError(error)))
}

export const deleteUser = (id, userID) => (dispatch) => {
  dispatch(deleteUserPending())
  return axios
    .delete(`/api/v1/users/${id}`)
    .then((res) => {
      dispatch(deleteUserSuccess(res.data))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.UserDeleted', {name: res.data}),
        }),
      )
    })
    .catch((error) => dispatch(deleteUserError(error)))
}

export const createUser = (values) => (dispatch) => {
  // TODO: no notification for this functionality
  dispatch(createUserPending())
  return axios
    .post(`/api/v1/users`, values)
    .then((res) => {
      dispatch(createUserSuccess(res.data))
    })
    .catch((error) => {
      dispatch(createUserError(error))
    })
}
