import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Logo from '../Logo'
import ImageSrc from '../../assets/DeviceLogin.svg'
import {setOidcProviderLogoAdmin} from '../../stores/oidc_providers'
import {uploadPrivacyPolicyClearError} from '../../stores/static_assets'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import {useTranslation} from 'react-i18next'

export default function FormEditOidcProviderLogo({identity}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //select and upload an image (process and test) states
  const imageSource = identity?.logo.thumbnail_id ? identity?.logo.thumbnail : ImageSrc
  const [selectedImageToUpload, setSelectedImageToUpload] = useState(imageSource)

  const {pending, error, success} = useSelector(
    (state) => state.oidc_providers.set_oidc_provider_admin,
  )

  const handleSubmit = (values) => {
    dispatch(setOidcProviderLogoAdmin(identity.id, values.file.file))
  }

  const fields = [
    {
      label: t('uploadFile'),
      component: InputComponent.FILE,
      name: 'privacy_policy',
      validationType: YupValidationType.OBJECT,
      onUpdate: (fileFieldValue) => {
        const imageURl = fileFieldValue && URL.createObjectURL(fileFieldValue)
        setSelectedImageToUpload(imageURl)
      },
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[2, 3], t('yupError.FileType')],
        },
        {
          type: InputYupValidationsType.FILESIZE,
          params: [10000000, t('yupError.MaxFileSize')],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.FileRequired')],
        },
      ],
    },
  ]

  return (
    <>
      <Logo ImgSrc={selectedImageToUpload} />
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'file-submit',
          submitButtonText: t('login.Submit'),
        }}
        onSubmit={handleSubmit}
        pending={pending}
        noFormBorder
        infoMessage={
          <Notification
            error={error}
            setError={() => dispatch(uploadPrivacyPolicyClearError())}
            success={!!success}
          />
        }
      />
    </>
  )
}
