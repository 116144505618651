import {useEffect, useState} from 'react'
import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import Tabs from '../components/Tabs'
import {
  getEmployee,
  getEmployeeFiles,
  getEmployeeFilesClearError,
  getEmployeeMeta,
  createEmployeeFile,
  createEmployeeFileClearError,
  createEmployeeCustomAttribute,
  createEmployeeCustomAttributeClearError,
  getEmployeeClearError,
  deleteEmployeePhoto,
  deleteEmployeePhotoClearError,
} from '../stores/employees'

import {getCardsHistory, getCardsHistoryClearError, getEmployeesCards} from '../stores/cards'
import {getIdentityResources, getIdentityResourcesClearError} from '../stores/identities'
import {
  getEmployeesInventories,
  getEmployeesInventoriesClearError,
  filterEmployeesInventories,
  filterEmployeesInventoriesClearError,
  createEmployeesInventory,
  createEmployeesInventoryCLearError,
} from '../stores/employees_inventories'
import FormEditEmployeePersonal from '../components/forms/FormEditEmployeePersonal'
import FormEditEmployeePrivate from '../components/forms/FormEditEmployeePrivate'
import FormAddCustomField from '../components/forms/FormAddCustomField'
import ResourcesWrapper from '../components/wrappers/ResourcesWrapper'
import InventoriesWrapper from '../components/wrappers/InventoriesWrapper'
import CardsHistoryWrapper from '../components/wrappers/CardsHistoryWrapper'
import FilesWrapper from '../components/wrappers/FilesWrapper'
import IdentityCardsWrapper from '../components/wrappers/IdentityCardsWrapper'
import IdentityHeader from '../components/IdentityHeader'
import ErrorModal from '../components/ErrorModal'
import {EMPLOYEE} from '../utils/formViews'
import {useParams, useNavigate} from 'react-router-dom'
import WorkflowForms from '../components/WorkflowForms'
import {useTableColumns} from '../hooks/useTableColumns'
import {TableId} from '../stores/types/tables'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'
import ServerSideTable from '../components/table/ServerSideTable'
import {getIdentityEmployee} from '../stores/identity'
import {Modal, ModalOpenButton, ModalContentsBase} from '../components/Modal'
import {Button} from '../components/Buttons'
import FormAddToIdentityGroup from '../components/forms/FormAddToIdentityGroup'

export default function EmployeeScreen() {
  let {id} = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const [identity, setIdentity] = useState()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  // employee states
  const employee = useSelector((state) => state.employees.get_employee.success)
  const employee_error = useSelector((state) => state.employees.get_employee.error)

  const set_person_success = useSelector((state) => state.persons.set_person.success)
  const create_employee_custom_attribute = useSelector(
    (state) => state.employees.create_employee_custom_attribute.success,
  )
  const create_employee_custom_attribute_error = useSelector(
    (state) => state.employees.create_employee_custom_attribute.error,
  )
  const create_employee_custom_attribute_pending = useSelector(
    (state) => state.employees.create_employee_custom_attribute.pending,
  )
  const delete_employee_custom_attribute = useSelector(
    (state) => state.employees.delete_employee_custom_attribute.success,
  )

  // employee meta states
  const employeeMeta = useSelector((state) => state.employees.get_employee_meta.success)

  //  employee cards states
  const get_employee_cards_pending = useSelector((state) => state.cards.get_employee_cards.pending)

  // employee resources states
  const employees_access_profiles = useSelector(
    (state) => state.identities.get_identity_resources.success,
  )
  const employees_access_profiles_pending = useSelector(
    (state) => state.identities.get_identity_resources.pending,
  )
  const employees_access_profiles_error = useSelector(
    (state) => state.identities.get_identity_resources.error,
  )
  const successfullyAssignedResource = useSelector(
    (state) => state.identities.attach_identity_resources.success,
  )
  const successfullyDeletedResource = useSelector(
    (state) => state.identities.delete_identity_resource.success,
  )

  // employee inventories states

  const employees_inventories = useSelector(
    (state) => state.employees_inventories.get_employees_inventories.success,
  )

  const employees_inventories_pending = useSelector(
    (state) => state.employees_inventories.get_employees_inventories.pending,
  )

  const employees_inventories_error = useSelector(
    (state) => state.employees_inventories.get_employees_inventories.error,
  )

  const assign_employees_inventory_success = useSelector(
    (state) => state.employees_inventories.create_employees_inventory.success,
  )

  const assign_employees_inventory_pending = useSelector(
    (state) => state.employees_inventories.create_employees_inventory.pending,
  )
  const assign_employees_inventory_error = useSelector(
    (state) => state.employees_inventories.create_employees_inventory.error,
  )

  const delete_employees_inventory = useSelector(
    (state) => state.employees_inventories.delete_employees_inventory.success,
  )
  const set_employees_inventory = useSelector(
    (state) => state.employees_inventories.set_employees_inventory.success,
  )

  const filter_employees_inventories = useSelector(
    (state) => state.employees_inventories.filter_employees_inventories.success,
  )

  const filter_employees_inventories_pending = useSelector(
    (state) => state.employees_inventories.filter_employees_inventories.pending,
  )
  const filter_employees_inventories_error = useSelector(
    (state) => state.employees_inventories.filter_employees_inventories.error,
  )

  // employee CardHistory State

  const cardsHistory = useSelector((state) => state.cards.get_cards_history.success)
  const cardsHistory_pending = useSelector((state) => state.cards.get_cards_history.pending)
  const cardsHistory_error = useSelector((state) => state.cards.get_cards_history.error)

  // employee CardHistory refresh states

  const assign_card = useSelector((state) => state.cards.assign_card.success)
  const unassign_card = useSelector((state) => state.cards.unassign_card.success)
  const action_applied_on_card = useSelector((state) => state.cards.apply_action_card.success)

  // employee's file states

  const employee_files = useSelector((state) => state.employees.get_employee_files.success)
  const employee_files_pending = useSelector((state) => state.employees.get_employee_files.pending)
  const employee_files_error = useSelector((state) => state.employees.get_employee_files.error)

  const assign_file_success = useSelector((state) => state.employees.create_employee_file.success)
  const assign_file_pending = useSelector((state) => state.employees.create_employee_file.pending)
  const assign_file_error = useSelector((state) => state.employees.create_employee_file.error)
  const unassign_file_success = useSelector((state) => state.employees.delete_employee_file.success)

  // employee's image states
  const employeePhotoUpdateSuccess = useSelector((state) => state.photos.set_avatar_photo.success) // Only way for now
  // to update the
  // screen after
  // photo update
  const delete_employee_photo_error = useSelector(
    (state) => state.employees.delete_employee_photo.error,
  )
  const delete_employee_photo_pending = useSelector(
    (state) => state.employees.delete_employee_photo.pending,
  )
  const delete_employee_photo_success = useSelector(
    (state) => state.employees.delete_employee_photo.success,
  )
  const delete_identity_child_success = useSelector(
    (state) => state.identity.delete_identity_child.success,
  )
  const update_identity_success = useSelector((state) => state.identity.update_identity.success)

  const columnConfigFilesEmployee = useTableColumns(TableId.FILES_EMPLOYEE)
  const columnConfigInventoriesEmployee = useTableColumns(TableId.INVENTORIES_EMPLOYEE)

  useEffect(() => {
    if (employee_error?.response?.status === 404) {
      navigate('/not_found/', {replace: true})
    }
  }, [employee_error?.response?.status, navigate])

  // get Employee
  useEffect(() => {
    dispatch(getEmployee(id))
  }, [
    id,
    dispatch,
    create_employee_custom_attribute,
    delete_employee_custom_attribute,
    employeePhotoUpdateSuccess,
    set_person_success,
    delete_employee_photo_success,
    // set_employee_success is not needed for now. Because both FormEditEmployeePersonal and FormEditEmployeePrivate
    // calls setPerson
  ])

  // get Employee Meta
  useEffect(() => {
    if (permissions.listEmployees) {
      // if user has no permission we don't need to call getEmployeeMeta
      dispatch(getEmployeeMeta(id))
    }
  }, [id, permissions.listEmployees, dispatch])

  // get Employee's resources
  useEffect(() => {
    dispatch(getIdentityResources(id, 'employee'))
  }, [
    dispatch,
    id,
    successfullyAssignedResource,
    successfullyDeletedResource,
    employees_access_profiles_error?.message,
  ])

  // get Employee's inventories
  useEffect(() => {
    dispatch(getEmployeesInventories(`/api/v1/employees/${id}/inventories`))
  }, [
    dispatch,
    id,
    assign_employees_inventory_success,
    delete_employees_inventory,
    set_employees_inventory,
    employees_inventories_error?.message,
  ])

  // get Employee's Card History
  useEffect(() => {
    dispatch(getCardsHistory('employees', id))
  }, [
    dispatch,
    id,
    assign_card,
    unassign_card,
    action_applied_on_card,
    cardsHistory_error?.message,
  ])

  // get Employee's Files
  useEffect(() => {
    dispatch(getEmployeeFiles(id))
  }, [dispatch, id, assign_file_success, unassign_file_success, employee_files_error?.message])

  // get Employee's Cards
  const cards = useSelector((state) => state.cards.get_employee_cards.success)
  const card_photo_changed = useSelector((state) => state.cards_photos.set_card_photo.success)
  const create_cards_assign_set_photos = useSelector(
    (state) => state.cards.create_cards_assign_set_photos.success,
  )
  const selected_printer = useSelector((state) => state.printers.get_selected_printer.success)
  // TODO: refactor this again. const create_card_success = useSelector((state) => state.cards.create_card.success) //
  // create_cards_assign_set_photos
  const bulk_create_personalized_cards = useSelector(
    (state) => state.cards.bulk_create_personalized_cards.success,
  )

  useEffect(() => {
    dispatch(getEmployeesCards(`/api/v1/employees/${id}/cards`))
  }, [
    dispatch,
    id,
    bulk_create_personalized_cards,
    create_cards_assign_set_photos,
    action_applied_on_card,
    card_photo_changed,
    assign_card,
    permissions,
    selected_printer,
    unassign_card,
    delete_employee_photo_success,
  ])

  useEffect(() => {
    const fetch = async (id) => {
      try {
        const data = await dispatch(getIdentityEmployee(id))
        setIdentity(data)
      } catch (error) {
        setIdentity(undefined)
      }
    }

    if (id) {
      fetch(id).then()
    }
  }, [id, dispatch])

  const tabsArray = permissions.createEmployee // TODO: this should be create customAttribute permission
    ? [
        {
          name: 'Personal',
          component: (
            <FormEditEmployeePersonal
              data={employee}
              identityUpdatePermission={permissions.updateEmployee}
              identityUpdateCustomAttribePermission={permissions.createEmployee} // TODO: this should be update/delete
              // customAttribute permission
            />
          ),
        },
        {
          name: 'Private',
          component: (
            <FormEditEmployeePrivate
              data={employee}
              identityUpdatePermission={permissions.updatePerson}
            />
          ),
        },
        {
          name: 'Custom Fields',
          component: (
            <FormAddCustomField
              data={employee}
              submit={createEmployeeCustomAttribute}
              setError={createEmployeeCustomAttributeClearError}
              error={create_employee_custom_attribute_error}
              pending={create_employee_custom_attribute_pending}
            />
          ),
        },
      ]
    : [
        {
          name: 'Personal',
          component: (
            <FormEditEmployeePersonal
              data={employee}
              identityUpdatePermission={permissions.updateEmployee}
              identityUpdateCustomAttribePermission={permissions.createEmployee}
            />
          ),
        },
        {
          name: 'Private',
          component: (
            <FormEditEmployeePrivate
              data={employee}
              identityUpdatePermission={permissions.updatePerson}
            />
          ),
        },
      ]

  const columnsIdentitiesEmployee = useTableColumns(TableId.IDENTITIES_EMPLOYEE)

  return (
    <>
      {employee_error ? (
        <ErrorModal error={employee_error} setError={() => dispatch(getEmployeeClearError())} />
      ) : null}
      <IdentityHeader
        identity={employee}
        identityMeta={employeeMeta}
        identityIdentity={identity}
        identityPermission={permissions.updateIdentity}
        identityListPermission={permissions.listEmployees}
        identityAssignInventoryPermission={
          permissions.listInventories && permissions.updateEmployee
        }
        identityAssignResourcePermission={
          permissions.listAccessProfiles && permissions.updateEmployee
        }
        identityLabel="employee_id"
        identityNumberLabel="employee_number"
        identityNavTo="/employees"
        isEmployee={true}
        isVisitor={false}
        cards={cards}
        assignInventoryToIdentity={createEmployeesInventory}
        assignInventoryToIdentityClearError={createEmployeesInventoryCLearError}
        assignInventoryToIdentityPending={assign_employees_inventory_pending}
        assignInventoryToIdentityError={assign_employees_inventory_error}
        filterIdentityInventory={filterEmployeesInventories}
        filterIdentityInventoryClearError={filterEmployeesInventoriesClearError}
        filteredIdentityInventoriesPending={filter_employees_inventories_pending}
        filteredIdentityInventoriesError={filter_employees_inventories_error}
        filteredIdentityInventories={filter_employees_inventories}
        deleteIdentityImage={deleteEmployeePhoto}
        deleteIdentityImagePending={delete_employee_photo_pending}
        deleteIdentityImageError={delete_employee_photo_error}
        deleteIdentityImageClearError={deleteEmployeePhotoClearError}
      />
      <WorkflowForms renderOnUi={EMPLOYEE} isOriginUrl />
      <Collapse title={t('employeeCardInfo.SectionTitle')}>
        <IdentityCardsWrapper
          data={employee}
          identityAssignCardPermission={permissions.createCard}
          identityUnassignCardPermission={permissions.deleteEmployee} // TODO: this should be unassign card permission
          // or something else
          cards={cards}
          identityCardsPending={get_employee_cards_pending}
          urlIdentity="employees"
        />
      </Collapse>
      <Collapse title={t('employeeInfo.EmployeeInfo')}>
        <Tabs tabs={tabsArray} />
      </Collapse>
      <Collapse title={t('employeeResource.Title')}>
        <ResourcesWrapper
          data={employees_access_profiles}
          pending={employees_access_profiles_pending}
          error={employees_access_profiles_error}
          identityAssignResourcePermission={
            permissions.listAccessProfiles && permissions.updateEmployee
          }
          // resource permission
          setError={getIdentityResourcesClearError}
          identityLabel="employee_id"
          identity={employee}
        />
      </Collapse>
      {!!identity?.id && (
        <Collapse title={t('sidebar.Identities')}>
          <>
            {permissions.updateIdentity && (
              <Modal>
                <ModalOpenButton>
                  <Button text={t('identity.AddIdentity')} variant="secondary" />
                </ModalOpenButton>
                <ModalContentsBase title={t('identity.AddIdentity')}>
                  <FormAddToIdentityGroup identity={identity} />
                </ModalContentsBase>
              </Modal>
            )}
            <EditTableWrapper tableId={TableId.IDENTITIES_EMPLOYEE} />
            <ServerSideTable
              columns={columnsIdentitiesEmployee}
              entityName={`identities/${identity?.id}/parents`}
              rowUrlAccessor={'id'}
              url={'/identities/'}
              deleteAction={delete_identity_child_success}
              addAction={update_identity_success}
            />
          </>
        </Collapse>
      )}
      <Collapse title={t('employeeInventory.Title')}>
        <InventoriesWrapper
          data={employees_inventories}
          pending={employees_inventories_pending}
          error={employees_inventories_error}
          setError={getEmployeesInventoriesClearError}
          identity={employee}
          identityLabel="employee_id"
          identityAssignInventoryPermission={
            permissions.listInventories && permissions.updateEmployee
          }
          assignInventoryToIdentity={createEmployeesInventory}
          assignInventoryToIdentityClearError={createEmployeesInventoryCLearError}
          assignInventoryToIdentityPending={assign_employees_inventory_pending}
          assignInventoryToIdentityError={assign_employees_inventory_error}
          filterIdentityInventory={filterEmployeesInventories}
          filterIdentityInventoryClearError={filterEmployeesInventoriesClearError}
          filteredIdentityInventoriesPending={filter_employees_inventories_pending}
          filteredIdentityInventoriesError={filter_employees_inventories_error}
          filteredIdentityInventories={filter_employees_inventories}
          columns={columnConfigInventoriesEmployee}
          tableId={TableId.INVENTORIES_EMPLOYEE}
        />
      </Collapse>
      <Collapse title={t('employeeCardHistoryInfo.Title')}>
        <CardsHistoryWrapper
          data={cardsHistory}
          pending={cardsHistory_pending}
          error={cardsHistory_error}
          setError={getCardsHistoryClearError}
        />
      </Collapse>
      <Collapse title={t('employeeFiles.Title')}>
        <FilesWrapper
          data={employee_files}
          pending={employee_files_pending}
          error={employee_files_error}
          setError={getEmployeeFilesClearError}
          assignFileToIdentityPending={assign_file_pending}
          assignFileToIdentityError={assign_file_error}
          addFile={createEmployeeFile}
          IdentityCreateFileClearError={createEmployeeFileClearError}
          entityId={id}
          columns={columnConfigFilesEmployee}
          tableId={TableId.FILES_EMPLOYEE}
        />
      </Collapse>
    </>
  )
}
