import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import {
  createCardAndAssignAndSetPhotos,
  getCardProfiles,
  getCardProfilesCLearError,
  createCardsAssignAndSetPhotosClearError,
} from '../../stores/cards'
import {iso8061UTC} from '../../utils/datetime'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import ErrorOnView from '../ErrorOnView'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'

export default function FormAssignCardPersonalized({identity}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const cardProfiles = useSelector((state) => state.cards.get_card_profiles.success)
  const cardProfilesError = useSelector((state) => state.cards.get_card_profiles.error)
  const error = useSelector((state) => state.cards.create_cards_assign_set_photos.error)

  useEffect(() => {
    dispatch(getCardProfiles(`/api/v1/cards/profiles`))
  }, [dispatch, cardProfilesError?.message])

  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const handleSubmit = (values) => {
    let identityValues = {
      id: identity.id,
      url: identity.url + '/cards',
      currentPhotoId: identity.photos.current_id,
      personName: identity.person.full_name,
      personEmail: identity.person.email,
    }
    let identityCardValues = {
      valid_from: iso8061UTC(values.valid_from),
      valid_to: iso8061UTC(values.valid_to),
      card_profile_id: values.card_profile_id,
      location: values.location,
    }

    dispatch(createCardAndAssignAndSetPhotos(identityValues, identityCardValues))
  }

  const pending = useSelector((state) => state.cards.create_cards_assign_set_photos.pending)

  const fields = [
    {
      component: InputComponent.SELECT,
      name: `card_profile_id`,
      label: 'addCard.CardProfile',
      placeholder: 'addCard.SelectCardProfile',
      isMulti: false,
      options: cardProfiles.map((o) => ({
        value: o.id,
        label: o.name,
      })),
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: 'addEmployee.Domain',
      placeholder: 'addEmployee.DomainPlaceholder',
      defaultValue: permissions.createCardLocations && permissions.createCardLocations[0]?.value,
      options: permissions.createCardLocations,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_from',
      inputType: 'datetime-local',
      label: 'addCard.ValidFrom',
      validationType: YupValidationType.DATE,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_to',
      inputType: 'datetime-local',
      label: 'addCard.ValidTo',
      validationType: YupValidationType.DATE,
      allowEndless: true,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
        {
          type: InputYupValidationsType.ENDDATE, // special type for validation
          params: ['visitor-valid_from', t('yupError.DateRange')],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-assign-card-personalized-submit',
          submitButtonText: t('addCard.Add'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        infoMessage={
          <Notification
            error={error}
            setError={() => dispatch(createCardsAssignAndSetPhotosClearError())}
          />
        }
      />
      {!!cardProfilesError && (
        <ErrorOnView
          error={cardProfilesError}
          setError={() => dispatch(getCardProfilesCLearError())}
        />
      )}
    </>
  )
}
