import React from 'react'
import {Panel, useReactFlow, getRectOfNodes, getTransformForBounds} from 'reactflow'
import {toPng} from 'html-to-image'
import {DocumentDownloadIcon} from '@heroicons/react/outline'
import {Button} from '../Buttons'
import {useTranslation} from 'react-i18next'

function downloadImage(dataUrl) {
  const a = document.createElement('a')

  a.setAttribute('download', 'idfunction.png')
  a.setAttribute('href', dataUrl)
  a.click()
}

const imageWidth = 1024
const imageHeight = 768

function DownloadButton() {
  const {t} = useTranslation()
  const {getNodes} = useReactFlow()
  const onClick = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getRectOfNodes(getNodes())
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2)

    toPng(document.querySelector('.react-flow__viewport'), {
      backgroundColor: 'white',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(downloadImage)
  }

  return (
    <Panel position="top-right">
      <Button
        variant="primary"
        icon={<DocumentDownloadIcon className="h-6 w-6" aria-hidden="true" />}
        text={t('downloadImage')}
        onClick={onClick}
      />
    </Panel>
  )
}

export default DownloadButton
