import {useTranslation} from 'react-i18next'

const TablePaginationCount = ({
  pageIndex,
  length,
  pageSize,
  onPageSizeSelect,
  sizeOptions = [5, 10, 20],
}) => {
  const {t} = useTranslation()
  return (
    <div className="flex items-center gap-x-2">
      <span className="text-sm text-gray-700" data-testid="table-pagination">
        {t('page')}{' '}
        <span className="font-medium" data-testid="table-pagination-current">
          {pageIndex + 1}
        </span>{' '}
        {t('of')}{' '}
        <span className="font-medium" data-testid="table-pagination-last">
          {length}
        </span>
      </span>
      <label>
        <span className="sr-only">Items Per Page</span>
        <select
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
          value={pageSize}
          onChange={(e) => {
            onPageSizeSelect(Number(e.target.value))
          }}
        >
          {sizeOptions
            .filter((i) => !!i)
            .map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t('show')} {pageSize}
              </option>
            ))}
        </select>
      </label>
    </div>
  )
}

export default TablePaginationCount
