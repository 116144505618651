import axios from '../services/api'
import {createNotification} from './notifications'
import I18n from '../i18n'

// Types

const GET_IDENTITY_RESOURCES_PENDING = 'idfunctiondb/identities/GET_IDENTITY_RESOURCES_PENDING'
const GET_IDENTITY_RESOURCES_SUCCESS = 'idfunctiondb/identities/GET_IDENTITY_RESOURCES_SUCCESS'
const GET_IDENTITY_RESOURCES_ERROR = 'idfunctiondb/identities/GET_IDENTITY_RESOURCES_ERROR'
const GET_IDENTITY_RESOURCES_CLEAR_ERROR =
  'idfunctiondb/identities/GET_IDENTITY_RESOURCES_CLEAR_ERROR'

const ATTACH_IDENTITIES_RESOURCES_PENDING =
  'idfunctiondb/identities/ATTACH_IDENTITIES_RESOURCES_PENDING'
const ATTACH_IDENTITIES_RESOURCES_SUCCESS =
  'idfunctiondb/identities/ATTACH_IDENTITIES_RESOURCES_SUCCESS'
const ATTACH_IDENTITIES_RESOURCES_ERROR =
  'idfunctiondb/identities/ATTACH_IDENTITIES_RESOURCES_ERROR'
const ATTACH_IDENTITIES_RESOURCES_CLEAR_ERROR =
  'idfunctiondb/identities/ATTACH_IDENTITIES_RESOURCES_CLEAR_ERROR'

const DELETE_IDENTITY_RESOURCE_PENDING = 'idfunctiondb/identities/DELETE_IDENTITY_RESOURCE_PENDING'
const DELETE_IDENTITY_RESOURCE_SUCCESS = 'idfunctiondb/identities/DELETE_IDENTITY_RESOURCE_SUCCESS'
const DELETE_IDENTITY_RESOURCE_ERROR = 'idfunctiondb/identities/DELETE_IDENTITY_RESOURCE_ERROR'

const initialState = {
  get_identity_resources: {
    success: [],
    pending: false,
    error: null,
  },

  attach_identity_resources: {
    success: null,
    pending: false,
    error: null,
  },

  delete_identity_resource: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_IDENTITY_RESOURCES_PENDING:
      return {
        ...state,
        get_identity_resources: {
          ...state.get_identity_resources,
          pending: true,
        },
      }
    case GET_IDENTITY_RESOURCES_SUCCESS:
      return {
        ...state,
        get_identity_resources: {
          ...state.get_identity_resources,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_IDENTITY_RESOURCES_ERROR:
      return {
        ...state,
        get_identity_resources: {
          ...state.get_identity_resources,
          error: action.payload,
          pending: false,
        },
      }
    case GET_IDENTITY_RESOURCES_CLEAR_ERROR:
      return {
        ...state,
        get_identity_resources: {
          ...state.get_identity_resources,
          error: null,
          pending: false,
        },
      }
    case ATTACH_IDENTITIES_RESOURCES_PENDING:
      return {
        ...state,
        attach_identity_resources: {
          ...state.attach_identity_resources,
          pending: true,
        },
      }
    case ATTACH_IDENTITIES_RESOURCES_SUCCESS:
      return {
        ...state,
        attach_identity_resources: {
          ...state.attach_identity_resources,
          success: action.payload,
          pending: false,
        },
      }
    case ATTACH_IDENTITIES_RESOURCES_ERROR:
      return {
        ...state,
        attach_identity_resources: {
          ...state.attach_identity_resources,
          error: action.payload,
          pending: false,
        },
      }
    case ATTACH_IDENTITIES_RESOURCES_CLEAR_ERROR:
      return {
        ...state,
        attach_identity_resources: {
          ...state.attach_identity_resources,
          error: null,
          pending: false,
        },
      }
    case DELETE_IDENTITY_RESOURCE_PENDING:
      return {
        ...state,
        delete_identity_resource: {
          ...state.delete_identity_resource,
          pending: true,
        },
      }
    case DELETE_IDENTITY_RESOURCE_SUCCESS:
      return {
        ...state,
        delete_identity_resource: {
          ...state.delete_identity_resource,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_IDENTITY_RESOURCE_ERROR:
      return {
        ...state,
        delete_identity_resource: {
          ...state.delete_identity_resource,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions

function getIdentityResourcesPending() {
  return {type: GET_IDENTITY_RESOURCES_PENDING}
}
function getIdentityResourcesSuccess(payload) {
  return {type: GET_IDENTITY_RESOURCES_SUCCESS, payload: payload}
}
function getIdentityResourcesError(error) {
  return {type: GET_IDENTITY_RESOURCES_ERROR, payload: error}
}
export function getIdentityResourcesClearError() {
  return {type: GET_IDENTITY_RESOURCES_CLEAR_ERROR}
}

function attachIdentitiesResourcesPending() {
  return {type: ATTACH_IDENTITIES_RESOURCES_PENDING}
}
function attachIdentitiesResourcesSuccess(payload) {
  return {type: ATTACH_IDENTITIES_RESOURCES_SUCCESS, payload: payload}
}
function attachIdentitiesResourcesError(error) {
  return {type: ATTACH_IDENTITIES_RESOURCES_ERROR, payload: error}
}

export function attachIdentitiesResourcesClearError() {
  return {type: ATTACH_IDENTITIES_RESOURCES_CLEAR_ERROR}
}

function deleteIdentityAccessProfilePending() {
  return {type: DELETE_IDENTITY_RESOURCE_PENDING}
}
function deleteIdentityAccessProfileSuccess(payload) {
  return {type: DELETE_IDENTITY_RESOURCE_SUCCESS, payload: payload}
}
function deleteIdentityAccessProfileError(error) {
  return {type: DELETE_IDENTITY_RESOURCE_ERROR, payload: error}
}

// Operations

export const getIdentityResources = (id, entity) => (dispatch) => {
  dispatch(getIdentityResourcesPending())
  return axios
    .get(`/api/v1/identities/${id}/${entity}_resources`)
    .then((res) => {
      dispatch(getIdentityResourcesSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getIdentityResourcesError(error)))
}

export const attachIdentitiesResources = (values, userID) => (dispatch) => {
  dispatch(attachIdentitiesResourcesPending())
  return axios
    .post(`/api/v1/identities_access_profiles`, values)
    .then((res) => {
      dispatch(attachIdentitiesResourcesSuccess(res))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.ResourceAttached'),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(attachIdentitiesResourcesError(error)))
}

export const deleteIdentityAccessProfile = (id) => (dispatch) => {
  dispatch(deleteIdentityAccessProfilePending())
  return axios
    .delete(`/api/v1/identities_access_profiles/${id}`)
    .then((res) => {
      dispatch(deleteIdentityAccessProfileSuccess(res))
      return res.data
    })
    .catch((error) => dispatch(deleteIdentityAccessProfileError(error)))
}
