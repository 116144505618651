import {FC, ReactNode} from 'react'
import {ErrorMessage} from '../ErrorMessage'
import {useTranslation} from 'react-i18next'

interface InputWrapperProps {
  label?: string
  id: string
  isRequired: boolean
  children: ReactNode
  error?: string
}

const InputWrapper: FC<InputWrapperProps> = ({label, id, isRequired, children, error}) => {
  const {t} = useTranslation()
  return (
    <div className="flex w-full flex-col gap-1">
      {label && (
        <label className="block text-sm font-medium text-gray-700" htmlFor={id}>
          {t(label)}
          {isRequired && '*'}
        </label>
      )}
      {children}
      <ErrorMessage error={t(error)} />
    </div>
  )
}

export default InputWrapper
