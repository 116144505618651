import {useTranslation} from 'react-i18next'
import {createIdentitiesFilesTags, createIdentitiesFilesTagsClearError} from '../../stores/files'
import {useDispatch, useSelector} from 'react-redux'
import {TextInput} from '../FormInputs'
import {Formik, Form} from 'formik'
import {FormikSaveButton} from '../Buttons'

export default function FormAddFileTag() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const initialValues = {
    name: '',
  }

  const pending = useSelector((state) => state.files.create_identity_files_tags.pending)
  const error = useSelector((state) => state.files.create_identity_files_tags.error)

  const handleSubmit = async (values) => {
    dispatch(createIdentitiesFilesTags('/api/v1/file_tags', values))
  }
  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <TextInput label={t('employeeFiles.Tag')} type="text" name="name" placeholder="" />
          <FormikSaveButton
            type="submit"
            testid="form-add-file-tag-submit"
            text={t('addEmployee.AddEmployee')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(createIdentitiesFilesTagsClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
