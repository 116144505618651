export const ResourcesIcon = (props) =>(
    <svg version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" className={props.className}  x="0px" y="0px"
	 viewBox="0 0 900 900" >

<path style={{fill:props.fill}}  d="M758.4,467.1h-165c-6.6,0-12,5.4-12,12v93.7c0,6.6,5.4,12,12,12h165c6.6,0,12-5.4,12-12v-93.7
		C770.4,472.5,765,467.1,758.4,467.1z M713.1,535h-27.3v27.3h-43.9v-43.9h27.3v-27.3h43.9V535z M454.2,410.4
		c99.3,0,179.8-80.5,179.8-179.8S553.5,50.8,454.2,50.8s-179.8,80.5-179.8,179.8S354.9,410.4,454.2,410.4z M380.2,214.5
		c7.8-6.1,19.2-4.7,25.3,3.1l29.3,37.4l68-89.9c6.1-7.9,17.4-9.5,25.3-3.4c7.9,6.1,9.5,17.4,3.4,25.3v0.5l-82.2,107.9
		c-3.4,4.4-8.6,7-14.2,7c-5.5,0-10.8-2.5-14.2-6.8l-43.9-55.9C371,231.9,372.4,220.6,380.2,214.5z M536.8,467.1h-165
		c-6.6,0-12,5.4-12,12v93.7c0,6.6,5.4,12,12,12h165c6.6,0,12-5.4,12-12v-93.7C548.8,472.5,543.4,467.1,536.8,467.1z M395.7,497.8
		h81.8v23.4h-81.8V497.8z M513.1,556.7h-81.8v-23.4h81.8V556.7z M547.7,689.4l-37.1,64.2l-37.1,64.2c-8.2,14.3-28.9,14.3-37.1,0
		l-37.1-64.2l-37.1-64.2c-8.2-14.3,2.1-32.1,18.5-32.1H455h74.2C545.6,657.3,555.9,675.1,547.7,689.4z M350.9,699.8l24.6,42.6H155.7
		c-56.6,0-103-46.3-103-103v-328c0-56.6,46.3-103,103-103h101c-4.9,19.7-1.2,42.6-1.2,42.6h-99.8c-33.4,0-60.4,27.1-60.4,60.4v328
		c0,33.4,27.1,60.4,60.4,60.4H350.9z M315.2,467.1h-165c-6.6,0-12,5.4-12,12v93.7c0,6.6,5.4,12,12,12h165c6.6,0,12-5.4,12-12v-93.7
		C327.2,472.5,321.8,467.1,315.2,467.1z M210.4,564.4c-10.7,0-19.4-8.7-19.4-19.4c0-10.7,8.7-19.4,19.4-19.4s19.4,8.7,19.4,19.4
		C229.8,555.7,221.1,564.4,210.4,564.4z M248.1,526.7c-10.7,0-19.4-8.7-19.4-19.4s8.7-19.4,19.4-19.4s19.4,8.7,19.4,19.4
		S258.8,526.7,248.1,526.7z M849.9,311.3v328c0,56.6-46.3,103-103,103H534.4l24.6-42.6h187.9c33.4,0,60.4-27.1,60.4-60.4v-328
		c0-33.3-27.1-60.4-60.4-60.4c0,0-92.2,0-94.8,0c4-19.4,0-42.6,0-42.6h94.8C803.5,208.3,849.9,254.7,849.9,311.3z"/>
</svg>
)