import {useRef} from 'react'
import {Formik, Form} from 'formik'
import {TextInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {setCompany, setCompanyClearError} from '../../stores/companies'

export default function FormEditCompany({selectedItem = {}, notify}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const pending = useSelector((state) => state.companies.set_company.pending)
  const error = useSelector((state) => state.companies.set_company.error)

  const initialValues = {
    name: selectedItem.label || selectedItem.name || '',
    street: selectedItem.street || '',
    zip: selectedItem.zip || '',
    city: selectedItem.city || '',
    state: selectedItem.state || '',
    country: selectedItem.country || '',
    phone: selectedItem.phone || '',
    email: selectedItem.email || '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(256, t('yupError.MaxCharsAllowed', {numberOfChars: '256'}))
      .required(t('yupError.ThisFieldIsRequired', {field: t('addCompanyForm.Company')})), // 256
    street: Yup.string().max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '256'})), // 256
    zip: Yup.string().max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '16'})), // 16
    city: Yup.string().max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '128'})), // 128
    state: Yup.string().max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '64'})), // 64
    country: Yup.string().max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '64'})), // 64
    // TODO: here no email and phone
  })

  const handleSubmit = (values, actions) => {
    dispatch(setCompany(selectedItem.value || selectedItem.id, values, notify))
    actions.resetForm()
    domainSelectRef.current.select.setValue('')
  }

  let domainSelectRef = useRef()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form>
          <TextInput label={t('addCompanyForm.Company')} name="name" type="text" placeholder="" />
          <TextInput label={t('addCompanyForm.Street')} name="street" type="text" placeholder="" />
          <TextInput label={t('addCompanyForm.ZipCode')} name="zip" type="text" placeholder="" />
          <TextInput label={t('addCompanyForm.City')} name="city" type="text" placeholder="" />
          <TextInput label={t('addCompanyForm.State')} name="state" type="text" placeholder="" />
          <TextInput
            label={t('addCompanyForm.Country')}
            name="country"
            type="text"
            placeholder=""
          />
          <TextInput label={t('addCompanyForm.Email')} name="email" type="email" placeholder="" />
          <TextInput label={t('addCompanyForm.Phone')} name="phone" type="text" placeholder="" />
          <FormikSaveButton
            type="submit"
            testid="form-edit-company-submit"
            text={t('addInventoryForm.Save')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(setCompanyClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
