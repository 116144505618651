import {CalendarIcon} from '@heroicons/react/outline'
import {Button} from './Buttons'
import {useTranslation} from 'react-i18next'

export default function NotAvailableEntity({message, setShow}) {
  const {t} = useTranslation()
  return (
    <div className="pt-4">
      <div className="rounded-md bg-red-50 p-3">
        <div className="flex">
          <div className="flex-shrink-0">
            <CalendarIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-red-800">{message}</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <Button
                variant="info"
                onClick={() => {
                  setShow(true)
                }}
                text={t('Retry')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
