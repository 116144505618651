import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {getRhfValidations, isFieldRequired} from '../lib/utils'
import InputWrapper from './inputWrapper'

const RadioGroup = (props) => {
  const {name, label, options, validations, validationType, withRhfValidation} = props
  const {register, formState, getValues, getFieldState} = useFormContext()
  const {t} = useTranslation()
  const error = getFieldState(name, formState).error?.message || ''
  const registerOptions = withRhfValidation ? getRhfValidations(getValues, name, validations) : {}

  const id = `${name}-${props.type}-${label}`

  return (
    <InputWrapper
      id={id}
      isRequired={isFieldRequired(validations, validationType)}
      label={label}
      error={error}
    >
      <fieldset>
        <legend className="sr-only">{t('formBuilder.RadioInput')}</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          {options &&
            options.map(({label, value}) => (
              <div key={value} className="flex items-center">
                <input
                  {...register(name, registerOptions)}
                  name={name}
                  value={value}
                  type="radio"
                  data-testid={`radio-${name}-${value}`}
                  className="h-4 w-4 border-gray-300 text-lime-600 focus:ring-lime-600"
                />
                <label
                  htmlFor={id}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >
                  {t(label)}
                </label>
              </div>
            ))}
        </div>
      </fieldset>
    </InputWrapper>
  )
}

export function RadioButtonField(props) {
  return (
    <div className="mt-2">
      <label className="block text-sm font-medium text-gray-700">{props.label}</label>
      {props.options.map((opt, index) => {
        return (
          <div className="flex items-center" key={index}>
            <input
              type="radio"
              name={props.name}
              value={opt.value}
              checked={opt.value === props.value}
              onBlur={props.handleBlur}
              onChange={props.onChange}
              className="h-4 w-4 border-gray-300 text-lime-600 focus:ring-lime-500"
            />
            <label htmlFor={props.name} className="ml-3 block text-sm font-medium text-gray-700">
              {opt.label}
            </label>
          </div>
        )
      })}
      {props.error && props.touched[props.name] && (
        <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
          {props.error}
        </p>
      )}
    </div>
  )
}

export default RadioGroup
