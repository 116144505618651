/* eslint-disable jsx-a11y/anchor-is-valid */

// TODO: not so sure about the classess here looks okay for now
import {XCircleIcon} from '@heroicons/react/outline'
export default function MeetingParticipants({participants, removeParticipant}) {
  return (
    <>
      <div>
        <div className="mt-6 flow-root">
          <ul className="-my-5 divide-y divide-gray-200">
            {participants.map((participant) => (
              <li key={participant.id} className="py-4">
                <div className="flex items-center space-x-4">
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-700">
                      {participant.full_name}
                    </p>
                    <p className="truncate text-sm text-gray-500">{participant.email}</p>
                  </div>
                  <div>
                    <a
                      href="#"
                      className=" items-center bg-red-100 text-sm font-medium text-gray-700 shadow-sm hover:bg-red-50"
                      onClick={() => {
                        removeParticipant(participant)
                      }}
                    >
                      <XCircleIcon
                        className="mr-4  h-6 w-6 flex-shrink-0 text-red-400 hover:text-green-500"
                        aria-hidden="true"
                      />
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}
