import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'SelectCheckbox',
    cell: 'SelectCheckboxCell',
    label: 'action',
    id: 'selection',
    width: 25,
    accessor: 'selection',
    noSort: true,
  },
  {
    header: 'ColumnFilter',
    accessor: 'badgenr',
    label: 'cardList.CardBadgeNumber',
    idAccessor: 'id',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'created_at',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.CustomAttributes',
    accessor: 'custom_attributes',
    noSort: true,
    cell: 'CustomAttributesCell',
    label: 'TableLabelsCommon.CustomAttributes',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'deleted_at',
    label: 'TableLabelsCommon.DeletedAt',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.costcenter',
    noSort: true,
    label: 'TableLabelsCommon.Costcenter',
  },

  {
    header: 'ColumnFilter',
    accessor: 'owner.department',
    noSort: true,
    label: 'TableLabelsCommon.Department',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.email',
    noSort: true,
    label: 'TableLabelsCommon.Email',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.employee_number',
    noSort: true,
    label: 'TableLabelsCommon.EmployeeId',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.employee_state_long',
    noSort: true,
    label: 'TableLabelsCommon.StatusLong',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'owner.end_of_contract',
    noSort: true,
    label: 'TableLabelsCommon.EndOfContract',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'owner.last_day_of_work',
    noSort: true,
    label: 'TableLabelsCommon.LastDayOfWork',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.local_hr_number',
    noSort: true,
    label: 'TableLabelsCommon.LocalHrNumber',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.mainoffice',
    noSort: true,
    label: 'TableLabelsCommon.Mainoffice',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.person.first_name',
    noSort: true,
    label: 'TableLabelsCommon.FirstName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.person.full_name',
    noSort: true,
    label: 'TableLabelsCommon.FullName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.person.gender_name',
    noSort: true,
    label: 'TableLabelsCommon.GenderName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.person.last_name',
    noSort: true,
    label: 'TableLabelsCommon.LastName',
  },
  {
    header: 'SelectColumnFilterLocations',
    accessor: 'location.description',
    label: 'personList.Domain',
    permissionsAccessor: 'listCardsLocations',
  },
  {
    header: 'ColumnFilter',
    accessor: 'owner.position',
    noSort: true,
    label: 'TableLabelsCommon.Position',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'owner.start_of_contract',
    noSort: true,
    label: 'TableLabelsCommon.StartOfContract',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'owner.updated_at',
    noSort: true,
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'ColumnFilter',
    accessor: 'production_profile_name',
    label: 'TableLabelsCommon.ProductionProfileName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'profile.description',
    label: 'TableLabelsCommon.ProfileDescription',
  },
  {
    header: 'ColumnFilter',
    accessor: 'profile.name',
    label: 'TableLabelsCommon.ProfileName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'profile.layout_profile.name',
    label: 'TableLabelsCommon.ProfileLayout',
  },
  {
    header: 'ColumnFilter',
    accessor: 'state.description',
    label: 'TableLabelsCommon.StatusDescription',
  },
  {
    accessor: 'state.name',
    cell: 'StatusPill',
    header: 'SelectColumnFilterCardStates',
    label: 'TableLabelsCommon.Status',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'updated_at',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'valid_from',
    label: 'TableLabelsCommon.ValidFrom',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'valid_to',
    label: 'TableLabelsCommon.ValidTo',
  },
]
export default columnConfig
