import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'employeeFiles.Description',
    label: 'employeeFiles.Description',
    accessor: 'description',
  },
  {
    header: 'TableLabelsCommon.Name',
    label: 'TableLabelsCommon.Name',
    accessor: 'original_name',
    secondAccessor: 'url',
    cell: 'LinkCell',
  },
  {
    header: 'employeeFiles.Name',
    accessor: 'predefined_name',
    label: 'employeeFiles.Name',
  },
  {
    header: 'employeeFiles.Tag',
    label: 'employeeFiles.Tag',
    accessor: 'ValidToFn',
  },
  {
    header: 'employeeFiles.Delete',
    label: 'employeeFiles.Delete',
    accessor: 'id',
    cell: 'FileVisitorActionCell',
    noSort: true,
  },
]
export default columnConfig
