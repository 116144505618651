import {ModalDismissButton} from './Modal'
import {useDispatch} from 'react-redux'
import {ExclamationIcon} from '@heroicons/react/outline'
import {useTranslation} from 'react-i18next'
import CollapseMessage from './CollapseMessage'
import {getErrorText} from './ErrorOnView'

export default function ErrorOnModal({error, clearError, setShow}) {
  const dispatch = useDispatch()
  const {t, i18n} = useTranslation()
  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <div className="text-lg font-medium leading-6 text-gray-900">Error</div>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{getErrorText(t, i18n, error)}</p>
            {/* Typed errors has message and they should not disappear */}
            {error?.response?.data?.error_type && error?.response?.data?.error_message ? (
              <CollapseMessage title={t('ErrorMessage')}>
                <p>{error?.response?.data?.error_message}</p>
              </CollapseMessage>
            ) : null}
          </div>
        </div>
      </div>
      <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <ModalDismissButton>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => dispatch(clearError())}
          >
            {t('common.CloseAndClear')}
          </button>
        </ModalDismissButton>
        <button
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 pb-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => {
            setShow(true)
            dispatch(clearError())
          }}
        >
          {t('Retry')}
        </button>
      </div>
    </>
  )
}
