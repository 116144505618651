import {cloneElement, useEffect, useState} from 'react'
import {useField} from 'formik'
import {classNames} from '../utils/classNames'
import Select from 'react-select'
import {
  ExclamationCircleIcon,
  InboxIcon,
  PencilAltIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import {Modal, ModalContentsBase, ModalOpenButton} from './Modal'
import {Switch} from '@headlessui/react'
import {useDispatch} from 'react-redux'
import SpinnerSVG from '../assets/Spinner.svg'
import ErrorOnView from './ErrorOnView'
import {useTranslation} from 'react-i18next'
import axios from '../services/api'
import {customStyles} from './formRendererRHF/formFieldStyle'

export const TextInput = ({label, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)

  const [field, meta] = useField(props)

  return (
    <>
      {label ? (
        <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700">
          {`${label}${props.required ? '*' : ''}`}
        </label>
      ) : null}
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          className={classNames(
            meta.touched && meta.error
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
              : 'border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500',
            'block w-full rounded-md sm:text-sm',
          )}
          autoComplete="off"
          data-testid={props.testid}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          </>
        ) : null}
      </div>
      {meta.touched && meta.error ? (
        <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
          {meta.error}
        </p>
      ) : null}
    </>
  )
}

export const TextInputCustomAttribute = ({
  label,
  deleteCustomAttribute,
  id,
  customAttributeKey,
  permission,
  ...props
}) => {
  const [field, meta] = useField(props)
  const dispatch = useDispatch()

  return (
    <>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:p-5">
        <label
          htmlFor={props.id || props.name}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 "
        >
          {label}
        </label>
        <div className="mt-1 mb-2">
          <div className="flex flex-col sm:gap-4">
            <input
              className={classNames(
                meta.touched && meta.error
                  ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                  : 'max-w-lg border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500',
                'block w-full rounded-md sm:max-w-xs sm:text-sm',
              )}
              autoComplete="off"
              {...field}
              {...props}
            />
          </div>
          {meta.touched && meta.error ? (
            <>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            </>
          ) : null}
        </div>
        {permission && (
          <div className="sm:col-span-1 sm:mt-2">
            <TrashIcon
              className="h-5 w-5 cursor-pointer text-red-500 hover:text-red-700"
              onClick={() => {
                dispatch(deleteCustomAttribute(id, customAttributeKey))
              }}
            />
          </div>
        )}
        {meta.touched && meta.error ? (
          <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
            {meta.error}
          </p>
        ) : null}
      </div>
    </>
  )
}

export const TextInputLabelOnLeft = ({label, disabled, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)

  const [field, meta] = useField(props)

  return (
    <>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:p-5">
        <label
          htmlFor={props.id || props.name}
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {label}
        </label>
        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <input
            className={classNames(
              meta.touched && meta.error
                ? 'block border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:max-w-xs'
                : disabled
                ? 'border-0 text-gray-900'
                : 'block max-w-lg border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:max-w-xs',
              'w-full rounded-md sm:text-sm',
            )}
            autoComplete="off"
            disabled={disabled}
            {...field}
            {...props}
          />
          {meta.touched && meta.error ? (
            <>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            </>
          ) : null}
        </div>
        {meta.touched && meta.error ? (
          <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
            {meta.error}
          </p>
        ) : null}
      </div>
    </>
  )
}

export const TextArea = ({label, rows, ...props}) => {
  const [field, meta] = useField(props)
  return (
    <>
      <label
        htmlFor={props.id || props.name}
        className="block pb-1 text-sm font-medium text-gray-700"
      >
        {`${label}${props.required ? '*' : ''}`}
      </label>
      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-lime-500 focus-within:ring-1 focus-within:ring-lime-500">
        <textarea
          rows={rows}
          name={props.name}
          id={props.id}
          className="block w-full resize-none border-0 p-2 py-3 focus:ring-0 sm:text-sm"
          placeholder={props.placeholder}
          {...field}
          {...props}
        />
        {/* {meta.touched && meta.error ? (
         <>
         <div className="absolute bottom-0 inset-x-0 flex items-center pointer-events-none">
         <ExclamationCircleIcon className=" h-5 w-5 text-red-500" aria-hidden="true" />
         </div>
         </>
         ) : null} */}
      </div>
      {meta.touched && meta.error ? (
        <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
          {meta.error}
        </p>
      ) : null}
    </>
  )
}

export const FileInput = (props) => {
  const {t} = useTranslation()
  return (
    <>
      <div className="mt-2 pt-2 pb-1 sm:col-span-2 sm:mt-0">
        <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
          <div className="space-y-1 text-center">
            <InboxIcon className="mx-auto h-12 w-12 rounded text-gray-400" />
            <div className="flex justify-center text-sm text-gray-600">
              <label
                htmlFor={props.id}
                className="relative cursor-pointer rounded-md bg-white font-medium text-lime-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-lime-500 focus-within:ring-offset-2 hover:text-lime-500"
              >
                <span>{t('uploadFile')}*</span>
                <input
                  onChange={(e) => props.onChange(e)}
                  id={props.id}
                  name={props.name}
                  type="file"
                  className="sr-only"
                  accept={props.accept}
                />
              </label>
            </div>
            <p className="text-xs text-gray-500">{props.fileTypeDescription}</p>
            <p className="text-xs text-gray-500">{props.fileName}</p>
          </div>
        </div>
      </div>
      {/* {meta.touched && meta.error ? (
       <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
       {meta.error}
       </p>
       ) : null} */}
    </>
  )
}

export const Checkbox = ({children, ...props}) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`

  const [field, meta] = useField({...props, type: 'checkbox'})

  return (
    <>
      <div className="relative m-1 flex items-start">
        <div className="flex h-5 items-center">
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-lime-600 accent-lime-500 focus:ring-lime-500"
            id={props.id}
            {...field}
            {...props}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={props.id} className="font-medium text-gray-700">
            {props.label}
          </label>
        </div>
      </div>
      {meta.touched && meta.error ? (
        <p className="mt-2 text-sm text-red-600" id={`${props.id || props.name}-error`}>
          {meta.error}
        </p>
      ) : null}
    </>
  )
}

export const CheckboxRaw = ({children, pending, label, id, name, ...props}) => {
  const {t} = useTranslation()
  return (
    <>
      {pending ? (
        <div className="text-md flex flex-col items-center justify-center p-3">
          <img alt="o" src={SpinnerSVG} className="mr-3 h-5 w-5 animate-spin text-lime-500" />
          {t('addAvatar.RunningBackgroundExtraction')}
        </div>
      ) : null}
      <div className="relative mt-1 flex items-start">
        <div className="flex h-5 items-center">
          <input
            type="checkbox"
            name={name}
            disabled={pending}
            className="h-4 w-4 rounded border-gray-300 text-lime-600 accent-lime-500 focus:ring-lime-500"
            id={id}
            {...props}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={id} className="font-medium text-gray-700">
            {label}
          </label>
        </div>
      </div>
    </>
  )
}

export const SelectInput = (props) => {
  const {name, options, error, setError, defaultValue} = props
  const [field, meta, helpers] = useField(name)
  const dispatch = useDispatch()

  const getValue = () => {
    if (options) {
      return props.isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find(
            (option) =>
              option.value === (!field.value && defaultValue ? defaultValue : field.value),
          )
    } else {
      return props.isMulti ? [] : ''
    }
  }

  return (
    <>
      <div className="flex w-full items-center justify-start">
        <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700">
          {`${props.label}${props.required ? '*' : ''}`}
        </label>
        {props.createNewEntry ? (
          <div className="m-1 flex items-center justify-start">
            <Modal>
              <ModalOpenButton>
                <div className="h-5 w-5">
                  <PlusCircleIcon className="text-sky-400 hover:text-sky-500" />
                </div>
              </ModalOpenButton>
              <ModalContentsBase title={props.addItemTitle}>{props.addItem}</ModalContentsBase>
            </Modal>
            {field.value !== '' && field.value !== undefined && !props.isMulti ? (
              <>
                <Modal>
                  <ModalOpenButton>
                    <div className="h-5 w-5">
                      <TrashIcon className="text-red-400 hover:text-red-500" />
                    </div>
                  </ModalOpenButton>
                  <ModalContentsBase title={props.deleteItemTitle}>
                    {cloneElement(props.deleteItem, {id: getValue().value})}
                  </ModalContentsBase>
                </Modal>
                <Modal>
                  <ModalOpenButton>
                    <div className="h-5 w-5">
                      <PencilAltIcon className="text-green-400 hover:text-green-500" />
                    </div>
                  </ModalOpenButton>
                  <ModalContentsBase title={props.editItemTitle}>
                    {cloneElement(props.editItem, {selectedItem: getValue()})}
                  </ModalContentsBase>
                </Modal>
              </>
            ) : null}
          </div>
        ) : null}
      </div>

      <div className="relative mt-1 rounded-md shadow-sm">
        {!error ? (
          <Select
            id={`select-${name}`}
            classNamePrefix={`select-${name}`}
            value={getValue()}
            onChange={(option) => {
              props.isMulti
                ? helpers.setValue(option.map((item) => item.value))
                : helpers.setValue(option.value)
            }}
            ref={props.innerRef}
            isDisabled={props.isDisabled}
            name={name}
            styles={{
              ...customStyles(!!error),
              multiValueRemove: (base) =>
                props.isDisabled
                  ? {
                      ...base,
                      display: 'none',
                    }
                  : {...base},
              dropdownIndicator: (base) =>
                props.isDisabled
                  ? {
                      ...base,
                      display: 'none',
                    }
                  : {...base},
            }}
            options={options}
            placeholder={props.placeholder}
            isMulti={!!props.isMulti}
            isClearable={!!props.isClearable}
            onBlur={(e) => {
              e.target.name = name
              field.onBlur(e)
            }}
          />
        ) : (
          <ErrorOnView error={error} setError={() => dispatch(setError())} />
        )}
      </div>
      {meta.touched && meta.error ? (
        <p className="mt-2 text-sm text-red-600" id={`${props.id || name}-error`}>
          {meta.error}
        </p>
      ) : null}
    </>
  )
}

export const SelectInputLabelOnLeft = (props) => {
  const {name, options, error, setError} = props
  const [field, meta, helpers] = useField(name)
  const dispatch = useDispatch()

  const getValue = () => {
    if (options) {
      return props.isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value)
    } else {
      return props.isMulti ? [] : ''
    }
  }

  return (
    <>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:p-5">
        <div className="flex w-full items-center justify-start">
          <label
            htmlFor={props.id || props.name}
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            {`${props.label}${props.required ? '*' : ''}`}
          </label>
          {props.createNewEntry ? (
            <div className="m-1 flex items-center justify-start">
              <Modal>
                <ModalOpenButton>
                  <div className="h-5 w-5">
                    <PlusCircleIcon className="text-sky-400 hover:text-sky-500" />
                  </div>
                </ModalOpenButton>
                <ModalContentsBase title={props.addItemTitle}>{props.addItem}</ModalContentsBase>
              </Modal>
              {field.value !== '' && field.value !== undefined && !props.isMulti ? (
                <>
                  <Modal>
                    <ModalOpenButton>
                      <div className="h-5 w-5">
                        <TrashIcon className="text-red-400 hover:text-red-500" />
                      </div>
                    </ModalOpenButton>
                    <ModalContentsBase title={props.deleteItemTitle}>
                      {cloneElement(props.deleteItem, {id: getValue().value})}
                    </ModalContentsBase>
                  </Modal>
                  <Modal>
                    <ModalOpenButton>
                      <div className="h-5 w-5">
                        <PencilAltIcon className="text-green-400 hover:text-green-500" />
                      </div>
                    </ModalOpenButton>
                    <ModalContentsBase title={props.editItemTitle}>
                      {cloneElement(props.editItem, {selectedItem: getValue()})}
                    </ModalContentsBase>
                  </Modal>
                </>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="mt-1 sm:col-span-2 sm:mt-0">
          {!error ? (
            <Select
              id={`select-${name}`}
              classNamePrefix={`select-${name}`}
              value={getValue()}
              onChange={(option) => {
                props.isMulti
                  ? helpers.setValue(option.map((item) => item.value))
                  : helpers.setValue(option.value)
              }}
              ref={props.innerRef}
              isDisabled={props.isDisabled}
              name={name}
              styles={customStyles(!!error)}
              options={options}
              placeholder={props.placeholder}
              isMulti={!!props.isMulti}
              isClearable={!!props.isClearable}
              onBlur={(e) => {
                e.target.name = name
                field.onBlur(e)
              }}
            />
          ) : (
            <ErrorOnView error={error} setError={() => dispatch(setError())} />
          )}
        </div>
        {meta.touched && meta.error ? (
          <p className="mt-2 text-sm text-red-600" id={`${props.id || name}-error`}>
            {meta.error}
          </p>
        ) : null}
      </div>
    </>
  )
}

export const AsyncSelectInput = (props) => {
  // Remaining feautures to be implemented pass onAdd, setFieldValue etc functions to addItem etc.

  //// Async Logic

  //set default query terms
  const [query, setQuery] = useState('')
  const [debouncedTerm, setDebouncedTerm] = useState(query)
  const [options, setOptions] = useState([])
  const [error, setError] = useState(null)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(query)
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [query])

  useEffect(() => {
    const fetchQuery = async (url, query) => {
      setError(false)
      setPending(true)
      try {
        const response = await axios.get(url + query)
        setOptions(response.data.results)
      } catch (error) {
        setError(error)
      }
      setPending(false)
    }
    fetchQuery(props.url, debouncedTerm)
  }, [debouncedTerm, props.url])
  /////////

  return (
    <>
      <div className="flex w-full items-center justify-start">
        <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
      </div>
      <div className="relative mt-1 rounded-md shadow-sm">
        {!error ? (
          <Select
            id={`select-${props.name}`}
            classNamePrefix={`select-${props.name}`}
            cacheOptions // saving the previous search results so deleting while typing won't trigger a new researh
            getOptionLabel={props.getOptionLabel} // to turn results as label and value pairs pass from parent
            getOptionValue={props.getOptionValue} // to turn results as label and value pairs pass frpm parent
            onInputChange={(query) => setQuery(query)}
            onChange={(v) => props.onChange(v)}
            styles={customStyles(!!error)}
            isLoading={pending} // TODO: check it again if it is doing it's job
            value={props.selected}
            options={options}
            placeholder={props.placeholder}
            isMulti={!!props.isMulti} // TODO: check again
            isClearable={!!props.isClearable}
          />
        ) : (
          <ErrorOnView error={error} setError={() => setError(null)} />
        )}
      </div>
    </>
  )
}

export const AsyncSelectInput2 = (props) => {
  // Remaining feautures to be implemented pass onAdd, setFieldValue etc functions to addItem etc.
  const {name} = props
  const [field, meta, helpers] = useField(name)

  //// Async Logic

  //set default query terms
  const [query, setQuery] = useState('')
  const [debouncedTerm, setDebouncedTerm] = useState(query)
  const [options, setOptions] = useState([])
  const [error, setError] = useState(null)
  const [pending, setPending] = useState(false)

  const selectOptions = options.map((item) => ({
    value: props.nestedOptions
      ? item[props.optionValue] // it is always id!
      : item[props.optionValue],
    label: props.nestedOptions
      ? item[props.nestedOptionLabel][props.optionLabel]
      : item[props.optionLabel],
  }))

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(query)
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [query])

  useEffect(() => {
    const fetchQuery = async (url, query) => {
      let tempurl = ''
      if (url.includes(`$temp#`)) {
        tempurl = url.replace(`$temp#`, query)
      } else {
        tempurl = url + query
      }
      setError(false)
      setPending(true)
      try {
        const response = await axios.get(tempurl)
        setOptions((prevState) =>
          prevState.concat(response.data.results).reduce((acc, current) => {
            const x = acc.find((item) => item.id === current.id)
            if (!x) {
              return acc.concat([current])
            } else {
              return acc
            }
          }, []),
        )
      } catch (error) {
        setError(error)
      }
      setPending(false)
    }
    fetchQuery(props.url, debouncedTerm)
  }, [debouncedTerm, props.url])
  /////////

  const getValue = () => {
    if (selectOptions) {
      return props.isMulti
        ? selectOptions.filter((option) => field.value.indexOf(option.value) >= 0)
        : selectOptions.find((option) => option.value === field.value)
    } else {
      return props.isMulti ? [] : ''
    }
  }

  return (
    <>
      <div className="flex w-full items-center justify-start">
        <label htmlFor={props.id || props.name} className="block text-sm font-medium text-gray-700">
          {`${props.label}${props.required ? '*' : ''}`}
        </label>
      </div>
      <div className="relative mt-1 rounded-md shadow-sm">
        {!error ? (
          <Select
            id={`select-${name}`}
            classNamePrefix={`select-${name}`}
            cacheOptions
            onInputChange={(query) => setQuery(query)}
            onChange={(option) => {
              props.isMulti
                ? helpers.setValue(option?.map((item) => item.value))
                : helpers.setValue(option.value)
            }}
            styles={customStyles(!!error)}
            value={getValue()}
            ref={props.innerRef}
            isLoading={pending} //TODO: check it again if it is doing it's job
            options={selectOptions}
            placeholder={props.placeholder}
            isMulti={!!props.isMulti} // TODO: check again
            isClearable={!!props.isClearable}
            onBlur={(e) => {
              e.target.name = name
              field.onBlur(e)
            }}
          />
        ) : (
          <ErrorOnView error={error} setError={() => setError()} />
        )}
      </div>
      {meta.touched && meta.error ? (
        <p className="mt-2 text-sm text-red-600" id={`${props.id || name}-error`}>
          {meta.error}
        </p>
      ) : null}
    </>
  )
}

export function SwitchWrapperFormik(props) {
  const {name} = props
  const [field, , helpers] = useField(name)
  return (
    <Switch.Group as="div" className="flex items-center p-2">
      <Switch.Label as="span" className="mr-3">
        <span className="text-sm font-medium text-gray-700">{props.label}</span>
      </Switch.Label>
      <Switch
        name={name}
        checked={field.value}
        onChange={() => helpers.setValue(!field.value)}
        className={classNames(
          field.value ? 'bg-lime-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            field.value ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

export function SwitchWrapper(props) {
  return (
    <Switch.Group as="div" className="flex items-center p-2">
      <Switch.Label as="span" className="mr-3">
        <span className="text-sm font-medium text-gray-900">{props.label1}</span>
      </Switch.Label>
      <Switch
        checked={props.checked}
        onChange={() => props.onChange()}
        className={classNames(
          props.checked ? 'bg-lime-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            props.checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900"> {props.label2} </span>
      </Switch.Label>
    </Switch.Group>
  )
}

export function SwitchWrapperAction(props) {
  return (
    <Switch.Group as="div" className="flex items-center pl-1">
      <Switch
        checked={props.checked}
        onChange={() => props.onChange()}
        className={classNames(
          props.checked ? 'bg-lime-600' : 'bg-red-200',
          'relative inline-flex  h-5 w-7 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            props.checked ? 'translate-x-2' : 'translate-x-0',
            'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
    </Switch.Group>
  )
}
