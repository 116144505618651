import axios from '../services/api'

// types

const GET_EXECUTIONS_PENDING = 'idfunctiondb/executions/GET_EXECUTIONS_PENDING'
const GET_EXECUTIONS_SUCCESS = 'idfunctiondb/executions/GET_EXECUTIONS_SUCCESS'
const GET_EXECUTIONS_ERROR = 'idfunctiondb/executions/GET_EXECUTIONS_ERROR'
const GET_EXECUTIONS_CLEAR_ERROR = 'idfunctiondb/executions/GET_EXECUTIONS_CLEAR_ERROR'

const initialState = {
  get_executions: {
    success: [],
    pending: false,
    error: null,
  },
}

// reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_EXECUTIONS_PENDING:
      return {
        ...state,
        get_executions: {
          ...state.get_executions,
          pending: true,
        },
      }
    case GET_EXECUTIONS_SUCCESS:
      return {
        ...state,
        get_executions: {
          ...state.get_executions,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_EXECUTIONS_ERROR:
      return {
        ...state,
        get_executions: {
          ...state.get_executions,
          error: action.payload,
          pending: false,
        },
      }
    case GET_EXECUTIONS_CLEAR_ERROR:
      return {
        ...state,
        get_executions: {
          ...state.get_executions,
          error: null,
        },
      }
    default:
      return state
  }
}

function getExecutionsPending() {
  return {type: GET_EXECUTIONS_PENDING}
}
function getExecutionsSuccess(payload) {
  return {type: GET_EXECUTIONS_SUCCESS, payload: payload}
}
function getExecutionsError(error) {
  return {type: GET_EXECUTIONS_ERROR, payload: error}
}
export function getExecutionsClearError() {
  return {type: GET_EXECUTIONS_CLEAR_ERROR}
}

export const getExecutions =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getExecutionsPending())
    axios
      .get(`/api/v1/executions?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getExecutionsSuccess(res.data))
      })
      .catch((error) => dispatch(getExecutionsError(error)))
  }
