import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'ColumnFilterDate',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'ColumnFilterDate',
    accessor: 'deleted_at',
    label: 'TableLabelsCommon.DeletedAt',
  },
  {
    header: 'ColumnFilter',
    accessor: 'name',
    label: 'TableLabelsCommon.Name',
  },
  {
    header: 'ColumnFilter',
    accessor: 'description',
    label: 'TableLabelsCommon.Description',
  },
  {
    header: 'resource.Group',
    accessor: 'is_group',
    cell: 'BooleanCell',
    label: 'resource.Group',
  },
  {
    header: 'resource.Dependencies',
    accessor: 'children_resources',
    cell: 'TreeGraphTableCell',
    width: 40,
    label: 'resource.Dependencies',
    noSort: true,
  },
  {
    header: 'Actions',
    label: 'Actions',
    width: 40,
    accessor: 'id',
    cell: 'ResourceIdentitiesChildrenTableActionsCell',
    noSort: true,
  },
]
export default columnConfig
