import Collapse from '../Collapse'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import FormAddInventoryType from '../forms/FormAddInventoryType'
import Table from '../table'
import {getInventoriesTypes, getInventoriesTypesClearError} from '../../stores/inventories'
import {useTableColumns} from '../../hooks/useTableColumns'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'

export default function InventoryTypeWrapper() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const inventoryTypes = useSelector((state) => state.inventories.get_inventories_types.success)
  const pending = useSelector((state) => state.inventories.get_inventories_types.pending)
  const error = useSelector((state) => state.inventories.get_inventories_types.error)
  const create_inventoryType_success = useSelector(
    (state) => state.inventories.create_inventory_type.success,
  )
  const update_inventoryType_success = useSelector(
    (state) => state.inventories.update_inventory_type.success,
  )
  const delete_inventoryType_success = useSelector(
    (state) => state.inventories.delete_inventory_type.success,
  )
  const create_and_set_inventory_type_photo_success = useSelector(
    (state) => state.inventories_photos.create_and_set_inventory_type_photo.success,
  )

  useEffect(() => {
    dispatch(getInventoriesTypes())
  }, [
    dispatch,
    create_inventoryType_success,
    update_inventoryType_success,
    delete_inventoryType_success,
    create_and_set_inventory_type_photo_success,
    error?.message,
  ])

  const columns = useTableColumns(TableId.INVENTORY_TYPE)
  return (
    <>
      <Collapse title={t('addInventoryTypeForm.AddInventoryTypeTitle')}>
        <FormAddInventoryType />
      </Collapse>
      <>
        <EditTableWrapper tableId={TableId.INVENTORY_TYPE} />
        <Table
          columns={columns}
          data={inventoryTypes}
          pending={pending}
          error={error}
          setError={getInventoriesTypesClearError}
        />
      </>
    </>
  )
}
