import Checkbox from './inputs/Checkbox'
import RadioGroup from './inputs/RadioGroup'
import TextArea from './inputs/TextArea'
import FileUpload from './inputs/FileUpload'
import SelectInput from './inputs/SelectInput'
import AsyncSelectInput from './inputs/AsyncSelectInput'
import DatePicker from './inputs/DatePicker'
import TextInput from './inputs/TextInput'
import {InputComponent} from './types'
import Text from '../Text'

const DynamicInput = (inputProps) => {
  switch (inputProps.component) {
    case InputComponent.CHECKBOX:
      return <Checkbox {...inputProps} key={inputProps.name} />
    case InputComponent.RADIO:
      return <RadioGroup {...inputProps} key={inputProps.name} />
    case InputComponent.TEXTAREA:
      return <TextArea {...inputProps} key={inputProps.name} />
    case InputComponent.FILE:
      return <FileUpload {...inputProps} key={inputProps.name} />
    case InputComponent.SELECT:
      return <SelectInput {...inputProps} key={inputProps.name} />
    case InputComponent.ASYNCREACTSELECT:
      return <AsyncSelectInput {...inputProps} key={inputProps.name} />
    case InputComponent.DATEPICKER:
      return <DatePicker {...inputProps} key={inputProps.name} />
    case InputComponent.TEXT_INPUT:
      return <TextInput {...inputProps} key={inputProps.name} />
    case InputComponent.STATIC_TEXT:
      return <Text {...inputProps} key={inputProps.name} />
    case InputComponent.CUSTOM:
      return inputProps.renderFn
    default:
      return null
  }
}
export default DynamicInput
