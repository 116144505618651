import {useEffect} from 'react'
import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import FormAddMeeting from '../components/forms/FormAddMeeting'
import {getVisitations, getVisitiationsClearError} from '../stores/visitations'
import Table from '../components/table'
import {useTableColumns} from '../hooks/useTableColumns'
import {TableId} from '../stores/types/tables'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'

export default function MeetingTableScreen() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const visitations = useSelector((state) => state.visitations.get_visitations.success)
  const pending = useSelector((state) => state.visitations.get_visitations.pending)
  const error = useSelector((state) => state.visitations.get_visitations.error)
  const create_meeting_success = useSelector(
    (state) => state.visitations.create_visitation_add_participants.success,
  )

  useEffect(() => {
    dispatch(getVisitations())
  }, [dispatch, create_meeting_success, error?.message])

  const columns = useTableColumns(TableId.MEETING)
  return (
    <>
      {permissions.createVisitation ? (
        <Collapse title={t('addVisitationForm.AddVisitationTitle')}>
          <FormAddMeeting />
        </Collapse>
      ) : null}
      {permissions.listVisitations ? (
        <>
          <EditTableWrapper tableId={TableId.MEETING} />
          <Table
            columns={columns}
            data={visitations}
            pending={pending}
            error={error}
            setError={getVisitiationsClearError}
          />
        </>
      ) : null}
    </>
  )
}
