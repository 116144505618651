import axios from '../services/api'

const GET_SUBSCRIPTIONS_PENDING = 'idfunctiondb/subscriptions/GET_SUBSCRIPTIONS_PENDING'
const GET_SUBSCRIPTIONS_SUCCESS = 'idfunctiondb/subscriptions/GET_SUBSCRIPTIONS_SUCCESS'
const GET_SUBSCRIPTIONS_ERROR = 'idfunctiondb/subscriptions/GET_SUBSCRIPTIONS_ERROR'
const GET_SUBSCRIPTIONS_CLEAR_ERROR = 'idfunctiondb/subscriptions/GET_SUBSCRIPTIONS_CLEAR_ERROR'

const GET_SUBSCRIPTION_SELECTORS_PENDING =
  'idfunctiondb/subscriptions/GET_SUBSCRIPTION_SELECTORS_PENDING'
const GET_SUBSCRIPTION_SELECTORS_SUCCESS =
  'idfunctiondb/subscriptions/GET_SUBSCRIPTION_SELECTORS_SUCCESS'
const GET_SUBSCRIPTION_SELECTORS_ERROR =
  'idfunctiondb/subscriptions/GET_SUBSCRIPTION_SELECTORS_ERROR'
const GET_SUBSCRIPTION_SELECTORS_CLEAR_ERROR =
  'idfunctiondb/subscriptions/GET_SUBSCRIPTION_SELECTORS_CLEAR_ERROR'

const POST_SUBSCRIPTION_PENDING = 'idfunctiondb/subscriptions/POST_SUBSCRIPTION_PENDING'
const POST_SUBSCRIPTION_SUCCESS = 'idfunctiondb/subscriptions/POST_SUBSCRIPTION_SUCCESS'
const POST_SUBSCRIPTION_ERROR = 'idfunctiondb/subscriptions/POST_SUBSCRIPTION_ERROR'
const POST_SUBSCRIPTION_CLEAR_ERROR = 'idfunctiondb/subscriptions/POST_SUBSCRIPTION_CLEAR_ERROR'

const UPDATE_SUBSCRIPTION_PENDING = 'idfunctiondb/subscriptions/UPDATE_SUBSCRIPTION_PENDING'
const UPDATE_SUBSCRIPTION_SUCCESS = 'idfunctiondb/subscriptions/UPDATE_SUBSCRIPTION_SUCCESS'
const UPDATE_SUBSCRIPTION_ERROR = 'idfunctiondb/subscriptions/UPDATE_SUBSCRIPTION_ERROR'
const UPDATE_SUBSCRIPTION_CLEAR_ERROR = 'idfunctiondb/subscriptions/UPDATE_SUBSCRIPTION_CLEAR_ERROR'

const initialState = {
  get_subscriptions: {
    success: [],
    pending: false,
    error: null,
  },
  get_subscription_selectors: {
    success: [],
    pending: false,
    error: null,
  },
  post_subscription: {
    success: null,
    pending: false,
    error: null,
  },
  update_subscription: {
    success: null,
    pending: false,
    error: null,
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_PENDING:
      return {
        ...state,
        get_subscriptions: {
          ...state.get_subscriptions,
          pending: true,
        },
      }
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        get_subscriptions: {
          ...state.get_subscriptions,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        get_subscriptions: {
          ...state.get_subscriptions,
          error: action.payload,
          pending: false,
        },
      }
    case GET_SUBSCRIPTIONS_CLEAR_ERROR:
      return {
        ...state,
        get_subscriptions: {
          ...state.get_subscriptions,
          error: null,
        },
      }
    case GET_SUBSCRIPTION_SELECTORS_PENDING:
      return {
        ...state,
        get_subscription_selectors: {
          ...state.get_subscription_selectors,
          pending: true,
        },
      }
    case GET_SUBSCRIPTION_SELECTORS_SUCCESS:
      return {
        ...state,
        get_subscription_selectors: {
          ...state.get_subscription_selectors,
          success: action.payload.results,
          pending: false,
        },
      }
    case GET_SUBSCRIPTION_SELECTORS_ERROR:
      return {
        ...state,
        get_subscription_selectors: {
          ...state.get_subscription_selectors,
          error: action.payload,
          pending: false,
        },
      }
    case GET_SUBSCRIPTION_SELECTORS_CLEAR_ERROR:
      return {
        ...state,
        get_subscription_selectors: {
          ...state.get_subscription_selectors,
          error: null,
        },
      }
    case POST_SUBSCRIPTION_PENDING:
      return {
        ...state,
        post_subscription: {
          ...state.post_subscription,
          pending: true,
        },
      }
    case POST_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        post_subscription: {
          ...state.post_subscription,
          success: action.payload,
          pending: false,
        },
      }
    case POST_SUBSCRIPTION_ERROR:
      return {
        ...state,
        post_subscription: {
          ...state.post_subscription,
          error: action.payload,
          pending: false,
        },
      }
    case POST_SUBSCRIPTION_CLEAR_ERROR:
      return {
        ...state,
        post_subscription: {
          ...state.post_subscription,
          error: null,
        },
      }
    case UPDATE_SUBSCRIPTION_PENDING:
      return {
        ...state,
        update_subscription: {
          ...state.update_subscription,
          pending: true,
        },
      }
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        update_subscription: {
          ...state.update_subscription,
          success: action.payload,
          pending: false,
        },
      }
    case UPDATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        update_subscription: {
          ...state.update_subscription,
          error: action.payload,
          pending: false,
        },
      }
    case UPDATE_SUBSCRIPTION_CLEAR_ERROR:
      return {
        ...state,
        update_subscription: {
          ...state.update_subscription,
          error: null,
        },
      }
    default:
      return state
  }
}

function getSubscriptionsPending() {
  return {type: GET_SUBSCRIPTIONS_PENDING}
}
function getSubscriptionsSuccess(payload) {
  return {type: GET_SUBSCRIPTIONS_SUCCESS, payload: payload}
}
function getSubscriptionsError(error) {
  return {type: GET_SUBSCRIPTIONS_ERROR, payload: error}
}
export function getSubscriptionsClearError() {
  return {type: GET_SUBSCRIPTIONS_CLEAR_ERROR}
}

function getSubscriptionSelectorsPending() {
  return {type: GET_SUBSCRIPTION_SELECTORS_PENDING}
}
function getSubscriptionSelectorsSuccess(payload) {
  return {type: GET_SUBSCRIPTION_SELECTORS_SUCCESS, payload: payload}
}
function getSubscriptionSelectorsError(error) {
  return {type: GET_SUBSCRIPTION_SELECTORS_ERROR, payload: error}
}
export function getSubscriptionSelectorsClearError() {
  return {type: GET_SUBSCRIPTION_SELECTORS_CLEAR_ERROR}
}

function updateSubscriptionPending() {
  return {type: UPDATE_SUBSCRIPTION_PENDING}
}
function updateSubscriptionSuccess(payload) {
  return {type: UPDATE_SUBSCRIPTION_SUCCESS, payload: payload}
}
function updateSubscriptionError(error) {
  return {type: UPDATE_SUBSCRIPTION_ERROR, payload: error}
}
export function updateSubscriptionClearError() {
  return {type: UPDATE_SUBSCRIPTION_CLEAR_ERROR}
}

function postSubscriptionPending() {
  return {type: POST_SUBSCRIPTION_PENDING}
}
function postSubscriptionSuccess(payload) {
  return {type: POST_SUBSCRIPTION_SUCCESS, payload: payload}
}
function postSubscriptionError(error) {
  return {type: POST_SUBSCRIPTION_ERROR, payload: error}
}
export function postSubscriptionClearError() {
  return {type: POST_SUBSCRIPTION_CLEAR_ERROR}
}

export const getSubscriptions =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getSubscriptionsPending())
    axios
      .get(`/api/v1/subscriptions?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getSubscriptionsSuccess(res.data))
      })
      .catch((error) => dispatch(getSubscriptionsError(error)))
  }

export const getSubscriptionSelectors =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getSubscriptionSelectorsPending())
    axios
      .get(`/api/v1/subscriptions/subscription_selectors?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getSubscriptionSelectorsSuccess(res.data))
      })
      .catch((error) => dispatch(getSubscriptionSelectorsError(error)))
  }

export const postSubscription = (values) => (dispatch) => {
  dispatch(postSubscriptionPending())
  axios
    .post(`/api/v1/subscriptions`, values)
    .then((res) => {
      dispatch(postSubscriptionSuccess(res.data))
    })
    .catch((error) => dispatch(postSubscriptionError(error)))
}

export const updateSubscription = (id, values) => (dispatch) => {
  dispatch(updateSubscriptionPending())
  axios
    .put(`/api/v1/subscriptions/${id}`, values)
    .then((res) => {
      dispatch(updateSubscriptionSuccess(res.data))
    })
    .catch((error) => dispatch(updateSubscriptionError(error)))
}
