import {useEffect} from 'react'
import CardImages from './CardImages'
import IdentityNavigationArrows from './IdentityNavigationArrows'
import CardFooterActions from './CardFooterActions'
import {useSelector, useDispatch} from 'react-redux'
import {getSelectedPrinter} from '../stores/printers'

export default function CardHeader({card, cardListPermission, cardMeta}) {
  const dispatch = useDispatch()

  const selected_printer = useSelector((state) => state.printers.get_selected_printer.success)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)

  useEffect(() => {
    dispatch(getSelectedPrinter(userID))
  }, [dispatch, userID])
  return (
    <>
      <div className="flex justify-between">
        <div>
          <CardImages card={card} />
        </div>
        {cardListPermission ? (
          <div className="flex pt-3">
            <IdentityNavigationArrows
              next={cardMeta?.next}
              previous={cardMeta?.previous}
              navTo="/cards"
            />
          </div>
        ) : null}
      </div>
      <CardFooterActions card={card} selected_printer={selected_printer} />
    </>
  )
}
