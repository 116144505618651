import axios from '../services/api'
import i18n from '../i18n'

import {splitToChunks, series} from '../utils/visitor'

// Types

const SET_NOTIFICATION_PENDING = 'idfunctiondb/notifications/SET_NOTIFICATION_PENDING'
const SET_NOTIFICATION_SUCCESS = 'idfunctiondb/notifications/SET_NOTIFICATION_SUCCESS'
const SET_NOTIFICATION_ERROR = 'idfunctiondb/notifications/SET_NOTIFICATION_ERROR'

const CREATE_NOTIFICATION_PENDING = 'idfunctiondb/notifications/CREATE_NOTIFICATION_PENDING'
const CREATE_NOTIFICATION_SUCCESS = 'idfunctiondb/notifications/CREATE_NOTIFICATION_SUCCESS'
const CREATE_NOTIFICATION_ERROR = 'idfunctiondb/notifications/CREATE_NOTIFICATION_ERROR'

const GET_USER_NOTIFICATIONS_PENDING = 'idfunctiondb/notifications/GET_USER_NOTIFICATIONS_PENDING'
const GET_USER_NOTIFICATIONS_SUCCESS = 'idfunctiondb/notifications/GET_USER_NOTIFICATIONS_SUCCESS'
const GET_USER_NOTIFICATIONS_ERROR = 'idfunctiondb/notifications/GET_USER_NOTIFICATIONS_ERROR'

const READ_ALL_NOTIFICATIONS_PENDING = 'idfunctiondb/notifications/READ_ALL_NOTIFICATIONS_PENDING'
const READ_ALL_NOTIFICATIONS_SUCCESS = 'idfunctiondb/notifications/READ_ALL_NOTIFICATIONS_SUCCESS'
const READ_ALL_NOTIFICATIONS_ERROR = 'idfunctiondb/notifications/READ_ALL_NOTIFICATIONS_ERROR'

const CREATE_NOTIFICATION_ON_CLIENT = 'idfunctiondb/notifications/CREATE_NOTIFICATION_ON_CLIENT'
const CLEAR_NOTIFICATION_ON_CLIENT = 'idfunctiondb/notifications/CLEAR_NOTIFICATION_ON_CLIENT'

const initialState = {
  set_notification: {
    success: null,
    pending: false,
    error: null,
  },

  create_notification: {
    success: null,
    success_info: {
      code: null,
      message: null,
    },
    pending: false,
    error: null,
  },

  get_user_notifications: {
    success: [],
    pending: false,
    error: null,
  },

  read_all_notifications: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NOTIFICATION_PENDING:
      return {
        ...state,
        set_notification: {
          ...state.set_notification,
          pending: true,
        },
      }
    case SET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        set_notification: {
          ...state.set_notification,
          success: action.payload,
          pending: false,
        },
      }
    case SET_NOTIFICATION_ERROR:
      return {
        ...state,
        set_notification: {
          ...state.set_notification,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_NOTIFICATION_PENDING:
      return {
        ...state,
        create_notification: {
          ...state.create_notification,
          pending: true,
        },
      }
    case CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        create_notification: {
          ...state.create_notification,
          success: action.payload,
          success_info: {
            code: action.payload.status,
            message: action.payload.data.message,
          },
          pending: false,
        },
      }
    case CREATE_NOTIFICATION_ERROR:
      return {
        ...state,
        create_notification: {
          ...state.create_notification,
          error: action.payload,
          pending: false,
        },
      }
    case GET_USER_NOTIFICATIONS_PENDING:
      return {
        ...state,
        get_user_notifications: {
          ...state.get_user_notifications,
          pending: true,
        },
      }
    case GET_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        get_user_notifications: {
          ...state.get_user_notifications,
          success: action.payload,
          pending: false,
        },
      }
    case GET_USER_NOTIFICATIONS_ERROR:
      return {
        ...state,
        get_user_notifications: {
          ...state.get_user_notifications,
          error: action.payload,
          pending: false,
        },
      }
    case READ_ALL_NOTIFICATIONS_PENDING:
      return {
        ...state,
        read_all_notifications: {
          ...state.read_all_notifications,
          pending: true,
        },
      }
    case READ_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        read_all_notifications: {
          ...state.read_all_notifications,
          success: action.payload,
          pending: false,
        },
      }
    case READ_ALL_NOTIFICATIONS_ERROR:
      return {
        ...state,
        read_all_notifications: {
          ...state.read_all_notifications,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_NOTIFICATION_ON_CLIENT:
      return {
        ...state,
        create_notification: {
          ...state.create_notification,
          success_info: {
            code: action.payload.code,
            message: action.payload.message,
          },
        },
      }
    case CLEAR_NOTIFICATION_ON_CLIENT:
      return {
        ...state,
        create_notification: {
          ...state.create_notification,
          success_info: {
            code: null,
            message: null,
          },
        },
      }
    default:
      return state
  }
}

// Actions

function setNotificationPending() {
  return {type: SET_NOTIFICATION_PENDING}
}
function setNotificationSuccess(payload) {
  return {type: SET_NOTIFICATION_SUCCESS, payload: payload}
}
function setNotificationError(error) {
  return {type: SET_NOTIFICATION_ERROR, payload: error}
}

function createNotificationPending() {
  return {type: CREATE_NOTIFICATION_PENDING}
}
function createNotificationSuccess(payload) {
  return {type: CREATE_NOTIFICATION_SUCCESS, payload: payload}
}
function createNotificationError(error) {
  return {type: CREATE_NOTIFICATION_ERROR, payload: error}
}

function getUserNotificationsPending() {
  return {type: GET_USER_NOTIFICATIONS_PENDING}
}
function getUserNotificationsSuccess(payload) {
  return {type: GET_USER_NOTIFICATIONS_SUCCESS, payload: payload}
}
function getUserNotificationsError(error) {
  return {type: GET_USER_NOTIFICATIONS_ERROR, payload: error}
}

function readAllNotificationsPending() {
  return {type: READ_ALL_NOTIFICATIONS_PENDING}
}
function readAllNotificationsSuccess(payload) {
  return {type: READ_ALL_NOTIFICATIONS_SUCCESS, payload: payload}
}
function readAllNotificationsError(error) {
  return {type: READ_ALL_NOTIFICATIONS_ERROR, payload: error}
}

function createNotificationOnClientSuccess(payload) {
  return {type: CREATE_NOTIFICATION_ON_CLIENT, payload: payload}
}

function clearNotificationOnClientSuccess(payload) {
  return {type: CLEAR_NOTIFICATION_ON_CLIENT, payload: payload}
}

// Operations

// sets the notification to be read
export const setNotification = (id, values) => (dispatch) => {
  dispatch(setNotificationPending())
  return axios
    .put(`/api/v1/notifications/${id}`, values)
    .then((res) => {
      dispatch(setNotificationSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(setNotificationError(error)))
}

// stores the event to the backend
export const createNotification = (id, values) => (dispatch) => {
  dispatch(createNotificationPending())
  return axios
    .post(`/api/v1/users/${id}/notifications`, values)
    .then((res) => {
      dispatch(createNotificationSuccess(res))
      return res.data
    })
    .catch((error) => dispatch(createNotificationError(error)))
}
// creates a notification just on the client side for success events
export const createNotificationOnClient = (code, translationKey) => (dispatch) => {
  dispatch(
    createNotificationOnClientSuccess({
      code: code,
      message: i18n.t(translationKey),
    }),
  )
}
// clears the notification just on the client side for success events
export const clearNotificationOnClient = () => (dispatch) => {
  dispatch(clearNotificationOnClientSuccess())
}
// fills the dropdown at Main and Basic Layout
export const getUserNotifications = (id) => (dispatch) => {
  dispatch(getUserNotificationsPending())
  return axios
    .get(`/api/v1/users/${id}/notifications`)
    .then((res) => {
      dispatch(getUserNotificationsSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getUserNotificationsError(error)))
}
// after you open the dropdown it is called at Main and Basic Layout
export const readAllNotifications = (id) => (dispatch) => {
  dispatch(readAllNotificationsPending())
  dispatch(getUserNotifications(id))
    .then((res) => {
      const chunkLength = res.length > 100 ? 10 : Math.ceil(res.length / 10)
      let notificationResult = []
      const notificationChunks = splitToChunks(res, chunkLength)
      return series(notificationChunks, (chunk) => {
        const notificationPromises = chunk.map((item) => {
          if (!item.seen) {
            dispatch(setNotification(item.id, {seen: true})).catch((error) => {
              dispatch(readAllNotificationsError(error))
            })
          }
          return null
        })
        return Promise.all(notificationPromises).then(
          (res) => (notificationResult = notificationResult.concat(res)),
        )
      }).then(() => {
        dispatch(readAllNotificationsSuccess(notificationResult))
      })
    })
    .catch((error) => dispatch(readAllNotificationsError(error)))
}
