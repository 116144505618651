import {Formik, Form} from 'formik'
import {useState} from 'react'
import {TextInputLabelOnLeft} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {PencilAltIcon} from '@heroicons/react/solid'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'
import {setPerson, setPersonClearError} from '../../stores/persons'
import {iso8061UTC} from '../../utils/datetime'

export default function FormEditEmployeePrivate({data, identityUpdatePermission}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const [editable, setEditable] = useState(true)

  const pending = useSelector((state) => state.persons.set_person.pending)
  const error = useSelector((state) => state.persons.set_person.error)

  const initialValues = {
    street: data?.person.street || '',
    city: data?.person.city || '',
    zip: data?.person.zip || '',
    email: data?.person.email || '',
    phone: data?.person.phone || '',
    birthday: new Date(data?.person.birthday).toISOString().split('T')[0],
  }
  return (
    <>
      {identityUpdatePermission && (
        <div className="m-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <PencilAltIcon className="h-6 w-6 text-lime-500" onClick={() => setEditable(!editable)} />
        </div>
      )}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          values['birthday'] = iso8061UTC(values.birthday)
          dispatch(setPerson(data.person.id, values))
          actions.resetForm()
          setEditable(!editable)
        }}
      >
        <Form>
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePrivateInfo.Address')}
            type="text"
            name="street"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePrivateInfo.City')}
            type="text"
            name="city"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePrivateInfo.Zip')}
            type="text"
            name="zip"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePrivateInfo.Email')}
            type="text"
            name="email"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePrivateInfo.Phone')}
            type="text"
            name="phone"
          />
          <TextInputLabelOnLeft
            disabled={editable}
            label={t('employeePrivateInfo.Birthday')}
            type="date"
            name="birthday"
          />
          {identityUpdatePermission && (
            <FormikSaveButton
              type="submit"
              testid="form-edit-employee-private-submit"
              text={t('addEmployee.AddEmployee')}
              primary
              pending={pending}
              error={error}
              setError={() => dispatch(setPersonClearError())}
              disabled={editable}
            />
          )}
        </Form>
      </Formik>
    </>
  )
}
