import axios from '../services/api'
import {persistor} from '../index'
import {setTokenData, removeTokenData, setCodeVerifier} from '../services/TokenService'
import {getLogo} from './static_assets'
import pkceChallenge from 'pkce-challenge'
import {
  clearLanguagePreference,
  clearTimezonePreference,
  setLanguagePreference,
  setTimezonePreference,
  getPrevLocation,
} from '../services/setPreferences'
import {updateLocation} from './configs'

// Types
export const LOGIN_PENDING = 'idfunctiondb/auth/LOGIN_PENDING'
export const LOGIN_SUCCESS = 'idfunctiondb/auth/LOGIN_SUCCESS'
export const LOGIN_ERROR = 'idfunctiondb/auth/LOGIN_ERROR'
export const LOGIN_CLEAR_ERROR = 'idfunctiondb/auth/LOGIN_CLEAR_ERROR'

export const LOGIN_SSO_PENDING = 'idfunctiondb/auth/LOGIN_SSO_PENDING'
export const LOGIN_SSO_SUCCESS = 'idfunctiondb/auth/LOGIN_SSO_SUCCESS'
export const LOGIN_SSO_ERROR = 'idfunctiondb/auth/LOGIN_SSO_ERROR'

// fetchMe Types
export const FETCH_ME_PENDING = 'idfunctiondb/auth/FETCH_ME_PENDING'
export const FETCH_ME_SUCCESS = 'idfunctiondb/auth/FETCH_ME_SUCCESS'
export const FETCH_ME_ERROR = 'idfunctiondb/auth/FETCH_ME_ERROR'
export const FETCH_ME_CLEAR_ERROR = 'idfunctiondb/auth/FETCH_ME_CLEAR_ERROR'

export const FETCH_BEARER_TOKEN_PENDING = 'idfunctiondb/auth/FETCH_BEARER_TOKEN_PENDING'
export const FETCH_BEARER_TOKEN_SUCCESS = 'idfunctiondb/auth/FETCH_BEARER_TOKEN_SUCCESS'
export const FETCH_BEARER_TOKEN_ERROR = 'idfunctiondb/auth/FETCH_BEARER_TOKEN_ERROR'

export const USER_LOGOUT_PENDING = 'idfunctiondb/auth/USER_LOGOUT_PENDING'
export const USER_LOGOUT_SUCCESS = 'idfunctiondb/auth/USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_ERROR = 'idfunctiondb/auth/USER_LOGOUT_ERROR'

export const REFRESH_TOKEN_DATA = 'idfunctiondb/auth/REFRESH_TOKEN_DATA'
export const CLEANUP_AFTER_LOGOUT = 'idfunctiondb/auth/CLEANUP_AFTER_LOGOUT'

// Initial
const initialState = {
  authenticationSuccess: false, // login + fetchBearerToken success
  authenticationPending: false,
  authenticationError: null,
  fetchUserSuccess: null,
  fetchUserPending: false,
  fetchUserError: null,
  logoutSuccess: false,
  logoutPending: false,
  logoutError: null,
  tokenData: null,
  loginPending: null,
  loginSuccess: null,
  loginError: null,
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_PENDING:
      return {
        ...state,
        loginPending: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginPending: false,
        loginSuccess: action.payload,
        logoutSuccess: false,
      }
    case LOGIN_ERROR:
      return {
        ...state,
        loginError: action.payload,
        loginPending: false,
      }
    case LOGIN_CLEAR_ERROR:
      return {
        ...state,
        loginError: null,
      }
    case LOGIN_SSO_PENDING:
      return {
        ...state,
        authenticationPending: true,
      }
    case LOGIN_SSO_SUCCESS:
      return {
        ...state,
        authenticationSuccess: true,
        authenticationPending: false,
        tokenData: action.payload,
      }
    case LOGIN_SSO_ERROR:
      return {
        ...state,
        authenticationError: action.payload,
        authenticationSuccess: false,
        authenticationPending: false,
      }
    case USER_LOGOUT_PENDING:
      return {
        ...state,
        logoutPending: true,
        logoutSuccess: false,
      }
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        logoutSuccess: true,
        logoutPending: false,
        authenticationSuccess: false,
        tokenData: null,
      }
    case USER_LOGOUT_ERROR:
      return {
        ...state,
        logoutPending: false,
        logoutError: action.payload,
      }
    case FETCH_ME_SUCCESS:
      return {
        ...state,
        fetchUserSuccess: action.payload,
        fetchUserPending: false,
      }
    case FETCH_ME_PENDING:
      return {
        ...state,
        fetchUserPending: true,
      }
    case FETCH_ME_ERROR:
      return {
        ...state,
        fetchUserPending: false,
        fetchUserError: action.payload,
      }
    case FETCH_ME_CLEAR_ERROR:
      return {
        ...state,
        fetchUserPending: false,
        fetchUserError: null,
      }
    case FETCH_BEARER_TOKEN_SUCCESS:
      return {
        ...state,
        authenticationSuccess: true,
        logoutSuccess: false,
        authenticationPending: false,
        tokenData: action.payload,
      }
    case FETCH_BEARER_TOKEN_PENDING:
      return {
        ...state,
        authenticationPending: true,
      }
    case FETCH_BEARER_TOKEN_ERROR:
      return {
        ...state,
        authenticationError: action.payload,
        authenticationSuccess: false,
        authenticationPending: false,
      }

    case REFRESH_TOKEN_DATA:
      return {
        ...state,
        tokenData: action.payload,
        logoutSuccess: false,
      }
    default:
      return state
  }
}

// Actions
function loginPending() {
  return {type: LOGIN_PENDING}
}

export function loginSuccess(payload) {
  return {type: LOGIN_SUCCESS, payload: payload}
}

function loginError(err) {
  return {type: LOGIN_ERROR, payload: err}
}

export function loginClearError() {
  return {type: LOGIN_CLEAR_ERROR}
}

function fetchBearerTokenPending() {
  return {type: FETCH_BEARER_TOKEN_PENDING}
}

export function fetchBearerTokenSuccess(payload) {
  return {type: FETCH_BEARER_TOKEN_SUCCESS, payload: payload}
}

function fetchBearerTokenError(err) {
  return {type: FETCH_BEARER_TOKEN_ERROR, payload: err}
}

function loginSSOPending() {
  return {type: LOGIN_SSO_PENDING}
}

function loginSSOSuccess(payload) {
  return {type: LOGIN_SSO_SUCCESS, payload: payload}
}

function loginSSOError(err) {
  return {type: LOGIN_SSO_ERROR, payload: err}
}

function logoutUserPending() {
  return {type: USER_LOGOUT_PENDING}
}

function logoutUserSuccess() {
  return {type: USER_LOGOUT_SUCCESS}
}

export function cleanupAfterLogout() {
  return {type: CLEANUP_AFTER_LOGOUT}
}

function logoutUserError(err) {
  return {type: USER_LOGOUT_ERROR, payload: err}
}

function fetchMePending() {
  return {type: FETCH_ME_PENDING}
}

function fetchMeSuccess(payload) {
  return {type: FETCH_ME_SUCCESS, payload: payload}
}

function fetchMeError(err) {
  return {type: FETCH_ME_ERROR, payload: err}
}

export function fetchMeClearError() {
  return {type: FETCH_ME_CLEAR_ERROR}
}

export const refreshTokenData = (tokenData) => (dispatch) => {
  dispatch({
    type: REFRESH_TOKEN_DATA,
    payload: tokenData,
  })
}

export const fetchMe = () => (dispatch) => {
  dispatch(fetchMePending())
  return axios
    .get('/api/v1/auth/me')
    .then((res) => {
      dispatch(fetchMeSuccess(res.data))
      setLanguagePreference(res.data.preferred_language)
      setTimezonePreference(res.data.preferred_timezone)
      return res.data
    })
    .catch((error) => {
      dispatch(fetchMeError(error))
    })
}

// Operations

export const login = (username, password) => (dispatch) => {
  dispatch(loginPending())
  let PKCE = pkceChallenge()
  setCodeVerifier(PKCE.code_verifier)
  let dataToPost = {
    username: username,
    password: password,
    response_type: 'code',
    grant_type: 'authorization_code',
    client_id: '0BwXH4BTGIUuJZV2e0M5bVHRzthDRH22DDr5',
    scope: 'openid profile',
    code_challenge: PKCE.code_challenge,
    code_challenge_method: 'S256',
  }
  axios
    .post('/api/v1/auth/login', dataToPost, {maxRedirects: 0})
    .then((res) => {
      let code = new URLSearchParams(res.request?.responseURL.split('?')[1]).get('code')
      if (code) {
        dispatch(updateLocation(`/?code=${code}`))
      }

      dispatch(loginSuccess(res.status))
      return res
    })
    .catch((error) => {
      dispatch(loginError(error))
    })
}

export const loginSSO = (auth) => (dispatch) => {
  dispatch(loginSSOPending())
  let PKCE = pkceChallenge()
  setCodeVerifier(PKCE.code_verifier)
  return axios
    .post('/api/v1/auth/login', {
      auth: auth,
      response_type: 'code',
      grant_type: 'authorization_code',
      client_id: '0BwXH4BTGIUuJZV2e0M5bVHRzthDRH22DDr5',
      scope: 'openid profile',
      code_challenge: PKCE.code_challenge,
      code_challenge_method: 'S256',
    })
    .then((res) => {
      let code = new URLSearchParams(res.request?.responseURL.split('?')[1]).get('code')
      dispatch(updateLocation(`/?code=${code}`))

      dispatch(loginSSOSuccess(res.status))
    })
    .catch((error) => {
      dispatch(loginSSOError(error))
      return error
    })
}

export const fetchBearerToken = (tokenData) => (dispatch) => {
  dispatch(fetchBearerTokenPending())
  return axios.post('/api/v1/oauth/token', tokenData).then((res) => {
    function setTokenAsync() {
      return Promise.resolve(setTokenData(res.data))
    }

    setTokenAsync()
      .then(() => {
        dispatch(fetchBearerTokenSuccess(res.data))
        // redirecting user from Appunauthenticated routes.

        dispatch(updateLocation(getPrevLocation() || '/'))
      })
      .then(() => {
        dispatch(fetchMe())
      })
      .then(() => {
        dispatch(getLogo())
      })
      .catch((error) => {
        dispatch(fetchBearerTokenError(error))
      })
  })
}

export const logout = () => (dispatch) => {
  dispatch(logoutUserPending())
  return axios
    .post('/api/v1/oauth/revoke')
    .then(() => {
      dispatch(logoutUserSuccess())
      removeTokenData()

      // if user logs out timezone and language preference gets cleared
      // because I am not checking if the user who logged in is the same user as the previous one
      // Max logged in and his language preference was french and timezone is Moscow
      // If I keep the settings;
      // when Martin logs in he will see the app in French and in Moscow timezone. Because there is no
      // difference between Martin and Max with respect to user info I save for these preferences.
      clearTimezonePreference()
      clearLanguagePreference()
    })
    .catch((error) => {
      dispatch(logoutUserError(error))
      persistor.purge()
    })
}
