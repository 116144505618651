import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'SelectCheckbox',
    cell: 'SelectCheckboxCell',
    label: 'setAvatarForm.Select',
    id: 'selection.Select',
    width: 25,
    accessor: 'identity.id',
    noSort: true,
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.city',
    label: 'TableLabelsCommon.CompanyCity',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.country',
    label: 'TableLabelsCommon.CompanyCountry',
  },
  {
    header: 'SelectColumnFilterCompanies',
    accessor: 'company.name',
    label: 'employeeList.Company',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.phone',
    label: 'TableLabelsCommon.CompanyPhone',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.state',
    label: 'TableLabelsCommon.CompanyState',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.street',
    label: 'TableLabelsCommon.CompanyStreet',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.type',
    label: 'TableLabelsCommon.CompanyType',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.uni_location_code',
    label: 'TableLabelsCommon.CompanyUniLocationCode',
  },
  {
    header: 'ColumnFilter',
    accessor: 'company.zip',
    label: 'TableLabelsCommon.CompanyZip',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'created_at',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.CustomAttributes',
    accessor: 'custom_attributes',
    noSort: true,
    cell: 'CustomAttributesCell',
    label: 'TableLabelsCommon.CustomAttributes',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'deleted_at',
    label: 'TableLabelsCommon.DeletedAt',
  },
  {
    header: 'ColumnFilter',
    accessor: 'department',
    label: 'TableLabelsCommon.Department',
  },
  {
    header: 'ColumnFilter',
    accessor: 'email',
    label: 'TableLabelsCommon.Email',
  },
  {
    header: 'SelectColumnFilterLocations',
    accessor: 'location.description',
    label: 'personList.Domain',
    permissionsAccessor: 'listVisitorsLocations',
  },
  {
    header: 'ColumnFilter',
    accessor: 'mobile',
    label: 'TableLabelsCommon.Mobile',
  },
  {
    header: 'ColumnFilter',
    accessor: 'notes',
    label: 'TableLabelsCommon.Notes',
  },
  {
    header: 'ColumnFilter',
    accessor: 'person.first_name',
    label: 'TableLabelsCommon.FirstName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'person.full_name',
    cell: 'AvatarCell',
    label: 'TableLabelsCommon.FullName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'person.last_name',
    label: 'TableLabelsCommon.LastName',
  },
  {
    header: 'ColumnFilter',
    accessor: 'phone',
    label: 'TableLabelsCommon.Phone',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'valid_from',
    label: 'TableLabelsCommon.ValidFrom',
  },
  {
    header: 'ColumnFilterDate',
    cell: 'DateCell',
    accessor: 'valid_to',
    label: 'TableLabelsCommon.ValidTo',
  },
  {
    header: 'ColumnFilter',
    accessor: 'visitor_number',
    label: 'visitorList.VisitorNumber',
  },
  {
    header: 'SelectColumnFilterEmployeeState',
    accessor: 'visitor_state.id',
    cell: 'EditVisitorStatusCell',
    label: 'TableLabelsCommon.Status',
    noSort: true,
  },
  {
    header: 'employeeList.Actions',
    label: 'employeeList.Actions',
    accessor: 'id',
    width: 40,
    cell: 'VisitorActionCell',
    noSort: true,
  },
]
export default columnConfig
