import {Formik, Form} from 'formik'
import {TextInput, TextArea} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {DynamicButton} from '../Buttons'
import {useState} from 'react'
import axios from '../../services/api'
import {parseJSON} from '../../utils/helpers'
import {ErrorMessage} from '../formRendererRHF/ErrorMessage'

export default function FormFormBuilderSimple({
  jsonObject,
  setJsonObject,
  formId,
  formName,
  formDescription,
}) {
  const {t} = useTranslation()

  const [pending, setPending] = useState(false)
  const [error, setError] = useState(false)
  const [parseError, setParseError] = useState(false)

  const initialValues = {
    name: formName || '',
    description: formDescription || '',
    form_json: JSON.stringify(jsonObject) || '',
  }

  const validationSchema = Yup.object({
    // TODO: ADD validations
    //form_json: Yup.string().required(),
  })

  const handleSubmit = async (values, id) => {
    setError(false)
    setPending(true)
    try {
      await axios.put(`api/v1/forms/${id}`, values)
    } catch (error) {
      setError(error)
    }
    setPending(false)
  }

  const handlePreviewClick = (formJsonValue) => {
    const parsedValues = parseJSON(formJsonValue)
    if (parsedValues?.formTitle) {
      setJsonObject(parsedValues)
      setParseError(false)
    } else {
      setParseError(true)
    }
  }

  // TODO: translation

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, formId, actions)
        }}
      >
        {({values}) => (
          <Form>
            <TextInput label={t('name')} name="name" type="text" placeholder="" required />
            <TextInput
              label={t('description')}
              name="description"
              type="text"
              placeholder=""
              required
            />
            <TextArea label={t('form_json')} name="form_json" placeholder="" rows={10} required />
            <p className="my-2 text-sm text-red-600" id={'form_json'}>
              {t('formBuilder.FormJsonFormatMessage')}
            </p>
            <button
              type="button"
              className="focus:ring-offset-2shadow-sm mx-2 inline-flex cursor-pointer items-center rounded-md border border-transparent bg-lime-600 px-3 py-2 text-sm font-medium  leading-4 text-white hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-lime-500 disabled:opacity-50"
              onClick={() => handlePreviewClick(values.form_json)}
            >
              {t('formBuilder.FormPreview')}
            </button>
            {parseError && <ErrorMessage error={t('formBuilder.ParseError')} />}
            <DynamicButton
              type="submit"
              text={t('Submit')}
              primary
              pending={pending}
              error={error}
              setError={setError}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}
