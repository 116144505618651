import {
  PrinterIcon,
  ClipboardCheckIcon,
  ExclamationCircleIcon,
  BadgeCheckIcon,
  LockClosedIcon,
  XCircleIcon,
  ShieldCheckIcon,
} from '@heroicons/react/outline'
import {useTranslation} from 'react-i18next'
import SpinnerSVG from '../assets/Spinner.svg'

export const CardStateIcon = (state) => {
  switch (state) {
    case 'UNPRODUCED':
      return <PrinterIcon className="h-10 w-10 text-center text-blue-600" aria-hidden="true" /> //  blue
    case 'REQUESTED':
      return (
        <ClipboardCheckIcon className="h-10 w-10 text-center text-yellow-600" aria-hidden="true" />
      ) // yellow
    case 'PRODUCTION_FAILED':
      return (
        <ExclamationCircleIcon className="h-10 w-10 items-center text-red-600" aria-hidden="true" />
      ) // red
    case 'ACTIVE':
      return <BadgeCheckIcon className="h-10 w-10 items-center text-green-600" aria-hidden="true" /> // green
    case 'PRODUCED':
      return (
        <ShieldCheckIcon className="h-10 w-10 items-center text-green-600" aria-hidden="true" />
      ) // green
    case 'BLOCKED':
      return <LockClosedIcon className="h-10 w-10 items-center text-red-600" aria-hidden="true" /> // red
    case 'DELETED':
      return <XCircleIcon className="h-10 w-10 items-center text-red-600" aria-hidden="true" /> //
    case true:
      return (
        <div className="flex items-center">
          <img alt="o" src={SpinnerSVG} className="ml-5 mr-3 h-6 w-6 animate-spin" />
        </div>
      )
    default:
      return null
  }
}

export const CardStateTitle = (state) => {
  const {t} = useTranslation()
  switch (state) {
    case 'UNPRODUCED':
      return t('cardStatusTooltip.unproducedTooltip')
    case 'REQUESTED':
      return t('cardStatusTooltip.requestedTooltip')
    case 'PRODUCTION_FAILED':
      return 'Production Failed'
    case 'ACTIVE':
      return t('cardStatusTooltip.activeTooltip')
    case 'PRODUCED':
      return t('cardStatusTooltip.producedToolpit')
    case 'BLOCKED':
      return t('cardStatusTooltip.blockedTooltip')
    case 'DELETED':
      return t('cardStatusTooltip.destoryedTooltip') // not necessary the card will be deleted.
    case true:
      return t('submitting')
    default:
      return null
  }
}
