import {useEffect, useRef} from 'react'
import {createUser, createUserClearError} from '../../stores/users'
import {getRoles, getRolesClearError} from '../../stores/roles'
import {Formik, Form} from 'formik'
import {TextInput, SelectInput, Checkbox} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'
import * as Yup from 'yup'

export default function FormAddUser() {
  const dispatch = useDispatch()
  const roles = useSelector((state) => state.roles.get_roles.success)
  const rolesError = useSelector((state) => state.roles.get_roles.error)
  const create_user_pending = useSelector((state) => state.users.create_user.pending)
  const create_user_error = useSelector((state) => state.users.create_user.error)

  const {t} = useTranslation()

  useEffect(() => {
    dispatch(getRoles())
  }, [dispatch, rolesError?.message])
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('userManagement.Name')}),
    ),
    email: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('userManagement.Email')}),
    ),
    password: Yup.string()
      .required(t('yupError.ThisFieldIsRequired', {field: t('userManagement.Password')}))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t('login.PasswordRequirements'),
      ),
    active: Yup.boolean().required(
      t('yupError.ThisFieldIsRequired', {field: t('userManagement.Active')}),
    ),
    roles: Yup.array().min(1, 'A user must have at least one role'),
  })

  const initialValues = {
    name: '',
    email: '',
    password: '',
    active: true,
    roles: [],
  }

  let rolesSelectRef = useRef()
  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={(values) => {
          dispatch(createUser(values))
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <TextInput label={t('userInfo.Name')} required name="name" type="text" />
          <TextInput label={t('userInfo.Email')} required name="email" type="text" />
          <TextInput
            label={t('userManagement.Password')}
            required
            name="password"
            type="password"
          />
          <Checkbox name="active" label={t('userInfo.Status')} id="form-add-user-checkbox" />
          <SelectInput
            label={t('userManagement.Roles')}
            isMulti
            options={roles.map((role) => ({
              value: role.name,
              label: role.name,
            }))}
            name="roles"
            required
            innerRef={rolesSelectRef}
            error={rolesError}
            setError={getRolesClearError}
          />
          <FormikSaveButton
            type="submit"
            testid="form-add-user-submit"
            text={t('addEmployee.AddEmployee')}
            primary
            pending={create_user_pending}
            error={create_user_error}
            setError={() => dispatch(createUserClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
