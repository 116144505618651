import {useDispatch, useSelector} from 'react-redux'
import {uploadPrivacyPolicy, uploadPrivacyPolicyClearError} from '../../stores/static_assets'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import {useTranslation} from 'react-i18next'

const FormAddPrivacyPolicy = () => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const handleSubmit = (values) => {
    dispatch(uploadPrivacyPolicy(values['privacy_policy'].file, '/api/v1/privacy_policy'))
  }
  const {error, pending, success} = useSelector(
    (state) => state.static_assets.upload_privacy_policy,
  )

  const fields = [
    {
      label: t('uploadFile'),
      component: InputComponent.FILE,
      name: 'privacy_policy',
      validationType: YupValidationType.OBJECT,
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[4], t('yupError.FileType')],
        },
        {
          type: InputYupValidationsType.FILESIZE,
          params: [1000000, t('yupError.MaxFileSize')],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.FileRequired')],
        },
      ],
    },
  ]

  return (
    <DynamicRhfForm
      formConfig={{
        fields,
        name: 'form-privacy_policy',
        submitButtonText: t('login.Submit'),
      }}
      onSubmit={handleSubmit}
      pending={pending}
      noFormBorder
      infoMessage={
        <Notification
          error={error}
          setError={() => dispatch(uploadPrivacyPolicyClearError())}
          success={!!success}
        />
      }
    />
  )
}

export default FormAddPrivacyPolicy
