import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import Table from '../table'
import Collapse from '../Collapse'
import FormAddSubscription from '../forms/FormAddSubscription'
import {
  getSubscriptions,
  getSubscriptionsClearError,
  getSubscriptionSelectors,
} from '../../stores/subscriptions'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'
import {useTableColumns} from '../../hooks/useTableColumns'

export default function SubscriptionsWrapper() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const subscription_update = useSelector(
    (state) => state.subscriptions.update_subscription.success,
  )
  const subscriptions = useSelector((state) => state.subscriptions.get_subscriptions.success)
  const subscriptionsPending = useSelector((state) => state.subscriptions.get_subscriptions.pending)
  const subscriptionsError = useSelector((state) => state.subscriptions.get_subscriptions.error)
  const subscription_create = useSelector((state) => state.subscriptions.post_subscription.success)
  const subscriptionSelectors = useSelector(
    (state) => state?.subscriptions?.get_subscription_selectors?.success,
  )

  //get Subscriptions
  useEffect(() => {
    dispatch(getSubscriptions())
  }, [dispatch, subscription_update, subscription_create])

  useEffect(() => {
    dispatch(getSubscriptionSelectors())
  }, [dispatch])
  const columns = useTableColumns(TableId.SUBSCRIPTION)
  return (
    <>
      <Collapse title={t('userManagement.AddSubscription')}>
        <FormAddSubscription />
      </Collapse>
      <EditTableWrapper tableId={TableId.SUBSCRIPTION} />
      <Table
        columns={columns}
        data={subscriptions.map((data) => ({...data, subscriptionSelectors}))}
        pending={subscriptionsPending}
        error={subscriptionsError}
        setError={getSubscriptionsClearError}
      />
    </>
  )
}
