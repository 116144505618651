import axios from '../services/api'

// Types
const GET_IDENTITY_PENDING = 'idfunctiondb/identity/GET_IDENTITY_PENDING'
const GET_IDENTITY_SUCCESS = 'idfunctiondb/identity/GET_IDENTITY_SUCCESS'
const GET_IDENTITY_ERROR = 'idfunctiondb/identity/GET_IDENTITY_ERROR'
const GET_IDENTITY_CLEAR_ERROR = 'idfunctiondb/identity/GET_IDENTITY_CLEAR_ERROR'

const CREATE_IDENTITY_PENDING = 'idfunctiondb/identity/CREATE_IDENTITY_PENDING'
const CREATE_IDENTITY_SUCCESS = 'idfunctiondb/identity/CREATE_IDENTITY_SUCCESS'
const CREATE_IDENTITY_ERROR = 'idfunctiondb/identity/CREATE_IDENTITY_ERROR'
const CREATE_IDENTITY_CLEAR_ERROR = 'idfunctiondb/identity/CREATE_IDENTITY_CLEAR_ERROR'

const UPDATE_IDENTITY_PENDING = 'idfunctiondb/identity/UPDATE_IDENTITY_PENDING'
const UPDATE_IDENTITY_SUCCESS = 'idfunctiondb/identity/UPDATE_IDENTITY_SUCCESS'
const UPDATE_IDENTITY_ERROR = 'idfunctiondb/identity/UPDATE_IDENTITY_ERROR'

const DELETE_IDENTITY_PENDING = 'idfunctiondb/identity/DELETE_IDENTITY_PENDING'
const DELETE_IDENTITY_SUCCESS = 'idfunctiondb/identity/DELETE_IDENTITY_SUCCESS'
const DELETE_IDENTITY_ERROR = 'idfunctiondb/identity/DELETE_IDENTITY_ERROR'
const DELETE_IDENTITY_CLEAR_ERROR = 'idfunctiondb/identity/DELETE_IDENTITY_CLEAR_ERROR'

const DELETE_IDENTITY_CHILD_PENDING = 'idfunctiondb/identity/DELETE_IDENTITY_CHILD_PENDING'
const DELETE_IDENTITY_CHILD_SUCCESS = 'idfunctiondb/identity/DELETE_IDENTITY_CHILD_SUCCESS'
const DELETE_IDENTITY_CHILD_ERROR = 'idfunctiondb/identity/DELETE_IDENTITY_CHILD_ERROR'
const DELETE_IDENTITY_CHILD_CLEAR_ERROR = 'idfunctiondb/identity/DELETE_IDENTITY_CHILD_CLEAR_ERROR'

// Initial
const initialState = {
  get_identity: {
    success: null,
    pending: false,
    error: null,
  },

  create_identity: {
    success: {},
    pending: false,
    error: null,
    successMessage: false,
  },

  update_identity: {
    success: {},
    pending: false,
    error: null,
  },

  delete_identity: {
    success: null,
    pending: false,
    error: null,
  },

  delete_identity_child: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case GET_IDENTITY_PENDING:
      return {
        ...state,
        get_identity: {
          ...state.get_identity,
          pending: true,
        },
      }
    case GET_IDENTITY_SUCCESS:
      return {
        ...state,
        get_identity: {
          ...state.get_identity,
          success: action.payload,
          pending: false,
        },
      }
    case GET_IDENTITY_ERROR:
      return {
        ...state,
        get_identity: {
          ...state.get_identity,
          error: action.payload,
          pending: false,
        },
      }
    case GET_IDENTITY_CLEAR_ERROR:
      return {
        ...state,
        get_identity: {
          ...state.get_identity,
          error: null,
        },
      }
    case CREATE_IDENTITY_PENDING:
      return {
        ...state,
        create_identity: {
          ...state.create_identity,
          pending: true,
        },
      }
    case CREATE_IDENTITY_SUCCESS:
      return {
        ...state,
        create_identity: {
          ...state.create_identity,
          success: action.payload,
          successMessage: true,
          pending: false,
        },
      }
    case CREATE_IDENTITY_ERROR:
      return {
        ...state,
        create_identity: {
          ...state.create_identity,
          error: action.payload,
          successMessage: false,
          pending: false,
        },
      }
    case UPDATE_IDENTITY_PENDING:
      return {
        ...state,
        update_identity: {
          ...state.update_identity,
          pending: true,
        },
      }
    case UPDATE_IDENTITY_SUCCESS:
      return {
        ...state,
        update_identity: {
          ...state.update_identity,
          success: action.payload,
          pending: false,
          error: null,
        },
      }
    case UPDATE_IDENTITY_ERROR:
      return {
        ...state,
        create_iupdate_identitydentity: {
          ...state.update_identity,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_IDENTITY_CLEAR_ERROR:
      return {
        ...state,
        create_identity: {
          ...state.create_identity,
          error: null,
          pending: false,
          successMessage: false,
        },
      }
    case DELETE_IDENTITY_PENDING:
      return {
        ...state,
        delete_identity: {
          ...state.delete_identity,
          pending: true,
        },
      }
    case DELETE_IDENTITY_SUCCESS:
      return {
        ...state,
        delete_identity: {
          ...state.delete_identity,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_IDENTITY_ERROR:
      return {
        ...state,
        delete_identity: {
          ...state.delete_identity,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_IDENTITY_CLEAR_ERROR:
      return {
        ...state,
        delete_identity: {
          ...state.delete_identity,
          error: null,
          pending: false,
        },
      }
    case DELETE_IDENTITY_CHILD_PENDING:
      return {
        ...state,
        delete_identity_child: {
          ...state.delete_identity_child,
          pending: true,
        },
      }
    case DELETE_IDENTITY_CHILD_SUCCESS:
      return {
        ...state,
        delete_identity_child: {
          ...state.delete_identity_child,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_IDENTITY_CHILD_ERROR:
      return {
        ...state,
        delete_identity_child: {
          ...state.delete_identity_child,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_IDENTITY_CHILD_CLEAR_ERROR:
      return {
        ...state,
        delete_identity_child: {
          ...state.delete_identity_child,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getIdentityPending() {
  return {type: GET_IDENTITY_PENDING}
}

function getIdentitySuccess(payload) {
  return {type: GET_IDENTITY_SUCCESS, payload: payload}
}

function getIdentityError(error) {
  return {type: GET_IDENTITY_ERROR, payload: error}
}

export function getIdentityClearError() {
  return {type: GET_IDENTITY_CLEAR_ERROR}
}

function createIdentityPending() {
  return {type: CREATE_IDENTITY_PENDING}
}

function createIdentitySuccess(payload) {
  return {type: CREATE_IDENTITY_SUCCESS, payload: payload}
}

function createIdentityError(error) {
  return {type: CREATE_IDENTITY_ERROR, payload: error}
}

function updateIdentityPending() {
  return {type: UPDATE_IDENTITY_PENDING}
}

function updateIdentitySuccess(payload) {
  return {type: UPDATE_IDENTITY_SUCCESS, payload: payload}
}

function updateIdentityError(error) {
  return {type: UPDATE_IDENTITY_ERROR, payload: error}
}

export function createIdentityClearError() {
  return {type: CREATE_IDENTITY_CLEAR_ERROR}
}

function deleteIdentityPending() {
  return {type: DELETE_IDENTITY_PENDING}
}

function deleteIdentitySuccess(payload) {
  return {type: DELETE_IDENTITY_SUCCESS, payload: payload}
}

function deleteIdentityError(error) {
  return {type: DELETE_IDENTITY_ERROR, payload: error}
}

export function deleteIdentityClearError() {
  return {type: DELETE_IDENTITY_CLEAR_ERROR}
}

function deleteIdentityChildPending() {
  return {type: DELETE_IDENTITY_CHILD_PENDING}
}

function deleteIdentityChildSuccess(payload) {
  return {type: DELETE_IDENTITY_CHILD_SUCCESS, payload: payload}
}

function deleteIdentityChildError(error) {
  return {type: DELETE_IDENTITY_CHILD_ERROR, payload: error}
}

export function deleteIdentityChildClearError() {
  return {type: DELETE_IDENTITY_CHILD_CLEAR_ERROR}
}

// Operations
export const createIdentity = (values) => (dispatch) => {
  dispatch(createIdentityPending())
  return axios
    .post(`/api/v1/identities`, values)
    .then((res) => {
      dispatch(createIdentitySuccess(res.data))
    })
    .catch((error) => dispatch(createIdentityError(error)))
}

export const updateIdentity = (id, values) => (dispatch) => {
  dispatch(updateIdentityPending())
  return axios
    .put(`/api/v1/identities/${id}`, values)
    .then((res) => {
      dispatch(updateIdentitySuccess(res.data))
    })
    .catch((error) => dispatch(updateIdentityError(error)))
}

export const deleteIdentity = (id) => (dispatch) => {
  dispatch(deleteIdentityPending())
  return axios
    .delete(`/api/v1/identities/${id}`)
    .then((res) => {
      dispatch(deleteIdentitySuccess(id))
      return res.data
    })
    .catch((error) => {
      dispatch(deleteIdentityError(error))
    })
}

export const getIdentity = (id) => (dispatch) => {
  dispatch(getIdentityPending())
  return axios
    .get(`/api/v1/identities/${id}`)
    .then((res) => {
      dispatch(getIdentitySuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getIdentityError(error)))
}

export const getIdentityEmployee = (id) => (dispatch) => {
  dispatch(getIdentityPending())
  return axios
    .get(`/api/v1/identities/${id}/employee`)
    .then((res) => {
      dispatch(getIdentitySuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getIdentityError(error)))
}

export const getIdentityVisitor = (id) => (dispatch) => {
  dispatch(getIdentityPending())
  return axios
    .get(`/api/v1/identities/${id}/visitor`)
    .then((res) => {
      dispatch(getIdentitySuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getIdentityError(error)))
}

export const deleteIdentityChild = (id, cid) => (dispatch) => {
  dispatch(deleteIdentityChildPending())
  return axios
    .delete(`/api/v1/identities/${id}/children/${cid}`)
    .then((res) => {
      dispatch(deleteIdentityChildSuccess(`${id}-${cid}`))

      return res.data
    })
    .catch((error) => dispatch(deleteIdentityChildError(error)))
}
