// Types
const UPDATE_LOADING_BAR_PENDING = 'idfunctiondb/loading_bar/UPDATE_LOADING_BAR_PENDING'
const UPDATE_LOADING_BAR_SUCCESS = 'idfunctiondb/loading_bar/UPDATE_LOADING_BAR_SUCCESS'

const initialState = {
  update_loading_bar_multi_select: {
    success: 0,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_LOADING_BAR_PENDING:
      return {
        ...state,
        update_loading_bar_multi_select: {
          ...state.update_loading_bar_multi_select,
          pending: true,
        },
      }
    case UPDATE_LOADING_BAR_SUCCESS:
      return {
        ...state,
        update_loading_bar_multi_select: {
          ...state.update_loading_bar_multi_select,
          success: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function updateLoadingBarPending() {
  return {type: UPDATE_LOADING_BAR_PENDING}
}
function updateLoadingBarSuccess(payload) {
  return {type: UPDATE_LOADING_BAR_SUCCESS, payload: payload}
}

// Operations
export const updateLoadingBarMultiSelect = (progress) => (dispatch) => {
  dispatch(updateLoadingBarPending())
  if (progress <= 100) {
    dispatch(updateLoadingBarSuccess(progress))
  }
  return
}
