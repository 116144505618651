import {CodeIcon} from '@heroicons/react/outline'
import {packages} from '../utils/package.js'

const {dependencies} = packages

const features = [
  {
    name: `Blazeface ${dependencies['@evoid/blazeface'].replace('^', '')}`,
    description: 'Licence: Apache-2.0',
    href: 'https://github.com/tensorflow/tfjs-models',
    icon: CodeIcon,
  },
  {
    name: `Axios ${dependencies.axios.replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `copyfiles ${dependencies.copyfiles.replace('^', '')}`,
    description: 'Licence:',
    icon: CodeIcon,
    href: 'https://github.com/calvinmetcalf/copyfiles',
  },
  {
    name: `date-fns ${dependencies['date-fns'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `formik ${dependencies.formik.replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `i18next ${dependencies.i18next.replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `react-redux ${dependencies['react-redux'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `react-router-dom ${dependencies['react-router-dom'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `react-select ${dependencies['react-select'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `react-table ${dependencies['react-table'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `redux ${dependencies['redux'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `redux-persist ${dependencies['redux-persist'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `redux-thunk ${dependencies['redux-thunk'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `yup ${dependencies['yup'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `tailwindcss 3.0.13`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },

  {
    name: `headlessui ${dependencies['@headlessui/react'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `headlessui ${dependencies['@heroicons/react'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `headlessui ${dependencies['@tailwindcss/forms'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `tailwindui`,
    description: 'Licence:',
    href: 'https://tailwindui.com/',
    icon: CodeIcon,
  },

  {
    name: `tensorflowjs ${dependencies['@tensorflow/tfjs'].replace('^', '')}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  {
    name: `tensorflowjs-backend.wasm ${dependencies['@tensorflow/tfjs-backend-wasm'].replace(
      '^',
      '',
    )}`,
    description: 'Licence:',
    href: '',
    icon: CodeIcon,
  },
  // backend
  {name: 'python 3.10.1', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'Flask-SQLAlchemy 2.5.1', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'Flask-Migrate 2.3', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'pyodbc 4.0.32', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'psycopg2-binary 2.9.3', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'Weasyprint 48.0', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'blinker 1.4', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'pyjwt 1.7', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'requests 2.27.1', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'requests-cache 0.5.2', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'flask-admin 1.5.8', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'Flask-Mail 0.9.1', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'pandas 1.3.5', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'Flask-Continuum 0.1.7', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'passlib 1.7.4', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'licensing 0.31', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'Authlib 0.15.5', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'flask-restx 0.5.1', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'numpy 1.22.0', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'pillow 8.3.2', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'SQLAlchemy 1.4.32', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'argon2-cffi 21.3.0', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'gunicorn 20.1.0', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'casbin 1.15.2', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'Flask 2.0.2', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'itsdangerous 2.0.1', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'aniso8601 9.0.1', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'WTForms 3.0.0', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'pytest 7.0.0', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'pytest_check 1.0.4', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'pytest-order 1.0.1', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'SQLAlchemy-Continuum 1.3.12', description: 'Licence:', href: '', icon: CodeIcon},
  {name: 'odata-query 0.5.1', description: 'Licence:', href: '', icon: CodeIcon},
]

export default function LicencesScreen() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold uppercase tracking-wider text-lime-600">
          Open Source Licences
        </h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          We ❤ open source at IDfunction
        </p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          Here are the libraries we use to build IDfunction
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <a key={feature.name} href={feature.href} target="_blank" rel="noreferrer">
                <div className="pt-6">
                  <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8 hover:bg-gray-200">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md bg-lime-500 p-2 shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
