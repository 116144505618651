import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getImpressum} from '../stores/static_assets'
import {Button} from '../components/Buttons'

export default function ImpressumScreen() {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const impressum = useSelector((state) => state.static_assets.get_impressum.success)

  useEffect(() => {
    dispatch(getImpressum())
  }, [dispatch])

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {t('impressum.ImpressumSubTitle')}
          </p>
          {impressum?.warning_key ? (
            <p className="mx-auto mt-5 max-w-xl text-xl text-red-500">
              {t('impressum.NoImpressumFound')}
            </p>
          ) : (
            <div className="pt-3">
              <Button href="/api/v1/impressum" text={t('impressum.ShowImpressum')} anchor />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
