import {useMemo, useState, useEffect, useRef, forwardRef} from 'react'
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useRowSelect,
  useSortBy,
  useAsyncDebounce,
} from 'react-table'
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid'
import {Button, PageButton} from '../Buttons'
import ErrorModal from '../ErrorModal'
import {useTranslation} from 'react-i18next'
import {Skeleton} from './TableComponents'
import ExportButtons from './ExportButtons'
import axios from '../../services/api'
import TablePaginationCount from './TablePaginationCount'
import {getFilterQuery, getSortQuery} from './utils'
import {useDispatch, useSelector} from 'react-redux'
import {getSelectedPrinter} from '../../stores/printers'
import {useLocation} from 'react-router-dom'
import TableView from './TableView'
import {resolvePath} from '../../utils/helpers'

const employeeHeaders = [
  'owner.costcenter',
  'owner.department',
  'owner.email',
  'owner.employee_number',
  'owner.employee_state_long',
  'owner.end_of_contract',
  'owner.last_day_of_work',
  'owner.local_hr_number',
  'owner.mainoffice',
  'owner.person.card_pin',
  'owner.person.first_name',
  'owner.person.full_name',
  'owner.person.gender_name',
  'owner.person.last_name',
  'owner.position',
  'owner.start_of_contract',
  'owner.updated_at',
]

const visitorHeaders = [
  'owner.email',
  'owner.person.card_pin',
  'owner.person.first_name',
  'owner.person.full_name',
  'owner.person.gender_name',
  'owner.person.last_name',
  'owner.updated_at',
]

const GlobalFilter = ({columns, filter, setFilter}) => {
  const {t} = useTranslation()
  const [value, setValue] = useState(filter)
  const onChange = useAsyncDebounce((value) => {
    let global_filter_query = ''
    let index = 0

    columns = columns
      .filter((item) => item.accessor !== 'valid_to' && item.accessor !== 'valid_from')
      .forEach(function (column) {
        let accessor = column.accessor
        if (
          column.noSort ||
          typeof column.Header === 'string' ||
          column.accessor === 'selection' ||
          column.accessor === 'is_group' ||
          column.accessor === 'parent_resources' ||
          column.accessor === 'children_resources' ||
          column.accessor === 'custom_attributes' ||
          column.accessor === 'identity.is_group' ||
          column.accessor === 'parent_identities' ||
          column.accessor === 'children_identities' ||
          (column.accessor === 'resource' && column.cell === 'BooleanCell') ||
          column.accessor === 'access'
        ) {
          return
        }
        if (column.accessor === 'person.full_name') {
          accessor = 'person/full_name'
        }
        if (column.accessor === 'resource') {
          accessor = 'resource/name'
        }
        if (column.accessor === 'resource_type') {
          accessor = 'resource/type/name'
        }
        if (column.accessor === 'employee_state.id' || column.accessor === 'visitor_state.id') {
          accessor = 'state_id'
        }
        if (column.accessor === 'type') {
          accessor = 'type/name'
        }
        if (column.accessor === 'location') {
          accessor = 'location/description'
        }
        if (column.accessor === 'location[0].description') {
          accessor = 'location/description'
        }
        if (!column.accessor.includes('owner')) {
          global_filter_query +=
            (index !== 0 ? ' OR ' : '') + `contains(${accessor.replaceAll('.', '/')}, '${value}')`
        } else {
          if (column.accessor === 'identity.owner_name') {
            global_filter_query +=
              (index !== 0 ? ' OR ' : '') +
              `contains(identity/employee/person/full_name, '${value}') OR contains(identity/visitor/person/full_name, '${value}') OR contains(identity/name, '${value}')`
          } else {
            if (column.accessor === 'owner_name') {
              global_filter_query +=
                (index !== 0 ? ' OR ' : '') +
                `contains(employee/person/full_name, '${value}') OR contains(visitor/person/full_name, '${value}') OR contains(name, '${value}')`
            } else {
              if (employeeHeaders.includes(column.accessor)) {
                global_filter_query +=
                  (index !== 0 ? ' OR ' : '') +
                  `contains(${column.accessor
                    .replaceAll('owner', 'employees/employee')
                    .replaceAll('.', '/')}, '${value}')`
                index++
              }
              if (visitorHeaders.includes(column.accessor)) {
                global_filter_query +=
                  (index !== 0 ? ' OR ' : '') +
                  `contains(${column.accessor
                    .replaceAll('owner', 'visitors/visitor')
                    .replaceAll('.', '/')}, '${value}')`
                index++
              }
            }
          }
        }
        index++
      })
    if (value.length <= 2) {
      // TODO: issue 411 on idfunctiondb
      setFilter('')
    } else {
      setFilter(global_filter_query || undefined)
    }
  }, 300)
  return (
    <label className="flex items-baseline gap-x-2">
      <span className="text-gray-700">{t('search')}: </span>
      <input
        type="text"
        className=" mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </label>
  )
}

export const IndeterminateCheckbox = forwardRef(({indeterminate, ...rest}, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <input
      className="h-4 w-4 rounded border-gray-300 text-lime-600 accent-lime-500 focus:ring-lime-500"
      type="checkbox"
      ref={resolvedRef}
      {...rest}
    />
  )
})

const ServerTable = ({
  columns,
  entityName,
  deleteAction,
  addAction,
  addActionQuick,
  editAction,
  title,
  rowUrlAccessor,
  url,
  dataAccessor,
  additionalFilter,
  additionalParams,
  SelectedActionsComponent,
  selectDataAccessor,
}) => {
  const PAGE_NO = 0
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(undefined)
  const {t} = useTranslation()
  const [error, setError] = useState(null)
  const [pending, setPending] = useState(false)
  const [data, setData] = useState([])

  const {pathname} = useLocation()
  const rowBaseUrl = useMemo(() => {
    const base = url || pathname
    return base.endsWith('/') ? base : base + '/'
  }, [url, pathname])

  const dispatch = useDispatch()
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)

  const multi_select_action_success = useSelector(
    (state) => state.multi_select.multi_select_action.success,
  )

  useEffect(() => {
    dispatch(getSelectedPrinter(userID))
  }, [dispatch, userID])

  // first look at visitor_id or employee_id one of them is not null.
  // then you will look at the owner object owner.person.full_name
  const tableColumns = useMemo(
    () =>
      pending
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton />,
          }))
        : columns.map((column) => ({
            ...column,
            withClick: (!column.Cell || column.cell === 'DateCell') && !!rowUrlAccessor,
          })),
    [pending, columns, rowUrlAccessor],
  )
  const totalPages = useMemo(
    () => (totalRecords ? Math.ceil(totalRecords / recordsPerPage) : 1),
    [totalRecords, recordsPerPage],
  )

  const {
    getTableProps, // required
    getTableBodyProps, // required
    headerGroups, // required
    prepareRow, // required
    // rows, // required
    // not sure if I need to use page any more
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    // Get the state from the instance
    state: {pageIndex, pageSize, globalFilter, filters, selectedRowIds, sortBy},
  } = useTable(
    {
      columns: tableColumns,
      data: data,
      manualPagination: true,
      manualGlobalFilter: true,
      manualFilters: true,
      manualSortBy: true,
      initialState: {
        pageIndex: PAGE_NO,
        pageSize: recordsPerPage,
      },
      pageCount: totalPages,
      autoResetSelectedRows: false,
      autoResetPage: false,
      autoResetSortBy: false,
      getRowId: (row) => (selectDataAccessor ? resolvePath(row, selectDataAccessor) : row.id),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  )

  const filterQuery = useMemo(
    () => getFilterQuery(filters, additionalFilter),
    [filters, additionalFilter],
  )

  useEffect(() => {
    const fetchData = async (
      pageNo,
      recordsPerPage,
      searchText = '',
      filterQuery,
      entityName,
      sortBy,
      additionalParams,
    ) => {
      setError(false)
      setPending(true)
      try {
        const response = await axios.get(
          `/api/v1/${entityName}?offset=${
            (filterQuery || searchText ? 0 : pageNo - 1) * recordsPerPage
          }&limit=${recordsPerPage}&filters=${filterQuery}&global_filter=${searchText}&sort_by=${getSortQuery(
            sortBy,
          )}&${additionalParams}`,
        )
        const data = dataAccessor ? response?.data[dataAccessor] : response?.data?.results

        if (filterQuery || searchText) {
          gotoPage(0)
        }
        setData(data)
        setTotalRecords(response?.data?.meta?.total)
      } catch (error) {
        setError(error)
      }
      setPending(false)
    }

    fetchData(
      pageIndex + 1,
      recordsPerPage,
      globalFilter,
      filterQuery,
      entityName,
      sortBy,
      additionalParams,
    ).then()
  }, [
    pageIndex,
    recordsPerPage,
    globalFilter,
    gotoPage,
    filterQuery,
    deleteAction,
    addActionQuick,
    addAction,
    editAction,
    entityName,
    multi_select_action_success,
    sortBy,
    dataAccessor,
    additionalParams,
  ])

  useEffect(() => {
    setRecordsPerPage(pageSize)
    gotoPage(0)
  }, [pageSize, gotoPage])

  const selectedRowArray = Object.keys(selectedRowIds).map((id) => {
    return {id}
  })

  return (
    <>
      {error ? <ErrorModal title="Error" error={error} setError={setError} /> : null}
      <div className="mt-4">
        <div className="flex gap-x-2">
          <GlobalFilter columns={columns} filter={globalFilter} setFilter={setGlobalFilter} />
          <ExportButtons
            headerGroups={headerGroups}
            page={page}
            setError={setError}
            url={`/api/v1/${entityName}?offset=0&limit=${totalRecords}&filters=${filterQuery}&global_filter=${
              globalFilter || ''
            }`}
          />
        </div>
        {!!title && <p className="mt-2 text-sm text-gray-700">{title}</p>}
        {!!SelectedActionsComponent && selectedRowArray.length !== 0 ? (
          <SelectedActionsComponent selected={selectedRowArray} />
        ) : null}
        <>
          <div className="mt-2 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <TableView
                    getTableProps={getTableProps}
                    getTableBodyProps={getTableBodyProps}
                    page={page}
                    headerGroups={headerGroups}
                    prepareRow={prepareRow}
                    rowBaseUrl={rowBaseUrl}
                    rowUrlAccessor={rowUrlAccessor}
                    pending={pending}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Pagination Part */}
          <div className="flex items-center justify-between py-3">
            <div className="flex flex-1 justify-between sm:hidden">
              <Button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                text={t('previous')}
              />
              <Button onClick={() => nextPage()} disabled={!canNextPage} text={t('next')} />
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
              <TablePaginationCount
                length={pageOptions.length}
                pageSize={pageSize}
                pageIndex={pageIndex}
                onPageSizeSelect={setPageSize}
                sizeOptions={[5, 10, 20, 30, 40, 50]}
              />
              <div>
                <nav
                  className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <PageButton
                    className="rounded-l-md"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    <span className="sr-only">First</span>
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </PageButton>
                  <PageButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </PageButton>
                  <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </PageButton>
                  <PageButton
                    className="rounded-r-md"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    <span className="sr-only">Last</span>
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                  </PageButton>
                </nav>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  )
}

export default ServerTable
