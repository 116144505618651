export const isEmpty = function (data) {
  if (typeof data === 'object') {
    if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
      return true
    } else if (!data) {
      return true
    }
    return false
  } else if (typeof data === 'string') {
    return !data.trim()
  } else return typeof data === 'undefined'
}

export const containsKey = function (object, key) {
  return !!Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase())
}

export const debounce = (fn, delay = 250) => {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

export const filterByUniqueProperty = (arr, property) => {
  const uniqueValues = new Set()
  return arr.filter((obj) => {
    const value = obj[property]
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value)
      return true
    }
    return false
  })
}
