import axios from '../services/api'

// Types
const GET_ROLES_PENDING = 'idfunctiondb/roles/GET_ROLES_PENDING'
const GET_ROLES_SUCCESS = 'idfunctiondb/roles/GET_ROLES_SUCCESS'
const GET_ROLES_ERROR = 'idfunctiondb/roles/GET_ROLES_ERROR'
const GET_ROLES_CLEAR_ERROR = 'idfunctiondb/roles/GET_ROLES_CLEAR_ERROR'

const CREATE_ROLE_PENDING = 'idfunctiondb/roles/CREATE_ROLE_PENDING'
const CREATE_ROLE_SUCCESS = 'idfunctiondb/roles/CREATE_ROLE_SUCCESS'
const CREATE_ROLE_ERROR = 'idfunctiondb/roles/CREATE_ROLE_ERROR'

const DELETE_ROLE_CUSTOM_ATTRIBUTE_PENDING =
  'idfunctiondb/roles/DELETE_ROLE_CUSTOM_ATTRIBUTE_PENDING'
const DELETE_ROLE_CUSTOM_ATTRIBUTE_SUCCESS =
  'idfunctiondb/roles/DELETE_ROLE_CUSTOM_ATTRIBUTE_SUCCESS'
const DELETE_ROLE_CUSTOM_ATTRIBUTE_ERROR = 'idfunctiondb/roles/DELETE_ROLE_CUSTOM_ATTRIBUTE_ERROR'

const ADD_ROLE_CUSTOM_ATTRIBUTE_PENDING = 'idfunctiondb/roles/ADD_ROLE_CUSTOM_ATTRIBUTE_PENDING'
const ADD_ROLE_CUSTOM_ATTRIBUTE_SUCCESS = 'idfunctiondb/roles/ADD_ROLE_CUSTOM_ATTRIBUTE_SUCCESS'
const ADD_ROLE_CUSTOM_ATTRIBUTE_ERROR = 'idfunctiondb/roles/ADD_ROLE_CUSTOM_ATTRIBUTE_ERROR'

// Initial
const initialState = {
  get_roles: {
    success: [],
    pending: false,
    error: null,
  },

  create_role: {
    success: [],
    pending: false,
    error: null,
  },

  delete_role_custom_attribute: {
    success: [],
    pending: false,
    error: null,
  },

  add_role_custom_attribute: {
    success: [],
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ROLES_PENDING:
      return {
        ...state,
        get_roles: {
          ...state.get_roles,
          pending: true,
        },
      }
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        get_roles: {
          ...state.get_roles,
          success: action.payload,
          pending: false,
        },
      }
    case GET_ROLES_ERROR:
      return {
        ...state,
        get_roles: {
          ...state.get_roles,
          error: action.payload,
          pending: false,
        },
      }
    case GET_ROLES_CLEAR_ERROR:
      return {
        ...state,
        get_roles: {
          ...state.get_roles,
          error: null,
          pending: false,
        },
      }
    case CREATE_ROLE_PENDING:
      return {
        ...state,
        create_role: {
          ...state.create_role,
          pending: true,
        },
      }
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        create_role: {
          ...state.create_role,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_ROLE_ERROR:
      return {
        ...state,
        create_role: {
          ...state.create_role,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_ROLE_CUSTOM_ATTRIBUTE_PENDING:
      return {
        ...state,
        delete_role_custom_attribute: {
          ...state.delete_role_custom_attribute,
          pending: true,
        },
      }
    case DELETE_ROLE_CUSTOM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        delete_role_custom_attribute: {
          ...state.delete_role_custom_attribute,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_ROLE_CUSTOM_ATTRIBUTE_ERROR:
      return {
        ...state,
        delete_role_custom_attribute: {
          ...state.delete_role_custom_attribute,
          error: action.payload,
          pending: false,
        },
      }
    case ADD_ROLE_CUSTOM_ATTRIBUTE_PENDING:
      return {
        ...state,
        add_role_custom_attribute: {
          ...state.add_role_custom_attribute,
          pending: true,
        },
      }
    case ADD_ROLE_CUSTOM_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        add_role_custom_attribute: {
          ...state.add_role_custom_attribute,
          success: action.payload,
          pending: false,
        },
      }
    case ADD_ROLE_CUSTOM_ATTRIBUTE_ERROR:
      return {
        ...state,
        add_role_custom_attribute: {
          ...state.add_role_custom_attribute,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getRolesPending() {
  return {type: GET_ROLES_PENDING}
}
function getRolesSuccess(payload) {
  return {type: GET_ROLES_SUCCESS, payload: payload}
}
function getRolesError(error) {
  return {type: GET_ROLES_ERROR, payload: error}
}
export function getRolesClearError() {
  return {type: GET_ROLES_CLEAR_ERROR}
}

function createRolePending() {
  return {type: CREATE_ROLE_PENDING}
}
function createRoleSuccess(payload) {
  return {type: CREATE_ROLE_SUCCESS, payload: payload}
}
function createRoleError(error) {
  return {type: CREATE_ROLE_ERROR, payload: error}
}

function deleteRoleCustomAttributePending() {
  return {type: DELETE_ROLE_CUSTOM_ATTRIBUTE_PENDING}
}
function deleteRoleCustomAttributeSuccess(payload) {
  return {type: DELETE_ROLE_CUSTOM_ATTRIBUTE_SUCCESS, payload: payload}
}
function deleteRoleCustomAttributeError(error) {
  return {type: DELETE_ROLE_CUSTOM_ATTRIBUTE_ERROR, payload: error}
}

function addRoleCustomAttributePending() {
  return {type: ADD_ROLE_CUSTOM_ATTRIBUTE_PENDING}
}
function addRoleCustomAttributeSuccess(payload) {
  return {type: ADD_ROLE_CUSTOM_ATTRIBUTE_SUCCESS, payload: payload}
}
function addRoleCustomAttributeError(error) {
  return {type: ADD_ROLE_CUSTOM_ATTRIBUTE_ERROR, payload: error}
}

// Operations
export const getRoles =
  (limit = 1000, offset = 0) =>
  (dispatch) => {
    dispatch(getRolesPending())
    return axios
      .get(`/api/v1/roles?limit=${limit}&offset=${offset}`)
      .then((res) => {
        dispatch(getRolesSuccess(res.data.results))
      })
      .catch((error) => dispatch(getRolesError(error)))
  }

export const createRole = (values) => (dispatch) => {
  dispatch(createRolePending())
  return axios
    .post(`/api/v1/roles`, values)
    .then((res) => {
      dispatch(createRoleSuccess(res.data))
    })
    .catch((error) => dispatch(createRoleError(error)))
}

export const addRoleCustomAttribute = (id, values) => (dispatch) => {
  dispatch(addRoleCustomAttributePending())
  return axios
    .post(`/api/v1/roles/${id}/custom_attributes`, values)
    .then((res) => {
      dispatch(addRoleCustomAttributeSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(addRoleCustomAttributeError(error))
    })
}

export const deleteRoleCustomAttribute = (id, key) => (dispatch) => {
  dispatch(deleteRoleCustomAttributePending())
  return axios
    .delete(`/api/v1/roles/${id}/custom_attributes/${key}`)
    .then((res) => {
      dispatch(deleteRoleCustomAttributeSuccess(res))
      return res.data
    })
    .catch((error) => {
      dispatch(deleteRoleCustomAttributeError(error))
    })
}
