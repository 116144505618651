import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {applyActionOnCard} from '../stores/cards'
import {
  AnnotationIcon,
  ClockIcon,
  PrinterIcon,
  LockClosedIcon,
  LockOpenIcon,
  BanIcon,
} from '@heroicons/react/outline'
import {classNames} from '../utils/classNames'

export default function CardFooterActions({card, selected_printer}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const apply_action_card_pending = useSelector((state) => state.cards.apply_action_card.pending)

  const functionalities = [
    {
      functionName:
        selected_printer === null
          ? t('employeeFunctionalitySidebar.SelectPrinter')
          : card?.state?.name === 'PRODUCTION_FAILED'
          ? t('employeeFunctionalitySidebar.RetryProduction')
          : t('employeeFunctionalitySidebar.Produce'),
      icon:
        selected_printer === null
          ? AnnotationIcon
          : card?.state?.name === 'PRODUCTION_FAILED'
          ? ClockIcon
          : PrinterIcon,
      functionBody: function () {
        dispatch(applyActionOnCard(card.url, {action: 'request_production'}, false, userID))
      },
      disabled:
        (card?.state?.name !== 'PRODUCTION_FAILED' && card?.state?.name !== 'UNPRODUCED') ||
        selected_printer === null ||
        card?.state?.name === 'PRODUCED' ||
        card?.state?.name === 'REQUESTED' ||
        apply_action_card_pending ||
        !permissions.produceCard,
    },
    {
      functionName: t('employeeFunctionalitySidebar.BlockTemporary'),
      icon: LockClosedIcon,
      functionBody: function () {
        dispatch(applyActionOnCard(card.url, {action: 'block'}, true, userID))
      },
      disabled:
        card?.state?.name !== 'ACTIVE' ||
        card?.state?.name === 'PRODUCED' ||
        card?.state?.name === 'REQUESTED' ||
        apply_action_card_pending ||
        !permissions.updateCard,
    },
    {
      functionName: t('employeeFunctionalitySidebar.RevokeTempBlock'),
      icon: LockOpenIcon,
      functionBody: function () {
        dispatch(applyActionOnCard(card.url, {action: 'unblock'}, true, userID))
      },
      disabled:
        card?.state?.name !== 'BLOCKED' ||
        card?.state?.name === 'PRODUCED' ||
        card?.state?.name === 'REQUESTED' ||
        apply_action_card_pending ||
        !permissions.updateCard,
    },
    {
      functionName: t('employeeFunctionalitySidebar.BlockPermanently'),
      icon: BanIcon,
      functionBody: function () {
        dispatch(applyActionOnCard(card.url, {action: 'destroy'}, true, userID))
      },
      disabled:
        card?.state?.name === 'PRODUCED' ||
        card?.state?.name === 'REQUESTED' ||
        card?.state?.name === 'DELETED' ||
        apply_action_card_pending ||
        !permissions.deleteCard,
    },
  ]
  return (
    <>
      <div className="flex flex-shrink flex-nowrap">
        <div className="-mx-4 flex  justify-between sm:mx-auto">
          {functionalities.map((item) => (
            <div key={item.functionName}>
              <button
                onClick={() => {
                  item.functionBody(card.id)
                }}
                disabled={item.disabled}
              >
                <div className="m-2 flex flex-col items-center">
                  <item.icon
                    className={classNames(
                      item.disabled
                        ? 'cursor-not-allowed text-red-200'
                        : 'cursor-pointer text-gray-500',
                      ' m-4 h-6 w-6 flex-shrink-0',
                    )}
                  />
                  <p className="text-wrap invisible text-xs sm:visible">{item.functionName}</p>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
