import {Formik, Form} from 'formik'
import {TextInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {FormikSaveButton} from '../Buttons'
import {changePassword, changePasswordClearError} from '../../stores/forgot_password'

export default function FormResetOwnPassword() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const pending = useSelector((state) => state.forgot_password.change_password.pending)
  const error = useSelector((state) => state.forgot_password.change_password.error)

  const initialValues = {
    password: '',
    new_password: '',
    new_password_repeated: '',
  }

  const validationSchema = Yup.object({
    password: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('login.Password')}),
    ),
    new_password: Yup.string()
      .required(t('yupError.ThisFieldIsRequired', {field: t('login.Password')}))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$%^&*()_+|~=`{}[:;'"/§ <>?,.@#\]])[A-Za-z\d-!$%^&*()_+|~=`{}[:;'"/§ <>?,.@#\]]{6,}$/,
        t('login.PasswordRequirements'),
      ),
    new_password_repeated: Yup.string()
      .required(
        t('yupError.ThisFieldIsRequired', {
          field: t('login.NewPasswordRepeat'),
        }),
      )
      .oneOf([Yup.ref('new_password'), null], t('login.PasswordDoesntMatch')),
  })

  const handleSubmit = (values, actions) => {
    // Add function
    dispatch(changePassword(values.password, values.new_password, values.new_password_repeated))
    actions.resetForm()
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
      >
        <Form>
          <TextInput
            label={t('userInfo.OldPassword')}
            name="password"
            type="password"
            placeholder=""
            required
          />
          <TextInput
            label={t('userInfo.NewPassword')}
            name="new_password"
            type="password"
            placeholder=""
            required
          />
          <TextInput
            label={t('userInfo.RepeatNewPassword')}
            name="new_password_repeated"
            type="password"
            placeholder=""
            required
          />
          <FormikSaveButton
            type="submit"
            testid="form-reset-own-password-submit"
            text={t('userInfo.Submit')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(changePasswordClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
