import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import FormAddEmployee from '../components/forms/FormAddEmployee'
import FormAddEmployeeQuick from '../components/forms/FormAddEmployeeQuick'
import {useSelector} from 'react-redux'
import Tabs from '../components/Tabs'
import ServerSideTable from '../components/table/ServerSideTable'
import {EMPLOYEE_TABLE} from '../utils/formViews'
import WorkflowForms from '../components/WorkflowForms'
import {useTableColumns} from '../hooks/useTableColumns'
import {TableId} from '../stores/types/tables'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'
import FormCreateIdentityGroup from '../components/forms/FormCreateIdentityGroup'

export default function EmployeeTableScreen() {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const deleteEmployeeAction = useSelector((state) => state.employees.delete_employee.success)
  const addEmployeeAction = useSelector((state) => state.employees.create_employee.success)
  const editEmployeeAction = useSelector((state) => state.employees.set_employee.success)
  const addQuickEmployeeAction = useSelector(
    (state) => state.employees.create_quick_employee.success,
  )

  const tabsArray = [
    {name: t('addEmployee.Normal'), component: <FormAddEmployeeQuick />},
    {name: t('addEmployee.ExistingPerson'), component: <FormAddEmployee />},
  ]

  const columns = useTableColumns(TableId.EMPLOYEE)

  return (
    <>
      {permissions.createEmployee ? (
        <Collapse title={t('addEmployee.AddEmployeeTitle')}>
          <Tabs tabs={tabsArray} />
        </Collapse>
      ) : null}
      <WorkflowForms renderOnUi={EMPLOYEE_TABLE} />
      {permissions.listEmployees ? (
        <>
          <EditTableWrapper tableId={TableId.EMPLOYEE} />
          <ServerSideTable
            entityName={'employees'}
            columns={columns}
            deleteAction={deleteEmployeeAction}
            addAction={addEmployeeAction}
            editAction={editEmployeeAction}
            addActionQuick={addQuickEmployeeAction}
            rowUrlAccessor={'id'}
            SelectedActionsComponent={FormCreateIdentityGroup}
            selectDataAccessor={'identity.id'}
          />
        </>
      ) : null}
    </>
  )
}
