import {Link} from 'react-router-dom'
import {NewspaperIcon, PhoneIcon, SupportIcon} from '@heroicons/react/outline'
import aboutImage from '../assets/aboutImage.png'
import {useTranslation} from 'react-i18next'
import {getContactLinkPreference, getSupportLinkPreference} from '../services/setPreferences'
import {classNames} from '../utils/classNames'

export default function AboutScreen() {
  const supportLink = getSupportLinkPreference() === false ? '' : getSupportLinkPreference()
  const contactLink = getContactLinkPreference() === false ? '' : getContactLinkPreference()
  const {t} = useTranslation()
  const supportLinks = [
    {
      name: 'Sales',
      href: contactLink,
      linkText: t('about.ContactUs'),
      description: t('about.SomeExplanation'),
      icon: PhoneIcon,
    },
    {
      name: 'Technical Support',
      href: supportLink,
      linkText: t('about.SupportPortal'),
      description: t('about.SupportText'),
      icon: SupportIcon,
    },
    {
      name: 'Impressum',
      href: '/impressum',
      linkText: t('about.LearnMore'),
      description: t('about.ImpressumText'),
      icon: NewspaperIcon,
    },
  ]
  return (
    <div className="bg-white">
      {/* Header */}
      <div className="relative bg-gray-800 pb-32">
        <div className="absolute inset-0">
          <img className="h-3/4 w-3/4 object-cover" src={aboutImage} alt="" />
          <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
            {t('about.Title')}
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">{t('about.Slogan')}</p>
          <Link className="text-xl text-blue-400" to="/licences">
            Licences
          </Link>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="z-1 relative mx-auto -mt-32 max-w-7xl px-4 pb-32 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div key={link.name} className="flex flex-col rounded-2xl bg-white shadow-xl">
              <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
                <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-lime-600 p-5 shadow-lg">
                  <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                <p className="mt-4 text-base text-gray-500">{link.description}</p>
              </div>
              <div className="rounded-bl-2xl rounded-br-2xl bg-gray-50 p-6 md:px-8">
                <a
                  href={link.href}
                  className={classNames(
                    link.href === '' ? 'pointer-events-none' : '',
                    'text-base font-medium text-indigo-700 hover:text-lime-600',
                  )}
                >
                  {link.linkText}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}
