import {useSelector} from 'react-redux'
import Collapse from '../Collapse'
import {useTranslation} from 'react-i18next'
import FormAddPerson from '../forms/FormAddPerson'
import ServerSideTable from '../table/ServerSideTable'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'
import {useTableColumns} from '../../hooks/useTableColumns'

export default function PersonWrapper() {
  const {t} = useTranslation()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const deletePersonAction = useSelector((state) => state.persons.delete_person.success)
  const addPersonAction = useSelector((state) => state.persons.create_person.success)
  const editPersonAction = useSelector((state) => state.persons.set_person.success)

  const columns = useTableColumns(TableId.PERSON)

  return (
    <>
      {permissions.createPerson ? (
        <Collapse title={t('addPersonForm.AddPersonTitle')}>
          <FormAddPerson />
        </Collapse>
      ) : null}
      {permissions.listPersons ? (
        <>
          <EditTableWrapper tableId={TableId.PERSON} />
          <ServerSideTable
            columns={columns}
            entityName="persons"
            deleteAction={deletePersonAction}
            addAction={addPersonAction}
            editAction={editPersonAction}
          />
        </>
      ) : null}
    </>
  )
}
