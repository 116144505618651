import axios from '../services/api'

// Types
const GET_REPORT_PDF_PENDING = 'idfunctiondb/reports/GET_REPORT_PDF_PENDING'
const GET_REPORT_PDF_SUCCESS = 'idfunctiondb/reports/GET_REPORT_PDF_SUCCESS'
const GET_REPORT_PDF_ERROR = 'idfunctiondb/reports/GET_REPORT_PDF_ERROR'

const DOWNLOAD_REPORT_PDF_PENDING = 'idfunctiondb/reports/DOWNLOAD_REPORT_PDF_PENDING'
const DOWNLOAD_REPORT_PDF_SUCCESS = 'idfunctiondb/reports/DOWNLOAD_REPORT_PDF_SUCCESS'
const DOWNLOAD_REPORT_PDF_ERROR = 'idfunctiondb/reports/DOWNLOAD_REPORT_PDF_ERROR'

const CREATE_CARD_ACTIONS_REPORT_PENDING = 'idfunctiondb/reports/CREATE_CARD_ACTIONS_REPORT_PENDING'
const CREATE_CARD_ACTIONS_REPORT_SUCCESS = 'idfunctiondb/reports/CREATE_CARD_ACTIONS_REPORT_SUCCESS'
const CREATE_CARD_ACTIONS_REPORT_ERROR = 'idfunctiondb/reports/CREATE_CARD_ACTIONS_REPORT_ERROR'

const GET_EMPLOYEES_CUSTOM_FIELDS_PENDING =
  'idfunctiondb/reports/GET_EMPLOYEES_CUSTOM_FIELDS_PENDING'
const GET_EMPLOYEES_CUSTOM_FIELDS_SUCCESS =
  'idfunctiondb/reports/GET_EMPLOYEES_CUSTOM_FIELDS_SUCCESS'
const GET_EMPLOYEES_CUSTOM_FIELDS_ERROR = 'idfunctiondb/reports/GET_EMPLOYEES_CUSTOM_FIELDS_ERROR'

const GET_VISITORS_CUSTOM_FIELDS_PENDING = 'idfunctiondb/reports/GET_VISITORS_CUSTOM_FIELDS_PENDING'
const GET_VISITORS_CUSTOM_FIELDS_SUCCESS = 'idfunctiondb/reports/GET_VISITORS_CUSTOM_FIELDS_SUCCESS'
const GET_VISITORS_CUSTOM_FIELDS_ERROR = 'idfunctiondb/reports/GET_VISITORS_CUSTOM_FIELDS_ERROR'

const CREATE_IMPORT_EMPLOYEES_REPORT_PENDING =
  'idfunctiondb/reports/CREATE_IMPORT_EMPLOYEES_REPORT_PENDING'
const CREATE_IMPORT_EMPLOYEES_REPORT_SUCCESS =
  'idfunctiondb/reports/CREATE_IMPORT_EMPLOYEES_REPORT_SUCCESS'
const CREATE_IMPORT_EMPLOYEES_REPORT_ERROR =
  'idfunctiondb/reports/CREATE_IMPORT_EMPLOYEES_REPORT_ERROR'

const CREATE_PRE_INIT_CARDS_REPORT_PENDING =
  'idfunctiondb/reports/CREATE_PRE_INIT_CARDS_REPORT_PENDING'
const CREATE_PRE_INIT_CARDS_REPORT_SUCCESS =
  'idfunctiondb/reports/CREATE_PRE_INIT_CARDS_REPORT_SUCCESS'
const CREATE_PRE_INIT_CARDS_REPORT_ERROR = 'idfunctiondb/reports/CREATE_PRE_INIT_CARDS_REPORT_ERROR'

const initialState = {
  get_report_pdf: {
    success: null,
    pending: false,
    error: null,
  },

  download_report_pdf: {
    success: null,
    pending: false,
    error: null,
  },

  create_card_actions_report: {
    success: null,
    pending: false,
    error: null,
  },

  create_import_employees_report: {
    success: null,
    pending: false,
    error: null,
  },

  create_pre_init_cards_report: {
    success: null,
    pending: false,
    error: null,
  },

  get_employees_custom_fields: {
    success: [],
    pending: false,
    error: null,
  },

  get_visitors_custom_fields: {
    success: [],
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_REPORT_PDF_PENDING:
      return {
        ...state,
        get_report_pdf: {
          ...state.get_report_pdf,
          pending: true,
        },
      }
    case GET_REPORT_PDF_SUCCESS:
      return {
        ...state,
        get_report_pdf: {
          ...state.get_report_pdf,
          success: action.payload,
          pending: false,
        },
      }
    case GET_REPORT_PDF_ERROR:
      return {
        ...state,
        get_report_pdf: {
          ...state.get_report_pdf,
          error: action.payload,
          pending: false,
        },
      }
    case DOWNLOAD_REPORT_PDF_PENDING:
      return {
        ...state,
        download_report_pdf: {
          ...state.download_report_pdf,
          pending: true,
        },
      }
    case DOWNLOAD_REPORT_PDF_SUCCESS:
      return {
        ...state,
        download_report_pdf: {
          ...state.download_report_pdf,
          success: action.payload,
          pending: false,
        },
      }
    case DOWNLOAD_REPORT_PDF_ERROR:
      return {
        ...state,
        download_report_pdf: {
          ...state.download_report_pdf,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_CARD_ACTIONS_REPORT_PENDING:
      return {
        ...state,
        create_card_actions_report: {
          ...state.create_card_actions_report,
          pending: true,
        },
      }
    case CREATE_CARD_ACTIONS_REPORT_SUCCESS:
      return {
        ...state,
        create_card_actions_report: {
          ...state.create_card_actions_report,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_CARD_ACTIONS_REPORT_ERROR:
      return {
        ...state,
        create_card_actions_report: {
          ...state.create_card_actions_report,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_IMPORT_EMPLOYEES_REPORT_PENDING:
      return {
        ...state,
        create_import_employees_report: {
          ...state.create_import_employees_report,
          pending: true,
        },
      }
    case CREATE_IMPORT_EMPLOYEES_REPORT_SUCCESS:
      return {
        ...state,
        create_import_employees_report: {
          ...state.create_import_employees_report,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_IMPORT_EMPLOYEES_REPORT_ERROR:
      return {
        ...state,
        create_import_employees_report: {
          ...state.create_import_employees_report,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_PRE_INIT_CARDS_REPORT_PENDING:
      return {
        ...state,
        create_pre_init_cards_report: {
          ...state.create_pre_init_cards_report,
          pending: true,
        },
      }
    case CREATE_PRE_INIT_CARDS_REPORT_SUCCESS:
      return {
        ...state,
        create_pre_init_cards_report: {
          ...state.create_pre_init_cards_report,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_PRE_INIT_CARDS_REPORT_ERROR:
      return {
        ...state,
        create_pre_init_cards_report: {
          ...state.create_pre_init_cards_report,
          error: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEES_CUSTOM_FIELDS_PENDING:
      return {
        ...state,
        get_employees_custom_fields: {
          ...state.get_employees_custom_fields,
          pending: true,
        },
      }
    case GET_EMPLOYEES_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        get_employees_custom_fields: {
          ...state.get_employees_custom_fields,
          success: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEES_CUSTOM_FIELDS_ERROR:
      return {
        ...state,
        get_employees_custom_fields: {
          ...state.get_employees_custom_fields,
          error: action.payload,
          pending: false,
        },
      }
    case GET_VISITORS_CUSTOM_FIELDS_PENDING:
      return {
        ...state,
        get_visitors_custom_fields: {
          ...state.get_visitors_custom_fields,
          pending: true,
        },
      }
    case GET_VISITORS_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        get_visitors_custom_fields: {
          ...state.get_visitors_custom_fields,
          success: action.payload,
          pending: false,
        },
      }
    case GET_VISITORS_CUSTOM_FIELDS_ERROR:
      return {
        ...state,
        get_visitors_custom_fields: {
          ...state.get_visitors_custom_fields,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getReportPdfPending() {
  return {type: GET_REPORT_PDF_PENDING}
}
function getReportPdfSuccess(payload) {
  return {type: GET_REPORT_PDF_SUCCESS, payload: payload}
}
function getReportPdfError(error) {
  return {type: GET_REPORT_PDF_ERROR, payload: error}
}

function downloadReportPdfPending() {
  return {type: DOWNLOAD_REPORT_PDF_PENDING}
}
function downloadReportPdfSuccess(payload) {
  return {type: DOWNLOAD_REPORT_PDF_SUCCESS, payload: payload}
}
function downloadReportPdfError(error) {
  return {type: DOWNLOAD_REPORT_PDF_ERROR, payload: error}
}

function createCardActionsReportPending() {
  return {type: CREATE_CARD_ACTIONS_REPORT_PENDING}
}
function createCardActionsReportSuccess(payload) {
  return {type: CREATE_CARD_ACTIONS_REPORT_SUCCESS, payload: payload}
}
function createCardActionsReportError(error) {
  return {type: CREATE_CARD_ACTIONS_REPORT_ERROR, payload: error}
}

function createImportEmployeesReportPending() {
  return {type: CREATE_IMPORT_EMPLOYEES_REPORT_PENDING}
}
function createImportEmployeesReportSuccess(payload) {
  return {type: CREATE_IMPORT_EMPLOYEES_REPORT_SUCCESS, payload: payload}
}
function createImportEmployeesReportError(error) {
  return {type: CREATE_IMPORT_EMPLOYEES_REPORT_ERROR, payload: error}
}

function createPreInitCardsReportPending() {
  return {type: CREATE_PRE_INIT_CARDS_REPORT_PENDING}
}
function createPreInitCardsReportSuccess(payload) {
  return {type: CREATE_PRE_INIT_CARDS_REPORT_SUCCESS, payload: payload}
}
function createPreInitCardsReportError(error) {
  return {type: CREATE_PRE_INIT_CARDS_REPORT_ERROR, payload: error}
}

function getEmployeesCustomFieldsPending() {
  return {type: GET_EMPLOYEES_CUSTOM_FIELDS_PENDING}
}
function getEmployeesCustomFieldsSuccess(payload) {
  return {type: GET_EMPLOYEES_CUSTOM_FIELDS_SUCCESS, payload: payload}
}
function getEmployeesCustomFieldsError(error) {
  return {type: GET_EMPLOYEES_CUSTOM_FIELDS_ERROR, payload: error}
}

function getVisitorsCustomFieldsPending() {
  return {type: GET_VISITORS_CUSTOM_FIELDS_PENDING}
}
function getVisitorsCustomFieldsSuccess(payload) {
  return {type: GET_VISITORS_CUSTOM_FIELDS_SUCCESS, payload: payload}
}
function getVisitorsCustomFieldsError(error) {
  return {type: GET_VISITORS_CUSTOM_FIELDS_ERROR, payload: error}
}
// Operations
export const getReportPdf = (url) => (dispatch) => {
  dispatch(getReportPdfPending())
  return axios
    .get(url, {responseType: 'blob'})
    .then((res) => {
      dispatch(getReportPdfSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getReportPdfError(error)))
}

export const downloadReportPdf = (url, extension, document) => (dispatch) => {
  dispatch(downloadReportPdfPending())
  return dispatch(getReportPdf(url))
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Report.${extension}`)
      document.body.appendChild(link)
      link.click()
      dispatch(downloadReportPdfSuccess(response))
    })
    .catch((error) => dispatch(downloadReportPdfError(error)))
}

export const createCardActionsReport =
  (cardActionsArray, arrayOfTranslations, document) => (dispatch) => {
    dispatch(createCardActionsReportPending())
    return axios
      .post(
        '/api/v1/reports/multi_select',
        {card_actions_array: cardActionsArray, array_of_translation: arrayOfTranslations},
        {responseType: 'blob'},
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          res.headers['content-disposition'].substring(
            21,
            res.headers['content-disposition'].length,
          ),
        )
        document.body.appendChild(link)
        link.click()
        dispatch(createCardActionsReportSuccess(res.data))
        return link.getAttribute('download')
      })
      .catch((error) => dispatch(createCardActionsReportError(error)))
  }

export const createPreInitCardsReport =
  (preInitCardsArray, arrayOfTranslations, document) => (dispatch) => {
    dispatch(createPreInitCardsReportPending())
    return axios
      .post(
        '/api/v1/reports/pre_init_cards',
        {pre_init_cards_array: preInitCardsArray, array_of_translation: arrayOfTranslations},
        {responseType: 'blob'},
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          res.headers['content-disposition'].substring(
            21,
            res.headers['content-disposition'].length,
          ),
        )
        document.body.appendChild(link)
        link.click()
        dispatch(createPreInitCardsReportSuccess(res.data))
        return link.getAttribute('download')
      })
      .catch((error) => dispatch(createPreInitCardsReportError(error)))
  }

export const createImportEmployeesReport =
  (importedEmployeesResult, arrayOfTranslations, document) => (dispatch) => {
    dispatch(createImportEmployeesReportPending())
    return axios
      .post(
        '/api/v1/reports/import_employees',
        {
          imported_employees_array: importedEmployeesResult,
          array_of_translation: arrayOfTranslations,
        },
        {responseType: 'blob'},
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          res.headers['content-disposition'].substring(
            21,
            res.headers['content-disposition'].length,
          ),
        )
        document.body.appendChild(link)
        link.click()
        dispatch(createImportEmployeesReportSuccess(res.data))
        return link.getAttribute('download')
      })
      .catch((error) => dispatch(createImportEmployeesReportError(error)))
  }

export const getEmployeesCustomFields = () => (dispatch) => {
  dispatch(getEmployeesCustomFieldsPending())
  return axios
    .get(`/api/v1/employees/custom_attributes`)
    .then((res) => {
      dispatch(getEmployeesCustomFieldsSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getEmployeesCustomFieldsError(error)))
}

export const getVisitorsCustomFields = () => (dispatch) => {
  dispatch(getVisitorsCustomFieldsPending())
  return axios
    .get(`/api/v1/visitors/custom_attributes`)
    .then((res) => {
      dispatch(getVisitorsCustomFieldsSuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(getVisitorsCustomFieldsError(error)))
}
