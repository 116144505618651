import axios from '../services/api'
import {createNotification} from './notifications'
import I18n from '../i18n'

// Types
const GET_EMPLOYEES_INVENTORIES_PENDING =
  'idfunctiondb/employees_inventories/GET_EMPLOYEES_INVENTORIES_PENDING'
const GET_EMPLOYEES_INVENTORIES_SUCCESS =
  'idfunctiondb/employees_inventories/GET_EMPLOYEES_INVENTORIES_SUCCESS'
const GET_EMPLOYEES_INVENTORIES_ERROR =
  'idfunctiondb/employees_inventories/GET_EMPLOYEES_INVENTORIES_ERROR'
const GET_EMPLOYEES_INVENTORIES_CLEAR_ERROR =
  'idfunctiondb/employees_inventories/GET_EMPLOYEES_INVENTORIES_CLEAR_ERROR'

const CREATE_EMPLOYEES_INVENTORY_PENDING =
  'idfunctiondb/employees_inventories/CREATE_EMPLOYEES_INVENTORY_PENDING'
const CREATE_EMPLOYEES_INVENTORY_SUCCESS =
  'idfunctiondb/employees_inventories/CREATE_EMPLOYEES_INVENTORY_SUCCESS'
const CREATE_EMPLOYEES_INVENTORY_ERROR =
  'idfunctiondb/employees_inventories/CREATE_EMPLOYEES_INVENTORY_ERROR'
const CREATE_EMPLOYEES_INVENTORY_CLEAR_ERROR =
  'idfunctiondb/employees_inventories/CREATE_EMPLOYEES_INVENTORY_CLEAR_ERROR'

const SET_EMPLOYEES_INVENTORY_PENDING =
  'idfunctiondb/employees_inventories/SET_EMPLOYEES_INVENTORY_PENDING'
const SET_EMPLOYEES_INVENTORY_SUCCESS =
  'idfunctiondb/employees_inventories/SET_EMPLOYEES_INVENTORY_SUCCESS'
const SET_EMPLOYEES_INVENTORY_ERROR =
  'idfunctiondb/employees_inventories/SET_EMPLOYEES_INVENTORY_ERROR'

const DELETE_EMPLOYEES_INVENTORY_PENDING =
  'idfunctiondb/employees_inventories/DELETE_EMPLOYEES_INVENTORY_PENDING'
const DELETE_EMPLOYEES_INVENTORY_SUCCESS =
  'idfunctiondb/employees_inventories/DELETE_EMPLOYEES_INVENTORY_SUCCESS'
const DELETE_EMPLOYEES_INVENTORY_ERROR =
  'idfunctiondb/employees_inventories/DELETE_EMPLOYEES_INVENTORY_ERROR'

const FILTER_EMPLOYEES_INVENTORIES_PENDING =
  'idfunctiondb/employees_inventories/FILTER_EMPLOYEES_INVENTORIES_PENDING'
const FILTER_EMPLOYEES_INVENTORIES_SUCCESS =
  'idfunctiondb/employees_inventories/FILTER_EMPLOYEES_INVENTORIES_SUCCESS'
const FILTER_EMPLOYEES_INVENTORIES_ERROR =
  'idfunctiondb/employees_inventories/FILTER_EMPLOYEES_INVENTORIES_ERROR'
const FILTER_EMPLOYEES_INVENTORIES_CLEAR_ERROR =
  'idfunctiondb/employees_inventories/FILTER_EMPLOYEES_INVENTORIES_CLEAR_ERROR'
// Initial
const initialState = {
  get_employees_inventories: {
    success: [],
    pending: false,
    error: null,
  },

  create_employees_inventory: {
    success: [],
    pending: false,
    error: null,
  },

  set_employees_inventory: {
    success: [],
    pending: false,
    error: null,
  },

  delete_employees_inventory: {
    success: null,
    pending: false,
    error: null,
  },

  filter_employees_inventories: {
    success: [],
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_EMPLOYEES_INVENTORIES_PENDING:
      return {
        ...state,
        get_employees_inventories: {
          ...state.get_employees_inventories,
          pending: true,
        },
      }
    case GET_EMPLOYEES_INVENTORIES_SUCCESS:
      return {
        ...state,
        get_employees_inventories: {
          ...state.get_employees_inventories,
          success: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEES_INVENTORIES_ERROR:
      return {
        ...state,
        get_employees_inventories: {
          ...state.get_employees_inventories,
          error: action.payload,
          pending: false,
        },
      }
    case GET_EMPLOYEES_INVENTORIES_CLEAR_ERROR:
      return {
        ...state,
        get_employees_inventories: {
          ...state.get_employees_inventories,
          error: null,
          pending: false,
        },
      }
    case CREATE_EMPLOYEES_INVENTORY_PENDING:
      return {
        ...state,
        create_employees_inventory: {
          ...state.create_employees_inventory,
          pending: true,
        },
      }
    case CREATE_EMPLOYEES_INVENTORY_SUCCESS:
      return {
        ...state,
        create_employees_inventory: {
          ...state.create_employees_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_EMPLOYEES_INVENTORY_ERROR:
      return {
        ...state,
        create_employees_inventory: {
          ...state.create_employees_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_EMPLOYEES_INVENTORY_CLEAR_ERROR:
      return {
        ...state,
        create_employees_inventory: {
          ...state.create_employees_inventory,
          error: null,
          pending: false,
        },
      }
    case SET_EMPLOYEES_INVENTORY_PENDING:
      return {
        ...state,
        set_employees_inventory: {
          ...state.set_employees_inventory,
          pending: true,
        },
      }
    case SET_EMPLOYEES_INVENTORY_SUCCESS:
      return {
        ...state,
        set_employees_inventory: {
          ...state.set_employees_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case SET_EMPLOYEES_INVENTORY_ERROR:
      return {
        ...state,
        set_employees_inventory: {
          ...state.set_employees_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_EMPLOYEES_INVENTORY_PENDING:
      return {
        ...state,
        delete_employees_inventory: {
          ...state.delete_employees_inventory,
          pending: true,
        },
      }
    case DELETE_EMPLOYEES_INVENTORY_SUCCESS:
      return {
        ...state,
        delete_employees_inventory: {
          ...state.delete_employees_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_EMPLOYEES_INVENTORY_ERROR:
      return {
        ...state,
        delete_employees_inventory: {
          ...state.delete_employees_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case FILTER_EMPLOYEES_INVENTORIES_PENDING:
      return {
        ...state,
        filter_employees_inventories: {
          ...state.filter_employees_inventories,
          pending: true,
        },
      }
    case FILTER_EMPLOYEES_INVENTORIES_SUCCESS:
      return {
        ...state,
        filter_employees_inventories: {
          ...state.filter_employees_inventories,
          success: action.payload,
          pending: false,
        },
      }
    case FILTER_EMPLOYEES_INVENTORIES_ERROR:
      return {
        ...state,
        filter_employees_inventories: {
          ...state.filter_employees_inventories,
          error: action.payload,
          pending: false,
        },
      }
    case FILTER_EMPLOYEES_INVENTORIES_CLEAR_ERROR:
      return {
        ...state,
        filter_employees_inventories: {
          ...state.filter_employees_inventories,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getEmployeesInventoriesPending() {
  return {type: GET_EMPLOYEES_INVENTORIES_PENDING}
}
function getEmployeesInventoriesSuccess(payload) {
  return {type: GET_EMPLOYEES_INVENTORIES_SUCCESS, payload: payload}
}
function getEmployeesInventoriesError(error) {
  return {type: GET_EMPLOYEES_INVENTORIES_ERROR, payload: error}
}
export function getEmployeesInventoriesClearError() {
  return {type: GET_EMPLOYEES_INVENTORIES_CLEAR_ERROR}
}

function createEmployeesInventoryPending() {
  return {type: CREATE_EMPLOYEES_INVENTORY_PENDING}
}
function createEmployeesInventorySuccess(payload) {
  return {type: CREATE_EMPLOYEES_INVENTORY_SUCCESS, payload: payload}
}
function createEmployeesInventoryError(error) {
  return {type: CREATE_EMPLOYEES_INVENTORY_ERROR, payload: error}
}
export function createEmployeesInventoryCLearError() {
  return {type: CREATE_EMPLOYEES_INVENTORY_CLEAR_ERROR}
}

function setEmployeesInventoryPending() {
  return {type: SET_EMPLOYEES_INVENTORY_PENDING}
}
function setEmployeesInventorySuccess(payload) {
  return {type: SET_EMPLOYEES_INVENTORY_SUCCESS, payload: payload}
}
function setEmployeesInventoryError(error) {
  return {type: SET_EMPLOYEES_INVENTORY_ERROR, payload: error}
}

function deleteEmployeesInventoryPending() {
  return {type: DELETE_EMPLOYEES_INVENTORY_PENDING}
}
function deleteEmployeesInventorySuccess(payload) {
  return {type: DELETE_EMPLOYEES_INVENTORY_SUCCESS, payload: payload}
}
function deleteEmployeesInventoryError(error) {
  return {type: DELETE_EMPLOYEES_INVENTORY_ERROR, payload: error}
}

function filterEmployeesInventoriesPending() {
  return {type: FILTER_EMPLOYEES_INVENTORIES_PENDING}
}
function filterEmployeesInventoriesSuccess(payload) {
  return {type: FILTER_EMPLOYEES_INVENTORIES_SUCCESS, payload: payload}
}
function filterEmployeesInventoriesError(error) {
  return {type: FILTER_EMPLOYEES_INVENTORIES_ERROR, payload: error}
}
export function filterEmployeesInventoriesClearError() {
  return {type: FILTER_EMPLOYEES_INVENTORIES_CLEAR_ERROR}
}
// Operations
export const getEmployeesInventories = (url) => (dispatch) => {
  dispatch(getEmployeesInventoriesPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getEmployeesInventoriesSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(getEmployeesInventoriesError(error)))
}

export const filterEmployeesInventories = (valid_to, valid_from, inventory_type) => (dispatch) => {
  dispatch(filterEmployeesInventoriesPending())
  return axios
    .get(
      `/api/v1/employees_inventories?filters=` +
        `type_id eq '${inventory_type}'` +
        `&valid_from='${valid_from}'` +
        `&valid_to='${valid_to}'`,
    )
    .then((res) => {
      dispatch(filterEmployeesInventoriesSuccess(res.data.results))
      return res.data.results
    })
    .catch((error) => dispatch(filterEmployeesInventoriesError(error)))
}

export const filterEmployeesInventoriesById =
  (valid_to, valid_from, inventory_id, inventory_type) => (dispatch) => {
    dispatch(filterEmployeesInventoriesPending())
    return axios
      .get(
        `/api/v1/employees_inventories?filters=` +
          `id eq '${inventory_id}' AND type_id eq '${inventory_type}'` +
          `&valid_from='${valid_from}'` +
          `&valid_to='${valid_to}'`,
      )
      .then((res) => {
        dispatch(filterEmployeesInventoriesSuccess(res.data.results))
        return res.data.results
      })
      .catch((error) => dispatch(filterEmployeesInventoriesError(error)))
  }

export const createEmployeesInventory = (values, userID) => (dispatch) => {
  dispatch(createEmployeesInventoryPending())
  return axios
    .post(`/api/v1/employees_inventories`, values)
    .then((res) => {
      dispatch(createEmployeesInventorySuccess(res.data))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.InventoryAttached', {
            assigned_to: res.data.assigned_to,
            related_to: res.data.related_to,
          }),
          link: res.data.assigned_to_url,
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(createEmployeesInventoryError(error)))
}

export const setEmployeesInventory = (id, values) => (dispatch) => {
  dispatch(setEmployeesInventoryPending())
  return axios
    .put(`/api/v1/employees_inventories/${id}`, values)
    .then((res) => {
      dispatch(setEmployeesInventorySuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(setEmployeesInventoryError(error)))
}

export const deleteEmployeesInventory = (id) => (dispatch) => {
  dispatch(deleteEmployeesInventoryPending())
  return axios
    .delete(`/api/v1/employees_inventories/${id}`)
    .then((res) => {
      dispatch(deleteEmployeesInventorySuccess(res.data))
      return res.data
    })
    .catch((error) => dispatch(deleteEmployeesInventoryError(error)))
}
