import {useState, useRef, useEffect} from 'react'
import {TextArea, AsyncSelectInput, SelectInput, AsyncSelectInput2} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {Formik, Form} from 'formik'
import * as Yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import {FormikSaveButton, Button} from '../Buttons'
import {createVisitationAddParticipants} from '../../stores/visitations'
import MeetingParticipants from '../MeetingParticipants'
import {DatePickerDefault} from '../DatePickerDefault'
import {getCompanies, getCompaniesCLearError} from '../../stores/companies'
import {iso8061UTC} from '../../utils/datetime'

export default function FormAddMeeting() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const create_visitation_add_participants = useSelector(
    (state) => state.visitations.create_visitation_add_participants.pending,
  )
  const companies = useSelector((state) => state.companies.get_companies.success)
  const get_companiesError = useSelector((state) => state.companies.get_companies.error)

  // Visitor + Employee States
  const [participantSelected, setParticipantSelected] = useState([])
  const [addedParticipants, setAddedParticipants] = useState([])

  // // Helper Methods
  // const personOptionLabeler = (e) => {
  //   return `${e.person.title !== null ? e.person.title + ' ' : ''}${e.person.first_name} ${
  //     e.person.last_name
  //   } (Employee)`
  // }

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch, get_companiesError?.message])

  const addParticipant = (selected) => {
    if (selected) {
      const allParticipants = [...addedParticipants, selected]
      setAddedParticipants(allParticipants) // Set doesn't work if you remove this
      const uniqueParticipants = [...new Set(allParticipants)]
      setAddedParticipants(uniqueParticipants)
      setParticipantSelected(null)
    }
  }

  const removeParticipant = (selected) => {
    var filtered = addedParticipants.filter(function (value) {
      return value.id !== selected.id
    })
    setAddedParticipants(filtered)
  }

  const handleSubmit = (values, actions) => {
    const visitors = addedParticipants.filter(
      (addedParticipant) => addedParticipant.visitor_id !== null,
    )
    const employees = addedParticipants.filter(
      (addedParticipant) => addedParticipant.employee_id !== null,
    )

    values.from_dt = iso8061UTC(values.from_dt)
    values.to_dt = iso8061UTC(values.to_dt)

    dispatch(createVisitationAddParticipants(values, employees, visitors, userID)) // TODO: onAdd,
    actions.resetForm()
    // resetting react-select components
    domainSelectRef.current.select.setValue('')
    hostEmployeeSelectRef.current.select.setValue('')
    hostCompanySelectRef.current.select.setValue('')
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addVisitationForm.Topic')}),
    ),
    manager_id: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addVisitationForm.HostEmployee')}),
    ),
    host_id: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addEmployee.CompanyPlaceholder')}),
    ),
    from_dt: Yup.date().required(
      t('yupError.ThisFieldIsRequired', {field: t('addVisitationForm.FromDate')}),
    ),
    to_dt: Yup.date()
      .required(t('yupError.ThisFieldIsRequired', {field: t('addVisitationForm.ToDate')}))
      .min(Yup.ref('from_dt'), t('yupError.DateRange'))
      .test('same_dates_test', t('yupError.DateMustBeGreaterThan'), function (value) {
        const {from_dt} = this.parent
        return value.getTime() !== from_dt.getTime()
      })
      .nullable(),
    location_id: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addEmployee.Domain')}),
    ),
  })

  const initialValues = {
    name: '',
    host_id: '',
    manager_id: '',
    from_dt: new Date(),
    to_dt: new Date(),
    location: '', // TODO: not really necessary
  }

  let domainSelectRef = useRef()
  let hostEmployeeSelectRef = useRef()
  let hostCompanySelectRef = useRef()

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextArea
            label={t('addVisitationForm.Topic')}
            name="name"
            placeholder=""
            rows={3}
            required
          />
          <SelectInput
            label={t('addEmployee.Domain')}
            name="location_id"
            innerRef={domainSelectRef}
            options={permissions.createEmployeeLocations}
            placeholder={t('addEmployee.DomainPlaceholder')}
            required
          />
          <AsyncSelectInput2
            label={t('addVisitationForm.HostEmployee')}
            url={`/api/v1/employees?q=`}
            placeholder={t('addVisitationForm.HostEmployeePlaceholder')}
            optionLabel="full_name"
            nestedOptions={true}
            nestedOptionLabel="person"
            optionValue="id"
            name="manager_id"
            innerRef={hostEmployeeSelectRef}
            required
          />
          <SelectInput
            label={t('addEmployee.CompanyPlaceholder')}
            name="host_id"
            innerRef={hostCompanySelectRef}
            options={companies.map((company) => ({
              value: company.id,
              label: company.name,
            }))}
            placeholder={t('addEmployee.CompanyPlaceholder')}
            required
            error={get_companiesError}
            setError={getCompaniesCLearError}
          />
          {/*Show company Name here*/}
          {/* <p>{hostCompanySelected !== null ? hostCompanySelected.name : ''}</p> */}
          <DatePickerDefault
            validfrom="from_dt"
            validto="to_dt"
            validtoid="form-add-meeting-datepicker-validTo"
            validfromid="form-add-meeting-datepicker-validFrom"
            checkboxid="form-add-meeting-checkbox"
            type="datetime-local"
            labelvalidfrom={t('addVisitationForm.FromDate')}
            labelvalidto={t('addVisitationForm.ToDate')}
            required
          />
          {/*Show Participant adding section here */}
          <h3 className="mt-2 w-full rounded-lg bg-gray-500 px-4 py-2 text-left text-base font-medium text-slate-50 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
            {t('addVisitationForm.AddParticipants')}
          </h3>
          <div className="flex flex-wrap items-center justify-between">
            <div className=" w-64 flex-none sm:w-full">
              <AsyncSelectInput
                url={`/api/v1/persons?q=`}
                getOptionLabel={(e) => e.full_name}
                getOptionValue={(e) => e.id}
                label={t('addVisitationForm.Person')}
                selected={participantSelected}
                placeholder={t('addEmployee.PersonPlaceholder')}
                onChange={(selected) => {
                  if (selected) {
                    setParticipantSelected(selected)
                  } else {
                    setParticipantSelected(null)
                  }
                }}
              />
            </div>
            {/*Show Participant email here */}
            <div className="block">
              <label className=" text-sm font-medium text-gray-700">
                {t('addVisitationForm.ParticipantEmail')}
              </label>
              <p className="h-10 w-64 flex-none rounded-lg border border-gray-300 bg-gray-100 p-2 text-center text-sm text-gray-700 shadow-sm">
                {participantSelected !== null ? participantSelected.email : ''}
              </p>
            </div>
            <div className="h-2 flex-row-reverse pt-1 pb-4">
              <Button
                text={t('addVisitationForm.AddParticipant')}
                type="button"
                onClick={() => {
                  addParticipant(participantSelected)
                }}
              />
            </div>
          </div>
          {/*Show Participants here */}
          <MeetingParticipants
            participants={addedParticipants}
            removeParticipant={removeParticipant}
          />
          <FormikSaveButton
            type="submit"
            testid="form-add-meeting-submit"
            text={t('addVisitationForm.CreateVisitation')}
            primary
            pending={() => dispatch(create_visitation_add_participants())}
          />
        </Form>
      </Formik>
    </>
  )
}
