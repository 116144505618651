import Collapse from '../Collapse'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'
import Table from '../table'
import FormAddOidcProvider from '../forms/FormAddOidcProvider'
import {useSelector, useDispatch} from 'react-redux'
import {getOidcProvidersAdmin, getOidcProvidersAdminClearError} from '../../stores/oidc_providers'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'
import {useTableColumns} from '../../hooks/useTableColumns'

export default function IdentityProviderWrapper() {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const oidc_providers_admin = useSelector(
    (state) => state.oidc_providers.get_oidc_providers_admin.success,
  )

  const oidc_providers_admin_pending = useSelector(
    (state) => state.oidc_providers.get_oidc_providers_admin.pending,
  )

  const oidc_providers_admin_error = useSelector(
    (state) => state.oidc_providers.get_oidc_providers_admin.error,
  )

  const post_oidc_provider = useSelector((state) => state.oidc_providers.post_oidc_provider.success)

  const set_oidc_provider_admin = useSelector(
    (state) => state.oidc_providers.set_oidc_provider_admin.success,
  )

  const delete_oidc_provider_admin = useSelector(
    (state) => state.oidc_providers.delete_oidc_provider_admin.success,
  )

  // get Providers
  useEffect(() => {
    dispatch(getOidcProvidersAdmin())
  }, [
    dispatch,
    oidc_providers_admin_error?.message,
    post_oidc_provider,
    set_oidc_provider_admin,
    delete_oidc_provider_admin,
  ])
  const columns = useTableColumns(TableId.IDENTITY_PROVIDER)

  return (
    <>
      <Collapse title={t('addIdentityProvider.AddOidcProvider')} formExpanded={false}>
        <FormAddOidcProvider />
      </Collapse>
      <EditTableWrapper tableId={TableId.IDENTITY_PROVIDER} />
      <Table
        columns={columns}
        data={oidc_providers_admin}
        pending={oidc_providers_admin_pending}
        error={oidc_providers_admin_error}
        setError={getOidcProvidersAdminClearError}
        url="/management/identity_providers/oidc/"
        rowUrlAccessor="id"
      />
    </>
  )
}
