import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import Image from '../Image'
import ImageSrc from '../../assets/inventory.svg'
import {createInventoryType, createInventoryTypeClearError} from '../../stores/inventories'
import {
  createAndSetInventoryTypePhoto,
  createAndSetInventoryTypePhotoClearError,
} from '../../stores/inventories_photos'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'

export default function FormAddInventoryType() {
  const dispatch = useDispatch()
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const {pending: pendingInventory, error: errorInventory} = useSelector(
    (state) => state.inventories.create_inventory_type,
  )
  const {error, success, pending} = useSelector(
    (state) => state.inventories_photos.create_and_set_inventory_type_photo,
  )
  const {t} = useTranslation()

  const [typeImage, setTypeImage] = useState(ImageSrc)

  const handleSubmit = async (values) => {
    const response = await dispatch(createInventoryType({name: values.name}, userID))
    if (typeImage !== ImageSrc && response.id) {
      let blob = await fetch(typeImage).then((r) => r.blob())
      await dispatch(
        createAndSetInventoryTypePhoto(`/api/v1/inventories_types/${response.id}/photos`, blob),
      )
    }
  }

  const fields = [
    {
      label: t('uploadFile'),
      component: InputComponent.FILE,
      name: 'inventory-type-fileInput',
      validationType: YupValidationType.OBJECT,
      onUpdate: (fileFieldValue) => {
        const imageURl = fileFieldValue && URL.createObjectURL(fileFieldValue)
        setTypeImage(imageURl)
      },
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[2, 3], t('yupError.FileType')],
        },
        {
          type: InputYupValidationsType.FILESIZE,
          params: [10000000, t('yupError.MaxFileSize')],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.FileRequired')],
        },
      ],
    },
    {
      component: InputComponent.TEXT_INPUT,
      label: 'userManagement.Name',
      name: 'name',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.FileRequired')],
        },
      ],
    },
  ]

  return (
    <>
      <Image ImgSrc={typeImage} alt="inventoryTypePHoto" />
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-add-inventory-type',
          submitButtonText: t('addInventoryForm.InventoryAdd'),
        }}
        onSubmit={handleSubmit}
        pending={pending || pendingInventory}
        noFormBorder
        infoMessage={
          <Notification
            error={error || errorInventory}
            setError={() =>
              dispatch(
                error
                  ? createAndSetInventoryTypePhotoClearError()
                  : createInventoryTypeClearError(),
              )
            }
            success={!!success}
          />
        }
      />
    </>
  )
}
