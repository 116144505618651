import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {createEmployee, createEmployeeClearError} from '../../stores/employees'
import {getCompaniesCLearError, deleteCompanyClearError} from '../../stores/companies'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import ErrorOnView from '../ErrorOnView'
import {InputComponent, InputYupValidationsType} from '../formRendererRHF/types'
import AddNewItem from '../AddNewItemRHF'
import {ModalContentsBase} from '../Modal'
import FormAddCompany from './FormAddCompany'
import FormEditCompany from './FormEditCompany'
import {useCompanyOptions} from '../../hooks/useCompanies'

export default function FormAddEmployee() {
  const dispatch = useDispatch()
  const get_companiesError = useSelector((state) => state.companies.get_companies.error)
  const {
    error: create_employee_error,
    successMessage: create_employee_success,
    pending: create_employee_pending,
  } = useSelector((state) => state.employees.create_employee)
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const {t} = useTranslation()
  const delete_company = useSelector((state) => state.companies.delete_company)

  const {companies, defaultCompany} = useCompanyOptions()

  const handleSubmit = (values) => {
    delete values['add-company']
    dispatch(createEmployee(values, true, userID))
  }

  const fields = [
    {
      component: InputComponent.ASYNCREACTSELECT,
      name: 'person_id',
      label: t('addVisitor.AddPerson'),
      optionLabel: 'full_name',
      optionValue: 'id',
      url: '/api/v1/persons?filter=is:unemployed&q=',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.SELECT,
      name: `company_id`,
      label: t('employeeCompanyInfo.Company'),
      placeholder: t('addEmployee.CompanyPlaceholder'),
      isMulti: false,
      options: companies,
      defaultValue: defaultCompany?.id,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      component: InputComponent.CUSTOM,
      name: 'add-company',
      label: 'add-company',
      renderFn: permissions.createCompany ? (
        <AddNewItem
          fieldName="company_id"
          options={companies}
          label={t('employeeCompanyInfo.ManageCompany')}
          addComponent={
            <ModalContentsBase title={t('addCompanyForm.AddCompanyTitle')}>
              <FormAddCompany />
            </ModalContentsBase>
          }
          deleteState={delete_company}
          setDeleteError={deleteCompanyClearError}
          editComponent={(selectedItem) => (
            <ModalContentsBase title={t('addCompanyForm.EditCompany')}>
              <FormEditCompany notify={true} selectedItem={selectedItem} />
            </ModalContentsBase>
          )}
        />
      ) : null,
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: t('addEmployee.Domain'),
      placeholder: t('addEmployee.DomainPlaceholder'),
      isMulti: false,
      options: permissions.createEmployeeLocations,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.ThisFieldIsRequired')],
        },
      ],
    },
    {
      label: t('login.Email'),
      component: InputComponent.TEXT_INPUT,
      name: 'email',
      validations: [
        {
          type: InputYupValidationsType.EMAIL,
          params: [t('yupError.EmailInvalid')],
        },
      ],
    },
    {
      label: t('addEmployee.MainOffice'),
      component: InputComponent.TEXT_INPUT,
      name: 'mainoffice',
    },
    {
      label: t('addEmployee.CostCenter'),
      component: InputComponent.TEXT_INPUT,
      name: 'costcenter',
    },
    {
      label: t('addEmployee.Number'),
      component: InputComponent.TEXT_INPUT,
      name: 'employee_number',
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-add-employee',
          submitButtonText: t('addEmployee.AddEmployee'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={create_employee_pending}
        infoMessage={
          <Notification
            error={create_employee_error}
            setError={() => dispatch(createEmployeeClearError())}
            success={create_employee_success}
          />
        }
      />
      {!!get_companiesError && (
        <ErrorOnView
          error={get_companiesError}
          setError={() => dispatch(getCompaniesCLearError())}
        />
      )}
    </>
  )
}
