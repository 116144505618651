import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'TableLabelsCommon.CreatedAt',
    label: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
  },
  {
    header: 'visitationList.VisitationFrom',
    label: 'visitationList.VisitationFrom',
    accessor: 'from_dt',
    cell: 'DateCell',
  },
  {
    header: 'TableLabelsCommon.City',
    accessor: 'host_company.city',
    label: 'TableLabelsCommon.City',
  },
  {
    header: 'TableLabelsCommon.Country',
    accessor: 'host_company.country',
    label: 'TableLabelsCommon.Country',
  },
  {
    header: 'visitationList.VisitationHostCompany',
    accessor: 'host_company.name',
    label: 'visitationList.VisitationHostCompany',
  },
  {
    header: 'TableLabelsCommon.CompanyUniLocationCode',
    accessor: 'host_company.uni_location_code',
    label: 'TableLabelsCommon.CompanyUniLocationCode',
  },
  {
    header: 'TableLabelsCommon.Zip',
    accessor: 'host_company.zip',
    label: 'TableLabelsCommon.Zip',
  },
  {
    header: 'TableLabelsCommon.EmployeeId',
    label: 'TableLabelsCommon.EmployeeId',
    accessor: 'host_employee.employee_number',
  },
  {
    header: 'visitationList.VisitationManager',
    label: 'visitationList.VisitationManager',
    accessor: 'host_employee.person.full_name',
  },

  {
    header: 'TableLabelsCommon.Name',
    accessor: 'name',
    label: 'TableLabelsCommon.Name',
    cell: 'TwoRowCellLink',
    idAccessor: 'id',
    secondAccessor: 'status',
  },
  {
    header: 'TableLabelsCommon.Status',
    accessor: 'status',
    label: 'TableLabelsCommon.Status',
  },
  {
    header: 'visitationList.VisitationTo',
    label: 'visitationList.VisitationTo',
    accessor: 'to_dt',
    cell: 'DateCell',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    label: 'TableLabelsCommon.UpdatedAt',
    cell: 'DateCell',
  },
  {
    header: 'visitationList.Actions',
    label: 'visitationList.Actions',
    accessor: 'id',
    cell: 'MeetingActionCell',
    noSort: true,
  },
]
export default columnConfig
