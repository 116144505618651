import axios from '../services/api'
import {createNotification} from './notifications'
import I18n from '../i18n'

// Types
const DELETE_ACCESS_PROFILE_PENDING = 'idfunctiondb/access_profiles/DELETE_ACCESS_PROFILE_PENDING'
const DELETE_ACCESS_PROFILE_SUCCESS = 'idfunctiondb/access_profiles/DELETE_ACCESS_PROFILE_SUCCESS'
const DELETE_ACCESS_PROFILE_ERROR = 'idfunctiondb/access_profiles/DELETE_ACCESS_PROFILE_ERROR'
const DELETE_ACCESS_PROFILE_CLEAR_ERROR =
  'idfunctiondb/access_profiles/DELETE_ACCESS_PROFILE_CLEAR_ERROR'
const DELETE_ACCESS_PROFILE_CHILD_PENDING =
  'idfunctiondb/access_profiles/DELETE_ACCESS_CHILD_PROFILE_PENDING'
const DELETE_ACCESS_PROFILE_CHILD_SUCCESS =
  'idfunctiondb/access_profiles/DELETE_ACCESS_CHILD_PROFILE_SUCCESS'
const DELETE_ACCESS_PROFILE_CHILD_ERROR =
  'idfunctiondb/access_profiles/DELETE_ACCESS_CHILD_PROFILE_ERROR'
const DELETE_ACCESS_PROFILE_CHILD_CLEAR_ERROR =
  'idfunctiondb/access_profiles/DELETE_ACCESS_PROFILE_CHILD_CLEAR_ERROR'

const DELETE_ACCESS_PROFILE_TYPE_PENDING =
  'idfunctiondb/access_profiles/DELETE_ACCESS_PROFILE_TYPE_PENDING'
const DELETE_ACCESS_PROFILE_TYPE_SUCCESS =
  'idfunctiondb/access_profiles/DELETE_ACCESS_PROFILE_TYPE_SUCCESS'
const DELETE_ACCESS_PROFILE_TYPE_ERROR =
  'idfunctiondb/access_profiles/DELETE_ACCESS_PROFILE_TYPE_ERROR'
const DELETE_ACCESS_PROFILE_TYPE_CLEAR_ERROR =
  'idfunctiondb/access_profiles/DELETE_ACCESS_PROFILE_TYPE_CLEAR_ERROR'

const CREATE_ACCESS_PROFILE_PENDING = 'idfunctiondb/access_profiles/CREATE_ACCESS_PROFILE_PENDING'
const CREATE_ACCESS_PROFILE_SUCCESS = 'idfunctiondb/access_profiles/CREATE_ACCESS_PROFILE_SUCCESS'
const CREATE_ACCESS_PROFILE_ERROR = 'idfunctiondb/access_profiles/CREATE_ACCESS_PROFILE_ERROR'
const CREATE_ACCESS_PROFILE_CLEAR_ERROR =
  'idfunctiondb/access_profiles/CREATE_ACCESS_PROFILE_CLEAR_ERROR'

const GET_ACCESS_PROFILES_TYPES_PENDING =
  'idfunctiondb/access_profiles/GET_ACCESS_PROFILES_TYPES_PENDING'
const GET_ACCESS_PROFILES_TYPES_SUCCESS =
  'idfunctiondb/access_profiles/GET_ACCESS_PROFILES_TYPES_SUCCESS'
const GET_ACCESS_PROFILES_TYPES_ERROR =
  'idfunctiondb/access_profiles/GET_ACCESS_PROFILES_TYPES_ERROR'
const GET_ACCESS_PROFILES_TYPES_CLEAR_ERROR =
  'idfunctiondb/access_profiles/GET_ACCESS_PROFILES_TYPES_CLEAR_ERROR'

const CREATE_ACCESS_PROFILE_TYPE_PENDING =
  'idfunctiondb/access_profiles/CREATE_ACCESS_PROFILE_TYPE_PENDING'
const CREATE_ACCESS_PROFILE_TYPE_SUCCESS =
  'idfunctiondb/access_profiles/CREATE_ACCESS_PROFILE_TYPE_SUCCESS'
const CREATE_ACCESS_PROFILE_TYPE_ERROR =
  'idfunctiondb/access_profiles/CREATE_ACCESS_PROFILE_TYPE_ERROR'
const CREATE_ACCESS_PROFILE_TYPE_CLEAR_ERROR =
  'idfunctiondb/access_profiles/CREATE_ACCESS_PROFILE_TYPE_CLEAR_ERROR'

const UPDATE_ACCESS_PROFILE_TYPE_PENDING =
  'idfunctiondb/access_profiles/UPDATE_ACCESS_PROFILE_TYPE_PENDING'
const UPDATE_ACCESS_PROFILE_TYPE_SUCCESS =
  'idfunctiondb/access_profiles/UPDATE_ACCESS_PROFILE_TYPE_SUCCESS'
const UPDATE_ACCESS_PROFILE_TYPE_ERROR =
  'idfunctiondb/access_profiles/UPDATE_ACCESS_PROFILE_TYPE_ERROR'
const UPDATE_ACCESS_PROFILE_TYPE_CLEAR_ERROR =
  'idfunctiondb/access_profiles/UPDATE_ACCESS_PROFILE_TYPE_ERROR'

const GET_ACCESS_PROFILE_PENDING = 'idfunctiondb/access_profiles/GET_ACCESS_PROFILE_PENDING'
const GET_ACCESS_PROFILE_SUCCESS = 'idfunctiondb/access_profiles/GET_ACCESS_PROFILE_SUCCESS'
const GET_ACCESS_PROFILE_ERROR = 'idfunctiondb/access_profiles/GET_ACCESS_PROFILE_ERROR'
const GET_ACCESS_PROFILE_CLEAR_ERROR = 'idfunctiondb/access_profiles/GET_ACCESS_PROFILE_ERROR'

// Initial
const initialState = {
  delete_access_profile: {
    success: null,
    pending: false,
    error: null,
  },

  delete_access_profile_child: {
    success: null,
    pending: false,
    error: null,
  },

  delete_access_profile_type: {
    success: null,
    pending: false,
    error: null,
  },

  get_access_profiles_types: {
    success: [],
    pending: false,
    error: null,
  },

  create_access_profile_type: {
    success: null,
    pending: false,
    error: null,
  },

  create_access_profile: {
    success: null,
    pending: false,
    error: null,
  },

  update_access_profile_type: {
    success: null,
    pending: false,
    error: null,
  },

  get_access_profile: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DELETE_ACCESS_PROFILE_PENDING:
      return {
        ...state,
        delete_access_profile: {
          ...state.delete_access_profile,
          pending: true,
        },
      }
    case DELETE_ACCESS_PROFILE_SUCCESS:
      return {
        ...state,
        delete_access_profile: {
          ...state.delete_access_profile,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_ACCESS_PROFILE_ERROR:
      return {
        ...state,
        delete_access_profile: {
          ...state.delete_access_profile,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_ACCESS_PROFILE_CLEAR_ERROR:
      return {
        ...state,
        delete_access_profile: {
          ...state.delete_access_profile,
          error: null,
        },
      }
    case DELETE_ACCESS_PROFILE_CHILD_PENDING:
      return {
        ...state,
        delete_access_profile_child: {
          ...state.delete_access_profile_child,
          pending: true,
        },
      }
    case DELETE_ACCESS_PROFILE_CHILD_SUCCESS:
      return {
        ...state,
        delete_access_profile_child: {
          ...state.delete_access_profile_child,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_ACCESS_PROFILE_CHILD_ERROR:
      return {
        ...state,
        delete_access_profile_child: {
          ...state.delete_access_profile_child,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_ACCESS_PROFILE_CHILD_CLEAR_ERROR:
      return {
        ...state,
        delete_access_profile_child: {
          ...state.delete_access_profile_child,
          error: null,
        },
      }
    case DELETE_ACCESS_PROFILE_TYPE_PENDING:
      return {
        ...state,
        delete_access_profile_type: {
          ...state.delete_access_profile_type,
          pending: true,
        },
      }
    case DELETE_ACCESS_PROFILE_TYPE_SUCCESS:
      return {
        ...state,
        delete_access_profile_type: {
          ...state.delete_access_profile_type,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_ACCESS_PROFILE_TYPE_ERROR:
      return {
        ...state,
        delete_access_profile_type: {
          ...state.delete_access_profile_type,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_ACCESS_PROFILE_TYPE_CLEAR_ERROR:
      return {
        ...state,
        delete_access_profile_type: {
          ...state.delete_access_profile_type,
          error: null,
        },
      }
    case CREATE_ACCESS_PROFILE_PENDING:
      return {
        ...state,
        create_access_profile: {
          ...state.create_access_profile,
          pending: true,
        },
      }
    case CREATE_ACCESS_PROFILE_SUCCESS:
      return {
        ...state,
        create_access_profile: {
          ...state.create_access_profile,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_ACCESS_PROFILE_ERROR:
      return {
        ...state,
        create_access_profile: {
          ...state.create_access_profile,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_ACCESS_PROFILE_CLEAR_ERROR:
      return {
        ...state,
        create_access_profile: {
          ...state.create_access_profile,
          error: null,
          pending: false,
        },
      }
    case GET_ACCESS_PROFILES_TYPES_PENDING:
      return {
        ...state,
        get_access_profiles_types: {
          ...state.get_access_profiles_types,
          pending: true,
        },
      }
    case GET_ACCESS_PROFILES_TYPES_SUCCESS:
      return {
        ...state,
        get_access_profiles_types: {
          ...state.get_access_profiles_types,
          success: action.payload,
          pending: false,
        },
      }
    case GET_ACCESS_PROFILES_TYPES_ERROR:
      return {
        ...state,
        get_access_profiles_types: {
          ...state.get_access_profiles_types,
          error: action.payload,
          pending: false,
        },
      }
    case GET_ACCESS_PROFILES_TYPES_CLEAR_ERROR:
      return {
        ...state,
        get_access_profiles_types: {
          ...state.get_access_profiles_types,
          error: null,
          pending: false,
        },
      }
    case CREATE_ACCESS_PROFILE_TYPE_PENDING:
      return {
        ...state,
        create_access_profile_type: {
          ...state.create_access_profile_type,
          pending: true,
        },
      }
    case CREATE_ACCESS_PROFILE_TYPE_SUCCESS:
      return {
        ...state,
        create_access_profile_type: {
          ...state.create_access_profile_type,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_ACCESS_PROFILE_TYPE_ERROR:
      return {
        ...state,
        create_access_profile_type: {
          ...state.create_access_profile_type,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_ACCESS_PROFILE_TYPE_CLEAR_ERROR:
      return {
        ...state,
        create_access_profile_type: {
          ...state.create_access_profile_type,
          error: null,
          pending: false,
        },
      }
    case UPDATE_ACCESS_PROFILE_TYPE_PENDING:
      return {
        ...state,
        update_access_profile_type: {
          ...state.update_access_profile_type,
          pending: true,
        },
      }
    case UPDATE_ACCESS_PROFILE_TYPE_SUCCESS:
      return {
        ...state,
        update_access_profile_type: {
          ...state.update_access_profile_type,
          success: action.payload,
          pending: false,
        },
      }
    case UPDATE_ACCESS_PROFILE_TYPE_ERROR:
      return {
        ...state,
        update_access_profile_type: {
          ...state.update_access_profile_type,
          error: action.payload,
          pending: false,
        },
      }
    case UPDATE_ACCESS_PROFILE_TYPE_CLEAR_ERROR:
      return {
        ...state,
        update_access_profile_type: {
          ...state.update_access_profile_type,
          error: null,
          pending: false,
        },
      }
    case GET_ACCESS_PROFILE_PENDING:
      return {
        ...state,
        get_access_profile: {
          ...state.update_acceget_access_profiless_profile_type,
          pending: true,
        },
      }
    case GET_ACCESS_PROFILE_SUCCESS:
      return {
        ...state,
        get_access_profile: {
          ...state.get_access_profile,
          success: action.payload,
          pending: false,
        },
      }
    case GET_ACCESS_PROFILE_ERROR:
      return {
        ...state,
        get_access_profile: {
          ...state.get_access_profile,
          error: action.payload,
          pending: false,
        },
      }

    default:
      return state
  }
}

// Actions
function deleteAccessProfilePending() {
  return {type: DELETE_ACCESS_PROFILE_PENDING}
}

function deleteAccessProfileSuccess(payload) {
  return {type: DELETE_ACCESS_PROFILE_SUCCESS, payload: payload}
}

function deleteAccessProfileError(error) {
  return {type: DELETE_ACCESS_PROFILE_ERROR, payload: error}
}

export function deleteAccessProfileClearError() {
  return {type: DELETE_ACCESS_PROFILE_CLEAR_ERROR}
}

function deleteAccessProfileChildPending() {
  return {type: DELETE_ACCESS_PROFILE_CHILD_PENDING}
}

function deleteAccessProfileChildSuccess(payload) {
  return {type: DELETE_ACCESS_PROFILE_CHILD_SUCCESS, payload: payload}
}

function deleteAccessProfileChildError(error) {
  return {type: DELETE_ACCESS_PROFILE_CHILD_ERROR, payload: error}
}

export function deleteAccessProfileChildClearError() {
  return {type: DELETE_ACCESS_PROFILE_CHILD_CLEAR_ERROR}
}

function deleteAccessProfileTypePending() {
  return {type: DELETE_ACCESS_PROFILE_TYPE_PENDING}
}

function deleteAccessProfileTypeSuccess(payload) {
  return {type: DELETE_ACCESS_PROFILE_TYPE_SUCCESS, payload: payload}
}

function deleteAccessProfileTypeError(error) {
  return {type: DELETE_ACCESS_PROFILE_TYPE_ERROR, payload: error}
}

export function deleteAccessProfileTypeClearError() {
  return {type: DELETE_ACCESS_PROFILE_TYPE_CLEAR_ERROR}
}

function createAccessProfilePending() {
  return {type: CREATE_ACCESS_PROFILE_PENDING}
}

function createAccessProfileSuccess(payload) {
  return {type: CREATE_ACCESS_PROFILE_SUCCESS, payload: payload}
}

function createAccessProfileError(error) {
  return {type: CREATE_ACCESS_PROFILE_ERROR, payload: error}
}

export function createAccessProfileClearError() {
  return {type: CREATE_ACCESS_PROFILE_CLEAR_ERROR}
}

function getAccessProfilesTypesPending() {
  return {type: GET_ACCESS_PROFILES_TYPES_PENDING}
}

function getAccessProfilesTypesSuccess(payload) {
  return {type: GET_ACCESS_PROFILES_TYPES_SUCCESS, payload: payload}
}

function getAccessProfilesTypesError(error) {
  return {type: GET_ACCESS_PROFILES_TYPES_ERROR, payload: error}
}

export function getAccessProfilesTypesClearError() {
  return {type: GET_ACCESS_PROFILES_TYPES_CLEAR_ERROR}
}

function createAccessProfileTypePending() {
  return {type: CREATE_ACCESS_PROFILE_TYPE_PENDING}
}

function createAccessProfileTypeSuccess(payload) {
  return {type: CREATE_ACCESS_PROFILE_TYPE_SUCCESS, payload: payload}
}

function createAccessProfileTypeError(error) {
  return {type: CREATE_ACCESS_PROFILE_TYPE_ERROR, payload: error}
}

export function createAccessProfileTypeClearError() {
  return {type: CREATE_ACCESS_PROFILE_TYPE_CLEAR_ERROR}
}

function updateAccessProfileTypePending() {
  return {type: UPDATE_ACCESS_PROFILE_TYPE_PENDING}
}

function updateAccessProfileTypeSuccess(payload) {
  return {type: UPDATE_ACCESS_PROFILE_TYPE_SUCCESS, payload: payload}
}

function updateAccessProfileTypeError(error) {
  return {type: UPDATE_ACCESS_PROFILE_TYPE_ERROR, payload: error}
}

export function updateAccessProfileTypeClearError() {
  return {type: UPDATE_ACCESS_PROFILE_TYPE_CLEAR_ERROR}
}

function getAccessProfilePending() {
  return {type: GET_ACCESS_PROFILE_PENDING}
}

function getAccessProfileSuccess(payload) {
  return {type: GET_ACCESS_PROFILE_SUCCESS, payload}
}

function getAccessProfileError(error) {
  return {type: GET_ACCESS_PROFILE_ERROR, payload: error}
}

export function getAccessProfileClearError() {
  return {type: GET_ACCESS_PROFILE_CLEAR_ERROR}
}

// Operations

export const deleteAccessProfile = (id) => (dispatch) => {
  dispatch(deleteAccessProfilePending())
  return axios
    .delete(`/api/v1/access_profiles/${id}`)
    .then((res) => {
      dispatch(deleteAccessProfileSuccess(res.data))

      return res.data
    })
    .catch((error) => dispatch(deleteAccessProfileError(error)))
}

export const deleteAccessProfileChild = (id, cid) => (dispatch) => {
  dispatch(deleteAccessProfileChildPending())
  return axios
    .delete(`/api/v1/access_profiles/${id}/children/${cid}`)
    .then((res) => {
      dispatch(deleteAccessProfileChildSuccess(res.data))

      return res.data
    })
    .catch((error) => dispatch(deleteAccessProfileChildError(error)))
}

export const deleteAccessProfileType = (id) => (dispatch) => {
  dispatch(deleteAccessProfileTypePending())
  return axios
    .delete(`/api/v1/access_profiles_types/${id}`)
    .then((res) => {
      dispatch(deleteAccessProfileTypeSuccess(res.data))
    })
    .catch((error) => {
      dispatch(deleteAccessProfileTypeError(error))
    })
}

export const createAccessProfile = (values, id) => (dispatch) => {
  dispatch(createAccessProfilePending())
  return axios
    .post(`/api/v1/access_profiles`, values)
    .then((res) => {
      dispatch(createAccessProfileSuccess(res))
      dispatch(
        createNotification(id, {
          code: '201',
          message: I18n.t('notificationSuccess.AccessProfileCreated', {name: res.data.name}),
        }),
      )
    })
    .catch((error) => dispatch(createAccessProfileError(error)))
}

export const getAccessProfilesTypes = () => (dispatch) => {
  dispatch(getAccessProfilesTypesPending())
  return axios
    .get(`/api/v1/access_profiles_types`)
    .then((res) => {
      dispatch(getAccessProfilesTypesSuccess(res.data.results))
      return res.data
    })
    .catch((error) => dispatch(getAccessProfilesTypesError(error)))
}

export const createAccessProfileType = (values, id) => (dispatch) => {
  dispatch(createAccessProfileTypePending())
  return axios
    .post(`/api/v1/access_profiles_types`, values)
    .then((res) => {
      dispatch(createAccessProfileTypeSuccess(res))
      dispatch(
        createNotification(id, {
          code: '201',
          message: I18n.t('notificationSuccess.AccessProfileTypeCreated', {name: res.data.name}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(createAccessProfileTypeError(error)))
}

export const updateAccessProfileType = (url, values, id) => (dispatch) => {
  dispatch(updateAccessProfileTypePending())
  return axios
    .put(url, values)
    .then((res) => {
      dispatch(updateAccessProfileTypeSuccess(res))
      dispatch(
        createNotification(id, {
          code: '201',
          message: I18n.t('notificationSuccess.AccessProfileTypeUpdated', {name: res.data.name}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(updateAccessProfileTypeError(error)))
}

export const getAccessProfile = (id) => (dispatch) => {
  dispatch(getAccessProfilePending())
  return axios
    .get(`/api/v1/access_profiles/${id}`)
    .then((res) => {
      dispatch(getAccessProfileSuccess(res?.data))
      return res.data
    })
    .catch((error) => dispatch(getAccessProfileError(error)))
}
