import ErrorOnView from './ErrorOnView'
import InfoMessage from './InfoMessage'
import {useTranslation} from 'react-i18next'
import {useEffect} from 'react'

const Notification = ({error, setError, success}) => {
  const {t} = useTranslation()
  useEffect(() => {
    return () => {
      error && setError && setError()
      success && setError && setError()
    }
  }, [error, setError, success])

  return (
    <>
      {!!error && <ErrorOnView error={error} setError={() => setError()} />}
      {!!success && (
        <InfoMessage
          message={t(typeof success === 'string' ? success : 'notificationSuccess.General')}
          type="success"
          onClose={() => {
            setError()
          }}
        />
      )}
    </>
  )
}

export default Notification
