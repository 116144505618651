import {drawBlobOnCanvas} from '../utils/camera'
import axios from '../services/api'

// Types
const BACKGROUND_EXTRACTION_PENDING =
  'idfunctiondb/background_extraction/BACKGROUND_EXTRACTION_PENDING'
const BACKGROUND_EXTRACTION_SUCCESS =
  'idfunctiondb/background_extraction/BACKGROUND_EXTRACTION_SUCCESS'
const BACKGROUND_EXTRACTION_ERROR = 'idfunctiondb/background_extraction/BACKGROUND_EXTRACTION_ERROR'

const initialState = {
  background_extraction: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case BACKGROUND_EXTRACTION_PENDING:
      return {
        ...state,
        background_extraction: {
          ...state.background_extraction,
          pending: true,
        },
      }
    case BACKGROUND_EXTRACTION_SUCCESS:
      return {
        ...state,
        background_extraction: {
          ...state.background_extraction,
          success: action.payload,
          pending: false,
        },
      }
    case BACKGROUND_EXTRACTION_ERROR:
      return {
        ...state,
        background_extraction: {
          ...state.background_extraction,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function backgroundExtractionPending() {
  return {type: BACKGROUND_EXTRACTION_PENDING}
}
function backgroundExtractionSuccess(payload) {
  return {type: BACKGROUND_EXTRACTION_SUCCESS, payload: payload}
}
function backgroundExtractionError(error) {
  return {type: BACKGROUND_EXTRACTION_ERROR, payload: JSON.parse(error)}
}

// Operations
export const backgroundExtraction = (blob, imageManipulationCanvas, setSelected) => (dispatch) => {
  dispatch(backgroundExtractionPending())
  let form = new FormData()
  form.append('webcam', blob, 'webcam.jpg')
  form.append('action', 'extraction')
  axios
    .post(`/api/v1/camstream/create_profile_image`, form, {responseType: 'blob'})
    .then((res) => {
      dispatch(backgroundExtractionSuccess(res.data))
      drawBlobOnCanvas(imageManipulationCanvas, res.data)
      setSelected(res.data)
    })
    .catch((err) => {
      Promise.resolve(err).then(async (x) =>
        dispatch(backgroundExtractionError(await x.response.data.text())),
      )
    })
}
