import React from 'react'
import * as tf from '@tensorflow/tfjs'
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm'
import {drawRoiOnCanvas} from '../utils/camera'
import {takeSnapshot} from '../stores/camera'
import {connect} from 'react-redux'
import {CameraButtons} from './Buttons'

// Things to cover:
// 1 - ❌ user has no webcam, just a microphone
// 2 - ✅ user has (accidentally) denied access to the webcam
// 3 - ❌ user plugs in the webcam/microphone after your getUserMedia() code has initialized
// 4 - ❌ the device is already used by another app on Windows
// 5 - ❌ Firefox only: the device is already used by another Firefox tab
// 6 - ❌ Chrome only: the user dismisses the privacy dialog
// 7 - ✅ Apple mobile devices are now opening the webcam.
// 8 - ❌ handle when browser doesn't support getUserMedia

///////////////////////////////////// polyfill

// polyfill based on https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
;(function polyfillGetUserMedia() {
  if (typeof window === 'undefined') {
    return
  }

  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {}
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      const getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
    }
  }
})()

tfjsWasm.setWasmPath('/tfjs-backend-wasm.wasm')

const blazeface = require('@evoid/blazeface')

class CameraAi extends React.Component {
  constructor(props) {
    super(props)
    this.play = true
    this.stream = ''
    this.portraitMode = false
    this.ref = React.createRef()
    this.refCanvas = React.createRef()
    this.refVideo = React.createRef()
    this.helpCanvas = React.createRef()
    this.videoCurrentWidth = 0
    this.videoCurrentHeight = 0
    this.setShowCamera = this.props.setShowCamera
    this.setDisableButtons = this.props.setDisableButtons
    this.onClose = this.props.onClose
    this.setCapturedImages = this.props.setCapturedImages
    this.takeSnapshot = this.props.takeSnapshot
  }

  async componentDidMount() {
    await tf.setBackend('wasm')
    await this.setupCamera().then(() => {
      let video = this.refVideo.current
      video.play()
      let videoWidth = this.refVideo.current.videoWidth
      let videoHeight = this.refVideo.current.videoHeight
      this.refVideo.current.width = videoWidth
      this.refVideo.current.height = videoHeight
      this.refCanvas.current.width = videoWidth
      this.refCanvas.current.height = videoHeight
      this.helpCanvas.current.width = videoWidth
      this.helpCanvas.current.height = videoHeight
      this.renderPrediction()
    })
  }

  renderPrediction = async () => {
    tf.engine().startScope()
    const model = await blazeface.load({maxFaces: 1, scoreThreshold: 0.95})
    if (this.play) {
      let videoWidth = this.refVideo.current.videoWidth
      let videoHeight = this.refVideo.current.videoHeight
      if (
        this.videoCurrentWidth !== 0 &&
        this.videoCurrentHeight !== 0 &&
        (this.videoCurrentWidth !== videoWidth || this.videoCurrentHeight !== videoHeight)
      ) {
        this.refVideo.current.width = this.refCanvas.current.width = videoWidth
        this.refVideo.current.height = this.refCanvas.current.height = videoHeight
      }
      this.videoCurrentWidth = videoWidth
      this.videoCurrentHeight = videoHeight
      const canvas = this.refCanvas.current
      const ctx = canvas.getContext('2d')
      const returnTensors = false
      const flipHorizontal = true
      const annotateBoxes = true
      const predictions = await model.estimateFaces(
        this.refVideo.current,
        returnTensors,
        flipHorizontal,
        annotateBoxes,
      )

      if (predictions.length > 0) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        for (let i = 0; i < predictions.length; i++) {
          if (returnTensors) {
            predictions[i].topLeft = predictions[i].topLeft.arraySync()
            predictions[i].bottomRight = predictions[i].bottomRight.arraySync()
            if (annotateBoxes) {
              predictions[i].landmarks = predictions[i].landmarks.arraySync()
            }
          }
          try {
          } catch (err) {
            console.log(err.message)
          }

          this.portraitMode =
            videoWidth < videoHeight && window.matchMedia('(orientation: portrait)').matches
          drawRoiOnCanvas(canvas, ctx, predictions[i], this.portraitMode, this.setDisableButtons)
        }
      }
      requestAnimationFrame(this.renderPrediction)
    }
    tf.engine().endScope()
  }

  setupCamera = async () => {
    const video = this.refVideo.current
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width: {
          min: 640, // 1280 - 640  are right dimensions for ios conditions screwed up on 1280 errors happen
        },
        height: {
          min: 480, // 960 - 480
        },
        facingMode: 'user',
      },
    })
    this.stream = stream
    video.srcObject = stream

    return new Promise((resolve) => {
      video.onloadedmetadata = () => {
        resolve(video)
      }
    })
  }

  capturePhoto = () => {
    const video = this.refVideo.current
    const canvas = this.helpCanvas.current
    // scale the canvas accordingly
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    // draw the video at that frame and apply mirror effect
    let ctx = canvas.getContext('2d')
    ctx.scale(-1, 1)
    ctx.drawImage(video, -canvas.width, 0)
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    // convert it to a usable data URL
    const dataURL = canvas.toDataURL()
    this.setShowCamera(false)
    this.takeSnapshot(dataURL, this.setCapturedImages, video.videoWidth, video.videoHeight)
  }

  componentWillUnmount() {
    this.play = false
    let video = this.refVideo.current
    video.pause()
    video.src = ''
    if (this.stream !== '') {
      this.stream.getTracks()[0].stop()
    }
  }

  render() {
    return (
      <div>
        <div id="helo" className="relative text-center">
          <div className=" relative">
            <video
              className="z-20 mx-auto -scale-x-100 p-3 lg:m-1"
              ref={this.refVideo}
              playsInline
            ></video>
            <canvas
              className="absolute  top-0 z-20 mx-auto h-full w-full p-3 lg:m-1 "
              ref={this.refCanvas}
            />
            <canvas
              className="absolute  top-0 -z-10 mx-auto h-full w-full -scale-x-100  p-3 lg:m-1"
              ref={this.helpCanvas}
            />
          </div>
        </div>
        <CameraButtons disabled={this.props.disableButtons} onClick={() => this.capturePhoto()} />
      </div>
    )
  }
}

export default connect(null, {takeSnapshot})(CameraAi)
