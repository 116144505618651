import {useEffect, useRef} from 'react'
import {Formik, Form} from 'formik'
import {TextInput, SelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import {
  getInventoriesTypes,
  getInventoriesTypesClearError,
  deleteInventoryType,
  deleteInventoryTypeClearError,
  createInventory,
  createInventoryClearError,
} from '../../stores/inventories'
import {FormikSaveButton} from '../Buttons'
import FormAddInventoryType from './FormAddInventoryType'
import FormEditInventoryType from './FormEditInventoryType'
import DeletionWarning from '../DeletionWarning'

export default function FormAddInventory(props) {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const inventory_types = useSelector((state) => state.inventories.get_inventories_types.success)
  const inventory_types_error = useSelector(
    (state) => state.inventories.get_inventories_types.error,
  )
  const created_inventory_pending = useSelector(
    (state) => state.inventories.create_inventory.pending,
  )
  const created_inventory_error = useSelector((state) => state.inventories.create_inventory.error)
  const create_inventory_type_success = useSelector(
    (state) => state.inventories.create_inventory_type.success,
  )
  const delete_inventory_type_success = useSelector(
    (state) => state.inventories.delete_inventory_type.success,
  )
  const delete_inventory_type_pending = useSelector(
    (state) => state.inventories.delete_inventory_type.pending,
  )
  const delete_inventory_type_error = useSelector(
    (state) => state.inventories.delete_inventory_type.error,
  )
  const update_inventoryType_success = useSelector(
    (state) => state.inventories.update_inventory_type.success,
  )
  const create_and_set_inventory_type_photo_success = useSelector(
    (state) => state.inventories_photos.create_and_set_inventory_type_photo.success,
  )
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const initialValues = {
    inventory_type: '',
    model: '',
    inventory_number: '',
    color: '',
    manufacturer: '',
    location: '',
    owner: '',
  }

  const validationSchema = Yup.object({
    model: Yup.string()
      .max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '128'})) // 128
      .required(t('yupError.ThisFieldIsRequired', {field: t('addInventoryForm.InventoryModel')})),
    inventory_type: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addInventoryForm.InventoryType')}),
    ),
    inventory_number: Yup.string()
      .max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '64'})) // 64
      .required(t('yupError.ThisFieldIsRequired', {field: t('addInventoryForm.InventoryNumber')})),
    color: Yup.string().max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '128'})), // 128
    owner: Yup.string().max(30, t('yupError.MaxCharsAllowed', {numberOfChars: '128'})), // 128
  })

  useEffect(() => {
    dispatch(getInventoriesTypes())
  }, [
    dispatch,
    create_inventory_type_success,
    delete_inventory_type_success,
    update_inventoryType_success,
    create_and_set_inventory_type_photo_success,
    inventory_types_error?.message,
  ])

  let inventoryTypeSelectRef = useRef()
  let domainSelectRef = useRef()
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          dispatch(createInventory(values, props.userID))
          actions.resetForm()
          // resetting react-select components
          domainSelectRef.current.select.setValue('')
          inventoryTypeSelectRef.current.select.setValue('')
        }}
      >
        <Form>
          <SelectInput
            label={t('addInventoryForm.InventoryType')}
            createNewEntry={props.createInventoryLocations}
            addItem={<FormAddInventoryType />}
            addItemTitle={t('addInventoryTypeForm.AddInventoryTypeTitle')}
            editItem={<FormEditInventoryType />}
            editItemTitle={t('updateInventoryType.UpdateInventoryTypeTitle')}
            deleteItem={
              <DeletionWarning
                deleteFunction={deleteInventoryType}
                pending={delete_inventory_type_pending}
                error={delete_inventory_type_error}
                setError={deleteInventoryTypeClearError}
                text={t('confirmDeletion.Confirm')}
                message={t('confirmDeletion.DeleteInventoryType')}
              />
            }
            deleteItemTitle={t('confirmDeletion.ConfirmDeletion')}
            options={inventory_types.map((inventoryType) => ({
              value: inventoryType.id,
              label: `${inventoryType.name}`,
              name: inventoryType.name, // FormEditInventory is rendered at two places
              id: inventoryType.id, // these two props are necessary for the component
              inventoryTypeCurrentPhotoUrl: inventoryType.photos.current,
              inventoryTypeCurrentPhotoId: inventoryType.photos.current_id,
            }))}
            placeholder={t('addInventoryForm.InventoryTypePlaceholder')}
            name="inventory_type"
            innerRef={inventoryTypeSelectRef}
            required
            error={inventory_types_error}
            setError={getInventoriesTypesClearError}
          />
          <SelectInput
            label={t('addEmployee.Domain')}
            options={permissions.createInventoryLocations}
            placeholder={t('addEmployee.DomainPlaceholder')}
            name="location"
            innerRef={domainSelectRef}
            required
          />
          <TextInput
            label={t('addInventoryForm.InventoryModel')}
            name="model"
            required
            type="text"
            placeholder=""
          />
          <TextInput
            label={t('addInventoryForm.InventoryNumber')}
            name="inventory_number"
            type="text"
            required
            placeholder=""
          />
          <TextInput
            label={t('addInventoryForm.InventoryManufacturer')}
            name="manufacturer"
            type="text"
            placeholder=""
          />
          <TextInput
            label={t('addInventoryForm.InventoryColor')}
            name="color"
            type="text"
            placeholder=""
          />
          <TextInput
            label={t('addInventoryForm.InventoryOwner')}
            name="owner"
            type="text"
            placeholder=""
          />
          <FormikSaveButton
            type="submit"
            testid="form-add-inventory-submit"
            text={t('addInventoryForm.InventoryAdd')}
            primary
            pending={created_inventory_pending}
            error={created_inventory_error}
            setError={() => dispatch(createInventoryClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
