import Image from './Image'
import {useTranslation} from 'react-i18next'
import RearPlaceholder from '../assets/RearPlaceholderFromEntity.png'
import FrontPlaceholder from '../assets/FrontPlaceholderFromEntity.png'

export default function IdentityCardImages({identityCard}) {
  const {t} = useTranslation()
  return (
    <div>
      <div className="flex justify-center">
        <span className="mt-1 text-center text-sm">
          {t('cardInfo.Badgenr')} : {identityCard.card.badgenr} | {t('cardInfo.Profile')} :{' '}
          {identityCard.card.profile?.name}
        </span>
      </div>
      <div className="flex">
        <div className="mx-auto flex justify-between">
          {identityCard.card?.images?.front && (
            <Image
              ImgSrc={
                identityCard.card?.images?.front === 'Deleted Front'
                  ? FrontPlaceholder
                  : identityCard.card?.images?.front
              }
            />
          )}
          {identityCard.card?.images?.rear && (
            <Image
              ImgSrc={
                identityCard.card?.images?.rear === 'Deleted Rear'
                  ? RearPlaceholder
                  : identityCard.card?.images?.rear
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}
