import {useTranslation} from 'react-i18next'
import {Button} from '../components/Buttons'
import {getSupportLinkPreference} from '../services/setPreferences'

export default function NotFound() {
  const supportLink = getSupportLinkPreference() === false ? '' : getSupportLinkPreference()
  const {t} = useTranslation()
  return (
    <>
      <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-lime-600 sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                  {t('landing.NotFound')}
                </h1>
                <p className="mt-1 text-base text-gray-500">{t('landing.NotFoundAction')}</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Button href="/" text={t('sidebar.Home')} variant="secondary" />
                <Button href={supportLink} text="Contact support" variant="secondary" anchor />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
