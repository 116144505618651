import Image from './Image'
import ImageSrc from '../assets/AIFaceScan.svg'
import {ModalOpenButton, Modal, ModalContentsBase} from './Modal'
import {Button} from './Buttons'
import IdentityNavigationArrows from './IdentityNavigationArrows'
import FormAssignInventory from './forms/FormAssignInventory'
import FormAssignResource from './forms/FormAssignResource'
import FormEditImageUploadIdentityHeader from './forms/FormEditImageUploadIdentityHeader'
import {useTranslation} from 'react-i18next'
import {DownloadIcon, UploadIcon, CameraIcon, TrashIcon, PencilIcon} from '@heroicons/react/outline'
import FormCameraAi from './forms/FormCameraAi'
import DeletionWarning from './DeletionWarning'
import FormEditImageUploadedIdentityHeader from './forms/FormEditImageUploadedIdentityHeader'
import FormAddToIdentityGroup from './forms/FormAddToIdentityGroup'

export default function IdentityHeader({
  identity,
  identityMeta,
  identityIdentity,
  identityListPermission,
  identityAssignInventoryPermission,
  identityAssignResourcePermission,
  identityNumberLabel,
  identityNavTo,
  assignInventoryToIdentityClearError,
  assignInventoryToIdentityPending,
  assignInventoryToIdentityError,
  cards,
  deleteIdentityImage,
  deleteIdentityImageError,
  deleteIdentityImageClearError,
  deleteIdentityImagePending,
  identityPermission,
  ...props
}) {
  const {t} = useTranslation()
  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          {identity.photos?.current_id ? (
            <Image ImgSrc={identity.photos?.current} />
          ) : (
            <Image ImgSrc={ImageSrc} />
          )}
          <div className="flex flex-col pt-3">
            <Modal>
              <ModalOpenButton>
                <CameraIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </ModalOpenButton>
              <ModalContentsBase>
                <FormCameraAi
                  identity={identity}
                  isCardPhoto={false}
                  isEmployee={props.isEmployee}
                  isVisitor={props.isVisitor}
                  cards={cards}
                />
              </ModalContentsBase>
            </Modal>
            <Modal>
              <ModalOpenButton>
                <UploadIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </ModalOpenButton>
              <ModalContentsBase>
                <FormEditImageUploadIdentityHeader
                  identity={identity}
                  isCardPhoto={false}
                  isEmployee={props.isEmployee}
                  isVisitor={props.isVisitor}
                  cards={cards}
                />
              </ModalContentsBase>
            </Modal>
            <div className="space-y-1 text-center">
              <a href={identity.photos?.current} download={`${identity?.person?.full_name}.png`}>
                <DownloadIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
              </a>
            </div>
            {identity.photos?.current_id ? (
              <>
                <Modal>
                  <ModalOpenButton>
                    <PencilIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
                  </ModalOpenButton>
                  <ModalContentsBase>
                    <FormEditImageUploadedIdentityHeader
                      identity={identity}
                      isCardPhoto={false}
                      IdentityCardPhotoPutUrl={`${identity.url}/photo`} //  "/api/v1/employees/1 + /photo
                      IdentityCardPhotoPostUrl={identity.photos?.list} //  "/api/v1/employees/1/photos"
                      isEmployee={props.isEmployee}
                      isVisitor={props.isVisitor}
                      cards={cards}
                    />
                  </ModalContentsBase>
                </Modal>
                <Modal>
                  <ModalOpenButton>
                    <TrashIcon className="mr-4 h-6 w-6 flex-shrink-0 cursor-pointer text-gray-500" />
                  </ModalOpenButton>
                  <ModalContentsBase>
                    <DeletionWarning
                      deleteFunction={deleteIdentityImage}
                      error={deleteIdentityImageError}
                      setError={deleteIdentityImageClearError}
                      pending={deleteIdentityImagePending}
                      message={t('confirmDeletion.DeleteIdentityPhoto', {
                        field: identity?.person?.full_name,
                      })}
                      text={t('confirmDeletion.Confirm')}
                      id={identity?.id}
                    />
                  </ModalContentsBase>
                </Modal>
              </>
            ) : null}
          </div>
          <div className="flex w-56 flex-col pt-3 md:w-72 lg:w-80">
            <p className="truncate text-gray-700">{identity?.person?.full_name}</p>
            <p className="text-gray-700">{identity?.[identityNumberLabel]}</p>
          </div>
        </div>
        {identityListPermission ? (
          <div className="flex pt-3">
            <IdentityNavigationArrows
              next={identityMeta?.next}
              previous={identityMeta?.previous}
              navTo={identityNavTo}
            />
          </div>
        ) : null}
      </div>
      {identityAssignInventoryPermission && (
        <Modal>
          <ModalOpenButton>
            <Button text={t('attachInventoryForm.AttachInventoryTitle')} variant="secondary" />
          </ModalOpenButton>
          <ModalContentsBase title={t('attachInventoryForm.AttachInventoryTitle')}>
            <FormAssignInventory
              identity={identity}
              assignInventoryToIdentity={props.assignInventoryToIdentity}
              assignInventoryToIdentityClearError={assignInventoryToIdentityClearError}
              assignInventoryToIdentityPending={assignInventoryToIdentityPending}
              assignInventoryToIdentityError={assignInventoryToIdentityError}
              identityLabel={props.identityLabel}
              filterIdentityInventory={props.filterIdentityInventory}
              filterIdentityInventoryClearError={props.filterIdentityInventoryClearError}
              filteredIdentityInventoriesPending={props.filteredIdentityInventoriesPending}
              filteredIdentityInventoriesError={props.filteredIdentityInventoriesError}
              filteredIdentityInventories={props.filteredIdentityInventories}
            />
          </ModalContentsBase>
        </Modal>
      )}
      {identityAssignResourcePermission && (
        <Modal>
          <ModalOpenButton>
            <Button text={t('attachResourceForm.AttachResourceTitle')} variant="secondary" />
          </ModalOpenButton>
          <ModalContentsBase title={t('attachResourceForm.AttachResourceTitle')}>
            <FormAssignResource identityLabel={props.identityLabel} identity={identity} />
          </ModalContentsBase>
        </Modal>
      )}
      {identityPermission && identityIdentity?.id && (
        <Modal>
          <ModalOpenButton>
            <Button text={t('identity.AddIdentity')} variant="secondary" />
          </ModalOpenButton>
          <ModalContentsBase title={t('identity.AddIdentity')}>
            <FormAddToIdentityGroup identity={identityIdentity} />
          </ModalContentsBase>
        </Modal>
      )}
    </>
  )
}
