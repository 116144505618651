import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType} from '../formRendererRHF/types'
import {createIdentityClearError, createIdentity} from '../../stores/identity'

function FormAddIdentity({selected}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const {error, pending, successMessage} = useSelector((state) => state.identity.create_identity)
  const handleSubmit = (values) => {
    if (selected) {
      values.children_identities = selected.map((i) => i.id)
    }
    dispatch(createIdentity({...values}))
  }

  const fields = [
    {
      label: 'TableLabelsCommon.Name',
      component: InputComponent.TEXT_INPUT,
      name: 'name',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      label: 'TableLabelsCommon.Description',
      component: InputComponent.TEXT_INPUT,
      name: 'description',
    },
    {
      component: InputComponent.SELECT,
      name: `location`,
      label: 'addEmployee.Domain',
      placeholder: 'addEmployee.DomainPlaceholder',
      isMulti: false,
      options: permissions.createEmployeeLocations,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    !selected && {
      component: InputComponent.ASYNCREACTSELECT,
      name: 'children_identities',
      label: 'identity.AddChildren',
      url: '/api/v1/identities?q=',
      nestedOptions: false,
      optionValue: 'id',
      optionLabel: 'owner_name',
      isMulti: true,
      validationType: 'array',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
  ].filter(Boolean)

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-add-identity',
          submitButtonText: t('addEmployee.AddEmployee'),
        }}
        onSubmit={handleSubmit}
        pending={pending}
        noFormBorder
        infoMessage={
          <Notification
            error={error}
            setError={() => dispatch(createIdentityClearError())}
            success={successMessage}
          />
        }
      />
    </>
  )
}

export default FormAddIdentity
