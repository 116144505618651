import {useState, useRef, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {
  createAndSetInventoryPhoto,
  createAndSetInventoryPhotoClearError,
} from '../../stores/inventories_photos'
import {ButtonSuccessAndError} from '../Buttons'
import {drawImageOnCanvasFitting, base64DecToArr} from '../../utils/camera'

export default function FormEditImageCameraInventory({identity, imageURI}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const {pending, error, successMessage} = useSelector(
    (state) => state.inventories_photos.create_and_set_inventory_photo,
  )

  // canvas refs
  const imageManipulationCanvas = useRef()

  useEffect(() => {
    drawImageOnCanvasFitting(imageManipulationCanvas.current, imageURI)
    const canvas = document.getElementById('imageManipulationCanvas')
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
  }, [imageURI, imageManipulationCanvas])

  // image manipulation states
  const [saturate, setSaturate] = useState({x: 100})
  const [brightness, setBrightness] = useState({x: 100})
  const [contrast, setContrast] = useState({x: 100})

  function applyFilters(brightness, saturate, contrast) {
    setBrightness(brightness)
    setSaturate(saturate)
    setContrast(contrast)
    const c = document.getElementById('imageManipulationCanvas')
    const ctx = c.getContext('2d')
    ctx.filter = `brightness(${brightness.x}%) contrast(${contrast.x}%) saturate(${saturate.x}%)`
    let imageObj = document.createElement('img')
    imageObj.src = imageURI

    imageObj.onload = function () {
      ctx.drawImage(imageObj, 0, 0, 310, 240)
    }
  }

  return (
    <>
      <>
        {/* trick to make refs work for canvasses */}
        <span className="hidden" ref={imageManipulationCanvas} />
      </>
      {/*Edit & Save photo part */}
      <>
        {/* image manipulation canvas */}
        <div className="relative text-center">
          <div className=" relative">
            <canvas
              className=" z-20 mx-auto p-3 lg:m-1"
              id="imageManipulationCanvas"
              ref={imageManipulationCanvas} // this is undefined!
              width={310}
              height={240}
              //style={{objectFit: 'contain'}}
            />
          </div>
        </div>
        <>
          <div className="p-3 text-center text-gray-600">{t('editPhoto')}</div>
          {/*Range Inputs*/}
          <div className="m-2 p-2">
            <>
              <label className="block text-sm font-medium text-gray-700">
                {t('addAvatar.Brightness')}
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="range"
                  min={50}
                  max={150}
                  step={1}
                  defaultValue={brightness.x}
                  onChange={(e) => {
                    applyFilters({x: parseFloat(e.target.value).toFixed(2)}, saturate, contrast)
                  }}
                  className="  block h-4 w-full appearance-none rounded-md border-gray-300 bg-lime-100 shadow-sm sm:text-sm"
                />
              </div>
            </>
            <>
              <label className="block text-sm font-medium text-gray-700">
                {t('addAvatar.Saturate')}
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="range"
                  min={50}
                  max={150}
                  step={1}
                  defaultValue={saturate.x}
                  onChange={(e) =>
                    applyFilters(brightness, {x: parseFloat(e.target.value).toFixed(2)}, contrast)
                  }
                  className="block h-4 w-full appearance-none rounded-md border-gray-300 bg-lime-100 shadow-sm sm:text-sm"
                />
              </div>
            </>
            <>
              <label className="block text-sm font-medium text-gray-700">
                {t('addAvatar.Contrast')}
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="range"
                  min={50}
                  max={150}
                  step={1}
                  defaultValue={contrast.x}
                  onChange={(e) =>
                    applyFilters(brightness, saturate, {
                      x: parseFloat(e.target.value).toFixed(2),
                    })
                  }
                  className="block h-4 w-full appearance-none rounded-md border-gray-300 bg-lime-100 shadow-sm sm:text-sm"
                />
              </div>
            </>
            <div>
              <ButtonSuccessAndError
                type="button"
                text={t('addAvatar.Save')}
                disabled={false}
                error={error}
                setError={createAndSetInventoryPhotoClearError}
                pending={pending}
                success={successMessage}
                setSuccess={createAndSetInventoryPhotoClearError}
                onClick={() => {
                  const canvas = document.getElementById('imageManipulationCanvas')
                  const dataURL = canvas.toDataURL()
                  const raw_image_data = dataURL.replace(/^data:image\/\w+;base64,/, '')
                  var blob = new Blob([base64DecToArr(raw_image_data)], {type: 'image/jpeg'})
                  dispatch(createAndSetInventoryPhoto(identity?.photos.list, blob))
                }}
              />
            </div>
          </div>
        </>
      </>
    </>
  )
}
