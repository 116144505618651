import {classNames} from '../utils/classNames'
import {useFormikContext} from 'formik'
import SpinnerSVG from '../assets/Spinner.svg'
import {useEffect} from 'react'
import {CameraIcon, XCircleIcon} from '@heroicons/react/solid'
import {ModalDismissButton} from './Modal'
import ErrorOnView from './ErrorOnView'
import InfoMessage from './InfoMessage'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import Notification from './Notification'

export const FormikSaveButton = ({
  type,
  text,
  primary,
  pending,
  error,
  setError,
  disabled,
  testid,
  ...props
}) => {
  const {isValid, dirty} = useFormikContext(props)
  const {t} = useTranslation()

  return (
    <>
      <Notification error={error} setError={setError} />
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type={type}
          data-testid={testid}
          className={classNames(
            primary
              ? 'bg-lime-600 text-white shadow-sm hover:bg-lime-700 focus:ring-lime-500'
              : 'bg-lime-100 text-lime-700 hover:bg-lime-200 focus:ring-lime-500',
            'mx-2 min-w-[10rem] items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 focus:outline-none  focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
          )}
          disabled={!(dirty && isValid) || disabled}
        >
          {!pending ? (
            `${text}`
          ) : (
            <div className="flex items-center">
              <img alt="o" src={SpinnerSVG} className="mr-3 h-5 w-5 animate-spin" />
              {t('submitting')}
            </div>
          )}
        </button>
      </div>
    </>
  )
}

export function Button({
  variant = 'primary',
  href,
  anchor,
  disabled,
  pending,
  text,
  testid,
  children,
  icon,
  ...props
}) {
  const {t} = useTranslation()

  let button_variant
  switch (variant) {
    case 'secondary':
      button_variant = 'bg-lime-100 text-lime-700 hover:bg-lime-200 focus:ring-lime-500'
      break
    case 'danger':
      button_variant = 'bg-red-600 text-white shadow-sm hover:bg-red-700 focus:ring-lime-500'
      break
    case 'info':
      button_variant =
        'border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-lime-500'
      break
    case 'primary':
      button_variant = 'bg-lime-600 text-white shadow-sm hover:bg-lime-700 focus:ring-lime-500'
      break
    default:
      button_variant = ''
      break
  }

  return href && !anchor ? (
    <Link
      to={href}
      className={classNames(
        button_variant,
        'mx-2 inline-flex cursor-pointer items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 focus:outline-none  focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
      )}
      {...props}
    >
      {text}
    </Link>
  ) : anchor ? (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        button_variant,
        href === '' ? 'pointer-events-none' : '',
        'mx-2 inline-flex cursor-pointer items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 focus:outline-none  focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
      )}
      {...props}
    >
      {text}
    </a>
  ) : (
    <button
      disabled={disabled}
      data-testid={testid}
      {...props}
      className={classNames(
        button_variant,
        'mx-2 inline-flex cursor-pointer items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 focus:outline-none  focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
        props.className,
      )}
    >
      {icon}
      {!pending ? (
        <span className={classNames(!!icon && 'hidden sm:inline')}>{text}</span>
      ) : (
        <div className="flex items-center">
          <img alt="o" src={SpinnerSVG} className="mr-3 h-5 w-5 animate-spin" />
          <span className="hidden sm:inline">{t('submitting')}</span>
        </div>
      )}
      {children}
    </button>
  )
}

export const DynamicButton = ({
  type,
  onClick,
  text,
  primary,
  pending,
  error,
  disabled,
  classNames = 'shadow-sm text-white bg-lime-600 hover:bg-lime-700 focus:ring-lime-500 disabled:opacity-50 cursor-pointer inline-flex items-center px-3 py-2 mx-2 border border-transparent text-sm leading-4 font-medium rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2',
  setError,
  testid,
  ...props
}) => {
  const {t} = useTranslation()
  // TODO: check what do you provide to <ErrorOnView in other components
  const create_notification = useSelector((state) => state.notifications.create_notification)
  const {message} = create_notification.success_info
  return (
    <>
      {error ? (
        <div className="p-2">
          <ErrorOnView error={error} setError={() => setError()} />
        </div>
      ) : null}
      {message ? (
        <div className="p-2">
          <InfoMessage message={message} type="success" />
        </div>
      ) : null}

      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type={type}
          onClick={onClick}
          className={classNames}
          disabled={disabled}
          data-testid={testid}
        >
          {!pending ? (
            `${text}`
          ) : (
            <div className="flex items-center">
              <img alt="o" src={SpinnerSVG} className="mr-3 h-5 w-5 animate-spin" />
              {t('submitting')}
            </div>
          )}
        </button>
      </div>
    </>
  )
}

export const ButtonSuccessAndError = ({
  type,
  onClick,
  text,
  primary,
  pending,
  error,
  setError,
  success,
  setSuccess,
  disabled,
  testid,
  ...props
}) => {
  const {t} = useTranslation()

  useEffect(() => {
    return () => {
      error && setError && setError()
      success && setSuccess && setSuccess(null)
    }
  }, [])

  return (
    <>
      <Notification
        error={error}
        setError={() => setError()}
        success={success}
        successMessageKey={typeof success === 'string' ? success : undefined}
      />
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type={type}
          onClick={() => onClick()}
          data-testid={testid}
          disabled={disabled}
          className={classNames(
            primary
              ? 'bg-lime-600 text-white shadow-sm hover:bg-lime-700 focus:ring-lime-500'
              : 'bg-lime-100 text-lime-700 hover:bg-lime-200 focus:ring-lime-500',
            'mx-2 inline-flex cursor-pointer items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 focus:outline-none  focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
          )}
        >
          {pending ? (
            <div className="flex items-center">
              <img alt="o" src={SpinnerSVG} className="mr-3 h-5 w-5 animate-spin" />
              {t('submitting')}
            </div>
          ) : (
            `${text}`
          )}
        </button>
      </div>
    </>
  )
}

export const CameraButtons = ({onClick, disabled, testid}) => {
  return (
    <div className="flex justify-center">
      <ModalDismissButton>
        <XCircleIcon className="h-10 w-10 text-red-500" />
      </ModalDismissButton>
      <button onClick={() => onClick()} disabled={disabled} data-testid={testid}>
        <CameraIcon
          className={classNames(disabled ? ' text-red-500' : 'text-lime-700', 'h-10 w-10')}
        />
      </button>
    </div>
  )
}

export function PageButton({children, className, testid, ...rest}) {
  return (
    <button
      type="button"
      className={classNames(
        'relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50',
        className,
      )}
      data-testid={testid}
      {...rest}
    >
      {children}
    </button>
  )
}
