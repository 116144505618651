import {useTranslation} from 'react-i18next'
import {useEffect, useMemo, useRef, useState} from 'react'
import {getExportValuesFromResponseData, getExportValuesFromTableData} from './utils'
import axios from '../../services/api'
import {CSVLink} from 'react-csv'
import {DocumentDownloadIcon, DownloadIcon} from '@heroicons/react/outline'
import {Button} from '../Buttons'

/**
 *
 * @param page
 * @param headerGroups
 * @param rows accepts rows for export all data in client side table
 * @param url accepts url to fetch all data for server side table
 * @param setError
 * @returns {JSX.Element}
 */
function ExportButtons({page, headerGroups, rows, url, setError}) {
  const {t} = useTranslation()
  const [csvData, setCsvData] = useState(false)
  const [pending, setPending] = useState(false)
  // https://github.com/react-csv/react-csv/issues/221
  const exportAllComponent = useRef(null)

  const exportHeaders = useMemo(
    () =>
      headerGroups.flatMap((headerGroup) =>
        headerGroup.headers.map((header) => ({
          label: header.label,
          key: header.id,
        })),
      ),
    [headerGroups],
  )
  const exportDataCurrent = useMemo(() => getExportValuesFromTableData(page), [page])
  const exportDataAll = useMemo(() => getExportValuesFromTableData(rows), [rows])
  const exportAllProps = {}

  useEffect(() => {
    if (csvData && exportAllComponent.current && exportAllComponent.current.link) {
      setTimeout(() => {
        exportAllComponent.current.link.click()
        setPending(false)
        setCsvData(false)
      })
    }
  }, [csvData])

  const fetchData = async () => {
    if (!pending && url) {
      setError(false)
      setPending(true)
      try {
        const response = await axios.get(url)

        setCsvData(getExportValuesFromResponseData(response?.data?.results, exportHeaders))
      } catch (error) {
        setError(error)
      }
      setPending(false)
    } else {
      // handle click for client side table
      exportAllComponent.current.link.click()
    }
  }

  return (
    <div className="flex grow justify-end">
      <CSVLink
        data={exportDataCurrent}
        filename="currentData.csv"
        enclosingCharacter={`'`}
        headers={exportHeaders}
        className={
          'mx-2 inline-flex items-center rounded-md border border-transparent bg-lime-100 px-3 py-2 text-sm font-medium leading-4 text-lime-700 hover:bg-lime-200 focus:outline-none focus:ring-2  focus:ring-lime-500 focus:ring-offset-2 disabled:opacity-50'
        }
      >
        <DownloadIcon className="h-6 w-6" aria-hidden="true" />
        <span className="hidden sm:inline">{t('table.ExportCurrent')}</span>
      </CSVLink>
      <Button
        variant="secondary"
        pending={pending}
        icon={<DocumentDownloadIcon className="h-6 w-6" aria-hidden="true" />}
        text={t('table.ExportAll')}
        onClick={fetchData}
      ></Button>
      {(url ? !!csvData : true) && (
        <CSVLink
          {...exportAllProps}
          data={url ? csvData : exportDataAll}
          filename="allData.csv"
          headers={exportHeaders}
          ref={exportAllComponent}
        ></CSVLink>
      )}
    </div>
  )
}

export default ExportButtons
