import {Formik, Form} from 'formik'
import {useEffect, useRef} from 'react'
import {AsyncSelectInput2} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {FormikSaveButton} from '../Buttons'
import {useSelector, useDispatch} from 'react-redux'
import {assignCard, assignCardClearError} from '../../stores/cards'
import * as Yup from 'yup'

export default function FormAssignCardNonPersonalized({identity}) {
  const dispatch = useDispatch()
  const pending = useSelector((state) => state.cards.assign_card.pending)
  const error = useSelector((state) => state.cards.assign_card.error)
  const {t} = useTranslation()

  const initialValues = {
    card_id: '',
  }

  let cardIdSelectRef = useRef()

  useEffect(() => {
    cardIdSelectRef?.current?.focus()
  }, [])

  const validationSchema = Yup.object({
    card_id: Yup.number('Please select company').required(
      t('yupError.ThisFieldIsRequired', {field: t('addCard.SearchFreeCard')}),
    ),
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          dispatch(assignCard(identity?.cards, values.card_id))
          actions.resetForm()
          // resetting react-select components
          cardIdSelectRef.current.select.setValue('')
        }}
      >
        <Form>
          <AsyncSelectInput2
            label={t('addCard.SearchFreeCard')}
            url={`/api/v1/cards/all_attachable?q=`}
            placeholder={'Please Select A Free Card'}
            optionLabel="badgenr"
            optionValue="id"
            name="card_id"
            innerRef={cardIdSelectRef}
          />
          <FormikSaveButton
            type="submit"
            testid="form-assign-card-non-personalized-submit"
            text={t('attachInventoryForm.Attach')}
            pending={pending}
            error={error}
            setError={() => dispatch(assignCardClearError())}
            primary
          />
        </Form>
      </Formik>
    </>
  )
}
