import {zonedTimeToUtc, formatInTimeZone} from 'date-fns-tz'
import {getTimezonePreference} from '../services/setPreferences'
import parse from 'date-fns/parse'

export const iso8061UTC = (dateTimeStr) => {
  const timezone = getTimezonePreference()
  // normalize the timezone;
  // Max is in munich, he looks at his watch and time is 09:00 he wants to assign a card to a visitor from 09:00 to 12:00 in NY time in the same day.
  // He should calculate the timezone. That he is 7 hours ahead of NY. So he should set the cards validity to 16:00 to 19:00.
  // Instead of this calculation now he changes his timezone to NY. Picks 09:00 to 12:00 and that is it.
  // when he changes his preferred_timezone to local one (Munich) again he should see the cards validitiy 16:00 to 19:00.
  const timezonedTime = zonedTimeToUtc(new Date(dateTimeStr), timezone)
  return timezonedTime.toISOString()
}

export const formatInPreferredTimezone = (dateStr) => {
  const timezone = getTimezonePreference()
  return formatInTimeZone(new Date(dateStr), timezone, "dd/MM/yyyy 'at' HH:mm (z)")
}

export function parseWithTimeZone(dateStr) {
  // in Edit forms you will get the dateStr as : "dd/MM/yyyy 'at' HH:mm (CET)"
  const timezone = getTimezonePreference()
  const cleanDateStr = dateStr.replace('at ', '').replace(/ *\([^)]*\) */g, '') // removes the (GMT+1) part
  const parsedDate = parse(cleanDateStr, 'dd/MM/yyyy HH:mm', new Date('1995-12-17T03:24:00'))
  const timezonedTime = zonedTimeToUtc(parsedDate, timezone)
  return timezonedTime.toISOString()
}
