import axiosInstance from './api'
import {
  getLocalAccessToken,
  getLocalRefreshToken,
  removeTokenData,
  updateLocalTokenData,
} from './TokenService'
import {cleanupAfterLogout, refreshTokenData} from '../stores/auth'
import {updateLocation} from '../stores/configs'

// Heavily inspired by this article
// https://www.bezkoder.com/axios-interceptors-refresh-token/

// also read this one:https://srini-dev.hashnode.dev/authentication-refresh-token-flow-with-nextjs-typescript-react-query-and-axios-interceptors

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getLocalAccessToken()
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  const {dispatch} = store
  axiosInstance.interceptors.response.use(
    (res) => {
      return res
    },
    async (err) => {
      const originalConfig = err.config

      if (originalConfig.url !== '/auth/login' && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true

          try {
            const rs = await axiosInstance.post('/api/v1/oauth/token', {
              refresh_token: getLocalRefreshToken(),
              client_id: '0BwXH4BTGIUuJZV2e0M5bVHRzthDRH22DDr5',
              grant_type: 'refresh_token',
            })

            dispatch(refreshTokenData(rs.data)) // tokenData at redux
            updateLocalTokenData(rs.data) // tokenData at local storage

            return axiosInstance(originalConfig)
          } catch (_error) {
            removeTokenData()
            dispatch(cleanupAfterLogout())
            // if SSOLogin throws an error don't navigate
            if (!window.location.search.includes('error_code')) {
              dispatch(updateLocation('/login'))
            }
            return Promise.reject(_error)
          }
        }
      }

      return Promise.reject(err)
    },
  )
}

export default setup
