import axios from '../services/api'

// Types
const DELETE_ACCESS_PROFILE_IDENTITIES_PENDING =
  'idfunctiondb/access_profiles_identities/DELETE_ACCESS_PROFILE_IDENTITIES_PENDING'
const DELETE_ACCESS_PROFILE_IDENTITIES_SUCCESS =
  'idfunctiondb/access_profiles_identities/DELETE_ACCESS_PROFILE_IDENTITIES_SUCCESS'
const DELETE_ACCESS_PROFILE_ERROR =
  'idfunctiondb/access_profiles_identities/DELETE_ACCESS_PROFILE_ERROR'
const DELETE_ACCESS_PROFILE_IDENTITIES_CLEAR_ERROR =
  'idfunctiondb/access_profiles_identities/DELETE_ACCESS_PROFILE_IDENTITIES_CLEAR_ERROR'
const UPDATE_ACCESS_PROFILE_IDENTITIES_PENDING =
  'idfunctiondb/access_profiles_identities/UPDATE_ACCESS_PROFILE_IDENTITIES_PENDING'
const UPDATE_ACCESS_PROFILE_IDENTITIES_SUCCESS =
  'idfunctiondb/access_profiles_identities/UPDATE_ACCESS_PROFILE_IDENTITIES_SUCCESS'
const UPDATE_ACCESS_PROFILE_ERROR =
  'idfunctiondb/access_profiles_identities/UPDATE_ACCESS_PROFILE_ERROR'

// Initial
const initialState = {
  delete: {
    success: null,
    pending: false,
    error: null,
  },
  update: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case DELETE_ACCESS_PROFILE_IDENTITIES_PENDING:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: true,
        },
      }
    case DELETE_ACCESS_PROFILE_IDENTITIES_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_ACCESS_PROFILE_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_ACCESS_PROFILE_IDENTITIES_CLEAR_ERROR:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: null,
        },
      }
    case UPDATE_ACCESS_PROFILE_IDENTITIES_PENDING:
      return {
        ...state,
        update: {
          ...state.update,
          pending: true,
        },
      }
    case UPDATE_ACCESS_PROFILE_IDENTITIES_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          success: action.payload,
          pending: false,
        },
      }
    case UPDATE_ACCESS_PROFILE_ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function deleteAccessProfileIdentityPending() {
  return {type: DELETE_ACCESS_PROFILE_IDENTITIES_PENDING}
}

function deleteAccessProfileIdentitySuccess(payload) {
  return {type: DELETE_ACCESS_PROFILE_IDENTITIES_SUCCESS, payload: payload}
}

function deleteAccessProfileIdentityError(error) {
  return {type: DELETE_ACCESS_PROFILE_ERROR, payload: error}
}

export function deleteAccessProfileIdentityClearError() {
  return {type: DELETE_ACCESS_PROFILE_IDENTITIES_CLEAR_ERROR}
}

export const deleteAccessProfileIdentity = (id) => (dispatch) => {
  dispatch(deleteAccessProfileIdentityPending())
  return axios
    .delete(`/api/v1/identities_access_profiles/${id}`)
    .then((res) => {
      dispatch(deleteAccessProfileIdentitySuccess(id))

      return res.data
    })
    .catch((error) => dispatch(deleteAccessProfileIdentityError(error)))
}

function updateAccessProfileIdentityPending() {
  return {type: UPDATE_ACCESS_PROFILE_IDENTITIES_PENDING}
}

function updateAccessProfileIdentitySuccess(payload) {
  return {type: UPDATE_ACCESS_PROFILE_IDENTITIES_SUCCESS, payload: payload}
}

export function updateAccessProfileIdentityError(error) {
  return {type: UPDATE_ACCESS_PROFILE_ERROR, payload: error}
}

export const updateAccessProfileIdentity =
  ({id, values}) =>
  (dispatch) => {
    dispatch(updateAccessProfileIdentityPending())
    return axios
      .put(`/api/v1/identities_access_profiles/${id}`, values)
      .then((res) => {
        dispatch(updateAccessProfileIdentitySuccess(id + values.valid_to))

        return res.data
      })
      .catch((error) => dispatch(updateAccessProfileIdentityError(error)))
  }
