import {Dialog, Transition} from '@headlessui/react'
import * as React from 'react'
import {Fragment} from 'react'
import {ExclamationIcon, XIcon} from '@heroicons/react/outline'
import {useTranslation} from 'react-i18next'
import CollapseMessage from './CollapseMessage'
import {Button} from './Buttons'
import {getSupportLinkPreference} from '../services/setPreferences'
import {getErrorText} from './ErrorOnView'

export default function ErrorModal({error, setError}) {
  const supportLink = getSupportLinkPreference() === false ? '' : getSupportLinkPreference()
  const {t, i18n} = useTranslation()
  const [isOpen, setIsOpen] = React.useState(true)
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={setIsOpen}>
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="ml-4 flex flex-shrink-0 place-content-end">
                <button
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                  onClick={() => {
                    window.location.reload(true) // force refresh to clear the whole state
                    setIsOpen(false)
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="relative mt-4 transform overflow-hidden bg-white text-left transition-all">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {error?.response?.status
                        ? `${error?.response?.status} `
                        : t('errorBoundary.Title')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{getErrorText(t, i18n, error)}</p>
                      {/* Typed errors has message and they should not disappear */}
                      {error?.response?.data?.error_type && error?.response?.data?.error_message ? (
                        <CollapseMessage title={t('ErrorMessage')}>
                          <p>{error?.response?.data?.error_message}</p>
                        </CollapseMessage>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {!!supportLink && (
                    <Button text={t('footer.Support')} anchor href={supportLink} variant="info" />
                  )}
                  <Button
                    type="button"
                    variant="danger"
                    onClick={() => {
                      setError(null)
                      setIsOpen(false)
                    }}
                    text={t('employeeFiles.Cancel')}
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
