import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import FullPageSpinner from './components/FullPageSpinner'
import ErrorModal from './components/ErrorModal'
import {fetchMeClearError} from './stores/auth'
import {useTranslation} from 'react-i18next'
import ErrorBoundary from './components/ErrorBoundary'

const AppAuthenticated = React.lazy(() => import(/* webpackPrefetch: true */ './AppAuthenticated'))
const AppUnauthenticated = React.lazy(() => import('./AppUnauthenticated'))

const App = () => {
  const user = useSelector((state) => state.auth.fetchUserSuccess)
  const pending = useSelector((state) => state.auth.fetchUserPending)
  const error = useSelector((state) => state.auth.fetchUserError)
  const isAuthenticated = useSelector((state) => state.auth.authenticationSuccess)
  const {t} = useTranslation()
  const dispatch = useDispatch()

  return (
    <ErrorBoundary>
      <React.Suspense fallback={<FullPageSpinner message={t('loading')} />}>
        {isAuthenticated && pending ? (
          <FullPageSpinner message={t('login.FetchingUser')} />
        ) : isAuthenticated && error ? (
          <ErrorModal error={error} setError={() => dispatch(fetchMeClearError())} />
        ) : isAuthenticated && user ? (
          <AppAuthenticated />
        ) : (
          <AppUnauthenticated />
        )}
      </React.Suspense>
    </ErrorBoundary>
  )
}

export default App
