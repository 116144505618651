import {useState, useEffect, useRef} from 'react'
import {Formik, Form} from 'formik'
import {SelectInput} from '../FormInputs'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Button, FormikSaveButton} from '../Buttons'
import {getInventoriesTypes, getInventoriesTypesClearError} from '../../stores/inventories'
import {DatePickerDefault} from '../DatePickerDefault'
import NotAvailableEntity from '../NotAvailableEntity'
import ErrorOnModal from '../ErrorOnModal'
import * as Yup from 'yup'
import SpinnerSVG from '../../assets/Spinner.svg'
import {iso8061UTC} from '../../utils/datetime'

export default function FormAssignInventory({
  identity,
  identityLabel,
  filterIdentityInventory,
  assignInventoryToIdentity,
  filteredIdentityInventories,
  filteredIdentityInventoriesPending,
  filteredIdentityInventoriesError,
  filterIdentityInventoryClearError,
  assignInventoryToIdentityPending,
  assignInventoryToIdentityError,
  assignInventoryToIdentityClearError,
}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const inventory_types = useSelector((state) => state.inventories.get_inventories_types.success)
  const inventory_types_error = useSelector(
    (state) => state.inventories.get_inventories_types.error,
  )

  const [showFormFilterInventory, setShowFormFilterInventory] = useState(true)

  useEffect(() => {
    dispatch(getInventoriesTypes())
  }, [dispatch, inventory_types_error?.message])

  const initialValues = {
    inventory_type: null,
    valid_from: new Date(),
    valid_to: new Date(),
  }

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const assignInventory = (id, identityLabel, values) => {
    const AttachValues = {
      [identityLabel]: id,
      inventory_id: values.filtered_inventory, //filteredInventorySelected.value,
      from_date: startDate,
      to_date: endDate,
    }
    dispatch(assignInventoryToIdentity(AttachValues, userID))
  }

  const validationSchema = Yup.object({
    valid_from: Yup.date()
      .required(t('yupError.ThisFieldIsRequired', {field: t('addCard.ValidFrom')}))
      .nullable(),
    valid_to: Yup.date()
      .required(t('yupError.ThisFieldIsRequired', {field: t('addCard.ValidTo')}))
      .min(Yup.ref('valid_from'), t('yupError.DateRange'))
      .test('same_dates_test', t('yupError.DateMustBeGreaterThan'), function (value) {
        const {valid_from} = this.parent
        return value.getTime() !== valid_from.getTime()
      })
      .nullable(),
    inventory_type: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addInventoryForm.InventoryType')}),
    ),
  })

  const validationSchema2 = Yup.object({
    filtered_inventory: Yup.string().required(
      t('yupError.ThisFieldIsRequired', {field: t('addInventoryForm.InventoryType')}),
    ),
  })

  let inventoryTypeSelectRef = useRef()
  let filteredInventorySelectRef = useRef()

  return (
    <>
      {filteredIdentityInventoriesPending ? (
        <div className="text-md flex flex-col items-center justify-center p-3">
          <img alt="o" src={SpinnerSVG} className="mr-3 h-7 w-7 animate-spin text-lime-500" />
          Searching...
        </div>
      ) : null}
      {showFormFilterInventory && (
        // Form Filter Inventory
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={(values) => {
            let valid_from = iso8061UTC(values.valid_from)
            let valid_to = iso8061UTC(values.valid_to)
            setStartDate(valid_from) // for passing the values to attachInventory func.
            setEndDate(valid_to)
            dispatch(filterIdentityInventory(valid_to, valid_from, values.inventory_type))
            setShowFormFilterInventory(false)
          }}
        >
          <Form>
            <SelectInput
              label={t('addInventoryForm.InventoryType')}
              options={inventory_types.map((o) => ({
                value: o.id,
                label: o.name,
              }))}
              placeholder={t('attachInventoryForm.InventoryPlaceholder')}
              name="inventory_type"
              innerRef={inventoryTypeSelectRef}
              required
              error={inventory_types_error}
              setError={getInventoriesTypesClearError}
            />
            <DatePickerDefault
              validfrom="valid_from"
              validto="valid_to"
              validtoid="form-assign-inventory-datepicker-validTo"
              validfromid="form-assign-inventory-datepicker-validFrom"
              checkboxid="form-assign-inventory-checkbox"
              type="datetime-local"
              labelvalidfrom={t('addVisitationForm.FromDate')}
              labelvalidto={t('addVisitationForm.ToDate')}
              endlessfieldlabel={t('datePickerForms.Endless')}
              endlessfield="true"
            />
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <Button text="Search" type="submit" />
            </div>
          </Form>
        </Formik>
      )}
      {!filteredIdentityInventoriesPending &&
      filteredIdentityInventoriesError &&
      !showFormFilterInventory ? (
        <ErrorOnModal
          error={filteredIdentityInventoriesError}
          clearError={filterIdentityInventoryClearError}
          setShow={setShowFormFilterInventory}
        />
      ) : null}
      {!filteredIdentityInventoriesPending &&
      !filteredIdentityInventoriesError &&
      filteredIdentityInventories.length === 0 &&
      !showFormFilterInventory ? (
        <NotAvailableEntity
          message={t('notificationSuccess.InventoryQueryZeroResult')}
          setShow={setShowFormFilterInventory}
        />
      ) : null}
      {filteredIdentityInventories.length > 0 &&
      !showFormFilterInventory &&
      !filteredIdentityInventoriesError &&
      !filteredIdentityInventoriesPending ? (
        // FormAssignInventory
        <>
          <Formik
            onSubmit={(values) => {
              assignInventory(identity.id, identityLabel, values)
            }}
            initialValues={{filtered_inventory: ''}}
            validationSchema={validationSchema2}
            validateOnBlur={false}
          >
            <Form>
              <div>
                <SelectInput
                  label={t('attachInventoryForm.SelectInventory')}
                  options={filteredIdentityInventories.map((o) => ({
                    value: o.id,
                    number: o.number,
                    label: `${o.model} ${o.type.name}`,
                  }))}
                  name="filtered_inventory"
                  innerRef={filteredInventorySelectRef}
                  required
                />
              </div>
              <FormikSaveButton
                type="submit"
                testid="form-assign-inventory-submit"
                text={t('attachInventoryForm.Attach')}
                pending={assignInventoryToIdentityPending}
                error={assignInventoryToIdentityError}
                setError={() => dispatch(assignInventoryToIdentityClearError())}
              />
            </Form>
          </Formik>
        </>
      ) : null}
    </>
  )
}
