import {useDispatch, useSelector} from 'react-redux'
import {uploadLogo, uploadLogoClearError} from '../../stores/static_assets'
import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import Notification from '../Notification'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import {useTranslation} from 'react-i18next'

export default function FormAddLogo() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const {pending, error, success} = useSelector((state) => state.static_assets.upload_logo)
  const handleSubmit = (values) => {
    dispatch(uploadLogo({files: [values['logo-fileInput'].file]}, '/api/v1/static_assets/logo'))
  }

  const fields = [
    {
      label: t('uploadFile'),
      component: InputComponent.FILE,
      name: 'logo-fileInput',
      validationType: YupValidationType.OBJECT,
      validations: [
        {
          type: InputYupValidationsType.FILETYPE,
          params: [[2, 3], t('yupError.FileType')],
        },
        {
          type: InputYupValidationsType.REQUIRED,
          params: [t('yupError.FileRequired')],
        },
      ],
    },
  ]
  return (
    <DynamicRhfForm
      formConfig={{
        fields,
        name: 'form-logo',
        submitButtonText: t('login.Submit'),
      }}
      onSubmit={handleSubmit}
      pending={pending}
      noFormBorder
      infoMessage={
        <Notification
          error={error}
          setError={() => dispatch(uploadLogoClearError())}
          success={!!success}
        />
      }
    />
  )
}
