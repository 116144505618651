import {useState, useRef, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {setAvatarPhoto, setAvatarPhotoCLearError} from '../../stores/photos'
import {backgroundExtraction} from '../../stores/background_extraction'
import {CheckboxRaw} from '../FormInputs'
import {Button} from '../Buttons'
import {drawBlobOnCanvas} from '../../utils/camera'
import Notification from '../Notification'

export default function FormEditImageCameraIdentityHeader({
  identity,
  isEmployee,
  isVisitor,
  isCardPhoto,
  capturedImage,
  IdentityCardPhotoPutUrl,
  IdentityCardPhotoPostUrl,
  cards,
  setShowCamera,
}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const take_snapshoot_success = useSelector((state) => state.camera.take_snapshoot.success)
  const take_snapshoot_pending = useSelector((state) => state.camera.take_snapshoot.pending)
  //const take_snapshoot_error = useSelector((state)=>state.camera.take_snapshoot.error)

  const {
    pending: set_avatar_photo_pending,
    error: set_avatar_photo_error,
    successMessage: set_avatar_photo_success,
  } = useSelector((state) => state.photos.set_avatar_photo)

  const bg_extraction_pending = useSelector(
    (state) => state.background_extraction.background_extraction.pending,
  )
  const bg_extraction_success = useSelector(
    (state) => state.background_extraction.background_extraction.success,
  )
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  // now this photo should be the photo after every upload to the database and manipulations
  const [selectedImageToSave, setSelectedImageToSave] = useState(capturedImage)

  // canvas refs
  const imageManipulationCanvas = useRef()
  const backgroundCanvas = useRef()

  // background extraction states
  const [checked, setChecked] = useState(false)
  const [bgExtraction, setBgExtraction] = useState(false)
  const [bgColor, setBgColor] = useState('default')

  useEffect(() => {
    if (!take_snapshoot_pending && !bgExtraction) {
      // !showFileUpload &&
      // because there is not a thing to show on canvas before upload
      drawBlobOnCanvas(imageManipulationCanvas.current, take_snapshoot_success)
      const canvas = document.getElementById('backgroundCanvas')
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
    }
  }, [
    selectedImageToSave,
    take_snapshoot_success,
    take_snapshoot_pending,
    imageManipulationCanvas,
    bgExtraction,
  ])

  // image manipulation states
  const [saturate, setSaturate] = useState({x: 100})
  const [brightness, setBrightness] = useState({x: 100})
  const [contrast, setContrast] = useState({x: 100})

  function applyFilters(brightness, saturate, contrast) {
    setBrightness(brightness)
    setSaturate(saturate)
    setContrast(contrast)
    const c = document.getElementById('imageManipulationCanvas')
    const ctx = c.getContext('2d')
    ctx.filter = `brightness(${brightness.x}%) contrast(${contrast.x}%) saturate(${saturate.x}%)`
    let imageObj = document.createElement('img')
    imageObj.src = URL.createObjectURL(selectedImageToSave)

    imageObj.onload = function () {
      ctx.drawImage(imageObj, 0, 0, 250, 320)
    }
  }

  // background extraction checkbox
  function handleCheckbox() {
    setChecked(!checked)
    if (!checked) {
      if (!bgExtraction) {
        setBgExtraction(!bgExtraction)
        dispatch(
          backgroundExtraction(
            take_snapshoot_success,
            imageManipulationCanvas.current,
            setSelectedImageToSave,
          ),
        )
      } else {
        setBgChecked(false)
        drawBlobOnCanvas(imageManipulationCanvas.current, bg_extraction_success)
        setSelectedImageToSave(bg_extraction_success)
        const canvas = document.getElementById('backgroundCanvas')
        const ctx = canvas.getContext('2d')
        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
      }
    } else {
      setBgColor('default')
      drawBlobOnCanvas(imageManipulationCanvas.current, take_snapshoot_success)
      setSelectedImageToSave(take_snapshoot_success)
    }
  }
  // backgorund changing states
  const [bgChecked, setBgChecked] = useState(false)
  function handleBackgroundCheckbox() {
    setBgChecked(!bgChecked)
    if (bgChecked) {
      setBgColor('default')
      const canvas = document.getElementById('backgroundCanvas')
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
    } else {
      setBgColor('#ffffff')
    }
  }

  function setBackgroundColor() {
    setBgColor(colorPickerRef.current.value)
    const canvas = document.getElementById('backgroundCanvas')
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = colorPickerRef.current.value
    ctx.fillRect(0, 0, canvas.width, canvas.height)
  }
  const colorPickerRef = useRef()

  // submit function

  function setImageAsAvatar(blob) {
    dispatch(
      setAvatarPhoto(
        blob,
        brightness,
        saturate,
        contrast,
        identity.photos.list, //props.avatarUrlPost,
        identity.photos.current, //props.avatarUrlPut,
        IdentityCardPhotoPutUrl,
        IdentityCardPhotoPostUrl,
        isCardPhoto,
        cards,
        isEmployee,
        isVisitor,
        bgColor,
      ),
    )
  }

  return (
    <>
      <>
        {/* trick to make refs work for canvasses */}
        <span className="hidden" ref={imageManipulationCanvas} />
        <span className="hidden" ref={backgroundCanvas} />
      </>
      {/*Edit & Save photo part */}
      <>
        {/* image manipulation canvas */}
        <div className="relative text-center">
          <div className=" relative h-[344px]">
            <canvas
              className="absolute inset-0 z-20 mx-auto p-3 lg:m-1"
              id="imageManipulationCanvas"
              ref={imageManipulationCanvas} // this is undefined!
              width={250}
              height={320}
            />
            {/* background extraction canvas */}
            <canvas
              className="absolute inset-0 -z-10 mx-auto p-3 lg:m-1"
              id="backgroundCanvas"
              ref={backgroundCanvas}
              width={250}
              height={320}
            />
          </div>
        </div>
        <>
          <div className="p-3 text-center text-gray-600">{t('editPhoto')}</div>
          {/*Range Inputs*/}
          <div className="m-2 p-2">
            <>
              <label className="block text-sm font-medium text-gray-700">
                {t('addAvatar.Brightness')}
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="range"
                  min={50}
                  max={150}
                  step={1}
                  defaultValue={brightness.x}
                  onChange={(e) => {
                    applyFilters({x: parseFloat(e.target.value).toFixed(2)}, saturate, contrast)
                  }}
                  className="block h-4 w-full appearance-none rounded-md border-gray-300 bg-lime-100 shadow-sm sm:text-sm"
                />
              </div>
            </>
            <>
              <label className="block text-sm font-medium text-gray-700">
                {t('addAvatar.Saturate')}
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="range"
                  min={50}
                  max={150}
                  step={1}
                  defaultValue={saturate.x}
                  onChange={(e) =>
                    applyFilters(brightness, {x: parseFloat(e.target.value).toFixed(2)}, contrast)
                  }
                  className="block h-4 w-full appearance-none rounded-md border-gray-300 bg-lime-100 shadow-sm sm:text-sm"
                />
              </div>
            </>
            <>
              <label className="block text-sm font-medium text-gray-700">
                {t('addAvatar.Contrast')}
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <input
                  type="range"
                  min={50}
                  max={150}
                  step={1}
                  defaultValue={contrast.x}
                  onChange={(e) =>
                    applyFilters(brightness, saturate, {
                      x: parseFloat(e.target.value).toFixed(2),
                    })
                  }
                  className="block h-4 w-full appearance-none rounded-md border-gray-300 bg-lime-100 shadow-sm sm:text-sm"
                />
              </div>
            </>
            <>
              <CheckboxRaw
                id="extract-background-camera-checkbox"
                name="extract_bg"
                label={t('addAvatar.BackgroundExtraction')}
                onClick={() => {
                  handleCheckbox()
                }}
                pending={bg_extraction_pending}
              />
            </>
            {checked && !bg_extraction_pending && (
              <>
                <CheckboxRaw
                  id="paint-bakcground-camera-checkbox"
                  name="background"
                  label={t('addAvatar.BackgroundColor')}
                  onClick={() => handleBackgroundCheckbox()}
                />
                {bgChecked && (
                  <>
                    <input
                      type="color"
                      ref={colorPickerRef}
                      name="bgColor"
                      defaultValue="#ffffff"
                      onChange={() => {
                        setBackgroundColor()
                      }}
                    />
                  </>
                )}
              </>
            )}
            <div className="flex flex-col">
              <div className="mt-2 mb-2 flex justify-between">
                <Button onClick={() => setShowCamera(true)} variant="secondary">
                  {t('takeNewPhoto')}
                </Button>
                <Button
                  variant="secondary"
                  disabled={bg_extraction_pending}
                  pending={set_avatar_photo_pending}
                  onClick={() => {
                    setImageAsAvatar(selectedImageToSave) // upload_photo_success.id upload_photo_success.current_url_extension
                  }}
                >
                  {t('addAvatar.Save')}
                </Button>
              </div>
              <Notification
                error={set_avatar_photo_error}
                setError={setAvatarPhotoCLearError}
                success={set_avatar_photo_success}
              />
            </div>
          </div>
        </>
      </>
    </>
  )
}
