import axios from '../services/api'
import {createNotification} from './notifications'
import I18n from '../i18n'

// Types

const CREATE_PERSON_PENDING = 'idfunctiondb/persons/CREATE_PERSON_PENDING'
const CREATE_PERSON_SUCCESS = 'idfunctiondb/persons/CREATE_PERSON_SUCCESS'
const CREATE_PERSON_ERROR = 'idfunctiondb/persons/CREATE_PERSON_ERROR'
const CREATE_PERSON_CLEAR_ERROR = 'idfunctiondb/persons/CREATE_PERSON_CLEAR_ERROR'

const SET_PERSON_PENDING = 'idfunctiondb/persons/SET_PERSON_PENDING'
const SET_PERSON_SUCCESS = 'idfunctiondb/persons/SET_PERSON_SUCCESS'
const SET_PERSON_ERROR = 'idfunctiondb/persons/SET_PERSON_ERROR'
const SET_PERSON_CLEAR_ERROR = 'idfunctiondb/persons/SET_PERSON_CLEAR_ERROR'

const DELETE_PERSON_PENDING = 'idfunctiondb/persons/DELETE_PERSON_PENDING'
const DELETE_PERSON_SUCCESS = 'idfunctiondb/persons/DELETE_PERSON_SUCCESS'
const DELETE_PERSON_ERROR = 'idfunctiondb/persons/DELETE_PERSON_ERROR'

const initialState = {
  create_person: {
    success: null,
    pending: false,
    error: null,
  },

  set_person: {
    success: null,
    pending: false,
    error: null,
  },

  delete_person: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_PERSON_PENDING:
      return {
        ...state,
        create_person: {
          ...state.create_person,
          pending: true,
        },
      }
    case CREATE_PERSON_SUCCESS:
      return {
        ...state,
        create_person: {
          ...state.create_person,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_PERSON_ERROR:
      return {
        ...state,
        create_person: {
          ...state.create_person,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_PERSON_CLEAR_ERROR:
      return {
        ...state,
        create_person: {
          ...state.create_person,
          error: null,
          pending: false,
        },
      }
    case SET_PERSON_PENDING:
      return {
        ...state,
        set_person: {
          ...state.set_person,
          pending: true,
        },
      }
    case SET_PERSON_SUCCESS:
      return {
        ...state,
        set_person: {
          ...state.set_person,
          success: action.payload,
          pending: false,
        },
      }
    case SET_PERSON_ERROR:
      return {
        ...state,
        set_person: {
          ...state.set_person,
          error: action.payload,
          pending: false,
        },
      }
    case SET_PERSON_CLEAR_ERROR:
      return {
        ...state,
        set_person: {
          ...state.set_person,
          error: null,
          pending: false,
        },
      }
    case DELETE_PERSON_PENDING:
      return {
        ...state,
        delete_person: {
          ...state.delete_person,
          pending: true,
        },
      }
    case DELETE_PERSON_SUCCESS:
      return {
        ...state,
        delete_person: {
          ...state.delete_person,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_PERSON_ERROR:
      return {
        ...state,
        delete_person: {
          ...state.delete_person,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function createPersonPending() {
  return {type: CREATE_PERSON_PENDING}
}
function createPersonSuccess(payload) {
  return {type: CREATE_PERSON_SUCCESS, payload: payload}
}
function createPersonError(error) {
  return {type: CREATE_PERSON_ERROR, payload: error}
}

export function createPersonClearError() {
  return {type: CREATE_PERSON_CLEAR_ERROR}
}

function setPersonPending() {
  return {type: SET_PERSON_PENDING}
}
function setPersonSuccess(payload) {
  return {type: SET_PERSON_SUCCESS, payload: payload}
}
function setPersonError(error) {
  return {type: SET_PERSON_ERROR, payload: error}
}
export function setPersonClearError() {
  return {type: SET_PERSON_CLEAR_ERROR}
}

function deletePersonPending() {
  return {type: DELETE_PERSON_PENDING}
}
function deletePersonSuccess(payload) {
  return {type: DELETE_PERSON_SUCCESS, payload: payload}
}
function deletePersonError(error) {
  return {type: DELETE_PERSON_ERROR, payload: error}
}

// Operations
export const createPerson = (values, notify, userID) => (dispatch) => {
  dispatch(createPersonPending())
  return axios
    .post(`/api/v1/persons`, values)
    .then((res) => {
      dispatch(createPersonSuccess(res))
      if (notify) {
        dispatch(
          createNotification(userID, {
            code: '201',
            message: I18n.t('notificationSuccess.PersonCreated', {name: res.data.full_name}),
          }),
        )
      }
      return res.data
    })
    .catch((error) => dispatch(createPersonError(error)))
}

export const setPerson = (id, values) => (dispatch) => {
  dispatch(setPersonPending())
  return axios
    .put(`/api/v1/persons/${id}`, values)
    .then((res) => {
      dispatch(setPersonSuccess(res))
      return res.data
    })
    .catch((error) => dispatch(setPersonError(error)))
}

export const deletePerson = (id, userID) => (dispatch) => {
  dispatch(deletePersonPending())
  return axios
    .delete(`/api/v1/persons/${id}`)
    .then((res) => {
      dispatch(deletePersonSuccess(res))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.PersonDeleted', {name: res.data}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(deletePersonError(error)))
}
