import axios from '../services/api'

// Types
const SET_CARD_PHOTO_PENDING = 'idfunctiondb/cards_photos/SET_CARD_PHOTO_PENDING'
const SET_CARD_PHOTO_SUCCESS = 'idfunctiondb/cards_photos/SET_CARD_PHOTO_SUCCESS'
const SET_CARD_PHOTO_ERROR = 'idfunctiondb/cards_photos/SET_CARD_PHOTO_ERROR'

// Initial
const initialState = {
  set_card_photo: {
    success: [],
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CARD_PHOTO_PENDING:
      return {
        ...state,
        set_card_photo: {
          ...state.set_card_photo,
          pending: true,
        },
      }
    case SET_CARD_PHOTO_SUCCESS:
      return {
        ...state,
        set_card_photo: {
          ...state.set_card_photo,
          success: Date.now(),
          pending: false,
        },
      }
    case SET_CARD_PHOTO_ERROR:
      return {
        ...state,
        set_card_photo: {
          ...state.set_card_photo,
          error: action.payload,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function setCardPhotoPending() {
  return {type: SET_CARD_PHOTO_PENDING}
}
function setCardPhotoSuccess(payload) {
  return {type: SET_CARD_PHOTO_SUCCESS, payload: payload}
}
function setCardPhotoError(error) {
  return {type: SET_CARD_PHOTO_ERROR, payload: error}
}

export const setCardPhoto = (url, values) => (dispatch) => {
  dispatch(setCardPhotoPending())
  return axios
    .put(url, values)
    .then((res) => {
      dispatch(setCardPhotoSuccess(url))
      return res
    })
    .catch((error) => dispatch(setCardPhotoError(error)))
}
