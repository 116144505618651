import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {InputComponent, InputYupValidationsType} from '../formRendererRHF/types'
import Notification from '../Notification'
import {updateIdentity} from '../../stores/identity'
import {useDispatch} from 'react-redux'

const FormAddToIdentityGroup = ({identity}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [pending, setPending] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (values) => {
    setPending(true)
    setError(null)
    setSuccess(false)

    const data = await dispatch(
      updateIdentity(values.group, {
        additional_children_identities: [identity.id],
      }),
    )

    if (data?.id) {
      setSuccess(true)
    } else {
      setError(data?.payload)
    }

    setPending(false)
  }

  const fields = [
    {
      component: InputComponent.ASYNCREACTSELECT,
      name: 'group',
      label: 'sidebar.Identities',
      url: `/api/v1/identities?q=`,
      clearOptions: true,
      nestedOptions: true,
      optionValue: 'id',
      optionLabel: 'owner_name',
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields: fields,
          name: 'form-update-identity',
          submitButtonText: t('formCommon.Add'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        infoMessage={
          <Notification
            error={error}
            setError={() => {
              setError(null)
              setSuccess(false)
            }}
            success={!!success}
            successMessageKey="identity.UpdateSuccess"
          />
        }
      />
    </>
  )
}

export default FormAddToIdentityGroup
