import axios from '../services/api'
import {createNotification, createNotificationOnClient} from './notifications'
import I18n from '../i18n'

// Types

const GET_INVENTORY_PENDING = 'idfunctiondb/inventories/GET_INVENTORY_PENDING'
const GET_INVENTORY_SUCCESS = 'idfunctiondb/inventories/GET_INVENTORY_SUCCESS'
const GET_INVENTORY_ERROR = 'idfunctiondb/inventories/GET_INVENTORY_ERROR'

const GET_INVENTORY_META_PENDING = 'idfunctiondb/inventories/GET_INVENTORY_META_PENDING'
const GET_INVENTORY_META_SUCCESS = 'idfunctiondb/inventories/GET_INVENTORY_META_SUCCESS'
const GET_INVENTORY_META_ERROR = 'idfunctiondb/inventories/GET_INVENTORY_META_ERROR'

const DELETE_INVENTORY_PENDING = 'idfunctiondb/inventories/DELETE_INVENTORY_PENDING'
const DELETE_INVENTORY_SUCCESS = 'idfunctiondb/inventories/DELETE_INVENTORY_SUCCESS'
const DELETE_INVENTORY_ERROR = 'idfunctiondb/inventories/DELETE_INVENTORY_ERROR'
const DELETE_INVENTORY_CLEAR_ERROR = 'idfunctiondb/inventories/DELETE_INVENTORY_CLEAR_ERROR'

const GET_INVENTORY_HISTORY_PENDING = 'idfunctiondb/inventories/GET_INVENTORY_HISTORY_PENDING'
const GET_INVENTORY_HISTORY_SUCCESS = 'idfunctiondb/inventories/GET_INVENTORY_HISTORY_SUCCESS'
const GET_INVENTORY_HISTORY_ERROR = 'idfunctiondb/inventories/GET_INVENTORY_HISTORY_ERROR'
const GET_INVENTORY_HISTORY_CLEAR_ERROR =
  'idfunctiondb/inventories/GET_INVENTORY_HISTORY_CLEAR_ERROR'

const SET_INVENTORY_PENDING = 'idfunctiondb/inventories/SET_INVENTORY_PENDING'
const SET_INVENTORY_SUCCESS = 'idfunctiondb/inventories/SET_INVENTORY_SUCCESS'
const SET_INVENTORY_ERROR = 'idfunctiondb/inventories/SET_INVENTORY_ERROR'
const SET_INVENTORY_CLEAR_ERROR = 'idfunctiondb/inventories/SET_INVENTORY_CLEAR_ERROR'

const CREATE_INVENTORY_PENDING = 'idfunctiondb/inventories/CREATE_INVENTORY_PENDING'
const CREATE_INVENTORY_SUCCESS = 'idfunctiondb/inventories/CREATE_INVENTORY_SUCCESS'
const CREATE_INVENTORY_ERROR = 'idfunctiondb/inventories/CREATE_INVENTORY_ERROR'
const CREATE_INVENTORY_CLEAR_ERROR = 'idfunctiondb/inventories/CREATE_INVENTORY_CLEAR_ERROR'

const GET_INVENTORIES_TYPES_PENDING = 'idfunctiondb/inventories/GET_INVENTORIES_TYPES_PENDING'
const GET_INVENTORIES_TYPES_SUCCESS = 'idfunctiondb/inventories/GET_INVENTORIES_TYPES_SUCCESS'
const GET_INVENTORIES_TYPES_ERROR = 'idfunctiondb/inventories/GET_INVENTORIES_TYPES_ERROR'
const GET_INVENTORIES_TYPES_CLEAR_ERROR =
  'idfunctiondb/inventories/GET_INVENTORIES_TYPES_CLEAR_ERROR'

const DELETE_INVENTORY_TYPE_PENDING = 'idfunctiondb/inventories/DELETE_INVENTORY_TYPE_PENDING'
const DELETE_INVENTORY_TYPE_SUCCESS = 'idfunctiondb/inventories/DELETE_INVENTORY_TYPE_SUCCESS'
const DELETE_INVENTORY_TYPE_ERROR = 'idfunctiondb/inventories/DELETE_INVENTORY_TYPE_ERROR'
const DELETE_INVENTORY_TYPE_CLEAR_ERROR =
  'idfunctiondb/inventories/DELETE_INVENTORY_TYPE_CLEAR_ERROR'

const CREATE_INVENTORY_TYPE_PENDING = 'idfunctiondb/inventories/CREATE_INVENTORY_TYPE_PENDING'
const CREATE_INVENTORY_TYPE_SUCCESS = 'idfunctiondb/inventories/CREATE_INVENTORY_TYPE_SUCCESS'
const CREATE_INVENTORY_TYPE_ERROR = 'idfunctiondb/inventories/CREATE_INVENTORY_TYPE_ERROR'
const CREATE_INVENTORY_TYPE_CLEAR_ERROR =
  'idfunctiondb/inventories/CREATE_INVENTORY_TYPE_CLEAR_ERROR'

const UPDATE_INVENTORY_TYPE_PENDING = 'idfunctiondb/inventories/UPDATE_INVENTORY_TYPE_PENDING'
const UPDATE_INVENTORY_TYPE_SUCCESS = 'idfunctiondb/inventories/UPDATE_INVENTORY_TYPE_SUCCESS'
const UPDATE_INVENTORY_TYPE_ERROR = 'idfunctiondb/inventories/UPDATE_INVENTORY_TYPE_ERROR'
const UPDATE_INVENTORY_TYPE_CLEAR_ERROR =
  'idfunctiondb/inventories/UPDATE_INVENTORY_TYPE_CLEAR_ERROR'

// Initial
const initialState = {
  get_inventory: {
    success: null,
    pending: false,
    error: null,
  },

  get_inventory_meta: {
    success: {},
    pending: false,
    error: null,
  },

  delete_inventory: {
    success: null,
    pending: false,
    error: null,
  },

  get_inventory_history: {
    success: [],
    pending: false,
    error: null,
  },

  set_inventory: {
    success: null,
    pending: false,
    error: null,
  },

  get_inventories_types: {
    success: [],
    pending: false,
    error: null,
  },

  delete_inventory_type: {
    success: null,
    pending: false,
    error: null,
  },

  create_inventory_type: {
    success: null,
    pending: false,
    error: null,
  },

  create_inventory: {
    success: null,
    pending: false,
    error: null,
  },

  update_inventory_type: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_INVENTORY_PENDING:
      return {
        ...state,
        get_inventory: {
          ...state.get_inventory,
          pending: true,
        },
      }
    case GET_INVENTORY_SUCCESS:
      return {
        ...state,
        get_inventory: {
          ...state.get_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case GET_INVENTORY_ERROR:
      return {
        ...state,
        get_inventory: {
          ...state.get_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case GET_INVENTORY_META_PENDING:
      return {
        ...state,
        get_inventory_meta: {
          ...state.get_inventory_meta,
          pending: true,
        },
      }
    case GET_INVENTORY_META_SUCCESS:
      return {
        ...state,
        get_inventory_meta: {
          ...state.get_inventory_meta,
          success: action.payload,
          pending: false,
        },
      }
    case GET_INVENTORY_META_ERROR:
      return {
        ...state,
        get_inventory_meta: {
          ...state.get_inventory_meta,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_INVENTORY_PENDING:
      return {
        ...state,
        delete_inventory: {
          ...state.delete_inventory,
          pending: true,
        },
      }
    case DELETE_INVENTORY_SUCCESS:
      return {
        ...state,
        delete_inventory: {
          ...state.delete_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_INVENTORY_ERROR:
      return {
        ...state,
        delete_inventory: {
          ...state.delete_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_INVENTORY_CLEAR_ERROR:
      return {
        ...state,
        delete_inventory: {
          ...state.delete_inventory,
          error: null,
        },
      }
    case GET_INVENTORY_HISTORY_PENDING:
      return {
        ...state,
        get_inventory_history: {
          ...state.get_inventory_history,
          pending: true,
        },
      }
    case GET_INVENTORY_HISTORY_SUCCESS:
      return {
        ...state,
        get_inventory_history: {
          ...state.get_inventory_history,
          success: action.payload,
          pending: false,
        },
      }
    case GET_INVENTORY_HISTORY_ERROR:
      return {
        ...state,
        get_inventory_history: {
          ...state.get_inventory_history,
          error: action.payload,
          pending: false,
        },
      }
    case GET_INVENTORY_HISTORY_CLEAR_ERROR:
      return {
        ...state,
        get_inventory_history: {
          ...state.get_inventory_history,
          error: null,
          pending: false,
        },
      }
    case SET_INVENTORY_PENDING:
      return {
        ...state,
        set_inventory: {
          ...state.set_inventory,
          pending: true,
        },
      }
    case SET_INVENTORY_SUCCESS:
      return {
        ...state,
        set_inventory: {
          ...state.set_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case SET_INVENTORY_ERROR:
      return {
        ...state,
        set_inventory: {
          ...state.set_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case SET_INVENTORY_CLEAR_ERROR:
      return {
        ...state,
        set_inventory: {
          ...state.set_inventory,
          error: null,
          pending: false,
        },
      }
    case CREATE_INVENTORY_PENDING:
      return {
        ...state,
        create_inventory: {
          ...state.create_inventory,
          pending: true,
        },
      }
    case CREATE_INVENTORY_SUCCESS:
      return {
        ...state,
        create_inventory: {
          ...state.create_inventory,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_INVENTORY_ERROR:
      return {
        ...state,
        create_inventory: {
          ...state.create_inventory,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_INVENTORY_CLEAR_ERROR:
      return {
        ...state,
        create_inventory: {
          ...state.create_inventory,
          error: null,
          pending: false,
        },
      }
    case GET_INVENTORIES_TYPES_PENDING:
      return {
        ...state,
        get_inventories_types: {
          ...state.get_inventories_types,
          pending: true,
        },
      }
    case GET_INVENTORIES_TYPES_SUCCESS:
      return {
        ...state,
        get_inventories_types: {
          ...state.get_inventories_types,
          success: action.payload,
          pending: false,
        },
      }
    case GET_INVENTORIES_TYPES_ERROR:
      return {
        ...state,
        get_inventories_types: {
          ...state.get_inventories_types,
          error: action.payload,
          pending: false,
        },
      }
    case GET_INVENTORIES_TYPES_CLEAR_ERROR:
      return {
        ...state,
        get_inventories_types: {
          ...state.get_inventories_types,
          error: null,
          pending: false,
        },
      }
    case DELETE_INVENTORY_TYPE_PENDING:
      return {
        ...state,
        delete_inventory_type: {
          ...state.delete_inventory_type,
          pending: true,
        },
      }
    case DELETE_INVENTORY_TYPE_SUCCESS:
      return {
        ...state,
        delete_inventory_type: {
          ...state.delete_inventory_type,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_INVENTORY_TYPE_ERROR:
      return {
        ...state,
        delete_inventory_type: {
          ...state.delete_inventory_type,
          error: action.payload,
          pending: false,
        },
      }
    case DELETE_INVENTORY_TYPE_CLEAR_ERROR:
      return {
        ...state,
        delete_inventory_type: {
          ...state.delete_inventory_type,
          error: null,
        },
      }
    case CREATE_INVENTORY_TYPE_PENDING:
      return {
        ...state,
        create_inventory_type: {
          ...state.create_inventory_type,
          pending: true,
        },
      }
    case CREATE_INVENTORY_TYPE_SUCCESS:
      return {
        ...state,
        create_inventory_type: {
          ...state.create_inventory_type,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_INVENTORY_TYPE_ERROR:
      return {
        ...state,
        create_inventory_type: {
          ...state.create_inventory_type,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_INVENTORY_TYPE_CLEAR_ERROR:
      return {
        ...state,
        create_inventory_type: {
          ...state.create_inventory_type,
          error: null,
          pending: false,
        },
      }
    case UPDATE_INVENTORY_TYPE_PENDING:
      return {
        ...state,
        update_inventory_type: {
          ...state.update_inventory_type,
          pending: true,
        },
      }
    case UPDATE_INVENTORY_TYPE_SUCCESS:
      return {
        ...state,
        update_inventory_type: {
          ...state.update_inventory_type,
          success: action.payload,
          pending: false,
        },
      }
    case UPDATE_INVENTORY_TYPE_ERROR:
      return {
        ...state,
        update_inventory_type: {
          ...state.update_inventory_type,
          error: action.payload,
          pending: false,
        },
      }
    case UPDATE_INVENTORY_TYPE_CLEAR_ERROR:
      return {
        ...state,
        update_inventory_type: {
          ...state.update_inventory_type,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions

function getInventoryPending() {
  return {type: GET_INVENTORY_PENDING}
}

function getInventorySuccess(payload) {
  return {type: GET_INVENTORY_SUCCESS, payload: payload}
}

function getInventoryError(error) {
  return {type: GET_INVENTORY_ERROR, payload: error}
}

function getInventoryMetaPending() {
  return {type: GET_INVENTORY_META_PENDING}
}

function getInventoryMetaSuccess(payload) {
  return {type: GET_INVENTORY_META_SUCCESS, payload: payload}
}

function getInventoryMetaError(error) {
  return {type: GET_INVENTORY_META_ERROR, payload: error}
}

function deleteInventoryPending() {
  return {type: DELETE_INVENTORY_PENDING}
}

function deleteInventorySuccess(payload) {
  return {type: DELETE_INVENTORY_SUCCESS, payload: payload}
}

function deleteInventoryError(error) {
  return {type: DELETE_INVENTORY_ERROR, payload: error}
}

export function deleteInventoryClearError() {
  return {type: DELETE_INVENTORY_CLEAR_ERROR}
}

function getInventoryHistoryPending() {
  return {type: GET_INVENTORY_HISTORY_PENDING}
}

function getInventoryHistorySuccess(payload) {
  return {type: GET_INVENTORY_HISTORY_SUCCESS, payload: payload}
}

function getInventoryHistoryError(error) {
  return {type: GET_INVENTORY_HISTORY_ERROR, payload: error}
}

export function getInventoryHistoryClearError() {
  return {type: GET_INVENTORY_HISTORY_CLEAR_ERROR}
}

function setInventoryPending() {
  return {type: SET_INVENTORY_PENDING}
}

function setInventorySuccess(payload) {
  return {type: SET_INVENTORY_SUCCESS, payload: payload}
}

function setInventoryError(error) {
  return {type: SET_INVENTORY_ERROR, payload: error}
}

export function setInventoryClearError() {
  return {type: SET_INVENTORY_CLEAR_ERROR}
}

function createInventoryPending() {
  return {type: CREATE_INVENTORY_PENDING}
}

function createInventorySuccess(payload) {
  return {type: CREATE_INVENTORY_SUCCESS, payload: payload}
}

function createInventoryError(error) {
  return {type: CREATE_INVENTORY_ERROR, payload: error}
}

export function createInventoryClearError() {
  return {type: CREATE_INVENTORY_CLEAR_ERROR}
}

function getInventoriesTypesPending() {
  return {type: GET_INVENTORIES_TYPES_PENDING}
}

function getInventoriesTypesSuccess(payload) {
  return {type: GET_INVENTORIES_TYPES_SUCCESS, payload: payload}
}

function getInventoriesTypesError(error) {
  return {type: GET_INVENTORIES_TYPES_ERROR, payload: error}
}

export function getInventoriesTypesClearError() {
  return {type: GET_INVENTORIES_TYPES_CLEAR_ERROR}
}

function deleteInventoryTypePending() {
  return {type: DELETE_INVENTORY_TYPE_PENDING}
}

function deleteInventoryTypeSuccess(payload) {
  return {type: DELETE_INVENTORY_TYPE_SUCCESS, payload: payload}
}

function deleteInventoryTypeError(error) {
  return {type: DELETE_INVENTORY_TYPE_ERROR, payload: error}
}

export function deleteInventoryTypeClearError() {
  return {type: DELETE_INVENTORY_TYPE_CLEAR_ERROR}
}

function createInventoryTypePending() {
  return {type: CREATE_INVENTORY_TYPE_PENDING}
}

function createInventoryTypeSuccess(payload) {
  return {type: CREATE_INVENTORY_TYPE_SUCCESS, payload: payload}
}

function createInventoryTypeError(error) {
  return {type: CREATE_INVENTORY_TYPE_ERROR, payload: error}
}

export function createInventoryTypeClearError() {
  return {type: CREATE_INVENTORY_TYPE_CLEAR_ERROR}
}

function updateInventoryTypePending() {
  return {type: UPDATE_INVENTORY_TYPE_PENDING}
}

function updateInventoryTypeSuccess(payload) {
  return {type: UPDATE_INVENTORY_TYPE_SUCCESS, payload: payload}
}

function updateInventoryTypeError(error) {
  return {type: UPDATE_INVENTORY_TYPE_ERROR, payload: error}
}

export function updateInventoryTypeClearError() {
  return {type: UPDATE_INVENTORY_TYPE_CLEAR_ERROR}
}

// Operations

export const getInventory = (url) => (dispatch) => {
  dispatch(getInventoryPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getInventorySuccess(res.data))
      return res.data
    })
    .catch((error) => {
      dispatch(getInventoryError(error))
    })
}

export const getInventoryMeta = (id) => (dispatch) => {
  dispatch(getInventoryMetaPending())
  return axios
    .get(`/api/v1/inventories?current=${id}`)
    .then((res) => {
      dispatch(getInventoryMetaSuccess(res.data.meta))
      return res.data.meta
    })
    .catch((error) => dispatch(getInventoryMetaError(error)))
}

export const deleteInventory = (id) => (dispatch) => {
  dispatch(deleteInventoryPending())
  return axios
    .delete(`/api/v1/inventories/${id}`)
    .then((res) => {
      dispatch(deleteInventorySuccess(res.data))
    })
    .catch((error) => {
      dispatch(deleteInventoryError(error))
    })
}

export const getInventoryHistory = (url) => (dispatch) => {
  dispatch(getInventoryHistoryPending())
  return axios
    .get(url)
    .then((res) => {
      dispatch(getInventoryHistorySuccess(res.data.history))
      return res.data.history
    })
    .catch((error) => dispatch(getInventoryHistoryError(error)))
}

export const setInventory = (id, values) => (dispatch) => {
  dispatch(setInventoryPending())
  return axios
    .put(`/api/v1/inventories/${id}`, values)
    .then((res) => {
      dispatch(setInventorySuccess(res.data))
      dispatch(createNotificationOnClient(res.status, 'notificationSuccess.InventoryUpdated'))
      return res.data
    })
    .catch((error) => dispatch(setInventoryError(error)))
}

export const createInventory = (values, userID) => (dispatch) => {
  dispatch(createInventoryPending())
  return axios
    .post(`/api/v1/inventories`, values)
    .then((res) => {
      dispatch(createInventorySuccess(res))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.InventoryCreated', {
            number: res.data.inventory_number,
          }),
          link: `/inventories/${res.data.id}`,
        }),
      )
    })
    .catch((error) => dispatch(createInventoryError(error)))
}

export const getInventoriesTypes = () => (dispatch) => {
  dispatch(getInventoriesTypesPending())
  return axios
    .get(`/api/v1/inventories_types`)
    .then((res) => {
      dispatch(getInventoriesTypesSuccess(res.data.results))
      return res.data
    })
    .catch((error) => dispatch(getInventoriesTypesError(error)))
}

export const deleteInventoryType = (id) => (dispatch) => {
  dispatch(deleteInventoryTypePending())
  return axios
    .delete(`/api/v1/inventories_types/${id}`)
    .then((res) => {
      dispatch(deleteInventoryTypeSuccess(res.data))
    })
    .catch((error) => {
      dispatch(deleteInventoryTypeError(error))
    })
}

export const createInventoryType = (values, userID) => (dispatch) => {
  dispatch(createInventoryTypePending())
  return axios
    .post(`/api/v1/inventories_types`, values)
    .then((res) => {
      dispatch(createInventoryTypeSuccess(res))
      dispatch(
        createNotification(userID, {
          code: '201',
          message: I18n.t('notificationSuccess.InventoryTypeCreated', {name: res.data.name}),
        }),
      )
      return res.data
    })
    .catch((error) => dispatch(createInventoryTypeError(error)))
}

export const updateInventoryType = (url, values) => (dispatch) => {
  dispatch(updateInventoryTypePending())
  return axios
    .put(url, values)
    .then((res) => {
      dispatch(updateInventoryTypeSuccess(res))
      return res.data
    })
    .catch((error) => dispatch(updateInventoryTypeError(error)))
}
