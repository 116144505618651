import {useTranslation} from 'react-i18next'
import {resetUserPassword, resetUserPasswordClearError} from '../../stores/users'
import {useDispatch, useSelector} from 'react-redux'
import {TextInput} from '../FormInputs'
import {Formik, Form} from 'formik'
import {FormikSaveButton} from '../Buttons'
import * as Yup from 'yup'

export default function FormResetUserPassword({userId}) {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const userID = useSelector((state) => state.auth.fetchUserSuccess.id)
  const pending = useSelector((state) => state.users.reset_user_password.pending)
  const error = useSelector((state) => state.users.reset_user_password.error)

  const initialValues = {
    password: '',
  }

  const handleSubmit = (values, actions) => {
    dispatch(
      resetUserPassword(
        userId, // the user to change the password
        {
          password: values.password,
        },
        userID, // the user who changed the password
      ),
    )
    actions.resetForm()
  }

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t('yupError.ThisFieldIsRequired', {field: t('login.Password')}))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$%^&*()_+|~=`{}[:;'"/§ <>?,.@#\]])[A-Za-z\d-!$%^&*()_+|~=`{}[:;'"/§ <>?,.@#\]]{6,}$/,
        t('login.PasswordRequirements'),
      ),
  })
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextInput
            label={t('userManagement.EnterPassword')}
            type="password"
            name="password"
            placeholder=""
          />
          <FormikSaveButton
            type="submit"
            testid="form-reset-password-submit"
            text={t('addPersonForm.Save')}
            primary
            pending={pending}
            error={error}
            setError={() => dispatch(resetUserPasswordClearError())}
          />
        </Form>
      </Formik>
    </>
  )
}
