import {Modal, ModalContentsBase, ModalOpenButton} from './Modal'
import {PrinterIcon} from '@heroicons/react/outline'
import FormAssignPrinter from './forms/FormAssignPrinter'
import {useTranslation} from 'react-i18next'
import {classNames} from '../utils/classNames'

const AssignPrinter = ({iconClassNames}) => {
  const {t} = useTranslation()
  return (
    <Modal>
      <ModalOpenButton>
        <PrinterIcon
          className={classNames(
            'h-6 w-6 flex-shrink-0 cursor-pointer',
            iconClassNames || 'text-gray-400',
          )}
        />
      </ModalOpenButton>
      <ModalContentsBase title={t('userManagement.AssignPrinter')}>
        <FormAssignPrinter />
      </ModalContentsBase>
    </Modal>
  )
}

export default AssignPrinter
