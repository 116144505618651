import {useState, useEffect} from 'react'
import {useAsyncDebounce} from 'react-table'
import {useDispatch, useSelector} from 'react-redux'
import {getCardProfiles, getCardProfilesCLearError} from '../../stores/cards'
import SpinnerSVG from '../../assets/Spinner.svg'
import ErrorOnView from '../ErrorOnView'
import {useTranslation} from 'react-i18next'
import {IndeterminateCheckbox} from './ServerSideTable'
import ReactSelect from 'react-select'
import axios from '../../services/api'
import {filterByUniqueProperty, debounce} from '../../utils/custom_attributes'
import {customStyles} from '../formRendererRHF/formFieldStyle'

export const SelectCheckbox = ({getToggleAllRowsSelectedProps}) => (
  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
)

const AsyncSelectView = ({label, optionValue, url, setFilter, filterValue}) => {
  const [pending, setPending] = useState(false)
  const [query, setQuery] = useState('')
  const [error, setError] = useState()
  const [options, setOptions] = useState([])

  useEffect(() => {
    const transformOptions = (options) =>
      options?.map((item) => {
        return {
          value: item[optionValue],
          label: item[optionValue],
        }
      })
    const fetchQuery = async (url, query) => {
      let tempurl = ''
      if (url.includes(`$temp#`)) {
        if (query === '') {
          url.searchParams.set('filters', '')
        }
        tempurl = url.replace(`$temp#`, query)
      } else {
        tempurl = url + query
      }
      setPending(true)
      setError()
      try {
        const {
          data: {results},
        } = await axios.get(tempurl)
        results &&
          setOptions((prevState) => {
            return filterByUniqueProperty(transformOptions(results), 'value')
          })
      } catch (error) {
        setError(error.data?.message)
      }
      setPending(false)
    }
    debounce(fetchQuery(url, query), 500)
  }, [query, url, optionValue]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <span className="w-full min-w-[190px]" onClick={(e) => e.stopPropagation()}>
      <label>{label}</label>
      {error ? (
        <ErrorOnView error={error} setError={() => setError()} />
      ) : (
        <ReactSelect
          isClearable
          cacheOptions
          isLoading={pending}
          onInputChange={(query) => setQuery(query)}
          styles={customStyles(!!error)}
          isSearchable
          options={options}
          menuPortalTarget={document.body}
          value={{
            value: filterValue,
            label: filterValue,
          }}
          onChange={(option) => setFilter(option?.value || undefined)}
        />
      )}
    </span>
  )
}

export const ColumnFilter = ({column}) => {
  const {t} = useTranslation()
  const {filterValue, setFilter} = column
  const [value, setValue] = useState(filterValue)
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 1000)
  return (
    <div
      className="relative min-w-[110px] rounded-md shadow-sm"
      onClick={(e) => e.stopPropagation()}
    >
      <label>{column.label}</label>
      <input
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
        type="search"
        placeholder={t('search')}
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </div>
  )
}

export function SelectColumnFilterLocations({
  column: {filterValue, setFilter, id, label, permissions},
}) {
  const options = permissions
  const {t} = useTranslation()
  // Render a multi-select box
  return (
    <span className="w-full min-w-[150px]" onClick={(e) => e.stopPropagation()}>
      <label>{label}</label>
      <select
        className="mt-1 block w-full text-ellipsis rounded-md border-gray-300 text-sm shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
        name={'id'}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option className="text-sm" value="">
          {t('all')}
        </option>
        {options?.map((option, i) => (
          <option className="text-sm" key={i} value={option.label}>
            {option.label}
          </option>
        ))}
      </select>
    </span>
  )
}

export function SelectColumnFilterRenderAt({column: {filterValue, setFilter, id, label}}) {
  const {t} = useTranslation()
  const options = [
    {value: 'employee', label: t('workflows.Employee')},
    {value: 'employeeTable', label: t('workflows.EmployeeTable')},
    {value: 'visitorTable', label: t('workflows.VisitorTable')},
    {value: 'visitor', label: t('workflows.Visitor')},
    {value: 'cardTable', label: t('workflows.CardTable')},
    {value: 'card', label: t('workflows.Card')},
    {value: 'inventoryTable', label: t('workflows.InventoryTable')},
    {value: 'inventory', label: t('workflows.Inventory')},
    {value: 'resourceTable', label: t('workflows.ResourceTable')},
  ]

  // Render a multi-select box
  return (
    <span className="w-full min-w-[150px]" onClick={(e) => e.stopPropagation()}>
      <label>{label}</label>
      <select
        className="mt-1 block w-full text-ellipsis rounded-md border-gray-300 text-sm shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
        name={'id'}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option className="text-sm" value="">
          {t('all')}
        </option>
        {options.map((option, i) => (
          <option className="text-sm" key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </span>
  )
}

export function SelectColumnFilterCardStates({column: {filterValue, setFilter, id, label}}) {
  return (
    <AsyncSelectView
      label={label}
      url={`/api/v1/cards/states?q=`}
      optionValue="name"
      name={id}
      setFilter={setFilter}
      filterValue={filterValue}
    />
  )
}

export function SelectColumnFilterCardProfiles({column: {filterValue, setFilter, id, label}}) {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const cardProfiles = useSelector((state) => state.cards.get_card_profiles.success)
  const cardProfilesPending = useSelector((state) => state.cards.get_card_profiles.pending)
  const cardProfilesError = useSelector((state) => state.cards.get_card_profiles.error)

  useEffect(() => {
    dispatch(getCardProfiles(`/api/v1/cards/profiles`))
  }, [dispatch, cardProfilesError?.message])

  // Render a multi-select box
  return (
    <span className="w-full min-w-[150px]" onClick={(e) => e.stopPropagation()}>
      <label>{label}</label>
      {cardProfilesPending ? ( // TODO make it nicer
        <div className="flex items-start justify-center p-2 text-sm">
          <img alt="o" src={SpinnerSVG} className="mr-3 h-6 w-6 animate-spin text-lime-500" />
          {t('loading')}
        </div>
      ) : !cardProfilesError ? (
        <select
          className="mt-1 block w-full text-ellipsis rounded-md border-gray-300 text-sm shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
          name={'id'}
          id={id}
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined)
          }}
        >
          <option className="text-sm" value="">
            {t('all')}
          </option>
          {cardProfiles.map((option, i) => (
            <option className="text-sm" key={i} value={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      ) : (
        <ErrorOnView
          error={cardProfilesError}
          setError={() => dispatch(getCardProfilesCLearError())}
        />
      )}
    </span>
  )
}

export function SelectColumnFilterCompanies({column: {setFilter, label, filterValue}}) {
  return (
    <AsyncSelectView
      label={label}
      url={`/api/v1/companies?q=`}
      optionValue="name"
      name={'company_name'}
      setFilter={setFilter}
      filterValue={filterValue}
    />
  )
}

export function SelectColumnFilterInventoryTypes({column: {filterValue, setFilter, id, label}}) {
  return (
    <AsyncSelectView
      label={label}
      url={`/api/v1/inventories_types?q=`}
      optionValue="name"
      name={'inventories_types'}
      setFilter={setFilter}
      filterValue={filterValue}
    />
  )
}

export function SelectColumnFilterResourceTypes({column: {filterValue, setFilter, id, label}}) {
  return (
    <AsyncSelectView
      label={label}
      url={`/api/v1/access_profiles_types?q=`}
      optionValue="name"
      name={'access_profiles_types'}
      setFilter={setFilter}
      filterValue={filterValue}
    />
  )
}

// Table

export const ColumnFilterDate = ({column}) => {
  const {t} = useTranslation()
  const {filterValue, setFilter} = column
  const [value, setValue] = useState(filterValue)
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 1000)
  return (
    <span className="w-full" onClick={(e) => e.stopPropagation()}>
      <div className="relative rounded-md shadow-sm">
        <label>{column.label}</label>
        <input
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-lime-500 focus:ring-lime-500 sm:text-sm"
          type="date"
          placeholder={t('search')}
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
        />
      </div>
    </span>
  )
}

export function SelectColumnFilterEmployeeState({column: {filterValue, setFilter, id, label}}) {
  const {t} = useTranslation()
  const options = [
    {
      value: 1,
      label: t('active'),
    },
    {
      value: 2,
      label: t('inactive'),
    },
  ]

  // Render a multi-select box
  return (
    <span className="w-full min-w-[150px]" onClick={(e) => e.stopPropagation()}>
      <label>{label}</label>
      <select
        className="mt-1 block w-full text-ellipsis rounded-md border-gray-300 text-sm shadow-sm focus:border-lime-300 focus:ring focus:ring-lime-200 focus:ring-opacity-50"
        name={'id'}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option className="text-sm" value="">
          {t('all')}
        </option>
        {options.map((option, i) => (
          <option className="text-sm" key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </span>
  )
}

// Not used for now
// export function SelectColumnFilterActive({
//   column: {filterValue, setFilter, id, label, permissions},
// }) {
//   const {t} = useTranslation()
//   const options = [
//     {value: 'active', label: t('workflows.Active')},
//     {value: 'inactive', label: t('workflows.Inactive')},
//   ]

//   // Render a multi-select box
//   return (
//     <span>
//       <label>{label}</label>
//       <select
//         className="mt-1 block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-lime-300 focus:ring
// focus:ring-lime-200 focus:ring-opacity-50" name={'id'} id={id} value={filterValue} onChange={(e) => {
// setFilter(e.target.value || undefined) }} > <option className="text-sm" value=""> All </option>
// {options.map((option, i) => ( <option className="text-sm" key={i} value={option.label}> {option.label} </option> ))}
// </select> </span> ) }
