import {Panel} from 'reactflow'
import {classNames} from '../../utils/classNames'
import {useTranslation} from 'react-i18next'
import {NODE_TYPE_CHILD, NODE_TYPE_PARENT, NODE_TYPE_ROOT, bg} from './consts'

const legend = [
  {
    title: 'resource.Children',
    bgColor: bg[NODE_TYPE_CHILD],
  },
  {
    title: 'resource.Parents',
    bgColor: bg[NODE_TYPE_PARENT],
  },
  {
    title: 'resource.Current',
    bgColor: bg[NODE_TYPE_ROOT],
  },
]
const LegendItem = ({title, bgColor}) => {
  return (
    <li className="flex text-sm text-gray-700">
      <span className={classNames('mr-2 flex h-4 w-4 rounded', bgColor)}></span>
      {title}
    </li>
  )
}
const Legend = () => {
  const {t} = useTranslation()
  return (
    <Panel position="top-left">
      <ul className="rounded bg-gray-50 p-2">
        {legend.map((item) => (
          <LegendItem key={item.title} title={t(item.title)} bgColor={item.bgColor} />
        ))}
      </ul>
    </Panel>
  )
}

export default Legend
