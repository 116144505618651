import {useEffect, useState} from 'react'
import axios from '../../../../services/api'
import {debounce, filterByUniqueProperty} from '../../../../utils/custom_attributes'
import ReactSelect from 'react-select'
import {customStyles} from '../../formFieldStyle'

const AsyncSelectInputView = (props) => {
  const {
    clearErrors,
    isMulti,
    onChange,
    field,
    onError,
    url,
    nestedOptionLabel,
    optionValue,
    optionLabel,
    hasError,
    defaultValue,
    disabled,
    clearOptions,
  } = props
  const [options, setOptions] = useState([])
  const [query, setQuery] = useState('')
  const [pending, setPending] = useState(false)

  useEffect(() => {
    const transformOptions = (options) =>
      options?.map((item) => {
        return {
          value: item[optionValue],
          label: nestedOptionLabel ? item[nestedOptionLabel][optionLabel] : item[optionLabel],
        }
      })
    const fetchQuery = async (url, query) => {
      let tempurl = url

      if (url.includes(`$temp#`)) {
        if (query === '') {
          const regex = /[a-zA-Z]+\((?:[a-zA-Z]+\/)?[a-zA-Z]+,\s?\$temp#\)\s?(AND|OR)?\s?/
          tempurl = url.replace(regex, '')
        } else {
          tempurl = url.replace(`$temp#`, query)
        }
      } else {
        tempurl = url + query
      }

      setPending(true)
      clearErrors()
      try {
        const {
          data: {results},
        } = await axios.get(tempurl)
        results &&
          setOptions((prevState) => {
            return filterByUniqueProperty(
              clearOptions
                ? transformOptions(results)
                : prevState.concat(transformOptions(results)),
              'value',
            )
          })
      } catch (error) {
        onError(error.data?.message)
      }
      setPending(false)
    }
    debounce(fetchQuery(url, query), 500)
  }, [query, url, nestedOptionLabel, optionValue, optionLabel]) // eslint-disable-line react-hooks/exhaustive-deps
  const getValue = (field) => {
    if (options) {
      const res = isMulti
        ? options.filter((c) =>
            !field?.value && defaultValue
              ? defaultValue?.includes(c.value)
              : field?.value?.includes(c.value),
          )
        : options.find(
            (option) => option.value === (field.value === undefined ? defaultValue : field.value),
          ) || ''
      return res
    } else {
      return isMulti ? [] : ''
    }
  }

  return (
    <ReactSelect
      isClearable
      cacheOptions
      isLoading={pending}
      onInputChange={(query) => setQuery(query)}
      styles={customStyles(!!hasError)}
      {...field}
      options={options}
      value={getValue(field)}
      onChange={onChange}
      isMulti={isMulti}
      isDisabled={disabled}
    />
  )
}

export default AsyncSelectInputView
