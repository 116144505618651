import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'ID',
    accessor: 'id',
    label: 'ID',
  },
  {
    header: 'TableLabelsCommon.Name',
    accessor: 'name',
    label: 'TableLabelsCommon.Name',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'configureInventoryType.InventoryTypeActions',
    label: 'configureInventoryType.InventoryTypeActions',
    accessor: 'url',
    idAccessor: 'id',
    nameAccessor: 'name',
    cell: 'InventoryTypeActionsCell',
    noSort: true,
  },
]
export default columnConfig
