import axios from '../services/api'

//types
const GET_FORMS_PENDING = 'idfunctiondb/forms/GET_FORMS_PENDING'
const GET_FORMS_SUCCESS = 'idfunctiondb/forms/GET_FORMS_SUCCESS'
const GET_FORMS_ERROR = 'idfunctiondb/forms/GET_FORMS_ERROR'

const DELETE_FORM_PENDING = 'idfunctiondb/forms/DELETE_FORM_PENDING'
const DELETE_FORM_SUCCESS = 'idfunctiondb/forms/DELETE_FORM_SUCCESS'
const DELETE_FORM_ERROR = 'idfunctiondb/forms/DELETE_FORM_ERROR'
const DELETE_FORM_CLEAR_ERROR = 'idfunctiondb/forms/DELETE_FORM_CLEAR_ERROR'

const SET_FORMDATA = 'idfunctiondb/forms/SET_FORMDATA'

const initialState = {
  get_forms: {
    success: [],
    pending: false,
    error: null,
  },
  delete_form: {
    success: null,
    pending: false,
    error: null,
  },
  set_formdata: {
    success: {},
  },
}

// reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_FORMS_PENDING:
      return {
        ...state,
        get_forms: {
          ...state.get_forms,
          pending: true,
        },
      }
    case GET_FORMS_SUCCESS:
      return {
        ...state,
        get_forms: {
          ...state.get_forms,
          success: action.payload,
          pending: false,
        },
      }
    case GET_FORMS_ERROR:
      return {
        ...state,
        get_forma: {
          ...state.get_forma,
          pending: false,
          error: action.payload,
        },
      }
    case DELETE_FORM_PENDING:
      return {
        ...state,
        delete_form: {
          ...state.delete_form,
          pending: true,
        },
      }
    case DELETE_FORM_SUCCESS:
      return {
        ...state,
        delete_form: {
          ...state.delete_form,
          success: action.payload,
          pending: false,
        },
      }
    case DELETE_FORM_ERROR:
      return {
        ...state,
        delete_form: {
          ...state.delete_form,
          pending: false,
          error: action.payload,
        },
      }
    case DELETE_FORM_CLEAR_ERROR:
      return {
        ...state,
        delete_form: {
          ...state.delete_form,
          error: null,
        },
      }
    case SET_FORMDATA:
      return {
        ...state,
        set_formdata: {
          ...state.set_formdata,
          success: action.payload,
        },
      }
    default:
      return state
  }
}

//actions

function getFormsPending() {
  return {type: GET_FORMS_PENDING}
}

function getFormsSuccess(payload) {
  return {type: GET_FORMS_SUCCESS, payload: payload}
}

function getFormsError(error) {
  return {type: GET_FORMS_ERROR, payload: error}
}

function deleteFormPending() {
  return {type: DELETE_FORM_PENDING}
}

function deleteFormSuccess(payload) {
  return {type: DELETE_FORM_SUCCESS, payload: payload}
}

function deleteFormError(error) {
  return {type: DELETE_FORM_ERROR, payload: error}
}

export function deleteFormClearError() {
  return {type: DELETE_FORM_CLEAR_ERROR}
}

export function setFormdata(payload) {
  return {type: SET_FORMDATA, payload: payload}
}

export const getForms = () => (dispatch) => {
  dispatch(getFormsPending())
  axios
    .get('/api/v1/forms?limit=10000')
    .then((res) => {
      dispatch(getFormsSuccess(res.data.results))
    })
    .catch((error) => {
      dispatch(getFormsError(error))
    })
}

export const deleteForm = (id) => (dispatch) => {
  dispatch(deleteFormPending())
  axios
    .delete(`/api/v1/forms/${id}`)
    .then((res) => {
      dispatch(deleteFormSuccess(res.data.results))
    })
    .catch((error) => {
      dispatch(deleteFormError(error))
    })
}
