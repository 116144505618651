import {ColumnConfig} from '../types'

const columnConfig: ColumnConfig[] = [
  {
    header: 'TableLabelsCommon.Status',
    accessor: 'active',
    label: 'TableLabelsCommon.Status',
    cell: 'Pill',
  },
  {
    header: 'TableLabelsCommon.CreatedAt',
    accessor: 'created_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.CreatedAt',
  },
  {
    header: 'TableLabelsCommon.Description',
    accessor: 'description',
    label: 'TableLabelsCommon.Description',
    minWidth: '400px',
  },
  {
    accessor: 'location.description',
    label: 'TableLabelsCommon.Location',
    header: 'SelectColumnFilterLocations',
    permissionsAccessor: 'listFormsLocations',
  },
  {
    header: 'TableLabelsCommon.Name',
    label: 'TableLabelsCommon.Name', // check
    accessor: 'name',
    secondAccessor: 'description',
    minWidth: '400px',
    cell: 'TwoRowCell',
  },
  {
    header: 'TableLabelsCommon.UpdatedAt',
    accessor: 'updated_at',
    cell: 'DateCell',
    label: 'TableLabelsCommon.UpdatedAt',
  },
  {
    header: 'workflows.Actions',
    label: 'workflows.Actions',
    accessor: 'id',
    secondAccessor: 'form_json',
    cell: 'FormEditFormCell',
    noSort: true,
  },
]
export default columnConfig
