import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import Collapse from '../components/Collapse'
import UserWrapper from '../components/wrappers/UserWrapper'
import PersonWrapper from '../components/wrappers/PersonWrapper'
import CompanyWrapper from '../components/wrappers/CompanyWrapper'
import ConfigurationWrapper from '../components/wrappers/ConfigurationWrapper'
import PrinterWrapper from '../components/wrappers/PrinterWrapper'
import InventoryTypeWrapper from '../components/wrappers/InventoryTypeWrapper'
import ResourceTypeWrapper from '../components/wrappers/ResourceTypeWrapper'
import IdentityProviderWrapper from '../components/wrappers/IdentityProviderWrapper'
import WorkflowsWrapper from '../components/wrappers/WorkflowsWrapper'
import SubscriptionsWrapper from '../components/wrappers/SubscriptionsWrapper'
import ExecutionsWrapper from '../components/wrappers/ExecutionsWrapper'
import FormsWrapper from '../components/wrappers/FormsWrapper'
import StaticAssetsWrapper from '../components/wrappers/StaticAssetsWrapper'

export default function AdminPanelScreen() {
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)
  const {t} = useTranslation()
  return (
    <>
      {permissions.listUsers ? (
        <Collapse title={t('userManagement.UserManagement')} formExpanded={false}>
          <UserWrapper />
        </Collapse>
      ) : null}
      {permissions.listPersons ? (
        <Collapse title={t('addPersonForm.PersonManagement')} formExpanded={false}>
          <PersonWrapper />
        </Collapse>
      ) : null}
      {permissions.listCompanies ? (
        <Collapse title={t('configureCompanies')} formExpanded={false}>
          <CompanyWrapper createCompanyLocations={permissions.createCompanyLocations} />
        </Collapse>
      ) : null}
      {permissions.listConnectors ? (
        <Collapse title={t('configureInterfaces.ConfigureInterfaces')} formExpanded={false}>
          <ConfigurationWrapper />
        </Collapse>
      ) : null}
      {permissions.listPrinters ? (
        <Collapse title={t('configurePrinters.ConfigurePrinters')} formExpanded={false}>
          <PrinterWrapper />
        </Collapse>
      ) : null}
      {permissions.listInventories ? (
        <Collapse title={t('configureInventoryType.ConfigureInventoryType')} formExpanded={false}>
          <InventoryTypeWrapper />
        </Collapse>
      ) : null}
      {permissions.listAccessProfiles ? (
        <Collapse title={t('configureResourceType.ConfigureResourceType')} formExpanded={false}>
          <ResourceTypeWrapper />
        </Collapse>
      ) : null}
      {permissions.updateCompany ? (
        <Collapse title={t('userManagement.StaticAssets')} formExpanded={false}>
          <StaticAssetsWrapper />
        </Collapse>
      ) : null}
      {permissions.listIdentityProviders ? (
        <Collapse title={t('userManagement.AddIdentityProvider')} formExpanded={false}>
          <IdentityProviderWrapper />
        </Collapse>
      ) : null}
      {permissions.listForms ? (
        <Collapse title={t('userManagement.WorkflowForms')} formExpanded={false}>
          <FormsWrapper />
        </Collapse>
      ) : null}
      {permissions.listWorkflows ? (
        <Collapse title={t('userManagement.Workflows')} formExpanded={false}>
          <WorkflowsWrapper />
        </Collapse>
      ) : null}
      {permissions.listSubscriptions ? (
        <Collapse title={t('userManagement.Subscriptions')} formExpanded={false}>
          <SubscriptionsWrapper />
        </Collapse>
      ) : null}
      {permissions.listWorkflows ? ( // TODO: listExecutions is not used.
        <Collapse title={t('userManagement.Executions')} formExpanded={false}>
          <ExecutionsWrapper />
        </Collapse>
      ) : null}
    </>
  )
}
