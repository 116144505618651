import axios from '../services/api'
import {setCardPhoto} from './cards_photos'

// Types
const GET_PHOTO_PENDING = 'idfunctiondb/photos/GET_PHOTO_PENDING'
const GET_PHOTO_SUCCESS = 'idfunctiondb/photos/GET_PHOTO_SUCCESS'
const GET_PHOTO_ERROR = 'idfunctiondb/photos/GET_PHOTO_ERROR'

const CREATE_PHOTO_PENDING = 'idfunctiondb/photos/CREATE_PHOTO_PENDING'
const CREATE_PHOTO_SUCCESS = 'idfunctiondb/photos/CREATE_PHOTO_SUCCESS'
const CREATE_PHOTO_ERROR = 'idfunctiondb/photos/CREATE_PHOTO_ERROR'

const SET_AVATAR_PHOTO_PENDING = 'idfunctiondb/photos/SET_AVATAR_PHOTO_PENDING'
const SET_AVATAR_PHOTO_SUCCESS = 'idfunctiondb/photos/SET_AVATAR_PHOTO_SUCCESS'
const SET_AVATAR_PHOTO_ERROR = 'idfunctiondb/photos/SET_AVATAR_PHOTO_ERROR'

const SET_AVATAR_PHOTO_CLEAR_ERROR = 'idfunctiondb/photos/SET_AVATAR_PHOTO_CLEAR_ERROR'

// Initial
const initialState = {
  get_photo: {
    success: null,
    pending: false,
    error: null,
  },

  create_photo: {
    success: [],
    pending: false,
    error: null,
  },

  set_avatar_photo: {
    success: null,
    pending: false,
    error: null,
    successMessage: false,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PHOTO_PENDING:
      return {
        ...state,
        get_photo: {
          ...state.get_photo,
          pending: true,
        },
      }
    case GET_PHOTO_SUCCESS:
      return {
        ...state,
        get_photo: {
          ...state.get_photo,
          success: action.payload,
          pending: false,
        },
      }
    case GET_PHOTO_ERROR:
      return {
        ...state,
        get_photo: {
          ...state.get_photo,
          error: action.payload,
          pending: false,
        },
      }
    case CREATE_PHOTO_PENDING:
      return {
        ...state,
        create_photo: {
          ...state.create_photo,
          pending: true,
        },
      }
    case CREATE_PHOTO_SUCCESS:
      return {
        ...state,
        create_photo: {
          ...state.create_photo,
          success: action.payload,
          pending: false,
        },
      }
    case CREATE_PHOTO_ERROR:
      return {
        ...state,
        create_photo: {
          ...state.create_photo,
          error: action.payload,
          pending: false,
        },
      }
    case SET_AVATAR_PHOTO_PENDING:
      return {
        ...state,
        set_avatar_photo: {
          ...state.set_avatar_photo,
          pending: true,
          successMessage: false,
        },
      }
    case SET_AVATAR_PHOTO_SUCCESS:
      return {
        ...state,
        set_avatar_photo: {
          ...state.set_avatar_photo,
          success: action.payload,
          pending: false,
          successMessage: true,
        },
      }
    case SET_AVATAR_PHOTO_ERROR:
      return {
        ...state,
        set_avatar_photo: {
          ...state.set_avatar_photo,
          error: action.payload,
          pending: false,
          successMessage: false,
        },
      }
    case SET_AVATAR_PHOTO_CLEAR_ERROR:
      return {
        ...state,
        set_avatar_photo: {
          ...state.set_avatar_photo,
          error: null,
          pending: false,
          successMessage: false,
        },
      }
    default:
      return state
  }
}

// Actions
function getPhotoPending() {
  return {type: GET_PHOTO_PENDING}
}
function getPhotoSuccess(payload) {
  return {type: GET_PHOTO_SUCCESS, payload: payload}
}
function getPhotoError(error) {
  return {type: GET_PHOTO_ERROR, payload: error}
}

function createPhotoPending() {
  return {type: CREATE_PHOTO_PENDING}
}

function createPhotoSuccess(payload) {
  return {type: CREATE_PHOTO_SUCCESS, payload: payload}
}

function createPhotoError(error) {
  return {type: CREATE_PHOTO_ERROR, payload: error}
}

function setAvatarPhotoPending() {
  return {type: SET_AVATAR_PHOTO_PENDING}
}

export function setAvatarPhotoSuccess(payload) {
  return {type: SET_AVATAR_PHOTO_SUCCESS, payload: payload}
}

function setAvatarPhotoError(error) {
  if (typeof error === 'string') {
    error = JSON.parse(error)
  }
  return {type: SET_AVATAR_PHOTO_ERROR, payload: error}
}

export function setAvatarPhotoCLearError() {
  return {type: SET_AVATAR_PHOTO_CLEAR_ERROR}
}

// Operations

export const getPhoto = (id) => (dispatch) => {
  dispatch(getPhotoPending())
  return axios
    .get(`/api/v1/employees/${id}`)
    .then((res) => {
      axios.get(res.data.photos.current, {responseType: 'blob'}).then((res) => {
        dispatch(getPhotoSuccess(res.data))
      })
      return res.data
    })
    .catch((error) => dispatch(getPhotoError(error)))
}

export const createPhoto = (url, form, errorAction) => (dispatch) => {
  dispatch(createPhotoPending())
  return axios
    .post(url, form)
    .then((res) => {
      dispatch(createPhotoSuccess(res.data))
      return res.data
    })
    .catch((error) => {
      dispatch(createPhotoError(error))
      dispatch(errorAction(error))
      return null
    })
}

export const setAvatarPhoto =
  (
    blob,
    brightness,
    saturate,
    contrast,
    avatarUrlPost, // should create the photo for identity
    avatarUrlPut, // should put the photo for identity
    IdentityCardPhotoPutUrl, // should put the photo for the card
    IdentityCardPhotoPostUrl, // should create the photo for the card
    isCardPhoto,
    employee_cards,
    isEmployee,
    isVisitor,
    bgColor,
  ) =>
  (dispatch) => {
    dispatch(setAvatarPhotoPending())
    let form = new FormData()
    form.append('webcam', blob, 'webcam.jpg')
    form.append('brightness', Math.floor(brightness.x))
    form.append('saturate', Math.floor(saturate.x))
    form.append('contrast', Math.floor(contrast.x))
    form.append('color', bgColor)

    axios
      .post(`/api/v1/camstream/create_profile_image`, form, {responseType: 'blob'})
      .then((response) => {
        const newform = new FormData()
        newform.append('webcam', response.data)

        if (!isCardPhoto && (isEmployee || isVisitor)) {
          dispatch(createPhoto(avatarUrlPost, newform))
            .then((resp) => {
              if (!isCardPhoto && (isEmployee || isVisitor)) {
                // sets the employee profile photo
                dispatch(setCardPhoto(avatarUrlPut, {file_id: resp.id})).then((res) =>
                  dispatch(setAvatarPhotoSuccess(res)),
                ) // sets the employee profile photo for unproduced cards
                employee_cards.forEach(function (arrayItem) {
                  if (arrayItem.card.state.name === 'UNPRODUCED') {
                    dispatch(
                      setCardPhoto(arrayItem.photos.current.split('?')[0], {
                        file_id: resp.id,
                      }),
                    ) // sets the card photo for every cards in the slider which is not produced
                  }
                })
              }
            })
            .catch((error) => {
              dispatch(setAvatarPhotoError(error))
            })
        }
        if (isCardPhoto) {
          dispatch(createPhoto(IdentityCardPhotoPostUrl, newform)) // POST: /api/v1/employees/4/cards/1012/photos
            .then((resp) => {
              dispatch(setCardPhoto(IdentityCardPhotoPutUrl, {file_id: resp.id})) // PUT: /api/v1/employees/4/cards/1012
                .then((res) => {
                  dispatch(setAvatarPhotoSuccess(res))
                })
                .catch((error) => {
                  dispatch(setAvatarPhotoError(error))
                })
            })
            .catch((error) => {
              dispatch(setAvatarPhotoError(error))
            })
        }
      })
      .catch((error) => {
        Promise.resolve(error).then(async (x) =>
          dispatch(setAvatarPhotoError(await x.response.data.text())),
        )
      })
  }
