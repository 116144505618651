import {useEffect, useState} from 'react'
import Collapse from '../components/Collapse'
import {useTranslation} from 'react-i18next'
import {useSelector, useDispatch} from 'react-redux'
import Tabs from '../components/Tabs'
import {
  getVisitor,
  getVisitorMeta,
  getVisitorFiles,
  getVisitorFilesClearError,
  createVisitorFile,
  createVisitorFileClearError,
  createVisitorCustomAttribute,
  createVisitorCustomAttributeClearError,
  getVisitorClearError,
  deleteVisitorPhoto,
  deleteVisitorPhotoClearError,
} from '../stores/visitors'
import {getIdentityResources, getIdentityResourcesClearError} from '../stores/identities'
import {getCardsHistory, getCardsHistoryClearError, getVisitorsCards} from '../stores/cards'
import {
  getVisitorsInventories,
  getVisitorsInventoriesClearError,
  createVisitorsInventory,
  createVisitorsInventoryCLearError,
  filterVisitorsInventories,
  filterVisitorsInventoriesClearError,
} from '../stores/visitors_inventories'
import ResourcesWrapper from '../components/wrappers/ResourcesWrapper'
import InventoriesWrapper from '../components/wrappers/InventoriesWrapper'
import CardsHistoryWrapper from '../components/wrappers/CardsHistoryWrapper'
import FilesWrapper from '../components/wrappers/FilesWrapper'
import FormEditVisitor from '../components/forms/FormEditVisitor'
import FormAddCustomField from '../components/forms/FormAddCustomField'
import IdentityCardsWrapper from '../components/wrappers/IdentityCardsWrapper'
import IdentityHeader from '../components/IdentityHeader'
import ErrorModal from '../components/ErrorModal'
import {useParams, useNavigate} from 'react-router-dom'
import {VISITOR} from '../utils/formViews'
import WorkflowForms from '../components/WorkflowForms'
import {TableId} from '../stores/types/tables'
import {useTableColumns} from '../hooks/useTableColumns'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'
import ServerSideTable from '../components/table/ServerSideTable'
import {Modal, ModalOpenButton, ModalContentsBase} from '../components/Modal'
import {Button} from '../components/Buttons'
import FormAddToIdentityGroup from '../components/forms/FormAddToIdentityGroup'
import {getIdentityVisitor} from '../stores/identity'

export default function VisitorScreen() {
  let {id} = useParams()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [identity, setIdentity] = useState()
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  // visitor states
  const visitor = useSelector((state) => state.visitors.get_visitor.success)
  const visitor_error = useSelector((state) => state.visitors.get_visitor.error)

  const set_visitor_success = useSelector((state) => state.visitors.set_visitor.pending)

  const create_visitor_custom_attribute = useSelector(
    (state) => state.visitors.create_visitor_custom_attribute.success,
  )

  const create_visitor_custom_attribute_error = useSelector(
    (state) => state.visitors.create_visitor_custom_attribute.error,
  )

  const create_visitor_custom_attribute_pending = useSelector(
    (state) => state.visitors.create_visitor_custom_attribute.pending,
  )

  const delete_visitor_custom_attribute = useSelector(
    (state) => state.visitors.delete_visitor_custom_attribute.success,
  )

  // visitor meta states
  const visitorMeta = useSelector((state) => state.visitors.get_visitor_meta.success)

  // visitor cards states
  const get_visitor_cards_pending = useSelector((state) => state.cards.get_visitor_cards.pending)

  // visitor resources states

  const visitors_access_profiles = useSelector(
    (state) => state.identities.get_identity_resources.success,
  )
  const visitors_access_profiles_pending = useSelector(
    (state) => state.identities.get_identity_resources.pending,
  )
  const visitors_access_profiles_error = useSelector(
    (state) => state.identities.get_identity_resources.error,
  )
  const successfullyAssignedResource = useSelector(
    (state) => state.identities.attach_identity_resources.success,
  )
  const successfullyDeletedResource = useSelector(
    (state) => state.identities.delete_identity_resource.success,
  )

  // visitor inventories states

  const visitors_inventories = useSelector(
    (state) => state.visitors_inventories.get_visitors_inventories.success,
  )
  const visitors_inventories_pending = useSelector(
    (state) => state.visitors_inventories.get_visitors_inventories.pending,
  )
  const visitors_inventories_error = useSelector(
    (state) => state.visitors_inventories.get_visitors_inventories.error,
  )
  const assign_visitors_inventory_success = useSelector(
    (state) => state.visitors_inventories.create_visitors_inventory.success,
  )

  const assign_visitors_inventory_pending = useSelector(
    (state) => state.visitors_inventories.create_visitors_inventory.pending,
  )
  const assign_visitors_inventory_error = useSelector(
    (state) => state.visitors_inventories.create_visitors_inventory.error,
  )

  const delete_visitors_inventory = useSelector(
    (state) => state.visitors_inventories.delete_visitors_inventory.success,
  )
  const set_visitors_inventory = useSelector(
    (state) => state.visitors_inventories.set_visitors_inventory.success,
  )

  const filter_visitors_inventories = useSelector(
    (state) => state.visitors_inventories.filter_visitors_inventories.success,
  )

  const filter_visitors_inventories_pending = useSelector(
    (state) => state.visitors_inventories.filter_visitors_inventories.pending,
  )
  const filter_visitors_inventories_error = useSelector(
    (state) => state.visitors_inventories.filter_visitors_inventories.error,
  )

  // visitor cardHistory states

  const cardsHistory = useSelector((state) => state.cards.get_cards_history.success)
  const cardsHistory_pending = useSelector((state) => state.cards.get_cards_history.pending)
  const cardsHistory_error = useSelector((state) => state.cards.get_cards_history.error)

  // visitor CardHistory refresh states

  const assign_card = useSelector((state) => state.cards.assign_card.success)
  const unassign_card = useSelector((state) => state.cards.unassign_card.success)
  const action_applied_on_card = useSelector((state) => state.cards.apply_action_card.success)

  // visitor files states

  const visitor_files = useSelector((state) => state.visitors.get_visitor_files.success)
  const visitor_files_pending = useSelector((state) => state.visitors.get_visitor_files.pending)
  const visitor_files_error = useSelector((state) => state.visitors.get_visitor_files.error)
  const assign_file_success = useSelector((state) => state.visitors.create_visitor_file.success)
  const assign_file_pending = useSelector((state) => state.visitors.create_visitor_file.pending)
  const assign_file_error = useSelector((state) => state.visitors.create_visitor_file.error)
  const unassign_file_success = useSelector((state) => state.visitors.delete_visitor_file.success)

  // visitor's image states
  const visitorPhotoUpdateSuccess = useSelector((state) => state.photos.set_avatar_photo.success) // Only way for now
  // to update the
  // screen after photo
  // update
  const delete_visitor_photo_error = useSelector(
    (state) => state.visitors.delete_visitor_photo.error,
  )
  const delete_visitor_photo_pending = useSelector(
    (state) => state.visitors.delete_visitor_photo.pending,
  )
  const delete_visitor_photo_success = useSelector(
    (state) => state.visitors.delete_visitor_photo.success,
  )
  const update_identity_success = useSelector((state) => state.identity.update_identity.success)
  const delete_identity_child_success = useSelector(
    (state) => state.identity.delete_identity_child.success,
  )
  const columnsFilesVisitor = useTableColumns(TableId.FILES_VISITOR)
  const columnsInventoriesVisitor = useTableColumns(TableId.INVENTORIES_VISITOR)
  // get Visitor
  useEffect(() => {
    dispatch(getVisitor(id))
  }, [
    dispatch,
    id,
    create_visitor_custom_attribute,
    delete_visitor_custom_attribute,
    visitorPhotoUpdateSuccess,
    set_visitor_success,
    delete_visitor_photo_success,
  ])

  // get Visitor Meta
  useEffect(() => {
    if (permissions.listEmployees) {
      // if user has no permission we don't need to call getVisitorMeta
      dispatch(getVisitorMeta(id))
    }
  }, [id, permissions.listEmployees, dispatch])

  // get Visitor's resources
  useEffect(() => {
    dispatch(getIdentityResources(id, 'visitor'))
  }, [
    dispatch,
    id,
    successfullyAssignedResource,
    successfullyDeletedResource,
    visitors_access_profiles_error?.message,
  ])

  // get Visitor's inventories
  useEffect(() => {
    dispatch(getVisitorsInventories(`/api/v1/visitors/${id}/inventories`)) // /api/v1/employees/3/inventories
  }, [
    dispatch,
    id,
    assign_visitors_inventory_success,
    delete_visitors_inventory,
    set_visitors_inventory,
    visitors_inventories_error?.message,
  ])

  // get Visitor's Card History
  useEffect(() => {
    dispatch(getCardsHistory('visitors', id))
  }, [
    dispatch,
    id,
    assign_card,
    action_applied_on_card,
    unassign_card,
    cardsHistory_error?.message,
  ])

  // get Visitor's Files
  useEffect(() => {
    dispatch(getVisitorFiles(id))
  }, [dispatch, id, assign_file_success, unassign_file_success, visitor_files_error?.message])

  // get Visitor's Cards
  const cards = useSelector((state) => state.cards.get_visitor_cards.success)
  const card_photo_changed = useSelector((state) => state.cards_photos.set_card_photo.success)
  const selected_printer = useSelector((state) => state.printers.get_selected_printer.success)
  // TODO: refactor this again. const create_card_success = useSelector((state) => state.cards.create_card.success)
  const bulk_create_personalized_cards = useSelector(
    (state) => state.cards.bulk_create_personalized_cards.success,
  )
  const create_cards_assign_set_photos = useSelector(
    (state) => state.cards.create_cards_assign_set_photos.success,
  )
  //const create_card_success = useSelector((state) => state.cards.create_card.success)

  useEffect(() => {
    dispatch(getVisitorsCards(`/api/v1/visitors/${id}/cards`))
  }, [
    dispatch,
    id,
    bulk_create_personalized_cards,
    action_applied_on_card,
    card_photo_changed,
    assign_card,
    permissions,
    selected_printer,
    unassign_card,
    delete_visitor_photo_success,
    create_cards_assign_set_photos,
  ])

  useEffect(() => {
    const fetch = async (id) => {
      try {
        const data = await dispatch(getIdentityVisitor(id))
        setIdentity(data)
      } catch (error) {
        setIdentity(undefined)
      }
    }

    if (id) {
      fetch(id).then()
    }
  }, [id, dispatch])

  useEffect(() => {
    if (visitor_error?.response?.status === 404) {
      navigate('/not_found/', {replace: true})
    }
  }, [visitor_error?.response?.status, navigate])

  const tabsArray = permissions.createVisitor // TODO: this should be create customAttribute permission
    ? [
        {
          name: 'Personal',
          component: (
            <FormEditVisitor
              data={visitor}
              identityUpdatePermission={permissions.updateVisitor}
              identityUpdateCustomAttribePermission={permissions.createVisitor} // TODO: this should be update/delete
              // customAttribute permission
            />
          ),
        },
        {
          name: 'Custom Fields',
          component: (
            <FormAddCustomField
              data={visitor}
              submit={createVisitorCustomAttribute}
              pending={create_visitor_custom_attribute_pending}
              setError={createVisitorCustomAttributeClearError}
              error={create_visitor_custom_attribute_error}
            />
          ),
        },
      ]
    : [
        {
          name: 'Personal',
          component: (
            <FormEditVisitor
              data={visitor}
              identityUpdatePermission={permissions.updateVisitor}
              identityUpdateCustomAttribePermission={permissions.createVisitor}
            />
          ),
        },
      ]

  const columnsIdentitiesVisitor = useTableColumns(TableId.IDENTITIES_VISITOR)

  return (
    <>
      {visitor_error ? (
        <ErrorModal error={visitor_error} setError={() => dispatch(getVisitorClearError())} />
      ) : null}
      <IdentityHeader
        identity={visitor}
        identityMeta={visitorMeta}
        identityIdentity={identity}
        identityPermission={permissions.updateIdentity}
        identityListPermission={permissions.listVisitors}
        identityAssignInventoryPermission={permissions.listInventories && permissions.updateVisitor}
        identityAssignResourcePermission={
          permissions.listAccessProfiles && permissions.updateVisitor
        }
        identityLabel="visitor_id"
        identityNumberLabel="visitor_number"
        identityNavTo="/visitors"
        isEmployee={false}
        isVisitor={true}
        cards={cards}
        assignInventoryToIdentity={createVisitorsInventory}
        assignInventoryToIdentityClearError={createVisitorsInventoryCLearError}
        assignInventoryToIdentityPending={assign_visitors_inventory_pending}
        assignInventoryToIdentityError={assign_visitors_inventory_error}
        filterIdentityInventory={filterVisitorsInventories}
        filterIdentityInventoryClearError={filterVisitorsInventoriesClearError}
        filteredIdentityInventoriesPending={filter_visitors_inventories_pending}
        filteredIdentityInventoriesError={filter_visitors_inventories_error}
        filteredIdentityInventories={filter_visitors_inventories}
        deleteIdentityImage={deleteVisitorPhoto}
        deleteIdentityImagePending={delete_visitor_photo_pending}
        deleteIdentityImageError={delete_visitor_photo_error}
        deleteIdentityImageClearError={deleteVisitorPhotoClearError}
      />
      <WorkflowForms renderOnUi={VISITOR} isOriginUrl />
      <Collapse title={t('employeeCardInfo.SectionTitle')}>
        <IdentityCardsWrapper
          data={visitor}
          cards={cards}
          identityAssignCardPermission={permissions.createCard}
          identityUnassignCardPermission={permissions.deleteVisitor} // TODO: this should be unassign card permission
          // or something else
          identityCardsPending={get_visitor_cards_pending}
          urlIdentity="visitors"
        />
      </Collapse>
      <Collapse title={t('employeeInfo.VisitorInfo')}>
        <Tabs tabs={tabsArray} />
      </Collapse>
      <Collapse title={t('employeeResource.Title')}>
        <ResourcesWrapper
          data={visitors_access_profiles}
          pending={visitors_access_profiles_pending}
          error={visitors_access_profiles_error}
          identityAssignResourcePermission={
            permissions.listAccessProfiles && permissions.updateVisitor
          }
          // resource permission
          setError={getIdentityResourcesClearError}
          identityLabel="visitor_id"
          identity={visitor}
        />
      </Collapse>
      {!!identity?.id && (
        <Collapse title={t('sidebar.Identities')}>
          <>
            {permissions.updateIdentity && (
              <Modal>
                <ModalOpenButton>
                  <Button text={t('identity.AddIdentity')} variant="secondary" />
                </ModalOpenButton>
                <ModalContentsBase title={t('identity.AddIdentity')}>
                  <FormAddToIdentityGroup identity={identity} />
                </ModalContentsBase>
              </Modal>
            )}
            <EditTableWrapper tableId={TableId.IDENTITIES_VISITOR} />
            <ServerSideTable
              columns={columnsIdentitiesVisitor}
              entityName={`identities/${identity?.id}/parents`}
              rowUrlAccessor={'id'}
              url={'/identities/'}
              deleteAction={delete_identity_child_success}
              addAction={update_identity_success}
            />
          </>
        </Collapse>
      )}
      <Collapse title={t('visitorInventory.Title')}>
        <InventoriesWrapper
          data={visitors_inventories}
          pending={visitors_inventories_pending}
          error={visitors_inventories_error}
          setError={getVisitorsInventoriesClearError}
          identity={visitor}
          identityLabel="visitor_id"
          identityAssignInventoryPermission={
            permissions.listInventories && permissions.updateVisitor
          }
          assignInventoryToIdentity={createVisitorsInventory}
          assignInventoryToIdentityClearError={createVisitorsInventoryCLearError}
          assignInventoryToIdentityPending={assign_visitors_inventory_pending}
          assignInventoryToIdentityError={assign_visitors_inventory_error}
          filterIdentityInventory={filterVisitorsInventories}
          filterIdentityInventoryClearError={filterVisitorsInventoriesClearError}
          filteredIdentityInventoriesPending={filter_visitors_inventories_pending}
          filteredIdentityInventoriesError={filter_visitors_inventories_error}
          filteredIdentityInventories={filter_visitors_inventories}
          columns={columnsInventoriesVisitor}
          tableId={TableId.INVENTORIES_VISITOR}
        />
      </Collapse>
      <Collapse title={t('employeeCardHistoryInfo.Title')}>
        <CardsHistoryWrapper
          data={cardsHistory}
          pending={cardsHistory_pending}
          error={cardsHistory_error}
          setError={getCardsHistoryClearError}
        />
      </Collapse>
      <Collapse title={t('employeeFiles.Title')}>
        <FilesWrapper
          data={visitor_files}
          pending={visitor_files_pending}
          error={visitor_files_error}
          setError={getVisitorFilesClearError}
          assignFileToIdentityPending={assign_file_pending}
          assignFileToIdentityError={assign_file_error}
          addFile={createVisitorFile}
          IdentityCreateFileClearError={createVisitorFileClearError}
          entityId={id}
          columns={columnsFilesVisitor}
          tableId={TableId.FILES_VISITOR}
        />
      </Collapse>
    </>
  )
}
