import Table from '../table'
import {useSelector} from 'react-redux'
import {useTableColumns} from '../../hooks/useTableColumns'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'

export default function InventoryHistoryWrapper(props) {
  const permissions = useSelector((state) => state.auth.fetchUserSuccess.permissions)

  const columns = useTableColumns(TableId.INVENTORY_HISTORY)
  return (
    <>
      {permissions.listAccessProfiles ? (
        <>
          <EditTableWrapper tableId={TableId.INVENTORY_HISTORY} />
          <Table
            columns={columns}
            data={props.data}
            pending={props.pending}
            error={props.error}
            setError={props.setError}
          />
        </>
      ) : null}
    </>
  )
}
