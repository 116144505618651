import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {getWorkflows, putWorkflowClearError} from '../../stores/workflows'
import Table from '../table'
import {TableId} from '../../stores/types/tables'
import EditTableWrapper from './EditTableWrapper'
import {useTableColumns} from '../../hooks/useTableColumns'

export default function WorkflowsWrapper() {
  const dispatch = useDispatch()
  const putWorkflowSuccess = useSelector((state) => state.workflows.put_workflow.success)
  const deleteWorkflowSuccess = useSelector((state) => state.workflows.delete_workflow.success)

  // get Workflows
  useEffect(() => {
    dispatch(getWorkflows())
  }, [dispatch, putWorkflowSuccess, deleteWorkflowSuccess])

  const columns = useTableColumns(TableId.WORKFLOWS)

  const workflows = useSelector((state) => state.workflows.get_workflows.success)
  const workflowsPending = useSelector((state) => state.workflows.get_workflows.pending)
  const workflowsError = useSelector((state) => state.workflows.get_workflows.error)

  return (
    <>
      <EditTableWrapper tableId={TableId.WORKFLOWS} />
      <Table
        columns={columns}
        data={workflows}
        pending={workflowsPending}
        error={workflowsError}
        setError={putWorkflowClearError}
      />
    </>
  )
}
