import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import ReactSelect, {components} from 'react-select'
import {customStyles} from '../../formFieldStyle'

function arrayMove(array, from, to) {
  const slicedArray = array.slice()
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0])
  return slicedArray
}

const SortableSelectView = ({
  isMulti,
  field,
  disableOnSelect,
  options,
  isError,
  defaultValue,
  disabled,
}) => {
  const getValue = (field) => {
    if (options) {
      const res = isMulti
        ? field.value?.map((fieldValue) => options.find((option) => option.value === fieldValue))
        : options.find(
            (option) => option.value === (field.value === undefined ? defaultValue : field.value),
          )
      return res || []
    } else {
      return isMulti ? [] : ''
    }
  }
  const onSortEnd = ({oldIndex, newIndex}) => {
    const newValue = arrayMove(field.value, oldIndex, newIndex)
    field.onChange(newValue)
  }

  const SortableMultiValue = SortableElement((props) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }
    const innerProps = {...props.innerProps, onMouseDown}
    return <components.MultiValue {...props} innerProps={innerProps} />
  })

  const SortableMultiValueLabel = SortableHandle((props) => (
    <components.MultiValueLabel {...props} />
  ))

  const SortableSelect = SortableContainer(ReactSelect)
  return isMulti ? (
    <SortableSelect
      useDragHandle
      axis="xy"
      closeMenuOnSelect={false}
      onSortEnd={onSortEnd}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      isDisabled={(disableOnSelect && !!field.value) || disabled}
      isClearable
      styles={{
        ...customStyles(isError),
        multiValueRemove: (base) =>
          disableOnSelect && !!field.value
            ? {
                ...base,
                display: 'none',
              }
            : {...base},
      }}
      {...field}
      options={options}
      value={getValue(field)}
      onChange={(option) => {
        const newValue = isMulti ? option.map((c) => c?.value) : option?.value
        field.onChange(newValue)
      }}
      isMulti
    />
  ) : (
    <ReactSelect
      isDisabled={(disableOnSelect && !!field.value) || disabled}
      isClearable
      styles={customStyles(isError)}
      {...field}
      options={options}
      value={getValue(field)}
      defaultValue={defaultValue}
      onChange={(option) => {
        field.onChange(option?.value)
      }}
      isMulti={false}
    />
  )
}

export default SortableSelectView
