import {base64DecToArr} from '../utils/camera'
import axios from '../services/api'

// Types
const TAKE_SNAPSHOT_PENDING = 'idfunctiondb/camera/TAKE_SNAPSHOT_PENDING'
const TAKE_SNAPSHOT_SUCCESS = 'idfunctiondb/camera/TAKE_SNAPSHOT_SUCCESS'
const TAKE_SNAPSHOT_ERROR = 'idfunctiondb/camera/TAKE_SNAPSHOT_ERROR'
const TAKE_SNAPSHOT_CLEAR_ERROR = 'idfunctiondb/camera/TAKE_SNAPSHOT_CLEAR_ERROR'

const UPLOAD_PHOTO_PENDING = 'idfunctiondb/camera/UPLOAD_PHOTO_PENDING'
const UPLOAD_PHOTO_SUCCESS = 'idfunctiondb/camera/UPLOAD_PHOTO_SUCCESS'
const UPLOAD_PHOTO_ERROR = 'idfunctiondb/camera/UPLOAD_PHOTO_ERROR'
const UPLOAD_PHOTO_CLEAR_ERROR = 'idfunctiondb/camera/UPLOAD_PHOTO_CLEAR_ERROR'

const initialState = {
  take_snapshoot: {
    success: [],
    pending: false,
    error: null,
  },

  upload_photo: {
    success: null,
    pending: false,
    error: null,
  },
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TAKE_SNAPSHOT_PENDING:
      return {
        ...state,
        take_snapshoot: {
          ...state.take_snapshoot,
          pending: true,
        },
      }
    case TAKE_SNAPSHOT_SUCCESS:
      return {
        ...state,
        take_snapshoot: {
          ...state.take_snapshoot,
          success: action.payload,
          pending: false,
        },
      }
    case TAKE_SNAPSHOT_ERROR:
      return {
        ...state,
        take_snapshoot: {
          ...state.take_snapshoot,
          error: action.payload,
          pending: false,
        },
      }
    case TAKE_SNAPSHOT_CLEAR_ERROR:
      return {
        ...state,
        take_snapshoot: {
          ...state.take_snapshoot,
          error: null,
          pending: false,
        },
      }
    case UPLOAD_PHOTO_PENDING:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          pending: true,
        },
      }
    case UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          success: action.payload,
          pending: false,
        },
      }
    case UPLOAD_PHOTO_ERROR:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          error: action.payload,
          pending: false,
        },
      }
    case UPLOAD_PHOTO_CLEAR_ERROR:
      return {
        ...state,
        upload_photo: {
          ...state.upload_photo,
          error: null,
          pending: false,
        },
      }
    default:
      return state
  }
}

// Actions
function takeSnapshotPending() {
  return {type: TAKE_SNAPSHOT_PENDING}
}
function takeSnapshotSuccess(payload) {
  return {type: TAKE_SNAPSHOT_SUCCESS, payload: payload}
}
function takeSnapshotError(error) {
  return {type: TAKE_SNAPSHOT_ERROR, payload: JSON.parse(error)}
}
export function takeSnapshotClearError() {
  return {type: TAKE_SNAPSHOT_CLEAR_ERROR}
}

function uploadPhotoPending() {
  return {type: UPLOAD_PHOTO_PENDING}
}
function uploadPhotoSuccess(payload) {
  return {type: UPLOAD_PHOTO_SUCCESS, payload: payload}
}
function uploadPhotoError(error) {
  return {type: UPLOAD_PHOTO_ERROR, payload: JSON.parse(error)}
}

export function uploadPhotoClearError() {
  return {type: UPLOAD_PHOTO_CLEAR_ERROR}
}

// Operations
export const takeSnapshot =
  (data_uri, setCapturedImages, videoWidth, videoHeight) => (dispatch) => {
    dispatch(takeSnapshotPending())
    const raw_image_data = data_uri.replace(/^data:image\/\w+;base64,/, '')
    var blob = new Blob([base64DecToArr(raw_image_data)], {type: 'image/jpeg'})
    var form = new FormData()
    form.append('webcam', blob)
    form.append('actions', 'facecrop')
    form.append('video_width', videoWidth)
    form.append('video_height', videoHeight)
    return axios
      .post(`/api/v1/camstream/crop_image`, form, {responseType: 'blob'})
      .then((res) => {
        setCapturedImages(res.data)
        dispatch(takeSnapshotSuccess(res.data))
        return res.data
      })
      .catch((error) => {
        Promise.resolve(error).then(async (x) =>
          dispatch(takeSnapshotError(await x.response.data.text())),
        )
      })
  }

export const uploadPhoto = (target, setSelected) => (dispatch) => {
  dispatch(uploadPhotoPending())
  var formData = new FormData()
  var imagefile = target
  formData.append('actions', 'facecrop')
  formData.append('upload', true)
  formData.append('webcam', imagefile.files[0])
  return axios
    .post(`/api/v1/camstream/crop_image`, formData, {responseType: 'blob'})
    .then((res) => {
      dispatch(uploadPhotoSuccess(res.data))
      setSelected(res.data)
    })
    .catch((error) => {
      Promise.resolve(error).then(async (x) =>
        dispatch(uploadPhotoError(await x.response.data.text())),
      )
    })
}
