import {useCallback, useEffect, useState} from 'react'
import {useParams, Navigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import axios from '../services/api'
import IdentityNavigationArrows from '../components/IdentityNavigationArrows'
import Collapse from '../components/Collapse'
import EditTableWrapper from '../components/wrappers/EditTableWrapper'
import {TableId} from '../stores/types/tables'
import {useTableColumns} from '../hooks/useTableColumns'
import ServerSideTable from '../components/table/ServerSideTable'
import {useDispatch, useSelector} from 'react-redux'
import {getIdentity, getIdentityClearError} from '../stores/identity'
import FormAddIdentityMember from '../components/forms/FormAddIdentityMember'
import FormUpdateIdentity from '../components/forms/FormUpdateIdentity'
import ErrorModal from '../components/ErrorModal'
import {IdentitiesIcon} from '../assets/IdentitiesIcon'
import IdentityDependencies from '../components/Dependency/IdentityDependencies'

const IdentityScreen = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [identity, setIdentity] = useState()
  const [isParents, setIsParents] = useState(false)
  const [identityMeta, setIdentityMeta] = useState()
  const [forceUpdate, setForceUpdate] = useState(0)

  const dispatch = useDispatch()

  const {success: deleteChildSuccessState} = useSelector(
    (state) => state.identity.delete_identity_child,
  )
  const {error} = useSelector((state) => state.identity.get_identity)
  const {success: deleteMemberSuccessState} = useSelector(
    (state) => state.access_profiles_identities.delete,
  )
  const {success: updateMemberSuccessState} = useSelector(
    (state) => state.access_profiles_identities.update,
  )

  const fetchIdentity = useCallback(async () => {
    if (id) {
      const data = await dispatch(getIdentity(id))
      setIdentity(data)
      setIsParents(!!data?.parent_identities)
    }
  }, [id, dispatch])

  useEffect(() => {
    if (id) {
      fetchIdentity().then()
    }
  }, [id, fetchIdentity])

  useEffect(() => {
    const fetchMeta = async (id) => {
      try {
        const {data: dataMeta} = await axios.get(
          `/api/v1/identities?current=${id}&filters=is_group eq '1'`,
        )
        setIdentityMeta(dataMeta.meta)
      } catch (error) {
        setIdentityMeta(undefined)
      }
    }

    if (id) {
      fetchMeta(id).then()
    }
  }, [id])

  const columnsIdentityMembers = useTableColumns(TableId.IDENTITY_MEMBERS)
  const columnsChildren = useTableColumns(TableId.IDENTITY_CHILDREN)
  const columnsParents = useTableColumns(TableId.IDENTITY_PARENTS)

  if (!error && !identity?.is_group && (identity?.employee_id || identity?.visitor_id)) {
    return (
      <>
        {!!identity.employee_id && <Navigate to={`/employees/${identity.employee_id}`} />}
        {!!identity.visitor_id && <Navigate to={`/visitors/${identity.visitor_id}`} />}
      </>
    )
  }

  return (
    <>
      {!!error && <ErrorModal error={error} setError={() => dispatch(getIdentityClearError())} />}
      <div className="flex justify-between">
        <div className="flex ">
          <IdentitiesIcon fill="#6b7280" className="mr-3 h-24 w-24 flex-shrink-0" />
          <div className="flex flex-col pt-3">
            <IdentityDependencies />
          </div>
          <div className="flex w-56 flex-col pt-3 md:w-72 lg:w-80">
            <p className="text-gray-700">{identity?.owner_name}</p>
          </div>
        </div>
        {!!identityMeta && (
          <div className="flex pt-3">
            <IdentityNavigationArrows
              next={identityMeta?.next}
              previous={identityMeta?.previous}
              navTo="/identities"
            />
          </div>
        )}
      </div>
      <Collapse title={t('resource.Members')}>
        <Collapse title={t('resource.AddMembers')}>
          <FormAddIdentityMember
            identity={identity}
            onSubmit={() => setForceUpdate((prev) => prev + 1)}
          />
        </Collapse>
        {id ? (
          <>
            <EditTableWrapper tableId={TableId.IDENTITY_MEMBERS} />
            <ServerSideTable
              columns={columnsIdentityMembers}
              entityName={'identities_access_profiles'}
              additionalParams={'flattened=false'}
              additionalFilter={`identity_id eq ${id}`}
              addAction={forceUpdate}
              deleteAction={deleteMemberSuccessState}
              addActionQuick={updateMemberSuccessState}
            />
          </>
        ) : (
          t('identity.MemberNotFound')
        )}
      </Collapse>
      {isParents && (
        <Collapse title={t('resource.Parents')}>
          <>
            <EditTableWrapper tableId={TableId.IDENTITY_PARENTS} />
            <ServerSideTable
              columns={columnsParents}
              entityName={`identities/${id}/parents`}
              rowUrlAccessor={'id'}
              url={'/identities/'}
            />
          </>
        </Collapse>
      )}
      <Collapse title={t('resource.Children')}>
        <>
          <Collapse title={t('resource.AddChildren')}>
            <FormUpdateIdentity
              identity={identity}
              onUpdate={() => setForceUpdate((prev) => prev + 1)}
              onlyChildren
            />
          </Collapse>
          <EditTableWrapper tableId={TableId.IDENTITY_CHILDREN} />
          <ServerSideTable
            columns={columnsChildren}
            entityName={`identities/${id}/children`}
            rowUrlAccessor={'id'}
            url={'/identities/'}
            addAction={forceUpdate}
            deleteAction={deleteChildSuccessState}
          />
        </>
      </Collapse>
      <Collapse title={t('identity.Details')}>
        <FormUpdateIdentity identity={identity} onUpdate={fetchIdentity} />
      </Collapse>
    </>
  )
}

export default IdentityScreen
