import {DynamicRhfForm} from '../formRendererRHF/DynamicRhfForm'
import {useTranslation} from 'react-i18next'
import {useState} from 'react'
import {InputComponent, InputYupValidationsType, YupValidationType} from '../formRendererRHF/types'
import axios from '../../services/api'
import Notification from '../Notification'

const FormAddResourceIdentity = ({resource, onSubmit}) => {
  const {t} = useTranslation()
  const [pending, setPending] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const now = new Date().toISOString().slice(0, -8)

  const handleSubmit = async (values) => {
    setPending(true)
    setError(null)
    setSuccess(false)
    try {
      await axios.post('/api/v1/identities_access_profiles', {...values, resource_id: resource.id})
      setSuccess(true)
      onSubmit()
    } catch (error) {
      setError(error)
    }
    setPending(false)
  }

  const fields = [
    {
      component: InputComponent.ASYNCREACTSELECT,
      name: 'identity_id',
      label: 'resource.Members',
      url: '/api/v1/identities?q=',
      nestedOptions: false,
      optionValue: 'id',
      optionLabel: 'owner_name',
      isMulti: true,
      validationType: YupValidationType.ARRAY,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_from',
      inputType: 'datetime-local',
      label: 'datePickerForms.ValidFrom',
      validationType: YupValidationType.DATE,
      defaultValue: now,
      validations: [
        {
          type: 'required',
          params: ['yupError.ThisFieldIsRequired'],
        },
      ],
    },
    {
      component: InputComponent.DATEPICKER,
      name: 'valid_to',
      inputType: 'datetime-local',
      allowEndless: true,
      label: 'datePickerForms.ValidTo',
      validationType: YupValidationType.DATE,
      defaultValue: now,
      validations: [
        {
          type: InputYupValidationsType.REQUIRED,
          params: ['yupError.ThisFieldIsRequired'],
        },
        {
          type: InputYupValidationsType.ENDDATE, // special type for validation
          params: ['valid_from', 'yupError.DateRange'],
        },
      ],
    },
  ]

  return (
    <>
      <DynamicRhfForm
        formConfig={{
          fields,
          name: 'form-add-resource-identity',
          submitButtonText: t('formCommon.Add'),
        }}
        onSubmit={handleSubmit}
        noFormBorder
        pending={pending}
        infoMessage={
          <Notification
            error={error}
            setError={() => {
              setError(null)
              setSuccess(false)
            }}
            success={!!success}
          />
        }
      />
    </>
  )
}

export default FormAddResourceIdentity
