import {getIsExpanded} from './dependancy'
import {classNames} from '../../utils/classNames'
import {Handle, Position, useEdges} from 'reactflow'
import {memo, useEffect, useState} from 'react'
import ArrowLeftOnRectangle from '../../assets/ArrowLeftOnRectangle.svg'
import ArrowRightOnRectangle from '../../assets/ArrowRightOnRectangle.svg'
import {NODE_TYPE_PARENT, NODE_TYPE_CHILD, CUSTOM_NODE_SIZE, bg} from './consts'

function CustomNode({data}) {
  const {isExpandable, type, label, id} = data
  const edges = useEdges()
  const [expanded, setExpanded] = useState()

  const isParent = type.includes(NODE_TYPE_PARENT)
  const isChild = type.includes(NODE_TYPE_CHILD)

  useEffect(() => {
    setExpanded(getIsExpanded(edges, id, isParent))
  }, [edges, id, isParent])
  return (
    <div
      style={{
        width: `${CUSTOM_NODE_SIZE.x}px`,
        height: `${CUSTOM_NODE_SIZE.y}px`,
      }}
      className={classNames(
        `border-1 flex rounded text-sm text-gray-700 shadow-md`,
        bg[type] || 'bg-gray-200',
      )}
    >
      {isParent && isExpandable && (
        <div className="flex w-6 items-center pl-0.5 align-middle">
          {expanded ? (
            <img
              alt="Expand"
              src={ArrowLeftOnRectangle}
              className="mr-4 h-6 w-6 flex-shrink-0 rotate-180 text-gray-500"
            />
          ) : (
            <img
              alt="Expand"
              src={ArrowRightOnRectangle}
              className="mr-4 h-6 w-6 flex-shrink-0 rotate-180 text-gray-500"
            />
          )}
        </div>
      )}

      <div className={`break-all px-2 text-center`}>{label}</div>
      {isChild && isExpandable && (
        <div className="flex w-6 items-center pr-0.5 align-middle">
          {expanded ? (
            <img
              alt="Expand"
              src={ArrowLeftOnRectangle}
              className="mr-4 h-6 w-6 flex-shrink-0  text-gray-500"
            />
          ) : (
            <img
              alt="Expand"
              src={ArrowRightOnRectangle}
              className="mr-4 h-6 w-6 flex-shrink-0  text-gray-500 "
            />
          )}
        </div>
      )}
      <Handle type="target" position={Position.Left} className="w-16 !bg-gray-500" />
      <Handle type="source" position={Position.Right} className="w-16 !bg-gray-500" />
    </div>
  )
}

export default memo(CustomNode)
