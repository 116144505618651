export default function ProgressBar({progress}) {
  return (
    <div className="w-full rounded-full bg-gray-200 dark:bg-gray-700">
      <div
        className="rounded-full bg-lime-600 p-0.5 text-center text-xs font-medium leading-none text-blue-100"
        style={{width: progress + '%', zoom: '100%'}}
      >
        {Math.floor(progress) + '%'}
      </div>
    </div>
  )
}
